import React, { useState, useEffect, useContext } from "react"
import PropTypes from 'prop-types'
import {
    Grid, Box
    } from '@mui/material'
import { CardContainer, LittleTag } from "common/containers"
import { HelveticaBold, HelveticaLight, PoppinsSemiBold } from "common/typographies"
import InviteUserModal from "./InviteUserModal"
import UserInvitationModal from "./UserInvitationModal"
import i18n from "i18n"
import addP from "assets/images/budgeting/addProject.png"
import ModalSelectTypeContact from "./modalSelectTypeContact"
import ModalSelectContact from './ModalSelectContact'
import AuthContext from "context/auth/authContext"
import OrgTableCustom from "./table/orgTable"
import * as amplitude from '@amplitude/analytics-browser';


const UserView = ({user}) => {
    const [open, setOpen] = useState(false)

    const {freeTrialExpired, getOrganization, organization, organizationUsers, organizationAdmin, getActualUser, internalUser} = useContext(AuthContext)
    const [openInvitation, setOpenInvitation] = useState(false)
    const [openModalSelect, setOpenModalSelect] = useState(false)
    const [openSelectContact, setOpenSelectContact] = useState(false)

    const [Worker, setWorker] = useState(null)


    useEffect(()=>{
    if(openInvitation==false){
        setWorker(null)
    }},[openInvitation])


   useEffect(()=>{
    amplitude.track('Gestion de equipo Visited');
   },[])

    return (

        <>
        <div style={{backgroundColor:'#EFEFEF'}}>
            <div style={{marginLeft:'30px', marginBottom:'25px'}}>
                <PoppinsSemiBold style={{fontSize:'18px'}}>{i18n.t("Gestión de equipo")}</PoppinsSemiBold>
            </div>
        {/* <div>
            <OrgTableCustom orgUsers = {organizationUsers} orgAdmin = {organizationAdmin} />
        </div> */}
        <Box sx={{ flexGrow: 1 }} style={{marginLeft:'30px', marginTop:'20px', minHeight:'260px', backgroundColor:'#EFEFEF'}}>
        <Grid container spacing={6} columns={{ xs: 2, sm: 8, md: 12 }}>
          
            <Grid item>
                <CardContainer>
                <img style={{width:'85px', height:'85px'}} src={user['custom:avatar']} />
                <HelveticaBold style={{fontSize:'14px', color:'#414141', marginTop:'5px',lineHeight:'17px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow:'hidden'}}>{organizationAdmin['custom:nombre_apellido'] ? organizationAdmin['custom:nombre_apellido'] : organizationAdmin['email']?.split('@')[0]}</HelveticaBold>
                <HelveticaLight style={{lineHeight:'10px', marginTop:'-5px'}}>{organizationAdmin['email']}</HelveticaLight>
                <LittleTag style={{marginLeft:'60px'}}> {i18n.t("PROPIETARIO")} </LittleTag>
                </CardContainer>
            </Grid>
          {
              organizationUsers ?  organizationUsers.length>0 ? 
              organizationUsers.map((worker, index) => (
                  
                  <>
                  
                <Grid item key={index}>
                <CardContainer onClick={()=>{setWorker(worker); setOpenInvitation(true)}} style={{cursor:'pointer'}}>
                <img style={{width:'85px', height:'85px'}} src={user['custom:avatar']} />
                <HelveticaBold style={{fontSize:'14px', color:'#414141', marginTop:'5px', lineHeight:'17px'}}>{worker.name}</HelveticaBold>
                <HelveticaLight style={{lineHeight:'10px', marginTop:'-5px'}}>{worker.email}</HelveticaLight>
                {worker.customRole && worker.customRole.value ?
                <LittleTag style={{
                    marginLeft:'60px' , 
                    textTransform:'uppercase', 
                    backgroundColor:worker.customRole.bgColor ? worker.customRole.bgColor : 'white' , 
                    color: worker.customRole.color ? worker.customRole.color : 'black'
                }} > 
                    {worker.customRole.value} 
                </LittleTag>
                 : null}   
                </CardContainer> 
     
            </Grid>
   
           
            </>
            ))
              
              : null : null
              
          }
          {
            freeTrialExpired || user['custom:role'] === 'WORKER' ?
            null :
            user['custom:subscription_plan']? 
                 user['custom_subscription_plan']=='BASIC' ?
                    organizationUsers.length<4?
                    <Grid item>
                    <CardContainer className="cardWithHover" style={{cursor:'pointer'}} onClick={()=>{setOpenModalSelect(true)}}>
                    <img style={{width:'85px', height:'85px'}} src={addP} />
                    <HelveticaBold style={{fontSize:'14px', color:'#AFAFB0', marginTop:'14px', lineHeight:'0px', marginTop:'35px'}}>{i18n.t('Añadir Usuario')}</HelveticaBold>
                    </CardContainer>
                </Grid> 
                :null
                :user['custom:subscription_plan']=='BUSINESS' || user['custom:subscription_plan']=='FREE_TRIAL' ?
                    organizationUsers.length<9?
                    <Grid item>
                    <CardContainer className="cardWithHover" style={{cursor:'pointer'}} onClick={()=>{setOpenModalSelect(true)}}>
                    <img style={{width:'85px', height:'85px'}} src={addP} />
                    <HelveticaBold style={{fontSize:'14px', color:'#AFAFB0', marginTop:'14px', lineHeight:'0px', marginTop:'35px'}}>{i18n.t('Añadir Usuario')}</HelveticaBold>
                    </CardContainer>
                </Grid>
                :null
                : <Grid item> 
                <CardContainer className="cardWithHover" style={{cursor:'pointer'}} onClick={()=>{setOpenModalSelect(true)}}>
                <img style={{width:'85px', height:'85px'}} src={addP} />
                <HelveticaBold style={{fontSize:'14px', color:'#AFAFB0', marginTop:'14px', lineHeight:'0px', marginTop:'35px'}}>{i18n.t('Añadir Usuario')}</HelveticaBold>
                </CardContainer>
            </Grid>
            :null
          }
          
        </Grid>
        
      </Box>
      </div>
      {open === true ? <InviteUserModal open={open} setOpen={setOpen} /> : null}
     { openInvitation && <UserInvitationModal open={openInvitation} setOpen={setOpenInvitation} worker={Worker}/>}
     { openModalSelect && <ModalSelectTypeContact open={openModalSelect} setOpen={setOpenModalSelect} openInvitation={open} setOpenInvitation={setOpen} setOpenSelectContact={setOpenSelectContact}/>}
      <ModalSelectContact open={openSelectContact} setOpen={setOpenSelectContact} />
     
      </>
      
      
    )
 }

UserView.propTypes = {
    user: PropTypes.object.isRequired
}

export default UserView