import React, { useState } from "react"
import PropTypes from "prop-types"
import { Form } from "reactstrap"
import UploadRoundedIcon from "@mui/icons-material/UploadRounded"
import Dropzone from "react-dropzone"
import i18n from "i18n"
import ModalCropImage from "./modalCropImage"
import ModalCallError from "common/modalCallError"

const ImageDropZone = ({ selectedFiles, setselectedFiles, setImage, message, accept}) => {
    const [open, setOpen] = useState(false)
    const [openError, setOpenError] = useState(false)
  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    if(files[0].size >= 2097152){
      setOpenError(true)
      return
    }else{
      setselectedFiles(files)
      //setOpen(true)
      setImage(files[0].preview)
    }

  }
  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }
  return (
    <>
    <Form>
      <Dropzone
       accept=  {accept? accept :['.jpg', '.png', '.jpeg']}
        className="dropzone"
        maxFiles={1}
        maxSize={2097152}
        onDrop={acceptedFiles => {
          handleAcceptedFiles(acceptedFiles)
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div className="dropzone2" style={{minHeight:'150px'}}>
            <div className="dz-message needsclick mt-2" {...getRootProps()} style={{padding:'10px'}}>
              <input {...getInputProps()} />
              <p
                style={{
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "19px",
                }}
              >
                {i18n.t("Arrastra archivos o explora tu ordenador")}
              </p>
              <div className="mb-3">
                <UploadRoundedIcon style={{ height: "60px", width: "60px" }} />
              </div>
              <p
                style={{
                  fontWeight: "300",
                  fontSize: "12px",
                  lineHeight: "16px",
                  textAlign: "center",
                }}
              >
                {message ? message : <>
                  {i18n.t(" Importante: La carga de tu logo debe de estar en formato ")}
                  .JPG o .PNG con una dimensión de 1200px de ancho y 600px de alto. </>}
               
              </p>
            </div>
          </div>
        )}
      </Dropzone>
      
    </Form>
    {
          openError && <ModalCallError open={openError} setOpen={setOpenError} showError={true} error={'La imagen subida es demasiado grande'} ubicacion={'Logotipo general'}/>
        }
    <ModalCropImage open={open} setOpen={setOpen} selectedFiles={selectedFiles} setselectedFiles={setselectedFiles} setImage={setImage}/>
    </>
  )
}
ImageDropZone.propTypes = {
  selectedFiles: PropTypes.array,
  setselectedFiles: PropTypes.func,
  setImage: PropTypes.func,
  message: PropTypes.string,
  accept: PropTypes.array,
}
export default ImageDropZone
