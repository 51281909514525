
import CreateActComponent from 'components/Project/Overview/Actas/createAct/CreateActComponent';
import React from 'react';
import { MetaTags } from 'react-meta-tags';
import { Container } from 'reactstrap';


const newCreateAct = () => {

    return (
        <React.Fragment>
        <div className="page-content" style={{overflow:'hidden'}}>
          <MetaTags>
            <title>Planhopper | Actas</title>
          </MetaTags>
          <Container fluid>
              <CreateActComponent/>
          </Container>
        </div>
      </React.Fragment>
    )

    }

export default newCreateAct;