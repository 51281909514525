import {
  SET_TREE,
  ON_UPDATE,
  SET_PART_ID,
  SET_LOADING,
  TOGGLE_DELETE_PART_MODAL,
  SET_ERROR,
  REMOVE_PART,
  MOVE_PART,
  REASSIGN_PART_POSITIONS,
  SET_OPEN_NEW_MODAL_PART,
  SET_BTN_LOADING,
} from "context/types"

const ExtrasAndContradictionsReducer = (state, action) => {
  switch (action.type) {
    case ON_UPDATE: {
      return {
        ...state,
        updating: true,
      }
    }

    case SET_LOADING: {
      return {
        ...state,
        loading: true,
      }
    }

    case SET_BTN_LOADING: {
      return {
        ...state,
        btnLoading: action.payload,
      }
    }

    case SET_TREE: {
      return {
        ...state,
        tree: action.payload,
        loading: false,
      }
    }

    case SET_PART_ID: {
      const { partIdsChecked } = action.payload
      return {
        ...state,
        partIdsChecked: partIdsChecked,
      }
    }

    case TOGGLE_DELETE_PART_MODAL: {
      const newState = {
        ...state,
        showDeletePartModal: action.payload.showDeletePartModal,
        deletingFromPart: action.payload.deletingFromPart,
      }

      return newState
    }

    case SET_ERROR: {
      const { loading, msj } = action.payload
      return {
        ...state,
        loading: loading,
        error: {
          ...state.error,
          msj: msj,
        },
      }
    }

    case REMOVE_PART: {
      const { chapterId, partId } = action.payload
      state.tree.removePart(chapterId, partId)
      return {
        ...state,
        deletingFromPart: null,
      }
    }

    case REASSIGN_PART_POSITIONS: {
      state.tree.updatePartPositionsForChapterOrAll(action.payload)
      return {
        ...state,
      }
    }

    case MOVE_PART: {
      const { part, searchDirection } = action.payload
      state.tree.moveParts(part.chapterId, part, searchDirection)
      return {
        ...state,
      }
    }

    case SET_OPEN_NEW_MODAL_PART: {
      const { isOpen, partToEdit } = action.payload

      return {
        ...state,
        newModalPart: {
          ...state.newModalPart,
          openModal: isOpen,
          partToEdit: partToEdit,
        },
      }
    }
    default: {
      return state
    }
  }
}

export default ExtrasAndContradictionsReducer
