import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { EditOffOutlined } from "@mui/icons-material"

import { HelveticaBold, HelveticaLight } from "common/typographies.js";
import { PriceInput } from "common/inputs";

const PartTableInput = ({
  partida = {},
  disabled = false,
  name = "name",
  onBlur = (name, value) => {},
  onClick = () => {},
  onFocus = (e) => {e.target.select()},
  symbol = "€",
  title = "Title",
  green = false, yellow = false, red = false,
  value = 0,
  isOutlined = false,
  outlined = () => {},
}) => {
  const [onEdit, setOnEdit] = useState(false);
  const [inputValue, setInputValue] = useState(value);

  const handleChange = (event) => {
    setOnEdit(true);
    setInputValue(event.target.value);
  };

  const handleBlur = (event) => {
    onBlur(name, event.target.value);
    setOnEdit(false);
  };

  useEffect(() => {
    setInputValue(parseFloat(value).toFixed(2));
  }, [partida]);

  return (
    <td style={{backgroundColor: red ? "#79211F" : yellow ? "#FCFC99" : green ? "#0CC078" : disabled ? "#E4E4E4" : "transparent"}}>
      <div className="inline" style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', position: 'relative', textAlign: 'center'}}>
        <PriceInput
          type="number" name={name} onClick={onClick} onFocus={onFocus}
          style={{width: '70%', position: 'relative', fontSize: '14px', textAlign: 'center', backgroundColor: "transparent", color: red ? '#FFFFFF' : '#414141' }}
          disabled={disabled}
          onChange={handleChange}
          onBlur={handleBlur}
          value={onEdit ? inputValue : parseFloat(inputValue).toFixed(2)}
        />
        <HelveticaBold style={{color: red ? '#FFFFFF' : '#414141', fontSize: '14px', textAlign: 'center'}}>{symbol}</HelveticaBold>
        {
          isOutlined && (
          <EditOffOutlined
            onClick={outlined}
            style={{position: "absolute", fontSize: "15px", right: "5px", 
                color: "#414141", top: "10px", cursor: "pointer"}}
          />)
        }
      </div>
    </td>
  );
};

PartTableInput.propTypes = {
  disabled: PropTypes.bool,
  green: PropTypes.bool,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  partida: PropTypes.object,
  red: PropTypes.bool,
  symbol: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.number,
  yellow: PropTypes.bool,
  isOutlined: PropTypes.bool,
  outlined: PropTypes.func,
};

export { PartTableInput };
