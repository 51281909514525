import React, { useEffect } from "react";  
import PropTypes from "prop-types";
import { HelveticaBold, HelveticaLight } from "common/typographies";
import i18n from "i18n";
import FileDropzone from "./fileDropzone";
import { CustomCheckedInput } from "common/inputs";
import { CheckedInput } from "common/inputs";
import TopQuickBar from "./topQuickBar";
import cloudIcon from "assets/customIcons/Cloud-upload.svg";

const FileIndex = ({partidaState, setPartidaState, imInUpdate}) => {
    const [openImage, setOpenImage] = React.useState(false)
    const [checkedFiles, setCheckedFiles] = React.useState(partidaState.files?.map((file, index) => false))

    useEffect(() => {
        setCheckedFiles(partidaState.files?.map((file, index) => false))
    }, [partidaState.files])

    const changeCheck = (index) => {
       setCheckedFiles(checkedFiles.map((file, i) => i === index ? !file : file))
    }


    return (
        <div style={{position:'relative', gridAutoRows: '1fr', display:'grid', flexDirection:'column', width:'100%', height:'100%'}}>
            <div style={{padding:'10px'}}>
            <HelveticaBold style={{fontSize:'14px', color:'#414141', marginBottom:'10px'}}>
                    {i18n.t("Archivos Adjuntos")}
                </HelveticaBold>
            <div style={{ width:'100%', height:'85%', position:'relative', overflowY:'auto'}}>
                {
                    partidaState.files?.length > 0 ?
                    partidaState.files?.map((file, index) => (
                       <div onClick={()=>{changeCheck(index)}} className="square" key={index} style={{position:'relative', cursor:'pointer', marginRight:'10px', marginBottom:'10PX', marginTop:'0px', display:'inline-flex', width:'28vh', background:'#F5F5F5', border:'1px solid #C4C4C4', borderRadius:'6px', height:'25vh'}}>
                        <div style={{position:'relative', overflow:'hidden'}}>
                            <div className="inline" style={{width:'100%'}}>
                        <CustomCheckedInput
                            style={{marginTop:'4.5px', minWidth:'14px', width:'14px', marginLeft:'3px', cursor:'pointer', marginRight:'3px'}}
                            >
                                { checkedFiles ? checkedFiles[index] && <CheckedInput/> : null  }
                            </CustomCheckedInput>
                            <HelveticaBold style={{color:'#414141', fontSize:'12px', marginBottom:'0px', fontWeight:'400', marginTop:'3px', overflow:'hidden', whiteSpace:'nowrap', display:'block', textOverflow:'ellipsis'}}>
                                {file.name}
                            </HelveticaBold>
                           
                            </div>
                            <div style={{width:'26vh', margin:'5px', height:'77%', justifyContent:'center', position:'relative', display:'flex',  borderRadius:'6px', border:'1px solid #C4C4C4', overflow:'hidden'}}>
                            {
                                file.name.split('.').pop() == 'pdf' ?
                                <embed src={file.url} style={{width:'100%', padding:'0px'}}/>
                                :
                                <img src={file.url} style={{padding:'10px'}}/>
                            }
                            </div>
                        </div>
                        </div>
                    ))      
                    :
                    <section 
                        style={{
                            justifyContent: 'center',
                            position: 'relative',
                            alignItems: 'center',
                            display: 'grid',
                            width:'100%',
                            height: '100%'
                        }}
                        >
                        <div onClick={()=>{setOpenImage(true)}} style={{borderRadius:'6px', cursor:'pointer', border: '1px dashed #414141', width:'320px', height:'277px', justifyContent:'center', textAlign:'center', padding:'20px'}}>
                            <HelveticaBold style={{fontWeight:'400', fontSize:'14px', color:'#414141', marginTop:'10px'}}>
                                {i18n.t("Arrastrar archivos o explorar equipo")}
                            </HelveticaBold>
                            <img src={cloudIcon} style={{width:'127px'}}></img>
                            <HelveticaBold style={{fontWeight:'400', fontSize:'14px', color:'#414141', marginTop:'10px'}}>
                                {i18n.t("Carga los archivos en formato .jpg y .pdf.")}
                            </HelveticaBold>
                        </div>
                    </section>
                }
            </div>
            {
                partidaState.files?.length>0 &&
            
            <div style={{position:'absolute', bottom:'0px'}}>
                <HelveticaLight onClick={()=>{setOpenImage(true)}} style={{cursor:'pointer', marginTop:'0px', color:'#414141', fontWeight:'500', width:'fit-content'}}>{i18n.t('+ Añadir archivos')}</HelveticaLight>
            </div>
}
            </div>
          
                {
                    openImage && <FileDropzone partidaState={partidaState} setPartidaState={setPartidaState} open={openImage} setOpen={setOpenImage} />
                }
        {
            checkedFiles?.includes(true) && <TopQuickBar files={partidaState.files} partidaState={partidaState} setPartidaState={setPartidaState} filesChequed={checkedFiles} setFilesChequed={setCheckedFiles}/>
        }
        </div>
    )
}

FileIndex.propTypes = {
    partidaState: PropTypes.object,
    setPartidaState: PropTypes.func,
    imInUpdate: PropTypes.bool
}

export default FileIndex
