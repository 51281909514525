import propTypes from "prop-types"
import React, { useContext, useEffect } from "react"
import ComparisonBudget from "./comparisonBudget"
import NotSendBudget from "./notSendBudget"
import ProvidersLayout from "./providersLayout"
import QuotedBudget from "./quotedBudget"
import SendBudget from "./sendBudget"
import { PoppinsNormal } from "common/typographies.js"
import AutoGestionBudget from "./autogestionBudget"
import i18n from "i18n"
import { useParams } from "react-router-dom"
import Budget2023Context from "context/budget2023/Budget2023Context"

const ProvidersContent = ({ projectInfo }) => {
  const { projectId } = useParams()

  const { budgetTree, getBudgetTree, loadingTree } = useContext(Budget2023Context)

  useEffect(() => {
    getBudgetTree(projectId, false)
  }, [projectId])

  return projectInfo != null || loadingTree==false? (
    <>
      <ProvidersLayout projectInfo={projectInfo} />
      <div style={{ marginLeft: "25px" }}>
        <NotSendBudget />
        <SendBudget projectInfo={projectInfo} />
        <ComparisonBudget />
        <QuotedBudget />
        <AutoGestionBudget />
      </div>
    </>
  ) : (
    <>
      <div
        style={{
          justifyContent: "center",
          width: "100%",
          textAlign: "center",
          position: "relative",
          display: "flex",
          marginLeft: "-100px",
          marginTop: "70px",
        }}
      >
        <div className="loader">
          <div className="ball"></div>
          <div className="ball"></div>
          <div className="ball"></div>
        </div>
      </div>
      <div
        style={{
          justifyContent: "center",
          width: "100%",
          textAlign: "center",
          position: "relative",
          display: "flex",
          marginLeft: "-30px",
        }}
      >
        <PoppinsNormal>{i18n.t("Cargando...")}</PoppinsNormal>
      </div>
      <div
        style={{
          justifyContent: "center",
          width: "100%",
          textAlign: "center",
          position: "relative",
          display: "flex",
          marginLeft: "-30px",
          marginTop: "-15px",
        }}
      >
        <PoppinsNormal style={{ fontWeight: "400" }}>
          {i18n.t("Esta acción puede tardar unos segundos")}
        </PoppinsNormal>
      </div>
    </>
  )
}

ProvidersContent.propTypes = {
  projectInfo: propTypes.object.isRequired,
}

export default ProvidersContent
