import React, { useContext, useEffect } from 'react'
import { Modal, CircularProgress } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types'
import { useState } from 'react';
import i18n from 'i18n';
import CloseIcon from "@mui/icons-material/Close";
import Budget2023Context from 'context/budget2023/Budget2023Context';
import  {PoppinsNormal} from 'common/typographies'
import CapituloModalMove from '../chapters/CapituloModalMove';
import ModalConfirmPaste from './modalConfirmPaste';
import BankPricesContext from 'context/bankPrices/bankPricesContext';
import AuthContext from 'context/auth/authContext';
import ModalSuccesPaste from './modalSuccesPaste';

function getModalStyle() {
    const top = 50 ;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: '850px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        borderRadius: 6,
        maxHeight: '550px',
        height: '550px',
        overflowY: 'auto',
        overflowX:'hidden',
    },
}));


const ModalPasteSelectionPart = ({open, setOpen, project, setOpenPrincipal}) => {

    const [ modalStyle ] = useState(getModalStyle);
    const classes = useStyles();
    const [loading, setLoading] = useState(false)
    const {getBudgetTree, budgetTree, loadingTree} = useContext(Budget2023Context)
    const {uncheckAll,myPricesBank, movePartsFromBankPrices, getSubCaps,selectedCategories2, importChaptersFromDatabase, categoriesChecked, partsChecked, createPartsOnBudget} = useContext(BankPricesContext);
    useEffect(() => {
        if(project){
            getBudgetTree(project)
        }
    } , [project])
    
    const [capituloSelected, setCapituloSelected] = useState({})
    const [openFinal, setOpenFinal] = useState(false)
    const [openModalConfirm, setOpenModalConfirm] = useState(false)

    useEffect(() => {
    } , [capituloSelected])
    const handleConfirm = async() => {
        setLoading(true)
        await movePartsFromBankPrices(capituloSelected, partsChecked)
        setLoading(false)
        uncheckAll()
        setOpen(false)
    }

    return(
        <>
        <Modal open={open} onClose={() => null}>
            <div style={modalStyle} className={classes.paper}>
                <div id='principalOptions' style={{marginTop:'20px', marginBottom:'15px', textAlign:'left'}}>
                    <CloseIcon id="cerrar" style={{marginTop:'-15px', right:'-10px'}} onClick={()=>{setOpen(false)}}></CloseIcon>
                    <p id='titles' style={{textAlign:'CENTER', fontSize:'20px', marginBottom:"35px"}}>{i18n.t("Selecciona el capitulo dónde quieres pegar tu seleccion")}</p>
                    
                    
                    <div style={{height:'350px', overflow:'auto', paddingBottom:'10px'}}>
                    {loadingTree ? 
                     <>
                     <div style={{justifyContent:'center', width:'100%', textAlign:'center', position:'relative', display:'flex', marginLeft:'-100px', marginTop:'0px'}}>
                     <div className='loader'>
                         <div className='ball'></div>
                         <div className='ball'></div>
                         <div className='ball'></div>
                    </div>
                     </div>
                     <div style={{justifyContent:'center', width:'100%', textAlign:'center', position:'relative', display:'flex', marginLeft:'-30px'}}>
                     <PoppinsNormal>{i18n.t("Cargando...")}</PoppinsNormal>
                     </div>
                     <div style={{justifyContent:'center', width:'100%', textAlign:'center', position:'relative', display:'flex', marginLeft:'-30px', marginTop:'5px'}}>
                     <PoppinsNormal style={{fontWeight:'400'}}>{i18n.t("Esta acción puede tardar unos segundos")}</PoppinsNormal>
                     </div>
                    
                     </> 
                     
                        : myPricesBank.subcapitulos ?
                        myPricesBank.subcapitulos.map((subcapitulo, index) => {
                            
                            return(
                                
                                subcapitulo.chapterId != "SOYUNCAPITULOCONPARTIDASSINCLASIFICAR" ?
                                <CapituloModalMove margin={0} key={index} capitulo={subcapitulo} capituloSelected={capituloSelected} setCapituloSelected={setCapituloSelected}/>
                                :
                                null
                            )
                            })
                            : 
                            <PoppinsNormal>Todavia no tienes capitulos creados</PoppinsNormal>
                            }
                        </div>
                        </div>
                        {loading==false ?
                        <button className='AddProject' style={{left:'40%'}} onClick={()=>{handleConfirm()}}>{i18n.t("Confirmar")}</button>
                            :
                            <div style={{textAlign:'center', marginTop: '50px'}}>
                                <CircularProgress size={24} style={{left:'40%'}} />
                                </div> }
            </div>
        </Modal>
        <ModalConfirmPaste successModal={openModalConfirm} setSuccessModal={setOpenModalConfirm} setOpenPrincipal={setOpenPrincipal} project={project} setOpenSegundo={setOpen}/>
        <ModalSuccesPaste successModal={openFinal} setSuccessModal={setOpenFinal} setOpenPrincipal={setOpenPrincipal} project={project} setOpenSegundo={setOpen}/>
   
        </>
    )
}

ModalPasteSelectionPart.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    project: PropTypes.object.isRequired,
    setOpenPrincipal: PropTypes.func.isRequired,
}
export default ModalPasteSelectionPart;