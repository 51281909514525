import React, { useState} from "react"
import PropTypes from 'prop-types'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
//i18n
import { useHistory } from "react-router-dom"
import i18n from "i18n"
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown"


import { ListBank } from "pages/BancoPrecios/ListBank"

const DropdownSelectBank = props => {
  const [menu, setMenu] = useState(false)
  const history = useHistory()
  return (
    <>
    <React.Fragment>
      <Dropdown
      isOpen={menu}
      toggle={() => setMenu(!menu)}
      className="d-inline-block"
      style={{height:'0px'}}
       >
      <DropdownToggle className="selectDrop"  id='settingb'
          style={{marginTop:'-28px', color:'#414141' , padding:'0px', borderRadius:'0px', paddingBottom:'12px', borderBottom: props.isActive? '2px solid #5984FC': '0px', cursor:'pointer'}}
    >
        <div className="inline">
        <p style={{color:'#414141', textTransform:'none', fontWeight: props.isActive? '600': 'normal', fontSize:'14px'}}>
            {i18n.t("Banco de precios")}
        </p>
        <KeyboardArrowDown style={{color:'#414141', width:'15px'}}/>
        </div>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-end">
        {
          Object.keys(ListBank).map((item, index) => {
            let bank = ListBank[item]
            return (
              <DropdownItem key={index} tag="b"  style={{lineHeight: '20px', cursor:'pointer'}} onClick={()=>{history.push(`/bancodeprecios/referencia/${bank.uri}`)}}>
              {" "}
                <div className="inline">
                {i18n.t("Banco de precios")}
                <img style={{marginTop:'0px', marginLeft:'5px', marginRight:'-10px', marginBottom:'-10px', zIndex:'9'}} src={bank.logo} width={20} height={20}/>
                </div>
            </DropdownItem>
            )
          })
        }

      </DropdownMenu>
    </Dropdown>
    
    </React.Fragment>

  </>
  )
}

DropdownSelectBank.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
  isActive: PropTypes.bool,

}

export default DropdownSelectBank