import React, { useEffect, useRef } from "react"
import PropTypes from 'prop-types'
import i18n from "i18n"
import { PoppinsNormal } from "common/typographies"


const ListPartida = ({items,version, addPartida, handleChangePartida, partida, handleCustom, estoyEnFinal, setPartida, creadaEnFinal, projectInfo, openModalCreatePartida, setOpenModalCreatePartida, setOpenAdd}) => {
  const referenciaLines = useRef(null)

  useEffect(()=>{
  if(partida.name){
    referenciaLines.current.innerText=partida.name
  }
  },[partida])


  return (
    <>
    <React.Fragment> 

              {
                partida.name ?  
                <p
                style={{
                  display: "block",
                  textAlign: "left",
                  marginBottom: "0px",
                  marginRight:'20px'
                }}
              >
                {" "}
                <span style={{maxWidth:'100% !important', color: estoyEnFinal && '#B7B7B7', WebkitTextFillColor: estoyEnFinal && '#b7b7b7'}}
                  ref={referenciaLines}
                  className='partidaNameAutoExpandible'
                  role="textbox"
                  autoComplete="off"
                  name="resumen"
                ></span>
              </p>
                
                  :
                  <div
                  ref={referenciaLines}
                  className='NombreAlign' 
                  style={{paddingTop:'6px',cursor:'pointer', resize:'none', fontSize:'14px', lineHeight:'19.5px', overflow:'hidden', color: '#414141', textAlign:'left'}} 
                  onClick={(e)=>{ if (!e) var e = window.event;
                    e.cancelBubble = true;
                    if (e.stopPropagation) e.stopPropagation()}}
                 
                   disabled='disabled'>
                    <PoppinsNormal style={{cursor:'pointer'}} onClick={()=>{ setOpenModalCreatePartida(true)}} className='textToHover'>
                      {i18n.t('Buscar partida en la base de datos')}
                    </PoppinsNormal>
                    </div>
          
                }

            
             

    </React.Fragment>
   
     </>
  )
}

ListPartida.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
  items: PropTypes.array, 
  addPartida : PropTypes.func,
  handleChangePartida : PropTypes.func,
  partida: PropTypes.object,
  handleCustom: PropTypes.func,
  estoyEnFinal: PropTypes.bool,
  creadaEnFinal: PropTypes.bool,
  projectInfo: PropTypes.object,
  version:PropTypes.object,
  openModalCreatePartida: PropTypes.bool,
  setOpenModalCreatePartida: PropTypes.func,
  setOpenAdd: PropTypes.func,
  setPartida: PropTypes.func
}
export default ListPartida