import React from "react"
import PropTypes from "prop-types"
import TableStructure from "./tableStructure"
import Part from "./part"

const PartTable = ({ parts, imParent, total, capitulo }) => {
  return (
    <TableStructure imParent={imParent} total={total} capitulo={capitulo}>
      {parts.map(part => (
        <Part key={part.partidaId} part={part} />
      ))}
    </TableStructure>
  )
}
PartTable.propTypes = {
  parts: PropTypes.array.isRequired,
  imParent: PropTypes.bool,
  total: PropTypes.float,
  capitulo: PropTypes.object,
}
export default PartTable
