import i18n from "i18n";
import {Modal} from "@mui/material";
import {makeStyles} from "@material-ui/core/styles";
import React, { useState } from "react";
import { useHistory, Link} from 'react-router-dom';
import PropTypes from 'prop-types'


function getModalStyle() {
    const top = 50 ;
    const left = 50;
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };

}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: '550px',
        backgroundColor: 'white',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        borderRadius: 6,
        maxHeight: '500px',
        overflowY: 'auto',
        overflowX:'hidden',
        paddingLeft:'0px',
        paddingRight:'0px'
    },
}));

const SuccessImportTemplate = ({importedTemplateId, setOpen, open, templateInfo}) => {
    const [ modalStyle ] = useState(getModalStyle);
    const classes = useStyles();
    const history = useHistory();
    const goToBudgeting = () => {
        history.push(`/bancodeprecios/template/`+importedTemplateId)
    }
  return(
      <>
    <Modal
        open={open}
        onClose={() => {null}}
    >
        <div style={modalStyle} className={classes.paper}>
        <div id='centerPaco'><img src={'https://media.tribboo.co/images/paquitoListo.png'} style={{width:'175px', height:'175px'}}></img></div>
        <button
            type="button"
            onClick={() => {
            setOpen(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
        >
            <span aria-hidden="true">&times;</span>
        </button>
        <div className="modal-body">
        <div>
            <div>
                <p
                    style={{
                    fontSize: '20px',
                    fontWeight: '500',
                    textAlign: 'center',
                    fontFamily: 'Roboto',
                    }}
                
                >{i18n.t("Está listo!")}</p>
                <p
                    style={{
                        fontStyle: 'normal',
                        fontWeight: '300',
                        fontSize: '14px',
                        lineHeight: '16px',
                        textAlign: 'center',
                        fontFamily: "Helvetica"
                    }}
                >{i18n.t("Explora nuestras funcionalidades disponibles")}
                  <br/>  {i18n.t("con los datos de tu nueva plantilla")}
                </p>
                <div id="" style={{width:'100%', marginBottom:'35px', marginTop:'30px'}}>
                    <button className='AddProject' style={{cursor:'pointer', position:'relative', bottom:'0px', width:'180px', height:'50px', left: '30%'}} onClick={goToBudgeting}>
                        {i18n.t("Plantilla")}
                    </button>
                </div>
                <p className="text-center">
                <Link to="/bancodeprecios/templates"   style={{color:'black', fontSize: '14px',
                           lineHeight: '16px', fontFamily:'Roboto'
                           }}>
                         <span style={{   
                           fontSize: '14px',
                           lineHeight: '16px',
                           fontFamily:'Roboto', cursor:'pointer'
                        }}
                  >{i18n.t("Ir al panel principal")}</span>
                </Link>
                </p>
     
            </div>
            </div>
        </div>
        </div>
    </Modal>
    </>
  )
}
SuccessImportTemplate.propTypes = {
  importedTemplateId: PropTypes.string,
  setOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  templateInfo:PropTypes.object.isRequired
}
export default SuccessImportTemplate