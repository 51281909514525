
export const query = `
    query Query($PK: String!, $SK: String!) {
        getProcess(PK: $PK, SK: $SK) {
            PK
            SK
            _created_at
            _updated_at
            _deleted_at
            process_type
            status
            version
            payload
        }
    }`;

export const subscribe = `
    subscription Subscription($PK: String!, $SK: String!) {
        onUpdateProcess(PK: $PK, SK: $SK) {
            PK
            SK
            _created_at
            _updated_at
            _deleted_at
            process_type
            status
            version
            payload
        }
    }`;
