import React, { useState, useContext } from "react"
import PropTypes from 'prop-types'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
//i18n
import MoreVertIcon from '@mui/icons-material/MoreVert';
import i18n from "i18n"
import TemplatesContext from "context/templates/templatesContext"
import ModalEditTemplate from "./modals/modalEditTemplate"

const DropdownSettingsTemplate = props => {

  const [menu, setMenu] = useState(false)
  const {deleteTemplate, duplicateTemplate} = useContext(TemplatesContext)
  const [open, setOpen] = useState(false)


  return (
    <>
    <React.Fragment>
      {props.imCard==true?
      <Dropdown
      isOpen={menu}
      toggle={() => setMenu(!menu)}
      className="d-inline-block"
      style={{height:'0px'}}
       >
      <DropdownToggle   id='settingb'
          style={{marginTop:'-13px', marginLeft:"305px", color:'#414141' , padding:'0px'}}
      >
        {
        props.template ? props.template.description ?
              <MoreVertIcon className='settingsProject' style={{
                marginTop:'-10px', marginLeft:"0px",color:'#414141',width:'20px', paddingTop:'2px'}}/>  
                :
                <MoreVertIcon className='settingsProject' style={{
                  marginTop:'-10px', marginLeft:"0px",color:'#414141',width:'20px', paddingTop:'2px'}}/>
                  : <MoreVertIcon className='settingsProject' style={{
                    marginTop:'-10px', marginLeft:"0px",color:'#414141',width:'20px', paddingTop:'2px'}}/>
      }
    
      </DropdownToggle>

      <DropdownMenu className="dropdown-menu-end">
        {props.template.accountId == 'tribboo' ?         <DropdownItem 
        onClick={()=>{duplicateTemplate(props.template.templateId, props.template.accountId)}}
        tag="b"  style={{lineHeight: '20px', cursor:'pointer'}}>
          {" "}
         {i18n.t("Duplicar")}
        </DropdownItem>
        : <>
      <DropdownItem tag="b" style={{lineHeight: '20px', cursor:'pointer'}} onClick={async()=>await deleteTemplate(props.template.templateId)}>
          {" "}
         {i18n.t("Eliminar")}
        </DropdownItem>
        <DropdownItem 
        onClick={()=>{duplicateTemplate(props.template.templateId, props.template.accountId)}}
         tag="b"  style={{lineHeight: '20px', cursor:'pointer'}}>
          {" "}
         {i18n.t("Duplicar")}
        </DropdownItem>
        {props.template? 
        <DropdownItem tag="b"  style={{lineHeight: '20px', cursor:'pointer'}} onClick={()=>{setOpen(true)}}>
          {" "}
        {i18n.t("Editar")}
        </DropdownItem> 
        :null }
        </>}

        </DropdownMenu>
      </Dropdown>:null}
          <ModalEditTemplate open={open} setOpen={setOpen} template={props.template}/>
    </React.Fragment>
   
  </>
  )
}

DropdownSettingsTemplate.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
  imCard: PropTypes.bool,
  template: PropTypes.object,

}

export default DropdownSettingsTemplate