import React, { useState } from "react"
import PropTypes from "prop-types"
import { Col, Row } from "reactstrap"
import PartTable from "./PartTable"
import { ChapterPropTypes } from "common/CChapter"
import ChapterHeader from "./ChapterHeader"
import classNames from "classnames"
import i18n from "i18n"

const CollapsableChapter = ({
  chapter,
  showAddPartBtn = false,
  setOpenNewModalPart,
  chapterIndex,
}) => {
  const [showRow, setShowRow] = useState(false)

  const handleCreatePart = () => {
    setOpenNewModalPart(true)
  }
  return (
    <Row
      xs={12}
      style={{ margin: "0px 10px 10px 30px" }}
      aria-label="general-row"
    >
      <Col
        xs={12}
        className="mt-1 rounded-2 backgroundWhite"
        onClick={() => {
          setShowRow(!showRow)
        }}
      >
        {chapter && (
          <ChapterHeader
            title={`${chapterIndex + 1}. ${chapter.name}`}
            chapter={chapter}
            showDownloadIcon
          />
        )}
      </Col>
      <Col
        xs={12}
        className={classNames("mt-1 rounded-2 ", {
          hide: !showRow,
          backgroundLightBlue: true,
        })}
        aria-label="general-Content"
      >
        {chapter.parts.length > 0 && (
          <PartTable parts={chapter.parts} chapterIndex={chapterIndex} />
        )}

        {showAddPartBtn && (
          <div
            className="NombreAlign"
            style={{ left: "5px", justifyContent: "left" }}
            onClick={() => {
              handleCreatePart()
            }}
          >
            <button
              data-amplify-analytics-on="click"
              data-amplify-analytics-name="AddNewPartPartTable"
              className="nuevoBotonCrear4"
              style={{ marginTop: "10px", marginBottom: "10px" }}
            >
              + {i18n.t("Crear nueva partida")}
            </button>
          </div>
        )}
      </Col>
    </Row>
  )
}

CollapsableChapter.propTypes = {
  chapter: ChapterPropTypes,
  showAddPartBtn: PropTypes.bool,
  setOpenNewModalPart: PropTypes.func,
  chapterIndex: PropTypes.number,
}

export default CollapsableChapter
