import React, {useContext, useState} from "react";
import {CircularProgress, Modal} from "@mui/material";
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import Budget2023Context from "context/budget2023/Budget2023Context";
import deleteCap from 'assets/images/budgeting/deleteCap.png'
import i18n from "i18n";
import AuthContext from "context/auth/authContext";

function getModalStyle() {
    const top = 50 ;
    const left = 50;
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: '630px',
        height: '470px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        borderRadius: 6,
        maxHeight: 'calc(100vh - 100px)',
        overflowY: 'auto',
        overflowX:'hidden',
    },
}));

const ModalDecomposition = ({ open, setOpen, partida, functionToDo, project}) => {
    
    // Configuración del modal para añadir contactos
    const [ modalStyle ] = useState(getModalStyle);
    const classes = useStyles();
    const [loading, setLoading] = useState(false)
    const doSomeAction = async () => {
        setLoading(true)
        if(project){
            //consoltar como funciona
        }else{
            await functionToDo(partida)
        }
        setLoading(false)
        setOpen(false)
    }

    return(
        <>
            <Modal open={open} onClose={()=>{setOpen(false)}}>
                <div style={modalStyle} className={classes.paper}>
                    <div id='centerPaco'>
                        <img src={deleteCap} style={{width:'95px', height:'144px'}}/>
                    </div>

                    <div id="DivTitleEliminar2" style={{width:'100%', textAlign:'center', display:'block', marginLeft:'0PX'}}><p id="centerTitleEliminar" style={{marginTop:'40px', fontWeight:'600', fontSize:'16px'}}>
                    {
                    partida.decomposition.length > 0 ?
                    i18n.t("Eliminarás la descomposición de precios de esta partida")
                    :
                    i18n.t("Recuperarás la descomposición de precios de esta partida")
                }
                        </p>
                        </div>
                    
                    <div id="DivTitleEliminar2" style={{width:'100%', textAlign:'center', display:'block', marginLeft:'0px'}}>
                    {i18n.t("¿Quieres continuar?")}
                    </div>
                    <div id="centerButton">
                        <div id='DivTitleEliminar2' style={{marginLeft:'70px', marginBottom:'10px', marginTop:'20px'}}>
                            {loading ? 
                                <CircularProgress size={24}/> 
                                : 
                                <>
                                <button type="button" style={{width: '190px', marginRight: '15px', borderRadius:'6px'}}
                                    className="CancelDel" onClick={()=>{setOpen(false)}}>{i18n.t("Cancelar")}</button>
                                <button type="button" style={{width: '190px', position:'initial', marginTop:'0px', color:'white', backgroundColor:'#BF2929'}}
                                        className="AddProject" id='DeleteButton'  onClick={()=>{doSomeAction()}}>{i18n.t("Aceptar")}</button>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

ModalDecomposition.propTypes = {
    open : PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    partida: PropTypes.object.isRequired,
    functionToDo: PropTypes.func.isRequired,
    project: PropTypes.string
}
export default ModalDecomposition;