import {
    CREATE_PROJECT_SUCCESS,
    ON_CREATE_PROJECT,
    ON_GET_PROJECTS,
    GET_PROJECTS_SUCCESS,
    LOADING_BANKPRICES,
    GET_BANK_PRICES,
    LOADING_SUBCAPS,
    GET_PROJECT_SUCCESS,
    DELETE_PROJECT_SUCCESS
  } from "../types";
  const TemplatesReducer = (state, action) => {
    switch (action.type){
    
      case CREATE_PROJECT_SUCCESS:
        return {
          ...state,
          templates : [...state.templates, action.payload.template],
          loading: false,
          selectedTemplate: action.payload.template
        }
        case "UPDATE_FILE_UPLOADED":
            return {
              ...state,
              fileUploaded: action.payload
            }
        case DELETE_PROJECT_SUCCESS:
            return {
              ...state,
              templates: action.payload,
              loadingTemplates: false
            }
      
      case ON_CREATE_PROJECT:
        return {
          ...state,
          loading: true,
        }
        case 'ON_CREATE_PROJECT2':
            return {
              ...state,
              loadingTemplates: true,
            }
        case GET_PROJECT_SUCCESS:
            return {
              ...state,
              templateInfo: action.payload
            }
      
      case ON_GET_PROJECTS:
        return {
          ...state,
          loadingTemplates: true
        }
        
      case GET_PROJECTS_SUCCESS:
        return {
          ...state,
          loadingTemplates: false,
          loading: false,
          templates: action.payload
        }

        case LOADING_BANKPRICES:
          return {
              ...state,
              loading: true,
              myPricesBank: action.payload.myPricesBank
          }
      case GET_BANK_PRICES:
          for (let capitulo of action.payload){
              state.myPricesBank.subcapitulos.push(capitulo)
          }
          return {
              ...state,
              loading: false
          }
          case 'LOADING_BANKPRICES_2':
              return {
                  ...state,
                  loading: true,
                  myNonSpecifiedPricesBank: action.payload.myPricesBank
              }
          case 'GET_BANK_PRICES_2':
              for (let capitulo of action.payload){
                  state.myNonSpecifiedPricesBank.subcapitulos.push(capitulo)
              }
              return {
                  ...state,
                  loading: false
              }
          
      case LOADING_SUBCAPS:   
          return {
              ...state,
              loadingCaps:true
          }
          case 'LOADING_SUBCAPS_SUCCESS':
            return {
                ...state,
                loadingCaps:false
            }
      case 'ADD_CAPITULO':
          state.myPricesBank.addCapitulo(action.payload.parentId, action.payload.res, action.payload.indexCapitulo)
          return {
              ...state,
          }
      case 'UPDATE_CAPITULO':
          state.myPricesBank.updateCapitulo(action.payload.newData, action.payload.capituloId)
          return {
              ...state,
              updating: false
          }
      case 'SELECCIONAR_AGRUPAR':
          return{
              ...state,
              selectedCategories: action.payload.selectedBudget,
          }
      case 'DEL_CAPITULO':
          state.myPricesBank.remove(action.payload)
          return {
              ...state,
          }
      case 'CHECK_CAPITULOS':
          state.myPricesBank.checkCapitulo(action.payload.capituloId, action.payload.value)
          return {
              ...state,
          }
      case 'UNCHECK_ALL':
          state.myPricesBank.checkCapitulo("root", false)
          return{
              ...state,
              categoriesChecked: [],
              partsChecked: []
          }
      case 'UPDATE_CHAPTERS_LIST':
          return{
              ...state,
              categoriesChecked:action.payload.list
          }
      case 'ADD_PARTIDA':
          state.myPricesBank.addPartida(action.payload.chapterId, action.payload.partidaNueva, action.payload.index)
          return {
              ...state,
          }
      case 'DEL_PARTIDA':
          state.myPricesBank.removePartida(action.payload.capituloId, action.payload.partidaId)
          return {
              ...state,
          }
        case 'UPDATE_PARTS_LIST':
            return{
                ...state,
                partsChecked:action.payload.list
            }
            case 'CHECK_PARTIDA':
                state.myPricesBank.checkPartida(action.payload.capituloId, action.payload.partidaId)
                return {
                    ...state
                }
                case 'ERROR_CAPITULO':
                    return{
                        ...state,
                        selectedPartGroup: [],
                        errorAgrupar: true
                    }
                    case 'SET_GROUP':
                        return{
                            ...state,
                            selectedPartGroup: action.payload.selectedParts,
                            errorAgrupar: false
                        }
                        case 'UPDATE_PARTIDA':
                            state.myPricesBank.updatePartida(action.payload.newData, action.payload.capituloId, action.payload.final)
                            return {
                                ...state,
                            }
                            case 'SET_SELECTED':
                                return {
                                    ...state,
                                    selectedCategories2: action.payload.selectedBudget
                                }
        case 'ON_UPLOAD_TEMPLATE':
            return {
                ...state,
                uploadingTemplate: true
            }
        case 'UPLOAD_TEMPLATE_SUCCESS':
            return {
                ...state,
                uploadingTemplate: false,
                uploadError: false,
                uploadSuccess: true
            }
        case 'CLEAN_UPLOAD_PROCESS':
            return {
                ...state,
                uploadingTemplate: null,
                uploadError: null,
                uploadSuccess: null
            }
            case 'DUPLICATE_TEMPLATE':
                let auxTemplates = state.templates
                auxTemplates.push(action.payload)
                return {
                    ...state,
                    templates: auxTemplates
                }
                
     
      default:
        return state;
    }
  }
  export default TemplatesReducer;