import React, { useEffect , useContext, useRef} from 'react'
import { Modal, CircularProgress } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types'
import { useState } from 'react';
import Budget2023Context from 'context/budget2023/Budget2023Context';
import i18n from 'i18n';
import { HelveticaInputBlue } from 'common/inputs';
import { useParams } from 'react-router-dom';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import ProjectContext from 'context/projects/projectContext';
import closeBud from 'assets/images/budgeting/CloseBud.png'

function getModalStyle() {
    const top = 50 ;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: '630px',
        height: '470px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        borderRadius: 6,
        maxHeight: 'calc(100vh - 100px)',
        overflowY: 'auto',
        overflowX:'hidden',
    },
}));

const ModalCloseVersion = ({open, setOpen}) => {
  
    const {projectId} = useParams()
    const {projectInfo, getProjectVersions, getProject} = useContext(ProjectContext)
    const [ modalStyle ] = useState(getModalStyle);
    const {closeBudgetVersion} = useContext(Budget2023Context)
    const classes = useStyles();
    const [nombre, setNombre] = useState('')
    const [loading, setLoading] = useState(false)
    const [saved, setSaved] = useState(false)
    useEffect(() => {
        if(!projectInfo){
            getProject(projectId)
        }
    }, [])
    const handleChangeNombre = (e) => {
        referencia.current.style.border=''
        setNombre(e.target.value)
    }
    const referencia = useRef(null)
    const handleCloseVersion = async() => {
        if(nombre!==''){
            setLoading(true)
            await closeBudgetVersion({name: nombre, projectId: projectId})
            await getProjectVersions(projectId, projectInfo.createdBy)
            setLoading(false)
            setSaved(true)
     }else{
        referencia.current.style.border='1px solid #c90505'
     }
    }
    return(
        <Modal open={open} onClose={() => null}>
            <div style={modalStyle} className={classes.paper}>
                <div id='principalOptions' style={{marginTop:'60px', marginBottom:'15px'}}>
                    <div>
                       {!loading &&  <CloseOutlined onClick={()=>{if(saved){setSaved(false);}setOpen(false)}} style={{position:'absolute', right:'20px', top:'20px', cursor:'pointer'}}/> }
                    </div>
                    {!saved ? <>
                    <p id='titles' style={{textAlign:'center'}}>{i18n.t("¡Estás apunto de cerrar este presupuesto como versión!")}</p>
                    <p style={{fontWeight:'300', textAlign:'center'}}>{i18n.t("Indica un nombre para encontrarla fácilmente.")}</p>
                    <HelveticaInputBlue ref={referencia} style={{width:'350px', marginTop:'50px', marginLeft:'110px'}} onChange={(e)=>{handleChangeNombre(e)}}
                        placeholder={i18n.t("Nombre de la versión")}
                        value={nombre}
                    />
                   
                     {loading==false ? 
                      <div style={{width:'100%', justifyContent:'center', display:'flex', position:'relative', marginTop:'60px'}}>                      
                        <div onClick={()=>{handleCloseVersion()}} id='downloadButton' style={{justifyContent:'center', cursor:'pointer', display:'flex', position:'relative', textAlign:'center', width:'190px', height:'48px', paddingTop:'12px', fontSize:'16px', fontWeight:'500'}}>{i18n.t('Guardar')}</div>
                     </div>
                    :
                     <div style={{width:'100%', justifyContent:'center', textAlign:'center', position:'relative', display:'block', marginTop:'60px'}}>
                        <CircularProgress size={24} style={{marginBottom:'5px'}}/>
                        <p id='titles' style={{textAlign:'center'}}>{i18n.t("Guardando versión...")}</p>
                        <p style={{fontWeight:'300', textAlign:'center'}}>{i18n.t("Este proceso puede tardar unos segundos, por favor no cierre la pestaña")}</p>
                    </div>
                     }
                    
                    </>
                    :
                    <>
                    <div style={{width:'100%', justifyContent:'center', display:'flex', position:'relative'}}>
                         <img src={closeBud} style={{width:'100px', height:'100px', marginTop:'30px', marginBottom:'30px'}}/>
                    </div>
                      
                    <p id='titles' style={{textAlign:'center'}}>{i18n.t("¡Versión cerrada correctamente!")}</p>

                    <div style={{width:'100%', justifyContent:'center', display:'flex', position:'relative', marginTop:'60px'}}>       
                        <div onClick={()=>{if(saved){setSaved(false);}setOpen(false)}} id='downloadButton' style={{justifyContent:'center', cursor:'pointer', display:'flex', position:'relative', textAlign:'center', width:'190px', height:'48px', paddingTop:'12px', fontSize:'16px', fontWeight:'500'}}>{i18n.t('Aceptar')}</div>
                    </div>
                    </>
                    }
                </div>
            </div>
        </Modal>
    )
}

ModalCloseVersion.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
 
   
}
export default ModalCloseVersion;