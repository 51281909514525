import React, { useState, useContext, useEffect } from "react"
import { Modal, Grid } from "@mui/material"
import { makeStyles } from "@material-ui/core/styles"
import SideBarContent from "./SideBarContent"
import { HelveticaBold } from "common/typographies.js"
import LeftGridContent from "./LeftGridContent"
import i18n from "i18n"
import RightGridContent from "./RightGridContent"
import PropTypes from "prop-types"
import CloseIcon from "@mui/icons-material/Close"
import AuthContext from "context/auth/authContext"
import BankPricesContext from "context/bankPrices/bankPricesContext"
import BankPricesReferenceContext from "context/referenceBankPrices/BankPricesReferenceContext"
import useNewSearch from "hooks/newSearch"
import LayoutBancoRef from "./selectPartType/layoutBancoRef"
import PrincipalChapters from "./cargaCapitulos/index.js"
import { YellowButton } from "common/buttons"
import { CircularProgress } from "@mui/material"
import { PoppinsNormal } from "common/typographies.js"
import ProjectContext from "context/projects/projectContext"
import { Input4Search } from "common/inputs"
import { SearchOutlined } from "@mui/icons-material"
import Snackbar from "@mui/material/Snackbar"
import MuiAlert from "@mui/material/Alert"
import Budget2023Context from "context/budget2023/Budget2023Context"
import { isNil } from "lodash"
import ExtrasAndContradictionsContext from "context/extrasAndContradictions/ExtrasAndContradictionsContext"

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    maxWidth: "1700px",
    width: "98%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: 6,
    height: "calc(100vh - 60px)",
    overflowY: "hidden",
    overflowX: "hidden",
    backgroundColor: "#414141",
  },
}))

const NewModalPartida = ({
  open,
  setOpen,
  chapterId,
  partidaInfo,
  bancoDePrecios,
  srcPage,
}) => {
  const classes = useStyles()
  const [modalStyle] = useState(getModalStyle)
  const [bankSelected, setBankSelected] = useState("Manual part")
  const [referencia, setReferencia] = useState("")
  const [tipo, setTipo] = useState("")
  const [chaptersToShow, setChaptersToShow] = useState([])
  const [disabled, setDisabled] = useState(true)
  const [loadingButton, setLoadingButton] = useState(false)
  const [doTheAction, setDoTheAction] = useState(false)
  const { projectInfo } = useContext(ProjectContext)
  const { user, getUser } = useContext(AuthContext)
  const {
    bc3LinkData,
    setBc3LinkData,
    setChapterIdSelectedFromBc3Link,
    setIsContradictingPart,
  } = useContext(Budget2023Context)
  const {
    getMyBankPrices,
    getMyBankPricesWithoutClassify,
    myPricesBank,
    loading,
    getSearchChapters,
  } = useContext(
    bankSelected == "User database"
      ? BankPricesContext
      : BankPricesReferenceContext
  )
  const { newModalPart } = useContext(ExtrasAndContradictionsContext)
  const [cont, setCont] = useState(0)
  const [estoyEnBusqueda, setEstoyEnBusqueda] = useState(false)
  const [partidaState, setPartidaState] = useState({})
  const [imInUpdate, setImInUpdate] = useState(false)

  useEffect(() => {
    if (!isNil(newModalPart?.partToEdit)) {
      setImInUpdate(true)
      setPartidaState({
        ...partidaInfo,
      })
    }else{
      setPartidaState({
        chapterId: chapterId,
        margin: 0,
        comeFrom: user.sub,
        projectId: projectInfo?.projectId,
        accountId: user.sub,
        price: 0,
        clientPrice: 0,
        quantity: 0,
        unity: "pa",
        description: "",
        name: "",
        precioRef: 0,
        refPrice: 0,
        comment: "",
      })
      setImInUpdate(false)
      setDetails([])
    }
  }, [newModalPart?.partToEdit])

  useEffect(() => {
    const data = isNil(partidaInfo) ? partidaState : partidaInfo
    if (!isNil(bc3LinkData)) {
      setPartidaState({
        ...data,
        decomposition: bc3LinkData.decomposition,
        price: bc3LinkData.price,
        description: bc3LinkData.description,
        code: bc3LinkData.code,
        name: bc3LinkData.name,
        unity: bc3LinkData.unity,
        bc3file_url: bc3LinkData.bc3file_url,
        margin: partidaState.margin,
      })
    }else{
      setPartidaState({
        ...data,
        decomposition: [],
        price: 0,
        description: "",
        code: "",
        name: "",
        unity: "pa",
        bc3file_url: "",
        margin: 0,
      })
    }
  }, [bc3LinkData])

  useEffect(() => {
    if (!user) {
      getUser()
    } else {
      if (partidaInfo) {
        let priceDetailsSum = 0
        let PriceMaterial = 0
        let PriceManoDeObra = 0
        let priceOther = 0

        partidaInfo.decomposition.forEach(detail => {
          priceDetailsSum += parseFloat(detail.price * detail.quantity)

          switch (detail.type) {
            case 1:
              PriceManoDeObra += parseFloat(detail.price * detail.quantity)
              break
            case 3:
              PriceMaterial += parseFloat(detail.price * detail.quantity)
              break
            default:
              priceOther += parseFloat(detail.price * detail.quantity)
              break
          }
        })

        let margin =
          partidaInfo.costeGeneral +
          partidaInfo.costeIndirecto +
          partidaInfo.industrialProfit

        setPartidaState({
          ...partidaInfo,
          margin: margin,
          priceManoDeObra: PriceManoDeObra,
          priceMaterial: PriceMaterial,
          priceOther: priceOther,
          priceDetailsSum: priceDetailsSum,
        })
        setImInUpdate(!isNil(partidaInfo.partidaId))
      } else {
        setPartidaState({
          chapterId: chapterId,
          margin: 0,
          comeFrom: user.sub,
          projectId: projectInfo?.projectId,
          accountId: user.sub,
          price: 0,
          clientPrice: 0,
          quantity: 0,
          unity: "pa",
          description: "",
          name: "",
          precioRef: 0,
          refPrice: 0,
          comment: "",
        })
        setImInUpdate(false)
      }
    }
  }, [user])

  useEffect(() => {
    if (user) {
      if (estoyEnBusqueda) {
        if (bankSelected == "User database") {
          handleSearch2()
        } else {
          if (tipo.length > 0) {
            handleSearch2()
          }
        }
      } else {
        if (bankSelected == "User database") {
          getMyBankPrices(user.sub)
          getMyBankPricesWithoutClassify(user.sub)
          setCont(1)
        } else {
          if (tipo.length > 0) {
            if (bankSelected == "Cype database") {
              getMyBankPrices(tipo, "cype")
              setCont(1)
            } else if (bankSelected == "Bedec database") {
              getMyBankPrices(tipo, "bedec")
              setCont(1)
            } else if (bankSelected == "Preoc database") {
              getMyBankPrices(tipo, "preoc")
              setCont(1)
            }
          }
        }
      }
    }
  }, [tipo, bankSelected])
  useEffect(() => {
    if (
      myPricesBank.subcapitulos.length > 0 &&
      bankSelected == "User database"
    ) {
      if (estoyEnBusqueda) {
        if (results.length > 0) {
          setChaptersToShow([myPricesBank])
        } else {
          setChaptersToShow([])
        }
      } else {
        setChaptersToShow(JSON.parse(JSON.stringify([myPricesBank])))
      }
    }
    if (
      myPricesBank.subcapitulos.length > 0 &&
      bankSelected == "Cype database"
    ) {
      if (estoyEnBusqueda) {
        if (results.length > 0) {
          setChaptersToShow([myPricesBank])
        } else {
          setChaptersToShow([])
        }
      } else {
        setChaptersToShow(JSON.parse(JSON.stringify([myPricesBank])))
      }
    }
    if (
      myPricesBank.subcapitulos.length > 0 &&
      bankSelected == "Bedec database"
    ) {
      if (estoyEnBusqueda) {
        if (results.length > 0) {
          setChaptersToShow([myPricesBank])
        } else {
          setChaptersToShow([])
        }
      } else {
        setChaptersToShow(JSON.parse(JSON.stringify([myPricesBank])))
      }
    }
    if (
      myPricesBank.subcapitulos.length > 0 &&
      bankSelected == "Preoc database"
    ) {
      if (estoyEnBusqueda) {
        if (results.length > 0) {
          setChaptersToShow([myPricesBank])
        } else {
          setChaptersToShow([])
        }
      } else {
        setChaptersToShow(JSON.parse(JSON.stringify([myPricesBank])))
      }
    }
    if (myPricesBank.subcapitulos.length == 0) {
      setChaptersToShow([])
    }
  }, [myPricesBank.subcapitulos.length, bankSelected, tipo, estoyEnBusqueda])

  const { results, loadingSearch, getResults } = useNewSearch()
  const [word, setWord] = useState("")
  const [details, setDetails] = useState([])
  const [counter, setCounter] = useState(0)
  const [interval, setInterval2] = useState(null)
  const [busca, setBusca] = useState(false)

  const handleSearch = async e => {
    if (e.target.value == "") {
      setEstoyEnBusqueda(false)
      setWord("")
      setInterval2(false)
      setCounter(0)
      setBusca(false)
      if (bankSelected == "User database") {
        getMyBankPrices(user.sub)
        setCont(1)
      } else {
        if (tipo.length > 0) {
          if (bankSelected == "Cype database") {
            await getMyBankPrices(tipo, "cype")
            setCont(1)
          } else if (bankSelected == "Bedec database") {
            await getMyBankPrices(tipo, "bedec")
            setCont(1)
          } else if (bankSelected == "Preoc database") {
            await getMyBankPrices(tipo, "preoc")
            setCont(1)
          }
        }
      }
    } else {
      if (e.target.value.length >= 3) {
        setInterval2(true)
        setCounter(0)
        setWord(e.target.value)
      } else {
        setWord(e.target.value)
      }
    }
  }

  useEffect(() => {
    if (interval) {
      const interval = setInterval(() => {
        setCounter(counter + 1)
      }, 1000)
      if (counter == 2) {
        setBusca(true)
        setInterval2(false)
        setCounter(0)
      }
      return () => clearInterval(interval)
    }
  }, [counter, interval])

  useEffect(() => {
    async function fetchData() {
      if (bankSelected == "User database" && busca) {
        const resultados = getSearchChapters(
          await getResults(word, "user-database")
        )
        setEstoyEnBusqueda(true)
        setInterval2(false)
        setCounter(0)
        setBusca(false)
        if (resultados.length == 0) {
          setChaptersToShow([])
        }
      } else if (bankSelected == "Cype database" && busca) {
        const resultados = getSearchChapters(
          await getResults(word, "cype", tipo)
        )
        setEstoyEnBusqueda(true)
        setInterval2(false)
        setCounter(0)
        setBusca(false)
        if (resultados.length == 0) {
          setChaptersToShow([])
        }
      } else if (bankSelected == "Bedec database" && busca) {
        const resultados = getSearchChapters(
          await getResults(word, "bedec", tipo)
        )
        setEstoyEnBusqueda(true)
        setInterval2(false)
        setCounter(0)
        setBusca(false)
        if (resultados.length == 0) {
          setChaptersToShow([])
        }
      } else if (bankSelected == "Preoc database" && busca) {
        const resultados = getSearchChapters(
          await getResults(word, "preoc", tipo)
        )
        setEstoyEnBusqueda(true)
        setInterval2(false)
        setCounter(0)
        setBusca(false)
        if (resultados.length == 0) {
          setChaptersToShow([])
        }
      }
    }
    if (busca) {
      fetchData()
    }
  }, [busca])

  const handleSearch2 = async () => {
    if (word.length >= 3) {
      if (bankSelected == "User database") {
        getSearchChapters(await getResults(word, "user-database"))
        if (results.length == 0) {
          setChaptersToShow([])
        }
      } else if (bankSelected == "Cype database") {
        await getSearchChapters(await getResults(word, "cype", tipo))
        if (results.length == 0) {
          setChaptersToShow([])
        }
      } else if (bankSelected == "Bedec database") {
        getSearchChapters(await getResults(word, "bedec", tipo))
        if (results.length == 0) {
          setChaptersToShow([])
        }
        setEstoyEnBusqueda(true)
      } else if (bankSelected == "Preoc database") {
        getSearchChapters(await getResults(word, "preoc", tipo))
        if (results.length == 0) {
          setChaptersToShow([])
        }
        setEstoyEnBusqueda(true)
      }
    }
  }

  const referenciaObject = {
    referenciaName: React.createRef(),
    referenciaDescripcion: React.createRef(),
  }

  const [errorMessage, setErrorMessage] = useState("")

  const [openSnack, setOpenSnack] = React.useState(false)
  const [openSnackIA, setOpenSnackIA] = React.useState(false)

  const handleClick = () => {
    setOpenSnack(true)
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }

    setOpenSnack(false)
  }
  
  const NO_REFRESH = false

  return (
    <Modal
      open={open}
      onClose={() => {
        setBc3LinkData(null)
        setIsContradictingPart(false)
        setChapterIdSelectedFromBc3Link(null)
        setOpen(false, NO_REFRESH)
      }}
    >
      <div style={modalStyle} className={classes.paper}>
        <div style={{ height: "100%", width: "100%", display: "flex" }}>
          {!imInUpdate && !bancoDePrecios && (
            <SideBarContent
              bankSelected={bankSelected}
              setBankSelected={setBankSelected}
              setReferencia={setReferencia}
              setTipo={setTipo}
              bancoDePrecios={bancoDePrecios}
            />
          )}
          <div style={{ height: "100%", width: "100%", padding: "10px" }}>
            <HelveticaBold
              style={{
                color: "#FFFFFF",
                fontSize: "14px",
                marginLeft: "10px",
                marginTop: "0px",
                marginBottom: "14px",
              }}
            >
              {bankSelected
                .substring(0, bankSelected.indexOf(" "))
                .toUpperCase()}
            </HelveticaBold>
            <CloseIcon
              id="cerrar"
              style={{ color: "white", marginTop: "-35px", right: "0px" }}
              onClick={() => {
                setBc3LinkData(null)
                setIsContradictingPart(false)
                setOpen(false, NO_REFRESH)
                setDetails([])
              }}
            ></CloseIcon>
            <div
              style={{
                height: "95%",
                backgroundColor: "white",
                borderRadius: "10px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  justifyContent: "center",
                  position: "relative",
                  textAlign: "center",
                  marginTop: "20px",
                }}
              ></div>

              {bankSelected === "Cype database" ||
              bankSelected == "Bedec database" ||
              bankSelected == "Preoc database" ||
              bankSelected === "User database" ? (
                <>
                  <div
                    style={{
                      width: "100%",
                      borderTopLeftRadius: "6px",
                      borderTopRightRadius: "6px",
                      background: "#EFEFEF",
                      position: "relative",
                      display: "flex",
                      height: "45px",
                    }}
                  >
                    <Input4Search
                      style={{
                        width: "30%",
                        fontSize: "14px",
                        height: "35px",
                        marginTop: "5px",
                        paddingLeft: "35px",
                        borderRadius: "50px",
                        marginLeft: "20px",
                      }}
                      onChange={e => {
                        handleSearch(e)
                      }}
                      value={word}
                      placeholder={i18n.t(
                        "Introduce palabras clave para buscar partidas..."
                      )}
                    />
                    <SearchOutlined
                      style={{
                        position: "absolute",
                        left: "30px",
                        top: "13px",
                        fontSize: "20px",
                        color: "#AFAFB0",
                      }}
                    />
                  </div>
                  <LayoutBancoRef
                    referencia={referencia}
                    tipo={tipo}
                    setTipo={setTipo}
                  />
                </>
              ) : null}
              {bankSelected === "Tribboo database" ? (
                <PoppinsNormal
                  style={{ textAlign: "center", paddingTop: "30px" }}
                >
                  {i18n.t(
                    "No hay ninguna base de datos de partidas de Planhopper disponible en este momento."
                  )}
                </PoppinsNormal>
              ) : bankSelected === "Manual part" ? (
                <>
                  <Snackbar
                    open={openSnack}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    style={{ left: "0px", right: "0px", position: "relative" }}
                  >
                    <Alert
                      onClose={handleClose}
                      severity="error"
                      sx={{ width: "100%", position: "absolute" }}
                    >
                      {errorMessage}
                    </Alert>
                  </Snackbar>
                  <Snackbar
                    open={openSnackIA}
                    onClose={() => {
                      setOpenSnackIA(false)
                    }}
                    style={{ left: "0px", right: "0px", position: "relative" }}
                  >
                    <Alert
                      onClose={() => {
                        setOpenSnackIA(false)
                      }}
                      severity="warning"
                      sx={{ width: "100%", position: "absolute" }}
                    >
                      Generando partida...
                    </Alert>
                  </Snackbar>

                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    style={{
                      height: "100%",
                      minHeight: "500px",
                      backgroundColor: "white",
                      borderRadius: "10px",
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{ borderRight: "1px solid #C4C4C4" }}
                    >
                      <LeftGridContent
                        bancoDePrecios={bancoDePrecios}
                        setOpenModalGeneral={setOpen}
                        setOpenSnackIA={setOpenSnackIA}
                        handleClick={handleClick}
                        details={details}
                        setErrorMessage={setErrorMessage}
                        setDetails={setDetails}
                        referenciaObject={referenciaObject}
                        partidaState={partidaState}
                        setPartidaState={setPartidaState}
                        imInUpdate={imInUpdate}
                        partidaInfo={partidaInfo}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <RightGridContent
                        bancoDePrecios={bancoDePrecios}
                        details={details}
                        setDetails={setDetails}
                        setErrorMessage={setErrorMessage}
                        referenciaObject={referenciaObject}
                        partidaState={partidaState}
                        setPartidaState={setPartidaState}
                        handleClick={handleClick}
                        imInUpdate={imInUpdate}
                        setOpen={setOpen}
                        srcPage={srcPage}
                      />
                    </Grid>
                  </Grid>
                </>
              ) : (
                <>
                  <PrincipalChapters
                    estoyEnBusqueda={estoyEnBusqueda}
                    chaptersToShow={chaptersToShow}
                    setChaptersToShow={setChaptersToShow}
                    loadingTree={loading}
                    bankSelected={bankSelected}
                    tipo={tipo}
                    disabled={disabled}
                    setDisabled={setDisabled}
                    loadingButton={loadingButton}
                    setLoadingButton={setLoadingButton}
                    doTheAction={doTheAction}
                    setDoTheAction={setDoTheAction}
                    chapterId={chapterId}
                    projectInfo={projectInfo}
                    setOpen={setOpen}
                    loadingSearch={loadingSearch}
                  />

                  <div
                    style={{
                      width: "60%",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    {loadingButton ? (
                      <CircularProgress
                        size={24}
                        style={{
                          position: "fixed",
                          bottom: "25px",
                          right: "40px",
                        }}
                      />
                    ) : (
                      <YellowButton
                        data-amplify-analytics-on="click"
                        data-amplify-analytics-name="PartCreated"
                        data-amplify-analytics-attrs={{
                          chapterId: chapterId,
                          projectId: projectInfo?.projectId,
                          userId: user?.sub,
                        }}
                        onClick={() => {
                          if (!disabled) {
                            setDoTheAction(true)
                          }
                        }}
                        style={{
                          position: "fixed",
                          bottom: "25px",
                          right: "40px",
                          width: "150px",
                          height: "34px",
                          cursor: disabled ? "default" : "pointer",
                          backgroundColor: disabled ? "#AFAFB0" : "#F1CA2C",
                        }}
                      >
                        {i18n.t("Añadir partida")}
                      </YellowButton>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
NewModalPartida.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  chapterId: PropTypes.string.isRequired,
  partidaInfo: PropTypes.object,
  bancoDePrecios: PropTypes.string,
  srcPage: PropTypes.string,
}
export default NewModalPartida
