import React from 'react'
import { Modal } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types'
import { useState } from 'react';
import i18n from 'i18n';
import { YellowButton } from 'common/buttons';

function getModalStyle() {
    const top = 50 ;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: '630px',
        height: '490px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        borderRadius: 6,
        maxHeight: 'calc(100vh - 100px)',
        overflowY: 'auto',
        overflowX:'hidden',
    },
}));

const ModalPreferencias = ({open, setOpen, preferencias, setPreferencias, soyFinal}) => {

    const handleCheckBox = (e) => {
        if(e.target.checked==false){
            if(e.target.value=='0'){
                let aux={basededatos:false, clientPrice:preferencias.clientPrice, objetivo:preferencias.objetivo, descuento:preferencias.descuento, procedencia: preferencias.procedencia, precioCoste: preferencias.precioCoste}
                setPreferencias(aux)
            }else if(e.target.value=='1'){
                let aux={basededatos:preferencias.basededatos, clientPrice:false, objetivo:preferencias.objetivo, descuento:preferencias.descuento, procedencia: preferencias.procedencia, precioCoste: preferencias.precioCoste}
                setPreferencias(aux)
            }else if(e.target.value=='2'){
                let aux={basededatos:preferencias.basededatos, clientPrice:preferencias.clientPrice, descuento:false, objetivo:preferencias.objetivo, procedencia: preferencias.procedencia, precioCoste: preferencias.precioCoste}
                setPreferencias(aux)
            }else if(e.target.value=='3'){
                let aux={basededatos:preferencias.basededatos, clientPrice:preferencias.clientPrice, descuento:preferencias.descuento, objetivo:false, procedencia: preferencias.procedencia, precioCoste: preferencias.precioCoste}
                setPreferencias(aux)
            }else if(e.target.value=='4'){
                let aux={basededatos:preferencias.basededatos, clientPrice:preferencias.clientPrice, descuento:preferencias.descuento, objetivo:preferencias.objetivo, procedencia: false, precioCoste: preferencias.precioCoste}
                setPreferencias(aux)
            }else{
                let aux={basededatos:preferencias.basededatos, clientPrice:preferencias.clientPrice, descuento:preferencias.descuento, objetivo:preferencias.objetivo, procedencia: preferencias.procedencia, precioCoste: false}
                setPreferencias(aux)
            }
        }
        else{
            if(e.target.value=='0'){
                let aux={basededatos:true, clientPrice:preferencias.clientPrice, objetivo:preferencias.objetivo, descuento:preferencias.descuento, procedencia: preferencias.procedencia, precioCoste: preferencias.precioCoste}
                setPreferencias(aux)
            }else if(e.target.value=='1'){
                let aux={basededatos:preferencias.basededatos, clientPrice:true, objetivo:preferencias.objetivo, descuento:preferencias.descuento, procedencia: preferencias.procedencia, precioCoste: preferencias.precioCoste}
                setPreferencias(aux)
            }else if(e.target.value=='2'){
                let aux={basededatos:preferencias.basededatos, clientPrice:preferencias.clientPrice, descuento:true, objetivo:preferencias.objetivo, procedencia: preferencias.procedencia, precioCoste: preferencias.precioCoste}
                setPreferencias(aux)
            }else if(e.target.value=='3'){
                let aux={basededatos:preferencias.basededatos, clientPrice:preferencias.clientPrice, descuento:preferencias.descuento, objetivo:true, procedencia: preferencias.procedencia, precioCoste: preferencias.precioCoste}
                setPreferencias(aux)
            }else if(e.target.value=='4'){
                let aux={basededatos:preferencias.basededatos, clientPrice:preferencias.clientPrice, descuento:preferencias.descuento, objetivo:preferencias.objetivo, procedencia: true, precioCoste: preferencias.precioCoste}
                setPreferencias(aux)
            }else{
                let aux={basededatos:preferencias.basededatos, clientPrice:preferencias.clientPrice, descuento:preferencias.descuento, objetivo:preferencias.objetivo, procedencia: preferencias.procedencia, precioCoste: true}
                setPreferencias(aux)
            }
            }
    }
    const [ modalStyle ] = useState(getModalStyle);
    const classes = useStyles();
    return(
        <Modal open={open} onClose={() => null}>
            <div style={modalStyle} className={classes.paper}>
                <div id='principalOptions' style={{marginTop:'30px', marginBottom:'15px'}}>
                    <p id='titles' style={{textAlign:'center'}}>{i18n.t("Visibilidad de presupuesto")}</p>
                    <p style={{fontWeight:'300', textAlign:'center'}}>{i18n.t("Customiza tus preferencias de visualización. Selecciona que opciones deseas ver en el presupuesto.")}</p>
                    
                    <>
                    <div className='inline' style={{marginTop:'45px', marginBottom:'12px'}}>
                    <p style={{fontWeight:'300', fontFamily:"Helvetica"}}>{i18n.t("Precio de coste")}</p>

                    <label className="switch" style={{marginBottom:'0px'}}>
                    <input onChange={handleCheckBox} className="form-check-input" type="checkbox" value="5" id="flexCheckDefault"
                        style={{marginRight:'10px'}} checked={preferencias.precioCoste} ></input>
                    <span className="slider round"></span>
                    </label>
                 
                    </div>
                    <div className='separator'></div>
                     <div className='inline' style={{marginTop:'10px', marginBottom:'12px'}}>
                    <p style={{fontWeight:'300', fontFamily:"Helvetica"}}>{i18n.t("Márgen de partida")}</p>

                    <label className="switch" style={{marginBottom:'0px'}}>
                    <input onChange={handleCheckBox} className="form-check-input" type="checkbox" value="0" id="flexCheckDefault"
                        style={{marginRight:'10px'}} checked={preferencias.basededatos} ></input>
                    <span className="slider round"></span>
                    </label>
                 
                    </div>
                    <div className='separator'></div>
                    <div className='inline' style={{marginTop:'10px',  marginBottom:'12px'}}>
                    <p style={{fontWeight:'300', fontFamily:"Helvetica"}}>{i18n.t("Precio cliente")}</p>
                    <label className="switch" style={{marginBottom:'0px'}}>
                    <input onChange={handleCheckBox} className="form-check-input" type="checkbox" value="1" id="flexCheckDefault"
                        style={{marginRight:'10px'}} checked={preferencias.clientPrice} ></input>
                    <span className="slider round"></span>
                    </label>
                 
                    </div>
                    <div className='separator'></div>
                    <div className='inline' style={{marginTop:'10px',  marginBottom:'12px'}}>
                    <p style={{fontWeight:'300', fontFamily:"Helvetica"}}>{i18n.t("Descuentos")}</p>
                                   <label className="switch" style={{marginBottom:'0px'}}>
                                   <input onChange={handleCheckBox} className="form-check-input" type="checkbox" value="2" id="flexCheckDefault"
                        style={{marginRight:'10px'}} checked={preferencias.descuento} ></input>
                    <span className="slider round"></span>
                    </label>
                    </div>


                    <div className='separator'></div>
                    <div className='inline' style={{marginTop:'10px',  marginBottom:'12px'}}>
                    <p style={{fontWeight:'300', fontFamily:"Helvetica"}}>{i18n.t("Mostrar procedencia")}</p>
                    <label className="switch" style={{marginBottom:'0px'}}>
                    <input onChange={handleCheckBox} className="form-check-input" type="checkbox" value="4" id="flexCheckDefault"
                        style={{marginRight:'10px'}} checked={preferencias.procedencia} ></input>
                    <span className="slider round"></span>
                    </label>
                 
                    </div>
                    </>
                    
                    <div style={{width:'100%', justifyContent:'center', display:'flex', position:'relative', marginTop:'30px'}}>
                    <YellowButton onClick={()=>{setOpen(false)}} style={{justifyContent:'center', cursor:'pointer', display:'flex', position:'relative', textAlign:'center', width:'190px', height:'48px', paddingTop:'15px', fontSize:'16px', fontWeight:'500'}}>{i18n.t('Guardar')}</YellowButton>

                    </div>
                    
                </div>
            </div>
        </Modal>
    )
}

ModalPreferencias.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    preferencias: PropTypes.object.isRequired,
    setPreferencias: PropTypes.func.isRequired,
    soyFinal: PropTypes.bool
   
}
export default ModalPreferencias;