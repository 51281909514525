
import React from 'react'
import { Modal } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types'
import { useState } from 'react';
import i18n from 'i18n';
import CloseIcon from "@mui/icons-material/Close";
import { HelveticaBold, HelveticaLight } from 'common/typographies';
import addTribbooImage from 'assets/images/budgeting/1.png'
import addDesktopImage from 'assets/images/budgeting/Download.png'
import ModalImportFile4Act from './ModalImportFile4Act';
import ModalSelectTribbooFiles from './ModalSelectTribbooFiles';


function getModalStyle() {
    const top = 50 ;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: '485px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        borderRadius: 6,
        maxHeight: '550px',
        overflowY: 'auto',
        overflowX:'hidden',
    },
}));


const AddFilesModal = ({open, actaValues, setActaValues, setOpen, selectedFiles, setSelectedFiles, index}) => {

    const [ modalStyle ] = useState(getModalStyle);
    const classes = useStyles();
    const [openFileModal, setOpenFileModal] = useState(false)
    const [openTribbooModal, setOpenTribbooModal] = useState(false)
    
   
    return(
        <>
        <Modal open={open} onClose={() => null}>
            <div style={modalStyle} className={classes.paper}>
                <div id='principalOptions' style={{marginTop:'20px', marginBottom:'15px', textAlign:'left'}}>
                    <CloseIcon id="cerrar" style={{marginTop:'-15px', right:'-10px'}} onClick={()=>{setOpen(false)}}></CloseIcon>
                    <div style={{display:'flex', width:'100%', justifyContent:'center', textAlign:'center', alignItems:'center', marginBottom:'20px'}}>
                        <HelveticaBold style={{fontSize:'18px', color:'#414141', fontWeight:'700'}}>{i18n.t("Subir archivos")}</HelveticaBold>
                    </div>

                    <div className="CardProjects" style={{ cursor:'pointer'}} onClick={()=>{setOpenFileModal(true)}} >
                        <div id="CardContent">
                            <div className="ImgProyect" style={{marginLeft:'0px'}}>
                                <span className="avatar-title rounded-circle bg-light text-danger font-size-16">
                                    <img src={addTribbooImage}  height="70px" width='80px' />
                                </span>
                            </div>
                            <div id="CenterContentProyectCard" style={{marginLeft: '105px', marginRight:'0px'}} >
                                <div className="flex-grow-1 overflow-hidden" style={{marginRight:'-10px'}}>
                                    <div id="AddProyecto">
                                    <HelveticaBold style={{fontSize:'18px', color:'#414141', fontWeight:'700'}}>{i18n.t("Desde el ordenador")}</HelveticaBold>
                                    </div>
                                    <HelveticaLight style={{fontSize:'14px', fontWeight:'300'}}>{i18n.t('Sube archivos que se encuentren en tu ordenador o móvil directamente')}</HelveticaLight>
                                </div>
                            </div>
                        </div>
                     </div>

                     <div className="CardProjects" style={{marginTop:'0px'}} onClick={()=>{setOpenTribbooModal(true)}}>
                            <div id="CardContent" style={{cursor:'pointer'}} 
                            >
                                <div className="ImgProyect" style={{marginLeft:'0px', marginBottom:'60px'}}>
                                    <span className="avatar-title rounded-circle bg-light text-danger font-size-16">
                                        <img src={addDesktopImage}  height="80px" width='55px' />
                                    </span>
                                </div>
                                <div id="CenterContentProyectCard" style={{marginLeft: '105px', marginRight:'0px'}}>
                                    <div className="flex-grow-1 overflow-hidden" style={{marginRight:'-10px'}}>
                                    <div id="AddProyecto">
                                        <HelveticaBold style={{fontSize:'18px', color:'#414141', fontWeight:'700'}}>{i18n.t("Desde Planhopper")}</HelveticaBold>
                                    </div>
                                    <HelveticaLight style={{fontSize:'14px', fontWeight:'300'}}>{i18n.t('Usar archivos ya subidos a Planhopper en el apartado de documentos ')}</HelveticaLight>
                                
                                    </div>
                                </div>
                            </div>
                           
                     </div>
   
                </div>
            </div>
        </Modal>
       {openFileModal && <ModalImportFile4Act index={index} actaValues={actaValues} setOpenAnterior={setOpen} setActaValues={setActaValues} openModal={openFileModal} setOpenModal={setOpenFileModal} selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} />}
       {openTribbooModal && <ModalSelectTribbooFiles index={index} actaValues={actaValues} setOpenAnterior={setOpen} setActaValues={setActaValues} openModal={openTribbooModal} setOpenModal={setOpenTribbooModal} selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles}/> }
        </>
    )
}

AddFilesModal.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    selectedFiles: PropTypes.array.isRequired,
    setSelectedFiles: PropTypes.func.isRequired,
    actaValues: PropTypes.object.isRequired,
    setActaValues: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired
}
export default AddFilesModal;