import React, { useContext, useEffect } from 'react'
import { Modal, CircularProgress } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types'
import { useState } from 'react';
import i18n from 'i18n';
import CloseIcon from "@mui/icons-material/Close";
import  {PoppinsNormal} from 'common/typographies'
import CapituloModal from './CapituloModal';
import ModalConfirmPaste from './modalConfirmPaste';
import AuthContext from 'context/auth/authContext';
import ModalSuccesPaste from './modalSuccesPaste';
import FavoritesBPContext from 'context/favoritesBP/favoritesBPContext';
import BankPricesContext from 'context/bankPrices/bankPricesContext';
import TemplatesContext from 'context/templates/templatesContext';

function getModalStyle() {
    const top = 50 ;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: '850px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        borderRadius: 6,
        maxHeight: '550px',
        height: '550px',
        overflowY: 'auto',
        overflowX:'hidden',
    },
}));


const ModalSelectChapter = ({open, setOpen, tipo}) => {

    const [ modalStyle ] = useState(getModalStyle);
    const classes = useStyles();
    const {user, getUser} = useContext(AuthContext)
    const [loading1, setLoading] = useState(false)
    const {importChaptersFromDatabase, createPartsOnBudget, importChapters2MBP, importParts2MBP} = useContext(FavoritesBPContext);
    const {selectedCategories2, categoriesChecked, partsChecked} = useContext(TemplatesContext);
    const {getMyBankPrices,loading, myPricesBank, addBankChapter, myNonSpecifiedPricesBank, cleanChapters, cleanParts} = useContext(BankPricesContext);
    useEffect(() => {
        if(user){

        getMyBankPrices(user.sub)
        
        }else{
        getUser()
        }
    } , [])
    const [capituloSelected, setCapituloSelected] = useState({})
    const [openFinal, setOpenFinal] = useState(false)
    const [openModalConfirm, setOpenModalConfirm] = useState(false)
    
    const handleConfirm = async() => {
        if(capituloSelected.capituloInfo){
            setLoading(true)
            if(categoriesChecked.length > 0){
            await importChapters2MBP(selectedCategories2, user.sub, capituloSelected.capituloInfo.chapterBankId, tipo)
            }
            else{
                await importParts2MBP(partsChecked, user.sub, capituloSelected.capituloInfo.chapterBankId,capituloSelected, tipo)
            }
            setLoading(false)
            setOpenFinal(true)
        }else{
            setLoading(true)
            setOpenModalConfirm(true)
            setLoading(false)
        }
    }

    return(
        <>
        <Modal open={open} onClose={() => null}>
            <div style={modalStyle} className={classes.paper}>
                <div id='principalOptions' style={{marginTop:'20px', marginBottom:'15px', textAlign:'left'}}>
                    <CloseIcon id="cerrar" style={{marginTop:'-15px', right:'-10px'}} onClick={()=>{setOpen(false)}}></CloseIcon>
                    <p id='titles' style={{textAlign:'CENTER', fontSize:'20px', marginBottom:"35px"}}>{i18n.t("Selecciona el capitulo dónde quieres pegar tu seleccion")}</p>
                    
                    <div style={{height:'290px', overflow:'auto', paddingBottom:'10px'}}>
                    {loading ? 
                     <>
                     <div style={{justifyContent:'center', width:'100%', textAlign:'center', position:'relative', display:'flex', marginLeft:'-100px', marginTop:'0px'}}>
                     <div className='loader'>
                         <div className='ball'></div>
                         <div className='ball'></div>
                         <div className='ball'></div>
                    </div>
                     </div>
                     <div style={{justifyContent:'center', width:'100%', textAlign:'center', position:'relative', display:'flex', marginLeft:'-30px'}}>
                     <PoppinsNormal>{i18n.t("Cargando...")}</PoppinsNormal>
                     </div>
                     <div style={{justifyContent:'center', width:'100%', textAlign:'center', position:'relative', display:'flex', marginLeft:'-30px', marginTop:'5px'}}>
                     <PoppinsNormal style={{fontWeight:'400'}}>{i18n.t("Esta acción puede tardar unos segundos")}</PoppinsNormal>
                     </div>
                    
                     </> 
                     
                        :  myPricesBank.subcapitulos.length>0?
                        myPricesBank.subcapitulos.map((subcapitulo, index) => {
                            return(
                                <CapituloModal key={index} capitulo={subcapitulo} capituloSelected={capituloSelected} setCapituloSelected={setCapituloSelected}/>
                            )
                            })
                            : 
                            <PoppinsNormal>Todavia no tienes capitulos creados</PoppinsNormal>
                            }
                        </div>
                        </div>
                        {loading1==false ?
                        <button className='AddProject' style={{left:'40%'}} onClick={()=>{handleConfirm()}}>{i18n.t("Confirmar")}</button>
                            :
                            <div>
                                <CircularProgress size={24} style={{left:'40%'}} />
                                </div> }
            </div>
        </Modal>
        <ModalConfirmPaste successModal={openModalConfirm} setSuccessModal={setOpenModalConfirm} setOpenSegundo={setOpen} tipo={tipo}/>
        <ModalSuccesPaste successModal={openFinal} setSuccessModal={setOpenFinal} setOpenSegundo={setOpen}/>
   
        </>
    )
}

ModalSelectChapter.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    tipo: PropTypes.string.isRequired
}
export default ModalSelectChapter;