import React from 'react';
import DropdownSettings from "components/Project/Overview/DropdownSettings"
import DropdownSelectBank from 'components/BancoPrecios/dropdowns/DropdownSelectBank';
import i18n from 'i18n';

export const SummaryNav = {
    'paths': [
    /*{
        
        'label': i18n.t('Panel'),
        'to': '/project',
        'isActive': true,
        'disabled': false,
        
    }*/,{
        'label': i18n.t('Presupuesto'),
        'to': '/budgeting',
        'isActive': false,
        'disabled': false,
    },
    
 
    // {
    //     'label': i18n.t('Proveedores'),
    //     'to': '/budgeting/providers',
    //     'isActive': false,
    //     'disabled': true,
    //     'muypronto': true
    // },
    
    /*
    {
        'label': i18n.t('Planning'),
        'to': '/planning/kanban',
        'isActive': false,
        'disabled': true,
        'muypronto': true
    },
    */
    {
        'label': i18n.t('Facturas'),
        'to': '/invoices',
        'isActive': false,
        'disabled': false,
        'muypronto': false
    },
    {
        'label': i18n.t('Actas'),
        'to': '/actas',
        'isActive': false,
        'disabled': false,
      
    },
    {
        'label': i18n.t('Certificaciones'),
        'to': '/certificaciones',
        'isActive': false,
        'disabled': false,
      
    },

    ],
    'options': <DropdownSettings/>
}
export const BudgetingNav = {
    'paths': [
   /*{
        
        'label': i18n.t('Panel'),
        'to': '/project',
        'isActive': false,
        'disabled': false,
              
    }*/,{
        'label': i18n.t('Presupuesto'),
        'to': '/budgeting',
        'isActive': true,
        'disabled': false,
    },
  
    // {
    //     'label': i18n.t('Proveedores'),
    //     'to': '/budgeting/providers',
    //     'isActive': false,
    //     'disabled': true,
    //     'muypronto': true
    // },
    /*{
        'label': i18n.t('Planning'),
        'to': '/planning/kanban',
        'isActive': false,
        'disabled': true,
        'muypronto': true
    },
      */
    {
        'label': i18n.t('Facturas'),
        'to': '/invoices',
        'isActive': false,
        'disabled': false,
        'muypronto': false
    },
    {
        'label': i18n.t('Actas'),
        'to': '/actas',
        'isActive': false,
        'disabled': false,
      
    },
    {
        'label': i18n.t('Certificaciones'),
        'to': '/certificaciones',
        'isActive': false,
        'disabled': false,
      
    },
    {
        'label': i18n.t('Historico Certificaciones'),
        'to': '/certhistory',
        'isActive': false,
        'disabled': false,
      
    },
    {
        'label': i18n.t('Extras y Contradictorios'),
        'to': '/extras',
        'isActive': false,
        'disabled': false,
      
    },
 
],     'options': <DropdownSettings/>}

export const BudgetingNavFinal = {
    'paths': [
    /*{
        
        'label': i18n.t('Panel'),
        'to': '/project',
        'isActive': false,
        'disabled': false,
              
    }*/,{
        'label': i18n.t('Presupuesto de Coste'),
        'to': '/budgeting',
        'isActive': false,
        'disabled': false,
    },
    {
        'label': i18n.t('Presupuesto Cliente'),
        'to': '/budgeting/final',
        'isActive': true,
        'disabled': false,
    },
    // {
    //     'label': i18n.t('Proveedores'),
    //     'to': '/budgeting/providers',
    //     'isActive': false,
    //     'disabled': true,
    //     'muypronto': true
    // },
    /*{
        'label': i18n.t('Planning'),
        'to': '/planning/kanban',
        'isActive': false,
        'disabled': true,
        'muypronto': true
    },  */
    {
        'label': i18n.t('Facturas'),
        'to': '/invoices',
        'isActive': false,
        'disabled': false,
        'muypronto': false
    },
    {
        'label': i18n.t('Actas'),
        'to': '/actas',
        'isActive': false,
        'disabled': false,
      
    },
    {
        'label': i18n.t('Certificaciones'),
        'to': '/certificaciones',
        'isActive': false,
        'disabled': false,
      
    },
    {
        'label': i18n.t('Historico Certificaciones'),
        'to': '/certhistory',
        'isActive': false,
        'disabled': false,
      
    },
    
 
],     'options': <DropdownSettings/>}

export const PlanningNav = {
    'paths': [
    /*{
        'label': i18n.t('Panel'),
        'to': '/project',
        'isActive': false,
        'disabled': false,
              
    }*/,{
        'label': i18n.t('Presupuesto'),
        'to': '/budgeting',
        'isActive': false,
        'disabled': false,
    },
   
    // {
    //     'label': i18n.t('Proveedores'),
    //     'to': '/budgeting/providers',
    //     'isActive': false,
    //     'disabled': true,
    //     'muypronto': true
    // },
    {
        'label': i18n.t('Planning'),
        'to': '/planning/kanban',
        'isActive': true,
        'disabled': true,
        'muypronto': true
    },
    {
        'label': i18n.t('Facturas'),
        'to': '/invoices',
        'isActive': false,
        'disabled': false,
        'muypronto': false
    }, {
        'label': i18n.t('Actas'),
        'to': '/actas',
        'isActive': false,
        'disabled': false,
      
    },
    {
        'label': i18n.t('Certificaciones'),
        'to': '/certificaciones',
        'isActive': false,
        'disabled': false,
      
    },

],     'options': <DropdownSettings/>}

export const ProvidersNav = {
    'paths': [
    /*{
        
        'label': i18n.t('Panel'),
        'to': '/project',
        'isActive': false,
        'disabled': false,
              
    }*/,{
        'label': i18n.t('Presupuesto'),
        'to': '/budgeting',
        'isActive': false,
        'disabled': false,
    },
// {
//         'label': i18n.t('Proveedores'),
//         'to': '/budgeting/providers',
//         'isActive': true,
//         'disabled': false,
//     },
    /*{
        'label': i18n.t('Planning'),
        'to': '/planning/kanban',
        'isActive': false,
        'disabled': true,
        'muypronto': true
    },
      */
    {
        'label': i18n.t('Facturas'),
        'to': '/invoices',
        'isActive': false,
        'disabled': false,
        'muypronto': false
    },
    {
        'label': i18n.t('Actas'),
        'to': '/actas',
        'isActive': false,
        'disabled': false,
      
    },
    {
        'label': i18n.t('Certificaciones'),
        'to': '/certificaciones',
        'isActive': false,
        'disabled': false,
      
    },
    
],     'options': <DropdownSettings/>}

export const FacturasNav = {
    'paths': [
    /*{
        'label': i18n.t('Panel'),
        'to': '/project',
        'isActive': false,
        'disabled': false,
              
    }*/,{
        'label': i18n.t('Presupuesto'),
        'to': '/budgeting',
        'isActive': false,
        'disabled': false,
    },
//    {
//         'label': i18n.t('Proveedores'),
//         'to': '/budgeting/providers',
//         'isActive': false,
//         'disabled': true,
//         'muypronto': true
//     },
    {
        'label': i18n.t('Facturas'),
        'to': '/invoices',
        'isActive': true,
        'disabled': false,
        'muypronto': false
    },
    {
        'label': i18n.t('Actas'),
        'to': '/actas',
        'isActive': false,
        'disabled': false,
      
    },
    {
        'label': i18n.t('Certificaciones'),
        'to': '/certificaciones',
        'isActive': false,
        'disabled': false,
      
    },
    
],     'options': <DropdownSettings/>}


export const ActasNav = {
    'paths': [
    /*{
        
        'label': i18n.t('Panel'),
        'to': '/project',
        'isActive': true,
        'disabled': false,
        
    }*/,{
        'label': i18n.t('Presupuesto'),
        'to': '/budgeting',
        'isActive': false,
        'disabled': false,
    },
    
   
    // {
    //     'label': i18n.t('Proveedores'),
    //     'to': '/budgeting/providers',
    //     'isActive': false,
    //     'disabled': true,
    //     'muypronto': true
    // },
    /*
    {
        'label': i18n.t('Planning'),
        'to': '/planning/kanban',
        'isActive': false,
        'disabled': true,
        'muypronto': true
    },
    */
   
    {
        'label': i18n.t('Facturas'),
        'to': '/invoices',
        'isActive': false,
        'disabled': false,
        'muypronto': false
    }, {
        'label': i18n.t('Actas'),
        'to': '/actas',
        'isActive': true,
        'disabled': false,
      
    },
    {
        'label': i18n.t('Certificaciones'),
        'to': '/certificaciones',
        'isActive': false,
        'disabled': false,
      
    },
    ],
    'options': <DropdownSettings/>

}



export const CertificacionesNav = {
    'paths': [
    /*{
        
        'label': i18n.t('Panel'),
        'to': '/project',
        'isActive': true,
        'disabled': false,
        
    }*/,{
        'label': i18n.t('Presupuesto'),
        'to': '/budgeting',
        'isActive': false,
        'disabled': false,
    },
    
   
    // {
    //     'label': i18n.t('Proveedores'),
    //     'to': '/budgeting/providers',
    //     'isActive': false,
    //     'disabled': true,
    //     'muypronto': true
    // },
    /*
    {
        'label': i18n.t('Planning'),
        'to': '/planning/kanban',
        'isActive': false,
        'disabled': true,
        'muypronto': true
    },
    */
   
    {
        'label': i18n.t('Facturas'),
        'to': '/invoices',
        'isActive': false,
        'disabled': false,
        'muypronto': false
    }, {
        'label': i18n.t('Actas'),
        'to': '/actas',
        'isActive': false,
        'disabled': false,
      
    },
    {
        'label': i18n.t('Certificaciones'),
        'to': '/certificaciones',
        'isActive': true,
        'disabled': false,
      
    },{
        'label': i18n.t('Historico Certificaciones'),
        'to': '/certhistory',
        'isActive': false,
        'disabled': false,
      
    },
    {
        'label': i18n.t('Extras y Contradictorios'),
        'to': '/extras',
        'isActive': false,
        'disabled': false,
      
    },
    ],
    'options': <DropdownSettings/>

}

export const CertHistoryNav = {
    'paths': [
    /*{
        
        'label': i18n.t('Panel'),
        'to': '/project',
        'isActive': true,
        'disabled': false,
        
    }*/,{
        'label': i18n.t('Presupuesto'),
        'to': '/budgeting',
        'isActive': false,
        'disabled': false,
    },
    
   
    // {
    //     'label': i18n.t('Proveedores'),
    //     'to': '/budgeting/providers',
    //     'isActive': false,
    //     'disabled': true,
    //     'muypronto': true
    // },
    /*
    {
        'label': i18n.t('Planning'),
        'to': '/planning/kanban',
        'isActive': false,
        'disabled': true,
        'muypronto': true
    },
    */
   
    {
        'label': i18n.t('Facturas'),
        'to': '/invoices',
        'isActive': false,
        'disabled': false,
        'muypronto': false
    }, {
        'label': i18n.t('Actas'),
        'to': '/actas',
        'isActive': false,
        'disabled': false,
      
    },
    {
        'label': i18n.t('Certificaciones'),
        'to': '/certificaciones',
        'isActive': false,
        'disabled': false,
      
    },
    {
        'label': i18n.t('Historico Certificaciones'),
        'to': '/certhistory',
        'isActive': true,
        'disabled': false,
      
    },
    {
        'label': i18n.t('Extras y Contradictorios'),
        'to': '/extras',
        'isActive': false,
        'disabled': false,
      
    },
    ],
    'options': <DropdownSettings/>

}

export const ExtrasAndContradictionsNav = {
    'paths': [
    /*{
        
        'label': i18n.t('Panel'),
        'to': '/project',
        'isActive': true,
        'disabled': false,
        
    }*/,{
        'label': i18n.t('Presupuesto'),
        'to': '/budgeting',
        'isActive': false,
        'disabled': false,
    },
    
   
    // {
    //     'label': i18n.t('Proveedores'),
    //     'to': '/budgeting/providers',
    //     'isActive': false,
    //     'disabled': true,
    //     'muypronto': true
    // },
    /*
    {
        'label': i18n.t('Planning'),
        'to': '/planning/kanban',
        'isActive': false,
        'disabled': true,
        'muypronto': true
    },
    */
   
    {
        'label': i18n.t('Facturas'),
        'to': '/invoices',
        'isActive': false,
        'disabled': false,
        'muypronto': false
    }, {
        'label': i18n.t('Actas'),
        'to': '/actas',
        'isActive': false,
        'disabled': false,
      
    },
    {
        'label': i18n.t('Certificaciones'),
        'to': '/certificaciones',
        'isActive': false,
        'disabled': false,
      
    },
    {
        'label': i18n.t('Historico Certificaciones'),
        'to': '/certhistory',
        'isActive': false,
        'disabled': false,
      
    },
    {
        'label': i18n.t('Extras y Contradictorios'),
        'to': '/extras',
        'isActive': true,
        'disabled': false,
      
    },
    ],
    'options': <DropdownSettings/>

}



export const DocumentsNav = {
    'paths': [
        {
            'label': i18n.t('Documentos'),
            'to': '/documents',
            'isActive': true,
            'disabled': false,
        }
]}

export const ProfileNav = {
    'paths': [
    {
        'label': i18n.t('General'),
        'to': '/generalProfile',
        'isActive': true,
        'disabled': false,
    },{
        'label': i18n.t('Perfil'),
        'to': '/profile',
        'isActive': false,
        'disabled': false,
    },
    // {
    //     'label': i18n.t('Plantillas'),
    //     'to': '/templateManagement',
    //     'isActive': false,
    //     'disabled': false,
    // },

    {
        'label': i18n.t('Gestión de equipo'),
        'to': '/gestionEquipo',
        'isActive': false,
        'disabled': false,
    },
    {
        'label': i18n.t('Suscripción'),
        'to': '/subscription',
        'isActive': false,
        'disabled': false,
    },
    {
            'label': i18n.t('Personalizar PDF'),
            'to': '/general-pdf',
            'isActive': false,
            'disabled': false,
        }
   
]

}
export const ProfileNav2 = {
    'paths': [
    {
        'label': i18n.t('General'),
        'to': '/generalProfile',
        'isActive': false,
        'disabled': false,
    },{
        'label': i18n.t('Perfil'),
        'to': '/profile',
        'isActive': true,
        'disabled': false,
    },
    // {
    //     'label': i18n.t('Plantillas'),
    //     'to': '/templateManagement',
    //     'isActive': false,
    //     'disabled': false,
    // },

    {
        'label': i18n.t('Gestión de equipo'),
        'to': '/gestionEquipo',
        'isActive': false,
        'disabled': false,
    },
    {
        'label': i18n.t('Suscripción'),
        'to': '/subscription',
        'isActive': false,
        'disabled': false,
    },
     {
                'label': i18n.t('Personalizar PDF'),
                'to': '/general-pdf',
                'isActive': false,
                'disabled': false,
            }
   
    
]

}

export const ProfileNav3 = {
    'paths': [
    {
        'label': i18n.t('General'),
        'to': '/generalProfile',
        'isActive': false,
        'disabled': false,
    },{
        'label': i18n.t('Perfil'),
        'to': '/profile',
        'isActive': false,
        'disabled': false,
    },
    // {
    //     'label': i18n.t('Plantillas'),
    //     'to': '/templateManagement',
    //     'isActive': false,
    //     'disabled': false,
    // },
    {
        'label': i18n.t('Gestión de equipo'),
        'to': '/gestionEquipo',
        'isActive': false,
        'disabled': false,
    },
    {
        'label': i18n.t('Suscripción'),
        'to': '/subscription',
        'isActive': true,
        'disabled': false,
    },
     {
                'label': i18n.t('Personalizar PDF'),
                'to': '/general-pdf',
                'isActive': false,
                'disabled': false,
            }
   
    
]

}

export const ProfileNav4 = {
    'paths': [
    {
        'label': i18n.t('General'),
        'to': '/generalProfile',
        'isActive': false,
        'disabled': false,
    },{
        'label': i18n.t('Perfil'),
        'to': '/profile',
        'isActive': false,
        'disabled': false,
    },
    // {
    //     'label': i18n.t('Plantillas'),
    //     'to': '/templateManagement',
    //     'isActive': true,
    //     'disabled': false,
    // },
    {
        'label': i18n.t('Gestión de equipo'),
        'to': '/gestionEquipo',
        'isActive': false,
        'disabled': false,
    },
    {
        'label': i18n.t('Suscripción'),
        'to': '/subscription',
        'isActive': false,
        'disabled': false,
    },
    
   
] 
}


export const ProfileNav6 = {
    'paths': [
    {
        'label': i18n.t('General'),
        'to': '/generalProfile',
        'isActive': false,
        'disabled': false,
    },{
        'label': i18n.t('Perfil'),
        'to': '/profile',
        'isActive': false,
        'disabled': false,
    },
    // {
    //     'label': i18n.t('Plantillas'),
    //     'to': '/templateManagement',
    //     'isActive': false,
    //     'disabled': false,
    // },
    {
        'label': i18n.t('Gestión de equipo'),
        'to': '/gestionEquipo',
        'isActive': false,
        'disabled': false,
    },
    {
        'label': i18n.t('Suscripción'),
        'to': '/subscription',
        'isActive': false,
        'disabled': false,
    },
     {
                'label': i18n.t('Personalizar PDF'),
                'to': '/general-pdf',
                'isActive': true,
                'disabled': false,
            }


]

}

export const ProfileNavTeamManagementSection = {
    'paths': [
    {
        'label': i18n.t('General'),
        'to': '/generalProfile',
        'isActive': false,
        'disabled': false,
    },{
        'label': i18n.t('Perfil'),
        'to': '/profile',
        'isActive': false,
        'disabled': false,
    },
    // {
    //     'label': i18n.t('Plantillas'),
    //     'to': '/templateManagement',
    //     'isActive': false,
    //     'disabled': false,
    // },
    {
        'label': i18n.t('Gestión de equipo'),
        'to': '/gestionEquipo',
        'isActive': true,
        'disabled': false,
    },
    {
        'label': i18n.t('Suscripción'),
        'to': '/subscription',
        'isActive': false,
        'disabled': false,
    },
     {
                    'label': i18n.t('Personalizar PDF'),
                    'to': '/general-pdf',
                    'isActive': false,
                    'disabled': false,
                }

    
   
] 
}
export const ProfileNav5 = {
    'paths': [
    {
        'label': i18n.t('General'),
        'to': '/generalProfile',
        'isActive': false,
        'disabled': false,
    },{
        'label': i18n.t('Perfil'),
        'to': '/profile',
        'isActive': false,
        'disabled': false,
    },
    // {
    //     'label': i18n.t('Plantillas'),
    //     'to': '/templateManagement',
    //     'isActive': false,
    //     'disabled': false,
    // },
    {
        'label': i18n.t('Gestión de equipo'),
        'to': '/gestionEquipo',
        'isActive': false,
        'disabled': false,
    },
    {
        'label': i18n.t('Suscripción'),
        'to': '/subscription',
        'isActive': false,
        'disabled': false,
    },
   
]

}

export const BancoDePreciosNav = {
    'paths': [
    {
        'label': i18n.t('Mi banco de precios'),
        'to': '/bancodeprecios',
        'isActive': true,
        'disabled': false,
    },{
        'label': i18n.t('Banco de precios'),
        'to': '/bancodeprecios',
        'isActive': true,
        'disabled': false,
        'imComponent':true,
    },
    {
        'label': i18n.t('Plantillas'),
        'to': '/bancodeprecios/templates',
        'isActive': false,
        'disabled': false,
    },
    /*
    {
        'label': i18n.t('Favoritos'),
        'to': '/bancodeprecios/favorites/myBP',
        'isActive': false,
        'disabled': false,
    },
    */
    ],
    'options2': <DropdownSelectBank isActive={false} key={1}/>
}

export const BancoDePreciosNavCype = {
    'paths': [
    {
        'label': i18n.t('Mi banco de precios'),
        'to': '/bancodeprecios',
        'isActive': false,
        'disabled': false,
    },{
        'label': i18n.t('Banco de precios'),
        'to': '/bancodeprecios',
        'isActive': true,
        'disabled': false,
        'imComponent':true,
    },
    {
        'label': i18n.t('Plantillas'),
        'to': '/bancodeprecios/templates',
        'isActive': false,
        'disabled': false,
    },
    /*
    {
        'label': i18n.t('Favoritos'),
        'to': '/bancodeprecios/favorites/myBP',
        'isActive': false,
        'disabled': false,
    },
      */
    ],
    'options2': <DropdownSelectBank isActive={true} key={2}/>
}


export const BancoDePreciosNavTemplate = {
    'paths': [
    {
        'label': i18n.t('Mi banco de precios'),
        'to': '/bancodeprecios',
        'isActive': false,
        'disabled': false,
    },{
        'label': i18n.t('Banco de precios'),
        'to': '/bancodeprecios',
        'isActive': false,
        'disabled': false,
        'imComponent':true,
    },
    {
        'label': i18n.t('Plantillas'),
        'to': '/bancodeprecios/templates',
        'isActive': true,
        'disabled': false,
    },
    /*
    {
        'label': i18n.t('Favoritos'),
        'to': '/bancodeprecios/favorites/myBP',
        'isActive': false,
        'disabled': false,
    },
      */
    ],
    'options2': <DropdownSelectBank isActive={false} key={3}/>
}
export const BancoDePreciosNavFavorites = {
    'paths': [
    {
        'label': i18n.t('Mi banco de precios'),
        'to': '/bancodeprecios',
        'isActive': false,
        'disabled': false,
    },{
        'label': i18n.t('Banco de precios'),
        'to': '/bancodeprecios',
        'isActive': false,
        'disabled': false,
        'imComponent':true,
    },
    {
        'label': i18n.t('Plantillas'),
        'to': '/bancodeprecios/templates',
        'isActive': false,
        'disabled': false,
    },
    /*
    {
        'label': i18n.t('Favoritos'),
        'to': '/bancodeprecios/favorites/myBP',
        'isActive': true,
        'disabled': false,
    },
      */
    ],
    'options2': <DropdownSelectBank isActive={false} key={4}/>

    
}







export const BancoDePreciosNavTemplateUnique = {
    'paths': [
    {
        'label': i18n.t('Mi banco de precios'),
        'to': '/bancodeprecios',
        'isActive': false,
        'disabled': false,
    },{
        'label': i18n.t('Banco de precios'),
        'to': '/bancodeprecios',
        'isActive': false,
        'disabled': false,
        'imComponent':true,
    },
    {
        'label': i18n.t('Plantillas'),
        'to': '/bancodeprecios/templates',
        'isActive': false,
        'disabled': false,
    },
    {
        'label': i18n.t('Plantilla'),
        'to': '/bancodeprecios/templateV2',
        'isActive': true,
        'disabled': false,
    },
   
    ],
    'options2': <DropdownSelectBank isActive={false} key={3}/>
}

