import React from "react"
import PropTypes from "prop-types"
import TableStructureBank from "./tableStructure"
import Part from "./part"

const PartTable = ({ parts, imParent, total, capitulo, referencia }) => {
  return (
    <TableStructureBank
      imParent={imParent}
      total={total}
      capitulo={capitulo}
      parts={parts}
    >
      {parts.map((part, i) => (
        <Part
          referencia={referencia + "." + (i + 1)}
          key={part.partidaId}
          part={part}
          index={i}
          partsLength={parts.length}
        />
      ))}
    </TableStructureBank>
  )
}
PartTable.propTypes = {
  parts: PropTypes.array.isRequired,
  imParent: PropTypes.bool,
  total: PropTypes.float,
  referencia: PropTypes.string,
  capitulo: PropTypes.object,
}
export default PartTable
