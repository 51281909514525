import React, { useState, useContext, useEffect } from "react"
import propTypes from "prop-types"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import PartTable from "../part"
import {
  CapituloContainer,
  SubChapterContainer,
  ShowChildDiv,
  ShowChildDivWithBorder,
} from "common/containers"
import {StyledProviderButton} from "common/buttons"
import { PoppinsNormal } from "common/typographies"
import send from "assets/images/icons/send.svg"
import selfManagement from "assets/images/icons/selfManagement.svg"
import { currencyFormatDE } from "lib/transformers"
import i18n from "i18n"
import ContactContext from "context/contact/contactContext"
import AuthContext from "context/auth/authContext"
import SendModal from "components/Feed/sendBudgetRefatctor/sendModal"
import Budget2023Context from "context/budget2023/Budget2023Context"
import { getCurrency } from "utils/functions"


const Capitulo = props => {
  const [openNotSendCapitulo, setOpenNotSendCapitulo] = useState(false)
  const {addToSelfManagement} = useContext(Budget2023Context)
  const handleOpen = () => {
    setOpenNotSendCapitulo(prev => !prev)
  }
  const [openSendBudget, setOpenSendBudget] = useState(false)
  const contactContext = useContext(ContactContext)
  const { Contacts, getContacts} = contactContext
  const {user, getUser, freeTrialExpired} = useContext(AuthContext)
  const {
    updateIsExpanded,
    createPartidaV2,
    addCapituloV2,
    updateCapitulov2,
    checkCapitulos, moveChapters,
    closeBudget, isUpdates, changeSidebar, checkMultipleChapters, budgetTree,  imChecked2 , chaptersChecked, partsChecked, changeCheck2,   updateChaptersList,
  } = useContext(Budget2023Context)
  const changeCheck = e => {
    if (!e) var e = window.event;
    e.cancelBubble = true;
    if (e.stopPropagation) e.stopPropagation();
    if(e.nativeEvent.shiftKey){
      if(props.capitulo.checked === true){
        checkCapitulos(props.capitulo.chapterId, false)
        updateChaptersList(props.capitulo.capituloInfo)
      }else{
        checkMultipleChapters(props.capitulo.chapterId)
      } 
    }else{
      if (props.capitulo.checked === true) {
        checkCapitulos(props.capitulo.chapterId, false)
        updateChaptersList(props.capitulo.capituloInfo)
      } else {
        checkCapitulos(props.capitulo.chapterId, true)
        updateChaptersList(props.capitulo.capituloInfo)
      }
    }
  }
  useEffect(() => {
    if(!user){
      getUser()
    }
  }, [])
  return (
    <div>
      {props.capitulo.parentId == "root" ||
      props.capitulo.capituloInfo.parentId === "root" ? (
        <div>
          <CapituloContainer>
            <div
              style={{
                height:'44px',
                background: "#F7DDC8",
                minWidth: "37px",
                paddingLeft: "3px",
                display: "flex",
                position: "relative",
                borderRadius: "6px",
                paddingTop: "12px",
                }}
            >
              {openNotSendCapitulo ? (
                <div>
                  <KeyboardArrowDownIcon
                    style={{ cursor: "pointer", marginLeft: "3px" }}
                    className="dropdownArrowB"
                    onClick={handleOpen}
                  />
                </div>
              ) : (
                <KeyboardArrowRightIcon
                  style={{ cursor: "pointer", marginLeft: "3px" }}
                  className="dropdownArrowB"
                  onClick={handleOpen}
                />
              )}
            
                <td className="tdcheckColumn">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={props.capitulo.checked}
                    id="flexCheckDefault"
                    onClick={(e)=>{
                      if (!e) var e = window.event;
                        e.cancelBubble = true;
                        if (e.stopPropagation) e.stopPropagation();
                        }}
                        onChange={changeCheck}
                        style={{ width: "13px", height: "13px", cursor:'pointer', zIndex:"5" }}
                      ></input>
              </td>
              
              <PoppinsNormal>{props.capitulo.capituloInfo.name}</PoppinsNormal>
              <div style={{display:'flex', position:'absolute', right:'0.7%'}}>
              <div style={{ marginRight: "0.5rem", marginTop:'-3px' }}>
                { user ? 
             user.freeTrialExpired || user['custom:role'] === 'WORKER' ?
             null
             :
                <StyledProviderButton style={{ minWidth: "84px" }} onClick={()=>{setOpenSendBudget(true)}}>
                  <img
                    src={send}
                    alt="send"
                    style={{ marginRight: "0.1rem", marginLeft:'-4px', marginTop:'-2px' }}
                  />{" "}
                  {i18n.t("Enviar")}
                </StyledProviderButton> : null }
              </div>

              <div style={{ marginRight: "0.5rem" , marginTop:'-3px'}}>
                { user ? 
             user.freeTrialExpired || user['custom:role'] === 'WORKER' ?
            null
             :
                <StyledProviderButton style={{ minWidth: "260px" }} onClick={()=>{addToSelfManagement(props.capitulo.capituloInfo.projectId, props.capitulo.chapterId)}}>
                  <img
                    src={selfManagement}
                    alt="self management"
                    style={{ marginRight: "0.3rem" , marginLeft:'-4px', marginTop:'0px', width:'12px' }}
                  />
                  {i18n.t("Seleccionar como Autogestión")}
                </StyledProviderButton> :null}
              </div>

              <div id="precioTotal" style={{marginTop:'-3px'}}>{currencyFormatDE(props.capitulo.total, getCurrency(user['custom:currency']))}</div>
              </div>
            </div>
         
             

          </CapituloContainer>
          {openNotSendCapitulo ? (
            <ShowChildDiv style={{ width: "98%", marginTop: props.capitulo.partidas?.length ? '-8px' : '-14px' }}>
              {props.capitulo.partidas?.length ? (
                <PartTable parts={props.capitulo.partidas} total={props.capitulo.total} capitulo={props.capitulo}/>
              ) : props.capitulo.subcapitulos?.length ? (
                props.capitulo.subcapitulos.map((capitulo, i) => (
                  capitulo.sended=="false" ?
                  (
                  <Capitulo
                    key={capitulo.chapterId}
                    i={i}
                    capitulo={capitulo}
                    imParent={(props.capitulo.parentId = "root" ? true : false)}
                  />
                  )
                  : null
                  ))
              ) : null}
            </ShowChildDiv>
          ) : null}
        </div>
      ) : (
        //im a subchapter  <SubChapterContainer> --> 'className='NewSubCapi' in budgeting.css
        <div>
          <SubChapterContainer style={{borderLeft: props.imParent? '6px solid #ECAE7D' : '0px'}}>
            <div
              style={{
                minWidth: "37px",
                paddingLeft: "3px",
                display: "flex",
                position: "relative",
                paddingTop: "11px",
              }}
            >
              {openNotSendCapitulo ? (
                <div>
                  <KeyboardArrowDownIcon
                    style={{ cursor: "pointer", marginLeft: "3px" }}
                    className="dropdownArrowB"
                    onClick={handleOpen}
                  />
                </div>
              ) : (
                <KeyboardArrowRightIcon
                  style={{ cursor: "pointer", marginLeft: "3px" }}
                  className="dropdownArrowB"
                  onClick={handleOpen}
                />
              )}
                <td className="tdcheckColumn">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={props.capitulo.checked}
                    id="flexCheckDefault"
                    onClick={(e)=>{
                      if (!e) var e = window.event;
                        e.cancelBubble = true;
                        if (e.stopPropagation) e.stopPropagation();
                        }}
                        onChange={changeCheck}
                        style={{ width: "13px", height: "13px", cursor:'pointer', zIndex:"5" }}
                      ></input>
              </td>
            
              <PoppinsNormal>{props.capitulo.capituloInfo.name}</PoppinsNormal>
              <div style={{display:'flex', position:'absolute', right:'0.7%'}}>
              <div style={{ marginRight: "0.5rem", marginTop:'-3px' }}>
              {user ? 
             user.freeTrialExpired || user['custom:role'] === 'WORKER' ?
            null
             :
                <StyledProviderButton style={{ minWidth: "84px" }} onClick={()=>{setOpenSendBudget(true)}}>
                  <img
                    src={send}
                    alt="send"
                    style={{ marginRight: "0.1rem", marginLeft:'-4px', marginTop:'-2px' }}
                  />{" "}
                  {i18n.t("Enviar")}
                </StyledProviderButton> : null }
              </div>

              <div style={{ marginRight: "0.5rem" , marginTop:'-3px'}}>
                { user ? 
             user.freeTrialExpired || user['custom:role'] === 'WORKER' ?
            null
             :
                <StyledProviderButton style={{ minWidth: "260px" }} onClick={()=>{addToSelfManagement(props.capitulo.capituloInfo.projectId, props.capitulo.chapterId)}}>
                  <img
                    src={selfManagement}
                    alt="self management"
                    style={{ marginRight: "0.3rem" , marginLeft:'-4px', marginTop:'0px', width:'12px' }}
                  />
                  {i18n.t("Seleccionar como Autogestión")}
                </StyledProviderButton> : null }
              </div>

              <div id="precioTotal" style={{marginTop:'-3px'}}>{currencyFormatDE(props.capitulo.total, getCurrency(user['custom:currency']))}</div>
              </div>
            </div>
          </SubChapterContainer>

          {openNotSendCapitulo ? (
            props.imParent ? (
              <ShowChildDivWithBorder style={{paddingLeft: props.capitulo.partidas?.length ? '0px' : '8px'}}>
                {props.capitulo.partidas?.length ? (
                  <PartTable
                    parts={props.capitulo.partidas}
                    imParent={props.imParent ? props.imParent : false}
                    total={props.capitulo.total}
                    capitulo={props.capitulo}
                  />
                ) : props.capitulo.subcapitulos?.length ? (
                  props.capitulo.subcapitulos.map((capitulo, i) => (
                    <Capitulo
                      key={capitulo.chapterId}
                      i={i}
                      capitulo={capitulo}
                    />
                  ))
                ) : null}
              </ShowChildDivWithBorder>
            ) : (
              <ShowChildDiv style={{backgroundColor:'white'}}>
                {props.capitulo.partidas?.length ? (
                  <PartTable parts={props.capitulo.partidas} total={props.capitulo.total}  capitulo={props.capitulo} />
                ) : props.capitulo.subcapitulos?.length ? (
                  props.capitulo.subcapitulos.map((capitulo, i) => (
                    <Capitulo
                      key={capitulo.chapterId}
                      i={i}
                      capitulo={capitulo}
                    />
                  ))
                ) : null}
              </ShowChildDiv>
            )
          ) : null}
        </div>
      )}
       <SendModal
                open={openSendBudget}
                setOpen={setOpenSendBudget}
                contactos={Contacts}
                capitulo={props.capitulo}
                chaptersChecked={chaptersChecked.length>0?? true}
            /> 
    </div>
  )
}

Capitulo.propTypes = {
  key: propTypes.string.isRequired,
  i: propTypes.number.isRequired,
  capitulo: propTypes.object.isRequired,
  imParent: propTypes.bool,
}

export default Capitulo
