import React, {Fragment, useEffect, useContext} from 'react';
import { useInvoice } from '../../hooks/useInvoice';
import {useParams } from 'react-router-dom';
import { MetaTags } from 'react-meta-tags';
import { Container } from 'reactstrap';
import ProjectContext from 'context/projects/projectContext';
import InvoicesDashboardContainer from 'components/Invoice/InvoicesDashboardContainer';
import * as amplitude from '@amplitude/analytics-browser';
import { RecordPageView } from "App"
const InvoicesDashboard = () => {
    RecordPageView()
    const {projectId} = useParams()
    const invoiceService = useInvoice()
    const projectContext = useContext(ProjectContext)
    const {getProject} = projectContext
    useEffect(() => {
        if(projectId){
            invoiceService.getByProject(projectId)
            getProject(projectId)
        }else{
            invoiceService.getByOrganization()
        }   
    }, [projectId])

    useEffect(() => {
        amplitude.track('Facturas Visited');
    }, [])
    return (
        <Fragment>
            <div className="page-content">
            <MetaTags>
                <title>Planhopper | Facturas</title>
            </MetaTags>
            <Container fluid>
                <InvoicesDashboardContainer invoiceService={invoiceService}/>
            </Container>
            </div>
        </Fragment>
    )
}

export default InvoicesDashboard