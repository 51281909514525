import React from "react"
import PropTypes from "prop-types"
import { YellowButton } from "common/buttons"
import { CircularProgress } from "@mui/material"
import { HelveticaBold } from "common/typographies"
import download from "assets/customIcons/download.svg"

const ActionsButtons = ({ children, ...props }) => {
  return <div {...props}>{children}</div>
}

ActionsButtons.propTypes = {
  children: PropTypes.node.isRequired,
}

const YellowButtonWithLoading = ({ title, loading, ...props }) => {
  return (
    <YellowButton
      className="col-1"
      {...props}
      style={{
        width: "auto",
        height: "32px",
        padding: "0px 10px 0px 10px",
        marginRight: "20px",
        marginLeft: "10px",
      }}
      disabled={loading}
    >
      {!loading ? (
        title
      ) : (
        <CircularProgress
          size={20}
          style={{ marginLeft: "auto", marginRight: "0px" }}
        />
      )}
    </YellowButton>
  )
}

YellowButtonWithLoading.propTypes = {
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
}

ActionsButtons.YellowButton = YellowButtonWithLoading

const DownloadBtn = ({ title, ...props }) => {
  return (
    <div
      id="hoverDropdown"
      style={{ display: "flex", cursor: "pointer" }}
      {...props}
    >
      <img
        src={download}
        style={{
          color: "black",
          marginTop: "-10px",
          width: "12px",
          marginRight: "4px",
          fontWeight: "400",
        }}
      />
      <HelveticaBold
        style={{
          color: "#414141",
          marginTop: "6px",
          width: "85px",
          textTransform: "none",
          fontSize: "14px",
          fontWeight: "400",
        }}
      >
        {title}
      </HelveticaBold>
    </div>
  )
}

DownloadBtn.propTypes = {
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
}

ActionsButtons.Download = DownloadBtn

export default ActionsButtons
