import React, { useEffect, useState, useContext} from 'react';
import CloseIcon from "@mui/icons-material/Close";
import {makeStyles} from "@material-ui/core/styles";
import { Modal} from "@mui/material";
import PropTypes from "prop-types";
import i18n from 'i18n';
import ModalSelectPlantilla from './modalSelectPlantilla';
import ModalCreateFromExcel from './ModalCreateFromExcel';
import ContactContext from "context/contact/contactContext"
import excel from "assets/customIcons/excel.svg"
import excelPlanhopper from "assets/customIcons/excelPlanhopper.svg"
import planhopperAI from "assets/customIcons/planhopperAI.svg"

import bc3 from "assets/customIcons/bc3.svg"

import startPlanhopper from "assets/customIcons/logos/startPlanhopper.svg"
import plantilla from "assets/customIcons/plantilla.svg"
import higherThan from "assets/customIcons/ArrowHigherThan.svg"

function getModalStyle() {
    const top = 50 ;
    const left = 50;
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };

}

const useStyles = makeStyles(theme => ({
    modal: {
        position: 'absolute',
        width: '820px',
        //height: '614px',
       //height: '560px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        //padding: theme.spacing(2, 4, 3),
        borderRadius: 6,
        //maxHeight: 'calc(100vh - 30px)',
        overflowY: 'auto',
        overflowX:'hidden',
    },
}));

const ModalSelectTypeProject =({modalNew, setModalNew, setmodal_first, onClickPersonalModal})=> {

    const [ modalStyle ] = useState(getModalStyle);
    const classes = useStyles();
    const handleCargar = () => {
        setModalNew(false)
        setmodal_first(true)
    }
    const [openModalPlantilla, setOpenModalPlantilla] = useState(false);

    const [openCreateFromExcel, setOpenCreateFromExcel] = useState(false);
    const [openCreateFromOther, setOpenCreateFromOther] = useState(false);
    const [openModalExcel, setOpenModalExcel] = useState(false);
    const [openModalOther, setOpenModalOther] = useState(false);
    const {Contacts} = useContext(ContactContext)

    return(<>
      <Modal open={modalNew} onClose={() => {null}}>
        <div style={modalStyle} className={classes.modal}>
          <CloseIcon id="cerrar" style={{marginTop:'12px', marginRight:'15px'}} onClick={() => {setModalNew(false);}}></CloseIcon>
  
          <div style={{marginTop:'35px', display:'flex', flex_direction:'column'}} >
            <div style={{width:'50%'}} >

              <div className="CardProjects" onClick={handleCargar}
                style={{cursor:'pointer', border:'1px solid #c4c4c4'}} >
                <div id="CardContent">
                  <div className="ImgProyect" style={{marginLeft:'0px'}}>
                    <span><img src={startPlanhopper}   height="75px" width='150px' /></span>
                  </div>
                  <div id="CenterContentProyectCard" style={{marginLeft: '105px'}}>
                    <div className="flex-grow-1 overflow-hidden" style={{marginRight:'-10px'}}>
                      <div id="AddProyecto" style={{fontSize:'16px', fontWeight:'700'}}>
                        {i18n.t("Comienza con Planhopper")}</div>
                      <p className="DescriptionText" style={{fontSize:'13px', marginRight:'0px', width:'95%'}}>
                        Construye tu presupuesto utilizando las partidas de las bases de datos</p>
                    </div>
                    <img src={higherThan} style={{width:'24px', height:'15px', marginTop:'30px' }}/>
                  </div>
                </div>
              </div>

              <div className="CardProjects" 
                style={{border:'1px solid #c4c4c4'}}>
                <div id="CardContent" style={{cursor:'pointer'}} 
                  onClick={()=>{setOpenModalPlantilla(true);setModalNew(false)}} >
                  <div className="ImgProyect" style={{marginLeft:'0px', marginBottom:'60px'}}>
                    <span><img src={plantilla}  height="75px" width='150px' /></span>
                  </div>

                  <div id="CenterContentProyectCard" style={{marginLeft: '105px', marginRight:'0px'}}>
                    <div className="flex-grow-1 overflow-hidden" style={{marginRight:'-10px'}}>
                      <div id="AddProyecto" style={{fontSize:'16px', fontWeight:'700'}}>
                        {i18n.t("Plantilla de referencia")}</div>
                      <p className="DescriptionText" style={{fontSize:'13px'}}>
                        Selecciona una plantilla y empieza a trabajar en tu presupuesto</p>
                      </div>
                      <img src={higherThan} style={{width:'24px', height:'15px', marginTop:'30px' }}/>
                    </div>
                  </div>
                </div>

                <div className="CardProjects" style={{border:'1px solid #c4c4c4'}}>
                  <div id="CardContent" style={{cursor:'pointer'}} 
                    onClick={()=>{setOpenCreateFromOther(true); setOpenModalOther(true); setModalNew(false)}}>
                    <div className="ImgProyect" style={{marginLeft:'0px', marginBottom:'60px'}}>
                      <span><img src={excel}  height="75px" width='150px' /></span>
                    </div>

                    <div id="CenterContentProyectCard" style={{marginLeft: '105px', marginRight:'0px'}}>
                      <div className="flex-grow-1 overflow-hidden" style={{marginRight:'-10px'}}>
                        <div id="AddProyecto" style={{fontSize:'16px', fontWeight:'700'}}>
                          {i18n.t("otherFormats")}</div>
                        <p style={{fontSize:'13px', fontFamily:"Helvetica", marginRight:"18px"}}>
                          Sube aquí <strong>Excel, PDF, CSV, CAD, Revit, TXT, DOC (Word)</strong> y recibirás un correo cuando esté cargado.</p>
                      </div>
                      <img src={higherThan} style={{width:'24px', height:'15px', marginTop:'30px' }}/>
                    </div>
                  </div>
                </div>

            </div> { /* Fin de la primera columna */ }
                   
            <div style={{width:'50%'}} >

              <div className="CardProjects" onClick={onClickPersonalModal}
                style={{cursor:'pointer', border:'1px solid #c4c4c4'}} >
                <div id="CardContent">
                  <div className="ImgProyect" style={{marginLeft:'0px'}}>
                    <span><img src={bc3}  height="75px" width='150px' /></span>
                  </div>
                  <div id="CenterContentProyectCard" style={{marginLeft: '105px', marginRight:'0px'}}>
                    <div className="flex-grow-1 overflow-hidden" style={{marginRight:'-10px'}}>
                      <div id="AddProyecto" style={{fontSize:'16px', fontWeight:'700'}}>
                        {i18n.t("Cargar un BC3")}</div>
                      <p className="DescriptionText" style={{fontSize:'13px'}}>
                        Sube tu archivo bc3 y empieza a trabajar en tu presupuesto</p>
                    </div>
                    <img src={higherThan} style={{width:'24px', height:'15px', marginTop:'30px' }}/>
                  </div>
                </div>
              </div>
              
              <div className="CardProjects" style={{border:'1px solid #c4c4c4'}}>
                <div id="CardContent" style={{cursor:'pointer'}} 
                  onClick={()=>{setOpenCreateFromExcel(true); setOpenModalExcel(true); setModalNew(false)}} >
                  <div className="ImgProyect" style={{marginLeft:'0px', marginBottom:'60px'}}>
                    <span><img src={excelPlanhopper}  height="75px" width='150px' /></span>
                  </div>

                  <div id="CenterContentProyectCard" style={{marginLeft: '105px', marginRight:'0px'}}>
                    <div className="flex-grow-1 overflow-hidden" style={{marginRight:'-10px'}}>
                      <div id="AddProyecto" style={{fontSize:'16px', fontWeight:'700'}}>
                        {i18n.t("Plantilla Excel Planhopper")}</div>
                      <p className="DescriptionText" style={{fontSize:'13px'}}>
                        Descarga la plantilla Excel de Planhopper, rellénala y cárgala.</p>
                    </div>
                    <img src={higherThan} style={{width:'24px', height:'15px', marginTop:'30px' }}/>
                  </div>
                </div>
              </div>

              <div className="CardProjectsDisabled" style={{border:'1px solid #c4c4c4'}}>
                <div id="CardContent" style={{cursor:'pointer'}} disabled={true} >
                  <div className="ImgProyect" style={{marginLeft:'0px', marginBottom:'60px'}}>
                    <span><img src={planhopperAI}  height="75px" width='150px' /></span>
                  </div>

                  <div id="CenterContentProyectCard" style={{marginLeft: '105px', marginRight:'0px'}}>
                    <div className="flex-grow-1 overflow-hidden" style={{marginRight:'-10px'}}>
                      <div id="AddProyecto" style={{fontSize:'16px', fontWeight:'700'}}>
                        {i18n.t("Planhopper AI")}</div>
                      <p className="DescriptionText" style={{fontSize:'13px'}}>
                        Pronto podrás cargar PDFs, Excels y demás sin necesidad de darles formato previo.</p>
                    </div>
                    <img src={higherThan} style={{width:'24px', height:'15px', marginTop:'30px' }}/>
                  </div>
                </div>
              </div>

            </div> { /* Fin de la segunda columna */ }
          </div>
	
	        <div style={{textAlign: 'center'}}>
            <p><strong>¿Dudas?</strong></p>
            <p>Haz clic <a target="_blank" rel="noreferrer" href="https://help.planhopper.com/en/help/articles/9805042-como-comenzar-con-planhopper">aquí</a> para conocer todos los formatos de carga disponibles</p>
          </div>
	
        </div>
      </Modal>

      {openModalPlantilla && <ModalSelectPlantilla open={openModalPlantilla} setOpen={setOpenModalPlantilla} /> }
      {openCreateFromExcel && <ModalCreateFromExcel from="excel" modal_first={openModalExcel} setmodal_first={setOpenModalExcel} contactos={Contacts} />}
      {openCreateFromOther && <ModalCreateFromExcel from="other" modal_first={openModalOther} setmodal_first={setOpenModalOther} contactos={Contacts} /> }

    </>)
}

ModalSelectTypeProject.propTypes = {
    modalNew: PropTypes.bool, 
    setModalNew: PropTypes.func,
    setmodal_first: PropTypes.func,
    onClickPersonalModal: PropTypes.func
}
  

export default ModalSelectTypeProject;
