import React from "react";
import AddIcon from '@mui/icons-material/Add';
import { PoppinsSemiBold, HelveticaBold } from "common/typographies";
import { BlackHoverButton4CreateDynamically } from "common/containers";
import i18n from "i18n";
import { ActasTable } from "common/tables";
import PropTypes from 'prop-types';
import AsuntosComponent from "./AsuntosComponent";


const AsuntosTratados = ({actaValues, setActaValues, actNum, setActaRefs, actasRefs}) => {

    const addAsunto = () => {
        setActaValues({...actaValues, subjects:[...actaValues.subjects, {code:''  , subject: '', status: 'Pendiente', responsible: '', files: []}]})
        setActaRefs({...actasRefs, subjectsRefs:[...actasRefs.subjectsRefs, {subject: React.createRef(), status: React.createRef(), responsible: React.createRef()}]})
    }

    const deleteAsunto = (index) => {
        const newActaValues = {...actaValues}
        newActaValues.subjects.splice(index, 1)
        setActaValues(newActaValues)
        const newActaRefs = {...actasRefs}
        newActaRefs.subjectsRefs.splice(index, 1)
        setActaRefs(newActaRefs)
    }

  return (
    <>
            <div style={{width:'100%', height:'auto', backgroundColor:'#F8F8F8', marginTop:'10px', marginBottom:'16px', marginLeft:'0px',  borderRadius:'6px'}}>

                 <div className="inline" style={{ width:'100%', height:'100%', position:'relative',display:'flex', flexWrap:'wrap', borderRadius:'6px'}}>

                    <div style={{backgroundColor:'#F8F8F8', width:'100%', marginLeft:'20px', marginRight:'20px', }}>
                        <div style={{display:'flex', padding:'10px', paddingTop:'8px', paddingLeft:'0px', paddingBottom:'5px', marginBottom:'-10px'}}>
                                <PoppinsSemiBold style={{fontSize:'14px', minWidth:'200px'}} >
                                    {i18n.t('Asuntos tratados')}
                                </PoppinsSemiBold>
                        </div>
                        <ActasTable style={{marginBottom:'12px'}}>
                            <tr>
                                <th style={{width:'20%'}}>{i18n.t('Codigo')}</th>
                                <th style={{width:'35%'}}>{i18n.t('Asunto')}</th>
                                <th style={{width:'15%'}}>{i18n.t('Estado')}</th>
                                <th style={{width:'15%'}}>{i18n.t('Responsable')}</th>
                                <th style={{width:'15%'}}>{i18n.t('Archivos')}</th>
                            </tr>
                          <AsuntosComponent deleteAsunto={deleteAsunto} actasRefs={actasRefs} actaValues={actaValues} setActaValues={setActaValues} index={0} actNum={actNum}/>
                          {actaValues.subjects.length>1 && actaValues.subjects.map((asunto, index) => {
                                if(index>0){
                                    return <AsuntosComponent deleteAsunto={deleteAsunto} actasRefs={actasRefs} key={index} actaValues={actaValues} setActaValues={setActaValues} index={index} actNum={actNum}/>
                                }
                            })
                          }
                        </ActasTable>
                        <BlackHoverButton4CreateDynamically onClick={()=>addAsunto()} style={{marginBottom:'12px'}}>
                            <AddIcon style={{fontSize:'18px', marginRight:'5px', color:'#414141', marginLeft:'-5px', paddingTop:'1px'}}/>
                            <HelveticaBold style={{color:'#414141', fontWeight:'600', paddingTop:'1px', fontSize:'12px'}}>{i18n.t('Añadir asunto')}</HelveticaBold>
                        </BlackHoverButton4CreateDynamically>
                    </div>

                
                   </div>
            </div>
    </>
  );
}

AsuntosTratados.propTypes = {
    actaValues: PropTypes.object.isRequired,
    setActaValues: PropTypes.func.isRequired,
    actNum: PropTypes.number.isRequired,
    setActaRefs: PropTypes.func.isRequired,
    actasRefs: PropTypes.array.isRequired
}

export default AsuntosTratados;