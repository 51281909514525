import { useReducer } from "react"
import { Auth, API } from "aws-amplify"
import AuthReducer from "./authReducer"
import AuthContext from "./authContext"
import React from "react"
import PropTypes from "prop-types"
import axios from "axios"
import { useHistory } from "react-router-dom"
import * as amplitude from '@amplitude/analytics-browser';
import {
  REGISTRO_ERROR,
  REGISTRO_EXITOSO,
  LOGIN_EXITOSO,
  LOGIN_ERROR,
  LOGOUT,
  OBTENER_USUARIO,
  ERROR_USUARIO,
  MODIFICAR_USUARIO,
} from "../types"

import { 
  ON_SAVE_CARD, 
  SAVE_CARD_SUCCESS, 
  ON_GET_CUSTOMER_INFO, 
  GET_CUSTOMER_INFO_SUCCESS,
  ON_SAVE_BILLING_ADDRESS,
  SAVE_BILLING_ADDRESS_SUCCESS,
  ON_VALIDATE_COUPON,
  VALIDATE_COUPON_SUCCESS,
  VALIDATE_COUPON_ERROR,
  ON_DELETE_CARD,
  DELETE_CARD_SUCCESS,
  ON_PAYMENT_INTENT,
  PAYMENT_INTENT_ERROR,
  ON_SEND_INVITATION,
  SEND_INVITATION_SUCCESS,
  SEND_INVITATION_ERROR,
} from "./types"

const AuthState = props => {
  const SERVICE_ACCOUNT = 'service-account'
  const initialState = {
    isAuthenticated: false,
    user: null,
    statusRegister: false,
    errorLogin: { estado: false, mensaje: null },
    errorRegistro: { estado: false, mensaje: null },
    invitationLoading: false,
    waiting: true,
    errorType: "",
    showSnackbar: true,
    budgetingTemplate: {},
    billingTemplate: {},
    facturaTemplate: {},
    freeTrialExpired: false,
    cardInfo: {},
    billingInfo: {},
    form: false,
    subscription: {},
    card: {
      loading: false,
      error: false,
    },
    billingAddress: {
      loading: false,
      error: false,
    },
    coupon: {
      loading: false,
      error: false,
    },
    paymentIntent: {
      loading: false,
      error: false,
      hostedPage: null,
    },
    organizationFields: null,
    chargebeePortal : null,
    organization : {
      loading: false,
      error: false,
    },
    organizationUsers : [],
    organizationAdmin : {},
    internalUser: null,
    loadingPendingActions: false,
    pendingActions: {},
    budgetingTemplates: [],
    facturasTemplates: [],
    actTemplates: [],

  }
  const history = useHistory()
  const [state, dispatch] = useReducer(AuthReducer, initialState)
  const SERVICE_INTEGRATION = 'service-integration'

  const Register = async (data, utm_s, utm_c, utm_m, worker) => {
    try {
      const { username, password, email } = data
      if(password.length==0 || email.length==0){
        dispatch({
          type: REGISTRO_ERROR,
          payload: {
            estado: true,
            mensaje: "Todos los campos son obligatorios"
          }
        })
      }else{
        if(Object.keys(worker).length !== 0){
          const user = await Auth.signUp({
            username: email,
            password: password,
            attributes: {
              "custom:avatar":
                "https://d1t181n48swd5d.cloudfront.net/avatares/avatar1.png",
                "custom:utm_source": utm_s,
                "custom:utm_campaign": utm_c,
                "custom:utm_medium": utm_m,
                "custom:host_id":worker.invitedBy,
                "custom:role":worker.rol,
                "custom:remaining_downloads":'2',
              email: email,
            },
          })
          amplitude.track('Sign Up');
          dispatch({
            type: REGISTRO_EXITOSO,
            payload: user,
          })
        }else{
          const user = await Auth.signUp({
            username: email,
            password: password,
            attributes: {
              "custom:avatar":
                "https://d1t181n48swd5d.cloudfront.net/avatares/avatar1.png",
                "custom:utm_source": utm_s,
                "custom:utm_campaign": utm_c,
                "custom:utm_medium": utm_m,
               "custom:remaining_downloads":'2',
              email: email,
            },
          })
          amplitude.track('Sign Up');
          dispatch({
            type: REGISTRO_EXITOSO,
            payload: user,
          })
        }
 
      }
      
    } catch (error) {
      dispatch({
        type: REGISTRO_ERROR,
        payload: error.message,
      })
    }
  }
  const Login = async data => {
    try {
      const res = await Auth.signIn(data.email, data.password)
      localStorage.setItem("userAuth", JSON.stringify(res.attributes))
      amplitude.track('Log In');
      dispatch({
        type: LOGIN_EXITOSO,
        payload: res,
      })
      //return res
    } catch (error) {
      dispatch({
        type: LOGIN_ERROR,
        payload: error.message,
      })
      //return error
    }
  }
  const getUser = async () => {
    try {
      //const res = await Auth.currentUserInfo()
      const res = await Auth.currentUserPoolUser()
      localStorage.setItem("userAuth", JSON.stringify(res.attributes))
      if (res.attributes == undefined) {
        logOut()
        setTimeout(() => {
          window.history.go("/login")
        }, 1000)
      }
      dispatch({
        type: OBTENER_USUARIO,
        payload: res,
      })
    } catch (error) {
      localStorage.removeItem("userAuth")
      dispatch({ type: ERROR_USUARIO })
    }
  }

  const logOut = async () => {
    try {
      await Auth.signOut()
      localStorage.removeItem("userAuth")
      amplitude.reset();
      dispatch({
        type: LOGOUT,
      })
    } catch (error) {}
  }
  const updateUser = async updateUserInfo => {
    try {
      const user = await Auth.currentAuthenticatedUser()
      await Auth.updateUserAttributes(user, updateUserInfo)
      const updatedUser = await Auth.currentAuthenticatedUser()
      dispatch({ type: MODIFICAR_USUARIO, payload: updatedUser.attributes })
    } catch (error) {
      console.log(error)
    }
  }

  const getBudgetingTemplate = async () => {
    try {
      const payload = {
        headers: {
          'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
          'Access-Control-Allow-Origin': '*'
        }
      }
      const res = await API.get("service-budget", "/documentTemplates/default-budget-pdf", payload)
      if(Object.keys(res).length === 0){
        const payload = {
          headers: {
            'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
            'Access-Control-Allow-Origin': '*'
          },
          body:{
            documentTemplateId:"default-budget-pdf",
            typeDocument:"BUDGET",
            formatDocument:"PDF",
            logo:{
                bucket:null,
                key:null
            },
            theme:"fff",
            conditions:""
          }
        }
        const res2 = await API.post("service-budget", "/documentTemplates/create", payload)
        let aux = {...payload.body}
        aux.logo = null
        dispatch({ type: 'ADD_BUDGETING_TEMPLATE', payload: aux })
      }else{

        let objectURL = ''
        toDataUrl('https://cdn.tribboo.co/'+res.logo.key, (objectUrl) => {
          let aux = {...res}
          aux.logo= objectUrl
          aux.theme = res.theme=='#fff'?'fff':res.theme
            dispatch({ type: 'ADD_BUDGETING_TEMPLATE', payload: aux })
        })
      
      }
    }
     catch (error) {
      console.log(error)
    }
  
  }
   const toDataUrl = (url, callback) => {
    const xhr = new XMLHttpRequest();
 
    xhr.onload = () => {
        const reader = new FileReader();
        reader.onloadend = () => {
            callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.setRequestHeader("Access-Control-Allow-Origin", "*")
    xhr.responseType = 'blob';
    xhr.send();
  };
  
  const updateBudgetingTemplate = async (color, image, text, selectedFile) => {
    try {
      if(selectedFile!=undefined){
      let payload = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        },
        body: {
          filename: selectedFile.name,
          extension: selectedFile.name.split(".").slice(-1)[0],
          size: selectedFile.size,
          contentType: selectedFile.type,
          customDB: false,
            importedProject: false,
            tags: [],
            description: "",
            orgId: "",
        },
      }
      const res1 = await API.post("dev-PLANHOPPER-API", "/api/file/upload", payload)
      let aux={...selectedFile}
      aux.preview=image
      const res3 = await uploadFile({ file: selectedFile, presignedPostData: res1.uploadInfo })
      const payload2 = {
        headers: {
          'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
          'Access-Control-Allow-Origin': '*'
        },
        body:{
          documentTemplateId:"default-budget-pdf",
          typeDocument:"BUDGET",
          formatDocument:"PDF",
          logo:{
              bucket:res1.file.bucket ? res1.file.bucket : 'tribboo-app',
              key: res1.uploadInfo.fields.key ? res1.uploadInfo.fields.key : 'e408abc8-0511-42ae-9bea-f3c12914c890/png/logo_large.png'
          },
          theme:color.toString()=='fff' ? '#ffffff' : color.toString(),
          conditions:text
        }
      }
      const res2 = await API.post("service-budget", "/documentTemplates/create", payload2)
      }else{
        const payload2 = {
          headers: {
            'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
            'Access-Control-Allow-Origin': '*'
          },
          body:{
            documentTemplateId:"default-budget-pdf",
            typeDocument:"BUDGET",
            formatDocument:"PDF",
            logo:{
                bucket:'tribboo-app',
                key: 'e408abc8-0511-42ae-9bea-f3c12914c890/png/logo_large.png'
            },
            theme:color.toString()=='fff' ? '#ffffff' :color.toString(),
            conditions:text
          }
        }
        const res2 = await API.post("service-budget", "/documentTemplates/create", payload2)
      }
   
    } catch (error) {
      console.log(error)
    }

  }


  const uploadFile = async ({ file, presignedPostData }) => {
    try {
      const formData = new FormData()
      Object.keys(presignedPostData.fields).forEach(key => {
        formData.append(key, presignedPostData.fields[key])
      })
      formData.append("file", file)
      const res = await axios.post(presignedPostData.url, formData)
      return res
    } catch (error) {
      console.log(error)
    }
  }


  const closeSnackbar = () => {
    dispatch({ type: "CLOSE_SNACKBAR" })
  }

  const openFreeTrialExpire = () => {
    dispatch({ type: "OPEN_SNACKBAR" })
  }

  const setExpireFreeTrial = async () => {
    dispatch({ type: "EXPIRE_FREE_TRIAL" })
  }

  const HUBSPOTAPI = 'prod-Planhopper-HubSpot-Integration'
  const commonHeaders = async () => {
    return {
      'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      'Access-Control-Allow-Origin': '*'
    }
  }
  const getCustomerInfo = async () => {
    try{
      dispatch({
        type: ON_GET_CUSTOMER_INFO
      })
      const headers = await commonHeaders()
      const customerInfo = await API.get(
        SERVICE_INTEGRATION, "/chargebee/customerInfo", {headers: headers}
      )
      let auxCard = {}
      if(customerInfo.card != null){
        auxCard = {
          'typeOfCreditCard': customerInfo.card.card_type.toUpperCase(),
          'creditCardNumber': customerInfo.card.masked_number,
          'expireDate': customerInfo.card.expiry_month + '-' + customerInfo.card.expiry_year,
          'fullName': customerInfo.card.first_name ? customerInfo.card.first_name : '',
          'cardId': customerInfo.card.payment_source_id,
          'last4': customerInfo.card.last4,
          'cvv': '***',
          'referenceId' : customerInfo.customer.payment_method.reference_id
        }
      }
      let auxBillingAddress = {}
      if(customerInfo.customer.billing_address){
        auxBillingAddress = {
          'address': customerInfo.customer.billing_address.line1? customerInfo.customer.billing_address.line1 : '',
          'city': customerInfo.customer.billing_address.city? customerInfo.customer.billing_address.city : '',
          'state': customerInfo.customer.billing_address.state ? customerInfo.customer.billing_address.state : '',
          'zip': customerInfo.customer.billing_address.zip? customerInfo.customer.billing_address.zip : '',
          'country': customerInfo.customer.billing_address.country? customerInfo.customer.billing_address.country : '',
          'company': customerInfo.customer.billing_address.company? customerInfo.customer.billing_address.company : '',
          'vatNumber': customerInfo.customer.vat_number? customerInfo.customer.vat_number : '',
        }
      }
      dispatch({
        type: GET_CUSTOMER_INFO_SUCCESS,
        payload: {
          subscription: customerInfo.subscription,
          card: auxCard,
          billingAddress: auxBillingAddress
        }
      })
    }
    catch(error){
      console.log(error)
    }
  }
  const validateCoupon = async (coupon) => {
    try{
      dispatch({
        type: ON_VALIDATE_COUPON
      })
      const headers = await commonHeaders()
      const body = {
        'coupon': coupon
      }
      const res = await API.post(SERVICE_INTEGRATION, '/chargebee/validate-coupon', {headers: headers, body: body})
      dispatch({
        type: VALIDATE_COUPON_SUCCESS,
        payload: res.coupon
      })
    }
    catch(error){
      dispatch({
        type: VALIDATE_COUPON_ERROR
      })
    }
  }
  const createCard = async (data) => {
    try{
      dispatch({
        type: ON_SAVE_CARD
      })
      const headers = await commonHeaders()
      const body = {
        'cardNumber': data.cardNumber,
        'cvv': data.cvv,
        'expiryYear': data.expiryYear,
        'expiryMonth': data.expiryMonth,
        'first_name': data.firstName
      }
      const res = await API.post(
        SERVICE_INTEGRATION, "/chargebee/card", {headers: headers, body: body}
      )
      dispatch({
        type: SAVE_CARD_SUCCESS,
        payload:{
          card: {
            creditCardNumber: res.card.card.masked_number,
            cvv: '***',
            fullName: res.card.card.first_name? res.card.card.first_name : '',
            expireDate: res.card.card.expiry_year + '-' + res.card.card.expiry_month,
            cardId : res.card.id,
            typeOfCreditCard: res.card.card.brand.toUpperCase(),
            referenceId: res.card.reference_id
          }
        }
      })
    } 
    catch(error){
      console.log(error)
    }
  }

  const getSubscription = async () => {
    try {
      const headers = await commonHeaders()
      const res = await API.get(HUBSPOTAPI, "/chargebee/getCustomerInfo", {headers: headers})
      let card  = {}
      let billingInfo = {}
      if(Object.keys(res.card).length !== 0){
        card.creditCardNumber = res.card.masked_number
        card.cvv = '***'
        card.fullName = res.billing.first_name ? res.billing.first_name : ''
        card.expireDate = res.card.expiry_year + '-' + res.card.expiry_month
        card.type = res.card.card_type
        if(Object.keys(res.billing).length !== 0){
          if(res.billing.billing_address){
          billingInfo.orgName=res.billing.billing_address.company
          billingInfo.address=res.billing.billing_address.line1
          billingInfo.city=res.billing.billing_address.city
          billingInfo.postalCode=parseInt(res.billing.billing_address.zip)
          billingInfo.provincia=res.billing.billing_address.state
          billingInfo.country=res.billing.billing_address.country
          billingInfo.cif=res.billing.cf_cif}
        }
      }
      
      dispatch({ type: "GET_SUBSCRIPTION", payload: {
        cardInfo: card,
        billingInfo: billingInfo
      } })
    } catch (error) {
      console.log(error)
    }
  }
  const updateBillingAddress = async (data) => {
    try{
      dispatch({
        type: ON_SAVE_BILLING_ADDRESS
      })
      const headers = await commonHeaders()
      const body = {
        vatNumber: data.vatNumber,
        company : data.company,
        country: data.country,
        state: data.state,
        city: data.city,
        zip: data.zip,
        address: data.address
      }
      const res = await API.post(SERVICE_INTEGRATION, '/chargebee/billing', {headers: headers, body: body})
      dispatch({
        type: SAVE_BILLING_ADDRESS_SUCCESS,
        payload: res.customer.billingAddress
      })
    }
    catch(error){
      console.log(error)
    }
  }
  const saveCreditCard = async (card) => {
    try {
      const headers = {
        'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        'Access-Control-Allow-Origin': '*'
      }
      const body = {
        cardNumber:card.creditCardNumber.toString(),
        cvv:card.cvv.toString(),
        expiryYear:parseInt(card.expireDate.split("-")[0]),
        expiryMonth:parseInt(card.expireDate.split("-")[1]),
        firstName:card.fullName,
      }
      const res = await API.post(HUBSPOTAPI, "/chargebee/cardinfo", {headers:headers, body:body})
      
   
    } catch (error) {
      console.log(error)
    }
  }

  const saveFacturation = async (facturation, pais) => {
    try {
      const headers = {
        'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        'Access-Control-Allow-Origin': '*'
      }
      const body = {
          orgName:facturation.orgName,
          address:facturation.address,
          city:facturation.city,
          postalCode:facturation.postalCode.toString(),
          provincia:facturation.provincia,
          country:pais.value,
          cif:facturation.cif,
      }
     
      const res = await API.put(HUBSPOTAPI, "/chargebee/billinginfo", {headers:headers, body:body})

    } catch (error) {
      console.log(error)
    }
  }

  const deleteCard = async (cardId) => {
    try {
      dispatch({
        type: ON_DELETE_CARD,
      })
      const headers = await commonHeaders()
      const res = await API.del(SERVICE_INTEGRATION, "/chargebee/card/"+cardId, {headers:headers})
      dispatch({
        type: DELETE_CARD_SUCCESS
      })
    } catch (error) {
      console.log(error)
    }
  }


  const updateBillingTemplate = async (color, image, text, iban, paymentType, selectedFile) => {
    try {
      if(selectedFile!=undefined){
      let payload = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        },
        body: {
          filename: selectedFile.name,
          extension: selectedFile.name.split(".").slice(-1)[0],
          size: selectedFile.size,
          contentType: selectedFile.type,
          customDB: false,
            importedProject: false,
            tags: [],
            description: "",
            orgId: "",
        },
      }
      const res1 = await API.post("dev-PLANHOPPER-API", "/api/file/upload", payload)
      let aux={...selectedFile}
      aux.preview=image
      const res3 = await uploadFile({ file: selectedFile, presignedPostData: res1.uploadInfo })
      const payload2 = {
        headers: {
          'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
          'Access-Control-Allow-Origin': '*'
        },
        body:{
          documentTemplateId:"default-invoice-pdf",
          typeDocument:"INVOICE",
          formatDocument:"PDF",
          logo:{
              bucket:res1.file.bucket ? res1.file.bucket : 'tribboo-app',
              key: res1.uploadInfo.fields.key ? res1.uploadInfo.fields.key : 'e408abc8-0511-42ae-9bea-f3c12914c890/png/logo_large.png'
          },
          theme:color.toString() == 'fff' ? '#ffffff' : color.toString(),
          conditions:text,
          iban:iban,
          payment_method: paymentType
        }
      }
      const res2 = await API.post("service-budget", "/documentTemplates/create", payload2)
      }else{
        const payload2 = {
          headers: {
            'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
            'Access-Control-Allow-Origin': '*'
          },
          body:{
            documentTemplateId:"default-invoice-pdf",
            typeDocument:"INVOICE",
            formatDocument:"PDF",
            logo: {
              bucket: "tribboo-app",
              key: "e408abc8-0511-42ae-9bea-f3c12914c890/png/logo_large.png"
             },
            theme:color.toString() == 'fff' ? '#ffffff' : color.toString(),
            conditions:text,
            iban:iban,
            payment_method: paymentType
          }
        }
        const res2 = await API.post("service-budget", "/documentTemplates/create", payload2)
      }
   
    } catch (error) {
      console.log(error)
    }

  }
  
  const getBillingTemplate = async () => {
    try {
      const payload = {
        headers: {
          'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
          'Access-Control-Allow-Origin': '*'
        }
      }
      const res = await API.get("service-budget", "/documentTemplates/default-invoice-pdf", payload)
      if(Object.keys(res).length === 0){
        const payload = {
          headers: {
            'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
            'Access-Control-Allow-Origin': '*'
          },
          body:{
            documentTemplateId:"default-invoice-pdf",
            typeDocument:"INVOICE",
            formatDocument:"PDF",
            logo:{
                bucket:null,
                key:null
            },
            theme: "#ffffff",
            conditions:"",
            iban:"",
            payment_method: ""
          }
        }
        const res2 = await API.post("service-budget", "/documentTemplates/create", payload)
        let aux = {...payload.body}
        aux.logo = null
        dispatch({ type: 'ADD_INVOICING_TEMPLATE', payload: aux })
      }else{
        let objectURL = ''
   
        toDataUrl('https://cdn.tribboo.co/'+res.logo.key, (objectUrl) => {
          let aux = {...res,
          iban: res.iban==null ? "" : res.iban,
          payment_method: res.payment_method==null ? "" : res.payment_method}
          aux.logo= objectUrl
            dispatch({ type: 'ADD_INVOICING_TEMPLATE', payload: aux })
        })
      
      }
  
    }
     catch (error) {
      console.log(error)
    }
  
  }

  const setForm = () => {
    dispatch({ type: 'SET_FORM', payload: true })
  }

  const createPaymentIntent = async (data) => {
    try{
      dispatch({
        type: ON_PAYMENT_INTENT
      })
      const headers = await commonHeaders()
      const body = {
        subscriptionId : data.subscriptionId
      }
      return API.post(SERVICE_INTEGRATION, '/chargebee/checkout', { headers, body }).then(res => res.checkout)
      
    }
    catch(error){
      dispatch({
        type: PAYMENT_INTENT_ERROR,
        payload: error
      })
    }
  }

  const updateChargebeeAccount = async (data) => {
    try {
      const headers = await commonHeaders()
      const res = await API.post(SERVICE_INTEGRATION, "/cognito/update/plan", {headers:headers, body: data})
      location.reload()
    } catch (error) {
      console.log(error)
    }
  }

  const createChargebeePortal = async () => {
    try {
      const headers = await commonHeaders()
      const res = await API.post(SERVICE_INTEGRATION, "/chargebee/portal", {headers:headers})
      dispatch({ type: 'GET_PORTAL_SUCCESS', payload: res })
    } catch (error) {
      console.log(error)
    }
  }

  const sendInvitation = async (data) => {
    try {
      dispatch({
        type: ON_SEND_INVITATION
      })
      const headers = await commonHeaders()
      const res = await API.post(SERVICE_ACCOUNT, "/invite", {headers:headers, body: {
        email: data.email,
        organizationId: data.organizationId,
        customRole: data.customRole,
        name: data.name
      }})
      console.log(res)
      dispatch({
        type: SEND_INVITATION_SUCCESS,
        payload : res.member
      })
      return res
    }
    catch (error) {
      dispatch({
        type: SEND_INVITATION_ERROR,
      })
      return error.response.data.message.toLowerCase()
    }
  }

  const getOrganization = async ({organizationId}) => {
    try{
      dispatch({ type: 'GETING_ORGANIZATION'})
      const headers = await commonHeaders()
      const res = await API.get(SERVICE_ACCOUNT, '/organization/'+organizationId, {headers:headers})
      console.log(res)
      dispatch({ type: 'GET_ORGANIZATION_SUCCESS', payload: res.organization })
    }catch(error){
      console.log(error)
      //dispatch({ type: 'EMPTY_ORGANIZATION'})
    }
  }

   const updateOrganization = async (organizationId, data) => {
      try {
      console.log(organizationId, data)
      console.log('/dev/organization-ddb/'+organizationId)
        const headers = await commonHeaders()
        const res = await API.post(SERVICE_ACCOUNT, '/organization-ddb/'+organizationId, {headers:headers, body:data})

      } catch (error) {
        console.log(error)
      }
    }

    const getOrganizationNew = async (organizationId) => {
        try{
          dispatch({ type: 'GETING_ORGANIZATION'})
          const headers = await commonHeaders()
          const res = await API.get(SERVICE_ACCOUNT, '/organization-ddb/'+organizationId, {headers:headers})
          console.log(res)
          dispatch({ type: 'GET_ORGANIZATION_NEW', payload: res })
        }catch(error){
          console.log(error)
          //dispatch({ type: 'EMPTY_ORGANIZATION'})
        }
      }

  const getActualUser = async () => {
    try{
      const headers = await commonHeaders()
      const res = await API.get(SERVICE_ACCOUNT, '/me/', {headers:headers})
      dispatch({ type: 'GET_ACTUAL_USER_SUCCESS', payload: res.user })
    }catch(error){
      console.log(error)
    }
  }

  const submitForm = async (data) => {
    try{
      const headers = await commonHeaders()
      const res = await API.post(SERVICE_ACCOUNT, '/onboarding', {headers:headers, body:data})
      dispatch({
        type: OBTENER_USUARIO,
        payload: res.user,
      })
    }catch(error){
      console.log(error)
    }
  }



  const executePendingActions = async () => {
    dispatch({ type: 'EXECUTE_PENDING_ACTIONS'})
  }

  const setPendingActions = async (data) => {
    try {
      const headers = await commonHeaders()
      const res = await API.put(SERVICE_ACCOUNT, '/updatePendingActions', {headers:headers, body:data})
      dispatch({ type: 'SET_PENDING_ACTIONS'})
    } catch (error) {
      console.log(error)
    }
  }

  const getPendingActions = async (mail) => {
    try{
      const headers = await commonHeaders()
      const res = await API.get(SERVICE_ACCOUNT, '/getPendingActions/'+mail, {headers:headers})
      dispatch({ type: 'GET_PENDING_ACTIONS', payload: res.pendingActions })
    }catch(error){
      console.log(error)
    }
  }

  const getTemplates = async (type) => {
    try{
      const headers = await commonHeaders()
      const res = await API.get(SERVICE_ACCOUNT, '/document-templates?type='+type, {headers:headers})
      dispatch({ type: 'SET_TEMPLATES', payload: {res: res.templates, type: type} })
      let auxTemplates = []
          res.templates.forEach((template) => {
            if (template.id != 621616) {
              auxTemplates.push(template)
            }
          })
      return auxTemplates
    }catch(error){
      console.log(error)
    }
  }

  const updateInternalUser = async (data) => {
    try{
      const headers = await commonHeaders()
      const res = await API.put(SERVICE_ACCOUNT, '/me/', {headers:headers, body: data})
    }catch(error){
      console.log(error)
    }
  }

  return (
    <AuthContext.Provider
      value={{
        user: state.user,
        isAuthenticated: state.isAuthenticated,
        statusRegister: state.statusRegister,
        waiting: state.waiting,
        Register,
        Login,
        getUser,
        submitForm,
        logOut,
        updateUser,
        createChargebeePortal,
        errorLogin: state.errorLogin,
        errorRegistro: state.errorRegistro,
        errorType: state.errorType,
        updateChargebeeAccount,
        closeSnackbar,
        showSnackbar: state.showSnackbar,
        budgetingTemplate: state.budgetingTemplate,
        facturaTemplate: state.facturaTemplate,
        getBudgetingTemplate,
        updateBudgetingTemplate,
        setExpireFreeTrial,
        freeTrialExpired: state.freeTrialExpired,
        getSubscription, saveCreditCard,
        saveFacturation, cardInfo: state.cardInfo,
        billingInfo: state.billingInfo,
        deleteCard,
        updateBillingTemplate,
        getBillingTemplate,
        billingTemplate: state.billingTemplate,
        form: state.form,
        getCustomerInfo,
        setForm,
        subscription: state.subscription,
        card: state.card,
        createCard,
        updateBillingAddress,
        billingAddress : state.billingAddress,
        validateCoupon,
        coupon: state.coupon,
        createPaymentIntent,
        paymentIntent : state.paymentIntent,
        openFreeTrialExpire,
        chargebeePortal: state.chargebeePortal,
        sendInvitation,
        getOrganization,
        organizationUsers : state.organizationUsers,
        organizationAdmin : state.organizationAdmin,
        getActualUser,
        organization: state.organization,
        internalUser: state.internalUser,
        executePendingActions,
        loadingPendingActions: state.loadingPendingActions,
        setPendingActions,
        getPendingActions,
        pendingActions: state.pendingActions,
        getTemplates,
        budgetingTemplates: state.budgetingTemplates,
        facturaTemplates: state.facturaTemplates,
        actTemplates: state.actTemplates,
        updateInternalUser,
        updateOrganization,
        getOrganizationNew,
        organizationFields: state.organizationFields
      }}
    >
      {props.children}
    </AuthContext.Provider>
  )
}

AuthState.propTypes = {
  children: PropTypes.any,
}

export default AuthState

