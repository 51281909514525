import React, { useContext, useEffect } from 'react'
import { Modal} from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types'
import { useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import PlanningContext from 'context/planning/planningContext';
import { useParams } from 'react-router-dom';
import i18n from 'i18n';

function getModalStyle() {
    const top = 30 ;
    const left = 37;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: '393px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 5, 3),
        borderRadius: 6,
        maxHeight: 'calc(100vh - 100px)',
        overflowY: 'auto',
        overflowX:'hidden',
    },
}));

const UpdateKanbanModal = ({open, setOpen, infoCol}) => {
    const {projectId} = useParams()
    const {updatePlanningColumn} = useContext(PlanningContext)
    const colors=['#15B3D6', '#8758E9', '#E82D2D', '#E2E800', '#E95EC2', '#54C16C', '#FFC727']
    const [ modalStyle ] = useState(getModalStyle);
    const classes = useStyles();
    const [colores, setColor] = useState(infoCol.color)
    const [titulo, setTitulo] = useState('')
    useEffect(()=>{setColor(infoCol.color); setTitulo(infoCol.title)},[infoCol])
    const handleTitulo = (e) => {
        setTitulo(e.target.value)
        
    }
    const updateCol = async (e) => {
        e.preventDefault();
        var aux = {
            id: infoCol.id,
            bgColor: colores,
            color: colores,
            title: titulo
        }
        await updatePlanningColumn(aux, projectId)
        setOpen(false)
    }
    return(
        <Modal open={open} onClose={() => null}>
            <div style={modalStyle} className={classes.paper} >
               <p style={{fontFamily:"Helvetica", marginBottom:'0px', marginTop:'0px', fontSize:'14px'}}>{i18n.t("Nombre")}</p> 
            <input autoFocus style={{width:'100%', textTransform:'uppercase',  marginBottom:'-5px'}}
                        value={titulo}
                        onChange={(e)=>{handleTitulo(e)}}
                      className="NombreAlign"
                      name='name'
                      placeholder="Introduce el nombre"
                      autoComplete="off"
                    ></input>
                <p style={{fontFamily:"Helvetica", marginBottom:'0px', fontSize:'14px', marginTop:'10px'}}>{i18n.t("Color")}:</p> 
                <div style={{marginBottom:'1px', marginTop:'5PX',  display:'flex'}}>
                  {colors.map((color,  index) => {
                      
                      return(
                          colores==color ?
                          <div key={index} style={{backgroundColor:color, width:'25px', height:'25px',  marginRight:'15px', borderRadius:'6px'}}>
                              <CheckIcon style={{color:'white', width:'18px', marginLeft:'3px'}}/>
                          </div> :
                          <div key={index} style={{backgroundColor:color, width:'25px', height:'25px',  marginRight:'15px', borderRadius:'6px', cursor:'pointer'}} onClick={()=>setColor(color)}>

                          </div>
                      )
                  })}
                </div>
                <div id='centerPaco' style={{marginTop:'25px', marginBottom:'10px', justifyContent:'left'}}>
                    <button className='AddContact' onClick={(e)=>{updateCol(e)}}>{i18n.t("Guardar")}</button>
                </div>
            </div>
        </Modal>
    )
}

UpdateKanbanModal.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    infoCol: PropTypes.object
}
export default UpdateKanbanModal;