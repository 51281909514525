import styled from "styled-components"

export const YellowButton = styled.button`
  width: 186px;
  height: 48px;
  box-shadow: none;
  border: none;
  background: #ffd52b;
  border-radius: 6px;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #414141;
  transform: matrix(1, 0.01, -0.01, 1, 0, 0);

  :hover {
    background-color: #fee398;
  }
`

export const RegisterOrLoginOrLogoutButton = styled.button`
  width: 186px;
  height: 48px;
  filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.1));
  border: 1px solid #ffd52b;
  box-sizing: border-box;
  border-radius: 6px;
  background-color: white;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #414141;
  transform: matrix(1, 0.01, -0.01, 1, 0, 0);
`

export const StyledProviderButton = styled.button`
  border: none;
  padding: 1px;
  background: #ed6335;
  border-radius: 6px;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #ffffff;
  height: 28px;
`

export const UnsetButton = styled.button`
  all: unset;
  cursor: "pointer";
`
