import React from "react"
import { HelveticaBold } from "common/typographies"
import i18n from "i18n"
import { CircularProgress } from "@mui/material"
const InvoiceLoader = () => {
    return (
        <>
                <div style={{ width:'100%', position:'absolute', transform:'translateY(-50%, -50%)',  top:'40%', left:'45%'}}>
                <CircularProgress
                    style={{ width: 150, height: 150, marginLeft:'0px', color:'#414141', marginBottom:'20px' }}
                />
                <HelveticaBold style={{color:'#414141', fontSize:'24px', marginLeft:'10px', marginTop:'0px'}}>{i18n.t("Cargando...")}</HelveticaBold>
                </div>
                   
            </>
    )
}
export default InvoiceLoader