import React, {useState} from "react";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import {Modal} from "@mui/material";
import {makeStyles} from "@material-ui/core/styles";
import CloseBud from 'assets/images/budgeting/CloseBud.png'
import ModalNewSend from "./ModalNewSend";
import i18n from "i18n";
function getModalStyle() {
    const top = 50 ;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: '630px',
        height: '470px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: "0px 3px 5px -1px rgb(0 0 0 / 7%), 0px 5px 8px 0px rgb(0 0 0 / 7%), 0px 1px 14px 0px rgb(0 0 0 / 7%)",
        padding: theme.spacing(2, 4, 3),
        borderRadius: 6,
        maxHeight: 'calc(100vh - 100px)',
        overflowY: 'auto',
        overflowX:'hidden',
        height: '440px'
    },
}));



const ModalCerrarPres = ({projectInfo, openCerrar, setOpenCerrar, open3, setOpen3, contactos}) => {
    const [ modalStyle ] = useState(getModalStyle);
    const classes = useStyles();
    const [openSend, setOpenSend] = useState(false)
    const guardarPres = (e) => {
        setOpenSend(true)
        setOpenCerrar(false)
    }
    
    return (
   <>
   <Modal open={openCerrar} onClose={()=>{setOpenCerrar(false)}}>
            <div id="totalDiv">
                <div style={modalStyle} className={classes.paper}>
                <CloseIcon id="cerrar" onClick={()=>{setOpenCerrar(false)}}style={{marginTop:'0px', paddingLeft:'6px', marginRight:'-10px'}}></CloseIcon>
                    <div style={{justifyContent:'center', display:'flex', marginTop:'35px'}}>
                    <img src={CloseBud} style={{width:'117px', height:'117px'}}/>
                    
                    </div>
                   
                    <div id="DivTitleEliminar" style={{marginTop:'25px', width:'100%' , justifyContent:'center', display:'flex'}}>
                        <p id="centradoBudget">
                        {i18n.t("¿Deseas cerrar presupuesto?")}
                         </p>
                    </div>
                    <p style={{marginTop:'10px'}} className="lightPoppins">
                        {i18n.t("Una vez cerrado, podrás visualizarlo en versiones para modificarlo en caso de ser necesario.")}
                     </p>
                    <div id="centerButton"><button type="button"
                             className="AddContact" onClick={guardarPres}>{i18n.t("Cerrar")}</button></div>
                    </div>
            </div>
        </Modal>
        <ModalNewSend projectInfo={projectInfo} openSend={openSend} setOpenSend={setOpenSend} open3={open3} setOpen3={setOpen3} contactos={contactos}/>
        </>
    );
}

ModalCerrarPres.propTypes = {
    projectInfo: PropTypes.object.isRequired,
    openCerrar: PropTypes.bool.isRequired,
    setOpenCerrar: PropTypes.func.isRequired,
    open3: PropTypes.bool.isRequired,
    setOpen3: PropTypes.func.isRequired,
    contactos : PropTypes.object.isRequired,
};

export default ModalCerrarPres;