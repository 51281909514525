import React, { useRef, useState, useEffect} from "react"
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import {CircularProgress, Modal} from "@mui/material";
import {makeStyles} from "@material-ui/core/styles";
import i18n from "i18n";


import { calcApplyDiscount } from "utils/partUtils";

function getModalStyle() {
    const top = 50 ;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: '444px',
        height: 'auto',
        backgroundColor: theme.palette.background.paper,
        boxShadow: "0px 3px 5px -1px rgb(0 0 0 / 7%), 0px 5px 8px 0px rgb(0 0 0 / 7%), 0px 1px 14px 0px rgb(0 0 0 / 7%)",
        padding: theme.spacing(2, 4, 3),
        borderRadius: 6,
        maxHeight: 'calc(100vh - 100px)',
        overflowY: 'auto',
        overflowX:'hidden',
    },
}));



const ModalEditDiscount = ({open, setOpen, partidaInfo, setPartidaState, partidaState}) => {
    const [ modalStyle ] = useState(getModalStyle);
    const classes = useStyles();
    const refGG = useRef(null);
    const [discount, setDiscount] = useState(0)

    const handleClose = () => {
        setOpen(false);
    }
    useEffect(() => {
        if(open==true){
            if(partidaState.discount){
                setDiscount(partidaState.discount)
            }
            else if (partidaInfo){
                setDiscount(partidaInfo.discount)
            }
        }
     }, [open])

    const handleChange = (e) => {
        if(e.target.value>=0){
       setDiscount(e.target.value)
        }else{
            setDiscount((e.target.value)*(-1))
        }
    }
    const [loading, setLoading] = useState(false)

    const saveDiscountLocal = () => {
        setLoading(true)
        if(discount>0){
            setPartidaState({...partidaState,
                discount: parseInt(discount),
                clientPrice: calcApplyDiscount(partidaState.clientPriceWithoutDiscount, discount)
            })
        }else{
            setPartidaState({...partidaState,
                discount: 0,
                clientPrice: partidaState.clientPriceWithoutDiscount
            })
        }
        setOpen(false)
        setLoading(false)
    }

    return (
   
   <Modal open={open} onClose={handleClose}>
            <div id="totalDiv">
                <div style={modalStyle} className={classes.paper}>
                <CloseIcon id="cerrar" onClick={()=>{setOpen(false)}}style={{marginTop:'0px', paddingLeft:'6px', marginRight:'-10px'}}></CloseIcon>
                    <div id="DivTitleEliminar" style={{marginTop:'24px', justifyContent:'center'}}>
                        <p id="centradoBudget" style={{fontSize:'20px', marginTop:'0px', marginBottom:'30px'}} >{i18n.t("Define el descuento para esta partida")} </p>
                    </div>
                    <div style={{width:"233px", marginLeft:"auto", marginRight:"auto", marginBottom:'10px'}}>
                        <div className="marginTableRows" id="linePress">
                            <p style={{borderRadius: "5px 0px 0px 5px"}} className="mlGG firstp">{i18n.t("Descuento aplicable")}</p>
                            <input style={{borderRight:'none'}} ref={refGG} id="inputGasto" 
                                 onChange={handleChange}
                                type="number" name="discount" value={discount}
                            />
                            <p style={{ borderLeft:'0px', 
                                                     backgroundColor:'white',
                                                    width:'20px', textAlign:'center', paddingLeft:'0px',
                                                    borderRadius: "0px 5px 5px 0px"
                                                }} 
                                            className="mlGG lastp">{i18n.t("%")}
                            </p>
                        </div>
                    </div>
                    {loading ? <CircularProgress size={24} style={{marginTop:'30px', marginLeft:'170px'}}/> : 
                    <div id="centerButton" style={{marginTop:'30px', marginBottom:'6px'}}><button 
                        style={{height:'38px', width:'103px'}}
                        type="button"
                        className="AddContact" 
                        onClick={saveDiscountLocal}>{i18n.t("Guardar")}</button></div>
                    }
                </div>
                    
            </div>
        </Modal>
    );
}

ModalEditDiscount.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    partidaInfo: PropTypes.object.isRequired,
    setPartidaState: PropTypes.func.isRequired,
    partidaState: PropTypes.object.isRequired,
};

export default ModalEditDiscount;
