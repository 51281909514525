import React,{Fragment,useContext, useState} from "react"
import i18n from "i18n"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import PropTypes from "prop-types"
import { HelveticaBold, PoppinsNormal } from "common/typographies";
import { YellowButton } from "common/buttons";
import OrgRow from "./orgRow";


const COLUMNS = [
    {
        id: 'none',
        label: '',
        show: true,
        type: 'none'
    },
    {
        id: 'name',
        label: i18n.t('Nombre'),
        show: true,
        type: 'name'
    },{
        id: 'email',
        label: i18n.t('Email'),
        show: true,
        type: 'string'
    },{
        id: 'role',
        label: i18n.t('Rol equipo'),
        show: true,
        type: 'string'
    },
    {
        id: 'status',
        label: i18n.t('Estado miembro'),
        show: true,
        type: 'status'
    }
]

const userStatus = (status) => {
    // let firmas = 0
    // for(let i = 0; i < status.attendees.length; i++){
    //     if(status.attendees[i].hasSigned){
    //         firmas += 1
    //     }
    // }
    // let bgColor = '' 
    // let color = 'white' 
    // let text = '' 

    // if(firmas === status.attendees.length){
    //     bgColor = '#0C8527'
    //     text = 'FIRMADA '+firmas+'/'+status.attendees.length

    // }else if(firmas > 0){
    //     bgColor = '#EE950F'
    //     text = 'FIRMADA '+firmas+'/'+status.attendees.length

    // }else{
    //     bgColor = '#BF2929'
    //     text = 'SIN FIRMAR '+firmas+'/'+status.attendees.length
   
    // }
    let bgColor = '#0C8527'
    let text = 'ACTIVO'
    let color = 'white'
    return(
        <div className="inline" style={{marginTop:'0px',position:'relative', backgroundColor: bgColor, height:'22px' , borderRadius:'6px', width:'75px', padding:'0px'}}>
            
            <PoppinsNormal style={{fontSize:'9px', color: color, lineHeight:'20px', cursor:'pointer', paddingTop:'1px', paddingLeft:'3px', marginLeft:'10px'}}>
            {text}
            </PoppinsNormal>
           
        </div>
    )
}



const userName = (user) => {
    
    let bgColor = '#C9DBCD'
    return(
        <div className="inline" style={{position:'relative', height:'100%', maxWidth:'40px', minWidth:'40px', overflow: 'inherit'}}>
            <div style={{minWidth:'35px', minHeight:'35px', maxWidth:'35px', maxHeight:'35px', borderRadius:'100px', backgroundColor:bgColor, marginRight:'0px', marginLeft:'5px'}}>
            </div>
          
        
        </div>
    )
}




const OrgTableCustom = ({orgUsers, orgAdmin}) =>{
    const [columns, setColumns] = useState(COLUMNS)
    const goToEdit = (e, user) => {
        e.preventDefault()
        e.stopPropagation()
    }

    const resolveValue = (user, column) => {
        switch(column.type){
            case 'date':
                try{
                    const dt= new Date(user[column.id]).toLocaleDateString()
                    if  (dt === 'Invalid Date') return null
                    return dt
                }catch(e){
                    return ''
                }
            case 'none':
                return userName(user)
            case 'string':
                try{
                    let ids = column.id.split('.')
                    if(ids.length > 1){
                        return user[ids[0]][ids[1]]
                    }
                    return user[column.id]

                }catch(e){
                    return <YellowButton style={{width:'70px', height:'30px', fontWeight:'600', cursor:'pointer'}} onClick={(e)=>{goToEdit(e, user)}}>Editar</YellowButton>
                }
            case 'attendees':
                try{
                   let firstAttendee = user.attendees[0].name
                   return firstAttendee
                }catch(e){
                    return ''
                }
            case 'status':
                return userStatus(user)
            case 'name':
                try{
                    let ids = column.id.split('.')
                    if(ids.length > 1){
                        return user[ids[0]][ids[1]]
                    }
                    return user[column.id]

                }catch(e){
                    return <YellowButton style={{width:'70px', height:'30px', fontWeight:'600', cursor:'pointer'}} onClick={(e)=>{goToEdit(e, user)}}>Editar</YellowButton>
                }
        }
    }

    return (
    <Fragment>

        <div style={{
                alignItems:'center',
                justifyContent:'center',
                display:'flex',
                marginRight:'20px',
                marginLeft:'30px',

            }}
        >
            <TableContainer component={Paper}
                sx={{
                maxWidth: '95vw', overflow: 'hidden', height: 'auto'
                }}
            >
            <Table sx={{ minWidth: 650}} aria-label="simple table">
                <TableHead>
                <TableRow
                >
                    {columns.map((column, index) => (
                            column.show &&
                            <TableCell key={index} id='helveticaTitle'>
                                {column.label}
                            </TableCell>
                    ))}
                    <TableCell >
                    <div style={{height:'20px'}}> </div>
                    </TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                 {orgUsers.map((user) => (
                    <OrgRow user={user} key={user} columns={columns} resolveValue={resolveValue}/>
                ))} 
                </TableBody>
            
            </Table>
            </TableContainer>
       </div>
    </Fragment>
    );
}

OrgTableCustom.propTypes = {
    orgUsers: PropTypes.array.isRequired,
    orgAdmin: PropTypes.object.isRequired
}


export default OrgTableCustom