import i18n from "i18n";
import {Modal} from "@mui/material";
import {makeStyles} from "@material-ui/core/styles";
import React, { useState, useContext } from "react";
import PropTypes from 'prop-types'
import { useHistory, useParams } from "react-router-dom";
import { HelveticaBold } from "common/typographies";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import {nanoid} from "nanoid"
import iconFace from "assets/customIcons/icon-face.svg"

function getModalStyle() {
    const top = 50 ;
    const left = 50;
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };

}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: '600px',
        backgroundColor: 'white',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        borderRadius: 6,
        maxHeight: '335px',
        overflowY: 'auto',
        overflowX:'hidden',
        paddingLeft:'0px',
        paddingRight:'0px'
    },
}));

const ModalAntesDeEmpezar = ({setOpen, open, setOpenNextStep, setNum, num, imGeneral, from}) => {
    const [ modalStyle ] = useState(getModalStyle);
    const classes = useStyles();
    const history = useHistory();
    const {projectId} = useParams()

    const handleContinuar = () => {
        setOpen(false)
        if(num){  //La variable numero viene definida desde SquareContent donde el siguiente paso es abrir un modal o una redirección a create
            if(num==1){
                setOpenNextStep()
            }
            else{
              
                history.replace(`/invoice/${nanoid()}${projectId? `?projectId=${projectId}` : ''}`)
            }
        }else{  //En este caso el siguiente paso es abrir el modal de crear factura para decidir cual de los dos casos quiere el usuario
            setOpenNextStep(true)
        }
        
        setNum(0)
    }

    const handleClick = () => {
        if(!projectId){
            history.push({pathname: '/templateManagement', state: {from: "invoices"}})
        } else {
            history.push({pathname: '/templateManagement', state: {from: "invoicesProject", project: projectId}})
        }
    }
   
  return(
      <>
    <Modal
        open={open}
        onClose={() => {null}}
    >
         <div style={modalStyle} className={classes.paper}>
            <CloseOutlined onClick={()=>{setOpen(false)}}  style={{position:'absolute', right:'15px', cursor:'pointer', width:'15px'}}/>
                <div id='centerPaco'>
                    <img src={iconFace} style={{width:'70px', height:'70px', marginBottom:'25px', marginTop:'-20px'}}></img>
                </div>
                <div style={{width:'100%', marginTop:'10px', position:'relative', display:'grid', justifyContent:'center', textAlign:'center', marginTop:'20px', marginBottom:'30px'}}>
                    <HelveticaBold style={{color:'#414141', fontSize:'24px', marginBottom:'5px'}}>
                        {i18n.t("Antes de empezar")}
                    </HelveticaBold>
                    <HelveticaBold style={{color:'#41414199', marginBottom:'10px', fontSize:'14px', fontWeight:'400', lineHeight:'15px', paddingLeft:'60px', paddingRight:'60px'}}>
                        {i18n.t("Puedes personaliza el aspecto de tu factura en el apartado de plantillas.")}
                    </HelveticaBold>
                </div>

                <div className="inline" style={{width:'100%', justifyContent:'center', bottom:'0px', marginTop:'30px', marginBottom:'5px', position:'relative', marginTop:'10px'}}>
                    <button onClick={()=>{handleContinuar()}} className="AddProject" style={{marginRight:'10px', bottom:'0px',position:'relative',  paddingRight:'0px', width:'240px', border: '2px solid #FFD52B', background:'white'}}>
                        {i18n.t("Continuar sin personalizar")}
                    </button>
                    <button className='AddProject' onClick={()=>{handleClick()}} style={{position:'relative', bottom:'0px', paddingRight:'10px', cursor:'pointer', width:'140px', border: '2px solid #FFD52B', background:'#FFD52B'}} >
                        {i18n.t("Ir a plantillas")}
                    </button>
                </div>
     
        </div>
       
    </Modal>
    </>
  )
}
ModalAntesDeEmpezar.propTypes = {
    setOpen: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    setOpenNextStep: PropTypes.func,
    setNum: PropTypes.func,
    num: PropTypes.number,
    imGeneral: PropTypes.bool,
    from: PropTypes.string,
}
export default ModalAntesDeEmpezar