import React,{Fragment,useState, useEffect, useContext} from 'react';
import i18n from 'i18n';
import PropTypes from 'prop-types';
import { PoppinsSemiBold, HelveticaBold } from 'common/typographies';
import { HelveticaInput } from 'common/inputs';
import { FakeInput } from 'common/containers';
import AuthContext from 'context/auth/authContext';
import ModalSelectContact from 'components/Facturas/modals/modalSelectContact';
import circlePlus from 'assets/images/icons/circlePlus.svg';
import DropdownSetProject from 'components/Facturas/dropdowns/dropdownSetProject';
import ProjectContext from 'context/projects/projectContext';
import ContactContext from 'context/contact/contactContext';
const InvoicePageGeneralInfo = ({
        invoice, 
        setInvoice, 
        setOpenModalSearchParts, 
        localInvoice,
        getIdentifierNumber,
        projectError,
        contactError,
    }) => {
    const [selectedContact, setSelectedContact] = useState(null);
    const [open, setOpen] = useState(false);
    const {Contacts, getContacts} = useContext(ContactContext)
    const {user} = useContext(AuthContext)
    const {projects, getProjects} = useContext(ProjectContext)
    useEffect(()=>{
        
            setInvoice({
                ...invoice,
                contact: selectedContact
            })
    },[selectedContact])
    const handleChange = (e) => {
        setInvoice({
            ...invoice,
            [e.target.name]: e.target.value
        })
    }

    const currentDate= () =>{
        let date = new Date();
        let day = date.getDate();
        let month = date.getMonth()+1;
        let year = date.getFullYear();
        if(day<10){
            day = '0'+day;
        }
        if(month<10){
            month = '0'+month;
        }
        return day+'/'+month+'/'+year;
    }
    
    useEffect(() => {
      if(localInvoice === true && projects.length === 0){
        getProjects()
      }
    }, [localInvoice])
    const getContact = () => {
            if(Contacts){
                let aux = Contacts.filter(contact => contact.id == invoice.projectInfo.client)
                if(aux.length > 0){
                    setSelectedContact(aux[0])
                }else{
                    setSelectedContact(null)
                }
            }
    }

    useEffect(() => {
        getContacts()
    }, [])

    useEffect(() => {
        if(Contacts.length > 0){
        if(invoice.projectInfo){
            if(invoice.projectInfo.client){
               getContact() 
            }
        }}
    }, [invoice?.projectInfo, Contacts])
    return(
        <Fragment>
        <div style={{width:'96.4%', height:'auto', backgroundColor:'#F8F8F8', marginTop:'10px', marginBottom:'10px', marginLeft:'30px',  borderRadius:'6px'}}>
                 <div className="inline" style={{ width:'100%', height:'100%',paddingTop:'10px', paddingBottom:'20px', position:'relative', display:'flex', flexWrap:'wrap'}}>
                    <div style={{backgroundColor:'#F8F8F8', width:'26.5%', marginRight:'30px', minWidth:'250px'}}>
                        <div style={{display:'flex', padding:'10px', marginBottom:'-10px'}}>
                                <PoppinsSemiBold style={{fontSize:'14px', marginLeft:'10px', minWidth:'200px'}}>
                                    {i18n.t('Nombre del proyecto')}
                                </PoppinsSemiBold>
                        </div>
                        { 
                            localInvoice=== true?
                            <DropdownSetProject 
                                projectList={projects}
                                invoice={invoice}
                                setInvoice={setInvoice}
                                getIdentifierNumber={getIdentifierNumber}
                                projectError={projectError}

                            />:
                            <HelveticaInput
                                value={ invoice.projectInfo.name} contentEditable={false}
                                 style={{marginTop:'-25px', height:'40px', marginLeft:'20px', width:'100%', paddingLeft:'10px', minWidth:'250px'}} 
                            />
                        }
                    </div>

                    <div style={{backgroundColor:'#F8F8F8', width:'26.5%', marginRight:'30px', minWidth:'250px'}}>
                        <div style={{display:'flex', padding:'10px', marginBottom:'-10px'}}>
                                <PoppinsSemiBold style={{fontSize:'14px', marginLeft:'10px'}}>
                                    {i18n.t('Cliente')}
                                </PoppinsSemiBold>
                        </div>
                        {
                            invoice.contact?
                            <FakeInput
                                onClick={()=>{setOpen(true)}} 
                                className="inline" 
                                style={{
                                    marginTop:'-20px', marginLeft:'20px', width:'100%', paddingLeft:'10px',
                                    minWidth:'250px',display:'flex', overflow:'hidden',
                                    textOverflow:'ellipsis', whiteSpace:'nowrap'}}
                            >
                                <img src={ user && user['custom:avatar']} width={40}/>
                                <HelveticaBold style={{
                                    fontWeight:'300', color:'#414141', fontSize:'14px', marginLeft:'5px',
                                    marginTop:'9px'}}>
                                        {invoice.contact.completeName}
                                </HelveticaBold>
                            </FakeInput>
                        :
                        <FakeInput  
                            onClick={()=>{setOpen(true)}} 
                            className="inline" 
                            style={{marginTop:'-20px', marginLeft:'20px', width:'100%', paddingLeft:'10px', minWidth:'250px',
                                    border : contactError? '1px solid red' : '1px solid #b7b7b7'
                        }}
                        >
                            <img src={circlePlus} width={20}/>
                            <HelveticaBold style={{
                                fontWeight:'300', fontSize:'14px', marginLeft:'5px', marginTop:'9px', color:'#b7b7b7'
                            }}>
                                {i18n.t('Buscar contactos')}
                            </HelveticaBold>
                        </FakeInput>
                        }
                       
                    </div>

                    <div style={{backgroundColor:'#F8F8F8', width:'10%', marginRight:'30px', minWidth:'150px'}}>
                        <div style={{display:'flex', padding:'10px', marginBottom:'-10px'}}>
                                <PoppinsSemiBold 
                                    style={{fontSize:'14px', marginLeft:'10px', minWidth:'150px'}}
                                >
                                    {i18n.t('Numero de factura')}
                                </PoppinsSemiBold>
                        </div>
                        <HelveticaInput
                            value={invoice.invoiceNumber} 
                            name="invoiceNumber"
                            onChange={(e)=>{handleChange(e)}}
                            style={{marginTop:'-25px', marginLeft:'20px', width:'100%', paddingLeft:'10px', height:'40px', minWidth:'130px'}} 
                        />
                    </div>

                    <div style={{backgroundColor:'#F8F8F8', width:'10%', marginRight:'30px', minWidth:'150px'}}>
                        <div style={{display:'flex', padding:'10px', marginBottom:'-10px'}}>
                                <PoppinsSemiBold style={{fontSize:'14px', marginLeft:'10px'}}>
                                    {i18n.t('Fecha')}
                                </PoppinsSemiBold>
                        </div>
                        <HelveticaInput
                            type='date'
                            value={invoice.invoiceDate? invoice.invoiceDate
                            : currentDate()
                            } 
                            name="invoiceDate" 
                            onChange={(e)=>{handleChange(e)}}
                            style={{marginTop:'-25px', marginLeft:'20px', height:'40px', width:'100%', paddingLeft:'10px',
                             minWidth:'130px', color: invoice.invoiceDate && '#b7b7b7'
                            }}
                        />
                    </div>
                    <div style={{backgroundColor:'#F8F8F8', width:'10%', minWidth:'150px', marginRight:'30px'}}>
                        <div style={{display:'flex', padding:'10px', marginBottom:'-10px'}}>
                                <PoppinsSemiBold 
                                    style={{fontSize:'14px', marginLeft:'10px', minWidth:'150px'}}
                                >
                                    {i18n.t('Fecha de vencimiento')}
                                </PoppinsSemiBold>
                        </div>
                        <HelveticaInput
                            type='date'
                            value={ invoice.invoiceDueDate? invoice.invoiceDueDate: currentDate() }   
                            name="invoiceDueDate" 
                            onChange={(e)=>{handleChange(e)}}
                            style={{marginTop:'-25px', marginLeft:'20px', height:'40px', width:'100%', paddingLeft:'10px', minWidth:'130px', 
                            color: invoice.invoiceDueDate && '#b7b7b7'}} 
                        />
                    </div>

                </div>
            </div>
            {
                open &&   <ModalSelectContact open={open} setOpen={setOpen} setContactSelected={setSelectedContact}/>
            }
          
        </Fragment>
    )
}
InvoicePageGeneralInfo.propTypes = {
    invoice: PropTypes.object,
    setInvoice: PropTypes.func,
    setOpenModalSearchParts: PropTypes.func,
    localInvoice: PropTypes.bool,
    getIdentifierNumber: PropTypes.func,
    projectError: PropTypes.bool,
    contactError: PropTypes.bool,
}

export default InvoicePageGeneralInfo