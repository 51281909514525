import React, { useContext, useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import BankPricesContext from "context/bankPrices/bankPricesContext"
import JustificantePrecio from "components/Project/Overview/JustificantePrecio"
import AuthContext from "context/auth/authContext"
import { HelveticaBold } from "common/typographies"
import NewModalPartida from "pages/ModalPartidas/NewModalPartida"
import { getCurrency } from "utils/functions"
import ArrowDownIcon from "assets/customIcons/ArrowDownIcon.svg"
import ArrowUpIcon from "assets/customIcons/ArrowUpIcon.svg"

const Part = ({ part, referencia, index, partsLength }) => {
  const [openAdd, setOpenAdd] = useState(false)
  const referenciaLines = useRef(null)
  const {
    deleteBankPart,
    checkPartida,
    updatePartsList,
    checkMultipleParts,
    myPricesBank,
    selectedPartGroup,
    selectPartsGroup,
    updatePartPrice,
    cleanChapters,
    cleanParts,
    movePartsWithArrows,
  } = useContext(BankPricesContext)
  const [listaParaEliminar, setLista] = useState([])
  const [deleteState, setDeleteState] = useState(false)
  const [tocaEliminar, setTocaEliminar] = useState(false)
  const [price, setPrice] = useState(
    part.userPrice ? part.userPrice : part.price ? part.price : 0
  )
  const { user, freeTrialExpired } = useContext(AuthContext)

  useEffect(async () => {
    if (deleteState) {
      await selectPartsGroup([], myPricesBank.subcapitulos)
      setTocaEliminar(true)
    }
  }, [deleteState])
  useEffect(async () => {
    if (selectedPartGroup) {
      let auxList = []
      if (selectedPartGroup.length > 0) {
        let auxChapter = selectedPartGroup[0][0].chapterId
        let chapter = false
        for (let i = 0; i < selectedPartGroup.length; i++) {
          if (selectedPartGroup[i]) {
            if (selectedPartGroup[i].length > 0) {
              for (let j = 0; j < selectedPartGroup[i].length; j++) {
                if (selectedPartGroup[i][j].chapterId != auxChapter) {
                  chapter = true
                }
                auxList.push(selectedPartGroup[i][j])
              }
            }
          }
        }
        setLista(auxList)
      }
      if (deleteState && selectedPartGroup.length == 0) {
        await deleteBankPart(part.chapterId, part.partidaId, part.accountId)
        await cleanChapters()
        await cleanParts()
      } else if (deleteState && selectedPartGroup.length > 0) {
        for (let index = 0; index < auxList.length; index++) {
          await deleteBankPart(
            auxList[index].chapterId,
            auxList[index].partidaId,
            auxList[index].accountId
          )
          await cleanChapters()
          await cleanParts()
        }
      }
      setDeleteState(false)
      setTocaEliminar(false)
    }
  }, [selectedPartGroup, deleteState, tocaEliminar])

  useEffect(() => {
    if (part.name) {
      referenciaLines.current.innerText = part.name
    }
  }, [part.name])

  useEffect(() => {
    if (part) {
      setPrice(part.userPrice ? part.userPrice : part.price ? part.price : 0)
    }
  }, [part])

  const handleCheckBox = e => {
    if (e.nativeEvent.shiftKey) {
      if (part.checked) {
        checkPartida(part.chapterId, part.partidaId)
        updatePartsList(part)
      } else {
        checkMultipleParts(part.chapterId, part)
      }
    } else {
      checkPartida(part.chapterId, part.partidaId)
      updatePartsList(part)
    }
  }
  const changePrice = e => {
    setPrice(parseFloat(e.target.value))
  }
  const handleOnBlur = async e => {
    if (price != part.userPrice) {
      await updatePartPrice({ ...part, userPrice: price })
    }
  }

  return (
    <>
      <tr
        className="partidasInfo"
        style={{
          cursor: "pointer",
          border: part.checked ? "1.5px solid #FFD52B" : "0px",
        }}
        onClick={() => {
          setOpenAdd(true)
        }}
      >
        <td style={{ borderLeft: "none", width: "79px", paddingRight: "5px" }}>
          <div
            className="inline"
            style={{ marginLeft: "10px", height: "35px" }}
          >
            <div className="detallesPartiShow2"></div>
            <input
              type={"checkbox"}
              onChange={e => handleCheckBox(e)}
              checked={part.checked}
              onClick={e => e.stopPropagation()}
              style={{
                width: "13px",
                marginRight: "5px",
                cursor: "pointer",
                marginTop: "-2px",
              }}
            ></input>
            <HelveticaBold
              style={{
                color: "#414141",
                fontSize: "14px",
                marginTop: "9px",
                marginRight: "5px",
              }}
            >
              {referencia}
            </HelveticaBold>
            <p style={{ paddingTop: "10px", color: "#B7B7B7" }}>{part.unity}</p>
          </div>
        </td>
        <td
          style={{ fontWeight: "300", textAlign: "left", paddingLeft: "40px" }}
        >
          <p
            style={{
              display: "block",
              textAlign: "left",
              marginBottom: "0px",
              marginRight: "20px",
            }}
          >
            {" "}
            <span
              style={{ maxWidth: "100% !important" }}
              ref={referenciaLines}
              className="partidaNameAutoExpandible"
              role="textbox"
              autoComplete="off"
              name="name"
            ></span>
          </p>
        </td>
        <td
          style={{
            minWidth: "127px",
            color: "#414141",
            background:
              part.decomposition?.length > 0 ? "#E4E4E4" : "transparent",
          }}
        >
          <div>
            {part ? (
              <div>
                <input
                  style={{
                    color: "#414141",
                    fontWeight: "400",
                    backgroundColor: "transparent",
                  }}
                  id="inputUnidades"
                  min="0"
                  type="number"
                  onClick={e => e.stopPropagation()}
                  onChange={changePrice}
                  onBlur={handleOnBlur}
                  name="userPrice"
                  value={price}
                  disabled={
                    user
                      ? freeTrialExpired || user["custom:role"] === "WORKER"
                        ? true
                        : part.decomposition?.length > 0
                        ? true
                        : false
                      : false
                  }
                />
              </div>
            ) : (
              <div>{getCurrency(user["custom:currency"])}/u</div>
            )}
          </div>
        </td>
        <td
          style={{
            borderLeft: "none",
            minWidth: "40px",
          }}
        >
          <div
            className="detallesPartiShow"
            style={{
              backgroundColor: "transparent",
              width: "55px",
              minWidth: "55px",

              justifyContent: "center",
              marginLeft: "-5px",
            }}
          >
            {user ? (
              freeTrialExpired || user["custom:role"] === "WORKER" ? null : (
                <DeleteOutlineIcon
                  style={{ width: "19px", cursor: "pointer" }}
                  onClick={e => {
                    e.stopPropagation()
                    setDeleteState(true)
                  }}
                />
              )
            ) : null}
            {index + 1 < partsLength && (
              <img
                src={ArrowDownIcon}
                onClick={() => {
                  movePartsWithArrows(part, "down")
                }}
                style={{
                  width: "15px",
                  height: "15px",
                  marginTop: "5px",
                  cursor: "pointer",
                }}
              />
            )}
            {index !== 0 && (
              <img
                src={ArrowUpIcon}
                onClick={() => {
                  movePartsWithArrows(part, "up")
                }}
                style={{
                  width: "13px",
                  height: "13px",
                  marginTop: "5px",
                  cursor: "pointer",
                }}
              />
            )}
          </div>
        </td>
      </tr>
      {openAdd && (
        <NewModalPartida
          open={openAdd}
          setOpen={setOpenAdd}
          chapterId={part.chapterBankId}
          partidaInfo={part}
          bancoDePrecios={"MI BANCO DE PRECIOS"}
          srcPace={"priceBanks"}
        />
      )}
    </>
  )
}
Part.propTypes = {
  part: PropTypes.object.isRequired,
  referencia: PropTypes.string,
  index: PropTypes.number,
  partsLength: PropTypes.number,
}
export default Part
