import React, { useState, useContext, useEffect } from "react"
import MetaTags from "react-meta-tags"
import UploadDocument from "../../components/Documents/UploadDocument"
import { Container } from "reactstrap"
import DocumentDropZone from "components/Documents/DocumentDropZone"
import FileContext from "context/file/fileContext"
import ContactContext from "context/contact/contactContext"
import CardFiles from "../../components/Documents/CardFiles"
import { RecordPageView } from "App"
import LayoutDocuments from "./layoutDocuments"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import FilesEmpty from "assets/customIcons/empty_files_folder.png"

const IndexProjectPage = () => {
  RecordPageView()
  const [selectedDocument, setSelectedDocument] = useState([])
  const [openUploadModal, setOpenUploadModal] = useState(false)
  const handleOpen = () => setOpenDropZone(true)
  const [openDropZone, setOpenDropZone] = useState(false)
  const [selectedFiles, setSelectedFiles] = useState([])
  const fileContext = useContext(FileContext)
  const contactContext = useContext(ContactContext)
  const { Contacts, getContacts } = contactContext
  const {
    getPostUrl,
    uploadProcess,
    getMyFiles,
    files,
    updateFile,
    loading,
    filteredFilesByProject
  } = fileContext

  const {projectId} =  useParams()


  const handleUpload = e => {
    let auxInfo = {
      tags: [],
      customDB: false,
      importedProject: false,
      description: "",
      orgId: "",
    }
    e.preventDefault()
    getPostUrl(selectedFiles[0], auxInfo)
    setOpenDropZone(false)
    setOpenUploadModal(true)
  }


  useEffect(() => {
    getMyFiles()
    getContacts()
  }, [])

  return (
    <>
      <div className="page-content" style={{overflowX:'hidden'}}>
        <MetaTags>
          <title>Planhopper | Archivos</title>
        </MetaTags>
        <Container fluid style={{height:'80vh', marginLeft:'30px', paddingRight:'0px', marginRight:'30px', width:'auto'}}>
        <LayoutDocuments handleOpen={handleOpen} imInProject={true}/>
        {!filteredFilesByProject[projectId] && !loading ?
            <div style={{display:'flex', justifyContent:'center', alignItems:'center', height:'100%'}}>
              <div style={{width:'393px', height:'auto', borderRadius:'10px', padding:'30px 30px 26px 30px', 
              backgroundColor:'white', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', textAlign:'center'}}>
                <img
                  id="imagePaco"
                  src={FilesEmpty}
                  style={{
                    marginBottom:'30px'
                  }}
                ></img>
                <p className="DescriptionText" style={{fontSize:'14px', marginBottom:'0px'}}>Ups! Aún no tienes archivos importados. <br/>Agrega el primero haciendo <b>click en importar.</b></p>
                </div>
            </div>
            :
            <>

        <div className="inline" style={{flexWrap:'wrap'}}>
        <CardFiles
          files={filteredFilesByProject[projectId] ? filteredFilesByProject[projectId] : []}
          selectedDocument={selectedDocument}
          setSelectedDocument={setSelectedDocument}
        /> 
        </div>

        <UploadDocument
          open={openUploadModal}
          setOpen={setOpenUploadModal}
          uploadProcess={uploadProcess}
          contactos={Contacts}
          updateFile={updateFile}
        />

        <DocumentDropZone
          open={openDropZone}
          setOpen={setOpenDropZone}
          selectedFiles={selectedFiles}
          setselectedFiles={setSelectedFiles}
          handleSubmit={handleUpload}
          uploadProcess={uploadProcess}
        />
        </>
                }
        </Container>

      </div>
    </>
  )
}

export default IndexProjectPage
