import MetaTags from "react-meta-tags";
import React, {useEffect, useContext } from "react";
import PlanningKanban from "../../components/Planning/PlanningKanban";
import i18n from "i18n";
import {
    Container,
} from "reactstrap";
import { useParams, withRouter } from "react-router-dom";
import ProjectContext from "context/projects/projectContext";
import { PoppinsNormal } from "common/typographies";
const PlanningKanbanV1 = props => {
    const {projectId} = useParams()
    const {getProject, projectInfo} = useContext(ProjectContext)

    useEffect(() => {
      getProject(projectId)

    } , [])
    
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Planning</title>
                </MetaTags>
                <Container fluid>
                    {projectInfo ? 
                    <PlanningKanban  projectInfo={projectInfo}></PlanningKanban> :  <>
                    <div style={{justifyContent:'center', width:'100%', textAlign:'center', position:'relative', display:'flex', marginLeft:'-100px', marginTop:'70px'}}>
                    <div className='loader'>
                        <div className='ball'></div>
                        <div className='ball'></div>
                        <div className='ball'></div>
                   </div>
                    </div>
                    <div style={{justifyContent:'center', width:'100%', textAlign:'center', position:'relative', display:'flex', marginLeft:'-30px'}}>
                    <PoppinsNormal>{i18n.t("Cargando...")}</PoppinsNormal>
                    </div>
                    <div style={{justifyContent:'center', width:'100%', textAlign:'center', position:'relative', display:'flex', marginLeft:'-30px', marginTop:'-15px'}}>
                    <PoppinsNormal style={{fontWeight:'400'}}>{i18n.t("Esta acción puede tardar unos segundos")}</PoppinsNormal>
                    </div>
                   
                    </> }
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withRouter(PlanningKanbanV1);