import React, { useContext, useEffect, useRef, useState } from "react"
import { useHistory } from "react-router-dom"
import ProjectContext from "context/projects/projectContext"
import CloseIcon from "@mui/icons-material/Close"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import authContext from "../../context/auth/authContext"
import SuccessModal from "components/Feed/SuccessModal"
import { makeStyles } from "@material-ui/core/styles"
import {
  CircularProgress,
  Modal,
} from "@mui/material"
import PropTypes from "prop-types"
import AddContact from "../../components/Contacts/ContactTable/addContact"
import ModalImageProject from "./ModalImageProject"
import CheckCircleOutlineSharpIcon from "@mui/icons-material/CheckCircleOutlineSharp"
import ErrorOutlineSharpIcon from "@mui/icons-material/ErrorOutlineSharp"
import i18n from "i18n"
import ModalWaitingExcel from "./ModalWaitingExcel"
import UploadExcelModal from "components/Feed/uploadExcel"
import UploadOtherFormatsModal from "components/Feed/UploadOtherFormatsModal"

function getModalStyle() {
  const top = 50
  const left = 50
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "522px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 6,
    maxHeight: "calc(100vh - 100px)",
    overflowY: "auto",
    overflowX: "hidden",
  },
}))

const ModalCreateFromExcel = ({
  from,
  modal_first,
  setmodal_first,
  contactos,
  imComingFromNoProjects,
}) => {
  const [modalStyle] = useState(getModalStyle)
  const classes = useStyles()

  /*Funciones para las etiquetas*/
  const [input, setInput] = useState("")
  const [tags, setTags] = useState([])
  const [tagsFinal, setTagsFinal] = useState([])
  const [isKeyReleased, setIsKeyReleased] = useState(false)
  const [color, setColor] = useState(0)
  const {projectInfo} = useContext(ProjectContext)
  const [success, setSuccess] = useState(false)
  const listaColores = [
    "color1",
    "color2",
    "color3",
    "color4",
    "color5",
    "color6",
    "color7",
    "color8",
  ]
  const [openAdd, setOpenAdd] = useState(false)

  const [contacts, setContacts] = useState(contactos)
  const [documentInfo, setDocumentInfo] = useState({
    filename: "",
    description: "",
    tagsFinal: [],
    members: [],
  })

  const handleOpenAdd = e => {
    e.preventDefault()
    setOpenAdd(true)
  }

  useEffect(() => {
    setContacts(contactos)
  }, [contactos])

  const addMember = option => {
    setDocumentInfo({
      ...documentInfo,
      members: [...documentInfo.members, option],
    })
  }

  const onKeyDown = e => {
    const { key } = e
    const trimmedInput = input.trim()

    if (
      key === "Enter" &&
      trimmedInput.length &&
      !tags.includes(trimmedInput)
    ) {
      e.preventDefault()
      setTags(prevState => [...prevState, [trimmedInput, listaColores[color]]])
      setTagsFinal(prevState => [...prevState, trimmedInput])
      setInput("")
      if (color != 7) {
        setColor(color + 1)
      } else {
        setColor(0)
      }
    }

    if (key === "Backspace" && !input.length && tags.length && isKeyReleased) {
      const tagsCopy = [...tags]
      const poppedTag = tagsCopy.pop()
      e.preventDefault()
      setTags(tagsCopy)
      setInput(poppedTag[0])
    }

    setIsKeyReleased(false)
  }

  const AddTagOnClick = e => {
    const trimmedInput = input.trim()

    if (trimmedInput.length && !tags.includes(trimmedInput)) {
      e.preventDefault()
      setTags(prevState => [...prevState, [trimmedInput, listaColores[color]]])
      setTagsFinal(prevState => [...prevState, trimmedInput])
      setInput("")
      if (color != 7) {
        setColor(color + 1)
      } else {
        setColor(0)
      }
    }

    setIsKeyReleased(false)
  }

  const onKeyUp = () => {
    setIsKeyReleased(true)
  }
  const deleteTag = (e, index) => {
    e.preventDefault()
    setTags(prevState => prevState.filter((tag, i) => i !== index))
    setTagsFinal(prevState => prevState.filter((tag, i) => i !== index))
  }

  const [newProject, setNewProject] = useState({})

  const selectTag = e => {
    var etiqueta = e.target.innerHTML
    e.target.className = "hide"
    const trimmedInput = etiqueta.trim()
    e.preventDefault()
    setTags(prevState => [...prevState, [trimmedInput, listaColores[color]]])
    setTagsFinal(prevState => [...prevState, trimmedInput])
    setInput("")
    if (color != 7) {
      setColor(color + 1)
    } else {
      setColor(0)
    }
  }
  const AuthContext = useContext(authContext)
  const { user, getUser } = AuthContext
  //useEffect(() => {  getUser()}, [])

  /*Referencias para cambio de estilos en validacion de formulario*/
  const refNombre = useRef(null)
  const refMovil = useRef(null)
  const refEmail = useRef(null)
  const refDni = useRef(null)
  const refEmpresa = useRef(null)
  const refTags = useRef(null)
  const refDir = useRef(null)

  /*Referencias para iconos confirm and error*/
  const refNombreConfirm = useRef(null)
  const refMovilConfirm = useRef(null)
  const refEmailConfirm = useRef(null)
  const refDniConfirm = useRef(null)
  const refEmpresaConfirm = useRef(null)
  const refTagsConfirm = useRef(null)
  const refDirConfirm = useRef(null)

  const refNombreError = useRef(null)
  const refMovilError = useRef(null)
  const refEmailError = useRef(null)
  const refDniError = useRef(null)
  const refEmpresaError = useRef(null)
  const refTagsError = useRef(null)
  const refDirError = useRef(null)

  let valida = false
  let validaNombre = true
  let validaMail = true
  let validaMovil = true
  let validaNif = true
  let validaEmpresa = true
  let validaDir = true

  const [selectedContact, setSelectedContact] = useState(null)

  const handleOnClick = e => {
    if (e.target.name == "name") {
      refNombre.current["id"] = "focus"
      refNombreConfirm.current.id = "confirmNotVisible"
      refNombreError.current.id = "errorNotVisible"
    } else if (e.target.name == "client") {
      refDni.current["id"] = "focus"
      refDniConfirm.current.id = "confirmNotVisible"
      refDniError.current.id = "errorNotVisible"
    } else if (e.target.name == "description") {
      refEmpresa.current["id"] = "focus"
      refEmpresaConfirm.current.id = "confirmNotVisible"
      refEmpresaError.current.id = "errorNotVisible"
    } else if (e.target.name == "orgAddress") {
      refDir.current["id"] = "focus"
      refDirConfirm.current.id = "confirmNotVisible"
      refDirError.current.id = "errorNotVisible"
    } else if (e.target.name == "identifier_number") {
      refMovil.current["id"] = "focus"
      refMovilConfirm.current.id = "confirmNotVisible"
      refMovilError.current.id = "errorNotVisible"
    } else if (e.target.name == "email") {
      refEmail.current["id"] = "focus"
      refEmailConfirm.current.id = "confirmNotVisible"
      refEmailError.current.id = "errorNotVisible"
    } else if (e.target.name == "tags") {
      refTags.current["id"] = "focus"
    }
  }

  function nameIsValid(name) {
    var matches = name.match(/\d+/g)
    if (matches != null) {
      return false
    } else {
      return true
    }
  }
  function isNumeric(num) {
    return !isNaN(num)
  }

  const projectContext = useContext(ProjectContext)
  const { createProject, createProjectFromNoProjects } = projectContext
  const { projects, getProjects } = projectContext
  const [openExcel, setOpenExcel] = useState(false)
  const [openOther, setOpenOther] = useState(false)
  const [waitModal, setWaitModal] = useState(false)

  const [values, setValues] = useState({
    name: "",
    description: "",
    identifier_number: "",
    orgAddress: "",
    client: "",
    tags: [],
  })
  const { name, description, identifier_number, userEmail } = values

  const [campos, guardarCampos] = useState({
    name: "",
    description: "",
    identifier_number: "",
    orgAddress: "",
    client: "",
    tags: [],
    imagen: "",
  })

  const getLastProject = () => {
    let ultimaFecha = ""
    let iteracion = 0
    for (let i = 0; i < projects.length; i++) {
      if (i == 0) {
        ultimaFecha = projects[i].updatedAt
        iteracion = i
      } else {
        if (projects[i].createdAt > ultimaFecha) {
          ultimaFecha = projects[i].createdAt
          iteracion = i
        }
      }
    }
    return projects[iteracion]
  }

  const obtenerCampos = e => {
    guardarCampos({
      ...campos,
      [e.target.name]: e.target.value,
    })
  }
  const onEtiqueta = e => {
    const { value } = e.target
    setInput(value)
  }
  const history = useHistory()
  const [loading, setLoading] = useState(false)

  const onSubmit = async e => {
    //e.preventDefault();
    if (campos.name.length > 0) {
      validaNombre = true
      refNombre.current.id = " "
      refNombreConfirm.current.id = "confirmVisibleNombre"
      refNombreError.current.id = "errorNotVisible"
    } else {
      refNombre.current.id = "errorValidation"
      validaNombre = false
      refNombreConfirm.current.id = "confirmNotVisible"
      refNombreError.current.id = "errorVisibleNombre"
    }
    // if (
    //   //isNumeric(campos.identifier_number) &&
    //   campos.identifier_number.length > 0
    // ) {
    //   validaMovil = true
    //   refMovil.current.id = " "
    //   refMovilError.current.id = "errorNotVisible"
    //   refMovilConfirm.current.id = "confirmVisibleId"
    // } else {
    //   refMovil.current.id = "errorValidation"
    //   validaMovil = false
    //   refMovilError.current.id = "errorVisibleId"
    //   refMovilConfirm.current.id = "confirmNotVisible"
    // }
    /*
        if(campos.description.length>0){
            validaEmpresa=true;
            refEmpresa.current.id=" ";
            refEmpresaError.current.id="errorNotVisible"
            refEmpresaConfirm.current.id="confirmVisible"
        }else{
            refEmpresa.current.id="errorValidation"
            validaEmpresa=false;
            refEmpresaError.current.id="errorVisible"
            refEmpresaConfirm.current.id="confirmNotVisible"
        }
        if(campos.orgAddress.length>0){
            validaDir=true;
            refDir.current.id=" ";
            refDirError.current.id="errorNotVisible"
            refDirConfirm.current.id="confirmVisible"
        }else{
            refDir.current.id="errorValidation"
            validaDir=false;
            refDirError.current.id="errorVisible"
            refDirConfirm.current.id="confirmNotVisible"
        }
        */
    if (
      validaNombre 
      //&& validaMovil && validaEmpresa && validaDir
    ) {
      valida = true
    }
    if (valida == true) {
      campos.tags = tagsFinal
      campos.imagen = image
      if (selectedContact == null) {
        campos.client = " "
      } else {
        campos.client = selectedContact.completeName
      }
      setLoading(true)
      if(imComingFromNoProjects){
        setNewProject(await createProjectFromNoProjects(campos))
      }else{
        setNewProject(await createProject(campos))
      }
      setLoading(false)
      if (from == "excel") {
        setOpenExcel(true)
      } else if (from == "other") {
        setOpenOther(true)
      }        
      setmodal_first(false)
 
    }
  }
  const [focused, setFocused] = React.useState(false)
  const onFocus = e => {
    setFocused(true)
    if (e.target.name == "name") {
      refNombre.current["id"] = "focus"
      refNombreConfirm.current.id = "confirmNotVisible"
      refNombreError.current.id = "errorNotVisible"
    } else if (e.target.name == "client") {
      refDni.current["id"] = "focus"
      refDniConfirm.current.id = "confirmNotVisible"
      refDniError.current.id = "errorNotVisible"
    } else if (e.target.name == "description") {
      refEmpresa.current["id"] = "focus"
      refEmpresaConfirm.current.id = "confirmNotVisible"
      refEmpresaError.current.id = "errorNotVisible"
    } else if (e.target.name == "orgAddress") {
      refDir.current["id"] = "focus"
      refDirConfirm.current.id = "confirmNotVisible"
      refDirError.current.id = "errorNotVisible"
    } else if (e.target.name == "identifier_number") {
      refMovil.current["id"] = "focus"
      refMovilConfirm.current.id = "confirmNotVisible"
      refMovilError.current.id = "errorNotVisible"
    } else if (e.target.name == "tags") {
      refTags.current["id"] = "focus"
    }
  }
  const handleEnter = event => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form
      const index = [...form].indexOf(event.target)
      form.elements[index + 1].focus()
      event.preventDefault()
    }
  }
  const onBlur = e => {
    setFocused(false)
    if (e.target.name == "name") {
      if (campos.name.length > 0) {
        validaNombre = true
        refNombre.current.id = " "
        refNombreConfirm.current.id = "confirmVisibleNombre"
        refNombreError.current.id = "errorNotVisible"
      } else {
        refNombre.current.id = "errorValidation"
        validaNombre = false
        refNombreConfirm.current.id = "confirmNotVisible"
        refNombreError.current.id = "errorVisibleNombre"
      }
    } else if (e.target.name == "identifier_number") {
      /*
        else if(e.target.name=="description"){
            if(campos.description.length>0){
                validaEmpresa=true;
                refEmpresa.current.id=" ";
                refEmpresaError.current.id="errorNotVisible"
                refEmpresaConfirm.current.id="confirmVisible"
            }else{
                refEmpresa.current.id="errorValidation"
                validaEmpresa=false;
                refEmpresaError.current.id="errorVisible"
                refEmpresaConfirm.current.id="confirmNotVisible"
            }
            
        } 
        
        else if(e.target.name=="orgAddress"){
            if(campos.orgAddress.length>0){
                validaDir=true;
                refDir.current.id=" ";
                refDirError.current.id="errorNotVisible"
                refDirConfirm.current.id="confirmVisible"
            }else{
                refDir.current.id="errorValidation"
                validaDir=false;
                refDirError.current.id="errorVisible"
                refDirConfirm.current.id="confirmNotVisible"
            }
        }

        */
      if (
        // isNumeric(campos.identifier_number) &&
        campos.identifier_number.length > 0
      ) {
        validaMovil = true
        refMovil.current.id = " "
        refMovilError.current.id = "errorNotVisible"
        refMovilConfirm.current.id = "confirmVisibleId"
      } else {
        refMovil.current.id = "errorValidation"
        validaMovil = false
        refMovilError.current.id = "errorVisibleId"
        refMovilConfirm.current.id = "confirmNotVisible"
      }
    }
  }
  const [image, setImage] = useState(
    "https://d1t181n48swd5d.cloudfront.net/imgProject/00_DEFAULT.png"
  ) //useState(user['custom:avatar']);
  const [openImage, setOpenImage] = useState(false)
  const handleOpenImage = e => {
    e.preventDefault()
    setOpenImage(true)
  }
  return (
    <>
      <Modal
        open={modal_first}
        onClose={() => {
          null
        }}
      >
        <div style={modalStyle} className={classes.paper}>
          <CloseIcon
            id="cerrar"
            onClick={() => {
              setmodal_first(false)
            }}
          ></CloseIcon>
          <div id="UserImage">
            <img
              id="ProfileImage"
              onClick={handleOpenImage}
              className="rounded-circle header-profile-user"
              src={image}
              alt="Header Avatar"
            />
            <div id="foto">
              <EditOutlinedIcon
                onClick={handleOpenImage}
                id="fotoSize"
              ></EditOutlinedIcon>
            </div>
          </div>
          <form id="Form">
            <div id="inlineDiv">
              <div id="NombreProyecto">
                <p className="Titulos">{i18n.t("Nombre del Proyecto")}</p>
                <input
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onKeyDown={handleEnter}
                  ref={refNombre}
                  onClick={handleOnClick}
                  placeholder={i18n.t("Nombre del proyecto")}
                  name="name"
                  onChange={obtenerCampos}
                ></input>
                <CheckCircleOutlineSharpIcon
                  style={{ width: "16px", color: "#3BA452" }}
                  ref={refNombreConfirm}
                  id="confirmNotVisible"
                ></CheckCircleOutlineSharpIcon>
                <ErrorOutlineSharpIcon
                  style={{ width: "16px", color: "#BF2929" }}
                  ref={refNombreError}
                  id="errorNotVisible"
                ></ErrorOutlineSharpIcon>
              </div>
              {/*
              <div id="NumeroId">
                <p className="Titulos">{i18n.t("Nº. de id.")}</p>
                <input
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onKeyDown={handleEnter}
                  ref={refMovil}
                  onClick={handleOnClick}
                  placeholder="Número "
                  name="identifier_number"
                  onChange={obtenerCampos}
                ></input>
                <CheckCircleOutlineSharpIcon
                  style={{ width: "16px", color: "#3BA452" }}
                  ref={refMovilConfirm}
                  id="confirmNotVisible"
                ></CheckCircleOutlineSharpIcon>
                <ErrorOutlineSharpIcon
                  style={{ width: "16px", color: "#BF2929" }}
                  ref={refMovilError}
                  id="errorNotVisible"
                ></ErrorOutlineSharpIcon>
              </div>
              */}
            </div>
            <div id="OtroConten">
              {/*
              <p className="Titulos">{i18n.t("Dirección de Obra")}</p>
              <div>
                <input
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onKeyDown={handleEnter}
                  ref={refDir}
                  onClick={handleOnClick}
                  placeholder={i18n.t("Dirección de Obra")}
                  name="orgAddress"
                  onChange={obtenerCampos}
                ></input>
                <CheckCircleOutlineSharpIcon
                  style={{ width: "16px", color: "#3BA452", marginTop: "-8%",
                  marginLeft: "80%" }}
                  ref={refDirConfirm}
                  id="confirmNotVisible"
                ></CheckCircleOutlineSharpIcon>
                <ErrorOutlineSharpIcon
                  style={{ width: "16px", color: "#BF2929" }}
                  ref={refDirError}
                  id="errorNotVisible"
                ></ErrorOutlineSharpIcon>
              </div>
              <p className="Titulos">{i18n.t("Cliente")}</p>
              <div>
                <Autocomplete
                  className="FieldAuto"
                  options={contactos}
                  renderInput={params => (
                    <TextField {...params} label="" variant="outlined" />
                  )}
                  getOptionLabel={option => option.completeName}
                  onChange={(event, Seleccionado) => {
                    setSelectedContact(Seleccionado)
                  }}
                />
                <AddCircleOutlineIcon
                  id="addContact"
                  onClick={handleOpenAdd}
                ></AddCircleOutlineIcon>
              </div>
              <p className="Titulos">{i18n.t("Descripción")}</p>
              <div>
                <textarea
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onKeyDown={handleEnter}
                  contentEditable="true"
                  id="descripcionInput"
                  ref={refEmpresa}
                  onClick={handleOnClick}
                  name="description"
                  placeholder={i18n.t("Describe aquí tu proyecto")}
                  onChange={obtenerCampos}
                ></textarea>
                <CheckCircleOutlineSharpIcon
                  style={{ width: "16px", color: "#3BA452" }}
                  ref={refEmpresaConfirm}
                  id="confirmNotVisible"
                ></CheckCircleOutlineSharpIcon>
                <ErrorOutlineSharpIcon
                  style={{ width: "16px", color: "#BF2929" }}
                  ref={refEmpresaError}
                  id="errorNotVisible"
                ></ErrorOutlineSharpIcon>
              </div>
              <p className="Titulos">{i18n.t("Etiqueta")}</p>
              <div
                ref={refTags}
                onClick={handleOnClick}
                className="container2"
                style={{ padding: "0px" }}
              >
                <LocalOfferOutlinedIcon id="iconTag"></LocalOfferOutlinedIcon>
                {tags.map((tag, index) => (
                  // eslint-disable-next-line react/jsx-key
                  <div id={tag[1]} className="tag">
                    {tag[0]}
                    <button onClick={e => deleteTag(e, index)}>x</button>
                  </div>
                ))}
                <input
                  onFocus={onFocus}
                  onBlur={onBlur}
                  name="tags"
                  value={input}
                  placeholder="Añadir Etiqueta"
                  onKeyDown={onKeyDown}
                  onKeyUp={onKeyUp}
                  onChange={onEtiqueta}
                />
                <AddCircleOutlineIcon
                  id="addTag"
                  onClick={AddTagOnClick}
                ></AddCircleOutlineIcon>
              </div>
              <div id="Default">
                <span id="color1" className="defaultTag" onClick={selectTag}>
                  VIVIENDA
                </span>
                <span id="color2" className="defaultTag" onClick={selectTag}>
                  RESIDENCIAL
                </span>
                <span id="color3" className="defaultTag" onClick={selectTag}>
                  OBRA NUEVA
                </span>
                <span id="color5" className="defaultTag" onClick={selectTag}>
                  REFORMA
                </span>
                <span id="color4" className="defaultTag" onClick={selectTag}>
                  REHABILITACIÓN
                </span>
                <span id="color6" className="defaultTag" onClick={selectTag}>
                  RETAIL
                </span>
                <span id="color7" className="defaultTag" onClick={selectTag}>
                  PÚBLICO
                </span>
                <span id="color8" className="defaultTag" onClick={selectTag}>
                  PRIVADO
                </span>
                </div*/}
              <div id="centerButton" style={{paddingTop:'20px'}}>
                {loading?
                <CircularProgress size={24} />
                :  
                <button type="button" onClick={onSubmit} className="AddContact"
                data-amplify-analytics-on="click"
                data-amplify-analytics-name="ProjectCreated"
                data-amplify-analytics-attrs={campos}
                >
                  {i18n.t("Crear Proyecto")}
                </button>
              }
              </div>
            </div>
          </form>
        </div>
      </Modal>

      <AddContact openAdd={openAdd} setOpenAdd={setOpenAdd} />
      <ModalImageProject
        openImage={openImage}
        setOpenImage={setOpenImage}
        campos={campos}
        image={image}
        setImage={setImage}
        first={true}
      ></ModalImageProject>
      { openExcel &&
	<UploadExcelModal openModal={openExcel} setOpenModal={setOpenExcel} setWaitModal={setWaitModal} proyecto={newProject} />
      }
      { openOther &&
        <UploadOtherFormatsModal openModal={openOther} setOpenModal={setOpenOther} proyecto={newProject} />
      }
      {waitModal &&
        <ModalWaitingExcel openModal={waitModal} setOpenModal={setWaitModal} project={newProject} setSuccessModal={setSuccess} imComingFrom={'budgeting'} />
      }
      {success && 
      <SuccessModal setSuccessModal={setSuccess} successModal={success} project={newProject}/>
      }

    </>
  )
}
ModalCreateFromExcel.propTypes = {
  from: PropTypes.string.isRequired,
  modal_first: PropTypes.bool.isRequired,
  setmodal_first: PropTypes.func.isRequired,
  setModalUpload: PropTypes.func.isRequired,
  contactos: PropTypes.array,
  setSeleccionado: PropTypes.func.isRequired,
  setFirstProject: PropTypes.func.isRequired,
  imComingFromNoProjects: PropTypes.bool
}

export default ModalCreateFromExcel
