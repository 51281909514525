import { PoppinsSemiBold } from "common/typographies";
import React, { useContext, useState } from "react";
import i18n from "i18n";
import { YellowButton } from "common/buttons";
import { CircularProgress, Grid } from "@mui/material";
import BudgetingTemplate from "./BudgetingTemplate";
import PDFPreview from "./pdfPreview";
import AuthContext from "context/auth/authContext";
import FacturaTemplate from "./facturaTemplate";
import ActaTemplate from "./actaTemplate";

const LayoutTemplate = () => {

    const [loading, setLoading] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);
    const [urlPreview, setUrlPreview] = useState(null);

    const [openBudget, setOpenBudget] = useState(true)
    const [openInvoice, setOpenInvoice] = useState(false)
    const [openActas, setOpenActas] = useState(false)

    const {getTemplates} = useContext(AuthContext)

    const handleOpen = (e, type) => {
        e.preventDefault()
        if(type=='budget'){
            setOpenBudget(prev => !prev)
            setOpenActas(false)
            setOpenInvoice(false)
        }else if(type=='invoice'){
            setOpenInvoice(prev => !prev)
            setOpenBudget(false)
            setOpenActas(false)
        }else if(type=='actas'){
            setOpenActas(prev => !prev)
            setOpenBudget(false)
            setOpenInvoice(false)
        }
    }

 



    return (
    <div style={{padding:'30px', paddingTop:'0px', paddingBottom:'0px'}}>
        <div className="inline" style={{position:'relative'}}>
            <PoppinsSemiBold style={{fontSize:'18px', marginTop:'-4px'}}>
                {i18n.t('Personalizacion de plantillas')}
            </PoppinsSemiBold>
            {loadingButton ? <CircularProgress style={{position:'absolute', right:'18px', marginTop:'-10px'}}/> : 
           <YellowButton style={{position:'absolute', right:'0px',marginTop: '-10px' , width:'115px', height:'40px'}} >
                {i18n.t("Guardar")}
           </YellowButton>}
        </div>
        <div style={{width:'100%', height:'78vh', borderTop:'1px solid #EBEBEB'}}>
         <Grid container style={{}} direction='row'>
              <Grid id='BigGrid' item xs={4} style={{marginTop:'0px'}}>
                    <div style={{ overflow: 'auto', position:'relative', width:'100%',  display:'flex', flexDirection:'column', height:'100%'}}>

                        <BudgetingTemplate url={urlPreview} setUrl={setUrlPreview} handleOpen={handleOpen} openNotSendCapitulo={openBudget} setLoading={setLoading}/>

                        <FacturaTemplate url={urlPreview} setUrl={setUrlPreview} handleOpen={handleOpen} openNotSendCapitulo={openInvoice} setLoading={setLoading}/>
                
                        <ActaTemplate url={urlPreview} setUrl={setUrlPreview} handleOpen={handleOpen} openNotSendCapitulo={openActas} setLoading={setLoading}/>
                    </div>
              </Grid>
              <Grid id='BigGrid' item xs={8} style={{width:"100%", marginTop:'10PX'}}>
                   <PDFPreview pdf={urlPreview}/>
              </Grid>
        </Grid>
        </div>
    </div>
    
    );
    }

export default LayoutTemplate;