export const onUpdateImportedProjects = /* GraphQL */ `
  subscription Subscription($id: ID!) {
    onUpdateImportedProjects(id: $id) {
      id
      projectId
      accountId
      parts
      chapters
      importStatus
      organizationId
    }
  }
`;


export const onUpdateImportBc3 = /* GraphQL */ `
  subscription OnUpdateImportBc3($id: ID!) {
    onUpdateImportBc3(id: $id) {
      id
      projectId
      accountId
      parts
      chapters
      importStatus
      organizationId
    }
  }
`;

export const onUpdateImportProcess = /* GraphQL */ `
  subscription Subscription($id: String!) {
    onUpdateImportProcess(id: $id) {
      id
      fileId
      orgId
      status
      error
      errorMessage
      createdAt
      updatedAt
      organizationId
      _version
      chapters
      parts
      validation
      validation_result
    }
  }
`;

export const onUpdateImportExcel = /* GraphQL */ `
  subscription OnUpdateImportExcel($id: ID!) {
    onUpdateImportExcel(id: $id) {
      id
      fileId
      orgId
      status
      error
      errorMessage
      createdAt
      updatedAt
      validation
      chapters
      parts
    }
  }
`;

export const onUpdatePartGeneratedCustom = /* GraphQL */ `
  subscription OnUpdatePartGenerated {
    onUpdatePartGenerated {
      id
      name
      description
      price
      type
      unity
      decomposition {
        name
        quantity
        unity
        price
        total
        type
      }
      status
      _version
    }
  }
`;

export const onCreateExportPdfProcess = /* GraphQL */ `
  subscription onCreateExportPdfProcess($id: String!, $projectId: String!) {
    onCreateExportPdfProcess(id: $id, projectId: $projectId) {
      id
      projectId
      status
      error
      errorMessage
      parts_done
      parts_total
      chapters_done
      parts_total
      url
    }
  }
`;
