import {API, graphqlOperation, Auth} from "aws-amplify"
import {useState, useEffect} from 'react'
import * as customSubscriptions from "../customGraphql/subscriptions"
import {APPSYNC_SETTINGS, isStg} from "../utils/functions"

export const useProgressExcel = ({orgId, fileId}) => {
    const [importedExcel, setImportedExcel] = useState({
        orgId: orgId,
        fileId: fileId,
        error : false,
        errorMessage: "",
        status : "PENDING",
        validation: false,
        chapters: 0,
        parts:0,
        createdAt: "",
        updatedAt: "",
        id: fileId
    })

    const stage = isStg(APPSYNC_SETTINGS.endpoint)
    console.log("stage", stage)
    const query = stage ? customSubscriptions.onUpdateImportProcess : customSubscriptions.onUpdateImportExcel
    console.log("query", query)

    useEffect(() => {
      const subscription = API.graphql(
        graphqlOperation(query, {'id': fileId}
        )).subscribe({
            next : ({provider, value}) => {
		    setImportedExcel(
                        stage ? value.data.onUpdateImportedProjects : value.data.onUpdateImportExcel
                    )},
            error: error => console.warn(error)
        })
        return () => {
            subscription.unsubscribe()
        }
    }, [])
    return importedExcel
}
