import React, { useContext, useRef, useEffect } from "react"
import propTypes from "prop-types"
import i18n from "i18n"
import {
  Modal,
  Box,
  Typography,
  IconButton,
  Grid
} from "@mui/material"
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded"
import PDFPreview from "./pdfPreview"
import SendSteps from "./sendSteps"

const SendClasses = {
  position: "absolute",
  top: "0%",
  right: "0%",
  height: "100%",
  width: "80%",
  backgroundColor: "white",
  paddingRight: "0px",
  borderRadius: "16px 0px 0px 16px",
  overflowY: "scroll",
  overflowX: "hidden",
}

const SendModal = ({open, setOpen,  facturas, invoiceService}) => {
  return (
    open ?
    <>
      <Modal
        open={open}
        onClose={() => {
         null
        }}
      >
        <Box style={SendClasses}>
          <Grid container direction={"column"} marginTop={"0rem"} spacing={3} marginLeft={"-6px"} paddingRight={"10px"}
            style={{borderBottom: '1px solid #EBEBEB'}}
          >
            <Grid item marginLeft={"0rem"}>
              <IconButton
                sx={{  border: "1px solid #000001", height: "25px", width: "25px", color: "black", marginBottom: "1rem"}}
                onClick={() => {setOpen(false)}}
              >
                <ArrowForwardIosRoundedIcon style={{ width: "15px" }} />
              </IconButton>
            </Grid>
          </Grid>

            <Grid container direction={"row"} marginTop={"0rem"} spacing={0}>
                <Grid item xs={6.5}>
                   <PDFPreview invoiceId={facturas[0].invoiceId} invoiceService={invoiceService}/>
                </Grid>
                <Grid item xs={5.5}>
                    <SendSteps facturas={facturas} setOpen={setOpen} invoiceService={invoiceService}/>
                </Grid>
            </Grid>

        </Box>
      </Modal>
    </>
    : null
  )
}

SendModal.propTypes = {
  open: propTypes.bool.isRequired,
  setOpen: propTypes.func.isRequired,
  facturas: propTypes.array.isRequired,
  invoiceService: propTypes.object.isRequired,
}

export default SendModal
