import React, { useEffect, useState } from "react"
import propTypes from "prop-types"
import Estructura from "./estructura"
import Partida from "./partida"

const TablaPartida = ({
  certificaciones,
  capitulo,
  preferencias,
  setPreferencias,
  desplazamiento,
  esUnico,
  soyFinal,
  version,
  numeroIndex,
  openModalCreatePartida,
  setOpenModalCreatePartida,
  projectInfo,
  modificado,
  setModificado,
  contadorActualizaciones,
  setContadorActualizaciones,
  alinearPrecio,
  ocultoFinal,
  isChecked,
  setIsChecked,
  imDragging,
  setImDragging,
}) => {
  const [currentPercentageChapter, setCurrentPercentageChapter] = useState(0)
  const [parentUpdate, setParentUpdate] = useState(0)
  const [isFirstTime, setIsFirstTime] = useState(true)

  useEffect(() => {
    setIsFirstTime(false)
  }, [currentPercentageChapter])

  return (
    <>
      <Estructura
        certificaciones={certificaciones}
        openModalCreatePartida={openModalCreatePartida}
        setOpenModalCreatePartida={setOpenModalCreatePartida}
        capitulo={capitulo}
        version={version}
        projectInfo={projectInfo}
        total={capitulo.total}
        totalFinal={capitulo.capituloInfo.finalClientPrice}
        ocultoFinal={ocultoFinal}
        preferencias={preferencias}
        setPreferencias={setPreferencias}
        desplazamiento={desplazamiento}
        esUnico={esUnico}
        soyFinal={soyFinal}
        alinearPrecio={alinearPrecio}
      >
        {
          // aqui hacer un map de las partidas como children de Estructura
          capitulo.partidas.map((partida, index) => (
            <Partida
              isFirstTime={isFirstTime}
              setParentUpdate={setParentUpdate}
              parentUpdate={parentUpdate}
              currentPercentageChapter={parseInt(currentPercentageChapter)}
              setCurrentPercentageChapter={setCurrentPercentageChapter}
              certificaciones={certificaciones}
              numeroIndex={numeroIndex}
              whoIAm={
                index == 0
                  ? "first"
                  : index == capitulo.partidas.length - 1
                  ? "last"
                  : "middle"
              }
              key={partida.partidaId}
              indice={index}
              partidaInfo={partida}
              preferencias={preferencias}
              soyFinal={soyFinal}
              projectInfo={projectInfo}
              setModificado={setModificado}
              modificado={modificado}
              ocultoFinal={ocultoFinal}
              contadorActualizaciones={contadorActualizaciones}
              setContadorActualizaciones={setContadorActualizaciones}
              isChecked={isChecked}
              setIsChecked={setIsChecked}
              imDragging={imDragging}
              setImDragging={setImDragging}
              version={version}
              openModalCreatePartida={openModalCreatePartida}
              setOpenModalCreatePartida={setOpenModalCreatePartida}
            />
          ))
        }
      </Estructura>
    </>
  )
}

TablaPartida.propTypes = {
  capitulo: propTypes.object.isRequired,
  preferencias: propTypes.object.isRequired,
  setPreferencias: propTypes.func.isRequired,
  desplazamiento: propTypes.object.isRequired,
  esUnico: propTypes.bool,
  soyFinal: propTypes.bool,
  projectInfo: propTypes.object.isRequired,
  modificado: propTypes.number,
  setModificado: propTypes.func,
  contadorActualizaciones: propTypes.number,
  setContadorActualizaciones: propTypes.func,
  alinearPrecio: propTypes.bool,
  ocultoFinal: propTypes.bool,
  isChecked: propTypes.bool,
  setIsChecked: propTypes.func,
  imDragging: propTypes.bool,
  setImDragging: propTypes.func,
  version: propTypes.object,
  numeroIndex: propTypes.string,
  openModalCreatePartida: propTypes.bool,
  setOpenModalCreatePartida: propTypes.func,
  certificaciones: propTypes.bool,
}
export default TablaPartida
