import React, { useState} from 'react'
import {Modal} from "@mui/material";
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";

function getModalStyle() {
    const top = 50 ;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        maxWidth: 'calc(100vh - 100px)',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        borderRadius: 6,
        maxHeight: 'calc(100vh - 50px)',
        overflowY: 'auto',
        overflowX:'hidden',
        padding: '0'
    },
}));


const ModalZoomImage = ({openZoom, setOpenZoom, image}) => {
    const [ modalStyle ] = useState(getModalStyle);
    const classes = useStyles();
    return(
        <Modal open={openZoom} onClose={() => setOpenZoom(false)}>
              <div style={modalStyle} className={classes.paper}>
            {image? <img src={image.url}/> : null}
            </div>

        </Modal>
    )
}

ModalZoomImage.propTypes = {
    openZoom: PropTypes.bool,
    setOpenZoom: PropTypes.func,
    image: PropTypes.object,
}
export default ModalZoomImage;