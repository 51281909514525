
import { ActasTable } from 'common/tables';
import React from 'react';
import { MetaTags } from 'react-meta-tags';
import { Container } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import authContext from 'context/auth/authContext';
import actsContext from 'context/acts/actsContext';
import ActasTableCustom from './actasTable';
import { HelveticaBold } from 'common/typographies';
import {  SquareContent } from 'common/containers';
import { useHistory } from "react-router-dom";
import i18n from 'i18n';
import acta from 'assets/customIcons/acta.svg'
import { YellowButton } from 'common/buttons';
import * as amplitude from '@amplitude/analytics-browser';

const IndexActas = () => {

  const { projectId } = useParams()
  const { user, getUser } = useContext(authContext)
  const { getActs, acts } = useContext(actsContext)
  const history = useHistory()

  useEffect(() => {
    if (user) {
      getActs(projectId, user)
    }else{
      getUser()
    }
  }, [user])

  
useEffect(() => {
  amplitude.track('Actas Visited');
}, [])


    return (
        <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Planhopper | Actas</title>
          </MetaTags>
          <Container fluid>
            {
              acts.length > 0 ? <ActasTableCustom actas={acts} />
              :
            <>
             <div style={{width:'100%', justifyContent:'right', display:'flex',  marginTop:'-15px', marginBottom:'20PX'}}>
            <YellowButton
                onClick={() => history.push('/createAct/'+projectId)}
                style={{color:'white', height:'40px', width:'128px', marginRight:'20px', justifyContent:'center', textAlign:'center', marginTop:'6px', paddingTop:'8px'}}>
                <HelveticaBold style={{fontSize:'14px', color:'#414141', justifyContent:'center', textAlign:'center'}}>
                    {i18n.t('Nueva Acta')}
                </HelveticaBold>
              </YellowButton>
        </div>
              <div style={{width:'100%', justifyContent:'center', boxShadow:'none',display:'flex', textAlign:'center', height:'60vh' }}>
               <SquareContent style={{width:'370px', height:'200px'}}>
                <img src={acta} style={{width:'60px', height:'80px', marginBottom:'20px', marginTop:'30px'}}/>
                <HelveticaBold style={{fontSize:'14px', fontWeight:'400', padding:'0px 30px 30px 30px', color:'#414141', justifyContent:'center', textAlign:'center'}}>
                    {i18n.t('Ups! Aún no hay ningún acta. ')}<b>Haz click en Nueva Acta</b>{i18n.t(' para empezar.')}
                </HelveticaBold>
                </SquareContent>
              </div>
            </>
            }
          
          </Container>
        </div>
      </React.Fragment>
    )

    }

export default IndexActas;