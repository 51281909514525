import React, {  useEffect, useState, useRef } from 'react'
import { Modal, Box,Grid } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types'
import {BlueInput, PoppinsNormal} from 'common/typographies'
import ShareIcon from '@mui/icons-material/Share';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { HelveticaInput } from 'common/inputs';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CommentsContainer from "components/Comments/CommentsContainer";
import CropFreeIcon from '@mui/icons-material/CropFree';
import ModalZoomImage from "components/Project/Overview/modals/modalZoomImage";
import i18n from 'i18n';


function getModalStyle() {
    const top = 39 ;
    const left = 39;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: '90%',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 5, 3),
        borderRadius: 6,
        maxHeight: '95%',
        height:'95%',
        overflowY: 'hidden',
        overflowX:'hidden',
        marginLeft:'1%'
    },
}));

const ModalPlanningTaskDetails = ({open, setOpen, infoCard}) => {

    const [ modalStyle ] = useState(getModalStyle);
    const classes = useStyles();
    const [descripcion, setDescripcion] = useState('')
    const referenciaDescripcion = useRef(null)
    const handleChangeDescripcion = (e) => {
        setDescripcion(e.target.value)
      }

      const messagesEndRef = useRef(null)
      const scrollToBottom = () => {
          messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
      }

    const [auxRefs, setAuxRef] = useState([])
    useEffect(()=>{
        if(infoCard.partInfo){
            if(infoCard.partInfo.images){
                updateRefs()
            }
        }
       
    },[
        infoCard
    ])
    function updateRefs(){
        let x = []
        for(let i=0; i<infoCard.partInfo.images.length;i++){
            x.push(React.createRef())
        }
        setAuxRef(x)
    }

    const [zoom, setZoom] = useState(null)
    const [openZoom, setOpenZoom] = useState(null)
    useEffect(()=>{
        if(zoom!=null){
            setOpenZoom(true)
        }
    
    },[zoom])

    useEffect(()=>{
        if(openZoom==false){
            setZoom(null)
        }
    },[openZoom])

    const getLastItem = thePath => thePath.substring(thePath.lastIndexOf('/') + 1)
    return(
        <>
        <Modal open={open} onClose={() => null}>
            <div style={modalStyle} className={classes.paper} >
            <Grid container spacing={0} >
                <Grid item xs={6} >
                <div style={{width:'100%', display:'flex', paddingTop:'30px', height:'40px', position:'relative', marginBottom:'15px'}}>
                    <BlueInput style={{fontSize:'16px'}}>{infoCard.parentInfo ? infoCard.parentInfo.name : 'CAPITULO' }</BlueInput>
                    <div style={{right:'10px', display:'flex', position:'absolute'}}>
                        <ShareIcon style={{width:'16px', color:'#414141', marginTop:'-2px'}}/>
                        <DeleteOutlineIcon  style={{width:'20px', marginLeft:'5px', color:'#414141', marginTop:'-2px'}}/>
                    </div>
                </div>
                
                <div style={{height:'1px', backgroundColor:'#E6E6E6', width: '105%', marginLeft: '-40px', marginBottom:'17px'}}></div>
                <PoppinsNormal style={{marginBottom:'8px'}}>{i18n.t("Nombre")}</PoppinsNormal>
                {infoCard.partInfo ?   <HelveticaInput style={{width:'60%'}}
                    placeholder={i18n.t("Nombre Tarea")}
                    value={infoCard.title}
                />
                :
                infoCard.title ? 

                <HelveticaInput style={{width:'60%'}}
                value={infoCard.title}
            />
            : 
                <HelveticaInput style={{width:'60%'}}
                placeholder={i18n.t("Nombre Tarea")}
            />
                    }
           
                <div style={{display:'flex', width:'100%'}}>
                    <div style={{width:'27.5%'}}>
                        <PoppinsNormal style={{marginBottom:'8px'}}>{i18n.t("Fecha Inicio")}</PoppinsNormal>
                        <HelveticaInput style={{width:'100%'}}
                            placeholder='Fecha'
                            value={infoCard.startAt ? new Date(infoCard.startAt).toLocaleDateString() : ''}
                        />
                    </div>
                    <div style={{width:'5%'}}></div>
                    <div style={{width:'27.5%'}}>
                    <PoppinsNormal style={{marginBottom:'8px'}}>({i18n.t("Fecha Fin")}</PoppinsNormal>
                    <HelveticaInput style={{width:'100%'}}
                            placeholder='Fecha'
                            value={infoCard.endAt ? new Date(infoCard.endAt).toLocaleDateString() : ''}
                    />
                    </div>
                </div>
                <div style={{display:'flex', width:'100%'}}>
                    <div style={{width:'27.5%'}}>
                        <PoppinsNormal style={{marginBottom:'8px'}}>{i18n.t("Relación de dependencia")}</PoppinsNormal>
                        <HelveticaInput style={{width:'100%'}}
                            placeholder='Fecha'
                        />
                    </div>
                    <div style={{width:'5%', textAlign:'center'}}>
                        <ArrowForwardIosIcon style={{width:'15px', marginTop:'38px'}}/>
                    </div>
                    <div style={{width:'27.5%', paddingTop:'30px'}}>
                    <HelveticaInput style={{width:'100%'}}
                            placeholder='Fecha'
                    />
                    </div>
                </div>
                <PoppinsNormal style={{marginBottom:'8px'}}>{i18n.t("Tarea asiganda a")}</PoppinsNormal>
                <HelveticaInput style={{width:'60%'}}
                    placeholder={i18n.t('Agregar persona aquí')}
                />
                 <PoppinsNormal style={{marginBottom:'8px'}}>{i18n.t("Descripción")}</PoppinsNormal>
                <div style={{width:'100%'}}>
                {infoCard.partInfo ? 
                     <textarea style={{padding:'10px', backgroundColor:'white', width:'60%', boxSizing: 'border-box',
                     filter: 'drop-shadow(0px 0px 16px rgba(0,0,0,0.08))',
                     borderRadius: '6px'}}
                 ref={referenciaDescripcion}
                 placeholder={i18n.t('Añadir descripción')}
                 value={infoCard.description}
                 onChange={(e)=>{handleChangeDescripcion(e)}}
                 contentEditable='true'
                 />
                : 
                <textarea style={{padding:'10px', backgroundColor:'white', width:'60%', boxSizing: 'border-box',
                filter: 'drop-shadow(0px 0px 16px rgba(0,0,0,0.08))',
                borderRadius: '6px'}}
            ref={referenciaDescripcion}
            placeholder='Añadir descripción'
            
            onChange={(e)=>{handleChangeDescripcion(e)}}
            contentEditable='true'
            />
                }
               
                </div>
                <div>
                        <div className="inline" style={{marginTop:'5px'}}>
                            <p id='SubTitulo' style={{fontSize:'14px', marginLeft:'0px'}}>{i18n.t("Fotografias")}</p>
                         
                        </div>
                    </div>

                    <div style={{marginTop:'5px'}}>
                    {
                    infoCard.partInfo ? infoCard.partInfo.images ?
                        auxRefs.length>0?
                    <Box sx={{ flexGrow: 1 }} style={{marginLeft:'0px', }}>
                        <Grid container spacing={6} columns={{ xs: 2, sm: 8, md: 12 }} >
                        {infoCard.partInfo.images.map((foto, index) => (
                            <Grid item key={index} onMouseEnter={()=>auxRefs[index].current.style.visibility=''} onMouseLeave={()=>auxRefs[index].current.style.visibility='hidden'} >
                            <div ref={auxRefs[index]} style={{height:'25px', width:'254px', backgroundColor:'rgba(65, 65, 65, 0.79)', zIndex:'10', position:'relative', display:'flex', overflow:'auto', visibility:'hidden'}}>
                                <p id='interFont' style={{width:'180px'}}>{getLastItem(foto.url)}</p>
                                <CropFreeIcon style={{color:'white', paddingTop: '1px', width:'20px', marginLeft: '20px', marginRight: '5px', cursor:'pointer'}} onClick={()=>setZoom(foto)}/>
                                
                            </div>
                            <img style={{width:'254px', height:'169px', marginTop:'-25px'}} src={foto.url}/>
                            </Grid>
                        ))}
                        
                        </Grid>
                    </Box>: null : null
                     : null}
                     </div>
                
                </Grid>
                <Grid item xs={6}  >
                    <div style={{width:'100%', display:'flex', paddingTop:'15px', height:'40px', position:'relative', marginBottom:'15px'}}>
                    <Grid container spacing={0} >
                         <Grid item xs={3}>
                            <PoppinsNormal style={{fontWeight:'300', fontSize:'12px', marginBottom:'0px'}}>{i18n.t("Creada")}</PoppinsNormal> 
                            <BlueInput style={{fontSize:'14px'}} > 12 Jun 2022 </BlueInput>
                        </Grid>
                        <Grid item xs={9}>
                        <PoppinsNormal style={{fontWeight:'300', fontSize:'12px', marginBottom:'0px'}}>{i18n.t("Creada")}</PoppinsNormal> 
                            <BlueInput style={{fontSize:'14px'}}> 30 Jun 2022 </BlueInput>
                        </Grid>
                    </Grid>
                    </div>
                <div style={{height:'1px', backgroundColor:'#E6E6E6', width: '110%', marginLeft: '-5px', marginBottom:'17px', overflowY:'auto'}}></div>
                    {
                        infoCard.partInfo ?  <div style={{height:'90vh', overflowY:'scroll'}}>
                            <CommentsContainer conversationId={infoCard.partInfo.partidaId} scrollToBottom={scrollToBottom} messagesEndRef={messagesEndRef} estoyEnPlanning={true}/>
                        </div>
                        : null
                    }
              </Grid>
             </Grid>
            
            </div>
        </Modal>
        <ModalZoomImage openZoom={openZoom} setOpenZoom={setOpenZoom} image={zoom} />
        </>
    )
}

ModalPlanningTaskDetails.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    infoCard: PropTypes.object
}
export default ModalPlanningTaskDetails;