import React from 'react';
import { PoppinsSemiBold } from 'common/typographies';
import i18n from 'i18n';

const InvoiceConceptHeader = () => {
    return(
        <div 
            className="inline" 
            style={{ width:'100%', height:'100%',paddingTop:'10px', position:'relative', display:'flex'}}
        >
            <div style={{backgroundColor:'#F8F8F8', width:'49.5%', minWidth:'150px'}}>
                <div style={{display:'flex', padding:'10px', marginBottom:'-10px'}}>
                    <PoppinsSemiBold style={{fontSize:'14px', marginLeft:'10px', minWidth:'200px'}}>
                        {i18n.t('Concepto')}
                    </PoppinsSemiBold>
                </div>
            </div>
            <div style={{backgroundColor:'#F8F8F8', width:'11%', minWidth:'110px'}}>
                <div style={{display:'flex', padding:'10px', marginBottom:'-10px'}}>
                    <PoppinsSemiBold
                    style={{fontSize:'14px', marginLeft:'10px'}}>
                        {i18n.t('Unidades')}
                    </PoppinsSemiBold>
                </div>
            </div>
            <div style={{backgroundColor:'#F8F8F8', width:'11%', minWidth:'110px'}}>
                <div style={{display:'flex', padding:'10px', marginBottom:'-10px'}}>
                        <PoppinsSemiBold 
                            style={{fontSize:'14px', marginLeft:'10px', minWidth:'116px'}}>
                            {i18n.t('Precio')}
                        </PoppinsSemiBold>
                </div>
            </div>
            <div style={{backgroundColor:'#F8F8F8', width:'11%', minWidth:'140px'}}>
                <div style={{display:'flex', padding:'10px', paddingRight:'0px',marginBottom:'-10px', minWidth:'116px'}}>
                        <PoppinsSemiBold 
                        style={{fontSize:'14px', marginLeft:'10px'}}>
                            {i18n.t('Impuestos')}
                        </PoppinsSemiBold>
                </div>
            </div>
            <div style={{backgroundColor:'#F8F8F8', width:'10%', minWidth:'110px', marginRight:'30px'}}>
                <div style={{display:'flex', padding:'10px', marginBottom:'-10px'}}>
                    <PoppinsSemiBold style={{fontSize:'14px', marginLeft:'10px', minWidth:'116px'}}>
                        {i18n.t('Total')}
                    </PoppinsSemiBold>
                </div>
            </div>
        </div>
        
    )
}
export default InvoiceConceptHeader;