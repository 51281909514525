import {API, graphqlOperation, Auth} from "aws-amplify"
import {useState, useEffect} from 'react'
import * as customSubscriptions from "../customGraphql/subscriptions"

import {APPSYNC_SETTINGS, isStg} from "../utils/functions"

export const useProgress = ({projectId, accountId}) => {
    //console.log("useProgress", projectId, accountId)

    const [importedProject, setImportedProject] = useState({
        id : projectId,
        projectId: projectId,
        accountId: accountId,
        importStatus: '',
        parts : 0,
        chapters: 0,
    })

    const stage = isStg(APPSYNC_SETTINGS.endpoint)
    console.log("stage", stage)
    const query = stage ? customSubscriptions.onUpdateImportedProjects : customSubscriptions.onUpdateImportBc3
    console.log("query", query)

    useEffect(() => {
        const subscription = API.graphql(
        	graphqlOperation(query, {'id': projectId})
        ).subscribe({
            next: ({provider, value}) => {
                    setImportedProject(
                        stage ? value.data.onUpdateImportedProjects : value.data.onUpdateImportBc3
                    )},
            error: error => console.warn(error)
        })
        console.log("subscription", subscription)
        return () => {
            subscription.unsubscribe()
        }
    }, [])
    return importedProject
}
