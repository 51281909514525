import SubscriptionComponent from "components/GestionEmpresa/subscriptionComponent";
import React, { useEffect } from "react";
import MetaTags from "react-meta-tags";
import { Container } from "reactstrap";
import { RecordPageView } from "App"
import * as amplitude from '@amplitude/analytics-browser';

const SubscriptionPageAnual = () => {
  RecordPageView()

  useEffect(() => {
    amplitude.track('Subscription Visited');
  }
  ,[])
  const [anual, setAnual] = React.useState(true);
    return (
        <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Planhopper | Subscripción</title>
          </MetaTags>
          <Container fluid>
                <SubscriptionComponent anual={anual} setAnual={setAnual}/>
          </Container>
        </div>
      </React.Fragment>
    );
}

export default SubscriptionPageAnual;