import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import DropdownOptions from "./options/DropdownOptions"
import { useHistory } from "react-router-dom"
import i18n from "i18n"
import AuthContext from "context/auth/authContext"
import ModalFilterByTag from "./options/ModalFilterByTag"
import cloudCheck from "assets/customIcons/cloudCheck.svg"
import { HelveticaBold } from "common/typographies"
import ModalEditMargins from "./options/ModalEditMargins"
import Budget2023Context from "context/budget2023/Budget2023Context"
import DropdownPreferencias from "./TablaPartida/DropdownPreferencias"
import margenIcon from "assets/customIcons/margenIcon.svg"
import personalizar from "assets/customIcons/layers.svg"
import download from "assets/customIcons/download.svg"
import send from "assets/customIcons/send.svg"
import DownloadBudget from "components/Feed/DownloadBudget"
import ModalCerrarPres from "./options/ModalCerrarPres"
import ModalPersonalizarPdf from "./modals/modalPersonalizarPdf"
import { YellowButton } from "common/buttons"
import DownloadBudgetEnum from "common/DownloadBudgetEnum"
import ActionsButtons from "components/ActionsButtons/ActionsButtons"

const LayoutBudget = ({
  children,
  handleSendBudgetModal,
  handleDownloadBudgetModal,
  projectInfo,
  preferencias,
  setPreferencias,
  open3,
  setOpen3,
  contactos,
  soyFinal,
  setModificado,
  version,
  index,
  certificaciones,
}) => {
  const {
    readyToClose,
    partsChecked,
    selectPartsGroup,
    chaptersChecked,
    budgetTree,
    errorAgrupar,
    groupParts,
    getSelectedBudget2,
    lastActions,
    undoAction,
    closeCertification,
    closeBudgetExtras,
    openBudgetExtras,
    isBudgetClosed,
    loadingTree,
  } = useContext(Budget2023Context)
  const history = useHistory()
  const [openEditMargins, setOpenEditMargins] = useState(false)
  const [openCerrar, setOpenCerrar] = useState(false)
  const [contador, setCont] = useState(0)
  const [showGroup, setShowGroup] = useState(false)
  const [btnLoading, setBtnLoading] = useState(false)
  const { user, freeTrialExpired, openFreeTrialExpire, getOrganizationNew } =
    useContext(AuthContext)
  const enableUndo = false
  useEffect(() => {
    selectPartsGroup([], budgetTree.subcapitulos)
    getSelectedBudget2([], budgetTree.subcapitulos)
    if (partsChecked.length > 0 || chaptersChecked.length > 0) {
      setShowGroup(true)
    } else {
      setShowGroup(false)
    }
  }, [partsChecked.length, chaptersChecked.length])

  useEffect(() => {
    if (contador != 0) {
      if (readyToClose == true) {
        if (version) {
          history.push(`/versions/final/${projectInfo.projectId}/${index}`)
        } else {
          setCont(1)
          if (projectInfo.costeGeneral) {
            history.push(`/budgeting/final/${projectInfo.projectId}`)
          } else {
            setOpenEditMargins(readyToClose)
          }
        }
      }
    }
  }, [readyToClose])

  useEffect(() => {
    if (user) {
      getOrganizationNew(user["custom:organizationId"])
    }
  }, [user])

  const [name, setName] = useState("")
  const [openAgrupar, setOpenAgrupar] = useState(false)
  const [openCaps, setOpenCaps] = useState(false)
  const [estadoLista, setEstadoLista] = useState([])
  const [estadoListaCaps, setEstadoListaCaps] = useState([])
  const agruparPartidas = async () => {
    if (errorAgrupar == false) {
      if (estadoLista.length == 0) {
        setName("las partidas")
        setOpenAgrupar(true)
      } else {
        groupParts(projectInfo.projectId)
      }
    } else {
      setOpenSelected(true)
    }
  }

  const agruparCapitulos = async () => {
    if (estadoListaCaps.length == 0) {
      //modal no has agrupado nada
      setName("los capítulos")
      setOpenAgrupar(true)
    } else {
      //antes de esto mensaje de aviso
      setOpenCaps(true)
    }
  }
  const [openFiltrar, setOpenFiltrar] = useState(false)
  const [openDownloadBudgetModal, setOpenDownloadBudgetModal] = useState(false)
  const [openPersonalizarPdf, setOpenPersonalizarPdf] = useState(false)

  return (
    <>
      <div style={{ maxHeight: "750px", height: "750px", display: "contents" }}>
        <div
          style={{
            marginLeft: "30px",
            marginRight: "25px",
            marginBottom: "30px",
          }}
        >
          <div id="SubMenuProjects">
            <div className="inline">
              {" "}
              <p id="Configuration">
                {version ? version.name : projectInfo.name}
              </p>
              <img
                src={cloudCheck}
                style={{
                  width: "22px",
                  height: "22px",
                  marginTop: "5px",
                  marginLeft: "10px",
                }}
              />
              {enableUndo && lastActions.length > 0 && (
                <div
                  id="hoverDropdown"
                  onClick={() => {
                    undoAction()
                  }}
                  style={{
                    display: "flex",
                    cursor: "pointer",
                    marginLeft: "8px",
                  }}
                >
                  <HelveticaBold
                    style={{
                      color: "#414141",
                      marginTop: "6px",
                      width: "120px",
                      textTransform: "none",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {i18n.t("Deshacer acción")}
                  </HelveticaBold>
                </div>
              )}
            </div>

            <div id="botonesPrincBudget">
              {certificaciones && (
                <>
                  <div
                    id="hoverDropdown"
                    onClick={() => {
                      freeTrialExpired
                        ? openFreeTrialExpire()
                        : setOpenDownloadBudgetModal(true)
                    }}
                    style={{ display: "flex", cursor: "pointer" }}
                  >
                    <img
                      src={download}
                      style={{
                        color: "black",
                        marginTop: "-10px",
                        width: "12px",
                        marginRight: "4px",
                        fontWeight: "400",
                      }}
                    />
                    <HelveticaBold
                      style={{
                        color: "#414141",
                        marginTop: "6px",
                        width: "85px",
                        textTransform: "none",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      {i18n.t("Descargar")}
                    </HelveticaBold>
                  </div>
                  <YellowButton
                    onClick={async () => {
                      let result = await closeCertification(
                        projectInfo.projectId
                      )
                      console.log(result)
                      if (result) {
                        setTimeout(() => {
                          window.location.reload()
                        }, [1500])
                      }
                    }}
                    style={{
                      width: "auto",
                      height: "32px",
                      padding: "0px 5px 0px 5px",
                      marginRight: "20px",
                      marginLeft: "10px",
                    }}
                  >
                    {i18n.t("Cerrar certificación")}
                  </YellowButton>
                </>
              )}
              <div className="inline">
                {user ? (
                  user.freeTrialExpired ||
                  user["custom:role"] === "WORKER" ? null : (
                    <>
                      {!version ? (
                        <>
                          {
                            // Botón de descarga de presupuesto
                            certificaciones ? null : (
                              <>
                                {loadingTree ? null : (
                                  <ActionsButtons>
                                    <ActionsButtons.YellowButton
                                      title={
                                        isBudgetClosed
                                          ? "Abrir Presupuesto"
                                          : "Cerrar Presupuesto"
                                      }
                                      loading={btnLoading}
                                      onClick={async () => {
                                        setBtnLoading(true)
                                        isBudgetClosed
                                          ? await openBudgetExtras(
                                              projectInfo.projectId
                                            )
                                          : await closeBudgetExtras(
                                              projectInfo.projectId
                                            )
                                        setBtnLoading(false)
                                      }}
                                    />
                                  </ActionsButtons>
                                )}

                                <DropdownPreferencias />
                                <div
                                  id="hoverDropdown"
                                  onClick={() => {
                                    freeTrialExpired
                                      ? openFreeTrialExpire()
                                      : setOpenPersonalizarPdf(true)
                                  }}
                                  style={{
                                    display: "flex",
                                    cursor: "pointer",
                                    marginRight: "8px",
                                  }}
                                >
                                  <img
                                    src={personalizar}
                                    style={{
                                      color: "black",
                                      marginTop: "-10px",
                                      width: "14px",
                                      marginRight: "4px",
                                      fontWeight: "400",
                                    }}
                                  />
                                  <HelveticaBold
                                    style={{
                                      color: "#414141",
                                      marginTop: "6px",
                                      width: "110px",
                                      textTransform: "none",
                                      fontSize: "14px",
                                      fontWeight: "400",
                                    }}
                                  >
                                    {i18n.t("Personalizar Pdf")}
                                  </HelveticaBold>
                                </div>
                                <div
                                  id="hoverDropdown"
                                  onClick={() => {
                                    freeTrialExpired
                                      ? openFreeTrialExpire()
                                      : setOpenEditMargins(true)
                                  }}
                                  style={{ display: "flex", cursor: "pointer" }}
                                >
                                  <img
                                    src={margenIcon}
                                    style={{
                                      color: "black",
                                      marginTop: "-10px",
                                      width: "14px",
                                      marginRight: "4px",
                                      fontWeight: "400",
                                    }}
                                  />
                                  <HelveticaBold
                                    style={{
                                      color: "#414141",
                                      marginTop: "6px",
                                      width: "110px",
                                      textTransform: "none",
                                      fontSize: "14px",
                                      fontWeight: "400",
                                    }}
                                  >
                                    {i18n.t("Editar márgen")}
                                  </HelveticaBold>
                                </div>
                                <div
                                  id="hoverDropdown"
                                  onClick={() => {
                                    freeTrialExpired
                                      ? openFreeTrialExpire()
                                      : setOpenDownloadBudgetModal(true)
                                  }}
                                  style={{ display: "flex", cursor: "pointer" }}
                                >
                                  <img
                                    src={download}
                                    style={{
                                      color: "black",
                                      marginTop: "-10px",
                                      width: "12px",
                                      marginRight: "4px",
                                      fontWeight: "400",
                                    }}
                                  />
                                  <HelveticaBold
                                    style={{
                                      color: "#414141",
                                      marginTop: "6px",
                                      width: "85px",
                                      textTransform: "none",
                                      fontSize: "14px",
                                      fontWeight: "400",
                                    }}
                                  >
                                    {i18n.t("Descargar")}
                                  </HelveticaBold>
                                </div>
                              </>
                            )
                          }
                        </>
                      ) : null}
                    </>
                  )
                ) : null}
              </div>

              {!certificaciones && (
                <DropdownOptions
                  preferencias={preferencias}
                  setPreferencias={setPreferencias}
                  open2={openEditMargins}
                  setOpen2={setOpenEditMargins}
                  projectInfo={projectInfo}
                  soyFinal={soyFinal}
                  contactos={contactos}
                  handleSendBudgetModal={handleSendBudgetModal}
                  handleDownloadBudgetModal={handleDownloadBudgetModal}
                  agruparPartidas={agruparPartidas}
                  agruparCapitulos={agruparCapitulos}
                  setEstadoLista={setEstadoLista}
                  setEstadoListaCaps={setEstadoListaCaps}
                  openAgrupar={openAgrupar}
                  setOpenAgrupar={setOpenAgrupar}
                  name={name}
                  openCaps={openCaps}
                  setOpenCaps={setOpenCaps}
                  setOpenFiltrar={setOpenFiltrar}
                />
              )}
            </div>
          </div>
        </div>
        {children}
      </div>

      <ModalEditMargins
        projectInfo={projectInfo}
        open={openEditMargins}
        setOpen={setOpenEditMargins}
      />
      <ModalFilterByTag open={openFiltrar} setOpen={setOpenFiltrar} />
      <DownloadBudget
        open={openDownloadBudgetModal}
        setOpen={setOpenDownloadBudgetModal}
        name={projectInfo.name}
        soyFinal={soyFinal}
        fromTab={
          certificaciones
            ? DownloadBudgetEnum.FROM_CERTIFICATION
            : DownloadBudgetEnum.DEFAULT
        }
      />
      <ModalCerrarPres
        projectInfo={projectInfo}
        openCerrar={openCerrar}
        setOpenCerrar={setOpenCerrar}
        open3={open3}
        setOpen3={setOpen3}
        contactos={contactos}
      />
      {openPersonalizarPdf && (
        <ModalPersonalizarPdf
          open={openPersonalizarPdf}
          setOpen={setOpenPersonalizarPdf}
          projectInfo={projectInfo}
        />
      )}
    </>
  )
}
LayoutBudget.propTypes = {
  handleSendBudgetModal: PropTypes.func.isRequired,
  handleDownloadBudgetModal: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  projectInfo: PropTypes.object.isRequired,
  addCapitulo: PropTypes.func.isRequired,
  preferencias: PropTypes.object.isRequired,
  setPreferencias: PropTypes.func.isRequired,
  open3: PropTypes.bool.isRequired,
  setOpen3: PropTypes.func.isRequired,
  contactos: PropTypes.any.isRequired,
  soyFinal: PropTypes.bool,

  setModificado: PropTypes.func,
  version: PropTypes.object,
  index: PropTypes.number,
  certificaciones: PropTypes.bool,
}
export default LayoutBudget
