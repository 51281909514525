import React, { useCallback, useReducer } from "react"
import PropTypes from "prop-types"
import { isNil } from "lodash"
import { API, Auth } from "aws-amplify"
import {
  MOVE_PART,
  REASSIGN_PART_POSITIONS,
  REMOVE_PART,
  SET_ERROR,
  SET_LOADING,
  SET_OPEN_NEW_MODAL_PART,
  SET_PART_ID,
  SET_TREE,
  TOGGLE_DELETE_PART_MODAL,
  SET_BTN_LOADING,
} from "context/types"
import ExtrasAndContradictionsReducer from "./ExtrasAndContradictionsReducer"
import ExtrasAndContradictionsRoot from "./ExtrasAndContradictionsRoot"
import ExtrasAndContradictionsContext from "./ExtrasAndContradictionsContext"
import Movement from "common/Movement.enum"
import Direction from "common/Direction.enum"

const emptyFn = () => {}
const SERVICE_CORE_BASE = "service-core-base"
const SERVICE_BUDGET = "service-budget"
const SERVICE_BUDGET_TEMP = "service-budgeting-temp"

class ExtrasAndContradictions {
  constructor(
    source = null,
    partIdsChecked = [],
    updating = false,
    loading = false,
    error = {
      msj: null,
    },
    showDeletePartModal = false,
    deletingFromPart = null,
    newModalPart = {
      openModal: false,
      partToEdit: null,
    },
    btnLoading = false,
    dispatchs = {
      setTree: emptyFn,
      setPartId: emptyFn,
      setShowDeletePartModal: emptyFn,
      removePartById: emptyFn,
      removeParts: emptyFn,
      movePartsWithArrows: emptyFn,
      setOpenNewModalPart: emptyFn,
      closeExtras: emptyFn,
    }
  ) {
    this.tree = isNil(source) ? null : source
    this.partIdsChecked = partIdsChecked
    this.updating = updating
    this.loading = loading
    this.error = error
    this.showDeletePartModal = showDeletePartModal
    this.deletingFromPart = deletingFromPart
    this.newModalPart = newModalPart
    this.btnLoading = btnLoading
    this.dispatchs = dispatchs
  }
}

const initialState = new ExtrasAndContradictions()

const ExtrasAndContradictionsState = props => {
  const [state, dispatch] = useReducer(
    ExtrasAndContradictionsReducer,
    initialState
  )

  const getCommonConfig = async () => {
    const generalConfig = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        "Access-Control-Allow-Origin": "*",
      },
    }
    return generalConfig
  }

  const setTree = async projectId => {
    try {
      dispatch({
        type: SET_LOADING,
      })

      const configurations = await getCommonConfig()

      const respExtraContradictions = await API.get(
        SERVICE_CORE_BASE,
        `/extras/${projectId}`,
        configurations
      )

      const newObj = new ExtrasAndContradictionsRoot(respExtraContradictions)

      dispatch({
        type: SET_TREE,
        payload: newObj,
      })
    } catch (e) {
      dispatch({
        type: SET_ERROR,
        payload: { loading: false, msj: e },
      })
    }
  }

  const setPartId = partId => {
    const partIdsChecked = state.partIdsChecked.includes(partId)
      ? state.partIdsChecked.filter(id => id !== partId)
      : [...state.partIdsChecked, partId]

    dispatch({
      type: SET_PART_ID,
      payload: { partIdsChecked },
    })
  }

  const setShowDeletePartModal = part => {
    dispatch({
      type: TOGGLE_DELETE_PART_MODAL,
      payload: {
        showDeletePartModal: !state.showDeletePartModal,
        deletingFromPart: typeof part === "boolean" ? null : part,
      },
    })
  }

  const removePartById = async (chapterId, partId, user) => {
    try {
      const configurations = await getCommonConfig()

      const res = await API.del(
        SERVICE_BUDGET_TEMP,
        "/part/" + partId + "?accountId=" + user,
        configurations
      )

      dispatch({
        type: REMOVE_PART,
        payload: {
          chapterId,
          partId,
        },
      })

      dispatch({
        type: REASSIGN_PART_POSITIONS,
        payload: chapterId,
      })
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        payload: { loading: false, msj: e },
      })
    }
  }

  const removeParts = async user => {
    try {
      while (state.partIdsChecked.length > 0) {
        const partToDelete = state.tree.getPartByPartId(state.partIdsChecked[0])
        removePartById(partToDelete.chapterId, partToDelete.partId, user)
        state.partIdsChecked.shift()
      }

      dispatch({
        type: SET_PART_ID,
        payload: { partIdsChecked: [] },
      })

      dispatch({
        type: REASSIGN_PART_POSITIONS,
        payload: null,
      })
    } catch (e) {
      dispatch({
        type: SET_ERROR,
        payload: { loading: false, msj: e },
      })
    }
  }

  const movePartsWithArrows = async (part, movement) => {
    switch (movement) {
      case Movement.UP:
        dispatch({
          type: MOVE_PART,
          payload: {
            part: part,
            searchDirection: Direction.PREVIOUS,
          },
        })
        break
      case Movement.DOWN:
        dispatch({
          type: MOVE_PART,
          payload: {
            part: part,
            searchDirection: Direction.NEXT,
          },
        })
        break
      default:
        throw new Error(
          "Direction is required and must be either 'next' or 'previous'."
        )
    }

    try {
      const position = movement === Movement.DOWN ? 1 : -1
      const configurations = await getCommonConfig()
      const body = {
        partId: part.partId,
        position: position,
      }

      const payload = {
        ...configurations,
        body: body,
      }

      await API.patch(SERVICE_BUDGET, "/part/position", payload)
    } catch (e) {
      dispatch({
        type: SET_ERROR,
        payload: { loading: false, msj: e },
      })
    }
  }

  const setOpenNewModalPart = (isOpen, partToEdit = null) => {
    dispatch({
      type: SET_OPEN_NEW_MODAL_PART,
      payload: {
        isOpen,
        partToEdit,
      },
    })
  }

  const closeExtras = async projectId => {
    try {
      dispatch({
        type: SET_BTN_LOADING,
        payload: true,
      })
      const configurations = await getCommonConfig()
      await API.post(
        SERVICE_CORE_BASE,
        `/extras/close/${projectId}`,
        configurations
      )

      dispatch({
        type: SET_BTN_LOADING,
        payload: false,
      })
    } catch (error) {
      console.log(error)

      dispatch({
        type: SET_BTN_LOADING,
        payload: false,
      })

      dispatch({
        type: SET_ERROR,
        payload: { loading: false, msj: error },
      })
    }
  }

  return (
    <ExtrasAndContradictionsContext.Provider
      value={
        new ExtrasAndContradictions(
          state.tree,
          state.partIdsChecked,
          state.updating,
          state.loading,
          state.error,
          state.showDeletePartModal,
          state.deletingFromPart,
          state.newModalPart,
          state.btnLoading,
          {
            setTree,
            setPartId,
            setShowDeletePartModal,
            removePartById,
            removeParts,
            movePartsWithArrows,
            setOpenNewModalPart,
            closeExtras,
          }
        )
      }
    >
      {props.children}
    </ExtrasAndContradictionsContext.Provider>
  )
}

ExtrasAndContradictionsState.propTypes = {
  children: PropTypes.any.isRequired,
}

export default ExtrasAndContradictionsState
