import React, {useEffect, useState} from "react";  
import PropTypes from "prop-types";
import useNewSearch from "hooks/newSearch";
import { CircularProgress } from "@material-ui/core";
import Part from "./part";
import { HelveticaBold } from "common/typographies";
import DropdownSelectBank from './dropdownSelectBank';

const PriceSearcher = ({partidaState, setPartidaState}) => {
    const {  results, loadingSearch, getResults} = useNewSearch()
    const [loading, setLoading] = useState(false)
    const [bankSelected, setBankSelected] = useState('cype')

    useEffect(() => {
       getData(partidaState.name)
    }, [bankSelected])

    const [resultsToShow, setResultsToShow] = useState([])
    const [iteration, setIteration] = useState(0)
    const [searchingSimilar, setSearchingSimilar] = useState(false)

    const getData = async(name) => {
        if(bankSelected=='cype'){
            let partsList = []
            setLoading(true)
            let result = await getResults(name, 'cype', 'Rehabilitación')
            console.log(result)
            if(result.length>0){
                for(let i=0;i<result.length;i++){
                    if(result[i].parts){
                        for(let j=0;j<result[i].parts.length;j++){
                           partsList.push(result[i].parts[j].partInfo)
                        }

                    }
            }
            }
            let result2 = await getResults(name, 'cype', 'PARTIDAS DE OBRA Y CONJUNTOS')
            console.log(result2)
            if(result2.length>0){
                for(let i=0;i<result2.length;i++){
                    if(result2[i].parts){
                        for(let j=0;j<result2[i].parts.length;j++){
                            partsList.push(result2[i].parts[j].partInfo)
                        }
                    }
            }
          }
            let result3 = await getResults(name, 'cype', 'PRECIOS UNITARIOS')
            console.log(result3)
            if(result3.length>0){
                for(let i=0;i<result3.length;i++){
                    if(result3[i].parts){
                        for(let j=0;j<result3[i].parts.length;j++){
                            partsList.push(result3[i].parts[j].partInfo)
                        }
                    }
            }
        }
        if(partsList.length>0){
            setResultsToShow(partsList)
            setLoading(false)
        }
        else{
            if(!searchingSimilar){
                setSearchingSimilar(true)
            }
            if(iteration<3){
                setIteration(iteration+1)
                let newName = name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^a-zA-Z ]/g, "")
                let newName2 = name.substring(0, newName.lastIndexOf(" "));
                getData(newName2)
            }else{
                setResultsToShow(partsList)
                setLoading(false)
            }
        }
        }else if(bankSelected=='bedec'){
            let partsList = []
            setLoading(true)
            let result = await getResults(name, 'bedec', 'PARTIDAS DE OBRA Y CONJUNTOS')
            console.log(result)
            if(result.length>0){
                for(let i=0;i<result.length;i++){
                    if(result[i].parts){
                        for(let j=0;j<result[i].parts.length;j++){
                           partsList.push(result[i].parts[j].partInfo)
                        }
                    }
            }
            }
            let result2 = await getResults(name, 'bedec', 'MAQUINARIA')
            console.log(result2)
            if(result2.length>0){
                for(let i=0;i<result2.length;i++){
                    if(result2[i].parts){
                        for(let j=0;j<result2[i].parts.length;j++){
                            partsList.push(result2[i].parts[j].partInfo)
                        }
                    }
            }
          }
            let result3 = await getResults(name, 'bedec', 'MATERIALES Y COMPUESTOS')
            console.log(result3)
            if(result3.length>0){
                for(let i=0;i<result3.length;i++){
                    if(result3[i].parts){
                        for(let j=0;j<result3[i].parts.length;j++){
                            partsList.push(result3[i].parts[j].partInfo)
                        }
                    }
            }
        }
        let result4 = await getResults(name, 'bedec', 'MANO DE OBRA')
        console.log(result4)
        if(result4.length>0){
            for(let i=0;i<result4.length;i++){
                if(result4[i].parts){
                    for(let j=0;j<result4[i].parts.length;j++){
                        partsList.push(result4[i].parts[j].partInfo)
                    }
                }
        }
    }
        if(partsList.length>0){
            setResultsToShow(partsList)
            setLoading(false)
        }
        else{
            if(!searchingSimilar){
                setSearchingSimilar(true)
            }
            if(iteration<3){
                setIteration(iteration+1)
                let newName = name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^a-zA-Z ]/g, "")
                let newName2 = name.substring(0, newName.lastIndexOf(" "));
                getData(newName2)
            }else{
                setResultsToShow(partsList)
                setLoading(false)
            }
        }
           
        }else{
            let partsList = []
            setLoading(true)
            let result = await getResults(name, 'preoc', 'PRECIOS UNITARIOS')
            console.log(result)
            if(result.length>0){
                for(let i=0;i<result.length;i++){
                    if(result[i].parts){
                        for(let j=0;j<result[i].parts.length;j++){
                           partsList.push(result[i].parts[j].partInfo)
                        }
                    }
            }
            }
            let result2 = await getResults(name, 'preoc', 'PRECIOS AUXILIARES')
            console.log(result2)
            if(result2.length>0){
                for(let i=0;i<result2.length;i++){
                    if(result2[i].parts){
                        for(let j=0;j<result2[i].parts.length;j++){
                            partsList.push(result2[i].parts[j].partInfo)
                        }
                    }
            }
          }
            let result3 = await getResults(name, 'preoc', 'PRECIOS DESCOMPUESTOS')
            console.log(result3)
            if(result3.length>0){
                for(let i=0;i<result3.length;i++){
                    if(result3[i].parts){
                        for(let j=0;j<result3[i].parts.length;j++){
                            partsList.push(result3[i].parts[j].partInfo)
                        }
                    }
            }
        }
        if(partsList.length>0){
            setResultsToShow(partsList)
            setLoading(false)
        }
        else{
            if(!searchingSimilar){
                setSearchingSimilar(true)
            }
            if(iteration<3){
                setIteration(iteration+1)
                let newName = name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^a-zA-Z ]/g, "")
                let newName2 = name.substring(0, newName.lastIndexOf(" "));
                getData(newName2)
            }else{
                setResultsToShow(partsList)
                setLoading(false)
            }
        }
      
        }
      
}


    const calcHeight = () => {
        if(window.innerHeight<800){
            return '360px'
        }
        else if(window.innerHeight<1000){
            return '600px'
        }
    }


    return (
        <>
        <DropdownSelectBank bankSelected={bankSelected} setBankSelected={setBankSelected} />
        {
            loading? 
            <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', height:'100%'}}>
                <CircularProgress/>
                {
                    searchingSimilar &&
                    <HelveticaBold style={{color:'#414141', fontSize:'14px', marginTop:'10px', marginBottom:'10px'}}>Buscando partidas similares...</HelveticaBold>
                }

            </div>
            :
          <div style={{display:'flex', flexDirection:'column', width:'100%', height:calcHeight(), overflow:'auto'}}>
            {
                resultsToShow.length > 0 ?
                resultsToShow.map((part, i) => (
                    <Part key={i} partidaState={partidaState} setPartidaState={setPartidaState} part={part} i={i}/>
                ))
                :
                <div style={{display:'flex', justifyContent:'center', alignItems:'center', height:'100%'}}>
                    <HelveticaBold style={{color:'#414141', fontSize:'14px', marginTop:'0px', marginBottom:'10px'}}>No se han encontrado resultados</HelveticaBold>
                </div>
            }
          </div>
        }
        </>
    )
}

PriceSearcher.propTypes = {
    partidaState: PropTypes.object.isRequired,
    setPartidaState: PropTypes.func.isRequired,
}

export default PriceSearcher;