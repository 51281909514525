export const SuggestedTags =[
    {
        label: 'Proyecto',
        bgcolor: '#C9DBCD',
        color: '#3BA452',
    },
    {
        label: 'Presupuesto',
        bgcolor: '#B8DBEA',
        color: '#3090B9',
    },
    {
        label: 'Plano',
        bgcolor: '#D6A8A8',
        color: '#BF2929',
    },
    {
        label: 'Informe',
        bgcolor: '#FFC188',
        color: '#DB7600',
    },
    {
        label: 'Factura',
        bgcolor: '#C4BCDB',
        color: '#633BA4',
    },
    {
        label: 'Contrato',
        bgcolor: '#FAE1EF',
        color: '#BA4D8E',
    },
    {
        label: 'Acta',
        bgcolor: '#F2ECB7',
        color: '#AF9E09',
    },
    {
        label: 'Orden de compra',
        bgcolor: '#FFC9F3',
        color: '#D854BB',
    },
]