import React, { useContext, useEffect } from "react"
import PropTypes from "prop-types"
import AuthContext from "context/auth/authContext"
import { isNil } from "lodash"

const FeaturebaseWidget = ({ children }) => {
  const { user, getUser } = useContext(AuthContext)

  useEffect(() => {
    if (isNil(user)) {
      getUser()
    }
  }, [])

  useEffect(() => {
    const win = window

    if (typeof win.Featurebase !== "function") {
      win.Featurebase = function () {
        // eslint-disable-next-line prefer-rest-params
        ;(win.Featurebase.q = win.Featurebase.q || []).push(arguments)
      }
    }

    const lastChangesConfig = {
      organization: "planhopper", // Replace this with your featurebase organization name
      placement: "right", // Choose between right, left, top, bottom placement (Optional if fullscreenPopup is enabled)
      theme: "light", // Choose between dark or light theme
      fullscreenPopup: false, // Optional - Open a fullscreen announcement of the new feature to the user
      locale: "es", // Change the language, view all available languages from https://help.featurebase.app/en/articles/8879098-using-featurebase-in-my-language
      // usersName: "user['custom:nombre_apellido']", // Optional - Show the users name in the welcome message for the fullscreen popup
      alwaysShow: true,
    }

    win.Featurebase("initialize_changelog_widget", lastChangesConfig)

    const feedbackConfig = {
      organization: "planhopper", // Replace this with your organization name, copy-paste the subdomain part from your Featurebase workspace url (e.g. https://*yourorg*.featurebase.app)
      theme: "light",
      //placement: "right", // optional - remove to hide the floating button
      // email: user?.email, // optional
      defaultBoard: "planhopper", // optional - preselect a board
      locale: "es", // Change the language, view all available languages from https://help.featurebase.app/en/articles/8879098-using-featurebase-in-my-language
      metadata: null, // Attach session-specific metadata to feedback. Refer to the advanced section for the details: https://help.featurebase.app/en/articles/3774671-advanced#7k8iriyap66
    }

    win.Featurebase("initialize_feedback_widget", feedbackConfig)
  }, [])

  return <>{children}</>
}

FeaturebaseWidget.propTypes = {
  children: PropTypes.node,
}

export default FeaturebaseWidget
