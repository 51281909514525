import React, { useState, useContext, useEffect } from "react"
import { Auth, API } from "aws-amplify"
import PropTypes from "prop-types"

import AuthContext from "../auth/authContext"
import SubscriptionContext from "./subscriptionContext"

const SubscriptionProvider = ({ children }) => {

  const {user, getUser} = useContext(AuthContext)
  const subscriptionContext = useContext(SubscriptionContext)

  const [subscriptionState, setSubscriptionState] = useState(null)
  const [isTrial, setIsTrial] = useState(false)
  const [noSubscription, setNoSubscription] = useState(false)
  const [daysLeft, setDaysLeft] = useState(30)
  const [showTopAdviceBar, setShowTopAdviceBar] = useState(false)

  const [loading, setLoading] = useState(false)

  const _get_headers = async () => {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken()
    //console.log(`token: ${token}`)
    return {
      "Authorization": `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    }
  }

  useEffect(() => {
    if (!isTrial || !daysLeft) { return }
    setShowTopAdviceBar(daysLeft < 30)
  }, [daysLeft])
  
  useEffect(() => {
    if (!isTrial) { return }
    console.log(`isTrial: ${isTrial}`)

	  if (subscriptionState 
      && subscriptionState.hasOwnProperty("subscription_items")
      && subscriptionState.subscription_items.length > 0
      && subscriptionState.subscription_items[0].status === "in_trial" 
      && subscriptionState.subscription_items[0].hasOwnProperty("trial_end")) {
        // trial_end is epoch, convert to days left
        const epoch = subscriptionState.subscription_items[0].trial_end
        const now = Math.floor(Date.now() / 1000)
        const left = Math.floor((epoch - now) / 86400)
        console.log(`left: ${left}`)
        setDaysLeft(left)
        return left
    }
  }, [isTrial])

  useEffect(() => {
    if (!subscriptionState) { return }
    if (subscriptionState.hasOwnProperty("subscriptions")
          && subscriptionState.subscriptions.length > 0) {
        if (subscriptionState.subscriptions[0].status === "in_trial") {
          setIsTrial(true)
        } else {
          setIsTrial(false)
        }
    } else {
      setNoSubscription(true)
    }
    setLoading(false)
  }, [subscriptionState])

  const fetchSubscriptionData = async () => {
    setLoading(true)

    const config = {
      headers: await _get_headers(),
    }

    const path = "/chargebee/user/active_subscription"
    const query = `user_id=${user.sub}`

    try {
      const response = await API.get("CORE", `${path}?${query}`, config)
      setSubscriptionState(response)
    } catch (error) {
      console.log(`error: ${error}`)
      console.error(error)
      setLoading(false)
    }
  }
 
  useEffect(() => {
    if (user && !loading) {
      setLoading(true)
      fetchSubscriptionData()
    }
  }, [user])

  return (
    <SubscriptionContext.Provider value={{subscriptionState, isTrial, noSubscription, daysLeft, showTopAdviceBar, setShowTopAdviceBar}}>
      {children}
    </SubscriptionContext.Provider>
  )
}

SubscriptionProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default SubscriptionProvider
