import { currencyFormatDE } from 'lib/transformers'
import {React, useContext} from 'react'
import i18n from 'i18n'
import { getCurrency } from 'utils/functions'
import AuthContext from 'context/auth/authContext'

export const getLastRow = (preferencias, capitulo) => {
    const {user} = useContext(AuthContext)

    return (<>
      { // Skip first columns
        <td colSpan={ preferencias.basededatos ? 4 : 2} style={{borderRight:'1px solid #B7B7B7'}}></td>
      }
      { preferencias.precioCoste && (<>
        <td style={{paddingLeft:'0px'}}>{i18n.t('Total')}</td>
        <td style={{paddingLeft:'0px', borderRight:'1px solid #B7B7B7'}}>{currencyFormatDE( parseFloat(capitulo.price), getCurrency(user['custom:currency']))}</td>
      </>)}
      { preferencias.costReal && (<>
        { preferencias.basededatos ? <td></td> : null}
        <td style={{paddingLeft:'0px'}}>{i18n.t('Total')}</td>
        <td style={{paddingLeft:'0px'}}>{currencyFormatDE( parseFloat(capitulo.costReal), getCurrency(user['custom:currency']))}</td>
      </>)}
      { preferencias.clientPrice && (<>
        <td style={{paddingLeft:'0px', borderLeft:'1px solid #B7B7B7'}}>{i18n.t('Total')}</td>
        <td style={{paddingLeft:'0px'}}>{currencyFormatDE( parseFloat(capitulo.clientPriceWithoutDiscount), getCurrency(user['custom:currency']))}</td>
      </>)}
      { preferencias.descuento && (<>
        <td style={{paddingLeft:'0px', borderLeft:'1px solid #B7B7B7'}}>{i18n.t('Total')}</td>
        <td style={{paddingLeft:'0px'}}>{currencyFormatDE( parseFloat(capitulo.clientPrice), getCurrency(user['custom:currency']))}</td>
      </>)}
      { // Options
        <td></td>
      }

      </>
    )
}
