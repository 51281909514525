const DecompositionTypeEnum = Object.freeze({
  coste: 0,
  manodeobra: 1,
  maquinaria: 2,
  material: 3,
  residuoAdicional: 4,
  residuoClasificacion: 5,
})

export default DecompositionTypeEnum
