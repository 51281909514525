import React, { useContext, useEffect } from 'react'
import { Modal } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types'
import { useState } from 'react';
import i18n from 'i18n';
import CloseIcon from "@mui/icons-material/Close";
import { VersionsTable } from 'common/tables';
import ProjectContext from 'context/projects/projectContext';
import ModalPasteSelection from './modalPasteSelection';
import BankPricesContext from 'context/bankPrices/bankPricesContext';
import TemplatesContext from 'context/templates/templatesContext';
import AuthContext from 'context/auth/authContext';

function getModalStyle() {
    const top = 50 ;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: '850px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        borderRadius: 6,
        maxHeight: '550px',
        overflowY: 'auto',
        overflowX:'hidden',
    },
}));


const ModalSelectPlantilla = ({open, setOpen}) => {

    const [ modalStyle ] = useState(getModalStyle);
    const classes = useStyles();
    const {getProjects} = useContext(ProjectContext)
    const [open2, setOpen2] = useState(false);
    const {myPricesBank, getSelectedChapters} = useContext(BankPricesContext)
    
    useEffect(()=>{
        if(open){
            getSelectedChapters([],[], myPricesBank.subcapitulos, true)
        }
      },[open])
    useEffect(() => {
        getProjects()
    }, [])
    const templateContext = useContext(TemplatesContext);
    const {user, getUser} = useContext(AuthContext)
    const coloresTemplates = ['#A1CFC6', '#FC6E6E','#FFE992']
    const { getTemplates, templates} = templateContext;
    const [templateSelected, setTemplateSelected] = useState({});

    useEffect(() => {
        if(!user){
            getUser()
        }
        else{
            getTemplates(user.sub)
        }
    }, [user])


    const handleClick = (template, index) => {
        setTemplateSelected(template)
        setOpen2(true)
    }

    return(
        <>
        <Modal open={open} onClose={() => null}>
            <div style={modalStyle} className={classes.paper}>
                <div id='principalOptions' style={{marginTop:'20px', marginBottom:'15px', textAlign:'left'}}>
                    <CloseIcon id="cerrar" style={{marginTop:'-15px', right:'-10px'}} onClick={()=>{setOpen(false)}}></CloseIcon>
                    <p id='titles' style={{textAlign:'CENTER', fontSize:'20px', marginBottom:"35px"}}>{i18n.t("Selecciona la plantilla dónde quieres pegar tu selección")}</p>
                    
                    <VersionsTable style={{width:'99%'}}>
                        <tr>
                            <th style={{width:'430px'}} colSpan="2">{i18n.t('Nombre')}</th>
                            <th style={{width:'330px'}}>{i18n.t('Descripción')}</th>
                            <th style={{width:'140px'}}>{i18n.t('Etiqueta')}</th>
                        </tr>
                        {templates.length > 0 ? templates.map((template, index) => (
                            template.name && template.accountId!='tribboo'? 
                            <tr key={index} style={{cursor:'pointer', backgroundColor:templateSelected && templateSelected.templateId==template.templateId && 'rgb(252, 213, 107)'}} onClick={()=>{handleClick(template, index)
                            }}>
                                <td style={{width:'80px'}}><div style={{width:'35px', height:'35px', marginRight:'0px', borderRadius:'45px', backgroundColor: index<2? coloresTemplates[index] : coloresTemplates[index%3]}}></div></td>
                                <td style={{fontWeight:'500', color:'#rgb(252, 213, 107)', paddingLeft:'0px'}}>{template.name}</td>
                                <td style={{color:'#414141'}}> {template.description}</td>
                                <td>{template.tags? template.tags.length>0 ?
                                 <div style={{width:'fit-content', paddingRight:'10px', color:'#414141'}} id={template.tags[0][1]} className="tag">
                                 {template.tags[0][0]}
                               </div>
                                : null : null}</td>
                            </tr>
                            :null
                        )) : null}
                    </VersionsTable>
                        </div>
            </div>
        </Modal>
        <ModalPasteSelection open={open2} setOpen={setOpen2} template={templateSelected} setOpenPrincipal={setOpen}/>
        </>
    )
}

ModalSelectPlantilla.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
}
export default ModalSelectPlantilla;