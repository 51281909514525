import React, {useContext, useRef, useState, useEffect} from "react";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import {Modal} from "@mui/material";
import { useHistory } from 'react-router-dom';
import {makeStyles} from "@material-ui/core/styles";
import ProjectContext from "context/projects/projectContext";
import i18n from "i18n";
import Budget2023Context from "context/budget2023/Budget2023Context";
function getModalStyle() {
    const top = 50 ;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: '630px',
        height: '470px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: "0px 3px 5px -1px rgb(0 0 0 / 7%), 0px 5px 8px 0px rgb(0 0 0 / 7%), 0px 1px 14px 0px rgb(0 0 0 / 7%)",
        padding: theme.spacing(2, 4, 3),
        borderRadius: 6,
        maxHeight: 'calc(100vh - 100px)',
        overflowY: 'auto',
        overflowX:'hidden',
        height: '440px'
    },
}));



const Presupuesto = ({projectInfo, open, setOpen}) => {
    const [ modalStyle ] = useState(getModalStyle);
    const classes = useStyles();
    const refGI = useRef(null);
    const refGG = useRef(null);
    const refBI = useRef(null);
    const projectContext = useContext(ProjectContext)
    const {updatePresupuesto} = projectContext
    const {readyToClose} = useContext(Budget2023Context)
    const handleClick = (e) =>{
        if(e.target.name=="gi"){
            refGI.current['id']="focusLittle"
        }
        if(e.target.name=="gg"){
            refGG.current['id']="focusLittle"
        }
        if(e.target.name=="bi"){
            refBI.current['id']="focusLittle"
        }
    }
    const [estadoPresupuesto, setEstadoPres] = useState({
        gi:'', gg:'', bi:''})
    

    const handleClose = () => {
        setOpen(false);
    }
    useEffect(() => {
        if(open==true){
        if (projectInfo.costeGeneral!=null){
            setEstadoPres({
                gi:projectInfo.costeIndirecto, gg:projectInfo.costeGeneral, bi:projectInfo.industrialProfit
            })
       }
        }

     }, [open])


    const handleChange = (e) => {
        if(e.target.value>=0){
        setEstadoPres({
            ...estadoPresupuesto,
            [e.target.name] : e.target.value
        })
        }else{
            setEstadoPres({
                ...estadoPresupuesto,
                [e.target.name] : e.target.value*-1
            })
        }
    }
    const history = useHistory();
    const guardarPres = async (e) => {
        let valida=true
        if(estadoPresupuesto.gi==''){
            valida=false
            refGI.current['id']="focusError"
        }
        if(estadoPresupuesto.gg==''){
            valida=false
            refGG.current['id']="focusError"
        }
        if(estadoPresupuesto.bi==''){
            valida=false
            refBI.current['id']="focusError"
        }
        if(valida==true){
            await updatePresupuesto(projectInfo, estadoPresupuesto)
            history.push(`/budgeting/final/${projectInfo.projectId}`)
            location.reload()

        }
  
    }



    
    return (
   
   <Modal open={open} onClose={handleClose}>
            <div id="totalDiv">
                <div style={modalStyle} className={classes.paper}>
                <CloseIcon id="cerrar" onClick={()=>{setOpen(false)}}style={{marginTop:'0px', paddingLeft:'6px', marginRight:'-10px'}}></CloseIcon>
                    <div id="DivTitleEliminar" style={{marginTop:'25px'}}>
                        <p id="centradoBudget">{i18n.t("Define los siguientes márgenes para obtener tu precio final para el cliente.")} </p>
                    </div>
                    <div id="linePress" style={{justifyContent:'center'}}>
                        <p className="mlGI" id="mtline">{i18n.t("Gasto Indirecto")}</p>
                        <input ref={refGI} id="inputGasto" style={{color:'#414141', fontSize:'14px', fontWeight:'400', paddingTop:'1px'}} onClick={handleClick} onChange={handleChange}
                               type="number" name="gi" value={estadoPresupuesto.gi}
                        />
                        <p id="mtline">%</p>
                    </div>
                    <div id="linePress" style={{justifyContent:'center'}}>
                        <p className="mlGG" id="mtline">{i18n.t("Gastos Generales")}</p>
                        <input ref={refGG} style={{color:'#414141', fontSize:'14px', fontWeight:'400', paddingTop:'1px'}} id="inputGasto" onClick={handleClick} onChange={handleChange}
                               type="number" name="gg" value={estadoPresupuesto.gg}/>
                        <p id="mtline">%</p>
                    </div>
                    <div id="linePress" style={{justifyContent:'center'}}>
                        <p className="mlBI" id="mtline">{i18n.t("Beneficio Industrial")}</p>
                        <input ref={refBI} style={{color:'#414141', fontSize:'14px', fontWeight:'400', paddingTop:'1px'}} id="inputGasto" name="bi" onClick={handleClick} onChange={handleChange}
                               type="number" value={estadoPresupuesto.bi}
                        />
                        <p id="mtline">%</p>
                    </div>
                    <p style={{marginTop:'10px'}} className="lightPoppins">
                    {i18n.t("Podrás modificar los  márgenes de cualquier partida, pulsando el ícono de configuración dentro de presupuesto.")}
                    </p>
                    <div id="centerButton" style={{marginTop:'0px'}}><button type="button"
                                                   className="AddContact" 
                                                    data-amplify-analytics-on="click"
                                                    data-amplify-analytics-name="ChangeProfitMargin"
                                                    data-amplify-analytics-attrs={estadoPresupuesto}
                                                   onClick={guardarPres}>{i18n.t("Guardar")}</button></div>
                </div>
            </div>
        </Modal>
    );
}

Presupuesto.propTypes = {
    projectInfo: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired
};

export default Presupuesto;