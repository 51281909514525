import React, { Fragment, useContext, useEffect, useState } from "react"
import {
  Modal,
  Box,
  Grid,
  TextField,
  Stack,
  Chip,
  Typography,
  IconButton,
  Popover,
  Autocomplete,
  Avatar,
} from "@mui/material"
import PropTypes from "prop-types"
import LocalOfferRoundedIcon from "@mui/icons-material/LocalOfferRounded"
import { SuggestedTags } from "./StylesAndUtils/Constants"
import AddOutlinedIcon from "@mui/icons-material/AddOutlined"
import animationData from "../../lotties/uploadAnimation.json"
import { ListaColores } from "./StylesAndUtils/Colors"
import i18n from "i18n"
import { CustomButton, style } from "./StylesAndUtils/CustomStyles"
import { HelveticaLight } from "common/typographies"
import ContactContext from "context/contact/contactContext"
import FileContext from "context/file/fileContext"
const ModalEdit = ({
  open,
  setOpen,
  file,
  setSelectedFiles
}) => {
  const {Contacts} = useContext(ContactContext)
  const {updateFile} = useContext(FileContext)
  const [contacts, setContacts] = useState(Contacts)
  const [anchorEl, setAnchorEl] = useState(null)
  const [openAddContact, setOpenAddContact] = useState(false)
  const handleOpenPopOver = e => {
    setAnchorEl(e.currentTarget)
    setOpenAddContact(true)
  }
  const handleCloseAddContact = () => {
    setAnchorEl(null)
    setOpenAddContact(false)
  }
 
  const handleClose = () => {
    setOpen(false)
  }
  const [auxTag, setAuxTag] = useState("")
  const [documentInfo, setDocumentInfo] = useState({
    filename: "",
    description: "",
    tagsFinal: [],
    members: [],
  })

  useEffect(() => {
   
      setDocumentInfo({
        filename: file.filename,
        description: file.description,
        tagsFinal: [],
        members: file.members
          ? file.members
          : [],
        id: file.fileId,
        orgId: file.orgId,
      })
    
  }, [])
  useEffect(() => {
    setContacts(Contacts)
  }, [Contacts])

  const { filename, description, tagsFinal, members } = documentInfo
  const [tags, setTags] = useState(SuggestedTags)
  const addTag = index => {
    setTags(tags.filter((tag, i) => i !== index))
    setDocumentInfo({
      ...documentInfo,
      tagsFinal: [...documentInfo.tagsFinal, tags[index]],
    })
  }
  const removeTag = index => {
    setDocumentInfo({
      ...documentInfo,
      tagsFinal: documentInfo.tagsFinal.filter((tag, i) => i !== index),
    })
    setTags([...tags, tagsFinal[index]])
  }
  const handleChange = e => {
    setAuxTag(e.target.value)
  }
  const handleAddTag = e => {
    let color = ListaColores[tags.length % ListaColores.length]
    if (e.key === "Enter") {
      let aux = { label: auxTag, bgcolor: color.bg, color: color.color }
      setDocumentInfo({
        ...documentInfo,
        tagsFinal: [...documentInfo.tagsFinal, aux],
      })
      setAuxTag("")
    }
  }
  const handleChangeFields = e => {

    setDocumentInfo({ ...documentInfo, [e.target.name]: e.target.value })
  }
  const addMember = option => {
    setDocumentInfo({
      ...documentInfo,
      members: [...documentInfo.members, option],
    })
  }
  const onUpdateDocument = async () => {
    let t = documentInfo.tagsFinal.map(tag => tag.label)
    let m = documentInfo.members.map(member => {
      return { id: member.id, completeName: member.completeName }
    })
    let data = {
      filename: documentInfo.filename,
      description: documentInfo.description,
      tags: t,
      members: m,
      orgId: documentInfo.orgId,
      fileId: documentInfo.id,
    }
    await updateFile(data)
    setSelectedFiles([])
    setOpen(false)
   
      
  }


  return (
    <Fragment>
      <Modal open={open} onClose={handleClose}>
     
          <Box component="form" sx={style}>
            <h3 style={{marginBottom:'5px'}}>{i18n.t("Información del Documento")}</h3>
            <Grid container spacing={2} p={2}>
              <Grid item xs={12} md={12} lg={12}>
                <HelveticaLight style={{color:'#414141', marginBottom:'2px'}}>
                  <b>{i18n.t("Nombre del documento")}</b>
                </HelveticaLight>
                <TextField
                  fullWidth
                  value={filename}
                  id="filename"
                  name="filename"
                  onChange={handleChangeFields}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <TextField
                  label="Descripción"
                  fullWidth
                  value={description}
                  multiline
                  rows={3}
                  name="description"
                  id={"description"}
                  onChange={handleChangeFields}
                />
              </Grid>
              <Grid
                container
                direction="row"
                marginTop={"1rem"}
                alignContent={"center"}
              >
                <Typography variant="body1">
                  <b>{i18n.t("Compartir con ")}</b>
                </Typography>
                <IconButton
                  sx={{
                    border: "1.2px dashed #D0D0D0",
                    height: "30px",
                    width: "30px",
                    marginLeft: "1rem",
                  }}
                  onClick={handleOpenPopOver}
                >
                  <AddOutlinedIcon sx={{ fill: "#C9C9C9" }} />
                </IconButton>
              </Grid>
              <Grid item marginLeft={"2rem"}>
                <Stack direction={"row"} spacing={"5px"}>
                  {members.map((member, index) => (
                    <Avatar
                      key={member.id}
                      alt={member}
                      sx={{ width: "30px", height: "30px" }}
                    >
                      {member.completeName?.charAt(0)}
                    </Avatar>
                  ))}
                </Stack>
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <TextField
                  fullWidth
                  label="Etiquetas"
                  InputProps={{
                    startAdornment: (
                      <Stack direction={"row"} spacing={1}>
                        <LocalOfferRoundedIcon sx={{ marginRight: "0.5rem" }} />
                        {tagsFinal.map((tag, index) => (
                          <Chip
                            key={index}
                            label={tag?.label}
                            onDelete={() => {
                              removeTag(index)
                            }}
                            style={{
                              backgroundColor: tag?.bgcolor,
                              color: tag?.color,
                              borderRadius: "4px",
                            }}
                          />
                        ))}
                      </Stack>
                    ),
                  }}
                  value={auxTag}
                  onChange={handleChange}
                  onKeyDown={handleAddTag}
                />
              </Grid>
              <Grid container spacing={3} mt={"5px"}>
                {tags.map((tag, index) => (
                  <Grid item key={index}>
                    <Chip
                      label={tag.label}
                      onClick={() => addTag(index)}
                      style={{
                        backgroundColor: tag.bgcolor,
                        color: tag.color,
                        borderRadius: "4px",
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
              <Grid item xs={12} md={12} lg={12} p={2} mt={"2rem"}>
                <Box
                  display="flex"
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CustomButton onClick={onUpdateDocument}>
                    {i18n.t("Guardar")}
                  </CustomButton>
                </Box>
              </Grid>
            </Grid>
          </Box>
        
      </Modal>
      <Popover
        id="add-contact"
        open={openAddContact}
        anchorEl={anchorEl}
        onClose={handleCloseAddContact}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      >
        <Grid container direction={"column"} width={"400px"} padding={"2rem"}>
          <Grid item xs={12}>
            <Typography variant="h6">Buscar en contactos</Typography>
          </Grid>
          <Grid item xs={12} marginTop={"1rem"}>
            <Autocomplete
              freeSolo={true}
              options={contacts}
              getOptionLabel={option => option.completeName || ""}
              renderInput={params => (
                <TextField
                  {...params}
                  fullWidth
                  sx={{ border: "1px solid #C4C4C4", borderRadius: "6px" }}
                />
              )}
              renderOption={(props, option, { selected }) => (
                <li
                  {...props}
                  key={option.id}
                  onClick={e => {
                    addMember(option)
                  }}
                >
                  <Avatar>{option.completeName.charAt(0)}</Avatar>
                  <Box
                    sx={{
                      flexGrow: 1,
                      marginLeft: "1rem",
                    }}
                  >
                    {option.completeName}
                  </Box>
                </li>
              )}
            />
          </Grid>
        </Grid>
      </Popover>
    </Fragment>
  )
}

ModalEdit.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  file: PropTypes.object,
  setSelectedFiles: PropTypes.func,
}

export default ModalEdit
