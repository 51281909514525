import React, {useState} from 'react';
import {Modal, Box, Typography, Grid} from '@mui/material'
import Dropzone from 'react-dropzone';
import  {CustomButton} from './StylesAndUtils/CustomStyles'
import {makeStyles} from "@material-ui/core/styles";
import CloseIcon from "@mui/icons-material/Close";
import i18n from 'i18n';
import {
    Form,
    Card,
    Row,
    Col,
    CardTitle,
} from 'reactstrap'
import {Link } from 'react-router-dom'
import PropTypes from 'prop-types';
import CheckIcon from '@mui/icons-material/Check';
import {useParams} from 'react-router-dom'

function getModalStyle() {
  const top = 50 ;
  const left = 50;

  return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
      position: 'absolute',
      width: '550px',
      backgroundColor: '#FFFFFF',
      boxShadow: "0px 3px 5px -1px rgb(0 0 0 / 7%), 0px 5px 8px 0px rgb(0 0 0 / 7%), 0px 1px 14px 0px rgb(0 0 0 / 7%)",
      padding: theme.spacing(2, 4, 3),
      borderRadius: 6,
      maxHeight: 'calc(100vh - 100px)',
      overflowY: 'auto',
      overflowX:'hidden',
      height: '480px'
  },
}));

const DocumenDropZone =({open, setOpen, selectedFiles, setselectedFiles, handleSubmit, uploadProcess, imInPart, handleUploadImageToPart}) =>{
  const [ modalStyle ] = useState(getModalStyle);
  const classes = useStyles();
  const {projectId} = useParams()

    function handleAcceptedFiles(files) {
        files.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size),
            projectId: projectId ? projectId=='sin_clasificar' ? undefined : projectId : undefined,
          })
        )
        setselectedFiles(files)
      }
      /**
       * Formats the size
       */
      function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    
        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
      }

      return (
      <Modal
        open={open}
        onClose={() => null}

      >
             <div id="totalDiv">
        <div style={modalStyle} className={classes.paper}>
        <CloseIcon id="cerrar" onClick={()=>{setOpen(false)}}style={{marginTop:'0px', paddingLeft:'6px', marginRight:'-10px'}}></CloseIcon>
          <Card style={{border:'none',  boxShadow:'none'}}>
            <CardTitle className='text-center' style={{border:'none', boxShadow:'none'}}>
              <p id="SubTitulo" style={{marginBottom:'30px',marginTop:'40px', boxShadow:'none'}}>
                {i18n.t("Importa un archivo desde tu ordenador")}
              </p>
            </CardTitle>
          <Form>
            {selectedFiles.length > 0 ?
              <Grid container direction={'column'} mt={'2rem'} mb={'2rem'}  spacing={2} justifyContent={'center'} alignItems={'center'}>
                  <Grid item xs={12}>
                    <CheckIcon />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='p'>
                      {i18n.t("Documento Añadido")}
                    </Typography>
                  </Grid>
              </Grid>
              
              :
            <Dropzone
              onDrop={acceptedFiles => {
                handleAcceptedFiles(acceptedFiles)
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <div className="dropzone" style={{width:'368px', height:'232px', marginLeft:'53px', border: '2px dashed #FFD52B'}}>
                  <div
                    className="dz-message needsclick mt-2"
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    <p style={{
                      fontStyle: 'normal',
                      fontWeight: '500',
                      fontSize: '16px',
                      lineHeight: '19px',
                      fontFamily: 'Roboto',
                      color:'#414141'
                    }}>{i18n.t("Arrastrar archivos o explorar equipo")}</p>
                    <div className="mb-3">
                      <i className="display-4 text-muted bx bxs-cloud-upload" />
                    </div>
                    <p style={{
                      fontWeight: "300",
                      fontSize:"14px",
                      lineHeight: "16px",
                      textAlign:"center",
                      fontFamily:"Helvetica"
                    }}>{i18n.t("Sube tus archivos para poder gestionar mejor tus proyectos")}
                    </p>
                  </div>
                </div>
              )}
            </Dropzone>
            }
            <div className="dropzone-previews mt-3" id="file-previews">
              {selectedFiles.map((f, i) => {
                return (
                  <Card style={{width:'368px', marginLeft:'53px'}}
                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                    key={i + "-file"}
                  >
                    <div className="p-2">
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <i className="bx bx-file"/>
                        </Col>
                        <Col>
                          <Link
                            to="#"
                            className="text-muted font-weight-bold"
                          >
                            {f.name}
                          </Link>
                          <p className="mb-0">
                            <strong>{f.formattedSize}</strong>
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                )
              })}
            </div>
          </Form>
          </Card>
          <Grid container direction={'row'} justifyContent={'center'} >
          {uploadProcess.isUploading ?
            <p>{i18n.t("Subiendo...")}</p>
          :
          imInPart ?
            <CustomButton 
            data-amplify-analytics-on="click"
            data-amplify-analytics-name="SaveInvoice"
            data-amplify-analytics-attrs={selectedFiles}
            disabled={selectedFiles.length > 0 ? false: true  } onSubmit={(e)=>{handleUploadImageToPart(e, selectedFiles)}} onClick={(e)=>{handleUploadImageToPart(e, selectedFiles)}} style={{textTransform:'none'}}>
             {i18n.t("Subir")}
          </CustomButton>
          :
          <CustomButton 
          data-amplify-analytics-on="click"
          data-amplify-analytics-name="SaveInvoice"
          data-amplify-analytics-attrs={selectedFiles}
          disabled={selectedFiles.length > 0 ? false: true  } onSubmit={handleSubmit} onClick={handleSubmit} style={{textTransform:'none'}}>
            {i18n.t("Subir")}
       </CustomButton>
          }
          </Grid>
          </div>
          </div>
        </Modal>
      )
}

DocumenDropZone.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    selectedFiles: PropTypes.array.isRequired,
    setselectedFiles: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    uploadProcess: PropTypes.object.isRequired,
    imInPart : PropTypes.bool,
    handleUploadImageToPart: PropTypes.func
}

export default DocumenDropZone