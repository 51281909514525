export const ON_SAVE_CARD = "ON_SAVE_CARD"
export const SAVE_CARD_SUCCESS = "SAVE_CARD_SUCCESS"
export const SAVE_CARD_ERROR = "SAVE_CARD_ERROR"

export const ON_SAVE_BILLING_ADDRESS = "ON_SAVE_BILLING_ADDRESS"
export const SAVE_BILLING_ADDRESS_SUCCESS = "SAVE_BILLING_ADDRESS_SUCCESS"
export const SAVE_BILLING_ADDRESS_ERROR = "SAVE_BILLING_ADDRESS_ERROR"

export const ON_GET_CUSTOMER_INFO = "ON_GET_CUSTOMER_INFO"
export const GET_CUSTOMER_INFO_SUCCESS = "GET_CUSTOMER_INFO_SUCCESS"
export const GET_CUSTOMER_INFO_ERROR = "GET_CUSTOMER_INFO_ERROR"

export const ON_VALIDATE_COUPON = "ON_VALIDATE_COUPON"
export const VALIDATE_COUPON_SUCCESS = "VALIDATE_COUPON_SUCCESS"
export const VALIDATE_COUPON_ERROR = "VALIDATE_COUPON_ERROR"

export const ON_DELETE_CARD = "ON_DELETE_CARD"
export const DELETE_CARD_SUCCESS = "DELETE_CARD_SUCCESS"
export const DELETE_CARD_ERROR = "DELETE_CARD_ERROR"

export const ON_PAYMENT_INTENT = "ON_PAYMENT_INTENT"
export const PAYMENT_INTENT_SUCCESS = "PAYMENT_INTENT_SUCCESS"
export const PAYMENT_INTENT_ERROR = "PAYMENT_INTENT_ERROR"

export const ON_SEND_INVITATION = "ON_SEND_INVITATION"
export const SEND_INVITATION_SUCCESS = "SEND_INVITATION_SUCCESS"
export const SEND_INVITATION_ERROR = "SEND_INVITATION_ERROR"
