import React, {useContext, useState} from "react";
import {CircularProgress, Modal} from "@mui/material";
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import Budget2023Context from "context/budget2023/Budget2023Context";
import deleteCap from 'assets/images/budgeting/deleteCap.png'
import i18n from "i18n";
import AuthContext from "context/auth/authContext";
import ModalDeletePartEnum from "common/ModalDeletePartEnum";
import ExtrasAndContradictionsContext from "context/extrasAndContradictions/ExtrasAndContradictionsContext";

function getModalStyle() {
    const top = 50 ;
    const left = 50;
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: '630px',
        height: '470px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        borderRadius: 6,
        maxHeight: 'calc(100vh - 100px)',
        overflowY: 'auto',
        overflowX:'hidden',
    },
}));

const ModalDeletePart = ({ openEliminate, setOpenEliminate, partida, setOpenModalGeneral, from = ModalDeletePartEnum.FROM_BUDGETING}) => {
    
    // Configuración del modal para añadir contactos
    const [ modalStyle ] = useState(getModalStyle);
    const classes = useStyles();  
    const {user} = useContext(AuthContext)
    const [loading, setLoading] = useState(false)
    const onClose = ()=> {
        setOpenEliminate(false)
    }

        const budgetContext = useContext(Budget2023Context)
        const extrasAndContradictionsContext = useContext(
          ExtrasAndContradictionsContext
        )

        let deletePart
        let deleteMultiple
        let partsChecked

        switch (from) {
          case ModalDeletePartEnum.FROM_BUDGETING:
            deletePart = budgetContext.deletePartidav2
            deleteMultiple = budgetContext.deleteMultipleParts
            partsChecked = budgetContext.partsChecked
            break
          case ModalDeletePartEnum.FROM_EXTRAS_AND_CONTRADICTIONS:
            deletePart = extrasAndContradictionsContext.dispatchs.removePartById
            deleteMultiple =
              extrasAndContradictionsContext.dispatchs.removeParts
            partsChecked = extrasAndContradictionsContext.partIdsChecked
            if(partida){
                partida.partidaId = partida?.partId
            }
            break
          default:
            deletePart = budgetContext.deletePartidav2
            deleteMultiple = budgetContext.deleteMultipleParts
            partsChecked = budgetContext.partsChecked
            break
        }

      const handleDeletePartida = async e => {
        e.preventDefault()
        setLoading(true)
        if (partsChecked.length == 0) {
            await deletePart(partida.chapterId, partida.partidaId, user.sub)
            setLoading(false)
            if(setOpenModalGeneral){
                setOpenModalGeneral(false)
            }
            onClose()
        } else {
            await deleteMultiple(user.sub)
            setLoading(false)
            onClose()
        }
   
      }

    return(
        <>
            <Modal open={openEliminate} onClose={onClose}>
                <div style={modalStyle} className={classes.paper}>
                    <div id='centerPaco'>
                        <img src={deleteCap} style={{width:'95px', height:'144px'}}/>
                    </div>

                    <div id="DivTitleEliminar2" style={{width:'100%', textAlign:'center', display:'block', marginLeft:'0PX'}}><p id="centerTitleEliminar" style={{marginTop:'40px'}}>{i18n.t("Estás a punto de eliminar esta Partida")}</p></div>
                    
                    <div id="DivTitleEliminar2" style={{width:'100%', textAlign:'center', display:'block', marginLeft:'0px'}}>
                    <p id='subtitledelete'> {i18n.t("Eliminarás las Partidas seleccionadas")} <br/> {i18n.t("No podrás recuperar este apartado una vez eliminado.")} </p>
                    </div>
                    <div id="centerButton">
                        <div id='DivTitleEliminar2' style={{marginLeft:'70px', marginBottom:'10px', marginTop:'20px'}}>
                            {loading ? 
                                <CircularProgress size={24}/> 
                                : 
                                <>
                                <button type="button" style={{width: '190px', marginRight: '15px', borderRadius:'6px'}}
                                    className="CancelDel" onClick={onClose}>{i18n.t("Cancelar")}</button>
                                <button type="button" style={{width: '190px', position:'initial', marginTop:'0px', color:'white', backgroundColor:'#BF2929'}}
                                        className="AddProject" id='DeleteButton'  onClick={handleDeletePartida}>{i18n.t("Eliminar")}</button>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

ModalDeletePart.propTypes = {
    openEliminate : PropTypes.bool.isRequired,
    setOpenEliminate: PropTypes.func.isRequired,
    partida: PropTypes.object,
    setOpenModalGeneral: PropTypes.func,
    from: PropTypes.oneOf(Object.values(ModalDeletePartEnum))
}
export default ModalDeletePart;