import React, {useState} from "react";
import {CircularProgress, Modal} from "@mui/material";

import { makeStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import deleteCap from 'assets/images/budgeting/deleteCap.png'
import i18n from "i18n";
import { HelveticaBold } from "common/typographies";



function getModalStyle() {
    const top = 50 ;
    const left = 50;
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: '630px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        borderRadius: 6,
        height: '470px',
        maxHeight: 'calc(100vh - 100px)',
        overflowY: 'auto',
        overflowX:'hidden',
    },
}));

const ModalDeleteFactura = ({ openEliminate, setOpenEliminate, factura, invoiceService}) => {
    const [ modalStyle ] = useState(getModalStyle);
    const [loading, setLoading] = useState(false)
    const classes = useStyles();
    const onClose = ()=> {
        setOpenEliminate(false)
    }


    return(
        <>
            <Modal open={openEliminate} onClose={onClose}>
                <div style={modalStyle} className={classes.paper}>
                    <div id='centerPaco'>
                        <img src={deleteCap} style={{width:'95px', height:'144px'}}/>
                    </div>

                    <div id="DivTitleEliminar2" style={{width:'100%', textAlign:'center', display:'block', marginLeft:'0px'}}><HelveticaBold id="centerTitleEliminar" style={{marginTop:'40px', fontSize:'18px'}}>{i18n.t("Estás a punto de eliminar esta Factura")}</HelveticaBold></div>
                    
                    <div id="DivTitleEliminar2" style={{width:'100%', textAlign:'center', display:'block', marginLeft:'0px'}}>
                    <p id='subtitledelete'> {i18n.t("Eliminarás la factura seleccionada")} <br/> {i18n.t("No podrás recuperarla una vez eliminada.")} </p>
                    </div>
                    {!loading ?
                    <div id="centerButton" style={{marginTop:'50px'}}>
                        <div id='DivTitleEliminar2' style={{marginLeft:'70px', marginBottom:'10px'}}>
                        <button type="button" style={{width: '190px', marginRight: '15px', borderRadius:'6px'}}
                               className="CancelDel" onClick={onClose}>{i18n.t("Cancelar")}</button>
                        <button type="button" style={{width: '190px', position:'initial', marginTop:'0px', color:'white', backgroundColor:'#BF2929'}}
                                className="AddProject" onClick={async()=>{setLoading(true);await invoiceService.deleteInvoice(factura.invoiceId); setOpenEliminate(false); setLoading(false)}}>{i18n.t("Eliminar")}</button></div>
                    </div>
                    :
                    <div id="centerButton" style={{marginTop:'50px'}}>
                        <CircularProgress size={24} style={{color:'#BF2929'}}/>
                    </div>
                    }
                </div>
            </Modal>
        </>
    )
}

ModalDeleteFactura.propTypes = {
    openEliminate : PropTypes.bool.isRequired,
    setOpenEliminate: PropTypes.func.isRequired,
    factura: PropTypes.object.isRequired,
    invoiceService: PropTypes.any
}
export default ModalDeleteFactura;