import React, { useState, useEffect, useContext } from "react"
import PropTypes from 'prop-types'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
//i18n
import { withTranslation } from "react-i18next"
import { withRouter, useParams } from "react-router-dom"
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import i18n from "i18n"
import TemplatesContext from "context/templates/templatesContext"
import ModalNoSelection from "../modals/modalNoSelection"
import ImportTemplate from "../modals/importTemplate";
import SuccessImportTemplate from "../modals/successImportTemplate";
import UploadExcelModal from "../modals/uploadExcel";
import ModalWaitingExcel from "pages/Dashboard/ModalWaitingExcel";



const DropdownOptions = props => {
  
  const [menu, setMenu] = useState(false)
  const {myPricesBank, getSelectedChapters, selectedCategories2, partsChecked, agruparPartidas, agruparCapitulos, templateInfo} = useContext(TemplatesContext)
  const [openNoSelection, setOpenNoSelection] = useState(false)
  const [nombre, setNombre] = useState('')
  const {templateId} = useParams()
  const [openModalForExcel, setOpenModalForExcel] = useState(false)
  const[openWaitModalForExcel, setOpenWaitModalForExcel] = useState(false)
  const [openImportModal, setOpenImportModal] = useState(false)
  const [openModalImportSuccess, setOpenModalImportSuccess] = useState(false)
  useEffect(()=>{
      if(menu){
          getSelectedChapters([],[], myPricesBank.subcapitulos, true)
      }
    },[menu])


  return (
    <>
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
        
         >
        <DropdownToggle
        id='settingb'
            style={{marginTop:'-10px', color:'black', margin:'0px', padding:'0px'}}
            
        >
        <MoreHorizIcon id='settingsBud' style={{color:'#414141'}}/>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end" id='importantLeft'>

          <DropdownItem tag="c" style={{cursor:'pointer',display:'flex'}} onClick={()=>{if(partsChecked.length==0){setNombre('partidas'), setOpenNoSelection(true)}else{agruparPartidas(partsChecked,templateId)}}}>
            {" "}
            <i style={{marginTop:'-2px', paddingTop:'4px'}} className="bx bx-vertical-center font-size-16 align-middle me-1"/>
            {i18n.t("Agrupar Partidas")}  
          </DropdownItem>

          <DropdownItem  tag="c" style={{cursor:'pointer',display:'flex'}} onClick={()=>{if(selectedCategories2.length==0){setNombre('categorias'), setOpenNoSelection(true)}else{agruparCapitulos(selectedCategories2, templateId)}}}>
            {" "}
            <i style={{marginTop:'-2px', paddingTop:'4px'}} className="bx bx-vertical-center font-size-16 align-middle me-1"/>
            {i18n.t("Agrupar Categorias")}
          </DropdownItem>
          <DropdownItem  tag="c" style={{cursor:'pointer',display:'flex'}} onClick={()=>{setOpenImportModal(true)}}>
            {" "}
            <i style={{marginTop:'-2px', paddingTop:'4px'}} className="bx bx-plus font-size-16 align-middle me-1"/>
            {i18n.t("Importar BC3")}
          </DropdownItem>
          <DropdownItem  tag="c" style={{cursor:'pointer',display:'flex'}} onClick={()=>{setOpenModalForExcel(true)}}>
            {" "}
            <i style={{marginTop:'-2px', paddingTop:'4px'}} className="bx bx-plus font-size-16 align-middle me-1"/>
            {i18n.t("Importar Excel")}
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
    <ModalNoSelection open={openNoSelection} setOpen={setOpenNoSelection} nombre={nombre}/>
    {openModalImportSuccess ? <SuccessImportTemplate open={openModalImportSuccess} setOpen={setOpenModalImportSuccess} templateInfo={templateInfo} /> : null}
        {openModalForExcel ? <UploadExcelModal openModal={openModalForExcel} setOpenModal={setOpenModalForExcel} setWaitModal={setOpenWaitModalForExcel} template={templateInfo}/> : null}
        {openWaitModalForExcel &&  <ModalWaitingExcel template={templateInfo} openModal={openWaitModalForExcel} setOpenModal={setOpenWaitModalForExcel} setSuccessModal={setOpenModalImportSuccess} imComingFrom={"templates"} />}
        {openImportModal ? <ImportTemplate open={openImportModal} setOpen={setOpenImportModal} templateInfo={templateInfo} setOpenSuccess={setOpenModalImportSuccess}/> : null}
    </>
  )
}

DropdownOptions.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
  uploadBC3: PropTypes.func,
}

export default withTranslation()(withRouter(DropdownOptions))