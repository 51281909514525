import React from "react"
import i18n from "i18n"


const LayoutMiBancoDePrecios = () => {
  
  return (
    <>
        <div
          style={{
            marginTop: "10px",
          }}
        >
          <div>
            <div>
                  <button
                    id="costPressFin"
                    style={{
                      backgroundColor: "#FFD52B",
                      border: "0px",
                      marginRight: "-5px",
                      zIndex: "1",
                      width:'220px'
                    }}
                  >
                    {i18n.t("Partidas")}
                  </button>
                  <button
                    id="costPress"
                    style={{
                      backgroundColor: "#EAEAEA",
                      border: "0px",
                      marginRight: "-5px",
                      width:'220px'
                    }}
                  >
                    {i18n.t("Maquinaria")}
                  </button>
                  <button
                        id="costPress"
                        style={{
                        backgroundColor: "#EAEAEA",
                        border: "0px",
                        marginRight: "-5px",
                        width:'220px'
                        }}
                  >
                    {i18n.t("Materiales")}
                  </button>
                  <button
                        id="costPress"
                        style={{
                        backgroundColor: "#EAEAEA",
                        border: "0px",
                        marginRight: "-5px",
                        width:'220px'
                        }}
                  >
                    {i18n.t("Mano de obra")}
                  </button>
      
            </div>
          </div>
        </div>

  
    </>
  )
}

export default LayoutMiBancoDePrecios
