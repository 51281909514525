import React, { Fragment, useContext, useState} from 'react';
import i18n from "i18n"
import  { 
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap"
import { HelveticaBold, HelveticaLight } from 'common/typographies';
import Budget2023Context from 'context/budget2023/Budget2023Context';
import eyeIcon from 'assets/customIcons/eye.svg'
const DropdownPreferencias = () => {
    const {preferencias, setPreferencias} = useContext(Budget2023Context)
    const [menu, setMenu] = useState(false)

    const prefs = {
      '0': {'name': 'precioCoste', 'label': 'Coste Objetivo'},
      '1': {'name': 'costReal', 'label': 'Coste Real'},
      '2': {'name': 'basededatos', 'label': 'Márgen'},
      '3': {'name': 'clientPrice', 'label': 'Precio cliente'},
      '4': {'name': 'descuento', 'label': 'Descuentos'},
      '5': {'name': 'procedencia', 'label': 'Procedencia'}
    }

    return(<Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
        style={{ marginTop:'5px', marginLeft:'5px'}}
      >
        <DropdownToggle id='settingb' style={{marginTop:'0px', color:'black', margin:'0px', padding:'0px'}}>
          <div id='hoverDropdown' className='inline' style={{marginRight:'17px', marginTop:'1px'}}>
            <img src={eyeIcon}  style={{color:'black', marginTop:'-16px', width:'16px', marginRight:'4px', fontWeight:'400'}}/>
            <HelveticaBold style={{color:'#414141', textTransform:'none', fontSize:'14px', fontWeight:'400'}}>
            {i18n.t('Mostrar')}</HelveticaBold>
          </div>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">{
          Object.entries(prefs).map(([key, {name, label}]) => {
            return (
              <DropdownItem key={key} tag="b"  style={{lineHeight: '20px', cursor:'pointer'}}
                onClick={() => {setPreferencias({...preferencias, [name]: !preferencias[name]})}}
              >{" "}
                <div className="inline">
                  <input type="checkbox" key={key} id="flexCheckDefault" className="form-check-input"
                    style={{ width: "13px", height: "13px", cursor:'pointer', zIndex:"5", marginTop:'5px'}}
                    onChange={() => {setPreferencias({...preferencias, [name]: !preferencias[name]})}}
                    checked={preferencias[name]}/>
                  <HelveticaLight style={{color:'#414141', fontWeight:'400', fontSize:'14px', paddingTop:'2px',
                      paddingLeft:'10px', marginBottom:'-10px'}}>{label}</HelveticaLight> 
                </div>
              </DropdownItem>
            )})
        }</DropdownMenu>
      </Dropdown>
    </Fragment>)
}


export default DropdownPreferencias
