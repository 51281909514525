import {Auth, API} from 'aws-amplify'
import {useState} from 'react'


const useNewSearch = () => {
    const [results, setResults] = useState([])
    const [loadingSearch, setLoading] = useState(false)
    const ENDPOINT2 = 'Planhopper-Bank-Prices'
    const getResults = async (word, reference, type) => {
        try {
            setLoading(true)
            const headers = {
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                'Access-Control-Allow-Origin': '*',
            }
            if(reference!='user-database'){
                const res = await API.get(ENDPOINT2, `/search?q=`+word+'&reference='+reference+'&type='+type, {headers: headers});
                setResults(res)
                setLoading(false)
                return res;
            }else{
                const res = await API.get(ENDPOINT2, `/search?q=`+word+'&reference='+reference, {headers: headers});
                setResults(res)
                setLoading(false)
                return res;
            }
        }
        catch (err) {
            setLoading(false)
        }
    }

    

    return {
        results,
        loadingSearch,
        getResults
    }
}
export default useNewSearch