import React,{useContext, useEffect} from 'react'
import MetaTags from 'react-meta-tags'
import { useParams } from 'react-router-dom';

//CONTEXT
import ProjectContext from 'context/projects/projectContext';

import {
    Container
} from 'reactstrap'
import ProvidersContent from 'components/Project/Providers';

const BudgetingProviders = () => {
    const {projectId} = useParams()
    const projectContext = useContext(ProjectContext)
    const {getProject, projectInfo} = projectContext
    useEffect(() => {
        
        getProject(projectId)
    } , [])

    return (
        <React.Fragment>
            <div className='page-content'>
                <MetaTags>
                    <title>Planhopper | Proyecto </title>
                </MetaTags>
                <Container fluid>
                    <ProvidersContent projectInfo={projectInfo} />
                </Container>
            </div>

        </React.Fragment>

    )
}

export default BudgetingProviders