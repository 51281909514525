import React, { useState} from "react";
import {Modal} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {withRouter} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import avatar1 from "../../assets/images/avatar/avatar1.png"
import {Auth} from "aws-amplify";
import i18n from "i18n";


function getModalStyle() {
    const top = 50 ;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: '700px',
        height: '700px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        borderRadius: 6,
        maxHeight: 'calc(100vh - 100px)',
        overflowY: 'auto',
        overflowX:'hidden',
    },
}));

const ModalImage = ({openImage, setOpenImage, user}) => {

    // Configuración del modal para añadir contactos
    const [ modalStyle ] = useState(getModalStyle);
    const classes = useStyles();
    const [image, setImage] = useState(user['custom:avatar']);

    const onClose = ()=> {
        setOpenImage(false)
    }

    const handleonClick = (e) => {
        let varImage = (e.currentTarget.src)
        
        if(varImage.charAt(0)=='o'){
            setImage(varImage.slice(1))
        }else{
            setImage((e.currentTarget.src))
        }
    }

    async function saveImage() {
        try {
            let user = await Auth.currentAuthenticatedUser();
            let result = await Auth.updateUserAttributes(user, {
                'custom:avatar': image
            });
            // SUCCESS
            location.reload()
        } catch (error) {
            console.log(error)
        }
    }

    return(
        <>
            <Modal open={openImage} onClose={onClose}>
                <div style={modalStyle} className={classes.paper}>

                    <CloseIcon id="cerrar" onClick={onClose}></CloseIcon>
                    <div id="divFotoAvatar">
                        <img id="fotoAvatarPrin2" src={image}
                             alt={avatar1}/>
                    </div>
                    <div id="centerTitleAvatar">{i18n.t("Elige el avatar que más te guste")}</div>
                    <div id ="avatares2">
                        <img id="fotoAvatar2" src='https://d1t181n48swd5d.cloudfront.net/avatares/avatar1.png'
                             alt="avatar1" onClick={handleonClick}/>
                        <img id="fotoAvatar2" src='https://d1t181n48swd5d.cloudfront.net/avatares/avatar2.png'
                             alt="avatar2" onClick={handleonClick}/>
                        <img id="fotoAvatar2" src='https://d1t181n48swd5d.cloudfront.net/avatares/avatar3.png'
                             alt="avatar3" onClick={handleonClick}/>
                        <img id="fotoAvatar2" src='https://d1t181n48swd5d.cloudfront.net/avatares/avatar4.png'
                             alt="avatar4" onClick={handleonClick}/>
                        <img id="fotoAvatar2" src='https://d1t181n48swd5d.cloudfront.net/avatares/avatar5.png'
                             alt="avatar5" onClick={handleonClick}/>
                        <img id="fotoAvatar2" src='https://d1t181n48swd5d.cloudfront.net/avatares/avatar6.png'
                             alt="avatar6" onClick={handleonClick}/>
                    </div>
                    <div id="centerButton"><button type="button" onClick={saveImage}
                                                   className="SaveFoto">{i18n.t("Guardar")}</button></div>
                </div>
            </Modal>
        </>
    )
}

ModalImage.propTypes = {
    openImage: PropTypes.bool.isRequired,
    setOpenImage: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired

}
export default withRouter(ModalImage);