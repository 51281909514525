import React, { useContext, useEffect } from 'react';
import { HelveticaBold } from 'common/typographies';
import PropTypes from 'prop-types';
import i18n from 'i18n';
import { formatValue } from 'react-currency-input-field';
import { getCurrency } from "utils/functions"
import AuthContext from 'context/auth/authContext';

const SummaryElement = ({value, label, width}) => {
    const {user} = useContext(AuthContext)
    return(
        <div style={{width:width+'%', textAlign:'right',justifyContent:'right', borderLeft:'1px solid #c4c4c4'}}>
            <div style={{display:'block'}}>
                <HelveticaBold style={{color:'#414141', fontSize:'24px', marginTop:'10px', marginRight:'10px'}}>
                {formatValue({
                    value: value.toFixed(2),
                    groupSeparator: '.',
                    decimalSeparator: ',',
                    decimalScale: 2
                }) +   getCurrency(user['custom:currency'])}
                </HelveticaBold>
                <HelveticaBold style={{color:'#414141', fontSize:'13px', marginTop:'-20px', marginRight:'10px'}}>
                        {i18n.t(label)}
                </HelveticaBold>
            </div>
        </div>
    )
}
SummaryElement.propTypes = {
    value: PropTypes.any,
    label: PropTypes.any,
    width: PropTypes.number
}

const InvoiceSummary = ({
    invoice
}) => {

    const [width, setWidth] = React.useState(48)

    useEffect(() => {
        if(invoice.recargo>0 && invoice.retention!=0 && invoice.iva>0){
            setWidth(19)
        }else if(invoice.recargo>0 && invoice.retention!=0 && invoice.iva==0){
            setWidth(24)
        }else if(invoice.recargo>0 && invoice.retention==0 && invoice.iva>0){
            setWidth(24)
        }else if(invoice.recargo>0 && invoice.retention==0 && invoice.iva==0){
            setWidth(32)
        }else if(invoice.recargo==0 && invoice.retention!=0 && invoice.iva>0){
            setWidth(24)
        }else if(invoice.recargo==0 && invoice.retention!=0 && invoice.iva==0){
            setWidth(32)
        }else if(invoice.recargo==0 && invoice.retention==0 && invoice.iva>0){
            setWidth(32)
        }else if(invoice.recargo==0 && invoice.retention==0 && invoice.iva==0){
            setWidth(48)
        }

    }, [invoice])

    return (
        <div style={{position:'fixed', width:'100%', bottom:'0px', borderTop:'1px solid #C4C4C4', height:'70px', marginLeft:'-20px', backgroundColor:'white', filter: 'drop-shadow(0px -4px 14px rgba(0, 0, 0, 0.1))'}}>
            <div className='inline' >
                <SummaryElement width={width} value={invoice.subtotal} label='Subtotal'/>
                {invoice.recargo > 0 && <SummaryElement width={width} value={invoice.recargo} label='Rec. de eq.'/>}
                {invoice.retention != 0 && <SummaryElement width={width} value={invoice.retention} label='Retención'/>}
                {invoice.iva > 0 && <SummaryElement width={width} value={invoice.iva} label='IVA'/>}
                <SummaryElement width={width} value={invoice.total} label='Total'/>
                
            </div>
        </div>
    )
};
InvoiceSummary.propTypes = {
    invoice: PropTypes.any
};
export default InvoiceSummary;