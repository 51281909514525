import React, {useState, useRef, useEffect, useContext} from "react"
import {HelveticaBold} from "common/typographies.js" 
import PropTypes from "prop-types"
import i18n from "i18n";
import { ReactComponent as MaterialIcon }  from "assets/customIcons/materiales.svg";
import { ReactComponent as ManodeobraIcon }  from "assets/customIcons/manodeobra.svg";
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import RecyclingIcon from '@mui/icons-material/Recycling';
import AutoDeleteOutlinedIcon from '@mui/icons-material/AutoDeleteOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import CurrencyInput from 'react-currency-input-field';
import { TextareaAutosize } from '@mui/base';
import styled from 'styled-components';
import TypeDropdown from "./TypeDropdown";
import { DeleteOutline } from "@mui/icons-material";
import UnityDropdown from "../UnityDropdown";
import { getCurrency } from "utils/functions"
import AuthContext from "context/auth/authContext";

const CustomInput= styled.input`
text-align: center;
width: 385.13px;
height: 35px;
border: 1px solid #C4C4C4;
box-sizing: border-box;
border-radius: 6px;
font-family: 'Helvetica';
font-style: normal;
font-weight: 300;
color: #414141;
margin-bottom: 9px;
padding-left:8px;

&::placeholder {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #B7B7B7;
}

&:focus {
    outline: none !important;
    border:1px solid #FFD52B !important;
}

&:hover {
    &::placeholder {
        color: #414141;
    }
    z-index: 10;
    border: 1px solid #414141;
}`

const CustomTextarea = styled(TextareaAutosize)`
    padding-top: 5px;
    padding-bottom: 15px;
    display: block;
    width: 100%;
    overflow: hidden;
    font-family: "Helvetica";
    font-weight: 500;
    line-height: 19.5px;
    font-size:14px;
    min-height: 25px;
    margin-top: 10px;
    color: #414141;
    border: 0px;
    resize: none;
    justify-content:'left';
    text-align:'left',
    :focus-within {
        border: 1px solid #FFD52B !important;
    }
    :hover:not(:focus-within) {
        z-index: 10;
        border: 1px solid #414141 !important;
    }
    :empty:focus::before, :empty::before {
        content: 'Escribir';
        color: lightgray;
    }
`


const Detail = ({index, details, setDetails, detail, editMode, setEditMode}) => {
    const sizeRef = useRef(null)
    const {user} = useContext(AuthContext)
    const [size, setSize] = useState({height:28})

    const handleTypeChange = (type) => {
        setDetails((prevArray) => {
            const newArray = [...prevArray];
            const updatedObject = { ...newArray[index] };
            updatedObject.type = type;
            newArray[index] = updatedObject;
            return newArray;
          });
    }

    const handleUnityChange = (unity) => {
        setDetails((prevArray) => {
            const newArray = [...prevArray];
            const updatedObject = { ...newArray[index] };
            updatedObject.unity = unity;
            newArray[index] = updatedObject;

//        if (unity == '%') {
//            // Price should be the sum of every other detail's price that is not a percentage
//            updatedObject.price = updatedObject.quantity * newArray.reduce(
//                (acc, detail) => {
//                    if (detail.unity != '%') {
//                        return acc + detail.price * detail.quantity;
//                    }
//                    return acc;
//                }, 0);
//        }

            return newArray;
          });
    }

    const handleChange = (e) => {
        setDetails((prevArray) => {
                const newArray = [...prevArray];
                const updatedObject = { ...newArray[index] };
                updatedObject[e.target.name] = e.target.value;
                if(e.target.name == 'quantity' || e.target.name == 'price'){
                    updatedObject.total = updatedObject.quantity * updatedObject.price
                }
                newArray[index] = updatedObject;
                return newArray;
            
          }
        );    
    }

    const handleDescriptionChange = (e) => {
        setDetails((prevArray) => {
            const newArray = [...prevArray];
            const updatedObject = { ...newArray[index] };
            updatedObject.description = e.target.value;
            newArray[index] = updatedObject;
            return newArray;
          });
    }

    useEffect(()=>{
        if(sizeRef.current){
            const charWidth = 8
            const charsLength = detail.description.length * charWidth
            const rows = Math.floor(charsLength/sizeRef.current.clientWidth)+1
            setSize({
                height: rows* 18,
                width: sizeRef.current.clientWidth
            })
        }
    }, [sizeRef.current, detail.description])

    const deleteDetail = (e, index) => {
        e.preventDefault();
        setDetails((prevArray) => {
            const newArray = [...prevArray];
            newArray.splice(index, 1);
            return newArray;
          });
    }
 

    return(
        <>
        {   
        !editMode ? 
            <div id='rowHoverNoColor' onDoubleClick={()=>{setEditMode(true)}} className="inline" style={{ width: '100%' }}>
                <div style={{ marginBottom:'1rem', width: '8%', textAlign: 'center', paddingRight: '15px' }}>
                    {
                        detail.type == 'coste' ?
                        <MonetizationOnOutlinedIcon style={{width:'20px', height:'20px', marginLeft:'1px', color:'#c4c4c4'}}/>
                        :
                    detail.type == "material" ?
                        <MaterialIcon style={{marginTop:'-1px'}} />
                        : detail.type == 'manodeobra' ?
                        <ManodeobraIcon style={{marginTop:'-1px'}} />
                        :
                        detail.type == 'maquinaria' ? <LocalShippingOutlinedIcon style={{width:'20px', height:'20px', marginLeft:'1px', color:'#c4c4c4'}}/> :
                        detail.type == 'residuoAdicional' ? <RecyclingIcon style={{width:'20px', height:'20px', marginLeft:'1px', color:'#C4C4C4'}}/> :
                        detail.type == 'residuoClasificacion' ? <AutoDeleteOutlinedIcon style={{width:'20px', height:'20px', marginLeft:'1px', color:'#C4C4C4'}}/> :
                        <ManodeobraIcon style={{marginTop:'-1px'}} />
                    }
                </div>
                <div style={{ width: '48%', textAlign: 'start' }}>
                    <HelveticaBold style={{ overflowWrap:'break-word', color: '#414141', fontWeight: '300', fontFamily:'Helvetica', fontSize: '14px', paddingRight: '10px' }}>{
                        detail.description
                    }</HelveticaBold>
                </div>
                <div style={{ width: '13%', textAlign: 'right' }}>
                    <HelveticaBold style={{ color: '#414141', fontWeight: '300', fontFamily:'Helvetica', fontSize: '14px', paddingRight: '10px' }}>{
                        parseFloat(detail.quantity).toFixed(2) + ' ' + detail.unity
                    }</HelveticaBold>
                </div>
                {
                    detail.unity ? <>
                    <div style={{ width: '13%', textAlign: 'right' }}>
                    <HelveticaBold style={{ color: '#414141', fontWeight: '300', fontFamily:'Helvetica', fontSize: '14px', paddingRight: '10px' }}>{
                        detail.unity == '%' ? 
                            //detail.price + ' ' + '%' : 
                            parseFloat(detail.price).toFixed(2) + ' ' + getCurrency(user['custom:currency']):
                                detail.price > 0 ? 
                                    parseFloat(detail.price).toFixed(2) + ' ' + getCurrency(user['custom:currency']) + '/' + detail.unity :
                                    parseFloat(detail.price).toFixed(2) + getCurrency(user['custom:currency'])
                    }</HelveticaBold>
                </div>
                    </>:
                    <>
                     <div style={{ width: '13%', textAlign: 'center' }}>
                    <HelveticaBold style={{ color: '#414141', fontWeight: '300', fontFamily:'Helvetica', fontSize: '14px', paddingRight: '10px' }}>{
                        detail.unity == '%'?
                            detail.price + ' ' + '?':
                            detail.price > 0 ?
                                detail.price + ' ' + getCurrency(user['custom:currency']):
                                detail.price + ' ' + getCurrency(user['custom:currency'])
                    }</HelveticaBold>
                </div>
                <div style={{ width: '13%', textAlign: 'center' }}>
                    <HelveticaBold style={{ color: '#414141', fontWeight: '300', fontFamily:'Helvetica', fontSize: '14px', paddingRight: '10px', textAlign: 'right' }}>{
                        parseFloat(detail.quantity).toFixed(2)
                    }</HelveticaBold>
                </div>
                    </>
                }
               
                <div style={{ width: '12%', textAlign: 'center' }}>
                    <HelveticaBold style={{ color: '#414141', fontWeight: '300', fontFamily:'Helvetica', fontSize: '14px', paddingRight: '10px', textAlign: 'right' }}>{
                        parseFloat(detail.total ? detail.total : detail.price * detail.quantity).toFixed(2) + ' ' + getCurrency(user['custom:currency'])
                    }</HelveticaBold>
                </div>
             
                {
            <DeleteOutline onClick={(e)=>{deleteDetail(e, index)}} id='showCheckbox' style={{fontSize:'18px', display:'none', color:'#414141', marginTop:'1px', marginLeft:'5px', cursor:'pointer'}}></DeleteOutline>
            }
            </div>
        :
        <div id='rowHoverNoColor' className="inline" style={{width:'100%', marginBottom: `-${size.height-14}px` }}>
            <div style={{width:'8%', textAlign:'end'}}>
                <TypeDropdown type={detail.type} setType={handleTypeChange} size={size} mode={'edit'} details={details} index={index}
                />
            </div>
            <div style={{width:'41%', textAlign:'start'}}>
                <CustomTextarea
                    placeholder='Escribir descripción'
                    value={detail.description}
                    style={{
                        height: `${size.height+14}px`,  
                        position:"relative",
                        border:"1px solid #C4C4C4",
                        background:"white",
                        marginBottom:"-12.5px",
                        marginTop: '-4px', 
                        width:'100%', 
                        borderRadius:'0px',
                        padding:'3px 10px', 
                        minWidth:'85px', 
                    }}
                    ref={sizeRef}
                    onChange={(e)=>{
                        handleDescriptionChange(e)
                    }}
                />
            </div>
            {
                editMode &&
            
            <div style={{width:'11%', textAlign:'end'}}>
                <div style={{paddingTop:'15px', display:'flex', alignItems:'center', width:'100%', height:`${size.height+14}px`,background:"white",
                        marginBottom:"-12.5px",
                        marginTop: '-4px', border:'1px solid #C4C4C4'}}>
                    <UnityDropdown imDesglose={true} unity={detail.unity} setUnity={handleUnityChange}/>
                </div>
            </div>
            }

            <div style={{width:'12%', textAlign:'center'}}>
                <CustomInput
                    value={detail.quantity}
                    onClick={(e)=>{e.target.select()}}
                    decimalsLimit={10}
                    onChange={(e)=>{handleChange(e)}}
                    type="number"
                    name="quantity"
                    placeholder={i18n.t('0')}
                    style={{
                        height: `${size.height+14}px`, 
                        position:"relative", 
                        minHeight: '25px', marginTop:'-4px', marginBottom:'-2px',
                        width:'100%', padding:'3px 10px', borderRadius:'0px',
                        }} 
                />
            </div>
            
            <div style={{width:'12%', textAlign:'center'}}>
                <CustomInput
                    onClick={(e)=>{e.target.select()}}
                    value={detail.price}
                    decimalsLimit={10}
                    disabled={detail.unity == '%'? true : false}
                    type="number"
                    name="price"
                    onChange={(e)=>{handleChange(e)}}
                    placeholder={i18n.t('0')}
                    style={{
                        height: `${size.height+14}px`, 
                        position:"relative", 
                        minHeight: '25px', marginTop:'-4px', marginBottom:'-2px',
                        width:'100%', padding:'3px 10px', borderRadius:'0px',
                        }} 
                />
            </div>
            <div style={{width:'11%', textAlign:'center'}}>
                <CustomInput
                    value={parseFloat(detail.total ? detail.total : detail.price * detail.quantity)}
                    decimalsLimit={10}
                    disabled
                    type="number"
                    name="total"
                    placeholder={i18n.t('0')}
                    style={{
                        height: `${size.height+14}px`, 
                        position:"relative", 
                        minHeight: '25px', marginTop:'-4px', marginBottom:'-2px',
                        width:'100%', padding:'3px 10px', borderRadius:'0px',
                        borderBottomRightRadius: details.length-1 === index ? '6px' : '0px' ,
                        borderTopRightRadius: index==0 ? '6px' : '0px'
                        }} 
                />
            </div>
            {
            <DeleteOutline onClick={(e)=>{deleteDetail(e, index)}} id='showCheckbox' style={{fontSize:'18px', display:'none', color:'#414141', marginTop:`${(size.height/2)-5}px`, marginLeft:'5px', cursor:'pointer'}}></DeleteOutline>
            }
        </div>
    }
        
        </>
    )
}
Detail.propTypes = {
    index: PropTypes.any,
    details: PropTypes.array.isRequired,
    setDetails: PropTypes.func.isRequired,
    detail: PropTypes.object.isRequired,
    editMode: PropTypes.bool.isRequired,
    setEditMode: PropTypes.func.isRequired,
}
export default Detail
