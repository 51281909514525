import { useReducer } from "react";
import { Auth, API } from 'aws-amplify';
import ContactReducer from "./contactReducer";
import ContactContext from "./contactContext";
import React from 'react'
import PropTypes from 'prop-types';
import {
    GET_CONTACT_SUCCESS
} from "../types";

const ContactState = props => {
    const initialState = {
       Contacts:[],
       loadingContacts: false
    }

    const [state, dispatch] = useReducer(ContactReducer, initialState);

    const getContacts = async data =>{
        try {
            dispatch({
                type: 'GET_CONTACTS'})
            const data = {headers :{
                'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                'Access-Control-Allow-Origin': '*',
            }}
            const res = await API.get('dev-PLANHOPPER-API', '/api/contact', data)
            dispatch({
                type: GET_CONTACT_SUCCESS,
                payload: res
            })
        } catch (error) {
            dispatch({
                //type: LOGIN_ERROR,
                payload: error
            })
        }


    }
    return (
        <ContactContext.Provider
            value={{
                Contacts: state.Contacts,
                getContacts,
                loadingContacts: state.loadingContacts

            }}
        >
            {props.children}
        </ContactContext.Provider>
    )

}

ContactState.propTypes = {
    children: PropTypes.any
}

export default ContactState