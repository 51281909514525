import DropdownActOptions from "components/Project/Overview/Actas/createAct/DropdownActOptions";
import ModalPreviewPDF from "components/Project/Overview/Actas/modals/ModalPreviewPDF";
import React, {useState} from "react";
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import PropTypes from "prop-types"

const ActRow = ({act, columns, projectId, resolveValue}) => {

    const [openModalPreview1, setOpenModalPreview1] = useState(false)


    return (
    <TableRow
        id='rowHover'
        key={act.actId}
        sx={{ '&:last-child td, &:last-child th': { border: 0 },
        cursor: 'pointer' 
        }}
        >
        {columns.map((column, index) => {
            return column.show && (
                <TableCell key={index} id='bigCellWithOverflow'
                onClick={() => setOpenModalPreview1(true)}
                >
                    {resolveValue(act, column)}
                </TableCell>

            )
        })}
        <TableCell id='bigCellWithOverflow' style={{overflow:'inherit', borderBottom:'none'}}>

            {
                <div style={{position:'absolute'}}>
                <DropdownActOptions imInTable={true} actId={act.actId} pdf={act.pdfLink} loadingPdf={false} actNum={act.actNum} asistentes={
                    act.attendees.map((asistente) => {
                        return asistente.contact
                    }
                    )
                }/>
                </div>}
        </TableCell>
        {
        openModalPreview1 && <ModalPreviewPDF open={openModalPreview1} setOpen={setOpenModalPreview1} pdf={act.pdfLink} loadingPdf={false} actNum={act.actNum}/>
        }
        </TableRow>);
}

ActRow.propTypes = {
    act: PropTypes.any,
    columns: PropTypes.any,
    projectId: PropTypes.any,
    resolveValue: PropTypes.any
}

export default ActRow;