import {Auth, API} from 'aws-amplify'
import {useState} from 'react'

const useAccepted = () => {
    const [tablaAceptados, setTablaAceptados] = useState([])
    const [loading, setLoading] = useState(false)
    const ENDPOINT2 = 'dev-PROVIDERS-API'
    const getTablaAceptados = async (projectId, groupId) => {
        try {
            setLoading(true)
            const headers = {
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                'Access-Control-Allow-Origin': '*',
            }
            const res = await API.get(ENDPOINT2, `/proposals-accepted/`+projectId+"?groupId="+groupId, {headers: headers});
            setTablaAceptados(res)
            setLoading(false)
            return res;
        }
        catch (err) {
            console.log(err);
        }
    }

    

    return {
        tablaAceptados,
        loading,
        getTablaAceptados
    }
}
export default useAccepted