import React, {useContext, useRef, useState, useEffect} from "react";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import {CircularProgress, Modal} from "@mui/material";
import { useHistory } from 'react-router-dom';
import {makeStyles} from "@material-ui/core/styles";
import ProjectContext from "context/projects/projectContext";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import i18n from "i18n";
function getModalStyle() {
    const top = 50 ;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: '444px',
        height: 'auto',
        backgroundColor: theme.palette.background.paper,
        boxShadow: "0px 3px 5px -1px rgb(0 0 0 / 7%), 0px 5px 8px 0px rgb(0 0 0 / 7%), 0px 1px 14px 0px rgb(0 0 0 / 7%)",
        padding: theme.spacing(2, 4, 3),
        borderRadius: 6,
        maxHeight: 'calc(100vh - 100px)',
        overflowY: 'auto',
        overflowX:'hidden',
    },
}));



const ModalEditMargins = ({projectInfo, open, setOpen}) => {
    const [ modalStyle ] = useState(getModalStyle);
    const [ compoundMargin, setCompoundMargin] = useState(false)
    const classes = useStyles();
    const refGI = useRef(null);
    const refGG = useRef(null);
    const refBI = useRef(null);
    const projectContext = useContext(ProjectContext)
    const {updateProjectMargins} = projectContext

    const handleClick = (e) =>{
        /*
        if(e.target.name=="gi"){
            refGI.current['id']="focusLittle"
        }
        if(e.target.name=="gg"){
            refGG.current['id']="focusLittle"
        }
        if(e.target.name=="bi"){
            refBI.current['id']="focusLittle"
        }*/
        
    }
    const [estadoPresupuesto, setEstadoPres] = useState({
        gi:'', gg:'', bi:''})
    

    const handleClose = () => {
        setOpen(false);
    }
    useEffect(() => {
        if(open==true){
        if (projectInfo.costeGeneral!=null){
            if(projectInfo.costeIndirecto!=0 || projectInfo.industrialProfit!=0){
                setCompoundMargin(true)
            }else{
                setCompoundMargin(false)
            }
            setEstadoPres({
                gi:projectInfo.costeIndirecto, gg:projectInfo.costeGeneral, bi:projectInfo.industrialProfit
            })
       }
        }

     }, [open])

     useEffect(() => {
      }, [estadoPresupuesto]);


    const handleChange = (e) => {
        if(e.target.value>=0){
        setEstadoPres({
            ...estadoPresupuesto,
            [e.target.name] : e.target.value
        })
        }else{
            setEstadoPres({
                ...estadoPresupuesto,
                [e.target.name] : e.target.value*-1
            })
        }
    }
    const history = useHistory();
    const [loading, setLoading] = useState(false)
    const guardarPres = async (e) => {
        let valida=true
        if(estadoPresupuesto.gg==''){
            setEstadoPres(prevState => ({
                ...prevState,
                gg: '0'
            }));
        }
        if(estadoPresupuesto.gi=='' &&  compoundMargin){
            setEstadoPres(prevState => ({
                ...prevState,
                gi: '0'
            }));
        }
        if(estadoPresupuesto.bi=='' && compoundMargin){
            setEstadoPres(prevState => ({
                ...prevState,
                bi: '0'
            }));
        }
        if(valida==true){
            setLoading(true)
            await updateProjectMargins(projectInfo, estadoPresupuesto)
            //history.push(`/generalBudgeting/${projectInfo.projectId}`)
            location.reload()
            setLoading(false)

        }
  
    }



    
    return (
   
   <Modal open={open} onClose={handleClose}>
            <div id="totalDiv">
                <div style={modalStyle} className={classes.paper}>
                <CloseIcon id="cerrar" onClick={()=>{setOpen(false)}}style={{marginTop:'0px', paddingLeft:'6px', marginRight:'-10px'}}></CloseIcon>
                    <div id="DivTitleEliminar" style={{marginTop:'24px', justifyContent:'center'}}>
                        <p id="centradoBudget" style={{fontSize:'24px', marginTop:'0px', marginBottom:'30px'}} >{i18n.t("Define tu margen de beneficio.")} </p>
                    </div>
                    <div style={{width:"233px", marginLeft:"auto", marginRight:"auto", marginBottom:'10px'}}>
                        <div className="marginTableRows" id="linePress">
                            <p style={{borderRadius: compoundMargin ? "5px 0px 0px 0px" : "5px 0px 0px 5px"}} className="mlGG firstp">{i18n.t("Gastos Generales")}</p>
                            <input style={{borderRight:'none'}} ref={refGG} id="inputGasto" 
                                onClick={handleClick} onChange={handleChange}
                                type="number" name="gg" value={estadoPresupuesto.gg}
                            />
                            <p style={{ borderLeft:'0px', 
                                                     backgroundColor:'white',
                                                    width:'20px', textAlign:'center', paddingLeft:'0px',
                                                    borderRadius: compoundMargin ? "0px 5px 0px 0px" : "0px 5px 5px 0px"
                                                }} 
                                            className="mlGG lastp">{i18n.t("%")}
                            </p>
                        </div>
                        {                           
                            compoundMargin ?
                                <>
                                    <div className="marginTableRows" id="linePress">
                                        <p style={{borderTop:'none', borderBottom:'none'}} className="mlGG firstp">{i18n.t("Gasto Indirecto")}</p>
                                        <input style={{borderTop:'none', borderBottom:'none',borderRight:'none', width:'39px'}} ref={refGI}
                                            id="inputGasto" onClick={handleClick} onChange={handleChange}
                                            type="number" name="gi" value={estadoPresupuesto.gi} />
                                        <p style={{borderTop:'0px', borderBottom:'0px', 
                                                    backgroundColor:'white',
                                                    width:'20px', textAlign:'center', paddingLeft:'0px'
                                                }} 
                                            className="mlGG lastp">{i18n.t("%")}
                                        </p>
                                    </div>
                                    <div className="marginTableRows" id="linePress">
                                        <p style={{borderRadius:'0px 0px 0px 5px'}} className="mlGG firstp">{i18n.t("Beneficio Industrial")}</p>
                                        <input style={{borderRight:'none'}} ref={refBI}
                                            id="inputGasto" name="bi" onClick={handleClick} onChange={handleChange}
                                            type="number" value={estadoPresupuesto.bi} />
                                        <p style={{
                                                    backgroundColor:'white',
                                                    width:'20px', textAlign:'center', paddingLeft:'0px', borderRadius:'0px 0px 5px 0px'
                                                }} 
                                            className="mlGG lastp">{i18n.t("%")}
                                        </p>
                                    </div>
                                </>
                            : null
                        }
                        {
                            !compoundMargin ?
                                <p onClick={()=>{setCompoundMargin(true)}} style={{fontSize:'10px',marginTop:'10px', marginLeft:'3%', textAlign:'inherit', cursor:'pointer'}} className="lightPoppins">
                                    <KeyboardArrowDownIcon style={{height:'14px', width:'14px'}}/>{i18n.t("Definir margen compuesto.")}
                                </p>
                            :
                                null
                        }
                        
                    </div>
                    {loading ? <CircularProgress size={24} style={{marginTop:'30px', marginLeft:'170px'}}/> : 
                    <div id="centerButton" style={{marginTop:'30px', marginBottom:'6px'}}><button 
                    style={{height:'38px', width:'103px'}}
                    type="button"
                                                   className="AddContact" 
                                                    data-amplify-analytics-on="click"
                                                    data-amplify-analytics-name="ChangeProfitMargin"
                                                    data-amplify-analytics-attrs={estadoPresupuesto}
                                                   onClick={guardarPres}>{i18n.t("Guardar")}</button></div>
                    }
                </div>
                    
            </div>
        </Modal>
    );
}

ModalEditMargins.propTypes = {
    projectInfo: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired
};

export default ModalEditMargins;
