import React, { useContext, useState } from "react"
import PropTypes from "prop-types"
import { Col, Row } from "reactstrap"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import download from "assets/customIcons/download.svg"
import CertHistoryContext from "context/certHistory/CertHistoryContext"

const CertHistoryRowHeader = ({
  title,
  showDownloadIcon,
  totalCertPrice,
  certId,
}) => {
  const [showIcon, setShowIcon] = useState(false)
  const { setOpen, setSelectedCertificationId } = useContext(CertHistoryContext).exportSideBar

  return (
    <Row xs={12} className="rowHeader" onClick={() => setShowIcon(!showIcon)}>
      <Col xs={1} className="d-flex align-items-center maxWidth35">
        {showIcon ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
      </Col>
      <Col xs={showDownloadIcon ? 7 : 11} className="d-flex align-items-center">
        {title}
      </Col>
      {showDownloadIcon && (
        <>
          <Col xs={1} className="col-1 col-lg d-flex justify-content-end">
            <img
              src={download}
              style={{ maxWidth: "16px" }}
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                setOpen(true)
                setSelectedCertificationId(certId)
              }}
            />
          </Col>
          <Col xs={3} className="col-1 col-lg d-flex justify-content-end">
            <div
              id="precioTotal"
              style={{
                fontWeight: "700",
                minWidth: "170px",
                backgroundColor: "white",
                color: "#414141",
                border: "1px solid #414141",
                fontFamily: "Helvetica",
                width: "fit-content",
                paddingLeft: "5px",
                paddingRight: "5px",
                height: "30px",
                marginTop: "5px",
              }}
            >
              Total cert. € {totalCertPrice}
            </div>
          </Col>
        </>
      )}
    </Row>
  )
}

CertHistoryRowHeader.propTypes = {
  title: PropTypes.string,
  showDownloadIcon: PropTypes.bool,
  setOpenSideBar: PropTypes.func,
  totalCertPrice: PropTypes.number,
  certId: PropTypes.string,
}

export default CertHistoryRowHeader
