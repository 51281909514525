import * as amplitude from '@amplitude/analytics-browser';
import PropTypes from 'prop-types'
import React, { useState, useContext, useEffect } from "react";
import i18n from "i18n";
import { FileUpload } from "@mui/icons-material";
import {Modal} from "@mui/material";
import {makeStyles} from "@material-ui/core/styles";
import { saveAs } from "file-saver";
import { isNil } from "lodash";
import CircularProgress from '@mui/material/CircularProgress';

import { CircularProgressWithLabel } from "components/CircularProgressWithLabel";
import { useProcess } from 'hooks/useProcess';

function getModalStyle() {
    const top = 50;
    const left = 50;
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };

}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: '400px',
        height: '380px',
        backgroundColor: 'white',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        borderRadius: 6,
        maxHeight: '550px',
        overflowY: 'auto',
        overflowX:'hidden',
        paddingLeft:'0px',
        paddingRight:'0px'
    },
}));

const ModalWaitProcess = ({
  open, setOpen,
  PK, SK
  }) => {
  
  const [ modalStyle ] = useState(getModalStyle);
  const classes = useStyles();

  const [process, setProcess, gql] = useProcess(PK, SK)
  const [progress, setProgress] = useState(0)

  const handleDownload = (url) => {
    const basename = process.payload.filename ? process.payload.filename : 'documento.pdf'
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        saveAs(blob, basename)
      })
    handleClose()
  }

  const handleClose = () => {
    setOpen(false);
    gql.unsubscribe()
  }

  useEffect(() => {
    if (process) {
      const value = process.payload.chapters_done / process.payload.chapters_total * 100;
      if (!isNil(value)) {
        setProgress(value);
      }
    }
  }, [process])

  return (<>
    <Modal open={open} onClose={() => {null}}>
      <div style={modalStyle} className={classes.paper}>
        <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
          <p style={{fontSize:'22px'}}>{}</p>
          <button style={{marginRight:'24px'}} onClick={handleClose}>X</button>
        </div>

        <div className="modal-body" style={{display:'flex', flexDirection:'column'}}>

          { /* Title */ }
          <div>
            <p style={{fontSize: '18px', fontWeight: '300',
              textAlign: 'center', fontFamily: "Helvetica", marginTop:'0px'}}>
              {
                process && process.status 
                && (<>
                  {
                    (process.status == 'PENDING' || process.status == 'PROCESSING')
                      && i18n.t("Estamos construyendo el documento.")
                    || (process.status == 'DONE'
                      && i18n.t("¡El documento está listo!"))
                    || (process.status == 'ERROR'
                      && i18n.t("Ocurrió un error al construir el documento."))
                    || null
                  }
                </>)
              }
              <br />
            </p>
          </div>

          <div style={{display:'flex', justifyContent:'center'}}>
          { (process 
              && process.status
              && process.process_type == 'generate_pdf' 
              && !isNil(progress) 
              && <CircularProgressWithLabel variant="determinate" value={progress ? progress : 0} />
              || <CircularProgress variant="indeterminate" />
          ) }
          </div>

          <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
            { 
              (process && process.payload.chapters_total) && (<>
              <br /><br />
              <p style={{fontStyle: 'normal', fontWeight: '300', fontSize: '14px',
                  lineHeight: '16px', textAlign: 'center', fontFamily: "Helvetica"}}
                >Escritos {process.payload.chapters_done ? process.payload.chapters_done : 0
                            }/{process.payload.chapters_total ? process.payload.chapters_total : 0} capítulos</p>
              <p style={{fontStyle: 'normal', fontWeight: '300', fontSize: '14px',
                  lineHeight: '16px', textAlign: 'center', fontFamily: "Helvetica"}}
              >Escritas {process.payload.parts_done ? process.payload.parts_done : 0
                        }/{process.payload.parts_total ? process.payload.parts_total : 0} partidas</p>
              <br />
              </>)
            }
          </div>

          <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
          { 
            // Action
            process && process.payload.download_url
              ? (<>
                  <button className='AddProject' style={{width: '50%'}}
                    onClick={() => {
                      amplitude.track(process.process_type);
                      handleDownload(process.payload.download_url)
            }}>{i18n.t("Descargar")}</button>
                </>)
              : null
          }
          </div>

          </div>
        </div>
      </Modal>

  {
    /*
    openErrorModal && <ModalErrorExcel open={openErrorModal} setOpen={setOpenErrorModal} errorMessage={importedExcel.errorMessage} setOpenWaiting={setOpenModal} />
    */
  }

  </>)
}

ModalWaitProcess.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  PK: PropTypes.string.isRequired,
  SK: PropTypes.string.isRequired
}

export default ModalWaitProcess
