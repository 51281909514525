import React, { useState, useEffect, useContext } from "react"
import PropTypes from 'prop-types'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
//i18n
import { withTranslation } from "react-i18next"
import { withRouter, useParams } from "react-router-dom"
import ModalPreferencias from "./ModalPreferencias"
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Budget2023Context from "context/budget2023/Budget2023Context"
import ModalNoSelection from "./ModalNoSelection"
import ModalChapterSelected from "./ModalSelected"
import ModalCapitulos from "./ModalCapitulos"
import i18n from "i18n"
import UploadModal2 from "components/Feed/uploadBC32"
import WaitingModal from "components/Feed/WaitingModal"
import SuccessModal from "components/Feed/SuccessModal"
import ModalFilterByTag from "./ModalFilterByTag"
import ModalCloseVersion from "./ModalCloseVersion"
import ModalVersiones from "./ModalVersiones"
import AuthContext from "context/auth/authContext"
import ModalConvert2Template from "../modals/modalConvert2Template"
import ModalSelectPlantilla from "pages/Dashboard/modalSelectPlantilla"
import UploadExcelModal from "components/Feed/uploadExcel"
import ModalWaitingExcel from "pages/Dashboard/ModalWaitingExcel"
import ModalCargandoRecalc from "./ModalCargandoRecalc"
import ModalEditMargins from "./ModalEditMargins"


const DropdownOptions = props => {
  
  const [menu, setMenu] = useState(false)
  const [open, setOpen] = useState(false)
  const [openSelected, setOpenSelected] = useState(false)
  const [openModalTemplate, setOpenModalTemplate] = useState(false)
  const [openCloseVersion, setOpenCloseVersion] = useState(false)
  const [openModal, setOpenModal] = useState(false)
    const handlePreferencias = () => {
        setOpen(true)
    }
  const [waitModal, setWaitModal] = useState(false)
  const [successModal, setSuccessModal] = useState(false)
  const {selectPartsGroup, selectedPartGroup, budgetTree, errorAgrupar, groupParts, getSelectedBudget2, selectedChaptersGroup, groupChapters, exportBudget2BC3} = useContext(Budget2023Context)
  const {projectId} = useParams()
  const [openFiltrar, setOpenFiltrar] = useState(false)
  const [openVersion, setOpenVersion] = useState(false)
  const [openRecalcular, setOpenRecalcular] = useState(false)
  const {user, getUser, freeTrialExpired, openFreeTrialExpire} = useContext(AuthContext)
  const [waitExcel, setWaitExcel] = useState(false)
  
  // IMPORT TEMPLATE
  const [openImportTemplate, setOpenImportTemplate] = useState(false)

  useEffect(()=>{
    if(menu){
      selectPartsGroup([], budgetTree.subcapitulos)
      getSelectedBudget2([], budgetTree.subcapitulos)
    }
  },[menu])

  useEffect(() => {
    if(!user){
      getUser()
    }
  }, [])

  useEffect(() => {
      props.setEstadoLista(selectedPartGroup)
  }, [selectedPartGroup])

  useEffect(() => {
    props.setEstadoListaCaps(selectedChaptersGroup)
}, [selectedChaptersGroup])



const [openExcel, setOpenExcel] = useState(false)

  


  return (
    <>
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
         >
        <DropdownToggle
        id='settingb'
            style={{marginTop:'-9px', color:'black', margin:'0px', padding:'0px'}}
        >
        <MoreHorizIcon id='settingsBud' style={{color:'#414141', marginTop:'4.5px'}}/>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
    
        {
                    user ? 
                        user.freeTrialExpired || user['custom:role'] === 'WORKER' ?
                            null
                        :
          props.projectInfo.status==='CLOSED' ? null :
           <>
            {props.soyFinal ? null : <>
          <DropdownItem tag="d"  style={{cursor:'pointer'}} onClick={()=>{freeTrialExpired ? openFreeTrialExpire():setOpenModal(true)}}>
            {" "}
            <i style={{marginTop:'-2px'}} className="bx bx-plus font-size-16 align-middle me-1"/>
            {i18n.t("Añadir BC3")}
          </DropdownItem>
          <DropdownItem tag="d"  style={{cursor:'pointer'}} onClick={()=>{freeTrialExpired ? openFreeTrialExpire():setOpenExcel(true)}}>
            {" "}
            <i style={{marginTop:'-2px'}} className="bx bx-plus font-size-16 align-middle me-1"/>
            {i18n.t("Añadir Excel")}
          </DropdownItem>
          <DropdownItem tag="d"  style={{cursor:'pointer'}} onClick={()=>{freeTrialExpired ? openFreeTrialExpire():setOpenRecalcular(true)}}>
            {" "}
            <i style={{marginTop:'-2px'}} className="bx bx-rotate-right font-size-16 align-middle me-1"/>
            {i18n.t("Recalcular presupuesto")}
          </DropdownItem>
          {/* <DropdownItem tag="d"  style={{cursor:'pointer'}} onClick={()=>{freeTrialExpired ? openFreeTrialExpire():setOpenImportTemplate(true)}}>
            {" "}
            <i style={{marginTop:'-2px'}} className="bx bx-plus font-size-16 align-middle me-1"/>
            {i18n.t("Importar plantilla")}
          </DropdownItem> */}
              </>}
          <DropdownItem tag="d"  style={{cursor:'pointer'}} onClick={async()=>{freeTrialExpired ? openFreeTrialExpire():await exportBudget2BC3(projectId, props.projectInfo.name)}}>
            {" "}
            <i style={{marginTop:'-2px'}} className="bx bx-export font-size-16 align-middle me-1"/>
            {i18n.t("Exportar a BC3")}
          </DropdownItem>


          {/* {props.soyFinal ? null :  
           <DropdownItem tag="e" onClick={()=> {freeTrialExpired ? openFreeTrialExpire():props.setOpen2(true)}} style={{cursor:'pointer'}}>
            {" "}
            <i style={{marginTop:'-2px'}} className="bx bx-edit-alt font-size-16 align-middle me-1"/> 
           {i18n.t("Modificar márgenes")}
          </DropdownItem>  } */}

          </>
        :null  
        }
         

          {/* <DropdownItem tag="d" onClick={handlePreferencias} style={{cursor:'pointer'}}>
            {" "}
            <i style={{marginTop:'-2px'}} className="bx bx-low-vision font-size-16 align-middle me-1"/>
            {i18n.t("Visibilidad")}
          </DropdownItem> */}
          {/* <DropdownItem tag="d"    onClick={()=>{freeTrialExpired ? openFreeTrialExpire():props.setOpenFiltrar(true)}} style={{cursor:'pointer'}}>
            {" "}
            <i style={{marginTop:'-2px'}} className="bx bx-filter font-size-18 align-middle me-1"/>
            {i18n.t("Filtrar")}
          </DropdownItem> */}
       

          {
            /*
                    user ? 
                        user.freeTrialExpired || user['custom:role'] === 'WORKER' ?
                            null
                        :
          props.projectInfo.status==='CLOSED' ? null : <>
          <DropdownItem onClick={()=>{freeTrialExpired ? openFreeTrialExpire():setOpenCloseVersion(true)}} tag="g" style={{cursor:'pointer'}}>
            {" "}
            <i style={{marginTop:'-2px', paddingTop:'4px'}} className='bx bx-right-arrow-circle font-size-16 align-middle me-1' ></i>
           {i18n.t("Cerrar Versión")}
         
          </DropdownItem>
       
          </>
        : null  
        */
        }
        
        {/* 
       
        <DropdownItem tag="f" style={{cursor:'pointer'}} onClick={()=>{setOpenVersion(true)}}>
            {" "}
            <i style={{marginTop:'-4px', paddingTop:'4px'}} className="bx bx-droplet font-size-16 align-middle me-1"/>
            {i18n.t("Ver versiones")}
          </DropdownItem>
          */}
     {
      /*
 <DropdownItem tag="f" style={{cursor:'pointer'}} onClick={()=>{setOpenModalTemplate(true)}}>
            {" "}
            <i style={{marginTop:'-4px', paddingTop:'4px'}} className="bx bx-file font-size-16 align-middle me-1"/>
            {i18n.t("Convertir en plantilla")}
          </DropdownItem>
      */
     }
         
     
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>

    <ModalEditMargins  projectInfo={props.projectInfo} open={props.open2} setOpen={props.setOpen2} soyPrimeraVez={false}/>
    <ModalPreferencias open={open} setOpen={setOpen}  preferencias={props.preferencias} setPreferencias={props.setPreferencias} soyFinal={props.soyFinal}></ModalPreferencias>
    <ModalNoSelection open ={props.openAgrupar} setOpen={props.setOpenAgrupar} nombre={props.name}/>
    <ModalChapterSelected open ={openSelected} setOpen={setOpenSelected} />
    <ModalCapitulos open ={props.openCaps} setOpen={props.setOpenCaps} projectId={projectId} />
    <UploadModal2 openModal={openModal} 
                  setOpenModal={setOpenModal} 
                  setWaitModal={setWaitModal}
                  proyecto={props.projectInfo} />
    <UploadExcelModal openModal={openExcel} setOpenModal={setOpenExcel} proyecto={props.projectInfo} setWaitModal={setWaitExcel}/>    

    {waitModal ?
            <WaitingModal openModal={waitModal} setOpenModal={setWaitModal} setSuccessModal={setSuccessModal} project={props.projectInfo}/> : null}
    <SuccessModal setSuccessModal={setSuccessModal} successModal={successModal} project={props.projectInfo}/>

    {waitExcel && 
    <ModalWaitingExcel project={props.projectInfo} openModal={waitExcel} setOpenModal={setWaitExcel} setSuccessModal={setSuccessModal} imComingFrom={"budgeting"} />
    }

    <ModalFilterByTag open={openFiltrar} setOpen={setOpenFiltrar} />
    <ModalCloseVersion open={openCloseVersion} setOpen={setOpenCloseVersion} />
    <ModalVersiones open={openVersion} setOpen={setOpenVersion} />
    <ModalConvert2Template open={openModalTemplate} setOpen={setOpenModalTemplate}/>
    {openImportTemplate ? <ModalSelectPlantilla open={openImportTemplate} setOpen={setOpenImportTemplate} imInBudget={true} /> : null}
    {
              openRecalcular && <ModalCargandoRecalc open={openRecalcular} setOpen={setOpenRecalcular} />
            }
     </>
  )
}

DropdownOptions.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
  preferencias: PropTypes.object.isRequired,
  setPreferencias: PropTypes.func.isRequired,
  open2: PropTypes.bool.isRequired,
  setOpen2: PropTypes.func.isRequired,
  projectInfo: PropTypes.object.isRequired,
  soyFinal: PropTypes.bool,
  contactos : PropTypes.any.isRequired,
  handleSendBudgetModal: PropTypes.func.isRequired,
  handleDownloadBudgetModal: PropTypes.func.isRequired,
  agruparPartidas:PropTypes.func.isRequired,
  agruparCapitulos:PropTypes.func.isRequired,
  setEstadoLista:PropTypes.func.isRequired,
  setEstadoListaCaps:PropTypes.func.isRequired,
  openAgrupar:PropTypes.bool.isRequired,
  setOpenAgrupar:PropTypes.func.isRequired,
  name:PropTypes.string.isRequired,
  openCaps:PropTypes.bool.isRequired,
  setOpenCaps:PropTypes.func.isRequired,
  setOpenFiltrar:PropTypes.func.isRequired,
}

export default withTranslation()(withRouter(DropdownOptions))