import {Modal} from "@mui/material";
import {makeStyles} from "@material-ui/core/styles";
import React, { useEffect, useState, useContext} from "react";
import PropTypes from 'prop-types'
import i18n from "i18n";
import SuccessModal from "./SuccessModal";
import AuthContext from "context/auth/authContext";
import CloseBud from 'assets/images/budgeting/CloseBud.png'
import Budget2023Context from "context/budget2023/Budget2023Context";

function getModalStyle() {
    const top = 50 ;
    const left = 50;
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };

}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: '500px',
        height: '425px',
        backgroundColor: 'white',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        borderRadius: 6,
        maxHeight: '500px',
        overflowY: 'auto',
        overflowX:'hidden',
        paddingLeft:'0px',
        paddingRight:'0px'
    },
}));

const ModalWaitingPendingActions = ({open, setOpen}) =>{
  const [ modalStyle ] = useState(getModalStyle);
  const classes = useStyles();
  const {pendingActions, setPendingActions, user} = useContext(AuthContext)
  const [status, setStatus] = useState(0)
  const {urlDownload} = useContext(Budget2023Context)
  const {loadingSend, sendOk} = useContext(Budget2023Context)

  useEffect(() => {
    if(urlDownload == 'done' || (sendOk == true && loadingSend == false)){
      setStatus(1)
    }
  }, [urlDownload, loadingSend, sendOk])

return(
    <>
  <Modal
      open={open}
      onClose={() => {null}}
  >
      <div style={modalStyle} className={classes.paper}>
      <div className="modal-body">
      <div>
        {status == 0 ?
          <div>
       
              <p
                  style={{
                  fontSize: '20px',
                  fontWeight: '500',
                  textAlign: 'center',
                  fontFamily: "Helvetica",
                  marginTop:'30px'
                  }}
              
                  >Se esta {pendingActions.type == 'downloadBudget' ? 'descargando ' : 'enviando '} tu presupuesto
                </p>
                <p
                  style={{
                      fontStyle: 'normal',
                      fontWeight: '300',
                      fontSize: '14px',
                      lineHeight: '16px',
                      textAlign: 'center',
                      fontFamily: "Helvetica"
                  }}
              >Esto puede tomar un minuto. Por favor no te
                <br/>  vayas, ni cierres esta pestaña
              
              </p>
              <div className='loader'>
                    <div className='ball'></div>
                    <div className='ball'></div>
                    <div className='ball'></div>
               </div>
             
          </div>
          :
            <div>
         <div style={{justifyContent:'center', display:'flex', marginTop:'65px'}}>
                <img src={CloseBud} style={{width:'117px', height:'117px'}}/>
                </div>
            
                <div id="DivTitleEliminar" style={{marginTop:'15px', width:'100%' , justifyContent:'center', display:'flex'}}>
                    <p id="centradoBudget">
                    Tu presupuesto se ha {pendingActions.type == 'downloadBudget' ? 'descargado ' : 'enviado '} correctamente
                    </p>
                </div>
            
                <div id="centerButton" style={{marginTop:'15px'}}><button type="button"
                        className="AddContact" onClick={async()=>{await setPendingActions({pendingActions: {}, email: user.email})}}>{i18n.t("Cerrar")}</button></div>

                </div>
            }
        </div>
      </div>
      </div>
  </Modal>
  </>
)
}
ModalWaitingPendingActions.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired
}
export default ModalWaitingPendingActions