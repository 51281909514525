import PropTypes from "prop-types"

class CMeasure {
  constructor({ depth, description, height, quantity, width }) {
    this.depth = depth
    this.description = description
    this.height = height
    this.quantity = quantity
    this.width = width
  }
}

export default CMeasure

const MeasurePropTypes = PropTypes.shape({
  depth: PropTypes.number,
  description: PropTypes.string,
  height: PropTypes.number,
  quantity: PropTypes.number,
  width: PropTypes.number,
})

export { MeasurePropTypes }
