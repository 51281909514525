import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"
import "./i18n"
import "perfect-scrollbar/css/perfect-scrollbar.css"
import { Hub } from "aws-amplify"
import "bootstrap/dist/css/bootstrap.css"
import "assets/scss/paper-dashboard.scss?v=1.3.0"
import "assets/demo/demo.css"
import "assets/css/stylesForMui.css"
import "assets/css/Projects.css"
import "assets/css/TypeForm.css"
import "assets/css/Login.css"
import "assets/css/Budget.css"
import "assets/css/Contacts.css"
import "assets/css/NewBudgeting.css"
import "assets/css/CertHistory.css"
import "assets/css/TreeViewModal.css"
import { isLocalhost, COGNITO_SETTINGS, APPSYNC_SETTINGS, API_URL, CORE_URL } from "utils/functions"

import Amplify, { Analytics, Auth} from "aws-amplify"
Analytics.autoTrack('session', {
  enable: true,
  attributes: async () => {
    const user = await Auth.currentAuthenticatedUser();
    return user.attributes;
  },
  provider: 'AWSPinpoint'
});

Analytics.autoTrack('pageView', {
  
  enable: true,
  eventName: 'pageView',
  type: 'SPA',
  getUrl: () => {
    return window.location.origin + window.location.pathname;
  },
  provider: 'AWSPinpoint',
  attributes: async () => {
    const user = await Auth.currentAuthenticatedUser();
    return user.attributes;
  }
});

Analytics.autoTrack('event', {
  enable: true,
  events: ['click'],
  selectorPrefix: 'data-amplify-analytics-',
  provider: 'AWSPinpoint',
  attributes: async () => {
    const user = await Auth.currentAuthenticatedUser();
    return user.attributes;
  }
});
export const updateEndpoint = async (data) => {
  if(data === null || data === undefined){
    data = await Auth.currentAuthenticatedUser()
  }
  try{
  const userAttributes = Object.keys(data.attributes).reduce((acc, key) => {
    acc[key] = [data.attributes[key]]
    return acc}, {})
  const endpoint = {
    address:  data.attributes.email,
    channelType: 'APNS', // The channel type. Valid values: APNS, GCM
    demographic: {// The version of the application associated with the endpoint.
      locale: navigator.language, // The endpoint locale in the following format: The ISO 639-1 alpha-2 code, followed by an underscore, followed by an ISO 3166-1 alpha-2 value
      platform: navigator.platform,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone// The timezone of the endpoint. Specified as a tz database value, such as Americas/Los_Angeles.
    },
    optOut: 'ALL',
    userId: data.attributes.sub,
    userAttributes: userAttributes
  }

  await Analytics.updateEndpoint(endpoint).then(() => {
    console.log('Endpoint updated')

  }).catch((e) => {
    console.log('Error updating endpoint', e)
    
  });
  } 
  catch(e){

    console.log('Error updating endpoint', e)
  }
}
//window.LOG_LEVEL = "DEBUG"


const userAttributes = localStorage.getItem('userAuth')!='undefined' ? JSON.parse(localStorage.getItem('userAuth')) : null
const analyticsConfig = {
  AWSPinpoint: {
        appId:  isLocalhost ? '59e0f74e31d44e5797228281cd7d2932' :'c1fd905961cd49f18a974daf844c4b45',
        region: 'eu-west-1',
        mandatorySignIn: true,
        endpoint : {
          address:  userAttributes ? userAttributes.email : null,
          channelType: 'APNS', // The channel type. Valid values: APNS, GCM
          demographic: {// The version of the application associated with the endpoint.
            locale: navigator.language, // The endpoint locale in the following format: The ISO 639-1 alpha-2 code, followed by an underscore, followed by an ISO 3166-1 alpha-2 value
            platform: navigator.platform,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone// The timezone of the endpoint. Specified as a tz database value, such as Americas/Los_Angeles.
          },
          optOut: 'ALL',
          userId: userAttributes ? userAttributes.sub : null,
          userAttributes:  userAttributes ? Object.keys(userAttributes).reduce((acc, key) => {
            acc[key] = [userAttributes[key]]
            return acc}, {}) : {}
        }
  }
}

Amplify.configure({
  Auth: {
    identityPoolId: COGNITO_SETTINGS.identityPoolId,
    customRoleArn: COGNITO_SETTINGS.customRoleArn,
    region: "eu-west-1",
    userPoolId: COGNITO_SETTINGS.poolId,
    userPoolWebClientId: COGNITO_SETTINGS.webClientId,
    mandatorySignIn: true,
    oauth: {
      domain: "tribboo.auth.eu-west-1.amazoncognito.com",
      scope: ["email", "openid", "profile", "aws.cognito.signin.user.admin"],
      redirectSignIn: "https://app.planhopper.com/dashboard",
      redirectSignOut: "https://app.planhopper.com/login",
      responseType: "code",
    },
  },
  aws_appsync_graphqlEndpoint: APPSYNC_SETTINGS.endpoint,
  aws_appsync_region: "eu-west-1",
  aws_appsync_authenticationType: "API_KEY",
  aws_appsync_apiKey: APPSYNC_SETTINGS.apiKey,
  API: {
    endpoints: [
      {
        name: "CORE",
        endpoint: CORE_URL,
      },
      {
        name: "dev-PLANHOPPER-API",  // TODO: Replace with service-legacy
        endpoint: API_URL + "/legacy",
      },
      {
        name: "dev-Tribboo-Search",
        endpoint: "https://s4mxbp71kf.execute-api.eu-west-1.amazonaws.com/dev",
      },
      {
        name: "prod-Planhopper-Search",
        endpoint: "https://x84fwcob15.execute-api.eu-west-1.amazonaws.com/prod",
      },
      {
        name: "prod-Planhopper-HubSpot-Integration",
        endpoint: "https://cwuy0o8mvi.execute-api.eu-west-1.amazonaws.com/prod",
      },
      {
        name: "service-planning",
        endpoint: API_URL + "/planning",
      },
      {
        name: "service-budget",
        endpoint: API_URL + "/budget",
      },
      {
        name: "service-part-core",
        endpoint: CORE_URL + "/partida",
      },
      {
        name: "service-cert-core",
        endpoint: CORE_URL + "/cert",
      },
      {
        name: "service-core-base",
	endpoint: CORE_URL,
      },
      {
        name: "service-cert-core",
        endpoint: CORE_URL + "/cert"
      },
      {
        name: "service-budgeting-temp",
        endpoint: API_URL + "/budget-temp",
      },
      {
        name: "service-projects",
        endpoint: API_URL + "/project",
      },
      {
        name: 'dev-PROVIDERS-API',
        endpoint: API_URL + "/providers",
      },
      {
        name: 'Planhopper-Bank-Prices',
        endpoint: API_URL + "/bank-prices",
      },
      {
        name: 'service-templates',
        endpoint: API_URL + "/template",
      },
      {
        name: 'invoiceAPI',
        endpoint: API_URL + "/invoice",
      },
      {
        name: 'service-acts',
        endpoint: API_URL + "/acts",  // Or should it be acts/api/acts/ ?
      },
      {
        name: 'service-integration',
        endpoint: API_URL + "/integration",
      },
      {
        name : 'service-account',
        endpoint: API_URL + "/account"
      },{
        name : 'service-invoice-local',
        endpoint: API_URL + "/invoice"
      }
    ],
  },
  aws_user_files_s3_bucket:  isLocalhost ? "tribboo-app-dev": "tribboo-app-prod",
  aws_user_files_s3_bucket_region: "eu-west-1"
})

Hub.listen("auth", async data => {
  switch (data.payload.event) {
    case "signIn":
      await updateEndpoint(data.payload.data)
    case "signUp":
      console.log("user signed up")
      break
    case "signOut":
      console.log("user signed out")
      break
    case "signIn_failure":
      console.log("user sign in failed")
      break
    case "configured":
      console.log("the Auth module is configured")
  }
})


const app = (
    <BrowserRouter>
      <App />
    </BrowserRouter>
)

ReactDOM.render(app, document.getElementById("root"))
serviceWorker.unregister()
Analytics.configure(analyticsConfig)


