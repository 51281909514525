import React, { useState, useRef, useEffect, useContext } from "react"
import PropTypes from "prop-types"
import Autocomplete from "@mui/material/Autocomplete"
import TextField from "@mui/material/TextField"
import CloseIcon from "@mui/icons-material/Close"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import CheckCircleOutlineSharpIcon from "@mui/icons-material/CheckCircleOutlineSharp"
import ErrorOutlineSharpIcon from "@mui/icons-material/ErrorOutlineSharp"
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"
import { Titulos } from "../../common/containers"
import ProjectContext from "../../context/projects/projectContext"
import ModalImageProject from "./ModalImageProject"
import AddContact from "../../components/Contacts/ContactTable/addContact"
import { defaultsDeep } from "lodash"
import ContactContext from "context/contact/contactContext"
import AuthContext from "context/auth/authContext"
import { CircularProgress } from "@mui/material"

const ModalEditFields = ({
  campos,
  contactos,
  guardarCampos,
  projectInfo,
  setIsEdit,
  image,
  setImage,
  selectedContact,
  setSelectedContact,
  imFromDropdown
}) => {
  const { getProject, updateProject } = useContext(ProjectContext)
  const { Contacts, getContacts } = useContext(ContactContext)
  const [input, setInput] = useState("")
  const [focused, setFocused] = React.useState(false)
  const [openAdd, setOpenAdd] = useState(false)
  const [tags, setTags] = useState(projectInfo.tags)
  const [tagsFinal, setTagsFinal] = useState(projectInfo.tags)
  const [color, setColor] = useState(0)
  const [isKeyReleased, setIsKeyReleased] = useState(false)
  const [openImage, setOpenImage] = useState(false)

  const refNombre = useRef(null)
  const refNombreConfirm = useRef(null)
  const refNombreError = useRef(null)
  const refMovil = useRef(null)
  const refMovilConfirm = useRef(null)
  const refMovilError = useRef(null)
  const refDir = useRef(null)
  const refDirConfirm = useRef(null)
  const refDirError = useRef(null)
  const refEmpresa = useRef(null)
  const refEmpresaConfirm = useRef(null)
  const refEmpresaError = useRef(null)
  const refTags = useRef(null)
  const refBoton = useRef(null)
  const refEtiquetaConfirm = useRef(null)
  const refDni = useRef(null)
  const refDniConfirm = useRef(null)
  const refDniError = useRef(null)

  let validaNombre = true
  let validaMovil = true
  let valida = false
  let validaEmpresa = true
  let validaDir = true

  const {user, freeTrialExpired, openFreeTrialExpire} = useContext(AuthContext)

  const handleOpenImage = e => {
    e.preventDefault()
    setOpenImage(true)
  }

  const onFocus = e => {
    setFocused(true)

    if (e.target.name == "name") {
      refNombre.current["id"] = "focus"
      refNombreConfirm.current.id = "confirmNotVisible"
      refNombreError.current.id = "errorNotVisible"
    } else if (e.target.name == "identifier_number") {
      refMovil.current["id"] = "focus"
      refMovilConfirm.current.id = "confirmNotVisible"
      refMovilError.current.id = "errorNotVisible"
    } else if (e.target.name == "description") {
      refEmpresa.current["id"] = "focus"
      refEmpresaConfirm.current.id = "confirmNotVisible"
    } else if (e.target.name == "orgAddress") {
      refDir.current["id"] = "focus"
      refDirConfirm.current.id = "confirmNotVisible"
    } else if (e.target.name == "tags") {
      refTags.current["id"] = "focus"
      refEtiquetaConfirm.current.id = "confirmNotVisible"
    }
  }

  const onBlur = e => {
    setFocused(false)

    if (e.target.name == "name") {
      if (campos.name.length > 0) {
        validaNombre = true
        refNombre.current.id = " "
        refNombreConfirm.current.id = "confirmVisibleNombre"
        refNombreError.current.id = "errorNotVisible"
      } else {
        validaNombre = false
        refNombre.current.id = "errorValidation"
        refNombreConfirm.current.id = "confirmNotVisible"
        refNombreError.current.id = "errorVisibleNombre"
      }
    } else if (e.target.name == "identifier_number") {
      if (campos.identifier_number.length > 0) {
        validaMovil = true
        refMovil.current.id = " "
        refMovilError.current.id = "errorNotVisible"
        refMovilConfirm.current.id = "confirmVisibleId"
      } else {
        validaMovil = false
        refMovil.current.id = "errorValidation"
        refMovilError.current.id = "errorVisibleId"
        refMovilConfirm.current.id = "confirmNotVisible"
      }
    } else if (e.target.name == "description") {
      refEmpresaConfirm.current.id = "confirmVisible"
      refEmpresa.current.id = " "
      refBoton.current["className"] = "AddContact"
    } else if (e.target.name == "orgAddress") {
      refDirConfirm.current.id = "confirmVisible"
      refDir.current.id = " "
      refBoton.current["className"] = "AddContact"
    } else if (e.target.name == "tags") {
      refEtiquetaConfirm.current.id = "confirmVisible"
      refTags.current.id = " "
      refBoton.current["className"] = "AddContact"
    }
  }

  const handleEnter = event => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form
      const index = [...form].indexOf(event.target)
      form.elements[index + 1].focus()
      event.preventDefault()
    }
  }

  const handleOnClick = e => {
    // if (e.target.name == "name") {
    //   refNombre.current["id"] = "focus"
    //   refNombreConfirm.current.id = "confirmNotVisible"
    //   refNombreError.current.id = "errorNotVisible"
    // } else if (e.target.name == "identifier_number") {
    //   refMovil.current["id"] = "focus"
    //   refMovilConfirm.current.id = "confirmNotVisible"
    //   refMovilError.current.id = "errorNotVisible"
    // } else if (e.target.name == "description") {
    //   refEmpresa.current["id"] = "focus"
    //   refEmpresaConfirm.current.id = "confirmNotVisible"
    // } else if (e.target.name == "orgAddress") {
    //   refDir.current["id"] = "focus"
    //   refDirConfirm.current.id = "confirmNotVisible"
    // } else if (e.target.name == "tags") {
    //   refTags.current["id"] = "focus"
    // }
  }

  const obtenerCampos = e => {
    guardarCampos({
      ...campos,
      [e.target.name]: e.target.value,
    })
  }
  const onClose = () => {
    setIsEdit(false)
  }

  const handleOpenAdd = e => {
    e.preventDefault()
    setOpenAdd(true)
  }

  const onKeyDown = e => {
    const { key } = e
    const trimmedInput = input.trim()

    if (
      key === "Enter" &&
      trimmedInput.length &&
      !tags.includes(trimmedInput)
    ) {
      e.preventDefault()
      setTags(prevState => [...prevState, trimmedInput])
      setTagsFinal(prevState => [...prevState, trimmedInput])
      setInput("")
      if (color != 7) {
        setColor(color + 1)
      } else {
        setColor(0)
      }
    }

    // if (key === "Backspace" && !input.length && tags.length && isKeyReleased) {
    //   const tagsCopy = [...tags]
    //   const poppedTag = tagsCopy.pop()
    //   e.preventDefault()
    //   setTags(tagsCopy)
    //   setInput(poppedTag[0])
    // }

    // setIsKeyReleased(false)
  }

  const onBlurTags = e => {
    const { key } = e
    const trimmedInput = input.trim()
    if (
      trimmedInput.length &&
      !tags.includes(trimmedInput)
    ) {
      e.preventDefault()
      setTags(prevState => [...prevState, trimmedInput])
      setTagsFinal(prevState => [...prevState, trimmedInput])
      setInput("")
      if (color != 7) {
        setColor(color + 1)
      } else {
        setColor(0)
      }
    }
  }

const [hideTags, setHideTags] = useState(false)
  useEffect(() => {
    if (tags.length != 0) {
      if (tags.length == 2) {
        setHideTags(true)
        refTags.current.children[3].disabled = true
        refTags.current.children[3].style.backgroundColor = "white"
      } else {
        setHideTags(false)
        refTags.current.children[2].disabled = false
      }
    }
  }, [tags])
  const onKeyUp = () => {
    setIsKeyReleased(true)
  }

  const onEtiqueta = e => {
    const { value } = e.target
    setInput(value)
  }
  
  const [loading, setLoading] = useState(false)

  const onSubmit = async e => {
    e.preventDefault()
    setLoading(true)

    if (refBoton.current["className"] === "AddContact") {
      campos.tags = tagsFinal
      campos.imagen = image

      if (!selectedContact) {
        campos.client = " "
      } else {
        campos.client = selectedContact.id
      }

      console.log(campos)

      await updateProject({...campos, discount_notes: campos.nota, descuento: parseInt(campos.descuento*100), igic: parseInt(campos.igic*100)})

      await getProject(projectInfo.projectId)
      setLoading(false)
      onClose()
      if(imFromDropdown){
        //window.location.reload()
      }else{
        setIsEdit(false)
      }
 
    }
  }

  const deleteTag = (e, index) => {
    e.preventDefault()

    let copiaTag = []
    let copiaTagFinal = []

    for (let i = 0; i < tags.length; i++) {
      if (i !== index) {
        copiaTag.push(tags[i])
        copiaTagFinal.push(tagsFinal[i])
      }
    }

    setTags(copiaTag)
    setTagsFinal(copiaTagFinal)

    refBoton.current["className"] = "AddContact"
  }

  useEffect(() => {
    if (campos.name && campos.identifier_number) {
      if (refBoton.current) {
        refBoton.current["className"] = "AddContact"
      }
    } 
    
  }, [campos.name, campos.identifier_number])

  useEffect(() => {
    if (selectedContact != " ") {
      getContacts()
    }
  }, [])

  useEffect(() => {
    if (Contacts) {
      for (let i = 0; i < Contacts.length; i++) {
        if (selectedContact == Contacts[i].completeName) {
          setSelectedContact(Contacts[i])
        }
      }
    }
  }, [])

  return (
    <form onSubmit={(e) =>{onSubmit(e)}}>
    
      <CloseIcon id="cerrar" onClick={onClose}></CloseIcon>
      <div id="UserImage" style={{ paddingRight: "13%", overflow: "visible" }}>
        <div id="UserImage" style={{ paddingLeft: "38.898px" }}>
          <img
            id="ProfileImage"
            onClick={handleOpenImage}
            className="rounded-circle"
            src={image}
            alt="Header Avatar"
            style={{
              filter: "drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.08))",
            }}
          />

          <div id="foto" style={{ marginTop: "25%", marginLeft: "25%" }}>
            <EditOutlinedIcon
              onClick={handleOpenImage}
              style={{
                background: " #FFFFFF",
                borderRadius: "50%",
              }}
            ></EditOutlinedIcon>
          </div>
        </div>

        <form id="Form" style={{ marginTop: "30%", width: "100rem" }}>
          <div style={{ marginBottom: "0.5rem" }}>
            <Titulos>Nombre</Titulos>
            <div>
              <input
                onFocus={onFocus}
                onBlur={onBlur}
                onKeyDown={handleEnter}
                ref={refNombre}
                onClick={handleOnClick}
                placeholder="Nombre del proyecto"
                name="name"
                value={campos.name}
                onChange={obtenerCampos}
              ></input>
              <CheckCircleOutlineSharpIcon
                style={{
                  width: "16px",
                  color: "#3BA452",
                  marginTop: "-8%",
                  marginLeft: "80%",
                }}
                ref={refNombreConfirm}
                id="confirmNotVisible"
              ></CheckCircleOutlineSharpIcon>
              <ErrorOutlineSharpIcon
                style={{
                  width: "16px",
                  color: "#BF2929",
                  marginTop: "-8%",
                  marginLeft: "80%",
                }}
                ref={refNombreError}
                id="errorNotVisible"
              ></ErrorOutlineSharpIcon>
            </div>
          </div>

          <div style={{ marginBottom: "0.5rem" }}>
            <Titulos>Número de Identificación</Titulos>
            <div>
              <input
                onFocus={onFocus}
                onBlur={onBlur}
                onKeyDown={handleEnter}
                ref={refMovil}
                onClick={handleOnClick}
                placeholder="Número "
                name="identifier_number"
                value={campos.identifier_number}
                onChange={obtenerCampos}
              ></input>
              <CheckCircleOutlineSharpIcon
                style={{
                  width: "16px",
                  color: "#3BA452",
                  marginTop: "-8%",
                  marginLeft: "80%",
                }}
                ref={refMovilConfirm}
                id="confirmNotVisible"
              ></CheckCircleOutlineSharpIcon>
              <ErrorOutlineSharpIcon
                style={{
                  width: "16px",
                  color: "#BF2929",
                  marginTop: "-8%",
                  marginLeft: "80%",
                }}
                ref={refMovilError}
                id="errorNotVisible"
              ></ErrorOutlineSharpIcon>
            </div>
          </div>

          

          <div style={{ marginBottom: "0.5rem" }}>
            <Titulos>Cliente Asociado</Titulos>
            {selectedContact == " " ? (
              <div>
                <Autocomplete
                  {...defaultsDeep}
                  className="FieldAuto"
                  options={contactos}
                  onKeyDown={handleEnter}
                  renderInput={params => (
                    <TextField {...params} label="" variant="outlined" />
                  )}
                  getOptionLabel={option => option.completeName}
                  onChange={(_event, Seleccionado) => {
                    setSelectedContact(Seleccionado)
                  }}
                />
                <AddCircleOutlineIcon
                  style={{
                    color: "#414141",
                    position: "absolute",
                    display: "flex",
                    float: "right",
                    marginLeft: "65%",
                    marginTop: imFromDropdown ? '-9%' : "-9%",
                    cursor: "pointer",
                    top: !imFromDropdown ? "53%" : "55%",
                  }}
                  onClick={handleOpenAdd}
                ></AddCircleOutlineIcon>
              </div>
            ) : (
              <div style={{position:'relative'}}>
                <Autocomplete
                  {...defaultsDeep}
                  className="FieldAuto"
                  options={contactos}
                  onKeyDown={handleEnter}
                  renderInput={params => (
                    <TextField {...params} label="" variant="outlined" />
                  )}
                  getOptionLabel={option => option.completeName}
                  defaultValue={selectedContact}
                  onChange={(_event, Seleccionado) => {
                    setSelectedContact(Seleccionado)
                  }}
                />
                <AddCircleOutlineIcon
                  style={{
                    color: "#414141",
                    position: "absolute",
                    display: "flex",
                    float: "right",
                    cursor: "pointer",
                    top: '8px',
                    right: '35px'
                  }}
                  onClick={handleOpenAdd}
                ></AddCircleOutlineIcon>
              </div>
            )}
          </div>

          <div style={{ marginBottom: "0.5rem" }}>
            <Titulos>IVA %</Titulos>
            <div>
              <input
                onFocus={onFocus}
                onBlur={onBlur}
                onKeyDown={handleEnter}
                onClick={handleOnClick}
                placeholder="Introduce el iva"
                name="iva"
                value={campos.iva}
                onChange={obtenerCampos}
              ></input>
            
            </div>
          </div>
          <div style={{ marginBottom: "0.5rem" }}>
            <Titulos>IGIC %</Titulos>
            <div>
              <input
                onFocus={onFocus}
                onBlur={onBlur}
                onKeyDown={handleEnter}
                onClick={handleOnClick}
                placeholder="Introduce el IGIC"
                name="igic"
                value={campos.igic}
                onChange={obtenerCampos}
              ></input>
            
            </div>
          </div>

          <div style={{ marginBottom: "0.5rem" }}>
            <Titulos>Descuento %</Titulos>
            <div>
              <input
                onFocus={onFocus}
                onBlur={onBlur}
                onKeyDown={handleEnter}
                onClick={handleOnClick}
                placeholder="Introduce el descuento"
                name="descuento"
                value={campos.descuento}
                onChange={obtenerCampos}
              ></input>
            
            </div>
          </div>
          <div style={{ marginBottom: "0.5rem" }}>
            <Titulos> Notas sobre el descuento </Titulos>
            <div>
              <input
                onFocus={onFocus}
                onBlur={onBlur}
                onKeyDown={handleEnter}
                onClick={handleOnClick}
                placeholder="Introduce la nota"
                name="nota"
                value={campos.nota}
                onChange={obtenerCampos}
              ></input>
            
            </div>
          </div>

          <div style={{ marginBottom: "0.5rem" }}>
            <Titulos>Descripción</Titulos>
            <div>
              <input
                onFocus={onFocus}
                onBlur={onBlur}
                onKeyDown={handleEnter}
                contentEditable="true"
                ref={refEmpresa}
                onClick={handleOnClick}
                name="description"
                value={campos.description}
                placeholder="Describe aquí tu proyecto"
                onChange={obtenerCampos}
              ></input>
              <CheckCircleOutlineSharpIcon
                style={{
                  width: "16px",
                  color: "#3BA452",
                  marginTop: "-8%",
                  marginLeft: "80%",
                }}
                ref={refEmpresaConfirm}
                id="confirmNotVisible"
              ></CheckCircleOutlineSharpIcon>
            </div>
          </div>

          <div id="OtroConten" style={{ marginBottom: "0.5rem" }}>
            <Titulos>Dirección</Titulos>
            <div>
              <input
                onFocus={onFocus}
                onBlur={onBlur}
                onKeyDown={handleEnter}
                ref={refDir}
                onClick={handleOnClick}
                placeholder="Dirección de Obra"
                name="orgAddress"
                value={campos.orgAddress}
                onChange={obtenerCampos}
              ></input>
              <CheckCircleOutlineSharpIcon
                style={{
                  width: "16px",
                  color: "#3BA452",
                  marginTop: "-8%",
                  marginLeft: "80%",
                }}
                ref={refDirConfirm}
                id="confirmNotVisible"
              ></CheckCircleOutlineSharpIcon>
            </div>
          </div>
{/* 
          <div>
            <Titulos>Etiqueta</Titulos>
            <div
              ref={refTags}
              onFocus={onFocus}
              onBlur={onBlur}
              onClick={handleOnClick}
              className="container2"
              style={{
                padding: "0px",
                maxHeight: "fitContent",
                height: "45px",
                overflow: "auto",
              }}
            >
              <LocalOfferOutlinedIcon id="iconTag"></LocalOfferOutlinedIcon>
              {tags?.map((tag, index) => (
                <div key={index} id="tagColor" className="tag">
                  {tag}
                  <button onClick={e => deleteTag(e, index)}>x</button>
                </div>
              ))}
            {!hideTags &&
              <input
                name="tags"
                value={input}
                placeholder="Añadir Etiqueta"
                onKeyDown={onKeyDown}
                onBlur={onBlurTags}
                onKeyUp={onKeyUp}
                onChange={onEtiqueta}
              />
            }

              <CheckCircleOutlineSharpIcon
                style={{
                  width: "16px",
                  color: "#3BA452",
                  marginTop: "2%",
                  marginLeft: "80%",
                }}
                ref={refEtiquetaConfirm}
                id="confirmNotVisible"
              ></CheckCircleOutlineSharpIcon>
            </div>
          </div> */}

          <div id="centerButton" style={{ marginTop: "1rem", marginBottom:'1rem' }}>
            {loading ? <CircularProgress size={24} /> :
            <button
              type="button"
              ref={refBoton}
              className="AddContact"
              onClick={(e)=>{freeTrialExpired ? openFreeTrialExpire():onSubmit(e)}}
            >
              Guardar
            </button>
            }
          </div>
          <input type="submit" hidden />
        </form>
      </div>

      <ModalImageProject
        openImage={openImage}
        setOpenImage={setOpenImage}
        image={image}
        setImage={setImage}
        updateProject={updateProject}
        campos={campos}
        tagsFinal={tagsFinal}
        selectedContact={selectedContact}
        refBoton={refBoton}
      ></ModalImageProject>
      <AddContact openAdd={openAdd} setOpenAdd={setOpenAdd} />
    </form>
  )
}

ModalEditFields.propTypes = {
  campos: PropTypes.object.isRequired,

  // Change any
  contactos: PropTypes.any.isRequired,
  guardarCampos: PropTypes.func.isRequired,
  // Change any
  projectInfo: PropTypes.any.isRequired,

  setIsEdit: PropTypes.func.isRequired,
  image: PropTypes.any.isRequired,
  setImage: PropTypes.func.isRequired,
  selectedContact: PropTypes.any.isRequired,
  setSelectedContact: PropTypes.func.isRequired,
  imFromDropdown: PropTypes.bool
}

export default ModalEditFields
