import {
  GET_PROYECTOS,
  CREATE_PROJECT_SUCCESS,
  CREATE_PROJECT_ERROR,
  GET_PROJECT_SUCCESS,
  GET_CHAPTERS_SUCCESS,
  ON_CREATE_PROJECT,
  ON_GET_PROJECTS,
  ON_UPLOAD_PROJECT,
  ON_GET_CHAPTERS,
  UPLOAD_PROJECT_SUCCESS,
  UPLOAD_PROJECT_ERROR,
  GET_PROJECTS_ERROR,
  GET_PROJECTS_SUCCESS,
  UPDATE_PROJECT_SUCCESS,
  DELETE_CHAPTERS_SUCCESS,
  DELETE_PARTIDA_SUCCESS,
  GET_CHAPTERS_BUDGET_SUCCESS,
  DELETE_PROJECT_SUCCESS,
  CLEAR_UPLOAD_PROCESS, 
  ADD_TO_FAVORITES_SUCCESS, GET_VERSIONS_SUCCESS
  
} from "../types";
const ProjectReducer = (state, action) => {
  switch (action.type){
    case GET_PROYECTOS:
      return {
        ...state,
      }
    case CREATE_PROJECT_SUCCESS:
      return {
        ...state,
        selectedProject: action.payload.project,
        projects : [...state.projects, action.payload.project],
        createProcess:{
          creating_project: false,
          create_error: false,
          create_success: true
        }
      }
    case 'CREATE_PROJECT_SUCCESS_2':
      return {
        ...state,
        selectedProject: action.payload.project,
      }
      
    case CREATE_PROJECT_ERROR:
      return {
        ...state,
      }
      case DELETE_CHAPTERS_SUCCESS:
        return {
          ...state,
        }
        case DELETE_PARTIDA_SUCCESS:
          return {
            ...state,
          }    
    case ON_CREATE_PROJECT:
      return {
        ...state,
        createProcess : {
          creating_project : true,
          create_error : false,
          create_success : false,
        }
      }
    case ON_UPLOAD_PROJECT:
      return {
        ...state,
        uploadProcess : {
          uploading_project: true,
          upload_success : false,
          upload_error : false,
        }
      }
    
    case UPLOAD_PROJECT_ERROR:
      return {
        ...state,
        uploadProcess : {
          uploading_project: false,
          upload_success : false,
          upload_error : true,
        }
      }
    case UPLOAD_PROJECT_SUCCESS:
      return {
        ...state,
        selectedProject: null,
        uploadProcess : {
          uploading_project: false,
          upload_success : true,
          upload_error : false,
        }
      }
    case "UPDATE_FILE_UPLOADED":
      return {
        ...state,
        fileUploaded: action.payload
      }
    case UPDATE_PROJECT_SUCCESS:
      let index = state.projects.findIndex(x => x.projectId==action.payload.projectId); 
      index === -1 ? state.updatedsPendingSubjects.push(action.payload) : null
      Object.assign(state.projects[index],action.payload)
      return {
        ...state,
      }
    case ON_GET_PROJECTS:
      return {
        ...state,
        loadingProjects: true
      }
      case ON_GET_CHAPTERS:
        return {
          ...state,
          loadingChapters: true
        }   
    case GET_PROJECTS_SUCCESS:
      let projects =[]
      let templates = []
      action.payload.map(project => {
        if(project.is_template){
          templates.push(project)
        }else{
          projects.push(project)
        }
      })
      //console.log(projects, templates)
      return {
        ...state,
        loadingProjects: false,
        projects: projects,
        templateProjects: templates
      }
      case DELETE_PROJECT_SUCCESS:
        return {
          ...state,
          projects: action.payload
        }
 
      case GET_CHAPTERS_SUCCESS:
        return {
          ...state,
          loadingChapters: false,
          chapters: action.payload
        }   
    case GET_PROJECTS_ERROR:
      return {
        ...state,
      }
    case GET_PROJECT_SUCCESS:
      return {
        ...state,
        projectInfo: action.payload.project
      }
    case GET_CHAPTERS_BUDGET_SUCCESS:
      return {
        ...state,
        capitulosPresupuesto: action.payload
      }
    case CLEAR_UPLOAD_PROCESS:
      return {
        ...state,
        uploadProcess : {
          uploading_project: false,
          upload_success : false,
          upload_error : false,
        }}
    case ADD_TO_FAVORITES_SUCCESS:
      return{
        ...state,
        projectInfo: action.payload.project
      }
    case GET_VERSIONS_SUCCESS:
      return{
        ...state,
        versions: action.payload
      }
    case 'ON_IMPORT_PLANTILLA':
      return{
        ...state,
        loadingImportPlantilla: true
      }
    case 'IMPORT_PLANTILLA_SUCCESS':
      return{
        ...state,
        loadingImportPlantilla: false,
      }
    default:
      return state;
  }
}
export default ProjectReducer;
