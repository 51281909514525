import React, { useState, useEffect, useContext } from "react"
import PropTypes from 'prop-types'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
//i18n
import {  useHistory } from "react-router-dom"
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import { useParams } from 'react-router-dom'
import ProjectContext from 'context/projects/projectContext';
import ModalDeleteProject from "components/Dashboard/modals/project/modalDeleteProject"
import MoreVertIcon from '@mui/icons-material/MoreVert';
import i18n from "i18n"
import ModalEditProyecto from "./ModalEditProyecto"
import ContactContext from "context/contact/contactContext"
import ModalCargandoDuplicado from "./ModalCargandoDuplicado"

const DropdownSettings = props => {
  const projectContext = useContext(ProjectContext)
  const {deleteProject} = projectContext
  const {addToFavorites, removeFromFavorites, getProject, projectInfo, duplicateProject} = useContext(ProjectContext)
  const {Contacts} = useContext(ContactContext)

  const [menu, setMenu] = useState(false)
  const history = useHistory()
  const {projectId} = useParams();
  const [open, setOpen] = useState(false)

    const removeP = () => {
      if(projectId){
      deleteProject(projectId)
      setTimeout(() => {
          history.push("/")
        },500)
      }else{
        deleteProject(props.project.projectId)
        setTimeout(() => {
            history.push("/")
          },500)
      }
  }

  const gestionEq = () => {
    if(projectId){
      history.push("/equipos/"+projectId)
    }else{
      history.push("/equipos/"+props.project.projectId)
    }
}


  useEffect(() => {
    if(projectId){
      getProject(projectId)
    }
 
  }, [projectId])

  const marcar = () => {
    if(projectInfo.favourite==undefined){
      addToFavorites(projectId)
    } else if(projectInfo.favourite==false){
      addToFavorites(projectId)
    }
    else{
      removeFromFavorites(projectId)}
  }

  const [openLoading, setOpenLoading] = useState(false)
  const [loadingDupli, setLoadingDupli] = useState(false)
  const [checkTemplate, setCheckTemplate] = useState(false)

  const duplicateProjectFunc = async(is_template) => {
    if(is_template){
      setCheckTemplate(true)
    }else{
      setCheckTemplate(false)
    }
    setLoadingDupli(true)
    setOpenLoading(true)
    if(projectId){
      await duplicateProject(projectId, is_template)
    }else{
      await duplicateProject(props.project.projectId, is_template)
    }
    setLoadingDupli(false)
  }
  
  const marcar2 = () => {
    
    if(props.project.favourite==undefined){
      addToFavorites(props.project.projectId)
    } else if(props.project.favourite==false){
      addToFavorites(props.project.projectId)
    }
    else{
      removeFromFavorites(props.project.projectId)
    }
    setTimeout(() => {
      location.reload()
    }, 500)  

  }
  

  const [openEliminate, setOpenEliminate] = useState(false)
  return (
    <>
    <React.Fragment>
      {props.imCard==true?
      <Dropdown
      isOpen={menu}
      toggle={() => setMenu(!menu)}
      className="d-inline-block"
      style={{height:'0px' }}
       >
      <DropdownToggle   id='settingb'
          style={{marginTop: props.imList ? '0px' : '-16px', marginLeft:props.imList ? "0px" : "310px", color:'#414141' , padding:'0px'}}
      >
        
        {
          props.imList ? 
          <MoreVertIcon className='settingsProject' style={{
            marginTop:'0px', marginLeft:"20px",color:'#414141',width:'20px', paddingTop:'2px'}}/> :
          <MoreVertIcon className='settingsProject' style={{
                    marginTop:'-5px', marginLeft:"0px",color:'#414141',width:'20px', paddingTop:'2px'}}/>
      }
    
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-end">
      <DropdownItem tag="b" onClick={()=>setOpenEliminate(true)} style={{lineHeight: '20px', cursor:'pointer'}}>
          {" "}
         {i18n.t("Eliminar")}
        </DropdownItem>
        {/* <DropdownItem tag="b" onClick={() => {gestionEq()}} style={{lineHeight: '20px', cursor:'pointer'}}>
          {" "}
         {i18n.t("Gestionar Equipo")}
        </DropdownItem> */}
        <DropdownItem tag="b" onClick={() => {setOpen(true)}} style={{lineHeight: '20px', cursor:'pointer'}}>
          {" "}
         {i18n.t("Editar Proyecto")}
        </DropdownItem>
         <DropdownItem tag="b" onClick={() => {duplicateProjectFunc(false)}} style={{lineHeight: '20px', cursor:'pointer'}}>
          {" "}
         {i18n.t("Duplicar Proyecto")}
        </DropdownItem>  
        <DropdownItem tag="b" onClick={() => {duplicateProjectFunc(true)}} style={{lineHeight: '20px', cursor:'pointer'}}>
          {" "}
         {i18n.t("Duplicar como plantilla")}
        </DropdownItem>  
        
        {props.project? 
        <DropdownItem tag="b" onClick={() => {marcar2()}} style={{lineHeight: '20px', cursor:'pointer'}}>
          {" "}
         {props.project.favourite? props.project.favourite==true? 'Quitar de favoritos': 'Marcar como favoritos' : 'Marcar como favoritos'  }
        </DropdownItem> 
        
        :null }
      </DropdownMenu>
    </Dropdown>
      :
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
         >
        <DropdownToggle   id='settingb'
            style={{marginTop:'-10px', color:'#414141', margin:'0px', padding:'0px'}}
        >
            <SettingsOutlinedIcon className='settingsProject' style={{color:'#414141',width:'20px', paddingTop:'2px'}}/>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
        <DropdownItem tag="b" onClick={()=>setOpenEliminate(true)} style={{lineHeight: '20px', cursor:'pointer'}}>
            {" "}
           {i18n.t("Eliminar")}
          </DropdownItem>
          {/* <DropdownItem tag="b" onClick={() => {history.push("/equipos/"+projectId)}} style={{lineHeight: '20px', cursor:'pointer'}}>
            {" "}
           {i18n.t("Gestionar Equipo")}
          </DropdownItem> */}
          <DropdownItem tag="b" onClick={() => {setOpen(true)}} style={{lineHeight: '20px', cursor:'pointer'}}>
          {" "}
         {i18n.t("Editar Proyecto")}
        </DropdownItem>
        {/*
        <DropdownItem tag="b" onClick={() => {duplicateProjectFunc()}} style={{lineHeight: '20px', cursor:'pointer'}}>
          {" "}
         {i18n.t("Duplicar Proyecto")}
        </DropdownItem> 
        */}
          {projectInfo? 
          <DropdownItem tag="b" onClick={() => {marcar()}} style={{lineHeight: '20px', cursor:'pointer'}}>
            {" "}
           {projectInfo.favourite? projectInfo.favourite==true? i18n.t('Quitar de favoritos'): i18n.t('Marcar como favoritos') : i18n.t('Marcar como favoritos')  }
          </DropdownItem> 
          :null }
        </DropdownMenu>
      </Dropdown>
}
    </React.Fragment>
    <ModalDeleteProject openEliminate={openEliminate} setOpenEliminate={setOpenEliminate} project={props.project} />
   {open && <ModalEditProyecto imFromDropdown={true} projectInfo={props.imCard ? props.project : projectInfo} contactos={Contacts} open={open} setOpen={setOpen}/> }
   {openLoading && <ModalCargandoDuplicado checkTemplate={checkTemplate}  open={openLoading} setOpen={setOpenLoading} loading={loadingDupli}/> }
  </>
  )
}

DropdownSettings.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
  imCard: PropTypes.bool,
  project: PropTypes.object,
  imList: PropTypes.bool,

}

export default DropdownSettings
