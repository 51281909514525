import { CapituloMini } from "common/containers";
import React, {useState, useEffect, useContext} from "react";  
import PropTypes from 'prop-types';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { HelveticaBold } from "common/typographies";
import i18n from "i18next";
import { KeyboardArrowUp } from "@mui/icons-material";
import AuthContext from "context/auth/authContext";
import { CircularProgress } from "@mui/material";

const ActaTemplate = ({url, setUrl, handleOpen, openNotSendCapitulo, setLoading}) => {

    const [cargado, setCargado] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [auxUrl, setAuxUrl] = useState(null)
    const handleOpen2 = async(e) => {
        if (!e) var e = window.event;
        e.cancelBubble = true;
        if (e.stopPropagation) e.stopPropagation();
        handleOpen(e, 'actas')
       
      }
      const [options, setOptions] = useState([false, false, false])
      const {getTemplates, actTemplate, internalUser, updateInternalUser} = useContext(AuthContext)


      useEffect(() => {
        if(openNotSendCapitulo){
            setUrl(auxUrl)
            if(referenciaCondiciones.current && internalUser){
                if(internalUser.actTemplate.conditions){
                referenciaCondiciones.current.innerText = internalUser.actTemplate.conditions
                }
            }
        }
      }, [openNotSendCapitulo, referenciaCondiciones])

      useEffect(() => {
        if(auxUrl && openNotSendCapitulo){
            setUrl(auxUrl)
        }
      }, [auxUrl, openNotSendCapitulo])

      useEffect(() => {
        if(internalUser?.actTemplate && !cargado && openNotSendCapitulo){
        setLoading(true)
        setLoading2(true)
        getTemplates('ACT').then((res) => {
                if(openNotSendCapitulo){
                    setAuxUrl(res[0])
                    setLoading(false)
                    setLoading2(false)
                    setCargado(true)
            }
          
            if(internalUser.actTemplate.conditions){
              setCondiciones(internalUser.actTemplate.conditions)
              if(referenciaCondiciones.current){
              referenciaCondiciones.current.innerText = internalUser.actTemplate.conditions
              }
              
          }
        })
        }
    }, [internalUser, openNotSendCapitulo])


    const updateChanges = async(invoiceTemplateAux) => {
        let aux = {...internalUser}
        aux.actTemplate = {...invoiceTemplateAux, conditions: condiciones, preview: invoiceTemplateAux.preview.response}
        await updateInternalUser(aux)
    }


      

    const referenciaCondiciones = React.useRef(null)

    const [interval, setInterval2] = useState(null)
    const [counter, setCounter] = useState(0)
    const [guarda, setGuarda] = useState(false)
    const [condiciones, setCondiciones] = useState('')

    const handleChangeConfitions = (e) => {
        if(e.target.className=='newTextareaExpandEdit'){
            if(!interval) {setInterval2(true)}
            setCounter(0)
            setCondiciones(referenciaCondiciones.current.outerText)
        }
    }

    useEffect(() => {
        if(interval){
          const interval = setInterval(() => {
            setCounter(counter + 1);
          }, 1000);
          if(counter ==2){
            updateChanges(url)
            setInterval2(false)
            setCounter(0)
          }
          return () => clearInterval(interval);
        }
      }, [counter, interval]);


    return (
        <div style={{marginRight:'20px'}}>
        <CapituloMini  onClick={(e) => handleOpen2 (e)} style={{boxShadow:'none', borderBottomRightRadius: openNotSendCapitulo && '0px', borderBottomLeftRadius: openNotSendCapitulo && '0px'}}>
            <HelveticaBold style={{fontSize:'13px', marginLeft:'15px'}}>
                {i18n.t("Plantillas de actas")}
            </HelveticaBold>
        {openNotSendCapitulo ? (
                <div>
                  <KeyboardArrowUp
                    style={{ cursor: "pointer", position:'absolute', right:'5px', marginTop:'6px'  }}
                    className="dropdownArrowB"
               
                    
                  />
                </div>
              ) : (
                <KeyboardArrowDownIcon
                  style={{ cursor: "pointer", marginLeft: "5px", position:'absolute', right:'5px', marginTop:'6px' }}
                  className="dropdownArrowB"
                 
                />
              )}
              
        </CapituloMini>

        {
        openNotSendCapitulo ? 
          loading2 ? 

          <div style={{borderTop:'0.5px solid #C4C4C4', background:'white', height:'50vh', zIndex:'2', marginTop:'0px', position:'relative', borderBottomRightRadius: '6PX', borderBottomLeftRadius: '6PX', overflowY:'auto'}}>
        
          <CircularProgress style={{position:'absolute', top:'50%', left:'50%', marginTop:'-20px', marginLeft:'-20px'}}/>
      </div> 
      :
        <div style={{borderTop:'0.5px solid #C4C4C4', background:'white', height:'50vh', zIndex:'2', marginTop:'0px', position:'relative', borderBottomRightRadius: '6PX', borderBottomLeftRadius: '6PX', overflowY:'auto'}}>
           
 
            <HelveticaBold style={{fontWeight:'400', color:'#414141', fontSize:'12px', marginLeft:'15px', marginTop:'10px'}}>
                {i18n.t("Define un comentario")}:
            </HelveticaBold>

            <p
                style={{
                  display: "block",
                  textAlign: "left",
                  marginBottom: "0px",
                  border: '1px solid #C4C4C4',
                  borderRadius:'6px',
                    marginLeft:'15px',
                    marginRight:'15px',
                    marginTop:'-10px',
                    height:'30%',
                }}
              >
                {" "}
                <span ref={referenciaCondiciones}
                 style={{maxWidth:'100% !important', padding:'10px',height:'100%', overflowY:'auto'}}
                  className="newTextareaExpandEdit"
                  role="textbox"
                  autoComplete="off"
                  name="description"
                  onInput={handleChangeConfitions}
                  contentEditable
                ></span>
              </p>
 
        </div>: null
        }

        </div>
    )
}

export default ActaTemplate;

ActaTemplate.propTypes = {
    url: PropTypes.string.isRequired,
    setUrl: PropTypes.func.isRequired,
    handleOpen: PropTypes.func.isRequired,
    openNotSendCapitulo: PropTypes.bool.isRequired,
    setLoading: PropTypes.func.isRequired,
}
