import React, { useContext } from "react"
import PropTypes from "prop-types"
import { PartStyledTable } from "common/tables"
import i18n from "i18n"
import { currencyFormatDE } from "lib/transformers"
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { PoppinsNormal } from "common/typographies"
import { getCurrency } from "utils/functions"
import AuthContext from "context/auth/authContext"

const TableStructureComparison = ({ children, tabla, setOpen, proposalToAccept, setProposalToAccept}) => {
  const {user} = useContext(AuthContext)
  const handleAccept = (i) => {
    setProposalToAccept([tabla[tabla.length-1][i]])
    setOpen(true)
  }
  return (
    <PartStyledTable
      style={{
        marginLeft:  "0px",
        width: "100%",
        border: "0px solid #e0e0e0",
      }}
    >
      <tr style={{ background: "white" }}>
        <th style={{ minWidth: "81px" }}></th>
        <th style={{ minWidth: "300px", width: "90%" }}></th>
        <th style={{ minWidth: "88px" }}></th>
        <th style={{ minWidth: "205", fontFamily:"Helvetica" }} colSpan="2">
          {i18n.t("Precio Objetivo")}
        </th>
        {tabla[0].map((item, index) => {
          return ( 
          <th key={index} style={{ minWidth: "205", fontFamily:"Helvetica",
          backgroundColor: tabla[tabla.length-1][index].proposalInfo.statusUser=='LOCKED'? 'rgba(217, 217, 217, 0.44)': 'white',
          opacity: tabla[tabla.length-1][index].proposalInfo.statusUser=='LOCKED'? '0.44': '1'
          }} colSpan="2">
         {item.orgName}
        </th>
        )
        })}
      </tr>
      <tr style={{ background: "white" }}>
        <th style={{ minWidth: "81px" , fontFamily:"Helvetica"}}>U</th>
        <th
          style={{
            minWidth: "300px",
            width: "90%",
            textAlign: "left",
            paddingLeft: "33px",
            width: "auto",
            fontFamily:"Helvetica"
          }}
        >
          {tabla[1].name}
        </th>

        <th style={{ minWidth: "88px",fontFamily:"Helvetica" }}>{i18n.t("Medición")}</th>
        <th
          style={{ minWidth: "100px", textAlign: "left", paddingLeft: "33px", fontFamily:"Helvetica"}}
        >
           {  getCurrency(user['custom:currency'])}/u
        </th>
        <th
          style={{ minWidth: "105px", textAlign: "left", paddingLeft: "33px", fontFamily:"Helvetica", borderLeft:'1px solid #EBEBEB' }}
        >
          {i18n.t("Total")}
        </th>
        {tabla[0].map((item, index) => {
          return (<>
            <th
          style={{ 
            backgroundColor: tabla[tabla.length-1][index].proposalInfo.statusUser=='LOCKED'? 'rgba(217, 217, 217, 0.44)': 'white',
            opacity: tabla[tabla.length-1][index].proposalInfo.statusUser=='LOCKED'? '0.44': '1', minWidth: "100px", textAlign: "left", paddingLeft: "33px", fontFamily:"Helvetica" }}
        >
           {  getCurrency(user['custom:currency'])}/u
        </th>
        <th
          style={{ 
            backgroundColor: tabla[tabla.length-1][index].proposalInfo.statusUser=='LOCKED'? 'rgba(217, 217, 217, 0.44)': 'white',
            opacity: tabla[tabla.length-1][index].proposalInfo.statusUser=='LOCKED'? '0.44': '1',  minWidth: "105px", textAlign: "left", paddingLeft: "33px", fontFamily:"Helvetica", borderLeft:'1px solid #EBEBEB' }}
        >
          {i18n.t("Total")}
        </th>
        </>)
        })}
       
      </tr>
      {children}
      <tr style={{height:'45px'}}>
        <td style={{ fontFamily:"Helvetica", borderLeft:'none' }}></td>
        <td style={{ fontFamily:"Helvetica", borderLeft:'none' }}></td>
        <td style={{ fontFamily:"Helvetica", borderLeft:'none' }}></td>
        <td style={{ fontFamily:"Helvetica", borderLeft:'none' }}></td>
        <td style={{ fontFamily:"Helvetica", borderLeft:'none' }}></td>
        {tabla[tabla.length-1].map((item, index) => {
          return ( 
            <>
                 <td key={index} style={{ 
                   opacity: tabla[tabla.length-1][index].proposalInfo.statusUser=='LOCKED'? '0.44':'1',
                   backgroundColor: tabla[tabla.length-1][index].proposalInfo.statusUser=='LOCKED'? 'rgba(217, 217, 217, 0.44)':'white',
                  fontFamily:"Helvetica", paddingLeft:'0px', borderLeft:'none' }} >
                    {
                      tabla[tabla.length-1][index].proposalInfo.statusUser=='LOCKED'?
                      <div className="butonAceptar2" style={{border:'none', backgroundColor:'transparent', marginLeft:'12px', width:'77px', height:'23px', cursor:'pointer'}}>
                      <CheckCircleOutlineIcon style={{ color: "#00a650", fontSize: "11px", marginLeft:'6px', marginRight:'5px'}} />
                      <PoppinsNormal style={{fontSize:'11px', fontWeight:'500', paddingTop:'16px' }}>Aceptar</PoppinsNormal>
                   
                    </div>
                      :
                      tabla[tabla.length-1][index].proposalInfo.statusUser=='ACCEPTED'? 
                      <div  className="butonAceptar2" style={{border:'none', backgroundColor:'transparent',marginLeft:'8px', width:'77px', height:'23px', cursor:'pointer'}}>
                      <CheckCircleOutlineIcon style={{ color: "#00a650", fontSize: "11px", marginLeft:'6px', marginRight:'5px'}} />
                      <PoppinsNormal style={{fontSize:'11px', fontWeight:'500', paddingTop:'16px' }}>Aceptada</PoppinsNormal>
                   
                    </div>
                      :
                      <div onClick={()=>{handleAccept(index)}} className="butonAceptar" style={{marginLeft:'12px', width:'77px', height:'23px', cursor:'pointer'}}>
                      <CheckCircleOutlineIcon style={{ color: "#00a650", fontSize: "11px", marginLeft:'6px', marginRight:'5px'}} />
                      <PoppinsNormal style={{fontSize:'11px', fontWeight:'500', paddingTop:'16px' }}>Aceptar</PoppinsNormal>
                   
                    </div>
                    }
                  
                </td>
                <td key={index} style={{ 
                   opacity: tabla[tabla.length-1][index].proposalInfo.statusUser=='LOCKED'? '0.44':'1',
                   backgroundColor: tabla[tabla.length-1][index].proposalInfo.statusUser=='LOCKED'? 'rgba(217, 217, 217, 0.44)':'white',
                  fontFamily:"Helvetica", borderLeft:'none', paddingLeft:'0px' , borderLeft:'none'}} >
                <div className="butonAceptar2" style={{marginLeft:'5px', width:'95px', height:'23px', cursor:'pointer', justifyContent:'center', textAlign:'center'}}>
                      <PoppinsNormal style={{fontSize:'11px', fontWeight:'400', paddingTop:'16px', color:'#4461D7',  }}>{currencyFormatDE(item.providerTotal,getCurrency(user['custom:currency']))}</PoppinsNormal>
                    </div>
                </td>
            </>
     
        )
        })}
      </tr>
    </PartStyledTable>
  )
}

TableStructureComparison.propTypes = {
  children: PropTypes.node.isRequired,
  tabla: PropTypes.array.isRequired,
  setOpen: PropTypes.func,
  proposalToAccept: PropTypes.func,
  setProposalToAccept: PropTypes.func,
}

export default TableStructureComparison
