import React,{useEffect, useState} from 'react'
import MetaTags from 'react-meta-tags'
import BancoPreciosRefContent from 'components/FavoritesBP/BancoPreciosRefContent';

import {
    Container
} from 'reactstrap'




const FavoritesPage = () => {

    const [tipo, setTipo] = useState('')
    const [referencia, setReferencia] = useState('')

    useEffect(() => {
        let tipo2 = window.location.href.split('/')[5]
        if(tipo2=='myBP'){
        setTipo('USER_DATABASE')
        }else if(tipo2=='referenciaC'){
            setTipo('REFERENCE_DATABASE')
            setReferencia('cype')
        }else if(tipo2=='referenciaB'){
            setTipo('REFERENCE_DATABASE')
            setReferencia('bedec')
        }else if(tipo2=='referenciaT'){
            setTipo('REFERENCE_DATABASE')
            setReferencia('tribboo')
        }else if(tipo2=='templates'){
            setTipo('TEMPLATES')
        }

    }, [])

    return (
        <React.Fragment>
            <div className='page-content'>
                <MetaTags>
                    <title>Planhopper | Favoritos </title>
                </MetaTags>
                <Container fluid>
                    {tipo!='' ?
                    <BancoPreciosRefContent tipo={tipo} referencia={referencia}/>
                    :null
                }
                </Container>
            </div>
        </React.Fragment>

    )
}

export default FavoritesPage