import React, {useState, useMemo, useEffect, useContext} from "react";
import { Grid, CircularProgress} from "@material-ui/core";
import { CardPricing } from "common/containers";
import { HelveticaBold, PoppinsSemiBold } from "common/typographies";
import i18n from "i18n";
import { HelveticaInput } from "common/inputs";
import { YellowButton } from "common/buttons";
import Select from 'react-select'
import countryList from 'react-select-country-list'
import currencyList from './currency.json'
import languageList from './language.json'
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import ModalImage from "components/Profile/ModalImage";
import avatar1 from "assets/images/avatar/avatar1.png"
import AuthContext from "context/auth/authContext";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import ModalPassword from "components/Profile/ModalPassword";
import * as amplitude from '@amplitude/analytics-browser';



const ProfileComponent = () => {
    const [userInfo, setUserInfo] = useState({
      name: "",
      mobile: "",
      nif: "",
      email: ""
    })
    const countryOpts = useMemo(() => countryList().getData(), [])
    const currencyOpts = useMemo(() => currencyList, [])
    const idiomaOptions = useMemo(() => languageList, [])

    const [openImage, setOpenImage] = useState(false)
    const handleOpenImage = e => {
      e.preventDefault()
      setOpenImage(true)
    }
    const [openPassword, setOpenPassword] = useState(false)

    const [languageOptions, setLanguajeOptions] = useState({
        language: {},
        currency: {},
        country: {}
    })

    const {user, updateUser} = useContext(AuthContext)
    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [userInfoSaved, setUserInfoSaved] = useState(false)
    const [languageOptionsSaved, setLanguajeOptionsSaved] = useState(false)

    const [countryObj, setCountryObj] = useState({})
    const [currencyObj, setCurrencyObj] = useState({})
    const [languageObj, setLanguageObj] = useState('')
    const [userInputsShow, setUserInputsShow] = useState(true)

    const setCountry = () => {
      if (user['custom:pais']) {
        const country = countryOpts.find(o => o.label === user['custom:pais'])
        if (country) {
          console.log('country found', country)
          setCountryObj(country)
        } else {
          console.log('country not found', user['custom:pais'])
          setCountryObj({value: 'ES', label: 'España'})
        }
      } else {
        setCountryObj({value: 'ES', label: 'España'})
      }
      setLanguajeOptionsSaved(true)
    }

    const setLanguage = () => {
      if (user['custom:idioma']) {
        const language = idiomaOptions.find(o => o.value === user['custom:idioma'])
        if (language) {
          console.log('language found', language)
          setLanguageObj(language)
        } else {
          console.log('language not found', user['custom:idioma'])
          setLanguageObj({value: 'es', label: 'Castellano'})
        }
      } else {
        setLanguageObj({value: 'es', label: 'Castellano'})
      }
      setLanguajeOptionsSaved(true)
    }

    const setCurrency = () => {
      if (user['custom:currency']) {
        const currency = currencyOpts.find(o => o.value === user['custom:currency'])
        if (currency) {
          console.log('currency found', currency)
          setCurrencyObj(currency)
        } else {
          console.log('currency not found', user['custom:currency'])
          setCurrencyObj({value: 'EUR', label: 'Euro'})
        }
      } else {
        setCurrencyObj({value: 'EUR', label: 'Euro'})
      }
      setLanguajeOptionsSaved(true)
    }

    useEffect(() => {
      if (user) {
        setCountry()
        setLanguage()
        setCurrency()
      }
    }, [])

    useEffect(() => {
      return
      if(user){
        if ( !user["custom:nombre_apellido"] 
            || user["custom:nombre_apellido"] === ""
            || !user["custom:mobile"] 
            || user["custom:mobile"] === "" 
            || !user["custom:nif"] 
            || user["custom:nif"] === "" ) {
              setUserInputsShow(true)
          } else {
            setUserInputsShow(false)
          }
        
        setUserInfo({
          name: user['custom:nombre_apellido'] ? user['custom:nombre_apellido'] : "",
          mobile: user['custom:mobile'] ? user['custom:mobile'] : "",
          nif: user['custom:nif'] ? user['custom:nif'] : "",
          email: user['email'] ? user['email'] : ""
        })

        // setLanguage()
        // setCurrency()
        // setCountry()

            setLanguajeOptions({
                language: idioma,
                currency: moneda,
                country: pais
            })
    }
      setUserInfoSaved(true)

    }, [])

    useEffect(() => {
      amplitude.track({
        event_type: 'Profile visited',
        event_properties: {accountEmail: user?.email}
      });

    }, [])

    const [refs4UserInfo, setRefs4UserInfo] = useState([
      React.createRef(), React.createRef(), React.createRef(), React.createRef()
    ])
    const [refs4Address, setRefs4Address] = useState([
      React.createRef(), React.createRef(), React.createRef()
    ])
  
    
    const handleChange = (e) => {
      setUserInfoSaved(false)
      setUserInfo({
        ...userInfo,
        [e.target.name]: e.target.value
      })
    }
  
    useEffect(() => {
      console.log(`currencyObj=${JSON.stringify(currencyObj)}`)
    }, [currencyObj])

    const onChangeCountry = (obj) => {
      setLanguajeOptionsSaved(false)
      setCountryObj(obj)
    }

    const onChangeCurrency = (obj) => {
      console.log('onChangeCurrency', obj)
      setCurrencyObj({value: obj.value, label: obj.label})
      setLanguajeOptionsSaved(false)
    }
    const changeHandler3 = value => {
        setLanguajeOptionsSaved(false)
        setLanguageObj(value)
    }

    const handleSubmit1 = async(e) => {
      e.preventDefault()
      let valida=true
      if(userInfo.name.length==0){
        valida=false
        refs4UserInfo[0].current.style.border="1px solid red"
      }
      else{
          refs4UserInfo[0].current.style.border="1px solid #e0e0e0"
      }
      if(userInfo.mobile==""){
        valida=false
        refs4UserInfo[1].current.style.border="1px solid red"
      }else{
        refs4UserInfo[1].current.style.border="1px solid #e0e0e0"
      }
      if(userInfo.nif.length==0){
        valida=false
        refs4UserInfo[2].current.style.border="1px solid red"
      }else{
        refs4UserInfo[2].current.style.border="1px solid #e0e0e0"
      }
      if(userInfo.email.length==0){
        valida=false
        refs4UserInfo[3].current.style.border="1px solid red"
      }else{
        refs4UserInfo[3].current.style.border="1px solid #e0e0e0"
      }
      if(valida){
        setLoading(true)
        let userInfoParsed = {
            "custom:nombre_apellido": userInfo.name,
            "custom:mobile": userInfo.mobile,
            "custom:nif": userInfo.nif,
            "email": userInfo.email
        }
        await updateUser(userInfoParsed)
        setLoading(false)
        setUserInfoSaved(true)
        setUserInputsShow(false)
      }

    }
    const handleSubmit2 = async(e) => {
      e.preventDefault()
      let valida=true
      if(languageObj.length==0){
        valida=false
        refs4Address[0].current.props.style.border="4px solid red"
      }else{
        refs4Address[0].current.props.style.border="4px solid black"
        setLanguajeOptions(
          {
            ...languageOptions,
            langauge: languageObj.value
          }
        )
      }
      if(currencyObj.length==0){
        valida=false
        refs4Address[1].current.props.style.border="4px solid red"
      }else{
        refs4Address[1].current.props.style.border="4px solid black"
        setLanguajeOptions(
          {
            ...languageOptions,
            currency: currencyObj.value
          }
        )
      }
      if(countryObj.length==0){
        valida=false
        refs4Address[2].current.props.style.border="4px solid red"
      }else{
        refs4Address[2].current.props.style.border="4px solid black"
        setLanguajeOptions(
          {
            ...languageOptions,
            country: countryObj.value
          }
        )
      }
      if(valida){
        setLoading2(true)
        let userInfoParsed = {
            "custom:pais": countryObj.label,
            "custom:idioma": languageObj.value,
            "custom:currency": currencyObj.value,
        }
        await updateUser(userInfoParsed)
        setLoading2(false)
        setLanguajeOptionsSaved(true)
      }
    }

    return(
        <div>
          <PoppinsSemiBold style={{fontSize:'20px', marginLeft:'30px', marginBottom:'20px'}}>{i18n.t("Configuración")}</PoppinsSemiBold>
          <div className="inline">
          <Grid container style={{backgroundColor:'transparent', height:'auto', marginLeft:'20px', marginRight:'20px', marginBottom:'50px', maxWidth:'908px'}} direction='row' spacing={1}>
              <Grid  item xs={12} style={{paddingLeft:'12px', cursor:'pointer', width:'100%', position:'relative' }}>
               <CardPricing style={{width:'100%', height:'295px', maxWidth:'908px', minWidth:'520px', paddingLeft:'16px', position:'relative', paddingTop:'9px', cursor:'default'}}>
                <HelveticaBold style={{color:'#414141', fontSize:'20px'}}>{i18n.t("Perfil Usuario")}</HelveticaBold>
                
                  <div className="inline" style={{width:'85%'}}>
                    <div style={{width:'45%', marginRight:'20px'}}>
                    <HelveticaBold style={{fontSize:'14px', marginBottom:'5px', color:'#414141'}}>{i18n.t("Nombre y Apellido")}</HelveticaBold>
                    {!userInfoSaved || userInputsShow ?
                    <HelveticaInput style={{width:'100%'}}
                    placeholder={"Introduce tu nombre"}
                    name='name'
                    value={userInfo.name}
                    onChange={handleChange}
                    ref={refs4UserInfo[0]}
                    ></HelveticaInput>
                    :
                    <HelveticaBold style={{fontSize:'14px', marginBottom:'5px', color:'#414141', fontWeight:'400', paddingTop:'7PX', paddingBottom:'7px'}}>{userInfo.name}</HelveticaBold>
                    }
                    </div>
                    <div style={{width:'45%'}}>
                    <HelveticaBold style={{fontSize:'14px', marginBottom:'5px', color:'#414141'}}>{i18n.t("Telefono")}</HelveticaBold>
                    {!userInfoSaved || userInputsShow ?
                    <HelveticaInput style={{width: '100%'}}
                    placeholder={"+00 000 00 00 00"}
                    name='mobile'
                    value={userInfo.mobile}
                    onChange={handleChange}
                    ref={refs4UserInfo[1]}
                    ></HelveticaInput>
                    :
                    <HelveticaBold style={{fontSize:'14px', marginBottom:'5px', color:'#414141', fontWeight:'400', paddingTop:'7PX', paddingBottom:'7px'}}>{userInfo.mobile}</HelveticaBold>
                      }
                    </div>
              
                    
                    
                  </div>
                  <div className="inline" style={{width:'85%'}}>
                    <div style={{width:'45%', marginRight:'20px'}}>
                    <HelveticaBold style={{fontSize:'14px', marginBottom:'5px', color:'#414141'}}>{i18n.t("NIF/DNI/NIE")}</HelveticaBold>
                    {!userInfoSaved || userInputsShow ?
                    <HelveticaInput style={{width:'100%'}}
                    placeholder={"XXXXXXXX-B"}
                    name='nif'
                    value={userInfo.nif}
                    onChange={handleChange}
                    ref={refs4UserInfo[2]}
                    ></HelveticaInput>
                    :
                    <HelveticaBold style={{fontSize:'14px', marginBottom:'5px', color:'#414141', fontWeight:'400', paddingTop:'7PX', paddingBottom:'7px'}}>{userInfo.nif}</HelveticaBold>
    }
                    </div>
                    <div style={{width:'45%'}}>
                    <HelveticaBold style={{fontSize:'14px', marginBottom:'5px', color:'#414141'}}>{i18n.t("Cambiar Contraseña")}</HelveticaBold>
                    <button className="cambioContra" style={{width:'fit-content'}} onClick={()=>{setOpenPassword(true)}}>
                        {i18n.t("Cambiar contraseña actual")}
                        </button>
                    </div>
                  </div>
                  <div className="inline" style={{width:'85%'}}>
                        <div style={{width:'45%'}}>
                        <HelveticaBold style={{fontSize:'14px', marginBottom:'5px', color:'#414141'}}>{i18n.t("Email")}</HelveticaBold>
                        {!userInfoSaved || userInputsShow ?
                        <HelveticaInput style={{width:'100%', backgroundColor:'#e4e4e4'}}
                        placeholder={"example@gmail.com"}
                        name='email'
                        disabled
                        type='mail'
                        value={userInfo.email}
                        onChange={handleChange}
                        ref={refs4UserInfo[3]}
                        ></HelveticaInput>
                        :
                        <HelveticaBold style={{fontSize:'14px', marginBottom:'5px', color:'#414141', fontWeight:'400', paddingTop:'7PX', paddingBottom:'7px'}}>{userInfo.email}</HelveticaBold>
}
                        </div>
                    </div>
                  {loading==true?
                  <CircularProgress style={{position:'absolute', right:'30px', bottom:'25px'}} size={24}/>
                    :
                    !userInfoSaved || userInputsShow ?
                <YellowButton style={{position:'absolute', right:'30px', width:'130px', bottom:'25px', height:'35px', cursor:userInfoSaved && 'default', backgroundColor: userInfoSaved && '#E4E4E4'}}
                onClick={(e) => {
                    userInfoSaved ? null : handleSubmit1(e)
                }}
                >
                  {i18n.t("Guardar")}
                </YellowButton>
                :
                <EditOutlinedIcon style={{position:'absolute', right:'30px', bottom:'25px', cursor:'pointer'}} onClick={()=>{setUserInfoSaved(false)}}/>
                }
               </CardPricing>
               
              {
                //SEGUNDO COMPONENTE
              }
              </Grid>
              <Grid item xs={12} spacing={3} style={{paddingLeft:'12px', cursor:'pointer', height:'370px', marginTop:'20px'}}>
              <CardPricing style={{width:'auto', height:'220px', minWidth:'520px', maxWidth:'908px',paddingLeft:'16px', paddingTop:'9px', position:'relative', cursor:'default'}}>
               
              <HelveticaBold style={{color:'#414141', fontSize:'20px'}}>{i18n.t("Idioma y Pais")}</HelveticaBold>
                
                  <div className="inline" style={{width:'85%'}}>
                    <div style={{width:'45%', marginRight:'20px'}}>
                    <HelveticaBold style={{fontSize:'14px', marginBottom:'5px', color:'#414141'}}>{i18n.t("Idioma")}</HelveticaBold>
                    {!languageOptionsSaved ?
                    <Select ref={refs4Address[0]} style={{height:'45px', border:'4px solid black'}}  options={idiomaOptions} value={languageObj} onChange={changeHandler3} name='language'/>
                    :
                    <HelveticaBold style={{fontSize:'14px', marginBottom:'5px', color:'#414141', fontWeight:'400', paddingTop:'7PX', paddingBottom:'7px'}}>{languageObj.label}</HelveticaBold>
                    }
                    </div>
                    <div style={{width:'45%'}}>
                      <HelveticaBold style={{fontSize:'14px', marginBottom:'5px', color:'#414141'}}>{i18n.t("Moneda")}</HelveticaBold>
                      { // Language Select
                        !languageOptionsSaved
                        ? <Select name='currency' options={currencyOpts} value={currencyObj} onChange={onChangeCurrency} 
                            ref={refs4Address[1]} style={{height:'45px', border:'4px solid black'}}
                          />
                        : <HelveticaBold style={{fontSize:'14px', marginBottom:'5px', color:'#414141', fontWeight:'400', paddingTop:'7PX', paddingBottom:'7px'}}>{currencyObj.label}</HelveticaBold>
                      }
                  </div>
                </div>
        
                  <div className="inline" style={{width:'85%'}}>
                    <div style={{width:'45%', marginRight:'20px'}}>
                      <HelveticaBold style={{fontSize:'14px', marginBottom:'5px', color:'#414141'}}>{i18n.t("Pais")}</HelveticaBold>
                      { // Country Select
                        !languageOptionsSaved
                          ? <Select name='country' options={countryOpts} value={countryObj} onChange={onChangeCountry}
                              ref={refs4Address[2]} style={{height:'45px', border:'4px solid black'}}  />
                          : <HelveticaBold style={{fontSize:'14px', marginBottom:'5px', color:'#414141', fontWeight:'400', paddingTop:'7PX', paddingBottom:'7px'}}>{countryObj.label}</HelveticaBold>
                      }
                    </div>
                  
                  </div>
                  {loading2 == true ?
                  <CircularProgress style={{position:'absolute', right:'20px', bottom:'20px'}} size={24}/> :
                  !languageOptionsSaved ?
                <YellowButton style={{position:'absolute', right:'20px', width:'130px', bottom:'20px', height:'35px', cursor:languageOptionsSaved && 'default', backgroundColor: languageOptionsSaved && '#E4E4E4'}}
                onClick={(e) => {
                    languageOptionsSaved ? null : handleSubmit2(e)
                }}
                >
                  {i18n.t("Guardar")}
                </YellowButton>
                :
                <EditOutlinedIcon style={{position:'absolute', right:'20px', bottom:'20px', cursor:'pointer'}} onClick={()=>{setLanguajeOptionsSaved(false)}}/>
                }
              
              </CardPricing>
              
               </Grid>
            </Grid>
            <Grid  spacing={3} container style={{backgroundColor:'transparent', height:'600px'}}>
              <Grid item xs={12} style={{paddingLeft:'12px', cursor:'pointer'}}>
              <CardPricing style={{width:'auto', height:'295px',minWidth:'320px',maxWidth:'320px',paddingLeft:'16px', paddingTop:'9px', position:'relative', cursor:'default'}}>
                 <div id="UserImage2" onClick={(e)=>{handleOpenImage(e)}} style={{cursor: 'pointer',height:'140px', justifyContent:'center', display:'flex'}}>
                        <img style={{width:'132px', height:'132px'}}  id="ProfileImage2" src={user['custom:avatar']}
                             alt={avatar1}/>
                        <div style={{marginTop:'100px'}} id="foto2"><EditOutlinedIcon  id="fotoSize2"></EditOutlinedIcon></div>
                    </div>
                    <div style={{ position:'relative', justifyContent:'center', textAlign:'center', width:'100%'}}>
                        <HelveticaBold style={{fontSize:'14px', marginBottom:'5px', color:'#414141'}}>
                            {user['custom:nombre_apellido']? user['custom:nombre_apellido'] : i18n.t("Nombre y Apellido")}
                        </HelveticaBold>
                        <HelveticaBold style={{fontSize:'12px', marginBottom:'5px', color:'#414141'}}>
                            {countryObj.label ? countryObj.label : i18n.t("Pais")}
                        </HelveticaBold>
                        <div className="inline" style={{width:'100%', justifyContent:'center', marginTop:'20px'}}>
                            <MailOutlineIcon style={{fontSize:'18px', marginRight:'5px'}}/>
                            <HelveticaBold style={{fontSize:'12px', marginBottom:'5px', color:'#414141'}}>
                                {user['email'] ? user['email'] : i18n.t("Email")}
                            </HelveticaBold>
                        </div>
                        <div className="inline" style={{width:'100%', justifyContent:'center', marginTop:'10px'}}>
                            <LocalPhoneIcon style={{fontSize:'18px', marginRight:'5px'}}/>
                            <HelveticaBold style={{fontSize:'12px', marginBottom:'5px', color:'#414141'}}>
                                {user['custom:mobile'] ? user['custom:mobile'] : "+00 000 00 00 00"}
                            </HelveticaBold>
                        </div>
                    </div>
           

                </CardPricing>
              </Grid>
            </Grid>
            </div>
            <ModalImage openImage={openImage} setOpenImage={setOpenImage} user={user} first={true}/>
            <ModalPassword openPassword={openPassword} setOpenPassword={setOpenPassword} user={user}></ModalPassword> 
        </div>
    )
}

export default ProfileComponent
