import styled from 'styled-components';

const StrikethroughRow = styled.div`
  width: 98%;
  border: 1px solid #FF0000;
  position: absolute;
  top: 50%;
  text-decoration: line-through;
  margin-left: 25px;
`;

export default StrikethroughRow;