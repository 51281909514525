import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Grid, Box, Tooltip } from "@mui/material"
import { CardContainer, LittleTag } from "common/containers"
import {
  HelveticaBold,
  HelveticaLight,
  PoppinsSemiBold,
} from "common/typographies"
import { PoppinsNormal } from "common/typographies"
import i18n from "i18n"
import DropdownOptionsContact from "./dropdownOptionsContact"

const ContactGridView = ({ user, contacts }) => {
  const listaColores = [
    "color1",
    "color2",
    "color3",
    "color4",
    "color5",
    "color6",
    "color7",
    "color8",
  ]

  const [contactsAux, setContactsAux] = useState([])
  const [auxRoles, setAuxRoles] = useState([])

  const generateRoles = (roles = []) =>
    roles.map((role, index) => ({
      role,
      color: listaColores[index] || "color1",
    }))

  const formatContact = (contact = {}) => {
    const {
      id = "",
      completeName = "",
      email = "",
      mobile = "",
      role = [],
      orgName = "",
      nif = "",
      address = "",
    } = contact

    return { id, completeName, email, mobile, role, orgName, nif, address }
  }

  useEffect(() => {
    if (contacts) {
      const formattedContacts = contacts.map(contact => formatContact(contact))
      const rolesList = contacts.map(contact => generateRoles(contact.role))

      setContactsAux(formattedContacts)
      setAuxRoles(rolesList)
    }
  }, [contacts])

  function getColorId(role) {
    let colorId = ""
    if (role.length == 0) {
      colorId = "colorTransparent"
    }
    const roleName = role[0]
    auxRoles.map(tagGroups => {
      tagGroups.map(tag => {
        if (tag.role == roleName) {
          colorId = tag.color
        }
      })
    })
    return colorId
  }

  return user ? (
    <>
      <div
        style={{
          marginTop: "0px",
          backgroundColor: "transparent",
          marginBottom: "0px",
        }}
      >
        <Box
          sx={{ flexGrow: 1 }}
          style={{
            marginLeft: "0px",
            marginTop: "20px",
            minHeight: "260px",
            backgroundColor: "transparent",
          }}
        >
          <Grid container spacing={6} columns={{ xs: 2, sm: 8, md: 12 }}>
            {
              contactsAux &&
                contactsAux.length > 0 &&
                contactsAux.map((contact, index) => (
                  <Grid item key={index}>
                    <CardContainer
                      style={{
                        cursor: "pointer",
                        position: "relative",
                        whiteSpace: "nowrap",
                        display: "block",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <img
                        style={{ width: "85px", height: "85px" }}
                        src={user["custom:avatar"]}
                        alt="Avatar"
                      />

                      <div
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "10px",
                        }}
                      >
                        <DropdownOptionsContact
                          selectedContact={{
                            ...contact,
                            role: auxRoles[index],
                          }}
                        />
                      </div>

                      <HelveticaBold
                        style={{
                          fontSize: "14px",
                          color: "#414141",
                          marginTop: "14px",
                          lineHeight: "0px",
                        }}
                      >
                        {contact["completeName"]}
                      </HelveticaBold>

                      <Tooltip title={contact["email"]} placement="top">
                        <HelveticaLight
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "180px",
                            display: "inline-block",
                          }}
                        >
                          {contact["email"]}
                        </HelveticaLight>
                      </Tooltip>

                      <LittleTag
                        id={getColorId(contact.role)}
                        style={{
                          marginLeft: "60px",
                          textTransform: "uppercase",
                        }}
                      >
                        {contact.role ? contact.role[0] : ""}
                      </LittleTag>
                    </CardContainer>
                  </Grid>
                ))
            }
          </Grid>
        </Box>
      </div>
    </>
  ) : (
    <>
      <div
        style={{
          justifyContent: "center",
          width: "100%",
          textAlign: "center",
          position: "relative",
          display: "flex",
          marginLeft: "-100px",
          marginTop: "100px",
        }}
      >
        <div className="loader">
          <div className="ball"></div>
          <div className="ball"></div>
          <div className="ball"></div>
        </div>
      </div>
      <div
        style={{
          justifyContent: "center",
          width: "100%",
          textAlign: "center",
          position: "relative",
          display: "flex",
          marginLeft: "-30px",
        }}
      >
        <PoppinsNormal>{i18n.t("Cargando...")}</PoppinsNormal>
      </div>
      <div
        style={{
          justifyContent: "center",
          width: "100%",
          textAlign: "center",
          position: "relative",
          display: "flex",
          marginLeft: "-30px",
          marginTop: "-15px",
        }}
      >
        <PoppinsNormal style={{ fontWeight: "400" }}>
          {i18n.t("Esta acción puede tardar unos segundos")}
        </PoppinsNormal>
      </div>
    </>
  )
}

ContactGridView.propTypes = {
  user: PropTypes.object.isRequired,
  contacts: PropTypes.array.isRequired,
}

export default ContactGridView
