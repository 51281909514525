import React, {useContext, useRef, useState, useEffect} from "react";
import PropTypes from "prop-types";
import i18n from "i18n";
import {HelveticaBold} from 'common/typographies'
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import PersonIcon from '@mui/icons-material/Person';
import MailIcon from '@mui/icons-material/Mail';
import SendIcon from '@mui/icons-material/Send';
import FirstStep from "./FirstStep";
import SecondStep from "./SecondStep";
import FourthStep from "./FourthStep";
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { useParams } from "react-router-dom";
import ProposalContext from "context/proposal/proposalContext";
import AuthContext from "context/auth/authContext";
import SecondStep2 from "./SecondStep2";
import ProjectContext from "context/projects/projectContext";
import BlockSendModal from "./blockSendModal";
import Budget2023Context from "context/budget2023/Budget2023Context";
import {
  IconButton,
  CircularProgress
} from "@mui/material"
import ModalCallError from "common/modalCallError";

  
  const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
      color: '#784af4',
    }),
    '& .QontoStepIcon-completedIcon': {
      color: '#784af4',
      zIndex: 1,
      fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
  }));
  
  function QontoStepIcon(props) {
    const { active, completed, className } = props;
    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          <Check className="QontoStepIcon-completedIcon" />
        ) : (
          <div className="QontoStepIcon-circle" />
        )}
      </QontoStepIconRoot>
    );
  }
  
  QontoStepIcon.propTypes = {
    active: PropTypes.bool,
    className: PropTypes.string,
    completed: PropTypes.bool,
  };
  //Conectores
  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 11,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          'linear-gradient( 95deg,#5076FF 0%, #5076FF 100%)',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          'linear-gradient( 95deg,#5076FF 0%, #5076FF 100%)',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      width:'80%',
      marginLeft:'10%',
      backgroundColor:
        "#414141",
      borderRadius: 1,
    },
  }));
  //Circulos
  const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 25,
    height: 25,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
      backgroundImage:
        'linear-gradient( 136deg, #5076FF 0%, #5076FF 100%)',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
      backgroundImage:
        'linear-gradient( 136deg, #5076FF 0%, #5076FF 100%)',
    }),
  }));
  //Iconos
  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;
  
    const icons = {
      1: <PersonIcon style={{width:'16px'}} />,
      2: <MailIcon style={{width:'16px'}}/>,
      3: <InsertDriveFileOutlinedIcon style={{width:'16px'}}/>,
      4: <SendIcon style={{width:'16px', transform:'rotate(-40deg)', marginTop:'-2px'}} />,
    };
  
    return (
      <ColorlibStepIconRoot ownerState={{ completed, active}} className={className}>
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }
  
  ColorlibStepIcon.propTypes = {
    active: PropTypes.bool,
    className: PropTypes.string,
    completed: PropTypes.bool,
    icon: PropTypes.node,
  };
  //Pasos
  const steps = [i18n.t('Indica el formato'),i18n.t('Indica el contenido'), i18n.t('Añade el destinatario'),  i18n.t('Presupuesto enviado')];


const SendSteps = ({ contactos,open, setShowPdf, activeStep, setActiveStep, capitulo, proposalTree, groupId, chaptersChecked, setOpen, soyFinal, selectedContacts, setSelectedContacts}) => {


    const [selectedRoles, setSelectedRoles] = useState([]);
    const [asunto, setAsunto] = useState('');
    const [title, setTitle] = useState('');
    const [tipo, setTipo] = React.useState(()=> soyFinal ? ['PDF'] : [])
    const [groupName, setGroupName] = useState('');
    const [typeBudget, setTypeBudget] = React.useState(soyFinal ? "PF" :"PC")
    const {projectInfo, getProject} = useContext(ProjectContext)
    const { sendBudget, sendChapters, loadingSend, sendOk, reset} = useContext(Budget2023Context)
    const { budgetTree, selectedBudget2, getSelectedBudget, getSelectedChapters, getBudgetTree, selectedBudget, selectedChaptersGroup, budgetTre, cleanChapters, cleanParts } =
      useContext(Budget2023Context)
    const refCont = useRef(null)
    const handleChange = newValue => {
      setValue(newValue)
    }
  
    const handleChangeTipo = (event, newTipo) => {
      if (newTipo !== null) {
        setTipo(newTipo)
      }
    }
    useEffect(() => {
      setTipo([])
      if(selectedRoles=='client'){
        setOptions({
          ...options,
          client:true
        })
      }else{
        setOptions({
          ...options,
          client:false
        })
      }
    }, [selectedRoles])

    const handleChangeTypeBudget = (event, newTypeBudget) => {
      if (newTypeBudget !== null) {
        setTypeBudget(newTypeBudget)
      }
    }

    useEffect(() => {
      if(!projectInfo){
        getProject(projectId)
      }
    }, [])
    const [message, setMessage] = useState('');
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [ocultaM, setOcultaM] = useState(false)
    const [ocultaP, setOcultaP] = useState(false)
    const { projectId } = useParams()
  const {addProposalToGroup, createGroup, getGroupInfo} = useContext(ProposalContext)
  const {user, getUser} = useContext(AuthContext)

  const handleOculta = (e, param) =>{
    e.preventDefault()
    if(param=='medicion'){
      if(ocultaM){
        setOcultaM(false)
      }else{
        setOcultaM(true)
      }
    }else{
      if(ocultaP){
        setOcultaP(false)
      
      }else{
        setOcultaP(true)

      }
    }
  }

  useEffect(() => {
    if(!user){
      getUser()
    }
  }, [user])

  const [openBlockModal, setOpenBlockModal] = useState(false)
  const [openError, setOpenError] = useState(false)
  const [errorToSend, setErrorToSend] = useState('')
  const handleSubmit = async e => {
    e.preventDefault()
    if(user['custom:subscription_plan']=='FREE_TRIAL'){
        setOpenBlockModal(true)
    }else{
      setLoading(true)
      if (selectedContacts.length !== 0) {
        for (let i = 0; i < selectedContacts.length; i++) {
          const res = await sendBudget({
            projectId: projectId,
            budgetTree:  chaptersChecked? selectedBudget2 : capitulo? [capitulo] : proposalTree? proposalTree : selectedBudget2,
            contact: selectedContacts[i],
            message: message,
            capitulo: capitulo? true : false,
            tipo: tipo,
            mediciones: ocultaM,
            precioObjetivo: ocultaP,
            options: options,
          })
         if(res?.error){
          setOpenError(true)
          setErrorToSend(res)
         }
          setTimeout(async() => {
            if(proposalTree){
              addProposalToGroup(groupId, projectId, res.proposalId)
            }else{
              const responseCreate = await createGroup(groupName!='' ? groupName : title, user.sub , projectId, res.proposalId)
              await getGroupInfo(projectId)
              //CUANDO DE TIEMPO CAMBIAR POR UN ADD AL GROUPPROPOSALS
            }
          }, 1000)
        }
        //cleanChapters()
        //cleanParts()
        setTimeout(() => {
          reset()
          if(capitulo){
            getBudgetTree(projectId)
          }
          setActiveStep(activeStep + 1);
          setLoading(false)
        }, 2000)
      }
    }
    
  }
  const [cont, setCont] = useState(0)
  useEffect(() => {
    if(selectedContacts.length !== 0){
    setSelectedContacts(selectedContacts)
  }
  }, [cont])

  const [options, setOptions] = useState({
    description:false,
    client:false,
    cost: false,
    showCover:false
  })
  useEffect(() => {
    if (selectedContacts.length > 0) {
      setShowPdf(true);
    } else {
      setShowPdf(false);
    }
  }, [selectedContacts.length]);

    return (
    <>
     
    <div style={{maxHeight:'100%', position:'relative', height:'100%',  justifyContent:'left', backgroundColor:'white', paddingLeft:'10%', paddingRight:'10%', paddingTop:'20px'}}>
            <HelveticaBold style={{fontWeight:'700',textAlign:'center', color:'#414141', fontSize:'18px'}}>
                {i18n.t('Enviar el presupuesto por email')}
            </HelveticaBold>
            <Stepper alternativeLabel activeStep={activeStep} style={{marginLeft:'-5%', marginTop:'30px'}} connector={<ColorlibConnector />}>
            {steps.map((label) => (
                <Step key={label}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                </Step>
            ))}
            </Stepper>
            <div style={{marginTop:'30px', height:'1px', backgroundColor:'#EBEBEB', marginLeft:'-10%', width:'120%'}}></div>
           {
            activeStep==0 ?
            <FirstStep 
            options={options} setOptions={setOptions}
             handleChangeTipo={handleChangeTipo} tipo={tipo} handleOculta={handleOculta} ocultaM={ocultaM} ocultaP={ocultaP}
              groupName={groupName} setGroupName={setGroupName} soyFinal={soyFinal} selectedRoles={selectedRoles} setSelectedRoles={setSelectedRoles}/>
            :
            activeStep==1 ?
            <SecondStep   title={title} setTitle={setTitle} asunto={asunto} setAsunto={setAsunto} message={message} setMessage={setMessage} files={files} setFiles={setFiles}/>
            :
            activeStep==2 ?
            <SecondStep2 soyFinal={soyFinal} setCont={setCont}  selectedContacts={selectedContacts} setSelectedContacts={setSelectedContacts} />
            : 
            activeStep==3 ?
            <FourthStep/>
            :
            null
           }
     </div>
     {loading?
      <div className="inline" style={{height:'auto', backgroundColor:'white', position:'fixed', bottom:'0px', marginLeft:'35px'}}>
        <CircularProgress size={24}/>
      </div>
    :
    <div className="inline" style={{position:'fixed', bottom:'0px', marginLeft:'35px', width:'100%'}}>
        <button onClick={()=>{activeStep==3 ? setOpen(false) : activeStep!=0 ?  setActiveStep(activeStep-1) : setOpen(false)}}  className="AddProjectPdf2" style={{width:'113px', marginLeft:'20px',  border: '2px solid #4461D7', background:'white', bottom:'12px'}}>
            { activeStep == 0 ? i18n.t("Cancelar") : activeStep==4 ? 'Cerrar' : 'Atrás'}
        </button>
        {(activeStep == 0 && (((tipo.length==0 || selectedRoles.provider==false && selectedRoles.another==false && selectedRoles.client==false))) ? 
          <button disabled  className='AddProjectPdf2' style={{width:'113px',color: 'white',  cursor:'default',marginLeft:'160px',  opacity:'0.5', background:'rgba(65, 65, 65, 0.5)', bottom:'12px'}} >
          { activeStep!=2 ? i18n.t("Siguiente") : i18n.t("Enviar")}
        </button>
        :
        (activeStep == 1 && (asunto=='' || title=='')) ?
        <button disabled  className='AddProjectPdf2' style={{width:'113px',color: 'white',  cursor:'default',marginLeft:'160px',  opacity:'0.5', background:'rgba(65, 65, 65, 0.5)', bottom:'12px'}} >
        { activeStep!=2 ? i18n.t("Siguiente") : i18n.t("Enviar")}
      </button>
      :
      (activeStep == 2 && selectedContacts.length==0)) ?
      <button disabled  className='AddProjectPdf2' style={{width:'113px',color: 'white',  cursor:'default',marginLeft:'160px',  opacity:'0.5', background:'rgba(65, 65, 65, 0.5)', bottom:'12px'}} >
      { activeStep!=2 ? i18n.t("Siguiente") : i18n.t("Enviar")}
      </button>  :
      activeStep==2 ? 
        <button onClick={(e)=>{handleSubmit(e)}} 
        data-amplify-analytics-on="click"
        data-amplify-analytics-name="BudgetSended"
        data-amplify-analytics-attrs={projectInfo}
        className='AddProjectPdf2' style={{width:'113px',color: 'white',  cursor:'pointer',marginLeft:'160px',  border: '2px solid #4461D7', background:'#4461D7', bottom:'12px'}} >
            { activeStep!=2 ? i18n.t("Siguiente") : i18n.t("Enviar")}
        </button>
        :
        activeStep==3 ?
        null :
        <button onClick={()=>{setActiveStep(activeStep+1)}}  className='AddProjectPdf2' style={{color: 'white',  cursor:'pointer',marginLeft:'160px',  border: '2px solid #4461D7', width: '113px', background:'#4461D7', bottom:'12px'}} >
        { activeStep!=2 ? i18n.t("Siguiente") : i18n.t("Enviar")}
    </button>
        }
    </div>
}
{
          openError && <ModalCallError open={openError} setOpen={setOpenError} error={errorToSend} ubicacion={'modal Send Presupuesto'}/>
        }
{
  openBlockModal && <BlockSendModal open={openBlockModal} setOpen={setOpenBlockModal}
  capitulo={capitulo} proposalTree={proposalTree} chaptersChecked={chaptersChecked}
  ocultaM={ocultaM} ocultaP={ocultaP} tipo={tipo} message={message}  selectedContacts={selectedContacts}  
  />
}
    </>
    )
}
 SendSteps.propTypes = {
  contactos: PropTypes.array.isRequired,
  activeStep: PropTypes.number.isRequired,
  setActiveStep: PropTypes.func.isRequired,
  capitulo: PropTypes.object,
  proposalTree:PropTypes.array,
  groupId:PropTypes.string,
  chaptersChecked:PropTypes.bool,
  setOpen:PropTypes.func,
  open:PropTypes.bool,
  soyFinal:PropTypes.bool,
  selectedContacts:PropTypes.array,
  setSelectedContacts:PropTypes.func,
  setShowPdf:PropTypes.func,
};

export default SendSteps;