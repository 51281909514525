import React, { useContext, useMemo, useState } from "react"
import PropTypes from "prop-types"
import ExtrasAndContradictionsContext from "context/extrasAndContradictions/ExtrasAndContradictionsContext"
import { partPropTypes } from "common/CPart"
import ArrowDownIcon from "assets/customIcons/ArrowDownIcon.svg"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import classNames from "classnames"
import Movement from "common/Movement.enum"

const RowPart = ({ part, index, chapterIndex }) => {
  const { partIdsChecked, dispatchs, tree } = useContext(
    ExtrasAndContradictionsContext
  )
  const {
    setPartId,
    setShowDeletePartModal,
    movePartsWithArrows,
    setOpenNewModalPart,
  } = dispatchs

  const [showActions, setShowActions] = useState(false)

  const isChecked = useMemo(
    () => partIdsChecked?.includes(part.partId),
    [partIdsChecked, part.partId]
  )

  const isTheLast = useMemo(() => {
    const currentChapter = tree.chapters.find(
      c => c.chapterId === part.chapterId
    )

    if (!currentChapter) {
      throw new Error("Chapter not found")
    }

    const length = currentChapter.parts.length - 1
    return length === index
  }, [index, part.chapterId, tree.chapters])

  return (
    <tr>
      <td className="d-flex align-items-center justify-content-center">
        <input
          type={"checkbox"}
          onChange={e => {
            e.stopPropagation()
            setPartId(part.partId)
          }}
          checked={isChecked}
          style={{
            width: "12px",
            marginRight: "5px",
            cursor: "pointer",
            marginTop: "-2px",
          }}
        />
        {`${chapterIndex + 1}.${part.position + 1}`}
      </td>
      <td
        colSpan="4"
        style={{ textAlign: "left", paddingLeft: "10px" }}
        className="cursorPointer"
        onClick={() => {
          part.ref = `${chapterIndex + 1}.${part.position + 1}`
          setOpenNewModalPart(true, part)
        }}
      >
        {part.name}
      </td>
      <td>{part.unity}</td>
      <td>{part.quantity}</td>
      <td>{part.price.toFixed(2)} €</td>
      <td>{parseFloat(part.price * part.quantity).toFixed(2)} €</td>
      <td>{part.clientPrice?.toFixed(2)} €</td>
      <td>{parseFloat(part.clientPrice * part.quantity).toFixed(2)} €</td>
      <td
        className="border-start-0 w-45px"
        onMouseEnter={() => setShowActions(true)}
        onMouseLeave={() => setShowActions(false)}
      >
        <div
          className={classNames(
            "float-end gap-2 p-2 justify-content-center align-items-center",
            {
              "d-none": !showActions,
              "d-flex": showActions,
            }
          )}
        >
          <DeleteOutlineIcon
            className="dark-grey w-16px cursorPointer"
            onClick={e => {
              e.stopPropagation()
              setShowDeletePartModal(part)
            }}
          />
          {!isTheLast && (
            <img
              src={ArrowDownIcon}
              className="dark-grey w-13px h-13px cursorPointer"
              onClick={e => {
                e.stopPropagation()
                movePartsWithArrows(part, Movement.DOWN)
              }}
            />
          )}

          {index > 0 && (
            <img
              src={ArrowDownIcon}
              className="dark-grey w-13px h-13px rotate180 cursorPointer"
              onClick={e => {
                e.stopPropagation()
                movePartsWithArrows(part, Movement.UP)
              }}
            />
          )}
        </div>
      </td>
    </tr>
  )
}

RowPart.propTypes = {
  part: partPropTypes,
  index: PropTypes.number.isRequired,
  chapterIndex: PropTypes.number.isRequired,
}

export default RowPart
