import React, {useContext, useState} from 'react'
import { ChapterContainer, } from './styled'
import PropTypes from 'prop-types'
import { KeyboardArrowLeftRounded, KeyboardArrowRightRounded } from '@mui/icons-material'
import TableStructure from 'components/PartSearcher/selectParts/tableStructureAuto'
import Part from './Part'
import { formatValue } from 'react-currency-input-field'
import { getCurrency } from "utils/functions"
import AuthContext from 'context/auth/authContext'

const Chapter= ({chapter, typeBudget, fetchChapter, marginLeft, width, selectedParts, setSelectedParts}) =>{

    const [subChapters, setSubChapters] = useState([])
    const [parts, setParts] = useState([])
    const {user} = useContext(AuthContext)
    const hasChilds = subChapters.length > 0 || parts.length > 0
    const [opened, setOpened] = useState(false)
    const [fetched, setFetched] = useState(false)
    const handleOpen = async() => {
        setOpened(true)
        if(!fetched){
            const response = await fetchChapter({
                projectId: chapter.projectId,
                parentId: chapter.chapterId,
                typeBudget: typeBudget
            }) 
            setSubChapters(response.chapters)
            setParts(response.parts)
        }
    }
    const handleClose = () => {
        setOpened(false)
        setSubChapters([])
        setParts([])
    }
    const format = (value) => {
        return formatValue({
            value: value.toFixed(2),
            groupSeparator: '.',
            decimalSeparator: ',',
            suffix:   
                getCurrency(user['custom:currency'])+
              ' ',
            decimalScale: 2,
            fixedDecimalScale: true,
        })
    }
    return(
        <>
            <ChapterContainer hasChilds={hasChilds} width={`${width}%`} marginLeft={`${marginLeft}px`} >
                {opened ? 
                    <div style={{borderRadius:'100px', border:'2px solid #FDEFC6', cursor: "pointer", marginLeft: "10px", marginTop:'8px', width:'20px', height:'20px', backgroundColor:'white'}}>
                        <KeyboardArrowLeftRounded  style={{ color:'#FDEFC6', paddingLeft:'2px', marginTop:'-6px' }}
                        className="dropdownArrowB"
                        onClick={handleClose}
                        /> 
                    </div>
                : 
                    <KeyboardArrowRightRounded
                    style={{ cursor: "pointer", marginLeft: "5px", marginTop:'5px' }}
                    className="dropdownArrowB"
                    onClick={handleOpen}
                    />
                }
                <p>{chapter.name}</p>
                <p style={{
                    position:'absolute',
                    right:'10px',

                }}>
                    {chapter.clientPrice ? format(chapter.clientPrice) : null}
                </p>
            </ChapterContainer>
            <div>
            {
                opened && subChapters.map((subchapter, index) => { 
                    return(
                        <Chapter 
                            chapter={subchapter} 
                            key={index}  
                            typeBudget={typeBudget}  
                            fetchChapter={fetchChapter}
                            width={width-1}
                            marginLeft={marginLeft+10} 
                            selectedParts={selectedParts}
                            setSelectedParts={setSelectedParts}   
                        />
                    )
                })
            
            }
            </div>
            <div>
                {
                    opened && parts.length > 0 ?
                    <TableStructure width={width} ml={marginLeft}>
                        {parts.map((part, index) => {
                            return(
                                <Part 
                                    part={part} 
                                    selectedParts={selectedParts}
                                    setSelectedParts={setSelectedParts}
                                    key={index}
                                />
                            )
                        })}
                    </TableStructure>: null
                }
            </div>

        </>

        )
    
}

Chapter.propTypes = {
    chapter: PropTypes.object.isRequired,
    typeBudget: PropTypes.string.isRequired,
    fetchChapter: PropTypes.func.isRequired,
    marginLeft: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    selectedParts: PropTypes.array.isRequired,
    setSelectedParts: PropTypes.func.isRequired,
}

export default Chapter
