import React, {useContext, useState} from "react";
import PropTypes from "prop-types";
import { HelveticaBold } from "common/typographies";
import {  KeyboardArrowRight, KeyboardArrowUp } from "@mui/icons-material";
import { YellowButton } from "common/buttons";
import cypeLogo from "assets/images/logos/cypeSelected.png"
import preocLogo from "assets/customIcons/preocVert.svg"
import bedecLogo from "assets/images/logos/bedecSelected.png"
import { getCurrency } from "utils/functions"
import AuthContext from "context/auth/authContext";

const Part = ({partidaState, setPartidaState, i, part}) => {

    const [openPart, setOpenPart] = useState(false)
    const {user} = useContext(AuthContext)
    const changePrice = (e) => {
        setPartidaState({...partidaState, price: parseFloat(part.price).toFixed(2)})
    }

    return(
        <div key={i} onClick={(e) => setOpenPart(!openPart)} style={{borderBottom:'1px solid #C4C4C4'}}>
            <div className="inline" style={{position:'relative'}}>
                <div className="inline" style={{width:'75%', marginTop:'5px', cursor: "pointer", }}>
                    {
                        !openPart ? 
                        (
                            <div>
                                <KeyboardArrowRight
                                style={{ marginLeft: "5px", marginTop:'6px',  }}
                                className="dropdownArrowB"
                                
                                />
                            </div>
                            ) : (
                            <KeyboardArrowUp
                                style={{ marginLeft: "5px", marginTop:'6px', }}
                                className="dropdownArrowB"
                            
                            />
                            )
                    }
                    <HelveticaBold style={{color:'#414141', fontSize:'12px',  marginLeft: "5px", marginTop:'9px'}}>
                        {part.name}
                    </HelveticaBold>
            </div>
            <div style={{width:'10%', position:'absolute', right:'30px',  marginLeft: "5px", marginTop:'15px'}}>
                    <HelveticaBold style={{color:'#414141', fontSize:'12px'}}>
                            {parseFloat(part.price).toFixed(2)}{ getCurrency(user['custom:currency'])}
                    </HelveticaBold>
            </div>
            <div style={{width:'5%', position:'absolute', right:'10px',  marginLeft: "5px", marginTop:'12px'}}>
                {part.reference=='cype' ?
                <img style={{width:'18px',  height:'18px', marginLeft:'2px'}} src={cypeLogo}/>
                :
                part.reference=='bedec' ?
                    <img style={{width:'18px', height:'18px',  marginLeft:'2px'}} src={bedecLogo}/>
                    :
              <img style={{width:'18px', height:'18px',  marginLeft:'2px'}} src={preocLogo}/>
                }
            </div>
            
            </div>

            <div>
                {
                    openPart &&
                    <div  style={{width:'70%', marginLeft:'40px', marginTop:'0px', cursor: "pointer", marginBottom:'5px' }}>
                    <HelveticaBold style={{color:'#414141', fontSize:'12px', textAlign:'justify',  marginLeft: "5px", marginTop:'0px', marginBottom: '10px'}}>
                        {part.type.toString().toUpperCase()}
                    </HelveticaBold>
                    <HelveticaBold style={{color:'#414141', fontSize:'12px', textAlign:'justify',  marginLeft: "5px", marginTop:'0px', marginBottom: '10px', fontWeight:400}}>
                        {part.description}
                    </HelveticaBold>
                    <YellowButton onClick={()=>{changePrice()}} style={{ width:'120px',textAlign:'center', height:'40px', marginBottom:'10px'}}>
                        <HelveticaBold style={{color:'#414141', fontSize:'14px', marginTop:'6px', fontWeight:600}}>
                            Cambiar precio
                        </HelveticaBold>
                    </YellowButton>
                    </div>
                }
            
            </div>
    </div>
    )

}

Part.propTypes = {
    partidaState: PropTypes.object.isRequired,
    setPartidaState: PropTypes.func.isRequired,
    i: PropTypes.number.isRequired,
    part: PropTypes.object.isRequired
}

export default Part