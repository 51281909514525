import React, { useState, useContext, useEffect } from "react"
import propTypes from "prop-types"
import { ChapterContainer } from "common/containers"
import { ChapterTypo, PoppinsNormal } from "common/typographies"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import i18n from "i18n"
import Capitulo from "./chapters/Capitulo"
import {ShowParentDiv } from "common/containers"
import ContactContext from "context/contact/contactContext"
import Budget2023Context from "context/budget2023/Budget2023Context"

const NotSendBudget = props => {
  const { budgetTree, loadingTree } = useContext(Budget2023Context)
  const [openNotSendBudget, setOpenNotSendBudget] = useState(false)
  const [showError, setShowError] = useState(0)
  const handleOpen = () => {
      setOpenNotSendBudget(prev => !prev)
  }
  const {getContacts} = useContext(ContactContext)
  useEffect(() => {getContacts()},[])

  useEffect(() => {
   if(budgetTree.subcapitulos.length>0){
    let a = 0;
     for(let i=0; i<budgetTree.subcapitulos.length;i++){
       if(budgetTree.subcapitulos[i].sended!='false'){
         a=a+1
       }
     }
     setShowError(a)
   }
  }, [loadingTree, openNotSendBudget])
  

  return (
    <>
      <PoppinsNormal style={{ fontWeight: "600", marginBottom: "32px" }}>
        {i18n.t("PROVEEDORES")}
      </PoppinsNormal>

      <ChapterContainer
        style={{
          backgroundColor: "#ECAE7D",
          color: "white",
          borderLeft: "7px solid #D29767",
        }}
      >
        <tr style={{ marginTop: "0px" }}>
          <td style={{ minWidth: "37px", paddingLeft: "3px" }}>
            {openNotSendBudget ? (
              <div>
                <KeyboardArrowDownIcon
                  style={{ cursor: "pointer", marginLeft: "3px" }}
                  className="dropdownArrowB"
                  onClick={handleOpen}
                />
              </div>
            ) : (
              <KeyboardArrowRightIcon
                style={{ cursor: "pointer", marginLeft: "3px" }}
                className="dropdownArrowB"
                onClick={handleOpen}
              />
            )}
          </td>
          <td style={{ minWidth: "300px" }}>
            <ChapterTypo style={{ marginLeft: "0px", color: "white" }}>
              {i18n.t("PRESUPUESTOS NO ENVIADOS")}
            </ChapterTypo>
          </td>
        </tr>
      </ChapterContainer>

      {openNotSendBudget ? (
        loadingTree ? (
          <>
            <div
              style={{
                justifyContent: "center",
                width: "100%",
                textAlign: "center",
                position: "relative",
                display: "flex",
                marginLeft: "-100px",
                marginTop: "5px",
              }}
            >
              <div className="loader">
                <div className="ball"></div>
                <div className="ball"></div>
                <div className="ball"></div>
              </div>
            </div>
            <div
              style={{
                justifyContent: "center",
                width: "100%",
                textAlign: "center",
                position: "relative",
                display: "flex",
                marginLeft: "-30px",
              }}
            >
              <PoppinsNormal>{i18n.t("Cargando...")}</PoppinsNormal>
            </div>
            <div
              style={{
                justifyContent: "center",
                width: "100%",
                textAlign: "center",
                position: "relative",
                display: "flex",
                marginLeft: "-30px",
                marginTop: "-15px",
              }}
            >
              <PoppinsNormal style={{ fontWeight: "400" }}>
                {i18n.t("Esta acción puede tardar unos segundos")}
              </PoppinsNormal>
            </div>
          </>
        ) : 
          showError==budgetTree.subcapitulos.length ?  
            <div
            style={{backgroundColor:'#FFF1E7', paddingLeft:'35px', height:'33px', width:'100%', borderRadius:'6px', marginTop:'-16px', paddingTop:'6px', fontFamily:'Roboto', fontWeight:'300', fontSize:'12px'}}
            > {i18n.t('Has enviado todos los elementos de este presupuesto')} </div>
            :
          <ShowParentDiv style={{borderLeft: "7px solid #D29767"}}>
            {budgetTree.subcapitulos.map((capitulo, i) => (
              capitulo.sended=="false" ?
              (
              <Capitulo key={capitulo.chapterId} i={i} capitulo={capitulo} />
              )
              : null
              ))}
           
          </ShowParentDiv>
        )
       : null}
            
    </>
  )
}

NotSendBudget.propTypes = {
  budgetTree: propTypes.object.isRequired,
}

export default NotSendBudget
