import React,{useEffect, useState, useContext} from "react";
import TemplatesContext from "context/templates/templatesContext";
import { Link } from "react-router-dom";
import AuthContext from "context/auth/authContext";
import i18n from "i18n";
import DropdownSettingsTemplate from "./dropDownSettingsTemplate";
import { YellowButton } from "common/buttons";
import { HelveticaBold } from "common/typographies";
import { CircularProgress } from "@mui/material";
import ProjectContext from "context/projects/projectContext";
import DropdownSettings from "components/Project/Overview/DropdownSettings";
import WaitingModal from "pages/Dashboard/WaitingModal";
import SuccessModal from "pages/Dashboard/SuccessModal";
import ModalSelectTypeProject from "pages/Dashboard/ModalSelectTypeProject";
import ContactContext from "context/contact/contactContext";
import PersonalModal from "pages/Dashboard/PersonalModal";
import ModalFirstProject from "pages/Dashboard/ModalFirstProject";
import UploadModal from "pages/Dashboard/UploadModal";


const TemplatesContent = () => {

    const {getTemplates, templates, loadingTemplates, selectedTemplate} = useContext(TemplatesContext)
    const {user, getUser, freeTrialExpired, openFreeTrialExpire} = useContext(AuthContext)
    const [cont, setCont] = useState(0)
    const [modal_standard, setModal_standard] = useState(false)
    const [modal_upload, setModal_upload] = useState(false)
    const [waitModal, setWaitModal] = useState(false)
    const [successModal, setSuccessModal] = useState(false)
    const onClickPersonalModal = () => setModal_standard(!modal_standard)
    const projectContext = useContext(ProjectContext)
    const [projectSeleccionado, setSeleccionado] = useState(null)
    const [modal_first, setModal_first] = useState(false)
    const [esPrimerProyecto, setFirstProject] = useState(false)
    const {Contacts} = useContext(ContactContext)
    const handleOpenNew = () => {
        setModalNew(true)
      }
      const [modalNew, setModalNew] = useState(false)

    useEffect(() => {
        if(!user){
            getUser()
        }else{
            if(cont === 0){
                getTemplates(user.sub)
                setCont(1)
            }
        }
    }, [user])

    useEffect(() => {
        if(freeTrialExpired){
            openFreeTrialExpire()
        }
    }, [freeTrialExpired])

    const coloresTemplates = ['#A1CFC6', '#FC6E6E','#FFE992']
    const [open, setOpen] = useState(false)
    const [openImportModal, setOpenImportModal] = useState(false)
    const [openModalImportSuccess, setOpenModalImportSuccess] = useState(false)
    const {templateProjects} = useContext(ProjectContext)
    return(
        <>
        {loadingTemplates ?  <>
                <div style={{ width:'100%', position:'absolute', transform:'translateY(-50%, -50%)',  top:'40%', left:'45%'}}>
                <CircularProgress
                    style={{ width: 150, height: 150, marginLeft:'0px', color:'#414141', marginBottom:'20px' }}
                />
                <HelveticaBold style={{color:'#414141', fontSize:'24px', marginLeft:'10px', marginTop:'0px'}}>{i18n.t("Cargando...")}</HelveticaBold>
                </div>
                   
            </> : (
        <>
                 {templates.length>0 ?
            <div style={{display:'flex', justifyContent:'right'}}>
                 <button type="button" onClick={handleOpenNew}
                                                className="AddContact" style={{height:'40px', width:'200px', marginRight:'20px'}}>{i18n.t('Nuevo Presupuesto')}</button>
                                               
            </div> 
            :null}
        <div style={{marginLeft:"10px",display:'flex', flexWrap:'wrap'}}>
     
            {templates.length>0 ?
            templates.map((template, index) => (
                    <div id="templateOverflow" key={(template.templateId ? template.templateId : '')} style={{position:'relative'}}>
                        <div className="CardProjects" style={{marginRight: index==templates.length-1 ? '0px' :'30px'}}>
                        <Link className="text-dark"
                                                  to={`/bancodeprecios/template/${template.templateId? template.templateId : ''}`}
                                            >
                            <div id="CardContent" style={{height:'100%', minHeight:'122px'}} >
                                <div className="ImgProyect">
                    <div style={{height:'70px', width:'70px', marginTop:'5px', maxWidth:'90px', marginLeft:'-5px', borderRadius:'100px', backgroundColor: index<2? coloresTemplates[index] : coloresTemplates[index%3]}} />
                    {template.accountId=='tribboo' && <p style={{fontSize:'36px', color:'white', fontWeight:'600', marginTop:'-60px', marginLeft:'19px'}}>T</p>}
                                </div>
                                <div id="CenterContentProyectCard">
                                    <div className="flex-grow-1 overflow-hidden">
                                        <div id="AddProyecto">
                                                {template.name}
                                        </div>
                                        <p className="DescriptionText">{template.description}</p>
                                    </div>
                                </div>
                            </div>
                            </Link>
                        </div>
                        <div style={{position:'absolute', right:'20px', top: template.favourite? template.description?.length>0? '85px' : '85px' : '85px', height:'0px'}}>
                            <DropdownSettingsTemplate imCard={true} template={template}/>
                            </div>
                      
                    </div>
                )):null}
                 {templateProjects.map((project, index) => (
                    
                   <div id="projectOverflow" key={(project.projectId)+index} style={{position:'relative'}}>
                   
                       <div className="CardProjects" >
                       <Link className="text-dark"
                                                 to={`/bancodeprecios/templateV2/${project.projectId}`}
                                   
                                           >
                           <div id="CardContent" style={{height:'100%', minHeight:'122px'}} >
                        
                               <div className="ImgProyect" style={{borderRadius:'100px'}}>
                              
                                   
                   <img src={project.projectImage}  style={{height:'90px', width:'90px', marginTop:'-5px', maxWidth:'90px', marginLeft:'-5px', borderRadius:'100px'}} />
       
           
                               </div>
                               
                               <div id="CenterContentProyectCard">
                                   <div className="flex-grow-1 overflow-hidden">
                                       <div id="AddProyecto">
                                               {project.name}
                                       </div>
                                       <p className="DescriptionText">{project.orgAddress}</p>
                                   </div>
                             
                               </div>
                             
                               
                               
                           </div>
                           </Link>
                       </div>
                       <div className="DropdownContainer" style={{position:'absolute', right:'20px', top: project.favourite? project.orgAddress.length>0? '85px' : '85px' : '85px', height:'0px'}}>
                           <DropdownSettings imCard={true} project={project}/>
                           </div>
                   </div>
                   
               ))}
           
             
        </div>
        </>)}
        {modal_standard && <PersonalModal
            modal_standard={modal_standard}
            setmodal_standard={setModal_standard}
            setModalUpload={setModal_upload}
            contactos={Contacts}
            setSeleccionado={setSeleccionado}
            modal_first={modal_first}
            setmodal_first={setModal_first}
            imTemplate={true}
          />}

    { modal_first &&    <ModalFirstProject
            modal_first={modal_first}
            imTemplate={true}
            setmodal_first={setModal_first}
              setModalUpload={setModal_upload}
              contactos={Contacts}
              setSeleccionado={setSeleccionado}
              setFirstProject={setFirstProject}/>}



         {modal_upload &&   <UploadModal 
              openModal={modal_upload} 
              setOpenModal={setModal_upload}
              setWaitModal={setWaitModal}
              proyecto={projectSeleccionado}
              setSuccessModal={setSuccessModal}
            />}
        {waitModal ?
            <WaitingModal imTemplate={true} openModal={waitModal} setOpenModal={setWaitModal} setSuccessModal={setSuccessModal} setSeleccionado={setSeleccionado} project={projectSeleccionado}/> : null}
           { successModal && <SuccessModal imTemplate={true} setSuccessModal={setSuccessModal} successModal={successModal} project={projectSeleccionado}/>}

          <ModalSelectTypeProject modalNew={modalNew} setModalNew={setModalNew} setmodal_first={setModal_first} onClickPersonalModal={onClickPersonalModal}/>
          

        </>
    )
}

export default TemplatesContent;