import React, {useContext, useState } from "react"
import PropTypes from "prop-types"
import i18n from "i18n"
import AuthContext from "context/auth/authContext"
import ModalSearchParts from "components/PartSearcherTemplates/default"
import TemplatesContext from "context/templates/templatesContext"
import { getCurrency } from "utils/functions"

const TableStructureBank = ({ children, imParent, parts, capitulo}) => {
    const [openPart, setOpenPart] = useState(false)
    const {user, freeTrialExpired} = useContext(AuthContext)
    const {templateInfo} = useContext(TemplatesContext)
    const createPart = () => {

      setOpenPart(true)
    }
    return(
      <>
        <div id="table-wrapper">
          <div id="table-scroll">
        <table className='tablaPartidas' style={{    marginLeft: imParent ? "0px" : "-8px",
        width: imParent ? "100%" : "100.5%",
        marginTop: imParent ? "0px" : "-10px",
        marginBottom : imParent ? "0px" : "-10px",}}>
          <tbody>
                  <tr style={{borderBottom:  ' solid #E9ECEF'}}>
                  <th style={{minWidth:'78px', paddingLeft:'15px', borderLeft:'none'}}></th>
                  <th style={{minWidth:'300px', textAlign:'left', paddingLeft:'57px', borderLeft:'none', width:'100%'}}></th>
                  <th  style={{minWidth:'86px', backgroundColor:'white', color:'#414141'}}></th> 
                  <th colSpan='2' style={{minWidth:'300px', borderLeft:'1px solid #b7b7b7'}}>{i18n.t('Precio Total')}</th>
                  <th style={{minWidth:'35px', maxWidth:'35px', color:'#414141', borderLeft:'none'}}></th>
              </tr>
   
  
            <tr id='subCategory'>
            <td style={{borderLeft:'none', width:'79px', color:'#B7B7B7'}}>{i18n.t("Unidad")}</td>
            <td style={{ textAlign:'left', paddingLeft:'35px'}}>{i18n.t("Descripción")}</td>
            
              <td style={{minWidth:'86px', backgroundColor:'white', color:'#414141'}}>Medición</td>
              <td style={{minWidth:'86px', borderLeft: '1px solid #b7b7b7', backgroundColor:'white', color:'#414141'}}>{  getCurrency(user['custom:currency'])+i18n.t("/u")}</td>
            <td style={{minWidth:'127px', color:'#414141', backgroundColor:'white', borderLeft:'none'}}>Total</td>
            <td style={{minWidth:'35px', maxWidth:'35px', color:'#414141', borderLeft:'none'}}></td>
        </tr>
         
            {children}
     
            <tr id='lastPartida'>
                <td colSpan='3' style={{borderBottom:'none', textAlign:'left'}} >
                {
                  templateInfo.accountId == 'tribboo' ? null :
                            user ? 
                            freeTrialExpired ||  user['custom:role'] === 'WORKER' ? null : 
                <div className="NombreAlign" style={{left:'5px', justifyContent:'left'}} onClick={()=>{ createPart() }}>
                        <button 
                        data-amplify-analytics-on="click"
                        data-amplify-analytics-name="AddNewPartTemplates"
                        data-amplify-analytics-attrs=""
                        className="nuevoBotonCrear" style={{marginTop:'10px', marginBottom:'10px'}}>
                          + {i18n.t("Buscar o crear nueva partida")}
                        </button>
                    </div> : null }
                </td>
                </tr> 
                </tbody>
        </table>
        </div>
        </div>
        {openPart &&  <ModalSearchParts open={openPart} setOpen={setOpenPart} capitulo={capitulo} /> }

         </>
    )
}

TableStructureBank.propTypes = {
  children: PropTypes.node.isRequired,
  imParent: PropTypes.bool,
  parts: PropTypes.array,
  capitulo: PropTypes.object,
}

export default TableStructureBank
