import { HelveticaBold } from "common/typographies";
import React, { useContext } from "react";
import i18n from "i18n";
import { useHistory } from "react-router-dom";
import propTypes from "prop-types";
import CardFiles from "../CardFiles";
import FileContext from "context/file/fileContext";


const RecentFiles = ({selectedDocument ,setSelectedDocument}) => {

    const history = useHistory();
    const {files} = useContext(FileContext);
 
    return (
        <div style={{height:'210px', position:'fixed', bottom:'15px', width:'100%', zIndex:'9', background:'#EFEFEF'}}>
               <HelveticaBold id="capitalize" style={{color:'#414141', fontSize:'14px', marginBottom:'0px', width:'100%'}}>
                        {i18n.t("Archivos recientes")}
                </HelveticaBold>             
                <div className="inline" style={{width:'91.5%', paddingLeft:'10px', paddingTop:'15px', overflowX:'scroll', marginTop:'10px', backgroundColor:'white', height:'85%', borderRadius:'6px'}}>
                    <CardFiles files={files.sort(function(a,b){return new Date(b.updatedAt) - new Date(a.updatedAt)})} selectedDocument={selectedDocument} setSelectedDocument={setSelectedDocument} />
                </div>
 
        </div>
    )
}

RecentFiles.propTypes = {
    selectedDocument: propTypes.array,
    setSelectedDocument: propTypes.func,
};


export default RecentFiles;