import PropTypes from "prop-types"
import CPart, { PartPropTypes } from "./CPart"
import Direction from "./Direction.enum"

class CChapter {
  constructor({
    accountId,
    chapterId,
    chapters_count,
    clientPrice,
    clientPriceWithoutDiscount,
    createdAt,
    name,
    organizationId,
    parentId,
    partidas_count,
    position,
    price,
    projectId,
    selfManagement,
    sended,
    updatedAt,
    partidas,
  }) {
    this.accountId = accountId
    this.chapterId = chapterId
    this.chaptersCount = chapters_count
    this.clientPrice = clientPrice
    this.clientPriceWithoutDiscount = clientPriceWithoutDiscount
    this.createdAt = createdAt
    this.name = name
    this.organizationId = organizationId
    this.parentId = parentId
    this.partsCount = partidas_count
    this.position = position
    this.price = price
    this.projectId = projectId
    this.selfManagement = selfManagement
    this.sended = sended
    this.updatedAt = updatedAt
    this.parts = partidas
      .sort((a, b) => a.position - b.position)
      .map(p => new CPart(p))
  }

  /**
   * Updates the positions of all parts in the chapter.
   *
   * This method sorts the parts based on their current position
   * and reassigns new positions according to their order in the list,
   * ensuring that the positions are contiguous and start from zero.
   */
  reassignPartPositions = () => {
    this.parts = this.parts
      .sort((a, b) => a.position - b.position) // Sort parts by their current position
      .map((part, index) => {
        part.position = index
        return part
      })
  }

  /**
   * Removes a part from a specific chapter.
   *
   * @param {string} chapterId - The identifier of the chapter from which the part will be removed.
   * @param {string} partId - The identifier of the part to be removed.
   */
  removePart = (chapterId, partId) => {
    if (this.chapterId === chapterId) {
      this.parts = this.parts.filter(part => part.partId !== partId) // Remove the specified part
    }
  }

  /**
   * Retrieves the next or previous part based on the provided partId and direction.
   *
   * @param {string} partId - The identifier of the current part.
   * @param {string} direction - The direction to search (Direction.NEXT or Direction.PREVIOUS).
   * @returns {CPart|null} - The next or previous part, or null if not found.
   */
  getAdjacentPart = (partId, direction) => {
    const parts = this.parts
    const currentPart = parts.find(part => part.partId === partId)

    if (!currentPart) {
      throw new Error(
        `Part with ID ${partId} not found in the chapter with ID ${chapterId}`
      )
    }

    const currentIndex = parts.indexOf(currentPart)

    switch (direction) {
      case Direction.NEXT:
        return currentIndex < parts.length - 1 ? parts[currentIndex + 1] : null
      case Direction.PREVIOUS:
        return currentIndex > 0 ? parts[currentIndex - 1] : null
      default:
        throw new Error(
          "Direction is required and must be either 'next' or 'previous'."
        )
    }
  }
}

export default CChapter

const ChapterPropTypes = PropTypes.shape({
  accountId: PropTypes.string,
  chapterId: PropTypes.string,
  chaptersCount: PropTypes.number,
  clientPrice: PropTypes.number,
  clientPriceWithoutDiscount: PropTypes.number,
  createdAt: PropTypes.string,
  name: PropTypes.string,
  organizationId: PropTypes.string,
  parentId: PropTypes.string,
  partsCount: PropTypes.number,
  position: PropTypes.number,
  price: PropTypes.number,
  projectId: PropTypes.string,
  selfManagement: PropTypes.string, // TODO: check this
  sended: PropTypes.string, // TODO: check this
  updatedAt: PropTypes.string,
  parts: PropTypes.arrayOf(PartPropTypes),
})

export { ChapterPropTypes }
