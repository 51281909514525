import { PoppinsNormal } from "common/typographies";
import i18n from "i18n";
import React, { useContext, useState, useEffect} from "react";
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
import AuthContext from "context/auth/authContext";
import ModalSelectProject from "./modals/modalSelectProject";
import ModalSuccessFav from "./modals/modalSuccessFav";
import { CircularProgress } from "@mui/material";
import FavoritesBPContext from "context/favoritesBP/favoritesBPContext";
import StarHalfOutlinedIcon from '@mui/icons-material/StarHalfOutlined';
import PropTypes from 'prop-types'
import ModalSelectChapter from "./modals/addToMyBP/selectChapter";




const TopQuickMenu4 = ({tipo}) => {

    const [open, setOpen] = useState(false);
    const {categoriesChecked, partsChecked, deleteCapitulo, deleteBankPart, cleanParts, cleanChapters, myPricesBank, selectedCategories2, getSelectedChaptersToFav, loadingCaps, getSelectedChapters} = useContext(FavoritesBPContext)
    const {user, freeTrialExpired} = useContext(AuthContext)
    const [openModalMYBP, setOpenModalMYBP] = useState(false)

    const [openSuccess, setOpenSuccess] = useState(false);
    const [Iclick, setIClick] = useState(false);
   const handleAddToFavorites = async() => {
       await getSelectedChaptersToFav([],[], myPricesBank.subcapitulos, true, user.sub)
        setIClick(true)
   }

   useEffect(()=>{
        getSelectedChapters([],[], myPricesBank.subcapitulos, true)
  },[categoriesChecked.length])

   useEffect(() => {
    if(Iclick && loadingCaps===false){
        setOpenSuccess(true)
        setIClick(false)
    }
   }, [Iclick, loadingCaps])
 
    return(
        <>
    <div style={{position:'fixed', top:'0px', left:'0px', width:'100%', height:'47px', backgroundColor:'#FFD52B', zIndex:'999', paddingTop:'15px'}}>
        <div className="inline">
            <div style={{marginLeft:'10%'}} className="inline">
                <td className="tdcheckColumn">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={true}
                    id="flexCheckDefault"
                    style={{ width: "13px", height: "13px", cursor:'pointer', zIndex:"5" }}
                  ></input>
                </td>   
                    {categoriesChecked.length>0 ?
                    categoriesChecked.length==1 ? 
                <PoppinsNormal>{categoriesChecked.length}{" "}{i18n.t("capitulo seleccionado")} </PoppinsNormal>
                :
                <PoppinsNormal>{categoriesChecked.length}{" "}{i18n.t("capitulos seleccionados")} </PoppinsNormal>
                : partsChecked.length>0 ?
                partsChecked.length==1 ?
                <PoppinsNormal>{partsChecked.length}{" "}{i18n.t("partida seleccionada")} </PoppinsNormal>
                :
                <PoppinsNormal>{partsChecked.length}{" "}{i18n.t("partidas seleccionadas")} </PoppinsNormal>
                :
                null
                    }
            </div>
            {user ? (user['custom:role']==='WORKER' || freeTrialExpired) ? 
                    <div style={{justifyContent:'center', width:'100%', textAlign:'center', position:'absolute', display:'flex', width:'100%', marginLeft:'auto', marginRight:'auto'}}>
                    {tipo=="USER_DATABASE" ? <>
                    <BookOutlinedIcon
                    className="primerIconoShow" style={{cursor:'', color:'#C4C4C4', width:'24px', marginRight:'3px'  }}/>
                    
                    </>:
                    <>
                    <BookOutlinedIcon
                   
                    className="primerIconoShow" style={{cursor:'pointer', color:'#C4C4C4', width:'24px', marginRight:'3px'  }}/>
                   
                    </>
                    }
                    
                    <CreateNewFolderOutlinedIcon  className="segundoIconoShow" style={{cursor:'pointer', color:'#C4C4C4', width:'24px', marginRight:'3px'  }}/>
                   
                    {loadingCaps && Iclick ? <CircularProgress style={{marginTop:'3px'}} size={16}/> : <>
                    <StarHalfOutlinedIcon
                 
                    className="tercerIconoShow" style={{color:'#C4C4C4', width:'24px', marginRight:'3px', cursor:'pointer'  }}/>
                   
                    </>}
                    
                    </div>
            :
            <div style={{justifyContent:'center', width:'100%', textAlign:'center', position:'absolute', display:'flex', width:'100%', marginLeft:'auto', marginRight:'auto'}}>
                {tipo=="USER_DATABASE" ? <>
                <BookOutlinedIcon
                className="primerIconoShow" style={{cursor:'', color:'#C4C4C4', width:'24px', marginRight:'3px'  }}/>
                
                </>:
                <>
                 <BookOutlinedIcon
                onClick={()=>{setOpenModalMYBP(true)}}
                className="primerIconoShow" style={{cursor:'pointer', color:'#414141', width:'24px', marginRight:'3px'  }}/>
                <div className="primerIcono" style={{position: 'absolute', marginLeft:'-55px', marginTop:'35px'}}>
                    <div className="recuadro" style={{width:'150px'}}>
                            <div className="recuadroPico"></div>
                            {i18n.t("Añadir a mi banco de precios")}
                    </div>
              </div>
                </>
    }
               
                <CreateNewFolderOutlinedIcon onClick={()=>{setOpen(true)}} className="segundoIconoShow" style={{cursor:'pointer', color:'#414141', width:'24px', marginRight:'3px'  }}/>
                <div className="segundoIcono" style={{position: 'absolute', marginLeft:'0px', marginTop:'35px'}}>
                    <div className="recuadro">
                            <div className="recuadroPico"></div>
                            {i18n.t("Añadir a proyecto")}
                    </div>
              </div>
              {loadingCaps && Iclick ? <CircularProgress style={{marginTop:'3px'}} size={16}/> : <>
                <StarHalfOutlinedIcon
                 onClick={async()=>{handleAddToFavorites()}}
                className="tercerIconoShow" style={{color:'#414141', width:'24px', marginRight:'3px', cursor:'pointer'  }}/>
                <div className="tercerIcono" style={{position: 'absolute', marginLeft:'55px', marginTop:'35px'}}>
                    <div className="recuadro">
                            <div className="recuadroPico"></div>
                            {i18n.t("Quitar de favoritos")}
                    </div>
              </div>
              </>}
               
            </div>
        : null }
         
        </div>
        
    </div>
    <ModalSelectProject open={open} setOpen={setOpen} tipo={tipo}/>
    <ModalSuccessFav open={openSuccess} setOpen={setOpenSuccess}/>
    <ModalSelectChapter open={openModalMYBP} setOpen={setOpenModalMYBP} tipo={tipo}/>
    </>
    )
}

TopQuickMenu4.propTypes = {
    tipo: PropTypes.string.isRequired
}

export default TopQuickMenu4;