import i18n from "i18n";
import { Modal} from "@mui/material";
import {makeStyles} from "@material-ui/core/styles";
import React, { useContext, useState } from "react";
import PropTypes from 'prop-types'

import { HelveticaBold } from "common/typographies";


function getModalStyle() {
    const top = 50 ;
    const left = 50;
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };

}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: '550px',
        backgroundColor: 'white',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        borderRadius: 6,
        maxHeight: 'auto',
        overflowY: 'auto',
        overflowX:'hidden',
        paddingLeft:'0px',
        paddingRight:'0px'
    },
}));

const ModalInstructions = ({setOpen, open}) => {
    const [ modalStyle ] = useState(getModalStyle)
    const classes = useStyles();


  return(
      <>
    <Modal
        open={open}
        onClose={() => {setOpen(false)}}
    >
        <div style={modalStyle} className={classes.paper}>

        <button
            type="button"
            onClick={() => {
                setOpen(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
        >
            <span aria-hidden="true">&times;</span>
        </button>
        <div className="modal-body">
        <div>
            <div>
                <p
                    style={{
                    fontSize: '20px',
                    fontWeight: '500',
                    textAlign: 'center',
                    fontFamily: 'Roboto', marginBottom:'5px'
                    }}

                >{i18n.t(`Para mejorar calidad de tu logo, puedes usar esta herramienta, completamente gratuita.`)}</p>
                            <p
                                style={{
                                fontSize: '14px',
                                fontWeight: '400',
                                textAlign: 'left',
                                fontFamily: 'Roboto',
                                }}

                            >{i18n.t(`Pasos`)}</p>
                                    <p
                                                            style={{
                                                            fontSize: '14px',
                                                            fontWeight: '400',
                                                            textAlign: 'left',
                                                            fontFamily: 'Roboto',
                                                            }}

                                                        >Acceder a: <a target={'_blank'} rel="noreferrer" href='https://create.pixelcut.ai/upscaler'>https://create.pixelcut.ai/upscaler</a>
                                                    </p>
                                                    <p        style={{
                                                                                                                   fontSize: '14px',
                                                                                                                   fontWeight: '400',
                                                                                                                   textAlign: 'left',
                                                                                                                   fontFamily: 'Roboto',
                                                                                                                   marginBottom: '0px'
                                                                                                                   }}>
                                                         1- Subir el logo. </p>
                                                         <p        style={{
                                                                                                                        fontSize: '14px',
                                                                                                                        fontWeight: '400',
                                                                                                                        textAlign: 'left',
                                                                                                                        fontFamily: 'Roboto',
                                                                                                                        marginBottom:'0px'
                                                                                                                        }}> 2- Seleccionar 4x.  </p>
                                                       <p        style={{
                                                                                                                      fontSize: '14px',
                                                                                                                      fontWeight: '400',
                                                                                                                      textAlign: 'left',
                                                                                                                      fontFamily: 'Roboto',
                                                                                                                      }}>  3- Descargarlo. Es gratis para 1 logo. Lo tendrás en tu carpeta de descargas. </p>

                             <p        style={{
                           fontSize: '14px',
                           fontWeight: '400',
                           textAlign: 'left',
                           fontFamily: 'Roboto',
                           }}>   Si necesitas ayuda, no dudes en contactarnos. Nos envías el logo a <a style={{color:'#51cbce'}}>soporte@planhopper.com</a> y lo hacemos por ti.</p>

            </div>


            </div>
        </div>
        </div>
    </Modal>
    </>
  )
}
ModalInstructions.propTypes = {
    setOpen : PropTypes.func.isRequired,
    open : PropTypes.bool.isRequired,

}
export default ModalInstructions