import React, { useContext, useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import i18n from "i18n"
import { useHistory } from "react-router-dom"

const LayoutBancoRef = ({tipo, referencia, setTipo
}) => {

  return (
    <>

        <div
          style={{
         justifyContent: "right",
         marginRight: "30px",
            display: "flex",
            paddingTop:'10px'
          }}
        >
            {referencia=='cype' ?
                <div>
                <div>
                      <button
                        id="costPressFin"
                        style={{
                          backgroundColor: tipo=="Rehabilitación" ? '#FFD52B': "#EAEAEA",
                          border: "0px",
                          marginRight: "-1px",
                          zIndex: tipo=="Rehabilitación" ? "1" : "0",
                          width:'116px',
                          borderTopRightRadius: tipo=="Rehabilitación" ? "6px" : "0px",
                          borderBottomRightRadius: tipo=="Rehabilitación" ? "6px" : "0px",
                        }}
                        onClick={() => {setTipo('Rehabilitación')}}     
                      >
                        {i18n.t("Rehabilitación")}
                      </button>
                      <button
                        id="costPress"
                        style={{
                          backgroundColor: tipo=="Obra nueva" ? '#FFD52B': "#EAEAEA",
                          border: "0px",
                          marginRight: "-1px",
                          zIndex: tipo=="Obra nueva" ? "1" : "0",
                          width:'116px',
                          borderRadius: tipo=="Obra nueva" ? "6px" : "0px",
                        }}
                        onClick={() => {setTipo('Obra nueva')}}     
                      >
                        {i18n.t("Obra Nueva")}
                      </button>
                      <button
                            id="costPress"
                            style={{
                            backgroundColor: tipo=="Espacios urbanos" ? '#FFD52B': "#EAEAEA",
                            border: "0px",
                            zIndex: tipo=="Espacios urbanos" ? "1" : "0",
                            marginRight: "0px",
                            width:'140px',
                            borderTopLeftRadius: tipo=="Espacios urbanos" ? "6px" : "0px",
                            borderBottomLeftRadius: tipo=="Espacios urbanos" ? "6px" : "0px",

                            
                            }}
                            onClick={() => {setTipo('Espacios urbanos')}}     
                      >
                        {i18n.t("Espacios Urbanos")}
                      </button>
                   
          
                </div>
              </div> :
              referencia=='bedec' ?
              <div>
              <div>
                    <button
                      id="costPressFin"
                      style={{
                        backgroundColor: tipo=="PARTIDAS DE OBRA Y CONJUNTOS" ? '#FFD52B': "#EAEAEA",
                        border: "0px",
                        width: "fit-content",
                        marginRight: "-1px",
                        zIndex: tipo=="PARTIDAS DE OBRA Y CONJUNTOS" ? "1" : "0",
                        width:'200px',
                        borderTopRightRadius: tipo=="PARTIDAS DE OBRA Y CONJUNTOS" ? "6px" : "0px",
                        borderBottomRightRadius: tipo=="PARTIDAS DE OBRA Y CONJUNTOS" ? "6px" : "0px",
                      }}
                      onClick={() => {setTipo('PARTIDAS DE OBRA Y CONJUNTOS')}}     
                    >
                      {i18n.t("Partidas de obra y conjuntos")}
                    </button>
                    <button
                      id="costPress"
                      style={{
                        backgroundColor: tipo=="MAQUINARIA" ? '#FFD52B': "#EAEAEA",
                        border: "0px",
                        marginRight: "-1px",
                        zIndex: tipo=="MAQUINARIA" ? "1" : "0",
                        width:'116px',
                        borderRadius: tipo=="MAQUINARIA" ? "6px" : "0px",
                      }}
                      onClick={() => {setTipo('MAQUINARIA')}}    
                    >
                      {i18n.t("Maquinaria")}
                    </button>
                    <button
                          id="costPress"
                          style={{
                            width: "fit-content",
                          backgroundColor: tipo=="MATERIALES Y COMPUESTOS" ? '#FFD52B': "#EAEAEA",
                          border: "0px",
                          zIndex: tipo=="MATERIALES Y COMPUESTOS" ? "1" : "0",
                          marginRight: "-1px",
                          width:'200px',
                          borderRadius: tipo=="MATERIALES Y COMPUESTOS" ? "6px" : "0px",

                          
                          }}
                          onClick={() => {setTipo('MATERIALES Y COMPUESTOS')}}    
                    >
                      {i18n.t("Materiales y compuestos")}
                    </button>
                    <button
                          id="costPress"
                          style={{
                          backgroundColor: tipo=="MANO DE OBRA" ? '#FFD52B': "#EAEAEA",
                          border: "0px",
                          zIndex: tipo=="MANO DE OBRA" ? "1" : "0",
                          marginRight: "0px",
                          width:'116px',
                          borderTopLeftRadius: tipo=="MANO DE OBRA" ? "6px" : "0px",
                          borderBottomLeftRadius: tipo=="MANO DE OBRA" ? "6px" : "0px",
                          
                          }}
                          onClick={() => {setTipo('MANO DE OBRA')}}    
                    >
                      {i18n.t("Mano de obra")}
                    </button>                 
        
              </div>
            </div>
              :referencia=='preoc' ? 
              <div>
              <div>
                    <button
                      id="costPressFin"
                      style={{
                        backgroundColor: tipo=="PRECIOS UNITARIOS" ? '#FFD52B': "#EAEAEA",
                        border: "0px",
                        marginRight: "-1px",
                        zIndex: tipo=="PRECIOS UNITARIOS" ? "1" : "0",
                        width:'140px',
                        borderTopRightRadius: tipo=="PRECIOS UNITARIOS" ? "6px" : "0px",
                        borderBottomRightRadius: tipo=="PRECIOS UNITARIOS" ? "6px" : "0px",
                      }}
                      onClick={() => {setTipo('PRECIOS UNITARIOS')}}     
                    >
                      {i18n.t("Precios Unitarios")}
                    </button>
                    <button
                      id="costPress"
                      style={{
                        backgroundColor: tipo=="PRECIOS AUXILIARES" ? '#FFD52B': "#EAEAEA",
                        border: "0px",
                        marginRight: "-1px",
                        zIndex: tipo=="PRECIOS AUXILIARES" ? "1" : "0",
                        width:'140px',
                        borderRadius: tipo=="PRECIOS AUXILIARES" ? "6px" : "0px",
                      }}
                      onClick={() => {setTipo('PRECIOS AUXILIARES')}}     
                    >
                      {i18n.t("Precios Auxiliares")}
                    </button>
                    <button
                          id="costPress"
                          style={{
                          backgroundColor: tipo=="PRECIOS DESCOMPUESTOS" ? '#FFD52B': "#EAEAEA",
                          border: "0px",
                          zIndex: tipo=="PRECIOS DESCOMPUESTOS" ? "1" : "0",
                          marginRight: "0px",
                          width:'180px',
                          borderTopLeftRadius: tipo=="PRECIOS DESCOMPUESTOS" ? "6px" : "0px",
                          borderBottomLeftRadius: tipo=="PRECIOS DESCOMPUESTOS" ? "6px" : "0px",
                          
                          }}
                          onClick={() => {setTipo('PRECIOS DESCOMPUESTOS')}}     
                    >
                      {i18n.t("Precios Descompuestos")}
                    </button>
                 
        
              </div>
            </div>
              :null
            }
          
        </div>

    </>
  )
}
LayoutBancoRef.propTypes = {
  tipo: PropTypes.string,
    referencia: PropTypes.string,
    setTipo: PropTypes.func
}
export default LayoutBancoRef
