import React, { useContext, useEffect } from 'react'
import { Modal} from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom"
import PropTypes from 'prop-types'
import { useState } from 'react';
import i18n from 'i18n';
import CloseIcon from "@mui/icons-material/Close";
import { VersionsTable } from 'common/tables';
import TemplatesContext from 'context/templates/templatesContext';
import AuthContext from 'context/auth/authContext';
import {CapituloContainer, ShowChildDiv} from 'common/containers'
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import { PoppinsNormal } from 'common/typographies';
import ProjectContext from 'context/projects/projectContext';
import ModalWaiting from './modalWaiting';

function getModalStyle() {
    const top = 50 ;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: '850px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        borderRadius: 6,
        height: '550px',
        maxHeight: '550px',
        overflowY: 'auto',
        overflowX:'hidden',
    },
}));


const ModalSelectPlantilla = ({open, setOpen, imInBudget}) => {

    const [ modalStyle ] = useState(getModalStyle);
    const classes = useStyles();
    const templateContext = useContext(TemplatesContext);
    const {user, getUser, freeTrialExpired} = useContext(AuthContext)
    const { getTemplates, templates} = templateContext;
    const [templateSelected, setTemplateSelected] = useState({});
    const {importPlantilla2Project, createProject, projects, selectedProject, createProjectWithTemplate, projectInfo, createProcess} = useContext(ProjectContext)

    useEffect(() => {
        if(!user){
            getUser()
        }
        else{
            getTemplates(user.sub)
        }
    }, [user])
    const [openWaiting, setOpenWaiting] = useState(false)
    const coloresTemplates = ['#A1CFC6', '#FC6E6E','#FFE992']
    const [openTribboo, setOpenTribboo] = useState(false);
    const handleOpen = () => {
        setOpenTribboo(!openTribboo);
    };
    const [openMine, setOpenMine] = useState(false);
    const handleOpen2 = () => {
        setOpenMine(!openMine);
    };
    const history = useHistory()

    const [loading, setLoading] = useState(false);

    const handleClick = async(template, index) => {
        setLoading(true)
        setOpenWaiting(true)
        setTemplateSelected(template)
        if(imInBudget){
          const res = await importPlantilla2Project(projectInfo.projectId, template.templateId)
          setLoading(false)
        }else{
          const res = await createProjectWithTemplate({
            name: template.name,
            description: template.description,
            identifierNumber: '2022_'+projects.length,
            projectImage: "https://d1t181n48swd5d.cloudfront.net/imgProject/00_DEFAULT.png",
            orgAddress: '',
            client: '',
            tags: [],
            templateId: template.templateId,
          })
          history.push(`/budgeting/${res.projectId}`)
        }
        
        

    }
    return(
        <>
        <Modal open={open} onClose={() => null}>
            <div style={modalStyle} className={classes.paper}>
                <div id='principalOptions' style={{marginTop:'20px', marginBottom:'15px', textAlign:'left'}}>
                    <CloseIcon id="cerrar" style={{marginTop:'-15px', right:'-10px'}} onClick={()=>{setOpen(false)}}></CloseIcon>
                    <p id='titles' style={{textAlign:'CENTER', fontSize:'20px', marginBottom:"35px"}}>{i18n.t("Selecciona la plantilla de referencia que desees")}</p>
                    <div>
          <CapituloContainer     onClick={handleOpen} className="hoverCapitulo" style={{ background: openTribboo ? "#FCD56B" : '#FDEFC6'}}>
            <div
              style={{
                height:'44px',
                background: openTribboo ? "#FCD56B" : '#FDEFC6',
                minWidth: "37px",
                paddingLeft: "3px",
                display: "flex",
                position: "relative",
                borderRadius: "6px",
                paddingTop: "12px",
                cursor: "pointer",
                }}
            >
              {openTribboo ? (
                <div>
                  <KeyboardArrowDownIcon
                    style={{ cursor: "pointer", marginLeft: "3px" }}
                    className="dropdownArrowB"
              
                  />
                </div>
              ) : (
                <KeyboardArrowRightIcon
                  style={{ cursor: "pointer", marginLeft: "3px" }}
                  className="dropdownArrowB"
             
                />
              )}
               <div style={{width:'100%', textTransform:'uppercase'}}>
                <PoppinsNormal>
                    {i18n.t('Plantillas de Planhopper')}
                </PoppinsNormal>
            </div>
            </div>
         

          </CapituloContainer>
          {openTribboo ? (
            <ShowChildDiv  style={{ width: "99%", marginTop: '-8px'  , background:'#FDEFC6'}}>
             
                 <VersionsTable style={{width:'99%'}}>
                        <tr>
                            <th style={{width:'430px'}} colSpan="2">{i18n.t('Nombre')}</th>
                            <th style={{width:'330px'}}>{i18n.t('Descripción')}</th>
                            <th style={{width:'140px'}}>{i18n.t('Etiqueta')}</th>
                        </tr>
                        {templates.length > 0 ? templates.map((template, index) => (
                            template.name && template.accountId=='tribboo' ? 
                            <tr 
                            data-amplify-analytics-on="click"
                            data-amplify-analytics-name="TribbooTemplateCreated"
                            data-amplify-analytics-attrs={template}
                            key={index} style={{cursor:'pointer', backgroundColor:templateSelected && templateSelected.templateId==template.templateId && 'rgb(252, 213, 107)'}} onClick={()=>{handleClick(template, index)
                            }}>
                                <td style={{width:'80px'}}><div style={{width:'35px', height:'35px', marginRight:'0px', borderRadius:'45px', textAlign:'center', backgroundColor: index<2? coloresTemplates[index] : coloresTemplates[index%3]}}>
                                    <PoppinsNormal style={{fontWeight:'700', color:'white', fontSize:'24px', paddingTop:'9px'}}>
                                        T
                                        </PoppinsNormal></div></td>
                                <td style={{fontWeight:'500', color:'#414141', paddingLeft:'0px'}}>{template.name}</td>
                                <td style={{color:'#414141'}}> {template.description}</td>
                                <td>{template.tags? template.tags.length>0 ?
                                 <div style={{width:'fit-content', paddingRight:'10px', color:'#414141', }} id={template.tags[0][1]} className="tag">
                                 {template.tags[0][0]}
                               </div>
                                : null : null}</td>
                            </tr>
                            :null
                        )) : null}
                    </VersionsTable>
                
            </ShowChildDiv>
          ) : null}
        </div>

        <CapituloContainer  onClick={handleOpen2} className="hoverCapitulo" style={{ background: openMine ? "#FCD56B" : '#FDEFC6'}}>
            <div
              style={{
                height:'44px',
                background: openMine ? "#FCD56B" : '#FDEFC6',
                minWidth: "37px",
                paddingLeft: "3px",
                display: "flex",
                position: "relative",
                borderRadius: "6px",
                paddingTop: "12px",
                cursor: "pointer",
                }}
            >
              {openMine ? (
                <div>
                  <KeyboardArrowDownIcon
                    style={{ cursor: "pointer", marginLeft: "3px" }}
                    className="dropdownArrowB"
    
                  />
                </div>
              ) : (
                <KeyboardArrowRightIcon
                  style={{ cursor: "pointer", marginLeft: "3px" }}
                  className="dropdownArrowB"

                />
              )}
               <div style={{width:'100%', textTransform:'uppercase'}}>
                <PoppinsNormal>
                    {i18n.t('Mis Plantillas')}
                </PoppinsNormal>
            </div>
            </div>
         
       
          </CapituloContainer>
          {openMine ? (
            <ShowChildDiv  style={{ width: "99", marginTop: '-8px'  , background:'#FDEFC6'}}>
                <VersionsTable style={{width:'99%'}}>
                        <tr>
                            <th style={{width:'430px'}} colSpan="2">{i18n.t('Nombre')}</th>
                            <th style={{width:'330px'}}>{i18n.t('Descripción')}</th>
                            <th style={{width:'140px'}}>{i18n.t('Etiqueta')}</th>
                        </tr>
                        {templates.length > 0 ? templates.map((template, index) => (
                            template.name && template.accountId!='tribboo'? 
                            <tr key={index} style={{cursor:'pointer', backgroundColor:templateSelected && templateSelected.templateId==template.templateId && 'rgb(252, 213, 107)'}} onClick={()=>{handleClick(template, index)
                            }}>
                                <td style={{width:'80px'}}><div style={{width:'35px', height:'35px', marginRight:'0px', borderRadius:'45px', backgroundColor: index<2? coloresTemplates[index] : coloresTemplates[index%3]}}></div></td>
                                <td style={{fontWeight:'500', color:'#rgb(252, 213, 107)', paddingLeft:'0px'}}>{template.name}</td>
                                <td style={{color:'#414141'}}> {template.description}</td>
                                <td>{template.tags? template.tags.length>0 ?
                                 <div style={{width:'fit-content', paddingRight:'10px', color:'#414141'}} id={template.tags[0][1]} className="tag">
                                 {template.tags[0][0]}
                               </div>
                                : null : null}</td>
                            </tr>
                            :null
                        )) : null}
                    </VersionsTable>
            </ShowChildDiv>
          ) : null}

           </div>
   
            </div>
            
        </Modal>
        {openWaiting &&
        <ModalWaiting open={openWaiting} setOpen={setOpenWaiting} setOpenAnt={setOpen} project={imInBudget ? projectInfo : selectedProject} loading={loading} imInBudget={imInBudget}/>
  }
       </>
    )
}

ModalSelectPlantilla.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    imInBudget: PropTypes.bool
}
export default ModalSelectPlantilla;