import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import { CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
const PrompInput =({
    handleSubmit,
    generating,
    prompt, setPrompt
}) => {

    const validate = () => {
        const words = prompt.value.split(' ');
        if (words.length < 3) {
            return false;
        }
        return true;
    }
    const handleSearch = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (validate()) {
            setPrompt({value: prompt.value, error: false});
            handleSubmit(prompt.value);
        } else {
            
            setPrompt({value: prompt.value, error: true});
        }
    }

    return (
        <div style={{marginBottom:'15px'}}>
        <Paper style={{boxShadow: 'none', borderRadius:"6px"}}
        component="form"
        sx={{ p: '2px 4px', m: '0px 0px 0px 0px', display: 'flex', alignItems: 'center', width: '100%', border: `
        ${prompt.error ? '1px solid red' : '1px solid #D9D9D9'}` }}
        onSubmit={handleSearch}
        >   
            <InputBase
                sx={{ ml: 1, flex: 1 , width: '100%'}}
                placeholder="Escribe el nombre de la partida"
                value={prompt.value}
                onChange={(e) => setPrompt({value: e.target.value, error: false})}
                error = {prompt.error}
                onSubmit={handleSearch}
                helperText={prompt.error ? 'Debe tener al menos 3 palabras' : ''}
            />
             {generating ? <CircularProgress
                    size={20}
                    style={{margin:'12px'}}
                >
                   
                </CircularProgress> : 
            <Button type="button" sx={{ p: '10px', marginRight: '0px' }} aria-label="search"
                onClick={handleSearch}
                variant="contained"
            >
               Generar
            </Button>
            }
        </Paper>
        {
            prompt.error && 
            <div style={{color: 'red', fontSize: '12px', marginLeft:'20px', marginTop: '3px'}}>
                Para generar la búsqueda debe de introducir al menos 3 palabras
            </div>
            }
        </div>
    );

}
PrompInput.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    generating: PropTypes.bool.isRequired,
    prompt: PropTypes.object.isRequired,
    setPrompt: PropTypes.func.isRequired,
}
export default PrompInput;