import React, { useState, useContext, useEffect } from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import { Modal } from "@mui/material"
import i18n from "i18n"
import ModalExcel from "pages/Dashboard/ModalExcel"
import ExcelDropzone from "components/Dropzone/ExcelDropzone"
import { HelveticaBold } from "common/typographies"
import { DownloadingOutlined } from "@mui/icons-material"
import TemplatesContext from "context/templates/templatesContext"

function getModalStyle() {
  const top = 50
  const left = 50
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "550px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: 6,
    maxHeight: "calc(100vh - 100px)",
    overflowY: "auto",
    overflowX: "hidden",
  },
}))
const UploadExcelModal = ({ openModal, setOpenModal, template, setWaitModal }) => {
  const [selectedFiles, setSelectedFiles] = useState([])
  const [modalStyle] = useState(getModalStyle)
  const classes = useStyles()
  const [notExcel, setNotExcel] = useState(false)
  const {importTemplateFromFile } = useContext(TemplatesContext)
  const [open, setOpen] = useState(false)

  const onUpload = async() => {
    for(let i=0; i<selectedFiles.length;i++){
      let extension = selectedFiles[i].path.substr(selectedFiles[i].path.length - 3);
      let extension2 = selectedFiles[i].path.substr(selectedFiles[i].path.length - 4);
      if(extension=='xls' || extension2=='xlsx' || extension=='XLS' || extension2=='XLSX'){
        await importTemplateFromFile({file: selectedFiles[i], templateId: template.templateId})  
      }
    }
    setWaitModal(true)
    setOpenModal(false)
  }


  useEffect(() => {
    if (notExcel == true) {
      setOpen(true)
    }
  }, [notExcel])

  return (
    <>
      <Modal
        open={openModal}
        onClose={() => {
          null
        }}
      >
        <>
          <div style={modalStyle} className={classes.paper}>
            <div className={"py-4"}>
              <p
                className="text-center my-3"
                style={{
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "20px",
                  lineHeight: "23px",
                  color: "#414141",
                }}
              >
                {i18n.t("¡Importante!")}
              </p>
              <HelveticaBold className="text-center my-3" style={{fontWeight:'700', lineHeight: "10px", fontSize:'14px', cursor:'pointer', color:"#414141"}}>
                {i18n.t("Descarga nuestro modelo Excel para poder cargar tus mediciones.")}
              </HelveticaBold>
              <HelveticaBold className="text-center my-3" style={{fontWeight:'500', lineHeight: "23px", fontSize:'14px', cursor:'pointer', color:"#414141"}}>
                {i18n.t("Sólo podremos convertir archivos Excel que sigan el siguiente formato")}:
              </HelveticaBold>
              <div className="inline" style={{marginLeft:'50px', marginRight:'50px', justifyContent:'center', marginTop:'-10px'}}>
                   
                    <a href="https://cdn.tribboo.co/Modelo Proyecto - Presupuesto Simple.xlsx">
                    <div className="inline" style={{cursor:'pointer'}}>
                        <DownloadingOutlined style={{marginTop:'5px',  color:"#4461D7"}}/>
                        <HelveticaBold style={{marginLeft:'10px', fontSize:'12px', fontWeight:'500', paddingTop:'7px',  color:"#4461D7", textDecoration:'underline', textDecorationLine:'#4461D7' }}>Modelo Proyecto - Presupuesto Simple.xlsx</HelveticaBold>
                    </div>
                    </a>

                </div>
              <button
                type="button"
                onClick={() => {
                  setOpenModal(false)
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" style={{ padding: "0 5rem" }}>
              <ExcelDropzone
                selectedFiles={selectedFiles}
                setselectedFiles={setSelectedFiles}
                setNotExcel={setNotExcel}
              />
            </div>

            <div id="centerButton">
              {selectedFiles.length == 0 ? (
                <button
                  type="button"
                  className="AddProjectDisabled"
                  style={{ marginTop: "-10px", marginBottom: "30px" }}
                >
                  {i18n.t("Subir")}
                </button>
              ) : (
                <button
                  type="button"
                  className="AddContact"
                  onClick={onUpload}
                  style={{ marginTop: "-10px", marginBottom: "30px" }}
                >
                  {i18n.t("Subir")}
                </button>
              )}
            </div>

    
          </div>
        </>
      </Modal>
      <ModalExcel open={open} setOpen={setOpen} setNotExcel={setNotExcel} />
    </>
  )
}

UploadExcelModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  template: PropTypes.object,
  setWaitModal: PropTypes.func.isRequired,
}

export default UploadExcelModal
