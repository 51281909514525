import React, { useContext, useEffect, useState, useRef} from "react"
import PropTypes from "prop-types"
import i18n from "i18n"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import JustificantePrecio from "components/Project/Overview/JustificantePrecio";
import TemplatesContext from "context/templates/templatesContext";
import AuthContext from "context/auth/authContext";
import { currencyFormatDE, currencyFormatToConcept } from "lib/transformers";
import { getCurrency } from "utils/functions"

const Part = ({ part }) => {
    const [historic, setHistoricPrice] = useState(null)
    const [openAdd, setOpenAdd] = useState(false)
    const referenciaLines = useRef(null)
    const {deleteBankPart, checkPartida, templateInfo, updatePartsList, checkMultipleParts, myPricesBank, selectedPartGroup, selectPartsGroup, updatePartPrice, cleanChapters, cleanParts } = useContext(TemplatesContext)
    const [listaParaEliminar, setLista] = useState([])
    const [deleteState, setDeleteState] = useState(false)
    const [tocaEliminar, setTocaEliminar] = useState(false)
    const [userPrice, setPrice] = useState(part.userPrice ? part.userPrice : 0)
    const {user, freeTrialExpired} = useContext(AuthContext)

    useEffect(async()=>{
      if(deleteState){
        await selectPartsGroup([], myPricesBank.subcapitulos)
        setTocaEliminar(true)
      }
    },[deleteState])
    useEffect(async() => {
      if(selectedPartGroup){
        let auxList = []
      if(selectedPartGroup.length>0){
        let auxChapter = selectedPartGroup[0][0].chapterId
        let chapter = false
        for(let i=0; i<selectedPartGroup.length;i++){
            if(selectedPartGroup[i]){
                if(selectedPartGroup[i].length>0){
                    for(let j=0;j<selectedPartGroup[i].length;j++){
                        if(selectedPartGroup[i][j].chapterId!=auxChapter){
                            chapter = true
                        }
                        auxList.push(selectedPartGroup[i][j])
                    }
                }
            }
        }
        setLista(auxList)
      }
      if(deleteState && selectedPartGroup.length==0 ){
        await deleteBankPart(part.chapterId, part.partidaId, part.accountId);
        await cleanChapters()
        await cleanParts()
      }else if(deleteState && selectedPartGroup.length>0){
        for (let index = 0; index < auxList.length; index++) {
          await deleteBankPart(
            auxList[index].chapterId,
            auxList[index].partidaId,
            auxList[index].accountId
          )
          await cleanChapters()
          await cleanParts()
        }
      }
      setDeleteState(false)
      setTocaEliminar(false)
    }
  
    }, [ selectedPartGroup, deleteState, tocaEliminar])


    useEffect(()=>{

      if(referenciaLines.current.value!=''){
          let prueba= parseInt(((referenciaLines.current.value.length * 8.1)/620)+1)
          referenciaLines.current.style.height=prueba*22.5+'px'
      }else{
        referenciaLines.current.style.height=22+'px'
      }
      if(!part.resumen){
        part.resumen=part.description
      }
      
    },[part])

    const handleCheckBox = (e) => {
      if(e.nativeEvent.shiftKey){
        if(part.checked){ 
          checkPartida(part.chapterId, part.partidaId)
          updatePartsList(part)
        }else{
          checkMultipleParts(part.chapterId, part)
        } 
      }else{
        checkPartida(part.chapterId, part.partidaId)
        updatePartsList(part)
      }
    }
    const changePrice = (e) => {  
      setPrice(parseFloat(e.target.value))
    }
    const handleOnBlur = async (e) => {
      if(userPrice!=part.userPrice){
        await updatePartPrice({...part, userPrice: userPrice})
      }
    }

  return (
    <>
    <tr
    className="partidasInfo"
    style={{
      cursor: "pointer",
 
    }}
    onClick={(e) => {
      e.stopPropagation()
      setOpenAdd(true)
   }} 
  >
      <td style={{ borderLeft: "none", width: "79px", paddingTop:'5px' }}>
          <div
            className="inline"
            style={{ marginLeft: "10px", marginTop: "2px", height: "100%" }}
          >
            <div className="detallesPartiShow2"  style={{marginLeft:'-4px'}}>
             
          
            </div>
            <input
              type={"checkbox"}
              onChange={(e) => handleCheckBox(e)}
              checked={part.checked}
              onClick={(e) => e.stopPropagation()}
              style={{
                width: "13px",
                marginRight: "5px",
                cursor: "pointer",
                marginTop: "-2px",
              }}
            ></input>

            <p style={{ paddingTop: "10px", color: "#B7B7B7" }}>
              {part.unity}
            </p>
          </div>
        </td>
      <td      onClick={(e) => {
                e.stopPropagation()
                setOpenAdd(true)
             }}  style={{fontWeight:'300', textAlign:'left', paddingLeft:'40px'}}>
      <textarea
              ref={referenciaLines}
              className='NombreAlign' 
              style={{paddingLeft:'11px', cursor:'pointer', paddingTop:'2px', resize:'none', fontSize:'14px', width:'100%', lineHeight:'19.5px', overflow:'hidden',}} 
              placeholder={i18n.t('Buscar o crear partida')}
              //onChange={handleChangePartida}
              //onClick={()=>{setMenu(true)}}
         
              value={part.name}
              name = 'name'
              autoComplete="off"
              
              disabled={true}
             
             />
      </td>
      <td
                style={{
                  minWidth: "50px",
                  color: "#414141",
                }}
              >
                <div style={{ marginTop: '15px', height: "100%" }}>
                {currencyFormatToConcept(part.quantity)}
                </div>
              </td>
              <td
                style={{
                  minWidth: "86px",
                  borderLeft: "1px solid #b7b7b7",

                  paddingTop: "1px",
                  color: "#414141",
                }}
              >
                <div style={{ marginTop: "0px", height: "100%" }}>
              {part ? (
                  <div>
                    <input
                      style={{ color: "#414141", fontWeight: "400", backgroundColor:"transparent" }}
                      id="inputUnidades"
                      min="0"
                      type="number"
                      onChange={changePrice}
                      onBlur={handleOnBlur}
                      onClick={(e) => e.stopPropagation()}
                      name="userPrice"
                      value={userPrice}
                      disabled= {
                        templateInfo.accountId == 'tribboo' ? true :
                        user ? 
                        freeTrialExpired ||  user['custom:role'] === 'WORKER' ? true : false:false}
                      
                    />
                  </div>
              ) : (
                <div>
                   {  getCurrency(user['custom:currency'])}/u
                </div>
              )}
            </div>
              </td>
              <td style={{ minWidth: "127px", color: "#414141", borderLeft:'1px solid #F3F1F1' }}>
            
              <div style={{ marginTop: "17px", height: "100%" }}>
                 {currencyFormatDE(part.quantity*userPrice, getCurrency(user['custom:currency']))}</div>
          </td>
          <td
          style={{
            borderLeft: "none",
            minWidth: "40px",
          }}
        >
          <div
            className="detallesPartiShow"
            style={{
              backgroundColor: "transparent",
              width: "40px",
              minWidth: "40px",
              marginTop: "10px",
              height: "85%",
              justifyContent: "center",
              marginLeft: "-15px",
            }}
          >
          {
            templateInfo.accountId == 'tribboo' ? null :
                            user ? 
                            freeTrialExpired ||  user['custom:role'] === 'WORKER' ? null : 
         <DeleteOutlineIcon
                      style={{ width: "19px", cursor: "pointer" }}
                      onClick={(e) => {
                        e.stopPropagation()
                        setDeleteState(true);
                      }} 
          /> : null}
             <InfoOutlinedIcon
                      style={{ width: "19px", cursor: "pointer" }}
                    
          />

          </div>
        </td>
    </tr>
    <JustificantePrecio
    openAdd={openAdd}
    setOpenAdd={setOpenAdd}
    imFromBankPrice={true}
    partida={part}
    partidaInfo={part}
    soyFinal={false}
  />
  </>
  )
}
Part.propTypes = {
  part: PropTypes.object.isRequired,
}
export default Part
