import ExportSideBar from "./ExportSideBar"

class CertHistoryContextState {
  constructor(
    setCertHistory = () => {},
    certHistoryTree = {},
    loading = false,
    exportSideBar = new ExportSideBar(),
    updating = false
  ) {
    this.setCertHistory = setCertHistory
    this.certHistoryTree = certHistoryTree
    this.loading = loading
    this.exportSideBar = exportSideBar
    this.updating = updating
  }
}

export default CertHistoryContextState
