import React, {useState} from "react";
import {Modal} from "@mui/material";
import {withRouter} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import deleteCap from 'assets/images/budgeting/deleteCap.png'
import i18n from "i18n";

function getModalStyle() {
    const top = 50 ;
    const left = 50;
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: '630px',
        height: '470px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        borderRadius: 6,
        maxHeight: 'calc(100vh - 100px)',
        overflowY: 'auto',
        overflowX:'hidden',
    },
}));

const ModalNoSelection = ({ open, setOpen, nombre}) => {
    // Configuración del modal para añadir contactos
    const [ modalStyle ] = useState(getModalStyle);
    const classes = useStyles();

    const onClose = ()=> {
        setOpen(false)
    }

    return(
        <>
            <Modal open={open} onClose={onClose}>
                <div style={modalStyle} className={classes.paper}>
                    <div id='centerPaco'>
                        <img src={deleteCap} style={{width:'95px', height:'144px'}}/>
                    </div>
                    <div id="DivTitleEliminar2" style={{width:'100%', textAlign:'center', display:'block', marginLeft:'0px'}}><p id="centerTitleEliminar" style={{marginTop:'40px', fontSize:'16px', fontWeight:'700'}}>{i18n.t("Atención")}</p></div>
                    <div id="DivTitleEliminar2" style={{width:'100%', textAlign:'center', display:'block', marginLeft:'0px'}}>
                    <p id='subtitledelete'>Aún no has seleccionado nada, debes seleccionar {nombre} previamente.</p>
                    </div>
                    <div id="centerButton">
                        <div id='DivTitleEliminar2' style={{marginLeft:'70px', marginBottom:'10px', fontSize:'16px'}}>
                        <button type="button" style={{width: '190px', marginRight: '15px', borderRadius:'6px'}}
                               className="AddContact" onClick={onClose}>{i18n.t("Seleccionar")}</button>
                     </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

ModalNoSelection.propTypes = {
    open : PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    infoCap: PropTypes.object.isRequired,
    soyFinal: PropTypes.bool,
    modificado:  PropTypes.number,
    setClose: PropTypes.func,
    nombre: PropTypes.string,
}
export default withRouter(ModalNoSelection);