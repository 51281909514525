import React, { useRef, useState, useEffect} from "react";
import PropTypes from "prop-types";
import i18n from "i18n";
import { Document, Page, pdfjs } from 'react-pdf'
import {HelveticaBold} from 'common/typographies'
import { CircularProgress } from "@mui/material";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFPreview = ({pdf}) => {
    const [numPages, setNumPages] = useState(null);
    const [notRender, setNotRender] = useState(true);
    const componentRef = useRef();
    const [loading, setLoading] = useState(true);
    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
    }

    useEffect(() => {
        if(pdf){
            setNotRender(false);
            setLoading(false);
        }else{
            setNotRender(true);
            setLoading(true);
        }
    },[pdf]);

    return (
    <>
 
            {loading == true || notRender ? 
            <>
                   <div style={{ width:'100%', justifyContent:'center', display:'grid', marginTop:'15%'}}>
                <CircularProgress
                    style={{ width: 150, height: 150, marginLeft:'0px', color:'#414141', marginBottom:'20px' }}
                />
                <HelveticaBold style={{color:'#414141', fontSize:'24px', marginLeft:'10px', marginTop:'0px'}}>{i18n.t("Cargando...")}</HelveticaBold>
                </div>
                   </>
             :   
             <> 
             <div style={{  height:'100%', overflowY:'auto', display:'flex', justifyContent:'center', borderBottom:'1px solid #EBEBEB'}}>
             <Document width={'100%'}  ref={componentRef} file={pdf?.preview?.response} onLoadError={console.error} style={{overflowY:'auto'}} onLoadSuccess={onDocumentLoadSuccess}>
                {
               [...Array(numPages)].map((elementInArray, index) => ( 
                 <Page scale={1.5} key={index} pageNumber={index + 1} />
                 ) 
             )}
                
             </Document>
             </div>
             </>
        }
                    
    </>
    )
}
 PDFPreview.propTypes = {
    pdf: PropTypes.object,
};

export default PDFPreview;