import { CapituloMini } from "common/containers";
import React, {useState, useEffect, useContext} from "react";  
import PropTypes from 'prop-types';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { HelveticaBold } from "common/typographies";
import i18n from "i18next";
import { KeyboardArrowUp } from "@mui/icons-material";
import { CheckedInput, CustomCheckedInput } from "common/inputs";
import img1 from './images/img1.png'
import img2 from './images/img2.png'
import img3 from './images/img3.png'
import AuthContext from "context/auth/authContext";
import { CircularProgress } from "@mui/material";

const BudgetingTemplate = ({url, setUrl, handleOpen, openNotSendCapitulo, setLoading}) => {

    const [cargado, setCargado] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [auxUrl, setAuxUrl] = useState(null)
    const handleOpen2 = async(e) => {
        if (!e) var e = window.event;
        e.cancelBubble = true;
        if (e.stopPropagation) e.stopPropagation();
        handleOpen(e, 'budget')
       
      }
      const [options, setOptions] = useState([false, false, false])
      const {getTemplates, budgetingTemplates, internalUser, updateInternalUser} = useContext(AuthContext)

      useEffect(() => {
        if(openNotSendCapitulo){
            setUrl(auxUrl)
            if(referenciaCondiciones.current && internalUser){
                if(internalUser.budgetTemplate.conditions){
                referenciaCondiciones.current.innerText = internalUser.budgetTemplate.conditions
                }
            }
        }
      }, [openNotSendCapitulo, referenciaCondiciones])

      useEffect(() => {
        if(internalUser?.budgetTemplate && !cargado && openNotSendCapitulo){
        setLoading(true)
        setLoading2(true)
        getTemplates('BUDGET').then((res) => {
                if(openNotSendCapitulo){
                   let trobat = false
                   let i = 0
                    while(!trobat && i<res.length){
                        if(res[i].id==internalUser?.budgetTemplate.id){
                            switch(i){
                        
                                case 0:
                                    setOptions([false, true, false])
                                    break;
                                case 1:
                                    setOptions([true, false, false])
                                    break;
                                case 2:
                                    setOptions([false, false, true])
                                    break;
                            }
                            trobat = true
                 
                            setAuxUrl(res[i])
                        }
                        i++
                    }
                    if(!trobat){
                  
                        setOptions([true, false, false])  
                        setAuxUrl(res[1])
                    }
                    setLoading(false)
                    setLoading2(false)
                    setCargado(true)
            }
       
            if(internalUser.budgetTemplate.conditions){
              setCondiciones(internalUser.budgetTemplate.conditions)
              if(referenciaCondiciones.current){ referenciaCondiciones.current.innerText = internalUser.budgetTemplate.conditions}
             
              
          }
        })
        }
    }, [internalUser, openNotSendCapitulo])

    useEffect(() => {
      if(auxUrl && openNotSendCapitulo){
          setUrl(auxUrl)
      }
    }, [auxUrl, openNotSendCapitulo])

    const updateChanges = async(budgetTemplete) => {
        let aux = {...internalUser}
        aux.budgetTemplate = {...budgetTemplete, conditions: condiciones, preview: budgetTemplete.preview.response}
        await updateInternalUser(aux)
    }

      const changeCheck = (e, i) => {
        e.preventDefault()
        let newOptions = [...options]
        for(let j=0; j<newOptions.length; j++){
            if(j==i){
              if(newOptions[j]!=true){
                newOptions[j] = !newOptions[j]
                setLoading(true)
                if(j==0){
                    setUrl(budgetingTemplates[1])
                    updateChanges(budgetingTemplates[1])
                }else if(j==1){
                    setUrl(budgetingTemplates[0])
                    updateChanges(budgetingTemplates[0])
              }else{
                setUrl(budgetingTemplates[2])
                updateChanges(budgetingTemplates[2])
              }
              setLoading(false)
            }
            }else{
                newOptions[j] = false
            }
        }
        setOptions(newOptions)
    }

    const referenciaCondiciones = React.useRef(null)

    const [interval, setInterval2] = useState(null)
    const [counter, setCounter] = useState(0)
    const [guarda, setGuarda] = useState(false)
    const [condiciones, setCondiciones] = useState('')

    const handleChangeConfitions = (e) => {
        if(e.target.className=='newTextareaExpandEdit'){
            if(!interval) {setInterval2(true)}
            setCounter(0)
            setCondiciones(referenciaCondiciones.current.outerText)
        }
    }

    useEffect(() => {
        if(interval){
          const interval = setInterval(() => {
            setCounter(counter + 1);
          }, 1000);
          if(counter ==2){
            updateChanges(url)
            setInterval2(false)
            setCounter(0)
          }
          return () => clearInterval(interval);
        }
      }, [counter, interval]);

    return (
        <div style={{marginRight:'20px'}}>
        <CapituloMini  onClick={(e) => handleOpen2 (e)} style={{boxShadow:'none', borderBottomRightRadius: openNotSendCapitulo && '0px', borderBottomLeftRadius: openNotSendCapitulo && '0px'}}>
            <HelveticaBold style={{fontSize:'13px', marginLeft:'15px'}}>
                {i18n.t("Plantillas de presupuesto")}
            </HelveticaBold>
        {openNotSendCapitulo ? (
                <div>
                  <KeyboardArrowUp
                    style={{ cursor: "pointer", position:'absolute', right:'5px', marginTop:'6px'  }}
                    className="dropdownArrowB"
               
                    
                  />
                </div>
              ) : (
                <KeyboardArrowDownIcon
                  style={{ cursor: "pointer", marginLeft: "5px", position:'absolute', right:'5px', marginTop:'6px' }}
                  className="dropdownArrowB"
                 
                />
              )}
              
        </CapituloMini>

        {
        openNotSendCapitulo ? 
          loading2 ? 

          <div style={{borderTop:'0.5px solid #C4C4C4', background:'white', height:'50vh', zIndex:'2', marginTop:'0px', position:'relative', borderBottomRightRadius: '6PX', borderBottomLeftRadius: '6PX', overflowY:'auto'}}>
        
          <CircularProgress style={{position:'absolute', top:'50%', left:'50%', marginTop:'-20px', marginLeft:'-20px'}}/>
      </div> 
      :
        <div style={{borderTop:'0.5px solid #C4C4C4', background:'white', height:'50vh', zIndex:'2', marginTop:'0px', position:'relative', borderBottomRightRadius: '6PX', borderBottomLeftRadius: '6PX', overflowY:'auto'}}>
            <HelveticaBold style={{fontWeight:'400', color:'#414141', fontSize:'12px', marginLeft:'15px', marginTop:'10px'}}>
                {i18n.t("Seleciona un diseño para la portada de tu presupuesto")}:
            </HelveticaBold>
            <div style={{display:'flex', flexDirection:'row', flexWrap:'wrap', marginTop:'10px', marginLeft:'15px'}}>
                {options.map((option, i) => {
                    return (
                <div
                onClick={(e) => {e.stopPropagation(); changeCheck(e, i)}}
                key={i} style={{width:'30%', background:'#F5F5F5', borderRadius:'6px', marginRight:'10px', marginBottom:'5px', cursor:'pointer'}}>
                    <div className="inline" style={{position:'relative'}}>
                    <CustomCheckedInput
                      style={{marginTop:'10px', marginLeft:'10px'}}
                    >
                        {options[i] && <CheckedInput/> }
                    </CustomCheckedInput>
                    <HelveticaBold style={{fontWeight:'400', color:'#414141', fontSize:'11px', marginTop:'9px', marginLeft:'7px'}}>
                        {i18n.t("Opción")} {i+1}
                    </HelveticaBold>
                    </div>
                    <div style={{width:'100%', display:'flex', justifyContent:'center', marginTop:'-2px'}}>
                        <img src={ i == 0 ? img1 : i == 1 ? img2 : img3 } style={{width:'85%', height:'70%', borderRadius:'6px'}}/>
                    </div>
                </div>
                    )
                })}
              
            </div>
 
            <HelveticaBold style={{fontWeight:'400', color:'#414141', fontSize:'12px', marginLeft:'15px', marginTop:'10px'}}>
                {i18n.t("Define las condiciones generales")}:
            </HelveticaBold>

            <p
                style={{
                  display: "block",
                  textAlign: "left",
                  marginBottom: "0px",
                  border: '1px solid #C4C4C4',
                  borderRadius:'6px',
                    marginLeft:'15px',
                    marginRight:'15px',
                    marginTop:'-10px',
                    height:'30%',
                }}
              >
                {" "}
                <span ref={referenciaCondiciones}
                 style={{maxWidth:'100% !important', padding:'10px',height:'100%', overflowY:'auto'}}
                  className="newTextareaExpandEdit"
                  role="textbox"
                  autoComplete="off"
                  name="description"
                  onInput={handleChangeConfitions}
                  contentEditable
                ></span>
              </p>
 
        </div>: null
        }

        </div>
    )
}

export default BudgetingTemplate;

BudgetingTemplate.propTypes = {
    url: PropTypes.string.isRequired,
    setUrl: PropTypes.func.isRequired,
    handleOpen: PropTypes.func.isRequired,
    openNotSendCapitulo: PropTypes.bool.isRequired,
    setLoading: PropTypes.func.isRequired,
}
