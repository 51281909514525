import React, { useContext} from "react";
import AddIcon from '@mui/icons-material/Add';
import { PoppinsSemiBold, HelveticaBold } from "common/typographies";
import { BlackHoverButton4CreateDynamically } from "common/containers";
import i18n from "i18n";
import { ActasTable } from "common/tables";
import PropTypes from 'prop-types';
import AsistentesComponent from "./AsistentesComponent";


const AsistentesInfo = ({actaValues, setActaValues, actasRefs, setActaRefs}) => {


    const addAsistente = () => {
        setActaValues({...actaValues, attendees:[...actaValues.attendees, {title: '', name: '', signature: ''}]})
        setActaRefs({...actasRefs, attendeesRefs:[...actasRefs.attendeesRefs, {name: React.createRef()}]})
    }




  return (
    <>
            <div style={{width:'100%', height:'auto', backgroundColor:'#F8F8F8', marginTop:'10px', marginBottom:'16px', marginLeft:'0px',  borderRadius:'6px'}}>

                 <div className="inline" style={{ width:'100%', height:'100%', position:'relative',display:'flex', flexWrap:'wrap'}}>

                    <div style={{backgroundColor:'#F8F8F8', width:'100%', marginLeft:'20px', marginRight:'20px', borderRadius:'6px' }}>
                        <div style={{display:'flex', padding:'10px', paddingTop:'8px', paddingLeft:'0px', paddingBottom:'5px', marginBottom:'-10px'}}>
                                <PoppinsSemiBold style={{fontSize:'14px', minWidth:'200px'}} >
                                    {i18n.t('Asistentes')}
                                </PoppinsSemiBold>
                        </div>
                        <ActasTable style={{marginBottom:'12px'}}>
                            <tr>
                                <th style={{width:'20%'}}>{i18n.t('Titulo')}</th>
                                <th style={{width:'65%'}}>{i18n.t('Nombre')}</th>
                                <th style={{width:'15%'}}>{i18n.t('Firma')}</th>
                            </tr>
                            <AsistentesComponent actasRefs={actasRefs} actaValues={actaValues} setActaValues={setActaValues} index={0}/>
                            {
                                actaValues.attendees.length>1 && actaValues.attendees.map((asistente, index) => {
                                    if(index>0){
                                        return (
                                            <AsistentesComponent actasRefs={actasRefs} key={index} actaValues={actaValues} setActaValues={setActaValues} index={index}/>
                                        )
                                    }
                                })
                            }
                        </ActasTable>
                        <BlackHoverButton4CreateDynamically onClick={()=>addAsistente()} style={{marginBottom:'12px'}}>
                            <AddIcon style={{fontSize:'18px', marginRight:'5px', color:'#414141', marginLeft:'-5px', paddingTop:'1px'}}/>
                            <HelveticaBold style={{color:'#414141', fontWeight:'600', paddingTop:'1px', fontSize:'12px'}}>{i18n.t('Añadir asistente')}</HelveticaBold>
                        </BlackHoverButton4CreateDynamically>
                    </div>

                
                   </div>
            </div>
     
    </>
  );
}

AsistentesInfo.propTypes = {
    actaValues: PropTypes.object,
    setActaValues: PropTypes.func,
    actasRefs: PropTypes.array,
    setActaRefs: PropTypes.func
};

export default AsistentesInfo;