import React, {useState} from 'react';
import CloseIcon from "@mui/icons-material/Close";
import {makeStyles} from "@material-ui/core/styles";
import { Modal} from "@mui/material";
import PropTypes from "prop-types";
import i18n from 'i18n';
import addContactImage from 'assets/images/budgeting/addContactImage.png'
import { PoppinsSemiBold } from 'common/typographies';

function getModalStyle() {
    const top = 50 ;
    const left = 50;
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };

}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: '500px',
        height: '430px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        borderRadius: 6,
        maxHeight: 'calc(100vh - 100px)',
        overflowY: 'auto',
        overflowX:'hidden',
    },
}));

const ModalSelectTypeContact =({open, setOpen, openInvitation, setOpenInvitation, setOpenSelectContact})=> {

    const [ modalStyle ] = useState(getModalStyle);
    const classes = useStyles();

    return(
        <>
            <Modal
                open={open}
                onClose={() => {
                 null
                }}
            >
                <div style={modalStyle} className={classes.paper}>
                <CloseIcon id="cerrar" onClick={() => {
                        setOpen(false);
                    }}></CloseIcon>
                    <div id='centerPaco' style={{marginBottom:'-35px', marginTop:'15px'}}>
                        <PoppinsSemiBold style={{fontSize:'18px'}}>{i18n.t('Añadir Usuario')}</PoppinsSemiBold>
                    </div>
                    <div className="CardProjects" style={{marginTop:'60px', marginLeft:'28px', cursor:'pointer'}}  onClick={()=>{setOpenSelectContact(true); setOpen(false)}}>
                                                <div id="CardContent">
                                                    <div className="ImgProyect" style={{marginLeft:'0px'}}>
                                                        <span className="avatar-title rounded-circle bg-light text-danger font-size-16">
                                        <img src={addContactImage} height="78px" width='60px' />
                                    </span>
                                                    </div>
                                                    <div id="CenterContentProyectCard" style={{marginLeft: '105px', marginRight:'0px'}}>
                                                        <div className="flex-grow-1 overflow-hidden" style={{marginRight:'-10px'}}>
                                                            <div id="AddProyecto" style={{fontWeight:'700', fontSize:'18px', fontFamily:'Helvetica'}}>
                                                                    {i18n.t("Desde contactos")}
                                                            </div>
                                                            <p className="DescriptionText" style={{fontWeight:'400', fontSize:'14px', fontFamily:'Helvetica'}}>{i18n.t('Carga a un nuevo miembro de tu equipo desde tus contactos. ')}</p>
                                                        </div>
                                                    </div>
                                                </div>
                     </div>
                   
                     <div className="CardProjects" style={{marginTop:'0px', marginLeft:'28px', cursor:'pointer'}} onClick={()=>{setOpenInvitation(true); setOpen(false)}}>
                                                <div id="CardContent">
                                                    <div className="ImgProyect" style={{marginLeft:'0px'}}>
                                                        <span className="avatar-title rounded-circle bg-light text-danger font-size-16">
                                        <img src={addContactImage}  height="78px" width='60px' />
                                    </span>
                                                    </div>
                                                    <div id="CenterContentProyectCard" style={{marginLeft: '105px'}}>
                                                        <div className="flex-grow-1 overflow-hidden" style={{marginRight:'-10px'}}>
                                                            <div id="AddProyecto" style={{fontWeight:'700', fontSize:'18px', fontFamily:'Helvetica', marginTop:'-6px'}} >
                                                                    {i18n.t("Invita a un nuevo usuario")}
                                                            </div>
                                                            <p className="DescriptionText" style={{fontWeight:'400', fontSize:'14px', fontFamily:'Helvetica'}}>
                                                            {i18n.t("Añade un nuevo usuario enviando una invitación por correo electrónico..")} </p>
                                                        </div>
                                                    </div>
                                                </div>
                     </div>
                     
                     
                 </div>

                   
                    
            </Modal>


        </>
    )
}
ModalSelectTypeContact.propTypes = {
    open: PropTypes.bool, 
    setOpen: PropTypes.func,
    setOpenInvitation: PropTypes.func,
    openInvitation: PropTypes.bool,
    setOpenSelectContact: PropTypes.func,

}
  

export default ModalSelectTypeContact;