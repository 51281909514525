import React, { useState} from "react"
import PropTypes from 'prop-types'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
//i18n
import {useHistory } from "react-router-dom"
import i18n from "i18n"
import { PoppinsNormal } from "common/typographies"
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown"


const DropdownSelectQuestion2 = props => {
  const [menu, setMenu] = useState(false)
  const history = useHistory()
  return (
    <>
    <React.Fragment>
      <Dropdown
      isOpen={menu}
      toggle={() => setMenu(!menu)}
      className="d-inline-block"
      id='dropDownWidth2'
      style={{height:'0px', width: "100%", marginLeft: '20px'}}
      
       >
      <DropdownToggle style={{marginTop:'0px', height:'35px', marginLeft:'-20px', marginBottom:"30px", borderRadius:'6px', border:'1px solid #c4c4c4', width:'95%', paddingLeft:'10px'}}>
        <div id='overflowInputDrop' className="inline" style={{position:'relative'}}>
        <PoppinsNormal style={{fontSize:'14px', color:props.value.length>0 ? '#414141' : "#C4C4C4", lineHeight:'12px', cursor:'pointer', textTransform:"none"}}>
            {props.value.length > 0 ? props.value : i18n.t("Selecciona una opcion")}
            </PoppinsNormal>
        <KeyboardArrowDown style={{color:'#414141', width:'15px', position:'absolute', right: '5px', marginTop : '-5px'}}/>
        </div>
      </DropdownToggle>
      <DropdownMenu id="overflowDropdown" className="dropdown-menu-end" style={{width: '95%', marginRight:'32px'}}>
      <DropdownItem tag="b"  style={{lineHeight: '20px', cursor:'pointer'}} onClick={()=>{props.setValue('Crear un presupuesto de coste')}}>
          {" "}
            <div className="inline">
            {i18n.t("Crear un presupuesto de coste")}
            </div>
        </DropdownItem>
        <DropdownItem tag="b"  style={{lineHeight: '20px', cursor:'pointer', overflow:'hidden'}} onClick={()=>{props.setValue("Acceder a bancos de precios de materiales actualizados")}}>
          {" "}
            <div className="inline">
            {i18n.t("Acceder a bancos de precios de materiales actualizados")}
            </div>
        </DropdownItem>
        <DropdownItem tag="b"  style={{lineHeight: '20px', cursor:'pointer'}} onClick={()=>{props.setValue('Gestionar proveedores')}}>
          {" "}
            <div className="inline">
            {i18n.t("Gestionar proveedores")}
            </div>
        </DropdownItem>
        <DropdownItem tag="b"  style={{lineHeight: '20px', cursor:'pointer'}} onClick={()=>{props.setValue('Enviar presupuestos comerciales a clientes')}}>
          {" "}
            <div className="inline">
            {i18n.t('Enviar presupuestos comerciales a clientes')}
            </div>
        </DropdownItem>
        <DropdownItem tag="b"  style={{lineHeight: '20px', cursor:'pointer'}} onClick={()=>{props.setValue('Planning')}}>
          {" "}
            <div className="inline">
            {i18n.t('Planning')}
            </div>
        </DropdownItem>
        <DropdownItem tag="b"  style={{lineHeight: '20px', cursor:'pointer'}} onClick={()=>{props.setValue('Contabilidad')}}>
          {" "}
            <div className="inline">
            {i18n.t('Contabilidad')}
            </div>
        </DropdownItem>
        <DropdownItem tag="b"  style={{lineHeight: '20px', cursor:'pointer'}} onClick={()=>{props.setValue('Abrir archivo bc3')}}>
          {" "}
            <div className="inline">
            {i18n.t('Abrir archivo bc3')}
            </div>
        </DropdownItem>
       

      </DropdownMenu>
    </Dropdown>
    
    </React.Fragment>

  </>
  )
}

DropdownSelectQuestion2.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
  value: PropTypes.string,
  setValue: PropTypes.func,
  referencia: PropTypes.any,
}

export default DropdownSelectQuestion2