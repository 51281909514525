import React, {useContext, useState} from "react"
import { Modal, Box, Typography, Grid, IconButton, CircularProgress } from "@mui/material"
import PropTypes from "prop-types"
import WarningOutlinedIcon from "@mui/icons-material/WarningOutlined"
import { CustomButton, ActionModal } from "components/Documents/StylesAndUtils/CustomStyles"
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import { useParams } from 'react-router-dom'
import ProjectContext from 'context/projects/projectContext';
import { withRouter, Link, useHistory } from "react-router-dom"
import i18n from "i18n"
const ModalDeleteProject = ({
  openEliminate,
  setOpenEliminate,
  project,
}) => {
    const projectContext = useContext(ProjectContext)
    const {deleteProject} = projectContext
    const history = useHistory()
    const {projectId} = useParams();
    const [loading, setLoading] = useState(false)

    const removeP = async() => {
      if(projectId){
        setLoading(true)
        await deleteProject(projectId)
        setLoading(false)
        history.push('/dashboard')
      }else{
        setLoading(true)
        await deleteProject(project.projectId)
        setLoading(false)
        history.push('/dashboard')
      }
  }
      
  return (
    <Modal open={openEliminate} onClose={() => null}>
      <Box component="form" sx={ActionModal} style={{width:"630px", height:'470px'}}>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          spacing={2}
        >
          <Grid
            item
            sx={{
              alignSelf: "flex-end",
            }}
          >
            <IconButton onClick={() => setOpenEliminate(false)}>
              <CloseOutlinedIcon />
            </IconButton>
          </Grid>
          <Grid item style={{marginTop:'40px'}}>
            <WarningOutlinedIcon fontSize="large" sx={{ fill: "#FFD52B" }} />
          </Grid>
          <Grid item>
            <Typography variant="h6" align="center">
              {i18n.t("¿Está seguro que desea eliminar este proyecto?")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" align="center">
              {i18n.t("Esta acción no se puede deshacer.")}
            </Typography>
          </Grid>
          <Grid item sx={{ marginY: "3rem" }}>
            {!loading ? 
            <CustomButton style={{color:"white", backgroundColor:'#BF2929', width:'190px'}} onClick={removeP}>{i18n.t("Eliminar")}</CustomButton>
            :
            <CircularProgress size={24} />
            }
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}


ModalDeleteProject.propTypes = {
  openEliminate: PropTypes.bool.isRequired,
  setOpenEliminate: PropTypes.func.isRequired,
  project: PropTypes.object,
}

export default ModalDeleteProject
