import React from "react";
import { useContext, useState, useEffect } from "react";
import Capitulo from "./chapters/Capitulo";
import AuthContext from "context/auth/authContext";
import BankPricesReferenceContext from "context/referenceBankPrices/BankPricesReferenceContext";
import PropTypes from "prop-types"
import LayoutBancoRef from "./layoutBancoRef";
import TopQuickMenu2 from "./topQuickMenu2";
import { ListBank } from "pages/BancoPrecios/ListBank";
import Skeleton from '@mui/material/Skeleton';




const BancoPreciosRefContent = ({tipo, referencia}) => {

    const {getMyBankPrices, myPricesBank, loading, categoriesChecked, partsChecked, cleanChapters, cleanParts} = useContext(BankPricesReferenceContext);
    const {user, freeTrialExpired, openFreeTrialExpire} = useContext(AuthContext)

    const getName = (tipo) => {
        for(let categoria of ListBank[referencia].categorias){
            if(categoria.uri === tipo){
                return categoria.name
            }
        }
    }
    const [name, setName] = useState(getName(tipo))

    useEffect(() => {
        if(user){
            cleanChapters()
            cleanParts()
            getMyBankPrices(tipo, referencia)
            
        }
    },[user, tipo, referencia])
    

    useEffect(() => { if(freeTrialExpired){
        openFreeTrialExpire()
    }  }, [freeTrialExpired])
  


    return(
        <>
        <div style={{marginLeft:'30px'}}>
            <p id="Configuration">{referencia.charAt(0).toUpperCase() + referencia.slice(1)}</p>
        
        <LayoutBancoRef tipo={tipo} referencia={referencia}/>
        </div>
        {
            loading? 
            <>
                {[...Array(12)].map((e, i) => 
                  <>
                  <div id="" style={{alignItems:'center',marginLeft:'30px', position:'relative', display:"flex", gap:'10px', width:'100%'}} key={i}>
                    <Skeleton variant="text" width={15} height={25}  animation="wave" sx={{ fontSize: '14px' }} />

                    <Skeleton variant="text" width={1300} height={44} animation="wave" sx={{ fontSize: '14px' }} />

                  </div>
                  </> 
                )}
                   
            </>
            :
            
            myPricesBank.subcapitulos.length>0?
            <>
            <div style={{marginLeft:'30px'}}>
            {myPricesBank.subcapitulos.map((capitulo, i) => (
              <Capitulo key={capitulo.chapterId} i={i} capitulo={capitulo} indice={i} tipo={tipo} referencia={referencia} />
              ))}
           
            </div>
  
          </>

           
           :
           null}
            {categoriesChecked.length>0 ? <TopQuickMenu2/> : partsChecked.length>0 ? <TopQuickMenu2/> : null}
        </>
    )
}

BancoPreciosRefContent.propTypes = {
    tipo: PropTypes.string.isRequired,
    referencia: PropTypes.string.isRequired
  }

export default BancoPreciosRefContent;