import React, {useContext, useEffect, useRef, useState} from "react";
import AddIcon from "@mui/icons-material/Add"
import CropFreeIcon from '@mui/icons-material/CropFree';
import CommentsContainer from '../../Comments/CommentsContainer'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ModalEditarPartida from 'components/TablaPartida//ModalEditarPartida';
import {Modal,
    Card, 
    TableContainer,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    IconButton, 
    Box, 
    Grid, 
    CardContent, 
    Typography, 
    Chip,
    Divider,
} from "@mui/material";
import DocumentDropZone from 'components/Documents/DocumentDropZone';
import FileContext from 'context/file/fileContext';
import ContactContext from 'context/contact/contactContext';
import { useSearch } from "hooks/useSearch";
import { ListaColores } from "components/Documents/StylesAndUtils/Colors";
import CloseIcon from "@mui/icons-material/Close";
import {withRouter} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import { TiposPartidas } from "constants/tiposPartidas";
import AuthContext from "context/auth/authContext";
import { useParams } from 'react-router-dom'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ModalDeleteImages from "./modals/modalEliminateImages";
import ModalZoomImage from "./modals/modalZoomImage";
import i18n from "i18n";
import { HelveticaBold } from "common/typographies";
import { currencyFormatDE, currencyFormatWithoutSimbol } from "lib/transformers";
import { YellowButton } from "common/buttons";
import Budget2023Context from "context/budget2023/Budget2023Context";
import { getCurrency } from "utils/functions"

function createData(tipo, codigo, descripcion, precio,cantidad) {
    return { tipo, codigo, descripcion, precio, cantidad, importe: precio*cantidad };
  }
  
  const rows1 = [
    createData(1,'ETD223','Pintura',1.000,1),
    createData(2,'ETD223','Pintura',1.000,1),
    createData(2,'ETD223','Pintura',1.000,1),
    createData(2,'ETD223','Pintura',1.000,1),
    createData(3,'ETD223','Pintura',1.000,1),
    createData(1,'ETD223','Pintura',1.000,1),
    createData(2,'ETD223','Pintura',1.000,1),
    createData(2,'ETD223','Pintura',1.000,1),
    createData(2,'ETD223','Pintura',1.000,1),
    createData(3,'ETD223','Pintura',1.000,1),
    createData(1,'ETD223','Pintura',1.000,1),
    createData(2,'ETD223','Pintura',1.000,1),
    createData(2,'ETD223','Pintura',1.000,1),
    createData(2,'ETD223','Pintura',1.000,1),
    createData(3,'ETD223','Pintura',1.000,1),
  ];
function BasicTable({
    rows
}) {
    return (
      <TableContainer  sx={{
          padding: '1rem',
         
          maxHeight: '50vh',
          overflowY: 'scroll',
          backgroundColor:'#FBFBFE'
          
      }}>
        <Table sx={{ minWidth: 650, marginBottom:'-16px', marginTop:'-16px'}} aria-label="simple table">
          <TableHead>
            <TableRow sx={{border: 'none'}}>
              <TableCell align="center" sx={{border: 'none', width: '130px'}} 
              style={{fontWeight:'600', fontFamily:"Helvetica", fontSize:'14px', textAlign:'left'}}>{i18n.t("Tipo")}</TableCell>
              <TableCell align="center" sx={{border: 'none', width: '150px'}}
              style={{fontWeight:'600', fontFamily:"Helvetica", fontSize:'14px', textAlign:'left'}}>{i18n.t("Código")}</TableCell>
              <TableCell align="center" sx={{border: 'none'}}
              style={{fontWeight:'600', fontFamily:"Helvetica", fontSize:'14px', width: '500px', textAlign:'left'}}>{i18n.t("Descripción")}</TableCell>
              <TableCell align="center" sx={{border: 'none'}}
              style={{fontWeight:'600', fontFamily:"Helvetica", fontSize:'14px', width: '100px' , textAlign:'left'}}>{i18n.t("Precio")}</TableCell>
              <TableCell align="center" sx={{border: 'none'}} 
              style={{fontWeight:'600', fontFamily:"Helvetica", fontSize:'14px', width: '100px' , textAlign:'left'}}>{i18n.t("Cantidad")}</TableCell>
              <TableCell align="center" sx={{border: 'none'}} 
              style={{fontWeight:'600', fontFamily:"Helvetica", fontSize:'14px', width: '100px', textAlign:'left'}}>{i18n.t("Importe")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{border:'none'}}>
            {rows.map((row) => (
            
              <TableRow
                key={row.codigo}
                sx={{ '&:last-child td, &:last-child th': { border: 'none'}, border: 'none' }}
              >
                    
                <TableCell align="left" sx={{border: 'none', }}>
                  <Chip style={{minWidth:'120px', fontSize:'12px'}
                  } label={isNaN(row.tipo)? TiposPartidas[0].toUpperCase(): row.tipo>5 ? TiposPartidas[0].toUpperCase() : TiposPartidas[row.tipo].toUpperCase()} 
                  variant='outlined'
                  sx={{
                      margin: '0px',
                      borderRadius: '4px',
                      
                        backgroundColor: 'transparent',
                        color: `${isNaN(row.tipo)? ListaColores[0].color: row.tipo<=7 ?  ListaColores[row.tipo].color : ListaColores[0].color }`, borderColor: `${isNaN(row.tipo)? ListaColores[0].color: row.tipo<=7 ?  ListaColores[row.tipo].color : ListaColores[0].color}`,
                  }}/>
                </TableCell>
                <TableCell align="left" sx={{border:'none'}} style={{fontWeight:'500', textTransform: 'uppercase'}}>{row.codigo}</TableCell>
                <TableCell align="left" sx={{border:'none'}} style={{fontWeight:'300'}}>{row.descripcion}</TableCell>
                <TableCell align="left" sx={{border:'none'}} style={{fontWeight:'300'}}>{currencyFormatWithoutSimbol(row?.precio.toString())}<span>&#8364;</span>/{row.unidad}</TableCell>
                <TableCell align="left" sx={{border:'none'}} style={{fontWeight:'300'}}>{currencyFormatWithoutSimbol(row?.cantidad.toString())} {row.unidad}</TableCell>
                <TableCell align="left" sx={{border:'none'}} style={{fontWeight:'300'}}>{currencyFormatWithoutSimbol(row?.importe.toString())} <span>&#8364;</span></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
BasicTable.propTypes = {
    rows: PropTypes.array.isRequired,
}

function getModalStyle() {
    const top = 50 ;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: '90%',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: 0,
        borderRadius: 6,
        height:'auto',
        maxHeight: 'calc(100vh - 100px)',
        overflowY: 'scroll',
        overflowX:'hidden',
    },
}));

const JustificantePrecio = ({openAdd, setOpenAdd, partida, partidaInfo, soyFinal, imFromBankPrice}) => {
    const [openUploadModal, setOpenUploadModal] = useState(false);
    const handleOpen = () => setOpenDropZone(true);
    const [openDropZone, setOpenDropZone] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const fileContext = useContext(FileContext);
    const contactContext = useContext(ContactContext);
    const {user, getUser,freeTrialExpired, openFreeTrialExpire} = useContext(AuthContext)
    const {projectId} = useParams()
    const {addImageToPart} = useContext(Budget2023Context)
    const {Contacts, getContacts} = contactContext;
    const {getPostUrl, uploadProcess, getMyFiles, fotos, deleteFile, updateFile} = fileContext;
    const [view, setView] = useState(0)
    const {} = useContext(AuthContext)
    const [openEdit, setOpenEdit] = useState(false)
    
    const handleUpload = (e) =>{
        let auxInfo =  {
            tags : [],
            customDB: false,
            importedProject: false,
            description: "",
            orgId: "",
        }
        e.preventDefault();
        getPostUrl(selectedFiles[0], auxInfo);
        setOpenDropZone(false)
        setOpenUploadModal(true)
    }
    const mostrarPopUp = useRef(null);


    const [ modalStyle ] = useState(getModalStyle);
    const classes = useStyles();
    const {detailedInfo, getDetailedInfo, setDetailedInfo} = useSearch();
    const [coste, setCoste] = useState({
        coste_mano_obra: 0,
        coste_material: 0,
        coste_total: 0
    })
    const onClose = ()=> {
        setOpenAdd(false)
    }
    const [rows, setRows] = useState([]);
    useEffect(()=>{
        if(openAdd){
            if (partidaInfo.elementInfo !== null ){
                    setDetailedInfo(partidaInfo.elementInfo)
                }
                else {
                    getDetailedInfo(partida.partidaId)}
    }
    },[openAdd])
    const [auxRefs, setAuxRef] = useState([])
    useEffect(()=>{
        if(partidaInfo.images){
            updateRefs()
        }
    },[
        partidaInfo.images
    ])
    function updateRefs(){
        let x = []
        for(let i=0; i<partidaInfo.images.length;i++){
            x.push(React.createRef())
        }
        setAuxRef(x)
    }
    useEffect(()=>{
        
        if(detailedInfo){
           if((Object.keys(detailedInfo).length)>1){
            let auxRows = [];
           let elementInfo = null
           if (detailedInfo[`${partida.code}`]){
                elementInfo= detailedInfo[`${partida.code}`]['decompositions']
           }
           else{
                if (detailedInfo.DESGLOSE){
                (elementInfo = detailedInfo['DESGLOSE'])
                }
                else{
                    elementInfo = detailedInfo['decompositions']
                }
           }

           let coste_mano_obra_aux = 0;
        let coste_material_aux = 0;
           for (const key in elementInfo){
               if (!key.includes('%')){
                  
                   let aux = {
                       codigo: elementInfo[key]['CODIGO'],
                       descripcion: elementInfo[key]['RESUMEN'],
                       tipo: elementInfo[key]['TIPO'],
                       precio: elementInfo[key]['PRECIO']['E'],
                       cantidad: partida.unidades?  Number(elementInfo[key]['RENDIMIENTO'])*Number(partida.unidades) : Number(elementInfo[key]['RENDIMIENTO']),
                       unidad: elementInfo[key]['UNIDAD'], 
                       importe: partida.unidades?  Math.floor((Number(elementInfo[key]['PRECIO']['E'])*Number(elementInfo[key]['RENDIMIENTO'])*Number(partida.unidades))*100)/100 : Math.floor((Number(elementInfo[key]['PRECIO']['E'])*Number(elementInfo[key]['RENDIMIENTO']))*100)/100,
                   }
                   if (elementInfo[key]['TIPO'] === '1'){
                        coste_mano_obra_aux += aux.importe;
                   }else{
                       coste_material_aux += aux.importe;
                   }
                   auxRows.push(aux)
               }
           }
              setRows(auxRows)
              setCoste({
                    coste_mano_obra: Math.floor(coste_mano_obra_aux*100)/100,
                    coste_material: Math.floor(coste_material_aux*100)/100,
                    coste_total: Math.floor((coste_mano_obra_aux+coste_material_aux)*100)/100
              })
            }

        }
     
    },[detailedInfo])
    const getLastItem = thePath => thePath.substring(thePath.lastIndexOf('/') + 1)
    const [openEliminate, setOpenEliminate] = useState(false)
    const handleUploadImageToPart = async (e, selectedFile) =>{
        e.preventDefault();
        await addImageToPart(user.sub, partida.partidaId, partida.chapterId, projectId, selectedFile[0], selectedFile[0].name.split('.').slice(-1)[0], partidaInfo.elementInfo)
        setOpenDropZone(false);
    }


    const [foto, setFoto] = useState(null)
    useEffect(()=>{
        if(foto!=null){
            setOpenEliminate(true)
        }
    
    },[foto])

    useEffect(()=>{
        if(openEliminate==false){
            setFoto(null)
        }
    },[openEliminate])

    const [zoom, setZoom] = useState(null)
    const [openZoom, setOpenZoom] = useState(null)
    useEffect(()=>{
        if(zoom!=null){
            setOpenZoom(true)
        }
    
    },[zoom])

    useEffect(()=>{
        if(openZoom==false){
            setZoom(null)
        }
    },[openZoom])
    const [backState, setBackState] = useState(['#ffd52b','#F4F4F4', '#F4F4F4'])
    const changeView = (int) =>{
        setView(int)
        if(int==0){
            setBackState(['#ffd52b','#F4F4F4', '#F4F4F4'])
        }else if(int==1){
            setBackState(['#F4F4F4','#ffd52b', '#F4F4F4'])
        }else{
            setBackState(['#F4F4F4', '#F4F4F4', '#ffd52b'])
        }
    }

    const messagesEndRef = useRef(null)
    const scrollToBottom = () => {
        messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
    }
    return(
        <>
            <Modal open={openAdd} onClose={onClose}>
                
                <Card style={modalStyle} className={classes.paper} >
                    <div className="inline" style={{marginTop:'30px', marginBottom:'30px', marginLeft:'30px'}}>
                        <div style={{backgroundColor:'#F4F4F4', borderRadius:'50px'}}>
                        <button onClick={()=>changeView(0)} style={{width:'113px', borderRadius:'100px', boxShadow:'none', fontSize:'14px', zIndex:'2', backgroundColor:backState[0]}} className="AddContact">{i18n.t("Justificante")}</button>
                        <button onClick={()=>changeView(1)} style={{width:'113px', borderRadius:'100px', boxShadow:'none',fontSize:'14px', marginLeft:'0px', backgroundColor:backState[1]}} className="AddContact">{i18n.t("Adjuntos")}</button>
                        <button onClick={()=>changeView(2)} style={{width:'113px', borderRadius:'100px', boxShadow:'none',fontSize:'14px', backgroundColor:backState[2]}} className="AddContact">{i18n.t("Comentarios")}</button>
                        </div>
                        <div className="inline" style={{textAlign:'right', position:'absolute', display:'flex',  justifyContent:'right', right:'10px'}}>
                            {imFromBankPrice || soyFinal ? null :
                            <YellowButton onClick={()=>{freeTrialExpired ? openFreeTrialExpire():setOpenEdit(true)}} className="inline" style={{width:'100px', height:'40px', justifyContent:"center"}}>
                                <EditOutlinedIcon style={{width:'15px', height:'15px', marginTop:'12px', marginLeft:'-5px', marginRight:'2px'}}/>
                                <HelveticaBold style={{fontSize:'16px', color:'#414141', marginTop:'7px'}}>{i18n.t("Editar")}</HelveticaBold>
                            </YellowButton>
                            }
                        <IconButton aria-label="close" onClick={onClose}>
                                <CloseIcon />
                            </IconButton>
                            </div>
                    </div>
                    <div
                    style={{
                        backgroundColor: '#FFD52B',
                        alignItems: 'center',
                        marginBottom: '0px'
                    }}
                    >
                          <HelveticaBold style={{fontSize:'14px', color: '#414141', textAlign:'justify', paddingRight:'20px', marginLeft:'1rem', paddingTop:'5px', paddingLeft:'10px'}}>
                                {partidaInfo.name}
                            </HelveticaBold>
                        <Box sx={{display:'flex', padding:'0rem', alignItems: 'center'}}>
                                 <Typography sx={{marginLeft: '1rem'}}  style={{paddingLeft:'10px',fontWeight:'500', fontFamily:"Helvetica", fontSize:'14px', color: '#414141', textAlign:'justify', paddingRight:'20px'}}>
                            {partida.description}   
                            </Typography>
                        </Box>
                      
                       
                    </div>
                    <CardContent sx={{padding: '0px', marginTop:'-16PX'}}>
                        {view==0?
                    <div style={{width: '100%',}}>
      
                        <Box display={'flex'} style={{paddingBottom:'4px'}}  sx={{ backgroundColor: '#FFD52B', margin: '0px', marginTop: '1rem'}}>
                          
                        </Box>
                
                        {rows.length > 0 ?
                        <BasicTable rows={rows} />:
                        null
                        }
                        
                        <Divider sx={{
                            margin: '0px',
                            color: '#FFD52B',
                            width: '100%',
                            borderBottomWidth: 2,
                        }}/>
                        <Box display={'flex'} sx={{
                            justifyContent: 'space-around',
                        }}>
                            <Box
                                display={'flex'}
                                justifyContent={'space-evenly'}
                            >
                                <Box
                                    p={1}
                                    m={1}
                                >
                                    <Typography
                                          style={{marginLeft:'40px',fontWeight:'600', fontFamily:"Helvetica", fontSize:'18px'}}
                                    >
                                        {i18n.t("Total")}
                                    </Typography>
                                </Box>
                                <Box display={'flex'} alignItems='center' p={1} m={1}>
                                <Chip style={{marginLeft:'80px',marginTop:'-2px',minWidth:'120px', fontSize:'12px', fontFamily:"Helvetica", fontWeight:'400'}
                                } label={'Mano de obra: '+ coste.coste_mano_obra +   getCurrency(user['custom:currency'])} 
                                variant='outlined'
                                sx={{
                                    margin: '0px',
                                    borderRadius: '4px',
                                    
                                        backgroundColor: 'transparent',
                                        color: ListaColores[2].color, borderColor: ListaColores[2].color,
                                }}/>
                                </Box>
                                <Box display={'flex'} alignItems='center' p={1} m={1}>
                                <Chip style={{marginLeft:'10px',marginTop:'-2px',minWidth:'120px', fontSize:'12px', fontFamily:"Helvetica", fontWeight:'400'}
                                } label={'Costo material: '+ coste.coste_material +  getCurrency(user['custom:currency'])} 
                                variant='outlined'
                                sx={{
                                    margin: '0px',
                                    borderRadius: '4px',
                                    
                                        backgroundColor: 'transparent',
                                        color: ListaColores[4].color, borderColor: ListaColores[4].color,
                                }}/>
                                </Box>
                            </Box>
                            <Box
                                display={'flex'}
                                justifyContent={'space-around'}
                                alignItems={'center'}
                                m={1}
                                p={1}
                            >
                            <Box mx={3} ml={'-40'}>
                                {soyFinal ? 
                                 <Typography  style={{fontWeight:'600', fontFamily:"Helvetica", fontSize:'18px'}}>
                                 {i18n.t("Coste cliente")}
                             </Typography>
                                : 
                                <Typography  style={{fontWeight:'600', fontFamily:"Helvetica", fontSize:'18px'}}>
                                    {i18n.t("Coste directo")}
                                </Typography>
                            }
                            </Box>
                            {
                                console.log(partida)
                            }
                            <Box mx={2}>
                                <Typography  style={{fontWeight:'600', fontFamily:"Helvetica", fontSize:'18px'}}>
                                    {partida.userPrice? currencyFormatDE(parseFloat(partida.userPrice),getCurrency(user['custom:currency']))  : partida.price? currencyFormatDE(parseFloat(partida.price),getCurrency(user['custom:currency'])) : 0 } 
                                </Typography>
                            </Box>
                            </Box>
                        </Box>

                    </div>
                    
                        :view==1?
                        <>
                    <div >
                        <div className="inline" style={{marginTop:'30px'}}>
                            <p id='SubTitulo' style={{fontSize:'14px', marginLeft:'40px'}}>{i18n.t("Imágenes Adjuntas")}</p>
                            <div
                                id="addSubcapinuevo"
                                style={{ marginLeft: "15px", marginRight: "10px" }}
                            >
                                <AddIcon
                                onClick={handleOpen}
                                style={{ width: "20px" }}
                                />
                            </div>
                        </div>
                    </div>

                    <div style={{marginTop:'35px'}}>
                    {partidaInfo.images ?
                        auxRefs.length>0?
                    <Box sx={{ flexGrow: 1 }} style={{marginLeft:'40px', }}>
                        <Grid container spacing={6} columns={{ xs: 2, sm: 8, md: 12 }}>
                        {partidaInfo.images.map((foto, index) => (
                            <Grid item key={index} onMouseEnter={()=>auxRefs[index].current.style.visibility=''} onMouseLeave={()=>auxRefs[index].current.style.visibility='hidden'}>
                            <div ref={auxRefs[index]} style={{height:'25px', width:'254px', backgroundColor:'rgba(65, 65, 65, 0.79)', zIndex:'10', position:'relative', display:'flex', overflow:'auto', visibility:'hidden'}}>
                                <p id='interFont' style={{width:'180px'}}>{getLastItem(foto.url)}</p>
                                <CropFreeIcon style={{color:'white', paddingTop: '1px', width:'20px', marginLeft: '20px', marginRight: '5px', cursor:'pointer'}} onClick={()=>setZoom(foto)}/>
                                <DeleteOutlineIcon style={{color:'white', paddingTop: '1px', width:'20px', cursor:'pointer'}} onClick={()=>setFoto(foto)}/>
                            </div>
                            <img style={{width:'254px', height:'169px', marginTop:'-25px'}} src={foto.url}/>
                            </Grid>
                        ))}
                        
                        </Grid>
                    </Box>: null
                     : null}
                     </div>
                     </>
                    :
                <CommentsContainer conversationId={partida.partidaId} scrollToBottom={scrollToBottom} messagesEndRef={messagesEndRef} /> }
                </CardContent>
                </Card>
                
            </Modal>

            <ModalDeleteImages openEliminate={openEliminate} setOpenEliminate={setOpenEliminate} image={foto} partidaInfo={partidaInfo} />
            <ModalZoomImage openZoom={openZoom} setOpenZoom={setOpenZoom} image={zoom} />
            <ModalEditarPartida open={openEdit} setOpen={setOpenEdit} partida={partidaInfo} />
                    <DocumentDropZone 
                        open={openDropZone}
                        setOpen={setOpenDropZone}
                        selectedFiles={selectedFiles}
                        setselectedFiles={setSelectedFiles}
                        handleSubmit={handleUpload}
                        uploadProcess={uploadProcess}
                        imInPart={true}
                        handleUploadImageToPart = {handleUploadImageToPart}
                    />

        </>
    )
}

JustificantePrecio.propTypes = {
    openAdd: PropTypes.bool.isRequiredCoste,
    setOpenAdd: PropTypes.func.isRequired,
    partida: PropTypes.object.isRequired,
    partidaInfo: PropTypes.object.isRequired,
    soyFinal: PropTypes.bool,
    imFromBankPrice: PropTypes.bool,
}
export default withRouter(JustificantePrecio);