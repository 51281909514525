import React from "react"
import PropTypes from "prop-types"
import i18n from "i18n"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import { ListBank } from "pages/BancoPrecios/ListBank"
const ButtonTypeBank = styled.button` 
  background-color: ${props => props.active ? '#FFD52B' : '#EAEAEA'};
  border: 0px;
  margin-right: -5px;
  z-index: ${props => props.active ? '1' : '0'};
  font-family: "Helvetica";
  font-weight: 500;
  font-size: 14px;
  color: #414141;
  width: 175px;
  height: 30px;
  border-radius: 6px;
  border: 1px solid #FFD52B;
  margin-right: 7.4px;
  :hover{
      background-color: #FFE992 !important;
  }
`

const LayoutBancoRef = ({tipo, referencia
  
}) => {
  const history = useHistory()

  const tipos = ListBank[referencia]?.categorias
  return (
    <>
      <div style={{ maxHeight: "750px", height: "750px", display: "contents" }}>
        <div
          style={{
            marginLeft: "24px",
            marginRight: "10px",
            marginBottom: "30px",
            marginTop: "0px",
          }}
        >   
        <div id="SubMenuProjects">
                <div id="botonesPrincBudget" style={{marginRight:'0px'}}>
        {
            tipos && tipos.map((tp, index) => {
              return (
                <ButtonTypeBank
                style={{width : tp.width && tp.width}}
                  key={index}
                  active={tp.name === tipo}
                  onClick={() => {
                    history.push(`/bancodeprecios/referencia/${referencia}/${tp.uri}`)
                  }}
                >
                  {i18n.t(tp.name)}
                </ButtonTypeBank>
              )
            })
          }
          </div>
        </div>
           
          
        </div>
      </div>
  
    </>
  )
}
LayoutBancoRef.propTypes = {
  tipo: PropTypes.string,
    referencia: PropTypes.string
}
export default LayoutBancoRef
