import React, { useContext, useEffect, useState } from "react"
import propTypes from "prop-types"
import {
  Modal,
  Box,
  IconButton,
  Grid,
} from "@mui/material"
import PDFPreview from "./pdfPreview"
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded"
import SendSteps from "./sendSteps"
import { useParams } from "react-router-dom"
import DownloadBlockModal from "../DownloadBlockModal"
import AuthContext from "context/auth/authContext"
import Budget2023Context from "context/budget2023/Budget2023Context"
import ModalCallError from "common/modalCallError"

const SendClasses = {
  position: "absolute",
  top: "0%",
  right: "0%",
  height: "100%",
  width: "80%",
  backgroundColor: "white",
  paddingRight: "0px",
  borderRadius: "16px 0px 0px 16px",
  overflowY: "auto",
  overflowX: "auto",
}

const SendModal = ({open, setOpen, selectedBudget, contactos, capitulo, proposalTree, groupId, chaptersChecked, soyFinal}) => {

  const {projectId} = useParams()

  const { budgetTree,  downloadBudget, getSelectedChapters } =
    useContext(Budget2023Context)
  useEffect(() => {
    if (open == true) {
      //getSelectedBudget([], budgetTree.subcapitulos)
      getSelectedChapters([], [], budgetTree.subcapitulos, true)
    }
  }, [open])

  const {user, getUser} = useContext(AuthContext)

 useEffect(() => {
    if(!user){getUser()}
  }, [])


  const [loadingDown, setLoadingDown] = useState(false)
  const [openBlockModal, setOpenBlockModal] = useState(false)
  const [openError, setOpenError] = useState(false)
  const [errorToSend, setErrorToSend] = useState('')


  const handleClick = async(e) => {
    e.preventDefault()
    if(user['custom:subscription_plan']=='FREE_TRIAL'){
      setOpenBlockModal(true)
    }else{
      setLoadingDown(true)
      let result = await downloadBudget(projectId, 'budget', 'pdf', soyFinal? 'PF' : 'PC', {
        price: true,
        client: true,
        company: true,
        description: true,
        preview:false,
        briefing: true,
        showCover: true,
      })
      setLoadingDown(false)
      if(result?.error){
        setOpenError(true)
        setErrorToSend(result)
      }
    }
  }
  const {getBudgetPDF} = useContext(Budget2023Context);

  const [activeStep, setActiveStep] = useState(0)
  useEffect(() => {
    if(open){
    getBudgetPDF(projectId);
    }
    
},[open]);

const [selectedContacts, setSelectedContacts] = useState([]);
const [showPdf, setShowPdf] = useState(false);






  return (
    open ?
    <>
      <Modal
        open={open}
        onClose={() => {
         null
        }}
      >
        <Box style={{...SendClasses, width:(activeStep == 2 && showPdf) ? '80%' :'38%'}}>
       

            <Grid container direction={"row"} marginTop={"0rem"} spacing={0}>
              {
                (activeStep == 2 && showPdf) && 
              
                <Grid item xs={6.5}>
                   <PDFPreview projectId={projectId}/>
                </Grid>
            }
                <Grid style={{position:'relative'}} item xs={  (activeStep == 2 && showPdf) ? 5.5 : 12}>
                <IconButton
              style={{ position: "absolute", top: "20px", left: "20px" }}
                sx={{  border: "1px solid #000001", height: "25px", width: "25px", color: "black", marginBottom: "1rem"}}
                onClick={() => {setOpen(false)}}
              >
                <ArrowForwardIosRoundedIcon style={{ width: "15px" }} />
              </IconButton>
                    <SendSteps setShowPdf={setShowPdf} selectedContacts={selectedContacts} setSelectedContacts={setSelectedContacts}
                    activeStep={activeStep} setActiveStep={setActiveStep} soyFinal={soyFinal} setOpen={setOpen} open={open} selectedBudget={selectedBudget} contactos={contactos} capitulo={capitulo} proposalTree={proposalTree} groupId={groupId} chaptersChecked={chaptersChecked}/>
                </Grid>
            </Grid>

        </Box>
      </Modal>
      {
          openBlockModal && <DownloadBlockModal open={openBlockModal} setOpen={setOpenBlockModal} 
          name={'budget'} tipo={'pdf'} typeBudget={soyFinal? 'PF' : 'PC'} muestraP={true} muestraUser={true} muestrCompany={true} muestraDescription={true} soyFinal={soyFinal}/>
        }
        {
          openError && <ModalCallError open={openError} setOpen={setOpenError} error={errorToSend} ubicacion={'Modal enviar presupuesto'} />
        }
    </>
    : null
  )
}

SendModal.propTypes = {
  open: propTypes.bool.isRequired,
  setOpen: propTypes.func.isRequired,
  selectedBudget: propTypes.object,
  contactos: propTypes.array.isRequired,
  capitulo: propTypes.object,
  proposalTree:propTypes.array,
  groupId:propTypes.string,
  chaptersChecked:propTypes.bool,
  soyFinal:propTypes.bool
}

export default SendModal
