
  
  const FacturasReducer = (state, action) => {
    switch (action.type) {
        case "GET_FACTURAS_LOADING":
            return {
                ...state,
                loading:true
            }
        case 'GET_FACTURAS_SUCCESS':
            return {
                ...state,
                facturas: action.payload.facturas,
                total: action.payload.total,
                subtotal: action.payload.subtotal,
                loading: false
            }
        case "CREATING_FACTURA":
            return {
                ...state,
                loading:true
            }
        case 'FACTURA_CREATED':
            let aux = state.facturas
            aux.push(action.payload)
            return {
                ...state,
                facturas: aux,
                loading: false
            }
        case 'FACTURA_CREATED_WITHOUT_LOADING':
            let aux5 = state.facturas
            aux5.push(action.payload)
            return {
                ...state,
                facturas: aux5,
            }
        case "INVOICE_STATUS_UPDATED":
            let aux2 = state.facturas
            for (let i = 0; i < aux2.length; i++) {
                if(aux2[i].invoiceId === action.payload.invoiceId){
                    aux2[i].invoiceStatus = action.payload.invoiceStatus
                }
            }
            return {
                ...state,
                facturas: aux2
            }
        case "FACTURA_DELETED":
            let aux3 = state.facturas
            for (let i = 0; i < aux3.length; i++) {
                if(aux3[i].invoiceId === action.payload){
                    aux3.splice(i, 1)
                }
            }
            return {
                ...state,
                facturas: aux3
            }
        case "GET_FACTURA_COMPLETE_SUCCESS":
            return {
                ...state,
                facturaInfo: action.payload,
                loading: false
            }
        case "FACTURA_UPDATED": 
            let aux4 = state.facturas
            for (let i = 0; i < aux4.length; i++) {
                if(aux4[i].invoiceId === action.payload.invoiceId){
                    aux4[i] = action.payload
                }
            }
            return {
                ...state,
                facturas: aux4
            }
        case "GET_PDF_LOADING":
            return {
                ...state,
                loadingPDF: true
            }
        case "GET_INVOICE_PDF_SUCCESS":
            return {
                ...state,
                loadingPDF: false,
                invoicePdf: action.payload
            }
        case 'RESET':
            return {
                ...state,
                loading:false
            }
        case 'SEND_INVOICE_LOADING':
            return {
                ...state,
                loadingSendInvoice: true
            }
        case 'SEND_INVOICE_SUCCESS':
            return {
                ...state,
                loadingSendInvoice: false
            }
        default:
            return state
    }
  }
  
  export default FacturasReducer
  