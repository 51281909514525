import CloseIcon from "@mui/icons-material/Close";
import {Modal} from "@mui/material";
import React, { useState, useContext} from "react";
import { useHistory } from "react-router-dom"
import {makeStyles} from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import AuthContext from "../../../context/auth/authContext"
import i18n from "i18n";
import { HelveticaLight } from "common/typographies";

function getModalStyle() {
    const top = 50 ;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: '630px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        borderRadius: 6,
        height:'470px',
        maxHeight: 'calc(100vh - 100px)',
        overflowY: 'auto',
        overflowX:'hidden',
    },
}));

const ModalClosePassword = ({openPassword, setOpenPassword}) => {
    const [ modalStyle ] = useState(getModalStyle);
    const history = useHistory()
    const classes = useStyles();
    const authContext = useContext(AuthContext)
    const { logOut } = authContext
    const onClose = ()=> {
        setOpenPassword(false)
    }
    const cerrarsesion = () => {
        logOut()
        setTimeout(() => {
          history.push("/")
        },2000)
    }
    return (<>
        <Modal open={openPassword} onClose={onClose}>
            <div style={modalStyle} className={classes.paper}>
                <CloseIcon id="cerrar" onClick={onClose}></CloseIcon>
                <div id="tituloContra" style={{fontSixe:'14px', textTransform:'uppercase', marginTop:'120px'}}>{i18n.t('¡Atención!')}</div>
                <div id='centerPaco' style={{marginTop:'0px'}}>
                    <HelveticaLight style={{fontSize:'14px'}}>
                        {i18n.t('Estás apunto de cerrar sesión, ¿estás seguro de que quieres salir?')}
                    </HelveticaLight>
                </div>
                <div id="centerButton" style={{marginTop:'70px'}}>
                    <div id='DivTitleEliminar2' style={{marginLeft:'70px', marginBottom:'10px'}}>
                        <button type="button" style={{width: '190px', marginRight: '40px', borderRadius:'6px'}}
                                className="CancelDel" onClick={()=>{setOpenPassword(false)}}>{i18n.t("Cancelar")}</button>
                        <button type="button" style={{width: '190px', position:'initial', marginTop:'0px', color:'white', backgroundColor:'#BF2929'}}
                                className="AddProject"  onClick={cerrarsesion}>{i18n.t("Cerrar Sesión")}</button>
                    </div>
                </div>
            </div>
        </Modal>
    </>
)
}


ModalClosePassword.propTypes = {
    openPassword: PropTypes.bool.isRequired,
    setOpenPassword: PropTypes.func.isRequired,
}
export default withRouter(ModalClosePassword);
