import { isInteger } from "lodash"
import { API_URLS, CORE_URLS, CognitoUserPools, AppsyncSettings } from "./config"

export const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    window.location.hostname === "dev.app.planhopper.com" ||
    window.location.hostname === "qa.app.planhopper.com" ||
    window.location.hostname === "app.dev.planhopper.com" ||
    window.location.hostname === "develop.app.planhopper.com",
  // 127.0.0.1/8 is considered localhost for IPv4.
  window.location.hostname.match(
    /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
  )
)

export const isStg = (url) => { return url.includes("stg"); }

export const default_env = "dev"

// Parse window.location.hostname to determine the environment
const location = window.location.hostname.split(".")[1]
export const API_URL = String(
  location in API_URLS ? API_URLS[location] : API_URLS[default_env]
)
console.log("API_URL: " + API_URL)

export const CORE_URL = String(
  location in CORE_URLS ? CORE_URLS[location] : CORE_URLS[default_env]
)
console.log("CORE_URL: " + CORE_URL)

export const COGNITO_SETTINGS = CognitoUserPools[location] || CognitoUserPools[default_env]
console.log("COGNITO_SETTINGS: ", COGNITO_SETTINGS)

export const APPSYNC_SETTINGS = AppsyncSettings[location] || AppsyncSettings[default_env]
console.log("APPSYNC_SETTINGS: ", APPSYNC_SETTINGS)

export const getCurrency = currency => {
  switch (currency) {
    case "Euro":
      return "€"
    case "Dolar":
      return "$"
    case "Libra":
      return "£"
    case "Peso Argentino":
      return "ARS$"
    case "Peso Chileno":
      return "CLP$"
    case "Peso Colombiano":
      return "COP$"
    case "Peso Mexicano":
      return "MXN$"
    case "Peso Uruguayo":
      return "UYU$"
    case "Balboa":
      return "B/."
    default:
      return "€"
  }
}

export const formatTableValue = value => {
  if (value === "0.00" || value === "0,00") {
    return 0
  }

  if (typeof value !== "number") {
    return value
  }

  if (value === 0) {
    return 0
  }

  if (isInteger(value)) {
    return value
  }

  const roundedNumber = value.toFixed(2)
  return Number.isInteger(roundedNumber)
    ? parseInt(roundedNumber)
    : parseFloat(roundedNumber)
}

export const partidaGetClientPrice = (p) => {
    var margin = 0
	p.margin == 100 ? margin = p.price * p.quantity * 100 : margin = p.price * p.quantity / (1 - p.margin/100)
    return margin
}

export const emptyMeasure = (measure) => {
	    return (Object.hasOwn(measure, 'description') ? measure.description == '' : true)
        && (Object.hasOwn(measure, 'quantity') ? measure.quantity == 0 : true)
        && (Object.hasOwn(measure, 'depth') ? measure.depth == 0 || measure.depth == 1 : true)
        && (Object.hasOwn(measure, 'width') ? measure.width == 0 : true)
        && (Object.hasOwn(measure, 'height') ? measure.height == 0 : true)
        && (Object.hasOwn(measure, 'total') ? measure.total == 0 : true)
}

export const checkIfMeasuresAreEmpty = (measures) => {
	for (let i=0; i<measures.length; i++) {
          if (!emptyMeasure(measures[i])) return false
    }
    return true
}

