import React, { useContext } from "react"
import {HelveticaBold} from "common/typographies.js" 
import i18n from "i18n";
import PropTypes from "prop-types"
import { getCurrency } from "utils/functions"
import AuthContext from "context/auth/authContext";

const DetailsFooter = ({partidaState}) => {
    const {user} = useContext(AuthContext)
    return(
        <div className="inline" style={{width:'100%' , borderTop:'1px solid #C4C4C4', flexDirection:'column', padding:'15px 0 0 15px'}}>
            <div className='inline' style={{width:'100%'}}>
                <div>
                    <HelveticaBold style={{color:'#414141', fontSize:'12px', marginTop:'0px', marginBottom:'10px'}}>{i18n.t("Materiales")}</HelveticaBold>
                </div>
                <div style={{marginLeft:'auto', marginRight:'10%'}}>
                    <HelveticaBold style={{color:'#414141', fontSize:'12px', marginTop:'0px', marginBottom:'10px'}}>{
                      (partidaState.priceMaterial ? parseFloat(partidaState.priceMaterial).toFixed(2) : '0,00') + ' ' + getCurrency(user['custom:currency'])
                    }</HelveticaBold>
                </div>
            </div>
            <div className='inline' style={{width:'100%'}}>
                <div>
                    <HelveticaBold style={{color:'#414141', fontSize:'12px', marginTop:'0px', marginBottom:'10px'}}>{i18n.t("Mano de obra")}</HelveticaBold>
                </div>
                <div style={{marginLeft:'auto', marginRight:'10%'}}>
                    <HelveticaBold style={{color:'#414141', fontSize:'12px', marginTop:'0px', marginBottom:'10px'}}>{
                      (partidaState.priceManoDeObra ? parseFloat(partidaState.priceManoDeObra).toFixed(2): '0,00') + ' ' + getCurrency(user['custom:currency'])
                    }</HelveticaBold>
                </div>
            </div>
            <div className='inline' style={{width:'100%'}}>
                <div>
                    <HelveticaBold style={{color:'#414141', fontSize:'12px', marginTop:'0px', marginBottom:'10px'}}>{i18n.t("Otros")}</HelveticaBold>
                </div>
                <div style={{marginLeft:'auto', marginRight:'10%'}}>
                    <HelveticaBold style={{color:'#414141', fontSize:'12px', marginTop:'0px', marginBottom:'10px'}}>{
                      (partidaState.priceOther ? parseFloat(partidaState.priceOther).toFixed(2) : '0,00') + ' ' + getCurrency(user['custom:currency'])
                    }</HelveticaBold>
                </div>
            </div>
        </div>
    )
}

DetailsFooter.propTypes = {
    partidaState: PropTypes.object.isRequired
}

export default DetailsFooter
