import React, { useReducer } from "react"
import PropTypes from "prop-types"
import { API, Auth } from "aws-amplify"
import ActsContext from "./actsContext"
import actsReducer from "./actsReducer"

import {
  SET_SELECTED_ACTA,
  GET_ACTS,
  GET_ACTA,
  ADD_ACT,
  UPDATE_ACT,
  UPDATE_PENDING_SUBJECT,
} from "context/types"

const actsState = props => {
  const initialState = {
    acts: [],
    selectedActa: null,
    sendingAct: false,
    updatedsPendingSubjects: [],
  }

  const [state, dispatch] = useReducer(actsReducer, initialState)

  const updatePendingSubject = subject => {
    dispatch({ type: UPDATE_PENDING_SUBJECT, payload: subject})
  }

  const setSelectedActa = acta => {
    dispatch({ type: SET_SELECTED_ACTA, payload: acta })
  }

  const getActs = async (projectId, user) => {
    const apiName = "service-acts"
    const path = `getByProject?projectId=${projectId}&accountId=${user.sub}`
    const req = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        "Access-Control-Allow-Origin": "*",
      },
    }
    const res = await API.get(apiName, path, req)
    for(let i=0; i<res.length; i++){
      if(!res[i].pdfLink){
        res[i].pdfLink = await generatePdf(res[i].actId, user.email)
      }
    }
    dispatch({ type: GET_ACTS, payload: res })
  }

  const getActa = async ActaId => {
    const apiName = "service-acts"
    const path = `getActById/${ActaId}`
    const req = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        "Access-Control-Allow-Origin": "*",
      },
    }
    const res = await API.get(apiName, path, req)
    dispatch({ type: GET_ACTA, payload: res })
  }

  const createAct = async (data, projectInfo, user) => {
    const apiName = "service-acts"
    const path = "createAct"
    const newAttendants = []

    for (const attendant of data.attendees) {
      let aux = {...attendant, contactid: attendant.id, name: attendant.completeName}
      newAttendants.push(aux)
    }
    const newSubjects = []
    for (const subject of data.subjects) {
      let aux = {...subject, contact: {...subject.responsible, contactid:subject.responsible.id, name: subject.responsible.completeName}, responsible: subject.responsible.completeName}
      newSubjects.push(aux)
    }

    const req = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        "Access-Control-Allow-Origin": "*",
      },
      body: {
        projectId: projectInfo.projectId,
        project: [{ name: projectInfo.name, projectId: projectInfo.projectId }],
        projectName: projectInfo.name,
        accountId: user.sub,
        director: data.director,
        author: data.author,
        themes: data.themes,
        attendees: newAttendants,
        extras: newSubjects,
        organizationId: user["custom:organizationId"],
      },
    }
    const res = await API.post(apiName, path, req)
    await generatePdf(res.actId, user.email)
    dispatch({ type: ADD_ACT, payload: res })
  }

  const updateAct = async (data, projectInfo, user) => {
    const apiName = "service-acts"
    const path = "updateAct"

    const newAttendants = []

    for (const attendant of data.attendees) {
      let aux = {...attendant, contactid: attendant.id, name: attendant.completeName}
      newAttendants.push(aux)
    }
    const newSubjects = []
    for (const subject of data.subjects) {
      let aux = {...subject, contact: {...subject.responsible, contactid:subject.responsible.id, name: subject.responsible.completeName}, responsible: subject.responsible.completeName}
      newSubjects.push(aux)
    }
    const req = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        "Access-Control-Allow-Origin": "*",
      },
      body: {
        actId: data.actId,
        projectId: projectInfo.projectId,
        project: [{ name: projectInfo.name, projectId: projectInfo.projectId }],
        projectName: projectInfo.name,
        accountId: user.sub,
        director: data.director,
        author: data.author,
        themes: data.themes,
        attendees: newAttendants,
        extras: newSubjects,
      },
    }
    const res = await API.put(apiName, path, req)
    await generatePdf(data.actId, user.email)
    dispatch({ type: UPDATE_ACT, payload: res })
  }

  const getPdf = async (actId, accountId) => {
    const apiName = "service-acts"
    const path = `getActPDF?actId=${actId}&accountId=${accountId}`
    const req = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        "Access-Control-Allow-Origin": "*",
      },
    }
    const res = await API.get(apiName, path, req)
    return res.url
  }

  const sendAct = async (actId, accountId, asistentes) => {
    const apiName = "service-acts"
    const path = `sendAct`
    const req = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        "Access-Control-Allow-Origin": "*",
      },
    }
    dispatch({ type: 'SENDING_ACT'})
    for(let i=0; i<asistentes.length; i++){
      const body = {
        toAddress: asistentes[i].email,
        contactId: asistentes[i].contactid,
        actId: actId,
        accountId: accountId
      }
      await API.post(apiName, path, {...req, body:body})
    }
    dispatch({ type: 'SEND_ACT_COMPLETE'})
  }

  const generatePdf = async (actId, email) => {
    const apiName = "service-acts"
    const path = `generatePDF/${actId}`
    const req = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        "Access-Control-Allow-Origin": "*",
      },
      body:{
        email: email
      }
    }
    const res = await API.post(apiName, path, req)
    return res.url
  }

  return (
    <ActsContext.Provider
      value={{
        acts: state.acts,
        selectedActa: state.selectedActa,
        sendingAct: state.sendingAct,
        updatedsPendingSubjects: state.updatedsPendingSubjects,
        setSelectedActa,
        updatePendingSubject,
        getActs,
        getActa,
        createAct,
        updateAct,
        getPdf,
        sendAct,
        generatePdf
      }}
    >
      {props.children}
    </ActsContext.Provider>
  )
}

actsState.propTypes = {
  children: PropTypes.element,
}

export default actsState
