import React, { useState, useContext, useEffect } from "react"
import { ChapterContainer } from "common/containers"
import { ChapterTypo, PoppinsNormal } from "common/typographies"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import i18n from "i18n"
import {ShowParentDiv } from "common/containers"
import CapituloAutoGestion from "./chapters/AutoGestion/CapituloAutoGestion"
import Budget2023Context from "context/budget2023/Budget2023Context"

const AutoGestionBudget = () => {
  const { budgetTree, loadingTree } = useContext(Budget2023Context)
  const [openNotSendBudget, setOpenNotSendBudget] = useState(false)
  const [showError, setShowError] = useState(0)
  const handleOpen = () => {
      setOpenNotSendBudget(prev => !prev)
    
  }
  useEffect(() => {
    if(budgetTree.subcapitulos.length>0){
     let a = 0;
      for(let i=0; i<budgetTree.subcapitulos.length;i++){
        if(budgetTree.subcapitulos[i].selfManagement!='false'){
          a=a+1
        }
      }
      setShowError(a)
    }
   }, [loadingTree, openNotSendBudget])
    return (
        <>
        <PoppinsNormal style={{fontWeight:'600', marginBottom:'32px', marginTop:'32px'}}>{i18n.t("AUTOGESTIÓN")}</PoppinsNormal>
        <ChapterContainer style={{backgroundColor:'#FFC727', color:'white', borderLeft:'7px solid #D8AA28'}}>
              <tr  style={{marginTop: '0px'}}>
                <td style={{minWidth:'37px', paddingLeft: '3px'}}>
                {openNotSendBudget ? (
              <div>
                <KeyboardArrowDownIcon
                  style={{ cursor: "pointer", marginLeft: "3px" }}
                  className="dropdownArrowB"
                  onClick={handleOpen}
                />
              </div>
            ) : (
              <KeyboardArrowRightIcon
                style={{ cursor: "pointer", marginLeft: "3px" }}
                className="dropdownArrowB"
                onClick={handleOpen}
              />
            )}  </td>
                <td style={{minWidth:'300px'}}>
                    <ChapterTypo style={{marginLeft:'0px', color:'white'}}>{i18n.t("AUTOGESTIÓN")}</ChapterTypo>
                </td>
            </tr> 
        </ChapterContainer>
        {openNotSendBudget ? (
        loadingTree ? (
          <>
            <div
              style={{
                justifyContent: "center",
                width: "100%",
                textAlign: "center",
                position: "relative",
                display: "flex",
                marginLeft: "-100px",
                marginTop: "70px",
              }}
            >
              <div className="loader">
                <div className="ball"></div>
                <div className="ball"></div>
                <div className="ball"></div>
              </div>
            </div>
            <div
              style={{
                justifyContent: "center",
                width: "100%",
                textAlign: "center",
                position: "relative",
                display: "flex",
                marginLeft: "-30px",
              }}
            >
              <PoppinsNormal>{i18n.t("Cargando...")}</PoppinsNormal>
            </div>
            <div
              style={{
                justifyContent: "center",
                width: "100%",
                textAlign: "center",
                position: "relative",
                display: "flex",
                marginLeft: "-30px",
                marginTop: "-15px",
              }}
            >
              <PoppinsNormal style={{ fontWeight: "400" }}>
                {i18n.t("Esta acción puede tardar unos segundos")}
              </PoppinsNormal>
            </div>
          </>
        ) : 
          showError==0 ?  
            <div
            style={{backgroundColor:'#FFF1E7', paddingLeft:'35px', height:'33px', width:'100%', borderRadius:'6px', marginTop:'-16px', paddingTop:'6px', fontFamily:'Roboto', fontWeight:'300', fontSize:'12px'}}
            > {i18n.t('No has seleccionado ningun elemento para Autogestión')} </div>
            :
          <ShowParentDiv>
            {budgetTree.subcapitulos.map((capitulo, i) => (
            
              capitulo.selfManagement=='true' ?
              (
              <CapituloAutoGestion key={capitulo.chapterId} i={i} capitulo={capitulo} />
              ): null ))}
           
          </ShowParentDiv>
        )
       : null}
        </>
    )
}

export default AutoGestionBudget