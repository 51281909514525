import { BlueButton, CapituloMini } from "common/containers";
import React, {useState, useContext} from "react";
import PropTypes from 'prop-types'
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import { currencyFormatDE } from "lib/transformers";
import { getCurrency } from "utils/functions";
import AuthContext from "context/auth/authContext";
import Budget2023Context from "context/budget2023/Budget2023Context";


const CapituloModal = ({capitulo, capituloSelected, setCapituloSelected, margin}) => {
    const [openNotSendCapitulo, setOpenNotSendCapitulo] = useState(false)
    const {getSubCapitulosContent} = useContext(Budget2023Context)
    const {user} = useContext(AuthContext)
    const [cargado, setCargado] = useState(false)
    const handleOpen = async(e) => {
        if (!e) var e = window.event;
        e.cancelBubble = true;
        if (e.stopPropagation) e.stopPropagation();
        setOpenNotSendCapitulo(prev => !prev)
        if(cargado==false){
          getSubCapitulosContent(capitulo.projectId, capitulo.chapterId)
          setCargado(true)
        }
      }
    const handleCheckBox = () => {
      if (capituloSelected.chapterId == capitulo.chapterId){
        setCapituloSelected({})
      } else {
        setCapituloSelected(capitulo)
      }
    }
    return (
        <>
        <CapituloMini
                   onClick={() => handleCheckBox()}
          style={{background: capituloSelected.capituloInfo? 
            capituloSelected.capituloInfo.chapterId? 
              capituloSelected.capituloInfo.chapterId==capitulo.capituloInfo.chapterId ? 
                '#FFE992' : 'white'
              : capituloSelected.chapterId==capitulo.chapterId ? 
              '#FFE992' : 'white'
            : 'white', paddingLeft:margin+'px'}}>
            {openNotSendCapitulo ? (
                <div>
                  <KeyboardArrowDownIcon
                    style={{ cursor: "pointer", marginLeft: "5px", marginTop:'5px', visibility: capitulo.subcapitulos.length==0 && cargado==true ? 'hidden' : 'visible'  }}
                    className="dropdownArrowB"
                    onClick={(e) => handleOpen(e)}
                  />
                </div>
              ) : (
                <KeyboardArrowRightIcon
                  style={{ cursor: "pointer", marginLeft: "5px", marginTop:'5px', visibility: capitulo.subcapitulos.length==0 && cargado==true ? 'hidden' : 'visible' }}
                  className="dropdownArrowB"
                  onClick={(e) => handleOpen (e)}
                />
              )}
              {
              openNotSendCapitulo && capitulo.subcapitulos.length <= 0 ?
                <input
                  className="checkboxChapter"
                  type={"checkbox"}
       
                  checked={capitulo.chapterId == capituloSelected.chapterId}
                  style={{
                    display: capitulo.chapterId == capituloSelected.chapterId ? 'block' : 'none' ,
                    width: "13px",
                   
                    cursor: "pointer",
                    marginTop: "-2px",
                  }}
                ></input>
                :
                null
              }
              <p>
                {capitulo.capituloInfo.name}
              </p>
              <BlueButton style={{color:'white', justifyContent:'center', textAlign:'center', marginRight:'26px', marginTop:'6px'}}>
                {currencyFormatDE(capitulo.total, getCurrency(user['custom:currency']))}
              </BlueButton>
        </CapituloMini>
        {openNotSendCapitulo ? 
            capitulo.subcapitulos.length > 0 ? (
                capitulo.subcapitulos.map((subcapitulo, index) => (
                    <CapituloModal key={index} capitulo={subcapitulo} margin={margin+20} capituloSelected={capituloSelected} setCapituloSelected={setCapituloSelected} />
                    
                ))
            ) : null 
            : null}
        </>
    )
}

CapituloModal.propTypes = {
    capitulo: PropTypes.object.isRequired,
    capituloSelected: PropTypes.object.isRequired,
    setCapituloSelected: PropTypes.func.isRequired,
    margin: PropTypes.number.isRequired

}

export default CapituloModal;
