import i18n from "i18n";
import {Chip, Modal} from "@mui/material";
import {makeStyles} from "@material-ui/core/styles";
import React, { useState, useContext } from "react";
import PropTypes from 'prop-types'
import imagen1 from "assets/customIcons/invoices/crear-factura.svg"
import imagen2 from "assets/customIcons/invoices/plan-de-pago.svg"
import imagen3 from "assets/customIcons/invoices/certificacion.svg"
import { useParams } from "react-router-dom";
import { nanoid } from 'nanoid';
import { useHistory } from "react-router-dom";
import ModalScanInvoices from "components/Invoice/modals/modalScanInvoices";
import ModalDefinirFases from "components/Facturas/modals/modalDefinirFases";
import ModalSelectProject from "components/Facturas/modals/modalSelectProject";
function getModalStyle() {
    const top = 50 ;
    const left = 50;
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };

}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: '550px',
        backgroundColor: 'white',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        borderRadius: 6,
        maxHeight: '500px',
        overflowY: 'auto',
        overflowX:'hidden',
        paddingLeft:'0px',
        paddingRight:'0px'
    },
}));

const ModalCreateFactura = ({open, setOpen, invoiceService}) => {
    const [ modalStyle ] = useState(getModalStyle);
    const classes = useStyles();
    const history = useHistory();
    const [openFacturaFromBudgeting, setOpenFacturaFromBudgeting] = useState(false)
    const [openScanInvoices, setOpenScanInvoices] = useState(false)
    const {projectId} = useParams()
    const [openDefinirFases, setOpenDefinirFases] = useState(false);
    const [openProject, setOpenProject] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);
    const handleCreateNewFactura = () => {
        history.replace(
            `/invoice/${nanoid()}${projectId ? `?projectId=${projectId}` : ''}`
        )
    }
    const imGeneral = projectId ? false: true;
    
  return(
      <>
    <Modal
        open={open}
        onClose={() => {setOpen(false)}}
    >
        <div style={modalStyle} className={classes.paper}>
        <>
        <div
            onClick={handleCreateNewFactura}
            className="CardProjects" style={{ marginLeft:'40px', cursor:'pointer', width:'85%',   border:'1px solid #C4C4C4'}} >
            <div id="CardContent">
                <div className="ImgProyect" style={{marginLeft:'0px', marginRight:'20px'}}>
                    <span className="avatar-title rounded-circle bg-light text-danger font-size-16">
                        <img src={imagen1}  height="74px" width='74px' />
                    </span>
                    </div>
                    <div id="CenterContentProyectCard" style={{marginLeft: '105px', marginRight:'20px', textAlign:'left', fontSize:'14px'}}>
                        <div className="flex-grow-1 overflow-hidden" style={{marginRight:'-10px'}}>
                        <div id="AddProyecto" style={{fontSize:'18px', marginTop:'-5px'}}>
                                {i18n.t("Comienza desde cero")}
                        </div>
                        <p className="DescriptionText" style={{fontSize:'14px', marginRight:'0px'}}><b style={{fontSize:'14px', fontFamily:"Helvetica"}}>Crearás tu factura manualmente desde cero</b>{i18n.t(" en base a tus precios de referencia.")} </p>
                    </div>
                    </div>
            </div>
        </div>
        <div className="CardProjects" onClick={()=>{if(imGeneral) {setOpenProject(true); setOpen(false);} else{setOpenDefinirFases(true); setOpen(false);}}} style={{ marginLeft:'40px', cursor:'pointer', width:'85%', border:'1px solid #C4C4C4'}} >
            <div id="CardContent">
                <div className="ImgProyect" style={{marginLeft:'0px', marginRight:'20px'}}>
                    <span className="avatar-title rounded-circle bg-light text-danger font-size-16">
                        <img src={imagen2}  height="75px" width='54px' />
                    </span>
                    </div>
                    <div id="CenterContentProyectCard" style={{marginLeft: '105px', marginRight:'20px', textAlign:'left', fontSize:'14px'}}>
                        <div className="flex-grow-1 overflow-hidden" style={{marginRight:'-10px'}}>
                            <div id="AddProyecto" style={{fontSize:'18px', marginTop:'-5px'}}>
                                    {i18n.t("Factura desde presupuesto")}
                            </div>
                            <p className="DescriptionText" style={{fontSize:'14px'}}>Crea tu factura a partir de tu <b style={{fontSize:'14px', fontFamily:"Helvetica"}}>presupuesto del proyecto.</b></p>
                        </div>
                    </div>
            </div>
        </div>
 
        {/* <div
            onClick={()=>{setOpenScanInvoices(true); setOpen(false)}}
            className="CardProjects" style={{ marginLeft:'40px', cursor:'pointer', width:'85%',   border:'1px solid #C4C4C4'}} >
            <div id="CardContent">
                <div className="ImgProyect" style={{marginLeft:'0px', marginRight:'20px'}}>
                    <span className="avatar-title rounded-circle bg-light text-danger font-size-16">
                        <img src={imagen2}  height="74px" width='74px' />
                    </span>
                    </div>
                    <div id="CenterContentProyectCard" style={{marginLeft: '105px', marginRight:'20px', textAlign:'left', fontSize:'14px'}}>
                        <div className="flex-grow-1" style={{marginRight:'-10px'}}>
                        <div id="AddProyecto" style={{fontSize:'18px', marginTop:'-5px'}}>
                                {i18n.t("Escanea tus documentos")} 
                            <span style={{fontSize:'14px', fontFamily:"Helvetica"}}> &nbsp;
                                <Chip label={"BETA"} size={'small'}/>
                            </span> 
                        </div>
                        <p className="DescriptionText" style={{fontSize:'14px', marginRight:'0px'}}><b style={{fontSize:'14px', fontFamily:"Helvetica"}}>Sube tus facturas en el formato que quieras </b>{i18n.t(" y deja que Planhopper haga el resto.")} </p>
                    </div>
                </div>
            </div>
        </div> */}
          <div className="CardProjects" style={{ cursor:'default', backgroundColor:'#e9e9e9',
            marginLeft:'40px', cursor:'pointer', width:'85%'}} >
            <div id="CardContent">
                <div className="ImgProyect" style={{marginLeft:'0px', marginRight:'20px'}}>
                    <span className="avatar-title rounded-circle bg-light text-danger font-size-16">
                        <img src={imagen3}  height="62px" width='75px' />
                    </span>
                    </div>
                    <div id="CenterContentProyectCard" style={{marginLeft: '105px', marginRight:'20px', textAlign:'left', fontSize:'14px'}}>
                        <div className="flex-grow-1 overflow-hidden" style={{marginRight:'-10px'}}>
                            <div id="AddProyecto" style={{fontSize:'18px', marginTop:'-5px'}}>
                                    {i18n.t("Hacer una certificación")}
                            </div>
                            <p className="DescriptionText" style={{fontSize:'14px'}}>Realiza una certificación a partir de tu <b style={{fontSize:'14px', fontFamily:"Helvetica"}}>presupuesto del proyecto.</b></p>
                        </div>
                    </div>
                    <div style={{top:'10px', right:'10px'}} id='muypronto2'>{i18n.t('muy pronto')}</div>
            </div>
        </div>
     </>
        
        </div>
    </Modal>

    {
        openScanInvoices && <ModalScanInvoices 
            open={openScanInvoices}
            setOpen={setOpenScanInvoices}
            invoiceService={invoiceService}
        />
    }
      { openDefinirFases &&
    <ModalDefinirFases 
        open={openDefinirFases} 
        setOpen={setOpenDefinirFases} 
        imGeneral={imGeneral}
        invoiceService={invoiceService}
        selectedProject={selectedProject}
    /> }
    {
        openProject &&
    <ModalSelectProject 
        open={openProject} 
        setOpen={setOpenProject} 
        imGeneral={imGeneral} 
        openDefinirFases={openDefinirFases} 
        setOpenDefinirFases={setOpenDefinirFases}
        setProjectSelected={setSelectedProject}
    />
    }
   </>
  )
}
ModalCreateFactura.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  invoiceService: PropTypes.any
}
export default ModalCreateFactura