import React, { useContext, useEffect } from "react"
import i18n from "i18n"
import AuthContext from "context/auth/authContext"
import { HelveticaBold } from "common/typographies"
import { SquareContent } from "common/containers"
import { useHistory } from "react-router-dom"
import { ListBank } from "pages/BancoPrecios/ListBank"
import { updateEndpoint } from "index"
import arrowRight from "assets/customIcons/ArrowHigherThan.svg"
import {Auth } from 'aws-amplify'
const SelectBankPage = () => {
    
    const {user, freeTrialExpired, openFreeTrialExpire} = useContext(AuthContext)
    const history = useHistory()
    const referencias = Object.keys(ListBank).map((key) => {
        return ListBank[key]
    })

    useEffect(() => {
        if(freeTrialExpired){
           openFreeTrialExpire()
        }
    }, [freeTrialExpired])
    const onUpdateEndpoint = async () => {
        const user = await Auth.currentAuthenticatedUser()
        await updateEndpoint(user)
    }
    return(<>
        {
        
            user ? 
            freeTrialExpired ||  user['custom:role'] === 'WORKER' ?
            <HelveticaBold style={{fontSize:'18px', marginTop:'57px', color:"#414141", paddingLeft:"100px", paddingRight:"100px"}}>{i18n.t("Para ver los bancos de precios debes actualizar tu plan")}</HelveticaBold>
        :
        <>
      
            <div  style={{position:"relative", marginTop:'57px'}} onClick={onUpdateEndpoint}>
                {
                    referencias.map((referencia, index) => {
                        let  uri = `/bancodeprecios/referencia/${referencia.uri}`
                        return <>
                            <SquareContent 
                            data-amplify-analytics-on="click"
                            data-amplify-analytics-name={"SelectRefBankPrice"+referencia.name}
                            data-amplify-analytics-attrs={referencia}
                            onClick={()=>{history.push(uri)}} className="hoverYellow" style={{width:"440px", height:"120px", position:"relative", marginBottom:"25px", cursor:'pointer', boxShadow:'none', border:'1px solid #c4c4c4', display:'flex', position:'relative'}}>
                                    <img src={referencia.logo} style={{position:'relative', top:'20%', marginLeft:'30PX', width: referencia.uri == 'preoc' ? "80px" : "78px", height: referencia.uri == 'preoc' ? "73px"  :"73px"}}/>
                                    <HelveticaBold style={{fontSize:'16px', marginTop:'48px', color:"#414141", paddingLeft:"30px", paddingRight:"30px"}}>{i18n.t(`Banco de precios ${referencia.name}`)}</HelveticaBold>
                                    <img src={arrowRight} style={{marginTop:'52px', position:'absolute', right:'30px', width:'16px', height:'16px'}}/>
                            </SquareContent>
                        </>
                    })
                }
            </div>
        </>
            : null
}

        </>

    )
}

export default SelectBankPage;