import React, { useContext, useEffect } from 'react'
import { Modal, CircularProgress } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types'
import { useState } from 'react';
import i18n from 'i18n';
import CloseIcon from "@mui/icons-material/Close";
import Budget2023Context from 'context/budget2023/Budget2023Context';
import { useHistory, useParams } from "react-router-dom"
import  {PoppinsNormal} from 'common/typographies'
import CapituloModalMove from '../../BancoPrecios/chapters/CapituloModalMove';
import ModalConfirmPaste from '../../BancoPrecios/modals/modalConfirmPaste';
import BankPricesContext from 'context/bankPrices/bankPricesContext';
import AuthContext from 'context/auth/authContext';
import ModalSuccesPaste from '../../BancoPrecios/modals/modalSuccesPaste';
import { YellowButton } from 'common/buttons';

function getModalStyle() {
    const top = 50 ;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: '850px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        borderRadius: 6,
        maxHeight: '550px',
        height: '550px',
        overflowY: 'auto',
        overflowX:'hidden',
    },
}));


const ModalExportBankPrice = ({open, setOpen, project, setOpenPrincipal}) => {

    const [ modalStyle ] = useState(getModalStyle);
    const classes = useStyles();
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const {getBudgetTree, loadingTree} = useContext(Budget2023Context)
    const {uncheckAll, getMyBankPrices, myPricesBank} = useContext(BankPricesContext);
    const {partsChecked, chaptersChecked, ExportToBankPrices} = useContext(Budget2023Context);
    useEffect(() => {
        if(project){
            getBudgetTree(project)
        }
    } , [project])
    
    const [capituloSelected, setCapituloSelected] = useState({})
    const [openFinal, setOpenFinal] = useState(false)
    const [openModalConfirm, setOpenModalConfirm] = useState(false)
    const {user} = useContext(AuthContext)


    useEffect(()=>{
        if(user){
            getMyBankPrices(user.sub)
        }
    },[user])
    const handleConfirm = async() => {
        setLoading(true)
        await ExportToBankPrices(capituloSelected, partsChecked, chaptersChecked)
        setLoading(false)
        uncheckAll()
        setOpen(false)
    }

    return(
        <>
        <Modal open={open} onClose={() => null}>
            <div style={modalStyle} className={classes.paper}>
                <div id='principalOptions' style={{marginTop:'20px', marginBottom:'15px', textAlign:'left'}}>
                    <CloseIcon id="cerrar" style={{marginTop:'-15px', right:'-10px'}} onClick={()=>{setOpen(false)}}></CloseIcon>
                    <p id='titles' style={{textAlign:'CENTER', fontSize:'20px', marginBottom:"35px"}}>{i18n.t("Selecciona el capitulo dónde quieres pegar tu seleccion")}</p>
                    
                    
                    <div style={{height:'350px', overflow:'auto', paddingBottom:'10px'}}>
                    {loadingTree ? 
                     <>
                     <div style={{justifyContent:'center', width:'100%', textAlign:'center', position:'relative', display:'flex', marginLeft:'-100px', marginTop:'0px'}}>
                     <div className='loader'>
                         <div className='ball'></div>
                         <div className='ball'></div>
                         <div className='ball'></div>
                    </div>
                     </div>
                     <div style={{justifyContent:'center', width:'100%', textAlign:'center', position:'relative', display:'flex', marginLeft:'-30px'}}>
                     <PoppinsNormal>{i18n.t("Cargando...")}</PoppinsNormal>
                     </div>
                     <div style={{justifyContent:'center', width:'100%', textAlign:'center', position:'relative', display:'flex', marginLeft:'-30px', marginTop:'5px'}}>
                     <PoppinsNormal style={{fontWeight:'400'}}>{i18n.t("Esta acción puede tardar unos segundos")}</PoppinsNormal>
                     </div>
                    
                     </> 
                     
                        : myPricesBank.subcapitulos.length>0 ?
                        myPricesBank.subcapitulos.map((subcapitulo, index) => {
                            
                            return(
                                
                                subcapitulo.chapterId != "SOYUNCAPITULOCONPARTIDASSINCLASIFICAR" ?
                                <CapituloModalMove margin={0} key={index} capitulo={subcapitulo} capituloSelected={capituloSelected} setCapituloSelected={setCapituloSelected}/>
                                :
                               null
                            )
                            })
                            : 
                            <div style={{width:'100%', display:'grid', justifyContent:'center', textAlign:'center', marginTop:'100PX'}}>
                                    <PoppinsNormal>Todavia no tienes capitulos creados</PoppinsNormal>
                                    <YellowButton style={{left:'40%', width:'300px', marginTop:'20px'}} onClick={()=>{history.push('/bancodeprecios')}}>{i18n.t("Comienza a crear tu banco de precios")}</YellowButton>
                            </div>
                            }
                        </div>
                        </div>

                        {
                        myPricesBank.subcapitulos.length>0 ?
                        loading==false ?
                        <button className='AddProject' style={{left:'40%'}} onClick={()=>{handleConfirm()}}>{i18n.t("Confirmar")}</button>
                            :
                            <div style={{textAlign:'center', marginTop: '50px'}}>
                                <CircularProgress size={24} style={{left:'40%'}} />
                                </div>: null }
            </div>
        </Modal>
        <ModalConfirmPaste successModal={openModalConfirm} setSuccessModal={setOpenModalConfirm} setOpenPrincipal={setOpenPrincipal} project={project} setOpenSegundo={setOpen}/>
        <ModalSuccesPaste successModal={openFinal} setSuccessModal={setOpenFinal} setOpenPrincipal={setOpenPrincipal} project={project} setOpenSegundo={setOpen}/>
   
        </>
    )
}

ModalExportBankPrice.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    project: PropTypes.object.isRequired,
    setOpenPrincipal: PropTypes.func.isRequired,
}
export default ModalExportBankPrice;