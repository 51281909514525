import React, { useContext, useEffect, useRef, useState } from "react"
import { useHistory } from "react-router-dom"
import ProjectContext from "context/projects/projectContext"
import CloseIcon from "@mui/icons-material/Close"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import authContext from "context/auth/authContext"
import { makeStyles } from "@material-ui/core/styles"
import {
  Autocomplete,
  CircularProgress,
  Modal,
  TextField,
} from "@mui/material"
import PropTypes from "prop-types"

import CheckCircleOutlineSharpIcon from "@mui/icons-material/CheckCircleOutlineSharp"
import ErrorOutlineSharpIcon from "@mui/icons-material/ErrorOutlineSharp"
import i18n from "i18n"
import ModalImageProject from "pages/Dashboard/ModalImageProject"

function getModalStyle() {
  const top = 50
  const left = 50
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "522px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 6,
    maxHeight: "calc(100vh - 100px)",
    overflowY: "auto",
    overflowX: "hidden",
  },
}))

const CreateProject4Invoice = ({
  open,
  setOpen,
  setProject
}) => {
  const [modalStyle] = useState(getModalStyle)
  const classes = useStyles()

  const [openAdd, setOpenAdd] = useState(false)

  const AuthContext = useContext(authContext)
  //useEffect(() => {  getUser()}, [])

  /*Referencias para cambio de estilos en validacion de formulario*/
  const refNombre = useRef(null)

  /*Referencias para iconos confirm and error*/
  const refNombreConfirm = useRef(null)
  const refNombreError = useRef(null)


  let valida = false
  let validaNombre = true


  const [selectedContact, setSelectedContact] = useState(null)

  const handleOnClick = e => {
    if (e.target.name == "name") {
      refNombre.current["id"] = "focus"
      refNombreConfirm.current.id = "confirmNotVisible"
      refNombreError.current.id = "errorNotVisible"
    } 
  }


  const projectContext = useContext(ProjectContext)
  const { createProject, getProjects } = projectContext



  const [campos, guardarCampos] = useState({
    name: "",
    description: "",
    identifier_number: "",
    orgAddress: "",
    client: "",
    tags: [],
    imagen: "",
  })

  const obtenerCampos = e => {
    guardarCampos({
      ...campos,
      [e.target.name]: e.target.value,
    })
  }

  const history = useHistory()
  const [loading, setLoading] = useState(false)

  const onSubmit = async e => {
    //e.preventDefault();
    if (campos.name.length > 0) {
      validaNombre = true
      refNombre.current.id = " "
      refNombreConfirm.current.id = "confirmVisibleNombre"
      refNombreError.current.id = "errorNotVisible"
    } else {
      refNombre.current.id = "errorValidation"
      validaNombre = false
      refNombreConfirm.current.id = "confirmNotVisible"
      refNombreError.current.id = "errorVisibleNombre"
    }
   
    if (
      validaNombre 
    ) {
      valida = true
    }
    if (valida == true) {
      campos.imagen = image
      campos.client = ""
      setLoading(true)
      const res = await createProject(campos)
      setProject(res)
      setLoading(false)
      setOpen(false)


    }
    

  }
  const onFocus = e => {
    if (e.target.name == "name") {
      refNombre.current["id"] = "focus"
      refNombreConfirm.current.id = "confirmNotVisible"
      refNombreError.current.id = "errorNotVisible"
    } 
  }
  const handleEnter = event => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form
      const index = [...form].indexOf(event.target)
      form.elements[index + 1].focus()
      event.preventDefault()
    }
  }
  const onBlur = e => {
    if (e.target.name == "name") {
      if (campos.name.length > 0) {
        validaNombre = true
        refNombre.current.id = " "
        refNombreConfirm.current.id = "confirmVisibleNombre"
        refNombreError.current.id = "errorNotVisible"
      } else {
        refNombre.current.id = "errorValidation"
        validaNombre = false
        refNombreConfirm.current.id = "confirmNotVisible"
        refNombreError.current.id = "errorVisibleNombre"
      }
    } 
    }
  const [image, setImage] = useState(
    "https://d1t181n48swd5d.cloudfront.net/imgProject/00_DEFAULT.png"
  ) //useState(user['custom:avatar']);
  const [openImage, setOpenImage] = useState(false)
  const handleOpenImage = e => {
    e.preventDefault()
    setOpenImage(true)
  }
  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          null
        }}
      >
        <div style={modalStyle} className={classes.paper}>
          <CloseIcon
            id="cerrar"
            onClick={() => {
              setOpen(false)
            }}
          ></CloseIcon>
          <div id="UserImage">
            <img
              id="ProfileImage"
              onClick={handleOpenImage}
              className="rounded-circle header-profile-user"
              src={image}
              alt="Header Avatar"
            />
            <div id="foto">
              <EditOutlinedIcon
                onClick={handleOpenImage}
                id="fotoSize"
              ></EditOutlinedIcon>
            </div>
          </div>
          <form id="Form">
            <div id="inlineDiv">
              <div id="NombreProyecto">
                <p className="Titulos">{i18n.t("Nombre del Proyecto")}</p>
                <input
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onKeyDown={handleEnter}
                  ref={refNombre}
                  onClick={handleOnClick}
                  placeholder={i18n.t("Nombre del proyecto")}
                  name="name"
                  onChange={obtenerCampos}
                ></input>
                <CheckCircleOutlineSharpIcon
                  style={{ width: "16px", color: "#3BA452" }}
                  ref={refNombreConfirm}
                  id="confirmNotVisible"
                ></CheckCircleOutlineSharpIcon>
                <ErrorOutlineSharpIcon
                  style={{ width: "16px", color: "#BF2929" }}
                  ref={refNombreError}
                  id="errorNotVisible"
                ></ErrorOutlineSharpIcon>
              </div>
           
            </div>
            <div id="OtroConten">
             
              <div id="centerButton" style={{paddingTop:'20px'}}>
                {loading?
                <CircularProgress size={24} />
                :  
                <button type="button" onClick={onSubmit} className="AddContact"
                data-amplify-analytics-on="click"
                data-amplify-analytics-name="ProjectCreated"
                data-amplify-analytics-attrs={campos}>
                  {i18n.t("Crear Proyecto")}
                </button>
              }
              </div>
            </div>
          </form>
        </div>
      </Modal>
      <ModalImageProject
        openImage={openImage}
        setOpenImage={setOpenImage}
        campos={campos}
        image={image}
        setImage={setImage}
        first={true}
      ></ModalImageProject>
    </>
  )
}

CreateProject4Invoice.propTypes = {
  open: PropTypes.bool,
    setOpen: PropTypes.func,
    setProject: PropTypes.func,
}

export default CreateProject4Invoice
