import React, { useEffect, useState } from 'react'
import PropTypes from "prop-types";
import { ChapterContainerInside } from 'common/containers';
import { ChapterTypo } from 'common/typographies'
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import i18n from 'i18n';
import ModalAcceptProposal from '../modals/ModalAcceptProposal';
import useComparison from 'hooks/comparisonTable';
import PartTableComparison from '../part/comparison/partTableComparison';


const ProposalToAccept = ({group}) => {
    const [arrowShow, setArrowShow] = useState(false)
    const [openModalAccept, setOpenModalAccept] = useState(false)
    const { tablaComparativa, loading, getTablaComparativa} = useComparison()
    const [proposalToAccept, setProposalToAccept] = useState([])
    const handleShow = e => {
        e.preventDefault()
        if (arrowShow) {
            setArrowShow(false)
        } else {
            setArrowShow(true)
        }  
    }
    useEffect(() => {
        getTablaComparativa(group.projectId, group.groupId)
    },[])
    return(
        <>
          <ChapterContainerInside style={{backgroundColor:'#A1CFC6'}}>
          <tr  style={{marginTop: '0px'}}>
              <td style={{minWidth:'37px', paddingLeft: 0+'px'}}>
                {arrowShow ? 
                     <KeyboardArrowDownIcon
                     onClick={handleShow}
                     style={{ cursor: "pointer", marginLeft: "3px", color:'white' }}
                     className="dropdownArrowB"
                     id=""
             />
                : 
              <KeyboardArrowRightIcon
                    onClick={handleShow}
                    style={{ cursor: "pointer", marginLeft: "3px", color:'white' }}
                    className="dropdownArrowB"
                    id=""
            />
                }
            </td>
              <td style={{minWidth:'300px'}}>
                  <ChapterTypo style={{marginLeft:'0px', color:'white'}}>{group.groupName}</ChapterTypo>
              </td>
       
        
                     
          </tr> 
         
        </ChapterContainerInside>
        {arrowShow ?
        tablaComparativa.length > 0 ?
        tablaComparativa[0].length>0?
       <div style={{width:'98%', overflowX:'auto', marginTop:'-16px'}}>
           <PartTableComparison table={tablaComparativa} open={openModalAccept} setOpen={setOpenModalAccept} proposalToAccept={proposalToAccept} setProposalToAccept={setProposalToAccept}/>
       </div>:
        <div
        style={{backgroundColor:'#ECFCF9', paddingLeft:'35px', height:'33px', width:'100%', borderRadius:'6px', marginTop:'-16px', paddingTop:'6px', fontFamily:'Roboto', fontWeight:'300', fontSize:'12px'}}
        > {i18n.t('No has recibido ninguna propuesta para este grupo')} </div>
       :
       <div
       style={{backgroundColor:'#ECFCF9', paddingLeft:'35px', height:'33px', width:'100%', borderRadius:'6px', marginTop:'-16px', paddingTop:'6px', fontFamily:'Roboto', fontWeight:'300', fontSize:'12px'}}
       > {i18n.t('No has recibido ninguna propuesta para este grupo')} </div>
        : null }
          <ModalAcceptProposal proposalToAccept={proposalToAccept} setProposalToAccept={setProposalToAccept} open={openModalAccept} setOpen={setOpenModalAccept} />
    </>
    )
}

ProposalToAccept.propTypes = {
    group: PropTypes.object.isRequired
}

export default ProposalToAccept