import React, { useState, useContext } from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import { Modal } from "@mui/material"
import i18n from "i18n"
import DropzoneFiles from "./DrodpzoneFiles"
import FileContext from "context/file/fileContext"

function getModalStyle() {
  const top = 50
  const left = 50
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "550px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: 6,
    maxHeight: "calc(100vh - 100px)",
    overflowY: "auto",
    overflowX: "hidden",
  },
}))
const ModalImportFile4Act = ({ openModal, setOpenAnterior, setOpenModal, selectedFiles, actaValues, setActaValues, setSelectedFiles, index}) => {
  const [modalStyle] = useState(getModalStyle)
  const classes = useStyles()
  const {getPostUrl} = useContext(FileContext)

  const onUpload = async() => {
    for(let i=0; i<selectedFiles.length;i++){
        const res = await getPostUrl(selectedFiles[i], {
            customDB: false,
            importedProject: false,
            tags: [],
            description: "",
            orgId: "",
          })
     let aux = actaValues.subjects
     aux[index].files.push({
        fileId: res.file.fileId,
        url: res.file.url,
      })
      setActaValues({
        ...actaValues,
        subjects: aux,
        })

    setOpenModal(false)
    setOpenAnterior(false)
  }
}


  return (
    <>
      <Modal
        open={openModal}
        onClose={() => {
          null
        }}
      >
        <>
          <div style={modalStyle} className={classes.paper}>
            <div className={"py-4"}>
              <p
                className="text-center my-3"
                style={{
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "20px",
                  lineHeight: "23px",
                  color: "#414141",
                }}
              >
                {i18n.t("Sube aqui archivos para el acta")}
              </p>
            
              <button
                type="button"
                onClick={() => {
                  setOpenModal(false)
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" style={{ padding: "0 5rem" }}>
              <DropzoneFiles
                selectedFiles={selectedFiles}
                setselectedFiles={setSelectedFiles}
            
              />
            </div>

            <div id="centerButton">
              {selectedFiles.length == 0 ? (
                <button
                  type="button"
                  className="AddProjectDisabled"
                  style={{ marginTop: "-10px", marginBottom: "30px" }}
                >
                  {i18n.t("Subir")}
                </button>
              ) : (
                <button
                  type="button"
                  className="AddContact"
                  onClick={onUpload}
                  style={{ marginTop: "-10px", marginBottom: "30px" }}
                >
                  {i18n.t("Subir")}
                </button>
              )}
            </div>

    
          </div>
        </>
      </Modal>
    </>
  )
}

ModalImportFile4Act.propTypes = {
  openModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  selectedFiles: PropTypes.array.isRequired,
    setSelectedFiles: PropTypes.func.isRequired,
    actaValues: PropTypes.object.isRequired,
    setActaValues: PropTypes.func.isRequired,
    setOpenAnterior: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
}

export default ModalImportFile4Act
