import React,{useContext, useEffect, useState} from 'react'
import MetaTags from 'react-meta-tags'
import { useParams } from 'react-router-dom';

//CONTEXT
import ProjectContext from 'context/projects/projectContext';
//COMPONENT
import {
    Container
} from 'reactstrap'
import Budget2023Context from 'context/budget2023/Budget2023Context';


const BudgetVersion = () => {
    const {projectId} = useParams()
    const {index} = useParams()
    const projectContext = useContext(ProjectContext)
    const {getProject, projectInfo, getProjectVersions, versions} = projectContext
    const [cont, setCont]= useState(1);
    const {versionToDisplay} = useContext(Budget2023Context)
    useEffect(() => {
        if(cont!=0){
            if(projectId){
                getProject(projectId)
                
            }
        }
    } , [cont])
    useEffect(()=>{
        helper()
    },[projectInfo])

    const helper = ()=>{
        if(projectInfo){
            if(projectInfo.projectId!=projectId){
                setCont(cont+1)
            }else{
                getProjectVersions(projectId, projectInfo.createdBy)
                setCont(0)
            }
        }
       
    }


    return (
        <React.Fragment>
            <div className='page-content'>
                <MetaTags>
                    <title>Planhopper | Proyecto </title>
                </MetaTags>
                <Container fluid>
                    {cont==0?
                    versions.length>0?
                    null : null : null 
                     //   <TableBudget projectInfo={projectInfo} version={versions[index]} index={index}/>:null:null}
                    }
                </Container>
            </div>

        </React.Fragment>

    )
}

export default BudgetVersion