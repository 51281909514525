import React, { useContext, useEffect, useState } from "react"
import { Modal } from "@mui/material"
import { makeStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import {  PoppinsSemiBold, PoppinsNormal } from "common/typographies"
import i18n from "i18n"
import CloseIcon from "@mui/icons-material/Close";
import AuthContext from "context/auth/authContext"
import { YellowButton } from "common/buttons"
import Chapter from "./Chapter"
import { API, Auth } from "aws-amplify"
function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "1000px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: 6,
    maxHeight: "calc(100vh - 100px)",
    height: "calc(100vh - 100px)",
    overflowX: "hidden",
    overflowY: "hidden",
  },
}))
const fetchChapter = async ({projectId, parentId, typeBudget}) => {
  const SERVICE_BUDGET = 'service-budget'
  const path = `/project/${projectId}?parentId=${parentId}` 
  const headers = {
      'Content-Type': 'application/json',
      'Authorization': (await Auth.currentSession()).getIdToken().getJwtToken(),
      'Access-Control-Allow-Origin': '*',
  }
  const response = await API.get(SERVICE_BUDGET, path, { headers })
  return response

}
const Loader = (loadingTree) => {
  return(<>
    {loadingTree ? 
    
    <>
    <div style={{justifyContent:'center', width:'100%', textAlign:'center', position:'relative', display:'flex', marginLeft:'-100px', marginTop:'0px'}}>
    <div className='loader'>
        <div className='ball'></div>
        <div className='ball'></div>
        <div className='ball'></div>
   </div>
    </div>
    <div style={{justifyContent:'center', width:'100%', textAlign:'center', position:'relative', display:'flex', marginLeft:'-30px'}}>
    <PoppinsNormal>{i18n.t("Cargando...")}</PoppinsNormal>
    </div>
    <div style={{justifyContent:'center', width:'100%', textAlign:'center', position:'relative', display:'flex', marginLeft:'-30px', marginTop:'5px'}}>
    <PoppinsNormal style={{fontWeight:'400'}}>{i18n.t("Esta acción puede tardar unos segundos")}</PoppinsNormal>
    </div>

    </> : null}
  </>)
}


const ModalSearchParts = ({ open, setOpen, projectInfo, selectedParts, setSelectedParts ,handleAddFromParts }) => {
  const [modalStyle] = useState(getModalStyle)
  const classes = useStyles()
  const {user, getUser} = useContext(AuthContext)
  const [typeBudget, setTypeBudget] = useState('cliente')
  const [loadingTree, setLoadingTree] = useState(true)
  const [chapters, setChapters] = useState([])
  const [disabled, setDisabled] = useState(!(selectedParts.length > 0))
  
  const width = 96
  const marginLeft = 20
  useEffect(() => {
    if(!user){
        getUser
    }
    }, [])
  useEffect(async ()=>{
    if (projectInfo){
      setSelectedParts([])
      setLoadingTree(true)
      const response  = await fetchChapter({projectId: projectInfo.projectId, parentId: 'root', typeBudget})
      setChapters(response.chapters)
      setLoadingTree(false)
    }
  },[typeBudget])

  useEffect(()=>{
    setDisabled(!(selectedParts.length >  0))
  },[selectedParts])


  return (
    <>
    <Modal open={open} onClose={() => null}>
      <div style={modalStyle} className={classes.paper}>
            <div style={{width:'100%', justifyContent:'center', position:'relative', textAlign:'center', marginTop:'20px'}}>
                <PoppinsSemiBold style={{fontSize:'18px'}}>
                   {projectInfo.name}
                </PoppinsSemiBold>
                <CloseIcon id="cerrar" style={{marginTop:'-40px', right:'20px'}} onClick={()=>{setOpen(false)}}></CloseIcon>
                
            </div>
            <div style={{width:'100%', justifyContent:'center', position:'relative', textAlign:'center', marginTop:'20px'}}>
                <PoppinsSemiBold style={{fontSize:'18px'}}>
                   {i18n.t("Selecciona los conceptos que quieres añadir a la factura")}:
                </PoppinsSemiBold>
                
            </div>
            
            <div style={{height:'60vh', paddingBottom:'10px', overflowY:'auto', minHeight:'290px', position:'relative', justifyContent:'center'}}>
            {loadingTree ? Loader(loadingTree) :
              
                chapters.length > 0 ? chapters.map((chapter, index) => {
                    return(
                        <Chapter 
                          chapter={chapter} 
                          key={index} 
                          typeBudget={typeBudget} 
                          fetchChapter={fetchChapter}
                          width={width}
                          marginLeft={marginLeft}
                          selectedParts={selectedParts}
                          setSelectedParts={setSelectedParts}
                        />
                    )
                }): 
                <PoppinsNormal style={{marginLeft:'360px', marginTop:'30px'}}>
                      {i18n.t("No hay capítulos disponibles")}
                  </PoppinsNormal>
              
            }
            </div>
            <div style={{width:'60%', justifyContent:'center', textAlign:'center'}}>
              {
                selectedParts.length > 0 ? 
                <PoppinsNormal style={{marginLeft:'360px', marginTop:'30px'}}>
                      {i18n.t("Has seleccionado")} {selectedParts.length} {i18n.t("partidas")}
                  </PoppinsNormal> : null
              }
              <YellowButton
                onClick={handleAddFromParts}
               style={{position: 'fixed', bottom: '25px', width:'360px',  height:'34px', cursor: disabled ? 'default' : 'pointer', backgroundColor: disabled? '#AFAFB0': '#F1CA2C'}}>
                  {i18n.t("Añadir partidas a la factura")}
              </YellowButton>
            </div>
      </div>
    </Modal>
   
    </>
  )
}

ModalSearchParts.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    chapterId: PropTypes.string.isRequired,
    projectInfo: PropTypes.object.isRequired,
    selectedParts: PropTypes.array.isRequired,
    setSelectedParts: PropTypes.func.isRequired,
    handleAddFromParts : PropTypes.func.isRequired

}
export default ModalSearchParts

