import React, { useContext, useEffect, useState, useRef} from "react"
import PropTypes from "prop-types"
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import i18n from "i18n"
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import JustificantePrecio from "components/Project/Overview/JustificantePrecio";
import FavoritesBPContext from "context/favoritesBP/favoritesBPContext";
import { getCurrency } from "utils/functions"
import AuthContext from "context/auth/authContext";


const Part = ({ part }) => {
    const [historic, setHistoricPrice] = useState(null)
    const [openAdd, setOpenAdd] = useState(false)
    const referenciaLines = useRef(null)
    const {deleteBankPart, checkPartida, updatePartsList, checkMultipleParts, myPricesBank, selectedPartGroup, selectPartsGroup, updatePartPrice, cleanChapters, cleanParts } = useContext(FavoritesBPContext)
    const [deleteState, setDeleteState] = useState(false)
    const [tocaEliminar, setTocaEliminar] = useState(false)
    const [userPrice, setPrice] = useState(part.userPrice ? part.userPrice : 0)
    const {user} = useContext(AuthContext)

    useEffect(async()=>{
      if(deleteState){
        await selectPartsGroup([], myPricesBank.subcapitulos)
        setTocaEliminar(true)
      }
    },[deleteState])
    useEffect(async() => {
      if(selectedPartGroup){
        let auxList = []
      if(selectedPartGroup.length>0){
        let auxChapter = selectedPartGroup[0][0].chapterId
        let chapter = false
        for(let i=0; i<selectedPartGroup.length;i++){
            if(selectedPartGroup[i]){
                if(selectedPartGroup[i].length>0){
                    for(let j=0;j<selectedPartGroup[i].length;j++){
                        if(selectedPartGroup[i][j].chapterId!=auxChapter){
                            chapter = true
                        }
                        auxList.push(selectedPartGroup[i][j])
                    }
                }
            }
        }
        setLista(auxList)
      }
      if(deleteState && selectedPartGroup.length==0 ){
        await deleteBankPart(part.chapterId, part.partidaId, part.accountId);
        await cleanChapters()
        await cleanParts()
      }else if(deleteState && selectedPartGroup.length>0){
        for (let index = 0; index < auxList.length; index++) {
          await deleteBankPart(
            auxList[index].chapterId,
            auxList[index].partidaId,
            auxList[index].accountId
          )
          await cleanChapters()
          await cleanParts()
        }
      }
      setDeleteState(false)
      setTocaEliminar(false)
    }
  
    }, [ selectedPartGroup, deleteState, tocaEliminar])


    useEffect(()=>{

      if(referenciaLines.current.value!=''){
          let prueba= parseInt(((referenciaLines.current.value.length * 8.1)/620)+1)
          referenciaLines.current.style.height=prueba*22.5+'px'
      }else{
        referenciaLines.current.style.height=22+'px'
      }
      if(!part.resumen){
        part.resumen=part.description
      }
      
    },[part])

    const handleCheckBox = (e) => {
      if(e.nativeEvent.shiftKey){
        if(part.checked){
          checkPartida(part.chapterId, part.partidaId)
          updatePartsList(part)
        }else{
          checkMultipleParts(part.chapterId, part)
          
        } 
      }else{
        checkPartida(part.chapterId, part.partidaId)
        updatePartsList(part)
      }
    }
    const changePrice = (e) => {  
      setPrice(parseFloat(e.target.value))
    }
    const handleOnBlur = async (e) => {
      if(userPrice!=part.userPrice){
        await updatePartPrice({...part, userPrice: userPrice})
      }
    }

  return (
    <>
    <tr
    className="partidasInfo"
    style={{
      cursor: "pointer",
 
    }}
  >
      <td style={{ borderLeft: "none", width: "79px", paddingTop:'5px' }}>
          <div
            className="inline"
            style={{ marginLeft: "10px", marginTop: "2px", height: "100%" }}
          >
            <div className="detallesPartiShow2"  style={{marginLeft:'-4px'}}>
             
            <DragIndicatorIcon 
              style={{
                cursor: "all-scroll",
                width: "17px",
                color: "#B7B7B7",
                marginTop: "6px",
                marginRight: "4px",
                marginLeft: "-2px",
              }}
            />
            </div>
            <input
              type={"checkbox"}
              onChange={(e) => handleCheckBox(e)}
              checked={part.checked}
              style={{
                width: "13px",
                marginRight: "5px",
                cursor: "pointer",
                marginTop: "-2px",
              }}
            ></input>

            <p style={{ paddingTop: "10px", color: "#B7B7B7" }}>
              {part.unity}
            </p>
          </div>
        </td>
      <td style={{fontWeight:'300', textAlign:'left', paddingLeft:'40px'}}>
      <textarea
              ref={referenciaLines}
              className='NombreAlign' 
              style={{paddingLeft:'11px', paddingTop:'2px', resize:'none', fontSize:'14px', lineHeight:'19.5px', width: '100%', overflow:'hidden', WebkitTextFillColor:'#414141'}} 
              placeholder={i18n.t('Buscar o crear partida')}
              //onChange={handleChangePartida}
              //onClick={()=>{setMenu(true)}}
              value={part.name}
              name = 'name'
              autoComplete="off"
              //onClick={handleClick}
              disabled={true}
             
             />
      </td>
      <td
                style={{
                  minWidth: "86px",
                  color: "#B7B7B7",
                }}
              >
                <div style={{ marginTop: "15px", height: "100%" }}>
                  {historic == null
                    ?   
                      getCurrency(user['custom:currency'])+
                    "/u"
                    : currencyFormatDE(parseFloat(historic),getCurrency(user['custom:currency']))}
                </div>
              </td>
              <td
                style={{
                  minWidth: "86px",
                  borderLeft: "1px solid #F3F1F1",

                  paddingTop: "1px",
                  color: "#B7B7B7",
                }}
              >
                <div style={{ marginTop: "15px", height: "100%" }}>
                  {historic == null
                    ? i18n.t("Total")
                    : currencyFormatDE(
                        (historic * partida.unidades).toFixed(2),getCurrency(user['custom:currency'])
                      )}
                  {part.elementInfo != null ? (
                   null
                  ) : null}
                </div>
              </td>
              <td style={{ minWidth: "127px", color: "#414141" }}>
            <div style={{ marginTop: "5px", height: "100%" }}>
              {part ? (
                  <div>
                    <input
                      style={{ color: "#C4C4C4", fontWeight: "400", backgroundColor:"transparent", WebkitTextFillColor:'#C4C4C4' }}
                      id="inputUnidades"
                      min="0"
                      type="number"
                      onBlur={handleOnBlur}
                      name="userPrice"
                      value={userPrice}
                      disabled={true}
                    />
                  </div>
              ) : (
                <div>
                    {
                getCurrency(user['custom:currency'])
              }/u
                </div>
              )}
            </div>
          </td>
          <td
          style={{
            borderLeft: "none",
            minWidth: "40px",
          }}
        >
          <div
            className="detallesPartiShow"
            style={{
              backgroundColor: "transparent",
              width: "40px",
              minWidth: "40px",
              marginTop: "10px",
              height: "85%",
              justifyContent: "center",
              marginLeft: "-15px",
            }}
          >
  
             <InfoOutlinedIcon
                      style={{ width: "19px", cursor: "pointer" }}
                      onClick={() => {
                         setOpenAdd(true)
                      }} 
          />

          </div>
        </td>
    </tr>
    <JustificantePrecio
    openAdd={openAdd}
    setOpenAdd={setOpenAdd}
    imFromBankPrice={true}
    partida={part}
    partidaInfo={part}
    soyFinal={false}
  />
  </>
  )
}
Part.propTypes = {
  part: PropTypes.object.isRequired,
}
export default Part
