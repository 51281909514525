import MetaTags from "react-meta-tags"
import React, { useEffect, useContext } from "react"
import authContext from "context/auth/authContext"

import {
  Container
} from "reactstrap"


import ProfileComponent from "components/ProfileRefinament/ProfileComponent"
import { RecordPageView } from "App"

const Profile = props => {
  RecordPageView()
  const { user, getUser} = useContext(authContext)
  useEffect(() => {
    if(!user) {
    getUser()
}
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Planhopper - Perfil</title>
        </MetaTags>
        <Container fluid>
          {user ? (
            <ProfileComponent/>
          ) : null}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Profile