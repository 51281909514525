import React, {useContext, useRef, useState, useEffect} from "react";
import PropTypes from "prop-types";
import i18n from "i18n";
import { Document, Page, pdfjs } from 'react-pdf'
import {PoppinsNormal} from 'common/typographies'
import Budget2023Context from "context/budget2023/Budget2023Context";


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


const PDFPreview = ({projectId}) => {
    const [numPages, setNumPages] = useState(null);
    const [notRender, setNotRender] = useState(true);
    const {loadingBudgetPDF, getBudgetPDF, budgetPDF} = useContext(Budget2023Context);
    const componentRef = useRef();
    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
    }


    useEffect(() => {
        if(budgetPDF){
            setNotRender(false);
        }
    },[budgetPDF]);

    return (
    <>
 
            {loadingBudgetPDF || notRender ? 
            <>
                   <div style={{justifyContent:'center', width:'100%', textAlign:'center', position:'relative', display:'flex', marginLeft:'-100px', marginTop:'90px'}}>
                   <div className='loader'>
                       <div className='ball'></div>
                       <div className='ball'></div>
                       <div className='ball'></div>
                  </div>
                   </div>
                   <div style={{justifyContent:'center', width:'100%', textAlign:'center', position:'relative', display:'flex', marginLeft:'-30px'}}>
                   <PoppinsNormal>{i18n.t("Cargando PDF...")}</PoppinsNormal>
                   </div>
                   <div style={{justifyContent:'center', width:'100%', textAlign:'center', position:'relative', display:'flex', marginLeft:'-30px', marginTop:'-15px'}}>
                   <PoppinsNormal style={{fontWeight:'400'}}>{i18n.t("Esta acción puede tardar unos segundos")}</PoppinsNormal>
                   </div>
                   </>
             :   
             <> 
             <div style={{ height:'100%',minHeight:'85vh', maxHeight:'100vh', display:'flex', justifyContent:'center', backgroundColor:'#D9D9D9', borderBottom:'1px solid #EBEBEB', overflowY:'auto'}}>
             <Document  ref={componentRef} file={budgetPDF} onLoadError={console.error} style={{overflowY:'auto'}} onLoadSuccess={onDocumentLoadSuccess}>
                {
               [...Array(numPages)].map((elementInArray, index) => ( 
                 <Page key={index} pageNumber={index + 1} />
                 ) 
             )}
                
             </Document>
             </div>
             </>
        }
                    
    </>
    )
}
 PDFPreview.propTypes = {
    projectId: PropTypes.string.isRequired,
};

export default PDFPreview;