import styled from 'styled-components';

export const HistoricTable = styled.table`
    width: 1163px;
    border: none;
    th{
        font-family: "Helvetica";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #414141;
        height:'45px';
    };
    tr{
        border-bottom: 0.5px solid #B7B7B7;
    };
    td{
        font-family: "Helvetica";
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 18px;
        color: #000000;
        height:'45px';
    }
    td:first-child{
        padding-top:13px;
    }

`;
export const TableDiv =  styled.div`
    height: 45px;
`;

export const PartStyledTable = styled.table`
width:'100%'; 
marginLeft: '0px';
    background-color: white;
    border: 1px solid #B7B7B7;
    margin-top:-2px;

    th{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        text-align: center;
        height:33px;
        color: #414141;
        border-left: 1px solid #B7B7B7;
    }
    th:first-child{
        border-left:none;
    }
    td{
        font-family: 'Roboto';
        height:33px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #414141;
        border-left: 1px solid #B7B7B7;
        padding-left: 33px;
        text-align: left;
    }
    td:first-child{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        height:33px;
        font-size: 12px;
        line-height: 16px;
        color: #B7B7B7;
        border-left: none;
    }
    tr{
        border-bottom:1px solid #E9ECEF;
    }
    tr:last-child{
        border-bottom:none;
    }
    tr:first-child{
        border-bottom: 1px solid #B7B7B7;
    }

`;


export const VersionsTable = styled.table`
width:'100%'; 
marginLeft: '0px';
    background-color: white;
    margin-top:-2px;

    th{
        font-family: "Helvetica";
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        text-align: left;
        height:33px;
        color: #414141;
        
    }
    th:first-child{
        border-left:none;
        padding-left: 20px;
    }
    td{
        height:45px;
        font-family: "Helvetica";
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 18px;
        color: #C4C4C4;
        text-align: left;
    }
    td:first-child{
        font-family: "Helvetica";
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 18px;
        color: #000000;
        height:45px;
        border-left: none;
        padding-left: 20px;
        cursor: pointer;
    }
    tr{
        border-bottom:1px solid #E9ECEF;
    }
    tr:last-child{
        border-bottom:none;
    }
    tr:first-child{
        border-bottom: 1px solid #B7B7B7;
    }
    tr:first-child:hover{
        background-color: white;
    }
    tr:hover{
        background-color: #FFE992;
    }

`;


export const PricingTable = styled.table`
    width: 900px; 
    marginLeft: 0px;
    
    td{
        height:50px;
        font-family: 'Helvetica';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        color: #414141;
        text-align: center;
        display: flex;
        position: relative;
        justify-content: center;
        width: 185px;
       padding-top: 15px;
        
    }
    td:first-child{
        height:50px;
        font-family: Helvetica;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        color: #414141;
        text-align: left;
        border-left: none;
        width: 325px;
        display: block;
        margin-right: 0px;
        padding-top: 15px;
    }

    tr{
        border-bottom: 0.5px solid #414141;;
    }

`;


export const BillingTable = styled.table`
    background-color: white;
    border-radius: 6px;
    th{
        font-family: 'Helvetica';
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        text-align: left;
        height:56px;
        color: #414141;
        padding-top: 0px;
        width: fit-content;
        padding-left: 80px;
    }
    td{
        height:56px;
        font-family: 'Helvetica';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #414141;
        text-align: left;
        padding-top: 0px;  
        width: fit-content;
        padding-left: 80px;
    }

    tr{
        border-bottom: 0.5px solid #C4C4C4;;
    }

    tr:last-child{
        border-bottom: none;
    }

    th:first-child, td:first-child{
        padding-left: 10px;
    }
    th:last-child, td:last-child{
        padding-left: 10px;
    }
    @media (max-width: 2000px) {
        td, th{
            padding-left: 100px;
        }
      }
      @media (max-width: 1700px) {
        td, th{
            padding-left: 70px;
        }
      }
      @media (max-width: 1500px) {
        td, th{
            padding-left: 40px;
        }
      }
      @media (max-width: 1350px) {
        td, th{
            padding-left: 20px;
        }
      }
    

`;


export const ActasTable = styled.table`
    width:100%; 
    background-color: white;
    margin-top:-2px;

    th{
        font-family: "Helvetica";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        text-align: left;
        height: 36px;
        color: #414141;
        background-color: #CBD8FC;
        border-left: 1px solid #B7B7B7;
        padding-left: 28px;
    }
    th:first-child{
        border-left:none;
        padding-left: 28px;
    }
    td{
        height:35px;
        font-family: "Helvetica";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #414141;
        text-align: left;
        border-left: 1px solid #B7B7B7;
        padding-left: 28px;
    }
    td:first-child{
        font-family: "Helvetica";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        border-left: none;
        padding-left: 28px;
    }
    tr{
        border-bottom: 1px solid #E9ECEF;
    }
    tr:last-child{
        border-bottom:none;
    }
    tr:first-child{
        border-bottom: none
    }
`;