import React, { Fragment, useState} from 'react';
import i18n from "i18n"
import PropTypes from "prop-types"
import  { 
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap"
import { HelveticaBold, HelveticaLight } from 'common/typographies';
import { KeyboardArrowDown } from '@mui/icons-material';
import SortIcon from '@mui/icons-material/Sort';

const InvoicesFilter = ({invoiceService}) => {
    const [menu, setMenu] = useState(false)
    const handleChange = (ft) => {
        invoiceService.setFilter(ft)
    }
    const isActive = (ft) => {
        return (invoiceService.filter.value == ft.value)
    }
    return(
        <Fragment>
            <Dropdown
                isOpen={menu}
                toggle={() => setMenu(!menu)}
                className="d-inline-block"
            >
                <DropdownToggle
                    id='settingb'
                    style={{marginTop:'-10px', color:'black', margin:'0px', padding:'0px'}}
                >
                    <div className='inline' style={{marginRight:'10px', paddingTop:'7px'}}>
                        <SortIcon style={{color:'black', fontSize:'18px', marginRight:'5px'}}/>
                        <HelveticaBold style={{textTransform:'none', color:'#414141', fontSize:'12px', fontWeight:'400', marginRight:'1px'}}>{i18n.t('Filtros')}</HelveticaBold>
                        <KeyboardArrowDown style={{color:'#414141', fontSize:'18px', marginRight:'5px'}}/>
                    </div>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                    {invoiceService.filters.map((ft, index, arr) => {
                        const key = index * Math.random()
                        return(
                            <DropdownItem key={key} tag="b"  style={{lineHeight: '20px', cursor:'pointer'}} 
                                onClick={()=>{handleChange(ft)}}
                            >
                            {" "}
                                <div className="inline">
                                <input
                                    key = {key}
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={isActive(ft)}
                                    id="flexCheckDefault"
                                    onChange={()=>{handleChange(ft)}}
                                    style={{ width: "13px", height: "13px", cursor:'pointer', zIndex:"5", marginTop:'5px'}}
                                />
                                    <HelveticaLight style={{color:'#414141', fontWeight:'400',
                                        fontSize:'14px', paddingTop:'2px',
                                        paddingLeft:'10px',
                                        marginBottom:'-10px'}}>
                                        {i18n.t(`${ft.name}`)}
                                    </HelveticaLight> 
                                </div>
                            </DropdownItem>
                        )
                    })}
                </DropdownMenu>
            </Dropdown>
        </Fragment>
    )
}

InvoicesFilter.propTypes = {
    invoiceService: PropTypes.any
}
export default InvoicesFilter