import React from "react";
import PropTypes from "prop-types";
import { WhiteButton } from "common/containers";
import ModalFirstProject from "pages/Dashboard/ModalFirstProject";

const LayoutDocuments = ({handleOpen, imInProject}) => {

    const [openCreateProject, setOpenCreateProject] = React.useState(false);
    return (
        <div
        style={{
          display: "flex",
          position:'relative',
          width: "100%",
        }}
      >
            <div>
              <p id="Configuration" style={{ }}>
                Documentos
              </p>
            </div>
          {
            !imInProject &&
          
            <WhiteButton 
              onClick={() => setOpenCreateProject(true)}
              style={{position:'absolute', right:'170px', border:'1px solid #FFD52B', fontWeight:'700',  marginTop:'-5px', fontFamily:'Helvetica', width:'150px', height:'40px'}}>
              Crear Proyecto
            </WhiteButton>
            }

            <button
              onClick={handleOpen}
              className="AddContact"
              style={{ justifyContent: "right", right:'0px', position:'absolute', marginTop:'-5px', height:'40px', width:'150px' }}
            >
          Importar
           </button>
        
        {
          openCreateProject && <ModalFirstProject imInDocuments={true} modal_first={openCreateProject} setmodal_first={setOpenCreateProject} />
        }
      </div>
    )
}

LayoutDocuments.propTypes = {
    handleOpen: PropTypes.func,
    imInProject: PropTypes.bool,
};

export default LayoutDocuments;