import { useComments } from "hooks/useComments";
import React, {useContext, useEffect} from "react";
import { TextField} from "@mui/material"
import PropTypes from "prop-types";
import AuthContext from "context/auth/authContext"
import i18n from "i18n";

const CommentContainer = ({conversationId, scrollToBottom, messagesEndRef, estoyEnPlanning}) => {
 const { createComment, comments } = useComments({conversationId: conversationId}); 
 const {user, getUser} = useContext(AuthContext)  
 useEffect(() => {
  getUser()
 }, [])
    const [comment, setComment] = React.useState({
        conversationId: conversationId,
        content: ""
    });
    const { content } = comment;
    const handleChange = (event) => {
        setComment({ ...comment, [event.target.name]: event.target.value });
    }

const handleEnter = async (event) => {
    if (event.key.toLowerCase() === "enter") {
      event.preventDefault();
      await createComment(comment)
      setComment({conversationId: conversationId,
        content: ""})
    }
  };


  useEffect(()=>{
      if(!estoyEnPlanning){
        scrollToBottom()
      }
     
  },[comments])

    
    return(
        estoyEnPlanning? 
        <div style={{marginTop:'35px'}}> {
            comments.slice(0).reverse().map(comment => {
                return(
                    <div key={comment.commentId} >
                        {user? 
                    comment.author.accountId == user.sub ? 
                    <div className='MineComment'  style={{width:'100%', right:'10px'}}  key={comment.commentId}>
                           
                        <div className="totalofComments" style={{width:'60%'}}>
                        <div className="UpBar">
                            <p className="AuthorComment">{comment.author.fullName+' '}</p>
                            
                            <p className="upBarComment" style={{marginLeft:'5px'}}> {i18n.t("ha comentado")}</p>
                            <p className="upBarComment" style={{float:'right', right:'-5px', position:'relative'}}>{new Date(comment.createdAt).toLocaleDateString()}</p>
                         
                        </div>
                        
                        <div style={{paddingLeft:'20px'}}>
                        <p className='messageContent'>
                        {comment.content}
                        </p>
                        </div>
                        
                        </div>
                        <div className="Circulo" style={{position:'relative', right:'-10px', zIndex:'5'}}></div>
                    </div>
                    : null 
                    : 
                    <div className='OtherComment' key={comment.commentId} style={{left:'10px', width:'100%'}}>
                    <div className="Circulo2" style={{backgroundColor:'#D5DDFF', right:'0px', position:'relative', width:'46px', height:'46px', marginRight:'30px'}}></div>
                    <div className="totalofComments" style={{width:'60%'}}>
                    <div className="UpBar">
                        <p className="AuthorComment" style={{color:'#4461D7'}}>{comment.author.fullName+' '}</p>
                        
                        <p className="upBarComment" style={{marginLeft:'5px'}}> {i18n.t("ha comentado")}</p>
                        <p className="upBarComment" style={{float:'left', paddingLeft:'11%'}}>{new Date(comment.createdAt).toLocaleDateString()}</p>
                     
                    </div>
                    
                    <div style={{paddingLeft:'20px'}}>
                    <p className='messageContent'>
                    {comment.content}
                    </p>
                    </div>
                    
                    </div>
                    
                </div>
                
                }
                </div>
                )
            })
        }
       <TextField style={{width:'106%', bottom:'0px', position:'absolute'}}
            className="CommentsTextField"
             id="outlined-multiline-flexible"
             label="Haz comentarios aquí"
             multiline
             maxRows={4}
             name="content"
             value={content}
             variant='filled'
             color='success'
             onChange={handleChange}
             onKeyDown={handleEnter}
           />
            

        </div> : 

<div style={{marginTop:'35px'}}> {
    comments.slice(0).reverse().map(comment => {
        return(

            comment.author.accountId == user.sub ? 
            <div className='MineComment'key={comment.commentId}>
                   
                <div className="totalofComments">
                <div className="UpBar">
                    <p className="AuthorComment">{comment.author.fullName+' '}</p>
                    
                    <p className="upBarComment" style={{marginLeft:'5px'}}> ha comentado</p>
                    <p className="upBarComment" style={{float:'right', right:'110px', position:'fixed'}}>{comment.createdAt}</p>
                 
                </div>
                
                <div style={{paddingLeft:'20px'}}>
                <p className='messageContent'>
                {comment.content}
                </p>
                </div>
                
                </div>
                <div className="Circulo"></div>
            </div>
            
            :
            <div className='OtherComment' key={comment.commentId}>
            <div className="Circulo2" style={{backgroundColor:'#D5DDFF'}}></div>
            <div className="totalofComments">
            <div className="UpBar">
                <p className="AuthorComment" style={{color:'#4461D7'}}>{comment.author.fullName+' '}</p>
                
                <p className="upBarComment" style={{marginLeft:'5px'}}> ha comentado</p>
                <p className="upBarComment" style={{float:'left', left:'565px', position:'fixed'}}>{comment.createdAt}</p>
             
            </div>
            
            <div style={{paddingLeft:'20px'}}>
            <p className='messageContent'>
            {comment.content}
            </p>
            </div>
            
            </div>
            
        </div>
        )
    })
}

    <TextField 
    className="CommentsTextField"
     id="outlined-multiline-flexible"
     label="Haz comentarios aquí"
     multiline
     maxRows={4}
     name="content"
     value={content}
     variant='filled'
     color='success'
     onChange={handleChange}
     onKeyDown={handleEnter}
    />

    
<div ref={messagesEndRef}/>
</div>
        
    )
}
CommentContainer.propTypes = {
    conversationId: PropTypes.string.isRequired,
    scrollToBottom: PropTypes.func.isRequired,
    messagesEndRef: PropTypes.any,
    estoyEnPlanning: PropTypes.bool
}
export default CommentContainer;