import React, {useEffect} from "react";
import PropTypes from "prop-types"
import tribboLogo from "assets/images/icons/tribbooNon.png";
import cypeLogo from "assets/images/icons/CYPENUEVO_NON.png";
import bedecLogo from "assets/images/icons/itecnon selected.png";
import userDB from "assets/images/logos/userDB.png";
import manualLogo from "assets/images/logos/manualLogo.png";
import userDBselected from "assets/images/logos/userDBselected.png";
import cypeSelected from "assets/images/icons/CYPENUEVO.png";
import tribboSelected from "assets/images/icons/tribboo.png";
import bedecSelected from "assets/images/icons/itec.png";
import manualPartSelected from "assets/images/logos/manualPartselected.png";



const BankSelector = ({banks, bankSelected, setBankSelected, setReferencia, setTipo}) => {
    //const banks = ['User database', 'Tribboo database', 'Cype database', 'Bedec database', 'Manual part']
    useEffect(()=>{
        if(bankSelected=='Cype database'){
            setReferencia('cype')
            setTipo('Rehabilitación')
        }else if(bankSelected=='Bedec database'){
            setReferencia('bedec')
            setTipo('PARTIDAS DE OBRA Y CONJUNTOS')
        }
    },[bankSelected])
    return (
        <div className="inline" style={{width:'100%', justifyContent:'center', position:'relative', paddingTop:'20px'}}>
            {
                banks.map((bank, index) => (
                    <div key={index}>
                        {
                            bank != 'Tribboo database'? (
                            
                            bankSelected === bank ?
                            <img style={{width:bank=='Bedec database' ? '60px' : '42px', height:bank=='Bedec database' ? '30px' : '42px', marginRight: index==banks.length-1 ? '0px' : '70px',  marginTop : (bank=='User database' || bank=='Manual part') ? '3px' : bank=='Bedec database' ? '6px' : '0px'}} src={bank=='Tribboo database' ? tribboSelected : bank=='Cype database' ? cypeSelected : bank=='Bedec database' ? bedecSelected : bank=='Manual part' ? manualPartSelected : userDBselected} />
                            : 
                            <img style={{width:bank=='Bedec database' ? '60px' : '42px', height:bank=='Bedec database' ? '30px' : '42px', cursor:'pointer', marginRight: index==banks.length-1 ? '0px' : '70px', marginTop : (bank=='User database' || bank=='Manual part') ? '3px' : bank=='Bedec database' ? '6px' : '0px'}}  onClick={()=>{setBankSelected(bank)}} src={bank=='Tribboo database' ? tribboLogo : bank=='Cype database' ? cypeLogo : bank=='Bedec database' ? bedecLogo : bank=='Manual part' ? manualLogo : userDB} />
                            ) : null
                        }
                        
                    </div>
                ))
            }
        </div>
    )
}

BankSelector.propTypes = {
   banks: PropTypes.array.isRequired,
    bankSelected: PropTypes.string.isRequired,
    setBankSelected: PropTypes.func.isRequired,
    setReferencia: PropTypes.func.isRequired,
    setTipo: PropTypes.func.isRequired
}

export default BankSelector;