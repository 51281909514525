import React, {Fragment, useEffect} from "react"
import {useParams, useLocation} from "react-router-dom"
import {useInvoice} from "hooks/useInvoice"
import { MetaTags } from "react-meta-tags"
import { Container } from "reactstrap"
import InvoicePageContainer from "components/Invoice/invoicePageContainer"
import { RecordPageViewWithoutUserId } from "App"

const InvoicePage = () => {
    RecordPageViewWithoutUserId()
    const {invoiceId} = useParams()
    const { search } = useLocation()
    const queryValues = new URLSearchParams(search)
    const projectId = queryValues.projectId
    const invoiceService = useInvoice()
    useEffect(() => {
        invoiceService.getInvoice({
            invoiceId,
            projectId
        })
    }, [invoiceId])
    
    return(
        <Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Planhopper | Facturas</title>
                </MetaTags>
                <Container fluid>
                    <InvoicePageContainer invoiceService={invoiceService}/>
                </Container>
            </div>
        </Fragment>
    )
}
export default InvoicePage
