import React, { useContext, useEffect, useState } from "react"
import i18n from "i18n"
import PropTypes from "prop-types"
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid"
import {
  Avatar,
  Stack,
  Pagination,
  PaginationItem,
  Chip,
  IconButton,
  Box,
} from "@mui/material"
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import AddContact from "./addContact"
import ModalEditar from "./modalEditar"
import ModalEliminar from "./modalEliminar"
import paquitoContactos from "assets/images/paco/paquitoContactos.png"
import AuthContext from "context/auth/authContext"

const listaColores = [
  "color1",
  "color2",
  "color3",
  "color4",
  "color5",
  "color6",
  "color7",
  "color8",
]

function avatarCell(params) {
  return (
    <>
      <Avatar
        sx={{
          width: "30px",
          height: "30px",
          marginRight: "10px",
          backgroundColor: "#FACBCB",
          color: "#D78D8D",
        }}
      >
        {params.value.charAt(0).toUpperCase()}
        {params.value.charAt(1).toUpperCase()}
      </Avatar>
      {params.value}
    </>
  )
}

function roleCell(params) {
  if (!params.value.length) {
    return
  }
  let others = 0
  if (params.value.length > 2) {
    others = params.value.length - 2
  }

  return (
    <>
      <Stack
        style={{
          marginRight: "0.5rem",
        }}
      >
        <Chip
          id={params.value[0].color}
          className="etiquetaContacto"
          size="small"
          label={params.value[0].role}
          style={{ borderRadius: "4px", marginTop: "1px" }}
        />
      </Stack>

      {params.value[1] && (
        <Stack
          style={{
            marginRight: "0.5rem",
          }}
        >
          <Chip
            id={params.value[1].color}
            className="etiquetaContacto"
            size="small"
            label={params.value[1].role}
            style={{ borderRadius: "4px", marginTop: "1px" }}
          />
        </Stack>
      )}

      {others !== 0 && <span>+ {others}</span>}
    </>
  )
}
function editCell(params) {
  return (
    <IconButton value={"editar"}>
      <EditOutlinedIcon fontSize="small" />
    </IconButton>
  )
}
function delCell(params) {
  return (
    <IconButton value="eliminar">
      <DeleteOutlineOutlinedIcon style={{ fill: "#C90505" }} fontSize="small" />
    </IconButton>
  )
}
const columns = [
  { field: "id", headerName: "ID", hide: true },
  {
    field: "completeName",
    headerName: "Nombre",
    flex: 1,
    minWidth: 250,
    editable: true,
    renderHeader: () => <b>{i18n.t("NOMBRE")}</b>,
    renderCell: avatarCell,
  },
  {
    field: "email",
    headerName: "Correo",
    flex: 1,
    minWidth: 200,
    editable: true,
    renderHeader: () => <b>{i18n.t("EMAIL")}</b>,
  },
  {
    field: "mobile",
    headerName: "Telefono",
    type: "string",
    flex: 1,
    minWidth: 130,
    editable: true,
    renderHeader: () => <b>{i18n.t("NUMERO MOVIL")}</b>,
  },
  {
    field: "role",
    headerName: "Rol",
    type: "string",
    renderHeader: () => <b>{i18n.t("ETIQUETA")}</b>,
    renderCell: roleCell,
    flex: 1,
    minWidth: 200,
  },
  {
    field: i18n.t("EDITAR"),
    renderCell: editCell,
    flex: 1,
    minWidth: 70,
  },
  {
    field: i18n.t("ELIMINAR"),
    renderCell: delCell,
    flex: 1,
    minWidth: 100,
  },
]

function CustomPagination() {
  const apiRef = useGridApiContext()
  const page = useGridSelector(apiRef, gridPageSelector)
  const pageCount = useGridSelector(apiRef, gridPageCountSelector)
  return (
    <Pagination
      color="primary"
      variant="outlined"
      shape="rounded"
      page={page + 1}
      count={pageCount}
      renderItem={props2 => <PaginationItem {...props2} />}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  )
}
const ContactTable = ({ contacts }) => {
  let rows = []

  function completeRows() {
    const rows = contacts.map(contact => {
      const {
        id = "",
        completeName = "",
        email = "",
        mobile = "",
        role = [],
        orgName = "",
        nif = "",
        address = "",
      } = contact

      const formattedRoles = (role || []).map((x, i) => ({
        role: x,
        color: listaColores[i] || "#color1",
      }))

      return {
        id,
        completeName,
        email,
        mobile,
        role: formattedRoles,
        orgName,
        nif,
        address,
      }
    })

    return rows
  }

  rows = completeRows()

  const [openEditar, setOpenEditar] = useState(false)
  const [openAdd, setOpenAdd] = useState(false)
  const [openEliminate, setOpenEliminate] = useState(false)
  const [selectedContact, setSelectedContact] = useState({})
  const { user, getUser, freeTrialExpired, openFreeTrialExpire } =
    useContext(AuthContext)

  useEffect(() => {
    if (!user) {
      getUser()
    }
  }, [])

  const handleOpen = GridCellParams => {
    if (user) {
      if (user.freeTrialExpired || user["custom:role"] === "WORKER") {
        return
      } else {
        if (GridCellParams.field === "EDITAR") {
          //INFO DE LA FILA
          setSelectedContact(GridCellParams.row)
          freeTrialExpired ? openFreeTrialExpire() : setOpenEditar(true)
        }
        if (GridCellParams.field === "ELIMINAR") {
          //INFO DE LA FILA
          setSelectedContact(GridCellParams.row)
          setOpenEliminate(true)
        }
      }
    } else {
      if (GridCellParams.field === "EDITAR") {
        //INFO DE LA FILA
        setSelectedContact(GridCellParams.row)
        freeTrialExpired ? openFreeTrialExpire() : setOpenEditar(true)
      }
      if (GridCellParams.field === "ELIMINAR") {
        //INFO DE LA FILA
        setSelectedContact(GridCellParams.row)
        setOpenEliminate(true)
      }
    }
  }

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      {contacts.length > 0 ? (
        <Box
          sx={{
            height: "60vh",
            width: "90%",
          }}
        >
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10, 20, 30]}
            onCellClick={handleOpen}
            sx={{
              display: "flex",
              flexDirection: "column-reverse",
              border: "0px",
            }}
            components={{
              Pagination: CustomPagination,
            }}
          />
        </Box>
      ) : (
        <>
          <div id="fotoPaco">
            <img id="paquitoContactos" src={paquitoContactos} />
          </div>
          <div id="justifyPaco">
            <div id="messagePaco">
              {i18n.t(
                "Por ahora soy tu único contacto registrado. Agrega tu primer contacto clicando abajo."
              )}
            </div>
          </div>
        </>
      )}

      <AddContact openAdd={openAdd} setOpenAdd={setOpenAdd} />
      <ModalEditar
        open={openEditar}
        setOpen={setOpenEditar}
        selectedContact={selectedContact}
      />

      <ModalEliminar
        openEliminate={openEliminate}
        setOpenEliminate={setOpenEliminate}
        selectedContact={selectedContact}
      />
    </Box>
  )
}

ContactTable.propTypes = {
  contacts: PropTypes.array.isRequired,
}

export default ContactTable
