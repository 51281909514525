import { BlueButton, CapituloMini } from "common/containers";
import React, {useState, useContext} from "react";
import PropTypes from 'prop-types'
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import { currencyFormatDE } from "lib/transformers";
import Budget2023Context from "context/budget2023/Budget2023Context";
import { getCurrency } from "utils/functions"
import AuthContext from "context/auth/authContext";


const CapituloModal = ({capitulo, capituloSelected, setCapituloSelected, margin}) => {
  const [openNotSendCapitulo, setOpenNotSendCapitulo] = useState(false)
  const {user} = useContext(AuthContext)
  const {getSubCapitulosContent} = useContext(Budget2023Context)
  const [cargado, setCargado] = useState(false)
  const handleOpen = async(e) => {
      if (!e) var e = window.event;
      e.cancelBubble = true;
      if (e.stopPropagation) e.stopPropagation();
      setOpenNotSendCapitulo(prev => !prev)
      if(cargado==false){
        getSubCapitulosContent(capitulo.projectId, capitulo.chapterId)
        setCargado(true)
      }
    }
   
    return (
        <>
        <CapituloMini onClick={()=>setCapituloSelected(capitulo)} style={{background: capituloSelected.capituloInfo? capituloSelected.capituloInfo.chapterId==capitulo.capituloInfo.chapterId ? '#FFE992' : 'white': 'white', paddingLeft:margin+'px'}}>
            {openNotSendCapitulo ? (
                <div>
                  <KeyboardArrowDownIcon
                    style={{ cursor: "pointer", marginLeft: "5px", marginTop:'5px', visibility: capitulo.subcapitulos.length==0 && cargado==true ? 'hidden' : 'visible'  }}
                    className="dropdownArrowB"
                    onClick={(e) => handleOpen(e)}
                  />
                </div>
              ) : (
                <KeyboardArrowRightIcon
                style={{ cursor: "pointer", marginLeft: "5px", marginTop:'5px', visibility: capitulo.subcapitulos.length==0 && cargado==true ? 'hidden' : 'visible' }}
                  className="dropdownArrowB"
                  onClick={(e) => handleOpen (e)}
                />
              )}
              <p>
                {capitulo.capituloInfo.name}
              </p>
              <BlueButton style={{color:'white', justifyContent:'center', textAlign:'center', marginRight:'26px', marginTop:'6px'}}>
                {currencyFormatDE(capitulo.total,getCurrency(user['custom:currency']))}
              </BlueButton>
        </CapituloMini>
        {openNotSendCapitulo ? 
            capitulo.subcapitulos.length > 0 ? (
                capitulo.subcapitulos.map((subcapitulo, index) => (
                    <CapituloModal key={index} margin={margin+20} capitulo={subcapitulo} capituloSelected={capituloSelected} setCapituloSelected={setCapituloSelected} />
                ))
            ) : null : null}
        </>
    )
}

CapituloModal.propTypes = {
    capitulo: PropTypes.object.isRequired,
    capituloSelected: PropTypes.object.isRequired,
    setCapituloSelected: PropTypes.func.isRequired,
    margin: PropTypes.number.isRequired

}

export default CapituloModal;
