import React from 'react'
import MetaTags from 'react-meta-tags'

import {
    Container
} from 'reactstrap'

import SelectBankPage from 'components/BancoPreciosReferencia/selectBankPrice';
import { SquareContent } from 'common/containers';
import { RecordPageView } from 'App'
const SelectReferencia = () => {
    RecordPageView()
    return (
        <React.Fragment>
            <div className='page-content'>
                <MetaTags>
                    <title>Planhopper | Banco de precios </title>
                </MetaTags>
                <Container fluid>
                <div style={{justifyContent:'center', width:'100%', textAlign:'center', position:'relative', display:'flex', height:'80vh'}}>
                    <SquareContent style={{top:"50%" , padding: "40px", height:"490px", width:'520px', boxShadow:'none'}}>
                        <SelectBankPage/>
                    </SquareContent>
                </div>

                </Container>
            </div>
        </React.Fragment>

    )
}

export default SelectReferencia