import React, { useContext, useEffect } from 'react'
import { Modal } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types'
import { useState } from 'react';
import i18n from 'i18n';
import CloseIcon from "@mui/icons-material/Close";
import AuthContext from 'context/auth/authContext';
import ContactContext from 'context/contact/contactContext';
import {VersionsTable} from 'common/tables'
import { YellowButton } from 'common/buttons';
import Lottie from 'react-lottie-player'
import lottieJson from 'components/Facturas/sendFactura/animation.json'
import {  PoppinsNormal, HelveticaBold} from 'common/typographies';

function getModalStyle() {
    const top = 50 ;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: '850px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        borderRadius: 6,
        maxHeight: '550px',
        height: '550px',
        overflowY: 'auto',
        overflowX:'hidden',
    },
}));


const ModalSendMultiple = ({open, setOpen, invoices, invoiceService}) => {

    const [ modalStyle ] = useState(getModalStyle);
    const classes = useStyles();
    const [loading, setLoading] = useState(false)
    const [step, setStep] = useState(1)
    const {Contacts, getContacts} = useContext(ContactContext)
    const [contactSelected, setContactSelected] = useState(null)
    const {user} = useContext(AuthContext)

    useEffect(()=>{
        if(Contacts.length==0){
            getContacts()
        }
    },[])

    const handleSendMultiple = async() => {
        setStep(2)
        setLoading(true)
        for(let i=0; i<invoices.length; i++){
        const sendData = {
            selectedContacts: contactSelected,
            roles: contactSelected.role,
            asunto: 'Facturas',
            mensaje: '',
            title : invoices[i].invoiceNumber+'.pdf',
            projectId: invoices[i].projectId,
            orgId: invoices[i].accountId,
            selectedFiles : []
          }
          await invoiceService.handleSend({
            sendData: sendData,
            invoices: invoices,
          })
        }
        setLoading(false)
        setStep(3)
        }
   
    return(
        <>
        <Modal open={open} onClose={() => null}>
            <div style={modalStyle} className={classes.paper}>
                <div id='principalOptions' style={{marginTop:'20px', marginBottom:'15px', textAlign:'left', justifyContent:'center', position:'relative', display:'grid'}}>
                    <CloseIcon id="cerrar" style={{marginTop:'-15px', position:'absolute', right:'-10px'}} onClick={()=>{setOpen(false)}}></CloseIcon>
                    {step==1 ? <>
                        <p id='titles' style={{textAlign:'CENTER', fontSize:'20px', marginBottom:"35px"}}>{i18n.t("Selecciona el contacto al que enviar las facturas")}</p>
                        <VersionsTable style={{maxHeight:'350px'}}>
                        <tr>
                            <th style={{minWidth:'20px'}}></th>
                            <th style={{width:'200px'}} colSpan='2'>{i18n.t('Nombre')}</th>
                            <th style={{width:'300px'}}>{i18n.t('Email')}</th>
                            <th style={{width:'100px'}}>{i18n.t('Rol')}</th>
                        </tr>
                            {  Contacts.map((contact, index) => (
                                <tr className='hoverYellow' key={index} style={{cursor:'pointer', background: contactSelected?.index == index ? '#FFF2BC' : 'white'}} onClick={(e)=>{setContactSelected({...contact, index:index});}}>
                                    <td>
                      
                                    </td>
                                <td style={{width:'50px'}}><img src={user['custom:avatar']} style={{width:'45px', height:'45px', marginRight:'0px'}}/></td>
                                <td style={{fontWeight:'500', color:'#414141', paddingLeft:'0PX'}}>{contact.completeName}</td>
                                <td style={{color:'#414141'}}> {contact.email}</td>
                                <td>{contact.role? contact.role.length>0 ?
                                 <div style={{width:'fit-content', paddingRight:'10px'}} id="color1" className="tag">
                                 {contact.role[0]}
                               </div>
                                : null : null}</td>
                            </tr>
                                            
                                ))}
                                                    
                    </VersionsTable>
                    <div style={{justifyContent:'center', width:'100%', display:'flex', marginTop:'50PX'}}>
                    <YellowButton onClick={()=>{handleSendMultiple()}}>
                                        {i18n.t('Confirmar y enviar')}
                                        </YellowButton>         
                    </div>
                  
                    </>: 
                    step == 2 ? <>
                      <div style={{justifyContent:'center', width:'100%', textAlign:'center', position:'relative', display:'flex', marginLeft:'-80px', marginTop:'60px'}}>
                       <div className='loader'>
                           <div className='ball'></div>
                           <div className='ball'></div>
                           <div className='ball'></div>
                      </div>
                       </div>
                       <div style={{justifyContent:'center', width:'100%', textAlign:'center', position:'relative', display:'flex', marginLeft:'-10px'}}>
                       <PoppinsNormal>{i18n.t("Enviando facturas...")}</PoppinsNormal>
                       </div>
                       <div style={{justifyContent:'center', width:'100%', textAlign:'center', position:'relative', display:'flex', marginLeft:'-10px', marginTop:'15px'}}>
                       <PoppinsNormal style={{fontWeight:'400'}}>{i18n.t("Esta acción puede tardar unos segundos")}</PoppinsNormal>
                       </div>
                    </>:
                    <>
                    <Lottie
                        loop
                        animationData={lottieJson}
                        play
                        style={{ width: 300, height: 300, marginLeft:'0px', marginTop:'20px'}}
                    />
                    <div style={{justifyContent:'center', width:'100%', textAlign:'center', position:'relative', display:'flex', marginLeft:'-10px'}}>
                          <HelveticaBold style={{fontSize:'18px', color:'#414141'}}>{i18n.t("Facturas enviadas correctamente")}</HelveticaBold>
                    </div>
                  
                    <div style={{justifyContent:'center', width:'100%', display:'flex', marginTop:'50PX'}}>
                    <YellowButton onClick={()=>{setOpen(false)}}>
                                        {i18n.t('Cerrar')}
                                        </YellowButton>
                    </div>
                    </>
                    }
                    
                    
               
                </div>
            </div>
        </Modal>
    
        </>
    )
}

ModalSendMultiple.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    invoices: PropTypes.array.isRequired,
    invoiceService: PropTypes.any.isRequired,
}
export default ModalSendMultiple;