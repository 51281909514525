import React, { useState, useContext } from "react"
import CloseIcon from "@mui/icons-material/Close"
import { makeStyles } from "@material-ui/core/styles"
import { CircularProgress, Modal } from "@mui/material"
import PropTypes from "prop-types"
import i18n from "i18n"
import { ListItem, Typography } from "@material-ui/core"
import { List } from "reactstrap"
import { Auth, API } from "aws-amplify"
import Budget2023Context from "context/budget2023/Budget2023Context"

function getModalStyle() {
  const top = 50
  const left = 50
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}
const SERVICE_PART_CORE = "service-part-core"
const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "absolute",
    width: "522px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 6,
    maxHeight: "calc(100vh - 100px)",
    overflowY: "auto",
    overflowX: "hidden",
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "50px",
  },
  subTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "40px",
  },
  input: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "40px",
  },
  list: {
    marginTop: "40px",
  },
  closeButton: {
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(2),
    cursor: "pointer",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(2),
  },
}))

const ModalImportPartFromBc3Link = ({ openModal, setOpenModal, callback }) => {
  const [modalStyle] = useState(getModalStyle)
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [link, setLink] = useState("")
  const {
    setBc3LinkData,
    isImportingPartFromEditModal,
    setModalBudgetingError,
  } = useContext(Budget2023Context)
  const handleInputChange = e => {
    setLink(e.target.value)
  }
  const getBc3LinkDataFormatted = data => {
    let pricePerc = 0
    const decompositionList = data.parts.map(dec => {
      const concepto = dec.concepto
      const d = {
        type: parseInt(concepto.type_ || "0"),
        description: concepto.short_description || "",
        quantity: dec.rendimiento.toString() || "0",
        unity: concepto.unit || "pa",
      }
      if (d.unity !== "%") {
        d.price = concepto.price ? concepto.price[0].toString() : "0"
      } else {
        d.price = pricePerc.toFixed(2)
      }
      pricePerc += d.price * d.quantity

      return d
    })
    const priceList = decompositionList.map(d => {
      return d.price * d.quantity
    })

    const dataFormatted = {
      decomposition: decompositionList,
      price: priceList.reduce((total, currentValue) => total + currentValue, 0),
      code: data.code[0],
      description: data.description,
      name: data.short_description,
      unity: data.unit,
      refPrice: 0,
      quantity: 0,
      clientPrice: 0,
      margin: 0,
      costeGeneral: 0,
      costeIndirecto: 0,
      industrialProfit: 0,
      comment: "",
      bc3file_url: data.origin_url,
      measures: [],
    }
    return dataFormatted
  }

  const onSubmit = async e => {
    e.preventDefault()
    const payload = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        "Access-Control-Allow-Origin": "*",
      },
      body: {},
    }
    setLoading(true)
    try {
      const encodedLink = encodeURIComponent(link)

      const resp = await API.post(
        SERVICE_PART_CORE,
        `/parse/bc3?url=${encodedLink}`,
        payload
      )
      const dataFormatted = getBc3LinkDataFormatted(resp)
      setBc3LinkData(dataFormatted)
      setLoading(false)
      setOpenModal(false)
      if (!isImportingPartFromEditModal && callback) {
        callback()
      }
    } catch (error) {
      setModalBudgetingError({
        isOpen: true,
        error: error,
        location: `Import part from BC3 link: ${link}`,
      })
      setBc3LinkData(null)
      setLoading(false)
      setOpenModal(false)
    }
  }

  return (
    <Modal
      open={openModal}
      onClose={() => {
        setOpenModal(false)
      }}
    >
      <div style={modalStyle} className={classes.paper}>
        <div className={classes.closeButton}>
          <CloseIcon
            onClick={() => {
              setOpenModal(false)
            }}
          />
        </div>
        <Typography variant="h5" gutterBottom className={classes.title}>
          {i18n.t("linkBC3.title")}
        </Typography>
        <Typography
          variant="subtitle1"
          gutterBottom
          className={classes.subTitle}
        >
          <a rel="noreferrer" target="_blank" href="https://help.planhopper.com/articles/7493451-importar-partida-bc3-desde-generador-de-precios-de-cype">{i18n.t("linkBC3.watchVideo")}</a>
        </Typography>
        <input
          className={classes.input}
          onChange={handleInputChange}
          placeholder={i18n.t("linkBC3.inputPlaceholder")}
          name="link"
          value={link}
        />
        <List className={classes.list}>
          <Typography variant="body2" gutterBottom>
            {i18n.t("linkBC3.support")}
          </Typography>
          <ListItem>
            <Typography variant="body1">
              • {i18n.t("linkBC3.supportItem1")}
            </Typography>
          </ListItem>
        </List>
        <div className={classes.buttonContainer}>
          {loading ? (
            <CircularProgress size={24} />
          ) : (
            <button
              type="submit"
              onSubmit={onSubmit}
              onClick={onSubmit}
              className="AddContact"
              data-amplify-analytics-on="click"
              data-amplify-analytics-name="ProjectCreated"
            >
              {i18n.t("buttons.trigger")}
            </button>
          )}
        </div>
      </div>
    </Modal>
  )
}
ModalImportPartFromBc3Link.propTypes = {
  openModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  callback: PropTypes.func,
}

export default ModalImportPartFromBc3Link
