export const API_URLS = { 
    'dev':        'https://api.dev.planhopper.com',
    'qa':         'https://api.qa.planhopper.com',
    'stg':        'https://api.stg.planhopper.com',
    'planhopper': 'https://api.prod.planhopper.com'
}

export const CORE_URLS = {
    'dev':        'https://core.dev.planhopper.com',
    'qa':         'https://core.qa.planhopper.com',
    'stg':        'https://core.stg.planhopper.com',
    'planhopper': 'https://core.prod.planhopper.com'
}

export const CognitoUserPools = {
    'dev': {
        'poolId': 'eu-west-1_JNNspjQHg', 
        'webClientId': '69hhp95so766qq390oatkpb1ql',
        'identityPoolId': 'eu-west-1:94d474f4-eb61-4e38-8c67-977a21f0fb1a',
        'customRoleArn' : 'arn:aws:iam::711693965565:role/Cognito_tribbooappdevpinpointAuth_Role'
    },
    'qa': {
        'poolId': 'eu-west-1_JNNspjQHg',
        'webClientId': '69hhp95so766qq390oatkpb1ql',
        'identityPoolId': 'eu-west-1:94d474f4-eb61-4e38-8c67-977a21f0fb1a',
        'customRoleArn' : 'arn:aws:iam::711693965565:role/Cognito_tribbooappdevpinpointAuth_Role'
    },
    'stg': {
        'poolId': 'eu-west-1_XaEq6ZxVq',
        'webClientId': '2mbtkv2467ruskse5h5frqqp1a',
        'identityPoolId': 'eu-west-1:7b7292db-29c0-4e7f-a884-eca1e3cbe31c',
        'customRoleArn' : 'arn:aws:iam::905839172847:role/service-role/CognitoIdentityPoolAuthRole'
    },
    'planhopper': {
        'poolId': 'eu-west-1_0FPQ7pfW1',
        'webClientId': 't930opupjok1b5cb99fda5q7k',
        'identityPoolId': 'eu-west-1:94d474f4-eb61-4e38-8c67-977a21f0fb1a',
        'customRoleArn' : 'arn:aws:iam::711693965565:role/Cognito_tribbooappdevpinpointAuth_Role'
    }
}

export const AppsyncSettings = {
    'dev': {
        'endpoint': 'https://n5wiu5gvm5fi3pzudpr466ogpa.appsync-api.eu-west-1.amazonaws.com/graphql',
        'apiKey':   'da2-25647yersjfqvlusqcbofiiw2i'  // Expires at Mon, 30 Jun 2025 19:00:00 GMT
    },
// merged api test
    'stg': {
        //'endpoint': 'https://7hufsdia3rgobm2a2dt4qaysz4.appsync-api.eu-west-1.amazonaws.com/graphql',
        'endpoint': 'https://appsync.stg.planhopper.com/graphql',
        'apiKey':   'da2-3rbu7ca7bbgvtbkux7k7twzv6q'
    },
//    'stg': {
//      'endpoint': 'https://57pd4r7l25amjmdb7sq6gdpp5e.appsync-api.eu-west-1.amazonaws.com/graphql',
//      'apiKey':   'da2-elmly3qi35d7rpi2vpyyqgbvey'
//    },
    'planhopper': {
        'endpoint': 'https://6cuplvnnx5ahve5sd2hodymp74.appsync-api.eu-west-1.amazonaws.com/graphql',
        'apiKey': 'da2-3e7zgoh3f5gcxozsfhs6yjmpce',   // Expires at Tue, 15 Jul 2025 22:00:00 GMT
    }
}
