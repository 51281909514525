import React, {useContext, useEffect, useState} from 'react';
import CloseIcon from "@mui/icons-material/Close";
import {makeStyles} from "@material-ui/core/styles";
import { CircularProgress, Modal} from "@mui/material";
import PropTypes from "prop-types";

import i18n from 'i18n';
import { PoppinsSemiBold, PoppinsNormal} from 'common/typographies';
import ContactContext from 'context/contact/contactContext';
import AuthContext from 'context/auth/authContext';
import {VersionsTable} from 'common/tables'
import { YellowButton } from 'common/buttons';
import AddContact from 'components/Contacts/ContactTable/addContact';




function getModalStyle() {
    const top = 50 ;
    const left = 50;
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };

}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: 'auto',
        height: '500px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        borderRadius: 6,
        maxHeight: 'calc(100vh - 100px)',
        overflowY: 'auto',
        overflowX:'hidden',
    },
}));

const ModalSelectContact =({open, setOpen, setContactSelected})=> {

    const [ modalStyle ] = useState(getModalStyle);
    const classes = useStyles();
    const {Contacts, getContacts, loadingContacts} = useContext(ContactContext)
    const {user} = useContext(AuthContext) 
    
    useEffect(()=>{
        if(open){
            getContacts()
        }
    },[])

    const handleAddContacts = async(e, index) => {
        e.preventDefault()
        setContactSelected(Contacts[index])
        setOpen(false)
        
    }
    const [openAddContact, setOpenAddContact] = useState(false)
        
    return(
        <>
            <Modal
                open={open}
                onClose={() => {
                    null
                }}
            >
                <div style={modalStyle} className={classes.paper}>
                <CloseIcon id="cerrar" onClick={() => {
                        setOpen(false);
                    }}></CloseIcon>
                             <PoppinsSemiBold style={{marginTop:'20px', fontSize:'18px'}}>{i18n.t('Contactos')}</PoppinsSemiBold>
                <div style={{width:'100%', position:'relative'}}>
                    {
                        loadingContacts ?
                        <div style={{marginLeft:'60px'}}>
                    <div style={{justifyContent:'center', width:'100%', textAlign:'center', position:'relative', display:'flex', marginLeft:'-100px', marginTop:'60px'}}>
                    <div className='loader'>
                        <div className='ball'></div>
                        <div className='ball'></div>
                        <div className='ball'></div>
                   </div>
                    </div>
                    <div style={{justifyContent:'center', width:'100%', textAlign:'center', position:'relative', display:'flex', marginLeft:'-30px'}}>
                    <PoppinsNormal>{i18n.t("Cargando...")}</PoppinsNormal>
                    </div>
                    <div style={{justifyContent:'center', width:'100%', textAlign:'center', position:'relative', display:'flex', marginLeft:'-30px', marginTop:'-15px'}}>
                    <PoppinsNormal style={{fontWeight:'400'}}>{i18n.t("Esta acción puede tardar unos segundos")}</PoppinsNormal>
                    </div>
                   
                    </div> :

                    Contacts.length === 0 ?
                    <div style={{position:'relative', display:'block', justifyContent:'center', textAlign:'center', marginTop:'80px', width:'450px'}}>
                    <PoppinsSemiBold style={{fontSize:'18px'}}>
                        {i18n.t("No tienes contactos")}
                    </PoppinsSemiBold>
                    <YellowButton style={{width:'190px', marginTop:'80px', height:'40px', paddingTop:'10px'}} onClick={() => {setOpenAddContact(true)}}>
                        <PoppinsSemiBold style={{fontSize:'14px'}}>{i18n.t('Agregar Contacto')}</PoppinsSemiBold>
                    </YellowButton>
                    
                    </div>
                    :
                    <>
                    <VersionsTable style={{maxHeight:'350px'}}>
                        <tr>
                            <th style={{minWidth:'20px'}}></th>
                            <th style={{width:'300px'}} colSpan='2'>{i18n.t('Nombre')}</th>
                            <th style={{width:'300px'}}>{i18n.t('Email')}</th>
                            <th style={{width:'100px'}}>{i18n.t('Rol')}</th>
                        </tr>
                            {  Contacts.map((contact, index) => (
                                <tr className='hoverYellow' key={index} style={{cursor:'pointer', background:'white'}} onClick={(e)=>{handleAddContacts(e, index)}}>
                                    <td>
                      
                                    </td>
                                <td style={{width:'50px'}}><img src={user['custom:avatar']} style={{width:'45px', height:'45px', marginRight:'0px'}}/></td>
                                <td style={{fontWeight:'500', color:'#414141', paddingLeft:'0PX'}}>{contact.completeName}</td>
                                <td style={{color:'#414141'}}> {contact.email}</td>
                                <td>{contact.role? contact.role.length>0 ?
                                 <div style={{width:'fit-content', paddingRight:'10px'}} id="color1" className="tag">
                                 {contact.role[0]}
                               </div>
                                : null : null}</td>
                            </tr>
                                            
                                ))}
                                                            
                                                            
                                                        
                    </VersionsTable>
                    <div id='centerPaco'>
                    <YellowButton style={{width:'190px', height:'40px', marginTop:'-10px', paddingTop:'10px'}} onClick={() => {setOpenAddContact(true)}}>
                        <PoppinsSemiBold style={{fontSize:'14px'}}>{i18n.t('Agregar Contacto')}</PoppinsSemiBold>
                    </YellowButton>
                    </div>
                
                    </>
                    }
                </div>
                </div>

            </Modal>
            {
                openAddContact && <AddContact openAdd={openAddContact} setOpenAdd={setOpenAddContact}/>
            }
        </>
    )
}
ModalSelectContact.propTypes = {
    open: PropTypes.bool, 
    setOpen: PropTypes.func,
    setContactSelected: PropTypes.func
}
  

export default ModalSelectContact;