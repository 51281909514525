import React, { useContext, useEffect, useState } from "react"
import propTypes from "prop-types"
import { FakeInput } from "common/containers"
import circlePlus from "assets/images/icons/circlePlus.svg";
import ModalSelectContact from "./modalSelectContact"
import DeleteOutline from "@mui/icons-material/DeleteOutline"
import i18n from "i18n"
import { HelveticaBold, PoppinsSemiBold } from "common/typographies"
import AuthContext from "context/auth/authContext";


const SecondStep2 = ({ soyFinal,setCont,  setSelectedContacts, selectedContacts}) => {


  useEffect(()=>{setCont(Math.random())}, [selectedContacts.length])
  const [open, setOpen] = useState(false)
  const {user} = useContext(AuthContext)
  
  return (
    <>
         <HelveticaBold style={{fontWeight:'400', fontSize:'14px', color:'#414141', marginTop:'30px'}}>
        {i18n.t("Añade el destinatario al que quieres enviar tu presupuesto")}
     </HelveticaBold>
     <div style={{display:'flex', padding:'10px', paddingLeft:'0px', marginBottom:'-10px'}}>
            <PoppinsSemiBold style={{fontSize:'14px'}}>
                {i18n.t('Contacto')}
            </PoppinsSemiBold>
        </div>
        
        <FakeInput onClick={()=>{setOpen(true)}} className="inline" style={{marginTop:'-20px', width:'100%', paddingLeft:'10px', minWidth:'250px'}}>
            <img src={circlePlus} width={20}/>
            <HelveticaBold style={{fontWeight:'300', fontSize:'14px', marginLeft:'5px', marginTop:'9px'}}>{i18n.t('Buscar contactos')}</HelveticaBold>
        </FakeInput>
        {selectedContacts.length>0 ?
        <div style={{marginTop:'20px'}}>
            {selectedContacts.map((contact, index)=>{
                return(
                    <div key={index} style={{display:'flex', alignItems:'center', marginTop:'2px', background:'#D9D9D9', borderRadius:'6px', position:'relative'}}>
                        <img src={user['custom:avatar']} width={40} height={40} style={{marginLeft:'5px'}}/>
                        <PoppinsSemiBold style={{fontSize:'14px', marginRight:'10px', fontWeight:'400', marginTop:'14px', marginLeft:'12px',width: '45%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{contact.completeName}</PoppinsSemiBold>
                        <PoppinsSemiBold style={{fontSize:'14px', marginTop:'14px', fontWeight:'400', width: '45%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{contact.email}</PoppinsSemiBold>
                        <DeleteOutline style={{position:'absolute', right:'10px', marginTop:'-4px', cursor:'pointer', fontSize:'20px', color:'#414141'}} onClick={()=>{setSelectedContacts(selectedContacts.filter((item, i)=>{return i!==index}))}}/>
                    </div>
                )
            }
            )}
        </div>
        : null }

{open && <ModalSelectContact open={open} setOpen={setOpen} selectedContacts={selectedContacts}  setSelectedContacts={setSelectedContacts} />   }


    </>

  )
}

SecondStep2.propTypes = {
  setSelectedContacts: propTypes.func.isRequired,
  selectedContacts: propTypes.array.isRequired,
    soyFinal: propTypes.bool,
    setCont: propTypes.func.isRequired,
}

export default SecondStep2
