import React, { useContext, useRef, useEffect, useState } from "react"
import propTypes from "prop-types"
import i18n from "i18n"
import { HelveticaBold, PoppinsSemiBold } from "common/typographies"
import { FakeInput } from "common/containers"
import circlePlus from "assets/images/icons/circlePlus.svg";
import ModalSelectContact from "./modalSelectContact"
import AuthContext from "context/auth/authContext"
import DeleteOutline from "@mui/icons-material/DeleteOutline"


const FirstStep = ({selectedContacts, selectedRoles, setSelectedContacts, setSelectedRoles, setCont}) => {

  const [open, setOpen] = useState(false)
  const {user} = useContext(AuthContext)
  const changeCheckbox = (index) => {
    const newSelectedRoles = [...selectedRoles]
    newSelectedRoles[index].checked = !newSelectedRoles[index].checked
    newSelectedRoles[index].key = Math.random()
    setSelectedRoles([...newSelectedRoles])
  }
  useEffect(()=>{setCont(Math.random())}, [selectedContacts.length])

  return (
    <>
     <HelveticaBold style={{fontWeight:'400', fontSize:'14px', color:'#414141', marginTop:'40px'}}>
        {i18n.t("Añade el destinatario al que quieres enviar tu factura")}
     </HelveticaBold>
     <div>
        <div style={{display:'flex', padding:'10px', paddingLeft:'0px', marginBottom:'-10px'}}>
            <PoppinsSemiBold style={{fontSize:'14px'}}>
                {i18n.t('Contacto')}
            </PoppinsSemiBold>
        </div>
        
        <FakeInput onClick={()=>{setOpen(true)}} className="inline" style={{marginTop:'-20px', width:'100%', paddingLeft:'10px', minWidth:'250px'}}>
            <img src={circlePlus} width={20}/>
            <HelveticaBold style={{fontWeight:'300', fontSize:'14px', marginLeft:'5px', marginTop:'9px'}}>{i18n.t('Buscar contactos')}</HelveticaBold>
        </FakeInput>
        {selectedContacts.length>0 ?
        <div style={{marginTop:'30px'}}>
            {selectedContacts.map((contact, index)=>{
                return(
                    <div key={index} style={{display:'flex', alignItems:'center', marginTop:'2px'}}>
                        <img src={user['custom:avatar']} width={40} height={40}/>
                        <PoppinsSemiBold style={{fontSize:'14px', marginRight:'10px', fontWeight:'400', paddingTop:'13px', marginLeft:'12px'}}>{contact.completeName}</PoppinsSemiBold>
                        <PoppinsSemiBold style={{fontSize:'14px', position:'absolute', right:'5%', marginTop:'13px', fontWeight:'400'}}>{contact.email}</PoppinsSemiBold>
                    </div>
                )
            }
            )}
        </div>
        : null }

        <HelveticaBold style={{fontWeight:'400', fontSize:'14px', color:'#414141', marginTop:'30px'}}>
        {i18n.t("Selecciona si vas a mandar tu factura a alguno de estos perfiles")}
     </HelveticaBold>
     <div style={{marginTop:'30px'}}>
        {
            selectedRoles.map((profile, index)=>{
                return(
                    <div key={profile.key} className="inline" style={{marginTop:'-5px'}}>
                    <input
                            className="form-check-input"
                            type="checkbox"
                            id="flexCheckDefault"
                            checked={profile.checked}
                            onChange={()=>{changeCheckbox(index)}}
                            style={{ width: "15px", height: "15px", cursor:'pointer', zIndex:"5", padding:'0px', border:'1.5px solid #414141' }}
                    ></input>
                    <HelveticaBold style={{fontWeight:'400', fontSize:'14px', color:'#414141', marginLeft:'10px', marginTop:'1.5px'}}>
                    {profile.name}
                 </HelveticaBold>
                    </div>
                )
            }
            )
        }
     </div>
    
    </div>
     {open && <ModalSelectContact open={open} setOpen={setOpen} selectedContacts={selectedContacts}  setSelectedContacts={setSelectedContacts} />   }
    </>

  )
}

FirstStep.propTypes = {
    selectedContacts: propTypes.array.isRequired,
    selectedRoles: propTypes.object.isRequired,
    setSelectedContacts: propTypes.func.isRequired,
    setSelectedRoles: propTypes.func.isRequired,
    setCont: propTypes.func.isRequired,
}

export default FirstStep
