import i18n from "i18n"
import CloseIcon from "@mui/icons-material/Close"
import { Modal } from "@mui/material"
import React, { useRef, useState, useEffect, useCallback } from "react"
import { makeStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { Auth } from "aws-amplify"
import ModalConfirm from "./ModalConfirm"

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "630px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 6,
    maxHeight: "calc(100vh - 100px)",
    overflowY: "auto",
    overflowX: "hidden",
  },
}))


const ModalPassword = ({ openPassword, setOpenPassword }) => {
  const [modalStyle] = useState(getModalStyle)
  const classes = useStyles()
  const [currentPasswordShown, setCurrentPasswordShown] = useState(false)
  const [newPasswordShown, setNewPasswordShown] = useState(false)
  const refPasswordActual = useRef(null)
  const refPasswordNueva = useRef(null)
  const refPasswordRepita = useRef(null)
  const refErrorActual = useRef(null)
  const refErrorNueva = useRef(null)
  const refErrorRepita = useRef(null)

  

  const onClose = () => {
    setOpenPassword(false)
  }

  const [valida, setValida] = useState(false)

  const changePassword = () => {
    if (valida && valida2 && valida3) {
      Auth.currentAuthenticatedUser()
        .then(user => {
          return Auth.changePassword(user, contra.actual, contra.nueva)
        })
        .then(data => {
          setOpenNewModal(true)
          setOpenPassword(false)
        })
        .catch(err => {
          if (
            err == "NotAuthorizedException: Incorrect username or password."
          ) {
            refErrorActual.current.id = "ErrorMessagePasswordShow"
            refErrorActual.current.innerHTML = "La contraseña es incorrecta"
          }
        })
    }
  }

  const [contra, guardarContra] = useState({
    actual: "",
    nueva: "",
    repita: "",
  })

  const obtenerContra = e => {
    guardarContra({
      ...contra,
      [e.target.name]: e.target.value,
    })
    if(refErrorActual.current){
      refErrorActual.current.id = "errorMessagePassword"
    }
  }

  const clickPassword = e => {
    if (e.target.name == "actual") {
      refPasswordActual.current["id"] = "focus"
    } else if (e.target.name == "nueva") {
      refPasswordNueva.current["id"] = "focus"
    } else if (e.target.name == "repita") {
      refPasswordRepita.current["id"] = "focus"
    }
  }

  const isLowercase = str => {
    for (let i = 0; i < str.length; i++) {
      if (str[i] === str[i].toUpperCase()) {
        return false
      }
    }
    return true
  }

  const [counter, setCounter] = useState(0)
  const [valida3, setValida3] = useState(false)

  useEffect(() => {
    if (openPassword) {
      if(contra.nueva.length > 0){
        setCounter(counter+1)
        if ((!isLowercase(contra.nueva)) && contra.nueva.length >= 8) {
            setValida(true)
        }else{
          setValida(false)
        }
      }else{
        setValida(false)
      }
    } 
  }, [openPassword, contra.nueva.length])

  useEffect(() => {
    if(refPasswordNueva.current && refErrorNueva.current){
    if(!valida && counter>0){
      refPasswordNueva.current["id"] = "focus"
      refErrorNueva.current["id"] = "ErrorMessagePasswordShow"
    }else if(valida && counter>0){
      refPasswordNueva.current["id"] = ""
      refErrorNueva.current["id"] = "errorMessagePassword"
      setCounter(0)
    }
  }
  }, [valida, counter, refErrorNueva, refPasswordNueva])

  const [valida2, setValida2] = useState(false)
  const [openNewModal, setOpenNewModal] = useState(false)

  useEffect(() => {
    if (refErrorRepita.current) {
      if(contra.repita.length > 0){
      if (contra.nueva !== contra.repita) {
        setValida3(false)
        refErrorRepita.current.id = "ErrorMessagePasswordShow"
      } else {
        setValida3(true)
        refErrorRepita.current.id = "errorMessagePassword"
      }
    }
    if(contra.actual.length > 0){
      setValida2(true)
    }
  }
  }, [contra.repita, contra.nueva, contra.actual])
  return (
    <>
      <Modal open={openPassword} onClose={null}>
        <div style={modalStyle} className={classes.paper}>
          <CloseIcon id="cerrar" onClick={()=>setOpenPassword(false)}></CloseIcon>
          <div id="tituloContra">{i18n.t("Modifica tu contraseña")}</div>
          <form id="Form">
            <p className="Titulos">{i18n.t("Contraseña Actual")}</p>
            <div>
              <input
                type="password"
                ref={refPasswordActual}
                name="actual"
                onClick={clickPassword}
                className="inputContra"
                onChange={obtenerContra}
              ></input>
            </div>
            <div ref={refErrorActual} id="errorMessagePassword">
              {i18n.t("La contraseña introducida no es correcta")}
            </div>
            <p className="Titulos">{i18n.t("Nueva Contraseña")}</p>
            <div>
              <input
                type="password"
                ref={refPasswordNueva}
                name="nueva"
                onClick={clickPassword}
                className="inputContra"
                onChange={obtenerContra}
              ></input>
            </div>
            <div ref={refErrorNueva} id="errorMessagePassword">
              {i18n.t(
                "La contraseña debe contener una letra mayúscula y al menos 8 caracteres"
              )}
            </div>
            <p className="Titulos">{i18n.t("Repita Contraseña")}</p>
            <div>
              <input
                type="password"
                ref={refPasswordRepita}
                name="repita"
                onClick={clickPassword}
                className="inputContra"
                onChange={obtenerContra}
              ></input>
            </div>
            <div ref={refErrorRepita} id="errorMessagePassword">
              {i18n.t("Las contraseñas no coinciden")}
            </div>

            <div id="contraButton">
              <button
                type="button"
                onClick={ !(valida &&  valida2 && valida3) ?null : changePassword}
                className="AddContact"
                style={{ backgroundColor: !(valida &&  valida2 && valida3) && '#e4e4e4', cursor: !(valida &&  valida2 && valida3) && 'default'  }}
              >
                {i18n.t("Confirmar")}
              </button>
            </div>
          </form>
        </div>
      </Modal>
      <ModalConfirm open={openNewModal} setOpen={setOpenNewModal}></ModalConfirm>
    </>
  )
}

ModalPassword.propTypes = {
  openPassword: PropTypes.bool.isRequired,
  setOpenPassword: PropTypes.func.isRequired,
}

export default withRouter(ModalPassword)
