import {
  WORKER_CREATED,
  GET_WORKERS_BY_PROJECT,
  WORKER_UPDATED
  
} from "../types";
const TeamsReducer = (state, action) => {
  switch (action.type){
    case WORKER_CREATED:
      state.workers.push(action.payload)
      return {
        ...state
      }
    case GET_WORKERS_BY_PROJECT:
      return {
        ...state,
        workers : action.payload
    }
    case WORKER_UPDATED:
      return {
        ...state,
    }
      
    default:
      return state;
  }
}
export default TeamsReducer;