import TemplatesContent from 'components/Templates/templatesContent';
import React, { useEffect } from 'react'
import MetaTags from 'react-meta-tags'
import {
    Container
} from 'reactstrap'
import { RecordPageView } from 'App'
import * as amplitude from '@amplitude/analytics-browser';




const Template = () => {
    RecordPageView()

    useEffect(() => {
        amplitude.track('Templates Visited');
    }, [])
    return (
        <React.Fragment>
            <div className='page-content'>
                <MetaTags>
                    <title>Planhopper | Plantillas </title>
                </MetaTags>
                <Container fluid>
                 <TemplatesContent/>
                </Container>
            </div>
        </React.Fragment>

    )
}

export default Template