import { useState } from 'react';
import { Auth, API, graphqlOperation} from 'aws-amplify';
import { nanoid } from 'nanoid';
import * as mutations from 'graphql/mutations';
import * as subscriptions from 'customGraphql/subscriptions';
import axios from 'axios';
export const useOpenAI = () => {
    const [historial, setHistorial] = useState([]);
    const [currentPart, setCurrentPart] = useState(null);
    const [loading, setLoading] = useState(false);
    const [generating, setGenerating] = useState(false);
    const INTEGRATION_SERVICE = 'service-invoice-local'
    const [quantity, setQuantity] = useState({
        'value': '1',
        'formatted' : '1',
        'float' : 1,
    })
    
    const headers = async () => {
        return {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
            'Access-Control-Allow-Origin': '*',
        }
    }
    const handleSubmit = async ({
        text,
        model
    }) => {
        try {
            setGenerating(true);
            const gp = {
                id: nanoid(),
                name: text,
                status: 'PENDING',
            }
            const newGp = await API.graphql({
                query: mutations.createPartGenerated,
                variables: { input: gp },
            })
            setCurrentPart({
                ...newGp.data.createPartGenerated,
            });
            
            const sub = API.graphql(graphqlOperation(subscriptions.onUpdatePartGeneratedCustom, { id: gp.id })).subscribe({
                next: ({provider, value}) => {
                    const saved  = value
                    if( saved.data.onUpdatePartGenerated && saved.data.onUpdatePartGenerated.status === 'COMPLETED' && saved.data.onUpdatePartGenerated.id === gp.id ){
                        setCurrentPart({
                            ...saved.data.onUpdatePartGenerated,
                        });
                        setInterval(() => {
                            sub.unsubscribe();
                        }, 1000);
                    }else{
                            if(saved.data?.onUpdatePartGenerated && saved.data.onUpdatePartGenerated.id === gp.id){
                            setCurrentPart({
                                ...saved.data.onUpdatePartGenerated,
                            })
                        }
                    }
                },
                error: (error) => {
                    console.log(error);
                }
            });
            const config = {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    'Access-Control-Allow-Credentials': 'true'
                  },
                url : 'https://qxpq37f55t4qmlwt2riu6caysy0obhoe.lambda-url.eu-west-1.on.aws',
                method: 'get',
                params: {
                    prompt: text,
                    model: model,
                    id : gp.id,
                }
            }
            const response = await axios.request(config);
            setGenerating(false);
          
        }
        catch (error) {
            console.log(error);
        }
    }
    return {
        historial,
        handleSubmit,
        currentPart, 
        loading,
        generating,
        quantity,
        setQuantity,
        setCurrentPart
    }
}
