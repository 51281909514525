import React, { useEffect, useState, useContext } from 'react'
import PropTypes from "prop-types";
import { ChapterContainerInside, BlueButton } from 'common/containers';
import { ChapterTypo, PoppinsWhite } from 'common/typographies'
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import GroupStatuses from './GroupStatuses';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import i18n from 'i18n';
import ContactContext from 'context/contact/contactContext';
import ModalPrevisualizarPDF from '../modals/ModalPrevisualizarPDF';
import AuthContext from 'context/auth/authContext';
import SendModal from 'components/Feed/sendBudgetRefatctor/sendModal';


const ProposalChapter = ({group, margin, proposals}) => {
    const [arrowShow, setArrowShow] = useState(false)
    const handleShow = e => {
        e.preventDefault()
        if (arrowShow) {
            setArrowShow(false)
        } else {
            setArrowShow(true)
        }  
    }
    const [openSendBudget, setOpenSendBudget] = useState(false)
    const contactContext = useContext(ContactContext)
    const { Contacts, getContacts} = contactContext
    const [openPDF, setOpenPDF] = useState(false)
    const {user, getUser, freeTrialExpired} = useContext(AuthContext)
    useEffect(() => {
        if(!user){
            getUser()
        }
    }, [])
    return(
        <>
          <ChapterContainerInside style={{backgroundColor:'#ED6335'}}>
          <tr  style={{marginTop: '0px'}}>
              <td style={{minWidth:'37px', paddingLeft: margin+'px'}}>
                {arrowShow ? 
                     <KeyboardArrowDownIcon
                     onClick={handleShow}
                     style={{ cursor: "pointer", marginLeft: "3px", color:'white' }}
                     className="dropdownArrowB"
                     id=""
             />
                : 
              <KeyboardArrowRightIcon
                    onClick={handleShow}
                    style={{ cursor: "pointer", marginLeft: "3px", color:'white' }}
                    className="dropdownArrowB"
                    id=""
            />
                }
            </td>
              <td style={{minWidth:'300px'}}>
                  <ChapterTypo style={{marginLeft:'0px', color:'white'}}>{group.groupName}</ChapterTypo>
              </td>
       
        
                     
          </tr> 
          { user ? 
             user.freeTrialExpired || user['custom:role'] === 'WORKER' ?
             null
             :
          <div className='inline' style={{marginLeft:'55px',marginTop:'-42px', position:'relative', justifyContent:'right', right:'1.2%'}}>
                        <BlueButton onClick={()=>{setOpenSendBudget(true)}} className='inline' style={{marginRight:'7px', width:'130px'}}>
                            <UploadFileOutlinedIcon style={{color:'white', width:'16px', marginTop:'-2px', marginLeft:'7px', marginRight:'5px'}} />
                            <PoppinsWhite>{i18n.t("Enviar Grupo")}</PoppinsWhite>
                        </BlueButton>
                        <BlueButton className='inline' style={{width:'160px'}} onClick={(e)=>{e.preventDefault(); setOpenPDF(true)}}>
                            <NotificationsActiveOutlinedIcon style={{color:'white', width:'16px', marginTop:'-2px', marginLeft:'7px', marginRight:'7px'}}/>
                            <PoppinsWhite>{i18n.t("Previsualizar PDF")} </PoppinsWhite>
                        </BlueButton>
            </div> : null }
        </ChapterContainerInside>
        {arrowShow ?
        proposals.map((proposal, index)=>(
            <GroupStatuses key={index} proposal={proposal.proposalInfo} />
        ))
       
        : null }
           <SendModal
                open={openSendBudget}
                setOpen={setOpenSendBudget}
                contactos={Contacts}
                groupId={group.groupId}
                proposalTree={group.proposals !=undefined ? group.proposals[0].proposalTree : []}
            /> 
          <ModalPrevisualizarPDF open={openPDF} setOpen={setOpenPDF} group={group} />
    </>
    )
}

ProposalChapter.propTypes = {
    group: PropTypes.object,
    proposals: PropTypes.object,
    margin: PropTypes.string
}

export default ProposalChapter