import React, { useState } from "react"
import PropTypes from "prop-types"
import { Box, CircularProgress, Modal, Typography } from "@mui/material"
import i18n from "i18n"
import CloseIcon from "@mui/icons-material/Close"
import { makeStyles } from "@material-ui/core/styles"
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView"
import { TreeItem } from "@mui/x-tree-view/TreeItem"

const getModalStyle = () => {
  const top = 50
  const left = 50
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "absolute",
    width: "522px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 6,
    maxHeight: "calc(100vh - 100px)",
    overflowY: "auto",
    overflowX: "hidden",
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "50px",
  },
  subTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "40px",
  },
  input: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "40px",
  },
  list: {
    marginTop: "40px",
  },
  closeButton: {
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(2),
    cursor: "pointer",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(2),
  },
}))

const TreeViewModal = ({ open, setOpen, chapters, callback }) => {
  const [modalStyle] = useState(getModalStyle)
  const [btnLoading, setBtnLoading] = useState(false)
  const [selectedChapter, setSelectedChapter] = useState(null)
  const classes = useStyles()

  const renderTree = chapter => (
    <TreeItem
      key={chapter.chapterId}
      itemId={JSON.stringify(chapter)}
      label={chapter.name}
    >
      {Array.isArray(chapter.subcapitulos)
        ? chapter.subcapitulos.map(subChapter => renderTree(subChapter))
        : null}
    </TreeItem>
  )

  const handleItemSelectionToggle = (_, item, isSelected) => {
    const chapter = JSON.parse(item)
    if (isSelected) {
      setSelectedChapter(chapter)
    } else {
      setSelectedChapter(null)
    }
  }

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false)
      }}
    >
      <div style={modalStyle} className={classes.paper}>
        <div className={classes.closeButton}>
          <CloseIcon
            onClick={() => {
              setOpen(false)
            }}
          />
        </div>
        <Typography variant="h5" gutterBottom className={classes.subTitle}>
          Elija el capitulo donde desea mover la partida:
        </Typography>

        <div className={classes.buttonContainer}>
          <SimpleTreeView onItemSelectionToggle={handleItemSelectionToggle}>
            {chapters.map(chapter => renderTree(chapter))}
          </SimpleTreeView>
        </div>
        <div className={classes.buttonContainer}>
          {btnLoading ? (
            <CircularProgress size={24} />
          ) : (
            <button
              type="submit"
              onSubmit={() => {
                setBtnLoading(true)
                callback(selectedChapter)
              }}
              onClick={() => {
                setBtnLoading(true)
                callback(selectedChapter)
                setBtnLoading(false)
              }}
              disabled={
                !selectedChapter ||
                (selectedChapter.subcapitulos &&
                  selectedChapter.subcapitulos.length > 0)
              }
              className={`AcceptButton ${
                !selectedChapter ||
                (selectedChapter.subcapitulos &&
                  selectedChapter.subcapitulos.length > 0)
                  ? "disabled"
                  : ""
              }`}
              data-amplify-analytics-on="click"
              data-amplify-analytics-name="ProjectCreated"
            >
              Aceptar
            </button>
          )}
        </div>
      </div>
    </Modal>
  )
}

const ChapterPropTypes = PropTypes.shape({
  chapterId: PropTypes.string,
  name: PropTypes.string,
  subcapitulos: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        chapterId: PropTypes.string,
        name: PropTypes.string,
        subcapitulos: PropTypes.any,
      })
    ),
    PropTypes.array,
    PropTypes.null,
  ]),
})

TreeViewModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  chapters: PropTypes.arrayOf(ChapterPropTypes).isRequired,
  callback: PropTypes.func.isRequired,
}

export default TreeViewModal
