import React, { useEffect, useRef } from "react"
import { ChapterContainer } from "common/containers"
import { ChapterTypo, PoppinsNormal } from "common/typographies"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import { useContext } from "react"
import ProposalContext from "context/proposal/proposalContext"
import ProposalChapter from "./chapters/proposalChapter"
import PropTypes from "prop-types"
import { ShowParentDiv } from "common/containers"
import i18n from "i18n"

const SendBudget = ({ projectInfo }) => {
  const { getProposalInfo, proposalTree, proposalTreeWithInfo, getGroupInfo, proposalsGroup, loading} = useContext(ProposalContext)
  const showSubItems = useRef(null)
  
  useEffect(() => {
    getGroupInfo(projectInfo.projectId)
  }, [])
  const handleShow = e => {
    e.preventDefault()
    
    if (showSubItems.current.style.display == "block") {
      showSubItems.current.style.display = "none"
      e.target.id = ""
    } else {
      showSubItems.current.style.display = "block"
      e.target.id = "rotateimg90"
    }
    
  }
  return (
    <>
      <ChapterContainer
        style={{
          backgroundColor: "#ED6335",
          color: "white",
          borderLeft: "7px solid #C8522A",
        }}
      >
        <tr style={{ marginTop: "0px" }}>
          <td style={{ minWidth: "37px", paddingLeft: "3px" }}>
            <KeyboardArrowRightIcon
              onClick={handleShow}
              style={{ cursor: "pointer", marginLeft: "3px" }}
              className="dropdownArrowB"
              id=""
            />
          </td>
          <td style={{ minWidth: "300px" }}>
            <ChapterTypo style={{ marginLeft: "0px", color: "white" }}>
              {i18n.t("PRESUPUESTOS ENVIADOS")}
            </ChapterTypo>
          </td>
        </tr>
      </ChapterContainer>
      
        <div ref={showSubItems} style={{display:'none'}}>
            {!loading ? 
            
            proposalsGroup.length > 0 ? 
            <ShowParentDiv style={{backgroundColor:'#FFF1E7',  borderLeft: "7px solid #C8522A",}}>
             { proposalsGroup.map((group, index)=>(
                      <ProposalChapter key={index} group={group} proposals={group.proposals} />
              ))}
              </ShowParentDiv>
            : 
            <div
                    style={{backgroundColor:'#FFF1E7', paddingLeft:'35px', height:'33px', width:'100%', borderRadius:'6px', marginTop:'-16px', paddingTop:'6px', fontFamily:'Roboto', fontWeight:'300', fontSize:'12px'}}
            > {i18n.t('Todavía no hay presupuestos enviados')} </div>
            :
            <>
            <div style={{justifyContent:'center', width:'100%', textAlign:'center', position:'relative', display:'flex', marginLeft:'-100px', marginTop:'5px'}}>
            <div className='loader'>
                <div className='ball'></div>
                <div className='ball'></div>
                <div className='ball'></div>
           </div>
            </div>
            <div style={{justifyContent:'center', width:'100%', textAlign:'center', position:'relative', display:'flex', marginLeft:'-30px'}}>
            <PoppinsNormal>{i18n.t("Cargando...")}</PoppinsNormal>
            </div>
            <div style={{justifyContent:'center', width:'100%', textAlign:'center', position:'relative', display:'flex', marginLeft:'-30px', marginTop:'-15px'}}>
            <PoppinsNormal style={{fontWeight:'400'}}>{i18n.t("Esta acción puede tardar unos segundos")}</PoppinsNormal>
            </div>
            </>
            }
        </div>
  
    </>
  )
}

SendBudget.propTypes = {
  projectInfo: PropTypes.object,
}

export default SendBudget
