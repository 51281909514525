import React, {useState, useEffect} from 'react'
import { HelveticaBold } from 'common/typographies'
import i18n from "i18n";
import PropTypes from 'prop-types'
const SubscriptionTotal = ({ precio, discount }) => {
    const [total, setTotal] = useState(precio *1.21)
    const [discountLocal, setDiscountLocal] = useState({
        type: '',
        amount: 0,
    })
    useEffect(() => {
        setTotal(precio *1.21)
    }, [precio])
    useEffect(()=>{ 
        if(discount.loading === false && discount.error === false &&discount.id){
            if(discount.discount_type === 'percentage'){
                setDiscountLocal({
                    type: discount.discount_type,
                    amount: discount.discount_percentage
                })
            }
            else if(discount.discount_type === 'fixed_amount'){
                setDiscountLocal({
                    type: discount.discount_type,
                    amount: discount.discount_amount
                })
            }
        }
    }, [discount.loading])
    const computePrice = (precio, discountLocal) => {
        if(discountLocal.type === 'percentage'){
            return (precio - (precio * discountLocal.amount / 100)) * 1.21
        }
        else if(discountLocal.type === 'fixed_amount'){
            let finalPrice = (precio  - discountLocal.amount) * 1.21
            finalPrice < 0 ? finalPrice = 0 : finalPrice = finalPrice
            return finalPrice
        }
    }
    return(
        <> 
            {discount.error === true?
                <div style={{width:'95%', paddingTop:'5px'}} className="inline">
                <HelveticaBold style={{color:'red', fontSize:'14px', marginTop:'5px', cursor:'default'}}>{i18n.t("Codigo descuento no valido")}</HelveticaBold>
                </div> : null
            }

            {discount.id?
            <>
            <div style={{width:'95%'}} className="inline">
                <div style={{width:'80%', marginRight:'20px'}}> 
                    <HelveticaBold style={{color:'#707070', fontSize:'14', marginTop:'5px', cursor:'default' }}>{`${discount.id} aplicado`}</HelveticaBold>
                </div>
                <div style={{width:'20%'}}>
                    <HelveticaBold style={{color:'#707070', fontSize:'14', cursor:'default'}}>{`${discountLocal.amount} ${discountLocal.type === "percentage"? "%": "€"}`}</HelveticaBold>
                </div>
            </div>
            <div style={{width:'95%', paddingTop:'5px'}} className="inline">
            <div style={{width:'80%', marginRight:'20px'}}> 
                <HelveticaBold style={{color:'#707070', fontSize:'14px', marginTop:'5px', cursor:'default'}}>{i18n.t("Total")}</HelveticaBold>
            </div>
            <div style={{width:'20%'}}>
                <HelveticaBold style={{color:'#707070', fontSize:'20px', cursor:'default'}}>{parseFloat(computePrice(precio, discountLocal)).toFixed(2)+'€'}</HelveticaBold>
            </div>
            </div>
            </>
            :             <div style={{width:'95%', paddingTop:'5px'}} className="inline">
            <div style={{width:'80%', marginRight:'20px'}}> 
                <HelveticaBold style={{color:'#707070', fontSize:'14px', marginTop:'5px', cursor:'default'}}>{i18n.t("Total")}</HelveticaBold>
            </div>
            <div style={{width:'20%'}}>
                <HelveticaBold style={{color:'#707070', fontSize:'20px', cursor:'default', textDecoration: `${discount.id? "line-through": "none"}`}}>{parseFloat(total).toFixed(2)+'€'}</HelveticaBold>
            </div>
            </div>}
        </>
    )
}
SubscriptionTotal.propTypes = {
    precio: PropTypes.number,
    discount: PropTypes.any
}
export default SubscriptionTotal