import React, { useContext, useState } from "react"
import i18n from "i18n"
import BankPricesContext from "context/bankPrices/bankPricesContext"
import UserDatabaseOptions from "./dropdowns/UserDatabaseOptions"
import ModalImportAll from "./modals/modalImportAll"


const LayoutMiBancoDePrecios = ({
  
}) => {
  const {myPricesBank} = useContext(BankPricesContext);
  const [openImport, setOpenImport] = useState(false);
  return (
    <>
      <div style={{ maxHeight: "750px", height: "750px", display: "contents" }}>
        <div
          style={{
            marginLeft: "24px",
            marginRight: "10px",
            marginBottom: "30px",
            marginTop: "0px",
          }}
        >
          
          <div id="SubMenuProjects">
            <div id="botonesPrincBudget" style={{marginRight:'0px'}}>
            
      {        myPricesBank.subcapitulos.length>0 &&
                  <button
                  onClick={() => {setOpenImport(true)}}
                    id="costPressFin"
                    style={{
                      backgroundColor: "#FFD52B",
                      border: "0px",
                      marginRight: "-5px",
                      zIndex: "1",
                    }}
                  >
                    {i18n.t("Importar")}
                  </button>
              }
                  {/*
               
                  <button
                    id="costPress"
                    style={{
                      backgroundColor: "#EAEAEA",
                      border: "0px",
                      marginRight: "-5px",
                    }}
                  >
                    {i18n.t("Maquinaria")}
                  </button>
                  <button
                        id="costPress"
                        style={{
                        backgroundColor: "#EAEAEA",
                        border: "0px",
                        marginRight: "-5px",
                        }}
                  >
                    {i18n.t("Materiales")}
                  </button>
                  <button
                        id="costPress"
                        style={{
                        backgroundColor: "#EAEAEA",
                        border: "0px",
                        marginRight: "-5px",
                        }}
                  >
                    {i18n.t("Mano de obra")}
                  </button>
         */
                }
                {
              //<DropdownOptions/>
              myPricesBank.subcapitulos.length>0 &&
            <div style = {{marginTop:'0px', marginLeft:'10px', }}>
                <UserDatabaseOptions/>
            </div>
    }
            </div>
          </div>
        </div>
      </div>
    {
      openImport &&
      <ModalImportAll open={openImport} setOpen={setOpenImport} />
    }
    </>
  )
}

export default LayoutMiBancoDePrecios
