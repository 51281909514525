import React, {useContext, useState} from "react";
import {Modal} from "@mui/material";
import {withRouter} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import Budget2023Context from "context/budget2023/Budget2023Context";
import deleteCap from 'assets/images/budgeting/deleteCap.png'
import i18n from "i18n";

function getModalStyle() {
    const top = 50 ;
    const left = 50;
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: '630px',
        height: '470px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        borderRadius: 6,
        maxHeight: 'calc(100vh - 100px)',
        overflowY: 'auto',
        overflowX:'hidden',
    },
}));

const ModalCapitulos = ({ open, setOpen, projectId}) => {
    // Configuración del modal para añadir contactos
    const [ modalStyle ] = useState(getModalStyle);
    const classes = useStyles();
    const {groupChapters} = useContext(Budget2023Context);
    const onClose = ()=> {
        setOpen(false)
    }

    return(
        <>
            <Modal open={open} onClose={onClose}>
                <div style={modalStyle} className={classes.paper}>
                    <div id='centerPaco'>
                        <img src={deleteCap} style={{width:'95px', height:'144px'}}/>
                    </div>
                    <div id="DivTitleEliminar2" style={{justifyContent:'center'}}><p id="centerTitleEliminar" style={{marginTop:'40px', fontSize:'16px', fontWeight:'700'}}>{i18n.t("Atención")}</p></div>
                    <div id="DivTitleEliminar2"  style={{width:'100%', textAlign:'center', display:'block', marginLeft:'0PX'}}>
                    <p id='subtitledelete'>{i18n.t("Estás a punto de agrupar los capítulos seleccionados, las partidas de estos capítulos también serán agrupadas")}</p>
                    </div>
                    <div id="">
                        <div id='DivTitleEliminar2' style={{width:'100%', textAlign:'center', display:'block', marginLeft:'0PX', marginTop:'50PX'}}>
                        <button type="button" style={{width: '190px', marginTop:'2px', borderRadius:'6px'}}
                               className="CancelDel" onClick={onClose}>{i18n.t("Cancelar")}</button>
                        <button type="button" style={{width: '190px', marginLeft:'20px', borderRadius:'6px', marginTop:' 0px', boxShadow:'none'}}
                                className="AddContact" onClick={()=>{groupChapters(projectId); setOpen(false)}}>{i18n.t("Agrupar")}</button></div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

ModalCapitulos.propTypes = {
    open : PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    infoCap: PropTypes.object.isRequired,
    soyFinal: PropTypes.bool,
    modificado:  PropTypes.number,
    setClose: PropTypes.func,
    projectId: PropTypes.string,
}

export default withRouter(ModalCapitulos);