import React, { useContext, useEffect } from "react"
import { useParams } from "react-router-dom"
import ExtrasAndContradictionsContext from "context/extrasAndContradictions/ExtrasAndContradictionsContext"
import LayoutExtrasAndContradictions from "./LayoutExtrasAndContradictions"
import CollapsableChapter from "./CollapsableChapter"
import Loader from "common/Loader"
import ModalDeletePart from "components/Budgeting2023/TablaPartida/ModalDeletePart"
import ModalDeletePartEnum from "common/ModalDeletePartEnum"
import NewModalPartida from "pages/ModalPartidas/NewModalPartida"
import DownloadBudgetEnum from "common/DownloadBudgetEnum"
import DownloadBudget from "components/Feed/DownloadBudget"
import ProjectContext from "context/projects/projectContext"
import AuthContext from "context/auth/authContext"

const ExtrasAndContradictionsStructure = () => {
  const { projectId } = useParams()
  const { user } = useContext(AuthContext)

  const { projectInfo } = useContext(ProjectContext)
  const {
    tree,
    loading,
    showDeletePartModal,
    deletingFromPart,
    dispatchs,
    newModalPart,
  } = useContext(ExtrasAndContradictionsContext)

  const { setTree, setShowDeletePartModal, setOpenNewModalPart } = dispatchs

  useEffect(() => {
    setTree(projectId)
  }, [projectId])

  if (loading) {
    return <Loader />
  }

  const setOpenForNewPartModal = (isOpen, refresh = true) => {
    setOpenNewModalPart(isOpen)
    if (refresh) {
      setTree(projectId)
    }
  }

  return (
    <>
      <LayoutExtrasAndContradictions>
        <div>
          {tree?.chapters?.length > 0 ? (
            tree.chapters.map((item, index) => (
              <CollapsableChapter
                key={item.chapterId}
                chapter={item}
                chapterIndex={index}
                showAddPartBtn={item.chapterId === tree.extraChapter}
                setOpenNewModalPart={setOpenNewModalPart}
              />
            ))
          ) : (
            <h1>No hay nada</h1>
          )}
        </div>
      </LayoutExtrasAndContradictions>
      <div aria-describedby="Modals">
        <ModalDeletePart
          openEliminate={showDeletePartModal}
          setOpenEliminate={setShowDeletePartModal}
          partida={deletingFromPart}
          from={ModalDeletePartEnum.FROM_EXTRAS_AND_CONTRADICTIONS}
        />

        {user && (
          <NewModalPartida
            open={newModalPart.openModal}
            setOpen={setOpenForNewPartModal}
            chapterId={tree?.extraChapter}
            partidaInfo={newModalPart.partToEdit}
            srcPage={"extras"}
          />
        )}

        <DownloadBudget
          open={false}
          setOpen={() => {}}
          name={projectInfo?.name ?? ""}
          soyFinal={false}
          fromTab={DownloadBudgetEnum.FROM_EXTRAS_AND_CONTRADICTIONS}
        />
      </div>
    </>
  )
}

export default ExtrasAndContradictionsStructure
