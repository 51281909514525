import {Modal} from "@mui/material";
import {makeStyles} from "@material-ui/core/styles";
import React, { useState, useContext, useEffect } from "react";
import ProjectContext from 'context/projects/projectContext';
import PropTypes from 'prop-types'
import i18n from "i18n";
import { useProgressExcel } from "hooks/useProgressForExcel";
import TemplatesContext from "context/templates/templatesContext";
import BankPricesContext from "context/bankPrices/bankPricesContext";
import ModalErrorExcel from "./ModalErrorExcel";
import { FileUpload } from "@mui/icons-material";
import AuthContext from "context/auth/authContext";
import * as amplitude from '@amplitude/analytics-browser';


function getModalStyle() {
    const top = 50 ;
    const left = 50;
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };

}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: '500px',
        height: '425px',
        backgroundColor: 'white',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        borderRadius: 6,
        maxHeight: '500px',
        overflowY: 'auto',
        overflowX:'hidden',
        paddingLeft:'0px',
        paddingRight:'0px'
    },
}));

const ModalWaitingExcel = ({openModal, setOpenModal, setSuccessModal, template, project, imComingFrom, user}) =>{
  
  const [ modalStyle ] = useState(getModalStyle);
  const classes = useStyles();
  const [openErrorModal, setOpenErrorModal] = useState(false)
  //VARIABLE QUE VIENE DEL CONTEXT AL SUBIR EL ARCHIVO SE USA DIFERENTE CONTEXTO SEGUN EL PROP QUE INDICA DE DONDE VIENE ESTE MODAL
  const {fileUploaded} = useContext(imComingFrom=='budgeting' ? ProjectContext : imComingFrom=='templates' ? TemplatesContext : BankPricesContext)
  //PARA PRUEBAS : orgId: '1a04796f-2cbf-4298-a4f5-f3d61deeffd7', fileId: 'b20dbbd6-9bfa-4646-99b1-905b17f1cb5d'
  const importedExcel = useProgressExcel({orgId: project ? project.createdBy : template? template.createdBy : user?.sub , fileId: fileUploaded})
  useEffect(()=>{
    if(importedExcel){
        if(importedExcel.status === 'COMPLETED'){
            setTimeout(() => {
                amplitude.track('Excel Imported');
                setSuccessModal(true)
            }, 1000);
        }
        if(importedExcel.error){
            setTimeout(() => {
                setOpenErrorModal(true)
            }, 1000);
        }
    } 
  },[importedExcel])

return(
    <>
  <Modal
      open={openModal}
      onClose={() => {null}}
  >
      <div style={modalStyle} className={classes.paper}>
      <div className="modal-body">
      <div>
          <div>
       
              <p
                  style={{
                  fontSize: '20px',
                  fontWeight: '500',
                  textAlign: 'center',
                  fontFamily: "Helvetica",
                  marginTop:'30px'
                  }}
              
                  >{i18n.t("Estamos construyendo tu")}
                  <br/>  {template ? i18n.t("plantilla...") : imComingFrom=='budgeting' ? i18n.t("presupuesto y planning...") : "banco de precios"}
                </p>
                {importedExcel.validation==false ?
                  <p
                  style={{
                      fontStyle: 'normal',
                      fontWeight: '300',
                      fontSize: '14px',
                      lineHeight: '16px',
                      textAlign: 'center',
                      fontFamily: "Helvetica"
                  }}
              >Esto puede tomar un minuto. Por favor no te
                <br/>  vayas, ni cierres esta pestaña
                <br/>
                 Validando el formato del archivo...
              </p>
                :
                <p
                  style={{
                      fontStyle: 'normal',
                      fontWeight: '300',
                      fontSize: '14px',
                      lineHeight: '16px',
                      textAlign: 'center',
                      fontFamily: "Helvetica"
                  }}
              >Esto puede tomar un minuto. Por favor no te
                <br/>  vayas, ni cierres esta pestaña
                <br/>
                 Creando {importedExcel.chapters ? importedExcel.chapters : 0} capítulos con 
                un total de {importedExcel.parts ? importedExcel.parts : 0} partidas
              </p>}
              <div className='loader'>
                    <div className='ball'></div>
                    <div className='ball'></div>
                    <div className='ball'></div>
               </div>
             
          </div>
          </div>
      </div>
      </div>
  </Modal>
  {
    openErrorModal && <ModalErrorExcel open={openErrorModal} setOpen={setOpenErrorModal} errorMessage={importedExcel.errorMessage} setOpenWaiting={setOpenModal} />
  }
  </>
)
}
ModalWaitingExcel.propTypes = {
  openModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  setSuccessModal: PropTypes.func.isRequired,
  project: PropTypes.object,
  template: PropTypes.object,
  imComingFrom: PropTypes.string,
  user: PropTypes.string
}
export default ModalWaitingExcel