import PropTypes from "prop-types"

class CDecomposition {
  constructor({ description, price, quantity, type, unity }) {
    this.description = description
    this.price = price
    this.quantity = quantity
    this.type = type
    this.unity = unity
  }
}
export default CDecomposition

const DecompositionPropTypes = PropTypes.shape({
  description: PropTypes.string,
  price: PropTypes.number,
  quantity: PropTypes.number,
  type: PropTypes.any,
  unity: PropTypes.string,
})

export { DecompositionPropTypes }
