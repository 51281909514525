import { isNil } from "lodash"
import Certification from "./Certification"

class CertificationRoot {
  constructor(data) {
    this.certs = isNil(data.certs)
      ? []
      : data.certs.map(cert => new Certification(cert))
  }

  calculateAllTotalCertPrice = () => {
    this.certs.forEach(cert => {
      cert.calculateTotalCertPrice()
    })
  }
}

export default CertificationRoot
