import React from "react"
import ExtrasAndContradictionsStructure from "components/ExtrasAndContradictions/ExtrasAndContradictionsStructure"
import { MetaTags } from "react-meta-tags"
import { Container } from "reactstrap"
import ExtrasAndContradictionsState from "context/extrasAndContradictions/ExtrasAndContradictionsState"

const ExtrasAndContradictionsIndex = () => {
  return (
    <div className="page-content">
      <MetaTags>
        <title>Planhopper | Proyecto </title>
      </MetaTags>
      <Container fluid>
        <ExtrasAndContradictionsState>
          <ExtrasAndContradictionsStructure />
        </ExtrasAndContradictionsState>
      </Container>
    </div>
  )
}

export default ExtrasAndContradictionsIndex
