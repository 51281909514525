import React, { useState, useEffect } from "react"
import PropTypes from 'prop-types'
import i18n from "i18n"
import {Taxes } from "components/Invoice/constants"
import {CircularProgress, Modal} from "@mui/material";
import {makeStyles} from "@material-ui/core/styles";
import { CloseOutlined } from "@mui/icons-material";
import { HelveticaLight, PoppinsNormal } from "common/typographies";
import { YellowButton } from "common/buttons";

function getModalStyle() {
    const top = 50 ;
    const left = 50;
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: '650px',
        height: '300px',
        backgroundColor: 'white',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        borderRadius: 6,
        overflowY: 'auto',
        overflowX:'hidden',
        paddingLeft:'0px',
        paddingRight:'0px'
    },
}));

const ModalSelectTaxInModal = ({open, setOpen, impuestos, setImpuestos}) => {
    const [ modalStyle ] = useState(getModalStyle);
    const classes = useStyles();
    const ivaTaxes = Taxes.iva.types
    const recargo = Taxes.recargo.types
    const retention = Taxes.retention.types

    const handleChangeImpuestos = (type, tax) => {
        switch (type) {
            case 'iva':
                if(impuestos.iva?.id == tax.id){
                    setImpuestos({...impuestos, iva: {}})
                }else{
                    setImpuestos({...impuestos, iva: tax})
                }
                break;
            case 'recargo':
                if(impuestos.recargo==tax){
                    setImpuestos({...impuestos, recargo: {}})
                }else{
                    setImpuestos({...impuestos, recargo:  tax})
                }
                break;
            case 'retention':
                if(impuestos.retention==tax){
                    setImpuestos({...impuestos, retention: {}})
                }else{
                    setImpuestos({...impuestos, retention:  tax})
                }
                break;
            default:
                break;
            }
    }

    return (
        <Modal
        open={open}
        onClose={() => {null}}
        >
        <div style={modalStyle} className={classes.paper}>
            <CloseOutlined onClick={()=>{setOpen(false)}} style={{position:'absolute', right:'10px', top:'5px', width:'16px', cursor:'pointer'}}/>
            <div id='centerPaco' style={{marginLeft:'50px', marginRight:'50px', textAlign:'center', marginTop:'20px', display:'grid'}}>
                <HelveticaLight style={{fontSize:'14px', fontWeight:'700', marginTop:'-15px', marginBottom:'0px'}} >{i18n.t('Selecciona los impuestos y retenciones del presupuesto.')}</HelveticaLight>
            </div>

            <div className="inline" style={{width:'auto', justifyContent:'center', position:'relative', marginTop:'55px', marginLeft:'50px', marginRight:'50px'}}>
              <div style={{width:'31%',   alignContent:'baseline', display:'flex', flexWrap:'wrap', height:'135px'}}>
              <HelveticaLight style={{fontSize:'14px', textAlign:'left', marginLeft:'7px', width:'100%', marginTop:'10px',marginBottom:'7px', fontWeight:'700', marginBottom:'0px'}} >{i18n.t('IVA')}</HelveticaLight>
                    {
                        ivaTaxes.map((tax, index) => {
                            return(
                                <div onClick={()=>{handleChangeImpuestos('iva', tax)}} key={index} className='hoverYellow' style={{background: impuestos.iva?.id == tax.id && '#FFD52B', height:'25px',cursor:'pointer', paddingLeft:'6px', paddingRight:'6px', marginRight:tax.name=='IVA 4%' ? '15PX' :'7px',  marginLeft:  '7px', marginTop:'10PX', width: 'fit-content', borderRadius:'6px', border: '1px solid #FFD52B'}}>
                                    <HelveticaLight style={{fontSize:'14px', fontWeight:'700', marginBottom:'0px'}} >{tax.name}</HelveticaLight>
                                </div>
                            )
                        }
                        )
                    }
              </div>
              <div style={{width:'38%', cursor:'pointer',  alignContent:'baseline', display:'flex', flexWrap:'wrap', height:'135px'}}>
              <HelveticaLight style={{fontSize:'14px', textAlign:'left', marginLeft:'7px', width:'100%', marginTop:'10px',marginBottom:'7px', fontWeight:'700', marginBottom:'0px'}} >{i18n.t('RECARGO')}</HelveticaLight>
                    {
                        recargo.map((tax, index) => {
                            return(
                                <div onClick={()=>{handleChangeImpuestos('recargo', tax)}}   className='hoverYellow'  key={index} style={{height:'25px', background: impuestos.recargo?.id == tax.id && '#FFD52B', paddingLeft:'6px', paddingRight:'6px', marginRight:'7px',  marginLeft: '7px', marginTop:'10PX', width: 'fit-content', borderRadius:'6px', border: '1px solid #FFD52B'}}>
                                    <HelveticaLight style={{fontSize:'14px', fontWeight:'700', marginBottom:'0px'}} >{tax.name}</HelveticaLight>
                                </div>
                            )
                        }
                        )
                    }
              </div>
              <div style={{width:'24%', cursor:'pointer',  alignContent:'baseline', display:'flex', flexWrap:'wrap', height:'135px'}}>
              <HelveticaLight style={{fontSize:'14px', textAlign:'left', marginLeft:'7px', width:'100%', marginTop:'10px',marginBottom:'7px', fontWeight:'700', marginBottom:'0px'}} >{i18n.t('RETENCIONES')}</HelveticaLight>
                    {
                        retention.map((tax, index) => {
                            return(
                                <div onClick={()=>{handleChangeImpuestos('retention', tax)}}  className='hoverYellow'  key={index} style={{height:'25px', background: impuestos.retention?.id == tax.id && '#FFD52B', paddingLeft:'6px', paddingRight:'6px', marginRight:'7px',  marginLeft: '7px', marginTop:'10PX', width: 'fit-content', borderRadius:'6px', border: '1px solid #FFD52B'}}>
                                    <HelveticaLight style={{fontSize:'14px', fontWeight:'700', marginBottom:'0px'}} >{tax.name}</HelveticaLight>
                                </div>
                            )
                        }
                        )
                    }
              </div>
            
            </div>
            <div id='centerPaco' style={{textAlign:'center', marginTop:'0px', display:'grid'}}>
                <YellowButton onClick={()=>{setOpen(false)}}  style={{ marginTop:'10px', marginBottom:'25px'}}>
                        {i18n.t('Guardar')}
                </YellowButton>
            </div>
        </div>
    </Modal>
    )
    }

    ModalSelectTaxInModal.propTypes = {
        open: PropTypes.bool.isRequired,
        setOpen: PropTypes.func.isRequired,
        impuestos: PropTypes.object.isRequired,
        setImpuestos: PropTypes.func.isRequired,
    }

    export default ModalSelectTaxInModal