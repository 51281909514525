import React, { Children, useContext, useState, useEffect } from "react"
import PropTypes from "prop-types"
import { PartStyledTable } from "common/tables"
import i18n from "i18n"

const TableStructure = ({ children, capitulo, width, ml, bankSelected, tipo, disabled, setDisabled, loadingButton, setLoadingButton, doTheAction, setDoTheAction, chapterId, projectInfo, setOpen, parts}) => {

  return (
    <PartStyledTable
      style={{
        marginLeft:  ml+"px" ,
        width:  width+"%",
        marginTop: "1px",
        marginBottom : "0px",
        border: "0px solid #e0e0e0",
      
      }}
    >
     
      <tr style={{ background: "rgba(217, 217, 217, 0.2)" }}>
        <th style={{ minWidth: "81px" , fontFamily:"Helvetica"}}>U</th>
        <th
          style={{
            minWidth: "300px",
            width: "90%",
            textAlign: "left",
            paddingLeft: "33px",
            width: "auto",
            fontFamily:"Helvetica"
          }}
        >
          {i18n.t("Descripción")}
        </th>

        <th
          style={{ minWidth: "150px", textAlign: "left", paddingLeft: "33px", fontFamily:"Helvetica"}}
        >
                {i18n.t("Costo Objetivo")}
        </th>
      
      </tr>
      {children}
      <tr id="lastPartida">
        <td colSpan="3">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "0.5rem",
            }}
          >
           

            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              
            
            </div>
          </div>
        </td>
      </tr>
    </PartStyledTable>
  )
}

TableStructure.propTypes = {
  children: PropTypes.node.isRequired,
  capitulo: PropTypes.object,
  width: PropTypes.number,
  ml: PropTypes.number,
  bankSelected: PropTypes.string,
  tipo: PropTypes.string,
  disabled: PropTypes.bool,
  setDisabled: PropTypes.func,
  loadingButton: PropTypes.bool,
  setLoadingButton: PropTypes.func,
  doTheAction: PropTypes.bool,
  setDoTheAction: PropTypes.func,
  chapterId: PropTypes.string,
  projectInfo: PropTypes.object,
  setOpen: PropTypes.func,
  parts: PropTypes.array
}

export default TableStructure
