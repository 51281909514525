import {API, graphqlOperation} from "aws-amplify"
import {useState, useEffect} from 'react'

import {query, subscribe} from '../customGraphql/process'

export const useProcess = (PK, SK) => {
    console.log(`useProcess(${PK}, ${SK})`)

    const [process, setProcess] = useState(null)
    const [gql, setGql] = useState(null)
    const [error, setError] = useState(null)

    const parseProcess = (value) => {
      return {...value, payload: JSON.parse(value.payload ? value.payload : '{}')}
    }

    const queryProcess = () => {
      if (error) return

      console.log(`queryProcess(${PK}, ${SK})`)
      try {
        const gql = API.graphql(graphqlOperation(query,
            {'PK': PK, 'SK': SK}))
          .then((gql) => {
            console.log(`queryProcess(${PK}, ${SK}): ${JSON.stringify(gql.data.getProcess)}`)
            setProcess(parseProcess(gql.data.getProcess? gql.data.getProcess : gql.data))
          })
      } catch (error) {
        console.log(`Error querying process: ${error}`)
        setError(error)
      }
    }

    const subscribeProcess = () => {
      if (error) return

      console.log(`subscribeProcess(${PK}, ${SK})`)
      try {
        setGql(API.graphql(graphqlOperation(subscribe,
            {'PK': PK, 'SK': SK}))
          .subscribe({next: ({provider, value}) => {
              console.log(`subscribeProcess(${PK}, ${SK}): ${JSON.stringify(value.data.onUpdateProcess)}`)
              setProcess(parseProcess(value.data.onUpdateProcess))
          }}))
      } catch (error) {
        console.log(`Error subscribing to process: ${error}`)
        setError(error)
      }

    }

    useEffect(() => {
        queryProcess()
        subscribeProcess()
    }, [PK, SK])

    return [process, setProcess, gql, setGql, error, setError]
}
