import PropTypes from "prop-types"
import React from "react"
import { withRouter } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"
import SidebarContent from "./SidebarContent"
import { Route, useLocation } from "react-router-dom"
import routes from "./routes.js"
import PerfectScrollbar from "perfect-scrollbar"
import AuthContext from "context/auth/authContext"
import "react-perfect-scrollbar/dist/css/styles.css"
import FeaturebaseWidget from "common/FeaturebaseWidget"

var ps

const Sidebar = props => {
  const location = useLocation()
  const [backgroundColor, setBackgroundColor] = React.useState("white")
  const [activeColor, setActiveColor] = React.useState("warning")

  const mainPanel = React.useRef()
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on"
      document.documentElement.classList.remove("perfect-scrollbar-off")
      if (mainPanel.current != null) {
        ps = new PerfectScrollbar(mainPanel.current)
      }
    }
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        if (ps) {
          ps.destroy()
          document.documentElement.className += " perfect-scrollbar-off"
          document.documentElement.classList.remove("perfect-scrollbar-on")
        }
      }
    }
  })
  React.useEffect(() => {
    document.documentElement.scrollTop = 0
    document.scrollingElement.scrollTop = 0
  }, [location])
  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views)
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        )
      } else {
        return null
      }
    })
  }
  const handleActiveClick = color => {
    setActiveColor(color)
  }
  const handleBgClick = color => {
    setBackgroundColor(color)
  }
  const [imInSurvey, setImInSurvey] = React.useState(false)
  const { user, showSnackbar, form } = React.useContext(AuthContext)

  React.useEffect(() => {
    if (user) {
      if (user["custom:onboarding"] !== "true") {
        if (form == false) {
          setImInSurvey(true)
        }
      }
    }
  }, [user])

  return (
    <div className="sidebar-mini" style={{ display: imInSurvey && "none" }}>
      <FeaturebaseWidget>
        <SidebarContent
          location={location}
          routes={routes}
          bgColor={backgroundColor}
          activeColor={activeColor}
          handleMiniClick={props.handleMiniClick}
          sidebarMini={props.sidebarMini}
        />
      </FeaturebaseWidget>
    </div>
  )
}

Sidebar.propTypes = {
  navContent: PropTypes.any,
  handleMiniClick: PropTypes.any,
  sidebarMini: PropTypes.bool,
}
export default withRouter(withTranslation()(Sidebar))
