import React, {useState, useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import { PoppinsGrey } from 'common/typographies';
import { formatValue } from 'react-currency-input-field';
import styled from 'styled-components';
import { getCurrency } from "utils/functions"
import AuthContext from 'context/auth/authContext';

const CustomRow = styled.tr`
  transition: transform 0.2s;
  :hover {
    cursor: pointer;
    transform: scale(1.01);
    border-radius: 10px;
    border: 3px solid #fdefc6;
  }
`

const isSelected = ({partidaId, selectedParts}) => {
    for( let part of selectedParts){
        if(part.partidaId === partidaId){
            return true
        }
    }
    return false
}
const Part = ({part, selectedParts, setSelectedParts}) => {
    const [total, setTotal] = useState(0)
    const {user} = useContext(AuthContext)
    const [checked, setChecked] = useState(isSelected({partidaId: part.partidaId, selectedParts}))
    useEffect(() => {
      if (part) {
        setTotal(
          parseFloat(part.quantity * part.clientPrice)
        )
      }
    }, [])
    const onCheck = () => {
        if (checked) {
            setSelectedParts(selectedParts.filter((p) => p.partidaId !== part.partidaId))
            setChecked(false)
        } else {
            setSelectedParts([...selectedParts, part])
            setChecked(true)
        }
    }
    const formatCurrency = (value) => {
        let handledValue = value ?? 0
        return formatValue({value: handledValue.toFixed(2), locale: 'es-ES', decimalSeparator: ',', groupSeparator: '.',
        suffix:   
          getCurrency(user['custom:currency'])
        , decimalScale: 2, fixedDecimalScale: true
      })
    }
    const formatNumber = (value) => {
        let handledValue = value ?? 0
        return formatValue({value: handledValue.toFixed(2), locale: 'es-ES', decimalSeparator: ',', groupSeparator: '.',
        suffix: '', decimalScale: 2, fixedDecimalScale: true
      })
    }
    return (
      <CustomRow
        onClick={onCheck}
      >
        <td style={{fontFamily:"Helvetica"}}>
          <div className="inline" style={{marginLeft:'-10px', marginBottom:'-3px'}}>
        <input
                type={"checkbox"}
                checked={checked}
                onChange={onCheck}
                style={{
                  width: "13px",
                  marginRight: "10px",
                  cursor: "pointer",
                  marginTop: "0px",
                }}
              />
          <PoppinsGrey style={{fontWeight:'300', paddingTop:'10px', fontSize:'13px'}}>{part.unity}</PoppinsGrey>
          </div>
          </td>
        <td style={{fontWeight:'300'}}>{part.name}</td>
        <td style={{ paddingLeft: "23px", fontFamily:"Helvetica"}}>{part.quantity ? formatNumber(part.quantity) : 0}</td>
        <td style={{fontFamily:"Helvetica"}}>{part.userPrice? formatCurrency(part.userPrice) : formatCurrency(part.clientPrice)}</td>
        <td style={{fontFamily:"Helvetica"}}>{formatCurrency(total)}</td>
      </CustomRow>
    )
  }
  Part.propTypes = {
    part: PropTypes.object.isRequired,
    selectedParts: PropTypes.array.isRequired,
    setSelectedParts: PropTypes.func.isRequired,
  }
  export default Part
  
  
