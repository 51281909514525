import React, { useState, useContext, useEffect } from "react"
import MetaTags from "react-meta-tags"
import UploadDocument from "../../components/Documents/UploadDocument"
import { Container } from "reactstrap"
import DocumentDropZone from "components/Documents/DocumentDropZone"
import FileContext from "context/file/fileContext"
import ContactContext from "context/contact/contactContext"
import i18n from "i18n"
import { HelveticaBold } from "common/typographies"
import { RecordPageView } from "App"
import LayoutDocuments from "./layoutDocuments"
import IndexProjectDocuments from "components/Documents/Projects"
import ProjectContext from "context/projects/projectContext"
import * as amplitude from '@amplitude/analytics-browser';

import { CircularProgress } from "@material-ui/core"

const Document = () => {
  RecordPageView()
  const [selectedDocument, setSelectedDocument] = useState([])
  const [openUploadModal, setOpenUploadModal] = useState(false)
  const handleOpen = () => setOpenDropZone(true)
  const [openDropZone, setOpenDropZone] = useState(false)
  const [selectedFiles, setSelectedFiles] = useState([])
  const fileContext = useContext(FileContext)
  const contactContext = useContext(ContactContext)
  const { Contacts, getContacts } = contactContext
  const {
    getPostUrl,
    uploadProcess,
    getMyFiles,
    files,
    deleteFile,
    updateFile,
    loading,
  } = fileContext

  const {projects, getProjects} = useContext(ProjectContext)


  useEffect(() => {
    amplitude.track('Documentos Visited');
}, [])


  const handleUpload = e => {
    let auxInfo = {
      tags: [],
      customDB: false,
      importedProject: false,
      description: "",
      orgId: "",
    }
    e.preventDefault()
    getPostUrl(selectedFiles[0], auxInfo)
    setOpenDropZone(false)
    setOpenUploadModal(true)
  }


  useEffect(() => {
    getMyFiles()
    getContacts()
    if(projects.length === 0){
      getProjects()
    }
  }, [])

  return (
    <>
      <div className="page-content" style={{overflowX:'hidden'}}>
        <MetaTags>
          <title>Planhopper | Archivos</title>
        </MetaTags>
        <Container fluid style={{height:'80vh', marginLeft:'30px', paddingRight:'0px', marginRight:'30px', marginBottom:'100px', width:'auto'}}>
      
      {
    loading ?
    <div style={{justifyContent:'center', width:'100%', textAlign:'center', position:'relative', display:'grid',  marginTop:'90px'}}>
   <>
                <div style={{ width:'100%', position:'absolute', transform:'translateY(-50%, -50%)' }}>
                <CircularProgress
                    style={{ width: 150, height: 150, marginLeft:'0px', color:'#414141', marginBottom:'20px' }}
                />
                <HelveticaBold style={{color:'#414141', fontSize:'24px', marginLeft:'10px', marginTop:'0px'}}>{i18n.t("Cargando...")}</HelveticaBold>
                </div>
                   
            </>
  </div>
            :
            <>
           
      <LayoutDocuments handleOpen={handleOpen} />
        
      <IndexProjectDocuments   selectedDocument={selectedDocument}
          setSelectedDocument={setSelectedDocument}/>

        <UploadDocument
          open={openUploadModal}
          setOpen={setOpenUploadModal}

        />

        <DocumentDropZone
          open={openDropZone}
          setOpen={setOpenDropZone}
          selectedFiles={selectedFiles}
          setselectedFiles={setSelectedFiles}
          handleSubmit={handleUpload}
          uploadProcess={uploadProcess}
        />
         
         </>
      }
        </Container>
      </div>
    </>
  )
}

export default Document
