import { PoppinsNormal } from "common/typographies";
import i18n from "i18n";
import React, { useContext, useState, useEffect } from "react";
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import AuthContext from "context/auth/authContext";
import ModalSelectProject from "./modals/modalSelectProject";
import TemplatesContext from "context/templates/templatesContext";
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import ModalSuccessFav from "./modals/modalSuccessFav";
import { CircularProgress } from "@mui/material";
import ModalSelectChapter from "./addToMyBP/selectChapter";
import ModalDeleteCapTopMenu from "./modals/modalDeleteCapTopMenu";



const TopQuickMenu3 = () => {

    const [open, setOpen] = useState(false);
    const [openEliminate, setOpenEliminate] = useState(false);
    const {categoriesChecked, partsChecked, deleteCapitulo, deleteBankPart, templateInfo, cleanParts, cleanChapters, myPricesBank, selectedCategories2, getSelectedChaptersToFav, loadingCaps, getSelectedChapters, uncheckAll} = useContext(TemplatesContext)
    const {user, freeTrialExpired} = useContext(AuthContext)
    const [openModalMYBP, setOpenModalMYBP] = useState(false)
    const handleDelete = async() => {
      setOpenEliminate(true)
      /*uncheckAll()
      if(categoriesChecked.length>0){
        for (let index = 0; index < categoriesChecked.length; index++) {
          await deleteCapitulo(categoriesChecked[index].parentId, categoriesChecked[index].chapterTemplateId, )
          await cleanChapters()
          await cleanParts()
      }
      await cleanChapters()
      }else if(partsChecked.length>0){
        for (let index = 0; index < partsChecked.length; index++) {
            await deleteBankPart(
              partsChecked[index].chapterId,
              partsChecked[index].partidaId,
              partsChecked[index].accountId
            )
          }
          await cleanParts()
          await cleanChapters()
        
      }  */
    }
    useEffect(()=>{
      getSelectedChapters([],[], myPricesBank.subcapitulos, true)
},[categoriesChecked.length])

    const [openSuccess, setOpenSuccess] = useState(false);
    const [Iclick, setIClick] = useState(false);
   const handleAddToFavorites = async() => {
       await getSelectedChaptersToFav([],[], myPricesBank.subcapitulos, true, user.sub)
        setIClick(true)
   }

   useEffect(() => {
    if(Iclick && loadingCaps===false){
        setOpenSuccess(true)
        setIClick(false)
    }
   }, [Iclick, loadingCaps])

    const changeCheck = e => {
      if (!e) var e = window.event;
      e.cancelBubble = true;
      if (e.stopPropagation) e.stopPropagation();
      if(partsChecked.length > 0 || categoriesChecked.length > 0){
          uncheckAll()
      }
    }

    return(
        <>
    <div style={{position:'fixed', top:'0px', left:'0px', width:'100%', height:'47px', backgroundColor:'#FFD52B', zIndex:'999', paddingTop:'15px'}}>
        <div className="inline">
            <div style={{marginLeft:'10%'}} className="inline">
                <td className="tdcheckColumn">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={partsChecked.length > 0 || categoriesChecked.length > 0? true : false}
                    id="flexCheckDefault"
                    style={{ width: "13px", height: "13px", cursor:'pointer', zIndex:"5", position:"relative" }}
                    onClick={(e)=>{changeCheck(e)}}
                  ></input>
                </td>   
                    {categoriesChecked.length>0 ?
                    categoriesChecked.length==1 ? 
                <PoppinsNormal>{categoriesChecked.length}{" "}{i18n.t("capitulo seleccionado")} </PoppinsNormal>
                :
                <PoppinsNormal>{categoriesChecked.length}{" "}{i18n.t("capitulos seleccionados")} </PoppinsNormal>
                : partsChecked.length>0 ?
                partsChecked.length==1 ?
                <PoppinsNormal>{partsChecked.length}{" "}{i18n.t("partida seleccionada")} </PoppinsNormal>
                :
                <PoppinsNormal>{partsChecked.length}{" "}{i18n.t("partidas seleccionadas")} </PoppinsNormal>
                :
                null
                    }
            </div>
            {user ? (user['custom:role']==='WORKER' || freeTrialExpired) ? 
 <div style={{justifyContent:'center', width:'100%', textAlign:'center', position:'absolute', display:'flex', width:'100%', marginLeft:'auto', marginRight:'auto'}}>
 <BookOutlinedIcon
     className="primerIconoShow" style={{cursor:'pointer', color:'#C4C4C4', width:'24px', marginRight:'3px'  }}/>
   
     <CreateNewFolderOutlinedIcon  className="segundoIconoShow" style={{cursor:'pointer', color:'#C4C4C4', width:'24px', marginRight:'3px'  }}/>
    
   {loadingCaps && Iclick ? <CircularProgress style={{marginTop:'3px'}} size={16}/> : <>
     <StarOutlineIcon 
   
     className="tercerIconoShow" style={{color:'#C4C4C4', width:'24px', marginRight:'0px', cursor:'pointer'  }}/>
   
   </>}
   
     <DeleteOutlinedIcon className="cuartoIconoShow" style={{color:'#C4C4C4', width:'24px', marginRight:'3px', cursor:'pointer'  }}/>
    
    
 </div>
            :
            <div style={{justifyContent:'center', width:'100%', textAlign:'center', position:'absolute', display:'flex', width:'100%', marginLeft:'auto', marginRight:'auto'}}>
            <BookOutlinedIcon
                onClick={()=>{setOpenModalMYBP(true)}} className="primerIconoShow" style={{cursor:'pointer', color:'#414141', width:'24px', marginRight:'3px'  }}/>
                <div className="primerIcono" style={{position: 'absolute', marginLeft:'-55px', marginTop:'35px'}}>
                    <div className="recuadro" style={{width:'150px'}}>
                            <div className="recuadroPico"></div>
                            {i18n.t("Añadir a mi banco de precios")}
                    </div>
              </div>
                <CreateNewFolderOutlinedIcon onClick={()=>{setOpen(true)}} className="segundoIconoShow" style={{cursor:'pointer', color:'#414141', width:'24px', marginRight:'3px'  }}/>
                <div className="segundoIcono" style={{position: 'absolute', marginLeft:'0px', marginTop:'35px'}}>
                    <div className="recuadro">
                            <div className="recuadroPico"></div>
                            {i18n.t("Añadir a proyecto")}
                    </div>
              </div>
              {/* 
              {loadingCaps && Iclick ? <CircularProgress style={{marginTop:'3px'}} size={16}/> : <>
                <StarOutlineIcon 
                  onClick={()=>{handleAddToFavorites()}}
                className="tercerIconoShow" style={{color:'#414141', width:'24px', marginRight:'0px', cursor:'pointer'  }}/>
                <div className="tercerIcono" style={{position: 'absolute', marginLeft:'25px', marginTop:'35px'}}>
                    <div className="recuadro">
                            <div className="recuadroPico"></div>
                            {i18n.t("Añadir a favoritos")}
                    </div>
              </div>
              </>}
              */}
               {
    myPricesBank.subcapitulos.length==1 && categoriesChecked.length==1  ? 
    <DeleteOutlinedIcon className="cuartoIconoShow" style={{color:'#C4C4C4', width:'24px', marginRight:'3px', cursor:'pointer'  }}/>
   :
                <DeleteOutlinedIcon onClick={()=>{templateInfo.accountId == 'tribboo' ? null : handleDelete()}} className="cuartoIconoShow" style={{color:templateInfo.accountId == 'tribboo' ? '#C4C4C4' :'#414141', width:'24px', marginRight:'3px', cursor:templateInfo.accountId == 'tribboo' ? 'default' :'pointer'  }}/>}
                <div className="cuartoIcono" style={{position: 'absolute', marginLeft:'50px', marginTop:'35px'}}>
                    <div className="recuadro">
                            <div className="recuadroPico"></div>
                            {i18n.t("Eliminar")}
                    </div>
              </div>
               
            </div>
        
         : null}
        </div>
        
    </div>
    <ModalDeleteCapTopMenu openEliminate={openEliminate} setOpenEliminate={setOpenEliminate}/>
    <ModalSelectProject open={open} setOpen={setOpen}/>
    <ModalSuccessFav open={openSuccess} setOpen={setOpenSuccess}/>
    <ModalSelectChapter open={openModalMYBP} setOpen={setOpenModalMYBP} tipo={'TEMPLATES'}/>
    </>
    )
}
export default TopQuickMenu3;