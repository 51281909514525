import { useState, useLayoutEffect, useContext, useEffect} from 'react'
import AuthContext from 'context/auth/authContext'
import { useHistory } from 'react-router-dom'
import { isLocalhost } from 'utils/functions'

export const useRegister = () => {

    const {user, getUser, Register, errorRegistro, statusRegister, Login} = useContext(AuthContext)

    let search = window.location.search;
    let params = new URLSearchParams(search);
    const utm_s = params.get("utm_source")? params.get("utm_source") : ''
    const utm_c = params.get("utm_campaign")? params.get("utm_campaign") : ''
    const utm_m  = params.get("utm_medium")? params.get("utm_medium") : ''
    const invitedBy = params.get("invitedBy")? params.get("invitedBy") : ''
    const rol = params.get("rol")? params.get("rol") : ''
    const emailParams = params.get("email")? params.get("email") : ''

    const [mailError, setMailError] = useState(false)
    const [passwordError, setPasswordError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [passwordMessage, setPasswordMessage] = useState('')
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState(emailParams)

 

    const [password, setPassword] = useState('')
    const [cont, setCont] = useState(0) //Es un contador para recargar el useEffect por si sale el mismo error mas de una vez

  

      useLayoutEffect(()=>{
        let userAuth = localStorage.getItem('userAuth');
        if (userAuth) {
          history.push('/dashboard')
        }
        else{
          getUser()
        }
      },[])

      useEffect(()=>{
        if(user){
          user['custom:onboarding'] === 'true' ? history.push('/dashboard'): history.push('/onboarding')
        }
      },[user])

      useEffect(()=>{
        if(errorRegistro.estado){
            if(errorRegistro.mensaje === 'Incorrect username or password.'){
                setPasswordMessage('La contraseña es incorrecta')
                setPasswordError(true)
            }else if(errorRegistro.mensaje === 'An account with the given email already exists.'){
                setErrorMessage('Ya existe una cuenta creada con este correo')
                setMailError(true)
            }else if(errorRegistro.mensaje === 'Error - Account already exists with Planhopper'){
                setErrorMessage('Ya existe una cuenta con este correo asociado')
                setMailError(true)
             }else if(errorRegistro.mensaje === 'Ya existe una cuenta de google con este mail asociado'){
                setErrorMessage('Ya existe una cuenta con este correo asociado')
                setMailError(true)
             }
          setLoading(false)
        }
        },[errorRegistro.estado, errorRegistro.mensaje, cont])

      useEffect(() => {
        signUp()
      }, [statusRegister])
    
      const signUp = async() => {
        if (statusRegister) {
          await Login({
           email: email,
           password: password,
         })
         setLoading(false)
       }
      }

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }
    
      const isLowercase = str => {
        for (let i = 0; i < str.length; i++) {
          if (str[i] === str[i].toLowerCase() && str[i] !== str[i].toUpperCase()) {
            return true
          }
        }
        return false
      }

      const handleChange = (e) => {
        if(e.target.name === 'email'){
            setEmail(e.target.value)
            setErrorMessage('')
            setMailError(false)
        }
        if(e.target.name === 'password'){
            setPassword(e.target.value)
            setPasswordMessage('')
            setPasswordError(false)
        }
        }
      
      const handleRegister = async () => {
        setLoading(true)
        if(email === '' || password === ''){
          if(email === ''){
            setMailError(true)
            setErrorMessage('El correo no puede estar vacío')
          }else{
            setMailError(false)
            setErrorMessage('')
          }
          if(password === ''){
            setPasswordError(true)
            setPasswordMessage('La contraseña no puede estar vacía')
          }else{
            setPasswordError(false)
            setPasswordMessage('')
          }
          setLoading(false)
        }else if(!isValidEmail(email)){
            setMailError(true)
            setErrorMessage('El correo no es válido')
            setLoading(false)
        }else if (!isLowercase(password)) {
            setPasswordError(true)
            setPasswordMessage('La contraseña debe tener al menos una letra minúscula')
            setLoading(false)
        }else if(password.length < 8) {
            setPasswordError(true)
            setPasswordMessage('La contraseña debe contener al menos 8 caracteres')
            setLoading(false)
        }else if (password.match(/\d+/g) == null) {
            setPasswordError(true)
            setPasswordMessage('La contraseña debe contener al menos un caracter numérico')
            setLoading(false)
        }else{
            setCont(cont + 1)
            await Register({email:email, password:password}, utm_s, utm_c, utm_m, {invitedBy, rol})
        }
    }
    
    return {
        isLocalhost,
        history,
        errorMessage,
        mailError,
        passwordError,
        passwordMessage,
        email, 
        password,
        loading,

        handleRegister,
        handleChange
    }
}