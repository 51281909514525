export const CLEAN_IMPORT_PROGRESS = "CLEAN_IMPORT_PROGRESS"
export const ON_UPLOAD_FILE = "ON_UPLOAD_FILE"
export const UPLOAD_FILE_ERROR = "UPLOAD_FILE_ERROR"
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS"
export const ADD_PARTIDA_TO_MOVE = "ADD_PARTIDA_TO_MOVE"
export const ADD_CAPITULO_MOVE = "ADD_CAPITULO_MOVE"
export const DEL_CAPITULO = "DEL_CAPITULO"

export const GET_BANK_PRICES_SUCCESS = "GET_BANK_PRICES_SUCCESS"
export const GET_BANK_PRICES_ERROR = "GET_BANK_PRICES_ERROR"

export const ON_CREATE_PART_BANK = "ON_CREATE_PART_BANK"
export const CREATE_PART_BANK_SUCCESS = "CREATE_PART_BANK_SUCCESS"
export const CREATE_PART_BANK_ERROR = "CREATE_PART_BANK_ERROR"

export const ON_UPDATE_PART_BANK = "ON_UPDATE_PART_BANK"
export const UPDATE_PART_BANK_SUCCESS = "UPDATE_PART_BANK_SUCCESS"
export const UPDATE_PART_BANK_ERROR = "UPDATE_PART_BANK_ERROR"

export const ON_DELETE_PART_BANK = "ON_DELETE_PART_BANK"
export const DELETE_PART_BANK_SUCCESS = "DELETE_PART_BANK_SUCCESS"
export const DELETE_PART_BANK_ERROR = "DELETE_PART_BANK_ERROR"

export const ON_CREATE_CHAPTER_BANK = "ON_CREATE_CHAPTER_BANK"
export const CREATE_CHAPTER_BANK_SUCCESS = "CREATE_CHAPTER_BANK_SUCCESS"
export const CREATE_CHAPTER_BANK_ERROR = "CREATE_CHAPTER_BANK_ERROR"

export const ON_UPDATE_CHAPTER_BANK = "ON_UPDATE_CHAPTER_BANK"
export const UPDATE_CHAPTER_BANK_SUCCESS = "UPDATE_CHAPTER_BANK_SUCCESS"
export const UPDATE_CHAPTER_BANK_ERROR = "UPDATE_CHAPTER_BANK_ERROR"

export const ON_DELETE_CHAPTER_BANK = "ON_DELETE_CHAPTER_BANK"
export const DELETE_CHAPTER_BANK_SUCCESS = "DELETE_CHAPTER_BANK_SUCCESS"
export const DELETE_CHAPTER_BANK_ERROR = "DELETE_CHAPTER_BANK_ERROR"
