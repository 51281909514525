import React, { useState, useContext, useEffect } from "react"
import propTypes from "prop-types"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import {
  CapituloContainer,
  SubChapterContainer,
  ShowChildDiv,
  ShowChildDivWithBorder,
} from "common/containers"
import i18n from "i18n"
import ContactContext from "context/contact/contactContext"
import PartTable from "components/FavoritesBP/parts/index"
import AuthContext from "context/auth/authContext"
import ModalCreateBankPart from "../modals/modalCreateBankPart"
import FavoritesBPContext from "context/favoritesBP/favoritesBPContext"
import SendModal from "components/Feed/sendBudgetRefatctor/sendModal"

const Capitulo = props => {
  const [openNotSendCapitulo, setOpenNotSendCapitulo] = useState(false)
  const [subCapsLoaded, setSubCapsLoaded] = useState(false)
  const {getSubCaps, loadingCaps, addBankChapter, updateChapterBank, checkCapitulos, updateChaptersList, checkMultipleChapters, selectedCategories, getSelectedChapters, selectedCategories2, categoriesChecked } = useContext(FavoritesBPContext)
  const {user} = useContext(AuthContext)
  const handleOpen = async() => {
    setOpenNotSendCapitulo(prev => !prev)
    if(subCapsLoaded === false){
      await getSubCaps(props.capitulo.chapterId,user.sub, props.tipo, props.referencia)
      setSubCapsLoaded(true)
    }
  }

  const [openSendBudget, setOpenSendBudget] = useState(false)
  const contactContext = useContext(ContactContext)
  const { Contacts, getContacts} = contactContext
  const [openPart, setOpenPart] = useState(false)


  const [name, setName] = useState(props.capitulo.capituloInfo.name)
  useEffect(() => {
    setName(props.capitulo.capituloInfo.name)
  } ,[props.capitulo])

  const handleChangeName = e => {
    setName(e.target.value)
  }

  const changeCheck = async e => {
    if (!e) var e = window.event;
    e.cancelBubble = true;
    if (e.stopPropagation) e.stopPropagation();
    if(e.nativeEvent.shiftKey){
      if(props.capitulo.checked === true){
        await checkCapitulos(props.capitulo.chapterId, false)
        await updateChaptersList(props.capitulo.capituloInfo)
      }else{
        await checkMultipleChapters(props.capitulo.chapterId)
      } 
    }else{
      if (props.capitulo.checked === true) {
        await checkCapitulos(props.capitulo.chapterId, false)
        await updateChaptersList(props.capitulo.capituloInfo)
      } else {
        await checkCapitulos(props.capitulo.chapterId, true)
        await updateChaptersList(props.capitulo.capituloInfo)
      }
    }
  }

  return (
    <div style={{marginBottom: '0px' }}>
      {props.capitulo.parentId == "root" ||
      props.capitulo.capituloInfo.parentId === "root" || props.soyRoot ? (
        <div>
          <CapituloContainer onClick={(e)=>{e.preventDefault();e.stopPropagation();handleOpen()}} className="hoverCapitulo" style={{ background: openNotSendCapitulo ? "#FCD56B" : '#FDEFC6'}}>
            <div
              style={{
                height:'44px',
                background: openNotSendCapitulo ? "#FCD56B" : '#FDEFC6',
                minWidth: "37px",
                paddingLeft: "3px",
                display: "flex",
                position: "relative",
                borderRadius: "6px",
                paddingTop: "12px",
                }}
            >
              {openNotSendCapitulo ? (
                <div>
                  <KeyboardArrowDownIcon
                    style={{ cursor: "pointer", marginLeft: "3px" }}
                    className="dropdownArrowB"
   
                  />
                </div>
              ) : (
                <KeyboardArrowRightIcon
                  style={{ cursor: "pointer", marginLeft: "3px" }}
                  className="dropdownArrowB"
 
                />
              )}
              <div  className={props.capitulo.checked ? " " : "detallesCap"}>
                  <td className="tdcheckColumn">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={props.capitulo.checked}
                    id="flexCheckDefault"
                    onClick={(e)=>{
                      if (!e) var e = window.event;
                        e.cancelBubble = true;
                        if (e.stopPropagation) e.stopPropagation();
                    }}
                    onChange={changeCheck}
                    style={{ width: "13px", height: "13px", cursor:'pointer', zIndex:"5" }}
                  ></input>
                </td>
                </div>
                    <input
                    onChange={handleChangeName}
                    onClick={(e)=>{
                      if (!e) var e = window.event;
                        e.cancelBubble = true;
                        if (e.stopPropagation) e.stopPropagation();
                    }}
                    
                    className="NombreAlign"
                    style={{marginTop:'-10px', WebkitTextFillColor:'#414141'}}
                    name={"name"}
                    value={name}
                    placeholder={i18n.t("Introduce el nombre de la categoria")}
                    autoComplete="off"
                    disabled={true}
                  ></input>
                
                  <td className="tdTotalCap" style={{ paddingLeft: "7px" }}>
                    <div className="inline" style={{ marginLeft: "5px", position: 'absolute',
                              right: '1%',
                              marginTop: '0px' }}>
                                  <div
                            className="detallesCap"
                            style={{
                              backgroundColor: "transparent",
                              width: "40px",
                              height: "25px",
                              justifyContent: "center",
                              marginLeft: "120px",
                            }}
                          >
                        
                          
                          </div>
                    </div>
                  </td>
              
            </div>
            
             

          </CapituloContainer>
          {openNotSendCapitulo ? (
            <ShowChildDiv  style={{ width: "99%", marginTop: props.capitulo.partidas?.length ? '-8px' : '-14px' , background:'#FDEFC6'}}>
              {props.capitulo.partidas?.length ? (
                <PartTable parts={props.capitulo.partidas} total={props.capitulo.total} capitulo={props.capitulo}/>
              ) : props.capitulo.subcapitulos?.length ? (
                props.capitulo.subcapitulos.map((capitulo, i) => (
                 
                  <Capitulo
                    key={capitulo.chapterId}
                    i={i}
                    capitulo={capitulo}
                    imParent={(props.capitulo.parentId = "root" ? true : false)}
                    imLast={props.capitulo.subcapitulos.length - 1 === i ? true : false}
                    indice={i}
                    tipo={props.tipo}
                    referencia={props.referencia}
      
                  />
                ))
             
              ) : 
            null
              }
            </ShowChildDiv>
          ) : null}
        </div>
      ) : (
        <div>
          <SubChapterContainer onClick={(e)=>{e.preventDefault();e.stopPropagation();handleOpen()}} className="hoverCapitulo" style={{borderLeft: props.imParent? '6px solid #F1CA2C' : '0px', background:'#FCD56B'}}>
            <div
              style={{
                minWidth: "37px",
                paddingLeft: "3px",
                display: "flex",
                position: "relative",
                paddingTop: "11px",
                background: "#FCD56B",
                borderRadius:'6px'
              }}
            >
              {openNotSendCapitulo ? (
                <div>
                  <KeyboardArrowDownIcon
                    style={{ cursor: "pointer", marginLeft: "3px" }}
                    className="dropdownArrowB"
      
                  />
                </div>
              ) : (
                <KeyboardArrowRightIcon
                  style={{ cursor: "pointer", marginLeft: "3px" }}
                  className="dropdownArrowB"
    
                />
              )}
              <div className={props.capitulo.checked ? " " : "detallesCap"}>
                  <td className="tdcheckColumn" style={{height:'0px'}}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={props.capitulo.checked}
                    id="flexCheckDefault"
                    onClick={(e)=>{
                      if (!e) var e = window.event;
                        e.cancelBubble = true;
                        if (e.stopPropagation) e.stopPropagation();
                    }}
                    onChange={changeCheck}
                    style={{ width: "13px", height: "13px", cursor:'pointer', zIndex:"5" }}
                  ></input>
                  
                </td>
                </div>
               <input
                    onChange={handleChangeName}
                    onClick={(e)=>{
                      if (!e) var e = window.event;
                        e.cancelBubble = true;
                        if (e.stopPropagation) e.stopPropagation();
                    }}
                    className="NombreAlign"
                    style={{marginTop:'-10px', WebkitTextFillColor:'#414141'}}
                    name={"name"}
                    value={name}
                    disabled={true}
                    placeholder={i18n.t("Introduce el nombre de la categoria")}
                    autoComplete="off"
                  ></input>
                   
                    <div className="inline" style={{ marginLeft: "5px", position: 'absolute',
                              right: '1%',
                              marginTop: '0px' }}>
                                  <div
                            className="detallesCap"
                            style={{
                              backgroundColor: "transparent",
                              width: "40px",
                              height: "0px",
                              justifyContent: "center",
                              marginLeft: "120px",
                            }}
                          >
                          
                          </div>
                    </div>
                  
            </div>
       
          </SubChapterContainer>
         
          {openNotSendCapitulo ? (
            props.imParent ? (
              <ShowChildDivWithBorder style={{paddingLeft: props.capitulo.partidas?.length ? '0px' : '8px', borderLeft:'7px solid #F1CA2C'}}>
                {props.capitulo.partidas?.length ? (
                  <PartTable
                    parts={props.capitulo.partidas}
                    imParent={props.imParent ? props.imParent : false}
                    total={props.capitulo.total}
                    capitulo={props.capitulo}
                  />
                ) : props.capitulo.subcapitulos?.length ? (
                  props.capitulo.subcapitulos.map((capitulo, i) => (
                    <Capitulo
                      key={capitulo.chapterId}
                      i={i}
                      capitulo={capitulo}
                      imLast={props.capitulo.subcapitulos.length - 1 === i ? true : false}
                      indice={i}
                      tipo={props.tipo}
                      referencia={props.referencia}
                    />
                  ))
                ) : 
           null
                }
              </ShowChildDivWithBorder>
            ) : (
              <ShowChildDiv style={{backgroundColor:'#FDEFC6'}}>
                {props.capitulo.partidas?.length ? (
                  <PartTable parts={props.capitulo.partidas} total={props.capitulo.total}  capitulo={props.capitulo} />
                ) : props.capitulo.subcapitulos?.length ? (
                  props.capitulo.subcapitulos.map((capitulo, i) => (
                    <Capitulo
                      key={capitulo.chapterId}
                      i={i}
                      capitulo={capitulo}
                      imLast={props.capitulo.subcapitulos.length - 1 === i ? true : false}
                      indice={i}
                      tipo={props.tipo}
                      referencia={props.referencia}
                    />
                  ))
                ) :    
              null
            }
              </ShowChildDiv>
            )
          ) : null}
        
        </div>
      )}
       <SendModal
                open={openSendBudget}
                setOpen={setOpenSendBudget}
                contactos={Contacts}
                capitulo={props.capitulo}
            /> 

        <ModalCreateBankPart open={openPart} setOpen={setOpenPart} capitulo={props.capitulo}/>
    </div>
  )
}

Capitulo.propTypes = {
  key: propTypes.string.isRequired,
  i: propTypes.number.isRequired,
  capitulo: propTypes.object.isRequired,
  imParent: propTypes.bool,
  imLast: propTypes.bool,
  indice: propTypes.number,
  tipo: propTypes.string,
  referencia: propTypes.string,
  soyRoot: propTypes.bool

}

export default Capitulo
