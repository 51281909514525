import React, { useState } from "react"
import PropTypes from "prop-types"
import { Col, Row } from "reactstrap"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"

const ChapterHeader = ({ title, showDownloadIcon, chapter }) => {
  const [showIcon, setShowIcon] = useState(false)

  return (
    <Row xs={12} className="rowHeader" onClick={() => setShowIcon(!showIcon)}>
      <Col xs={1} className="d-flex align-items-center maxWidth35">
        {showIcon ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
      </Col>
      <Col xs={showDownloadIcon ? 7 : 11} className="d-flex align-items-center">
        {title}
      </Col>
      {showDownloadIcon && (
        <>
          { /* <Col xs={1} className="col-1 col-lg d-flex justify-content-end">
            <div
              className="precioTotal"
              style={{
                fontWeight: "700",
                minWidth: "170px",
                backgroundColor: "white",
                color: "#414141",
                border: "1px solid #414141",
                fontFamily: "Helvetica",
                width: "fit-content",
                paddingLeft: "5px",
                paddingRight: "5px",
                height: "30px",
                marginTop: "5px",
              }}
            >
              Total € {chapter.price}
            </div>
          </Col> */ }
          <Col xs={3} className="col-1 col-lg d-flex justify-content-end">
            <div
              className="precioTotal"
              style={{
                fontWeight: "700",
                minWidth: "170px",
                backgroundColor: "white",
                color: "#414141",
                border: "1px solid #414141",
                fontFamily: "Helvetica",
                width: "fit-content",
                paddingLeft: "5px",
                paddingRight: "5px",
                height: "30px",
                marginTop: "5px",
              }}
            >
              Total € {parseFloat(chapter.clientPrice).toFixed(2)} 
            </div>
          </Col>
        </>
      )}
    </Row>
  )
}

ChapterHeader.propTypes = {
  title: PropTypes.string,
  showDownloadIcon: PropTypes.bool,
  setOpenSideBar: PropTypes.func,
  chapter: PropTypes.object,
  certId: PropTypes.string,
}

export default ChapterHeader
