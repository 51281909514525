import React from "react";
import MetaTags from "react-meta-tags";
import {Container} from "reactstrap";
import i18n from "i18n";
import logoLarge from "assets/customIcons/logos/planhopper_logo_large.svg"
import { YellowButton } from "common/buttons";
import InputMail from "./inputMail";
import { usePassword } from "hooks/usePassword";
import InputPasswords from "./inputPasswords";
import PasswordCompleted from "./passwordCompleted";


const PasswordRecoveryFlow = () => {

    const {mailError,  passwordError, errorMessage, passwordMessage, loading, step, email, password, handleConfirmEmail, handleChange, code, codeError,codeMessage, handleReset} = usePassword()

    return (
        <React.Fragment>
        <MetaTags>
          <title>Recupera tu contraseña | Planhopper</title>
        </MetaTags>
        <Container fluid style={{backgroundColor:'#EFEFEF',height:'100vh'}}>
          <div className="inline">
            <a href="https://www.planhopper.com" target="_blank" rel="noreferrer" style={{zIndex:'99'}}>
              <img className="logoPositioning" src={logoLarge} width='147px' height='45px'/>
            </a>
            <a href='https://calendly.com/planhopper-xvq/planhopper' target='_blank' rel='noreferrer'>
            <YellowButton className="butonRightPositioning" style={{zIndex:'99'}}>
              {i18n.t("Solicitar Demo")}
            </YellowButton>
            </a>
          </div>
          
        </Container>
        {step==1 ?
            <InputMail loading={loading} mailError={mailError} errorMessage={errorMessage} handleConfirmEmail={handleConfirmEmail} email={email} handleChange={handleChange}/>
        :   
        step==2 ?
            <InputPasswords loading={loading} codeError={codeError} passwordError={passwordError} passwordMessage={passwordMessage} codeMessage={codeMessage} handleReset={handleReset} handleConfirmEmail={handleConfirmEmail} code={code} handleChange={handleChange} password={password} />
        :
            <PasswordCompleted />
        }
          
         </React.Fragment>
    );
 
}

export default PasswordRecoveryFlow;
