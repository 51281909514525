import React from "react"
import i18n from "i18n"
import { HelveticaBold, } from "common/typographies"
import Lottie from 'react-lottie-player'
import lottieJson from './animation.json'



const FourthStep = () => {


  return (
    <>
    <HelveticaBold style={{fontWeight:'700', fontSize:'18px', color:'#414141', marginTop:'40px'}}>
        {i18n.t("Buenas noticias para ti")}
     </HelveticaBold>
     <HelveticaBold style={{fontWeight:'400', fontSize:'14px', color:'#414141', marginTop:'40px'}}>
        {i18n.t("¡Tu presupuesto ha sido enviado con éxito!")}
     </HelveticaBold>
     <Lottie
      loop
      animationData={lottieJson}
      play
      style={{ width: 200, height: 200, marginLeft:'-20px' }}
    />
    </>

  )
}


export default FourthStep
