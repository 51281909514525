import { BlueButton, CapituloMini } from "common/containers";
import React, {useState, useContext} from "react";
import PropTypes from 'prop-types'
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import { currencyFormatDE } from "lib/transformers";
import TemplatesContext from "context/templates/templatesContext";
import { getCurrency } from "utils/functions"
import AuthContext from "context/auth/authContext";

const CapituloModal = ({capitulo, capituloSelected, setCapituloSelected, margin, templateId}) => {

    const {user} = useContext(AuthContext)
    const [openNotSendCapitulo, setOpenNotSendCapitulo] = useState(false)
    const {getSubCaps} = useContext(TemplatesContext)
    const [cargado, setCargado] = useState(false)
    const handleOpen = async(e) => {
        if (!e) var e = window.event;
        e.cancelBubble = true;
        if (e.stopPropagation) e.stopPropagation();
        setOpenNotSendCapitulo(prev => !prev)
        if(cargado==false){
          getSubCaps(capitulo.chapterId, templateId)
          setCargado(true)
        }
      }
   
    return (
        <>
        <CapituloMini onClick={()=>setCapituloSelected(capitulo)} 
          style={{background: capituloSelected.capituloInfo? 
            capituloSelected.capituloInfo.chapterId? 
              capituloSelected.capituloInfo.chapterId==capitulo.capituloInfo.chapterId ? 
                '#FFE992' : 'white'
              : capituloSelected.chapterId==capitulo.chapterId ? 
              '#FFE992' : 'white'
            : 'white', paddingLeft:margin+'px'}}>
            {openNotSendCapitulo ? (
                <div>
                  <KeyboardArrowDownIcon
                    style={{ cursor: "pointer", marginLeft: "5px", marginTop:'5px', visibility: capitulo.subcapitulos.length==0 && cargado==true ? 'hidden' : 'visible'  }}
                    className="dropdownArrowB"
                    onClick={(e) => handleOpen(e)}
                  />
                </div>
              ) : (
                <KeyboardArrowRightIcon
                  style={{ cursor: "pointer", marginLeft: "5px", marginTop:'5px', visibility: capitulo.subcapitulos.length==0 && cargado==true ? 'hidden' : 'visible' }}
                  className="dropdownArrowB"
                  onClick={(e) => handleOpen (e)}
                />
              )}
              <p>
                {capitulo.capituloInfo.name}
              </p>
              <BlueButton style={{color:'white', justifyContent:'center', textAlign:'center', marginRight:'26px', marginTop:'6px'}}>
                {capitulo.capituloInfo.price ? currencyFormatDE(capitulo.capituloInfo.price,getCurrency(user['custom:currency'])) : '0,00'+  
                getCurrency(user['custom:currency'])
              } 
              </BlueButton>
        </CapituloMini>
        {openNotSendCapitulo ? 
            capitulo.subcapitulos.length > 0 ? (
                capitulo.subcapitulos.map((subcapitulo, index) => (
                    <CapituloModal key={index} capitulo={subcapitulo} margin={margin+20} capituloSelected={capituloSelected} setCapituloSelected={setCapituloSelected} templateId={templateId} />
                    
                ))
            ) : null 
            : null}
        </>
    )
}

CapituloModal.propTypes = {
    capitulo: PropTypes.object.isRequired,
    capituloSelected: PropTypes.object.isRequired,
    setCapituloSelected: PropTypes.func.isRequired,
    margin: PropTypes.number.isRequired,
    templateId: PropTypes.string.isRequired,

}

export default CapituloModal;
