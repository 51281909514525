import PropTypes from "prop-types"
import CChapter, { ChapterPropTypes } from "common/CChapter"
import CPart from "common/CPart"
import Direction from "common/Direction.enum"
import { isNil } from "lodash"
class ExtrasAndContradictionsRoot {
  constructor({
    chapters_count = 0,
    client = "",
    contradictory_chapter = "",
    costeGeneral = 0,
    costeIndirecto = 0,
    createdAt = "",
    createdBy = "",
    description = "",
    discount = 0,
    extra_chapter = "",
    fase = 1,
    favourite = false,
    finalPrice = 0,
    finalPriceWithoutDiscount = 0,
    identifierNumber = "",
    igic = 0,
    industrialProfit = 0,
    is_template = false,
    iva = 0,
    name = "",
    orgAddress = "",
    organizationId = "",
    price = 0,
    progressPercentage = 0,
    projectId = "",
    projectImage = "",
    recalcAt = "",
    tags = [],
    totalDiscount = 0,
    updatedAt = "",
    chapters = [],
    parts_total = 0,
    chapters_total = 0,
  }) {
    this.chaptersCount = chapters_count
    this.client = client
    this.contradictoryChapter = contradictory_chapter
    this.costeGeneral = costeGeneral
    this.costeIndirecto = costeIndirecto
    this.createdAt = createdAt
    this.createdBy = createdBy
    this.description = description
    this.discount = discount
    this.extraChapter = extra_chapter
    this.fase = fase
    this.favourite = favourite
    this.finalPrice = finalPrice
    this.finalPriceWithoutDiscount = finalPriceWithoutDiscount
    this.identifierNumber = identifierNumber
    this.igic = igic
    this.industrialProfit = industrialProfit
    this.isTemplate = is_template
    this.iva = iva
    this.name = name
    this.orgAddress = orgAddress
    this.organizationId = organizationId
    this.price = price
    this.progressPercentage = progressPercentage
    this.projectId = projectId
    this.projectImage = projectImage
    this.recalcAt = recalcAt
    this.tags = tags
    this.totalDiscount = totalDiscount
    this.updatedAt = updatedAt
    //! There will only be two chapters in chapters: "Extras" and "Contradictions"
    this.chapters = chapters.map(c => new CChapter(c))
    this.partsTotal = parts_total
    this.chaptersTotal = chapters_total
  }

  /**
   * Updates the positions of parts for a specific chapter identified by chapterId,
   * or for all chapters if chapterId is null or undefined.
   *
   * This method iterates through the chapters and calls the reassignPartPositions
   * method on each chapter that matches the provided chapterId. If chapterId is
   * null or undefined, it updates the positions of parts for all chapters.
   *
   * @param {string|null|undefined} chapterId - The identifier of the chapter for which part positions will be updated.
   *                                 If null or undefined, updates positions for all chapters.
   */
  updatePartPositionsForChapterOrAll = chapterId => {
    this.chapters = this.chapters.map(chapter => {
      if (isNil(chapterId) || chapter.chapterId === chapterId) {
        chapter.reassignPartPositions()
      }
      return chapter
    })
  }

  /**
   * Removes a part from a specific chapter.
   *
   * @param {string} chapterId - The identifier of the chapter to be modified.
   * @param {string} partId - The identifier of the part to be removed.
   */
  removePart = (chapterId, partId) => {
    this.chapters = this.chapters.map(c => {
      if (c.chapterId === chapterId) {
        c.removePart(chapterId, partId)
      }
      return c
    })
  }

  /**
   * Retrieves a part by its partId.
   *
   * @param {string} id - The identifier of the part to be retrieved.
   * @returns {Object|null} - The part with the specified partId, or null if not found.
   */
  getPartByPartId = id => {
    return (
      this.chapters
        .flatMap(chapter => chapter.parts) // Combines all parts arrays into one
        .find(part => part.partId === id) || null
    )
  }

  /**
   * Adds a part to a specific chapter at a given position.
   *
   * @param {string} chapterId - The identifier of the chapter to be modified.
   * @param {CPart} partToAdd - The part to be added.
   * @param {number} position - The position in the list of parts where the new part should be added.
   */
  addPartByChapterId = (chapterId, partToAdd, position) => {
    this.chapters = this.chapters.map(c => {
      if (c.chapterId === chapterId) {
        c.parts.splice(position, 0, partToAdd)
      }
      return c
    })
  }

  /**
   * Reorders parts within a specific chapter.
   *
   * @param {string} chapterId - The identifier of the chapter to be modified.
   * @param {CPart} part - The part that will be moved up or down in the list of parts.
   * @param {string} searchDirection - The direction to search for the adjacent part (Direction.NEXT or Direction.PREVIOUS)
   */
  moveParts = (chapterId, part, searchDirection) => {
    const PARTS_TO_REPLACE = 2
    this.chapters = this.chapters.map(item => {
      if (item.chapterId !== chapterId) return item

      const nextOrPreviousPart = item.getAdjacentPart(
        part.partId,
        searchDirection
      )

      if (isNil(nextOrPreviousPart)) {
        throw new Error("Part not found")
      }

      if (searchDirection === Direction.PREVIOUS) {
        nextOrPreviousPart.position += 1
        part.position -= 1

        item.parts.splice(
          item.parts.indexOf(nextOrPreviousPart),
          PARTS_TO_REPLACE,
          part,
          nextOrPreviousPart
        )
      } else {
        nextOrPreviousPart.position -= 1
        part.position += 1

        item.parts.splice(
          item.parts.indexOf(part),
          PARTS_TO_REPLACE,
          nextOrPreviousPart,
          part
        )
      }

      return item
    })
  }

  getTotalPartCount = () => {
    return this.chapters.reduce(
      (total, chapter) => total + chapter.parts.length,
      0
    )
  }
}

export default ExtrasAndContradictionsRoot

const ExtrasAndContradictionsRootPropTypes = PropTypes.shape({
  chaptersCount: PropTypes.number,
  client: PropTypes.string,
  contradictoryChapter: PropTypes.string,
  costeGeneral: PropTypes.number,
  costeIndirecto: PropTypes.number,
  createdAt: PropTypes.string,
  createdBy: PropTypes.string,
  description: PropTypes.string,
  discount: PropTypes.number,
  extraChapter: PropTypes.string,
  fase: PropTypes.number,
  favourite: PropTypes.bool,
  finalPrice: PropTypes.number,
  finalPriceWithoutDiscount: PropTypes.number,
  identifierNumber: PropTypes.string,
  igic: PropTypes.number,
  industrialProfit: PropTypes.number,
  isTemplate: PropTypes.bool,
  iva: PropTypes.number,
  name: PropTypes.string,
  orgAddress: PropTypes.string,
  organizationId: PropTypes.string,
  price: PropTypes.number,
  progressPercentage: PropTypes.number,
  projectId: PropTypes.string,
  projectImage: PropTypes.string,
  recalcAt: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  totalDiscount: PropTypes.number,
  updatedAt: PropTypes.string,
  chapters: PropTypes.arrayOf(ChapterPropTypes),
  partsTotal: PropTypes.number,
  chaptersTotal: PropTypes.number,
})

export { ExtrasAndContradictionsRootPropTypes }
