import React from "react"
import PropTypes from "prop-types"
import TableStructureAuto from "./tableStructureAuto"
import PartAuto from "./partAuto"

const PartTableAuto = ({ parts, imParent, total, capitulo }) => {

  return (
    <TableStructureAuto imParent={imParent} total={total} capitulo={capitulo}>
      {parts.map(part => (
        <PartAuto key={part.partidaId} part={part} />
      ))}
    </TableStructureAuto>
  )
}
PartTableAuto.propTypes = {
  parts: PropTypes.array.isRequired,
  imParent: PropTypes.bool,
  total: PropTypes.float,
  capitulo: PropTypes.object,
}
export default PartTableAuto
