import { PoppinsNormal } from "common/typographies";
import BankPricesContext from "context/bankPrices/bankPricesContext";
import i18n from "i18n";
import React, { useContext, useState, useEffect } from "react";
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import AuthContext from "context/auth/authContext";
import ModalSelectProject from "./BancoPrecios/modals/modalSelectProject";
import ModalSuccessFav from "./BancoPrecios/modals/modalSuccessFav";
import ModalPasteSelectionPart from './BancoPrecios/modals/modalPasteSelectionPart';
import { CircularProgress } from "@material-ui/core";

const TopQuickMenu = () => {

    const [open, setOpen] = useState(false);
    const [openChapter, setOpenChapter] = useState(false);
    const {categoriesChecked, partsChecked, deleteCapitulo, deleteBankPart, cleanParts, cleanChapters, myPricesBank, selectedCategories2, getSelectedChaptersToFav, loadingCaps, uncheckAll} = useContext(BankPricesContext)
    const {user, freeTrialExpired} = useContext(AuthContext)

    const [deletingChapters, setDeletingChapters] = useState(false)
    const [deletingParts, setDeletingParts] = useState(false)

    const handleDelete = async() => {
      if(categoriesChecked.length>0){
        setDeletingChapters(true)
        for (let index = 0; index < categoriesChecked.length; index++) {
          await deleteCapitulo(categoriesChecked[index].chapterBankId, user.sub)

      }
      await cleanChapters()
      await cleanParts()
      setDeletingChapters(false)
      }else if(partsChecked.length>0){
        setDeletingParts(true)
        for (let index = 0; index < partsChecked.length; index++) {
            await deleteBankPart(
              partsChecked[index].chapterId,
              partsChecked[index].partidaId,
              partsChecked[index].accountId
            )
          }
        
          await cleanParts()
          await cleanChapters()
          setDeletingParts(false)
      }
    }
    const [openSuccess, setOpenSuccess] = useState(false);
    const [Iclick, setIClick] = useState(false);
   const handleAddToFavorites = async() => {
       await getSelectedChaptersToFav([],[], myPricesBank.subcapitulos, true, user.sub)
        setIClick(true)
   }

   useEffect(() => {
    if(Iclick && loadingCaps===false){
        setOpenSuccess(true)
        setIClick(false)
    }
   }, [Iclick, loadingCaps])

   const changeCheck = e => {
    if (!e) var e = window.event;
    e.cancelBubble = true;
    if (e.stopPropagation) e.stopPropagation();
    if(partsChecked.length > 0 || categoriesChecked.length > 0){
        uncheckAll()
    }
  }

    return(
        <>
    <div style={{position:'fixed', top:'0px', left:'0px', width:'100%', height:'47px', backgroundColor:'#FFD52B', zIndex:'999', paddingTop:'15px'}}>
        <div className="inline">
            <div style={{marginLeft:'10%'}} className="inline">
                <div className="tdcheckColumn">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={partsChecked.length > 0 || categoriesChecked.length > 0? true : false}
                    id="flexCheckDefault"
                    style={{ width: "13px", height: "13px", cursor:'pointer', zIndex:"5", position:"relative"}}
                    onClick={(e)=>{changeCheck(e)}}
                  ></input>
                </div>   
                    {categoriesChecked.length>0 ?
                    categoriesChecked.length==1 ? 
                <PoppinsNormal>{categoriesChecked.length}{" "}{i18n.t("capitulo seleccionado")} </PoppinsNormal>
                :
                <PoppinsNormal>{categoriesChecked.length}{" "}{i18n.t("capitulos seleccionados")} </PoppinsNormal>
                : partsChecked.length>0 ?
                partsChecked.length==1 ?
                <PoppinsNormal>{partsChecked.length}{" "}{i18n.t("partida seleccionada")} </PoppinsNormal>
                :
                <PoppinsNormal>{partsChecked.length}{" "}{i18n.t("partidas seleccionadas")} </PoppinsNormal>
                :
                null
                    }
            </div>
            <div style={{justifyContent:'center', width:'100%', textAlign:'center', position:'absolute', display:'flex', width:'100%', marginLeft:'auto', marginRight:'auto'}}>
                   <BookOutlinedIcon
                className="primerIconoShow" style={{cursor:'', color:'#C4C4C4', width:'24px', marginRight:'3px'  }}/>
                
                <CreateNewFolderOutlinedIcon onClick={()=>{
                  user ? (user['custom:role']==='WORKER' || freeTrialExpired) ? null : setOpen(true) : null }} className="segundoIconoShow" style={{cursor:'pointer', color: user ? (user['custom:role']==='WORKER' || freeTrialExpired) ? '#c4c4c4' : '#414141' : '#c4c4c4', width:'24px', marginRight:'3px'  }}/>
                  {user ? (user['custom:role']==='WORKER' || freeTrialExpired) ? null  :
                <div className="segundoIcono" style={{position: 'absolute', 
                marginLeft:partsChecked.length > 0 && categoriesChecked == 0 ? '-30px' : '-5px', 
                marginTop:'35px'}}>
                    <div className="recuadro">
                            <div className="recuadroPico"></div>
                            {i18n.t("Añadir a proyecto")}
                    </div>
              </div> : null }
              {/*
              {loadingCaps && Iclick ? <CircularProgress style={{marginTop:'3px'}} size={16}/> : <>
                <StarOutlineIcon 
                onClick={()=>{
                  user ? (user['custom:role']==='WORKER' || freeTrialExpired) ? null : handleAddToFavorites() : null
                  }}
                className="tercerIconoShow" style={{color:  user ? (user['custom:role']==='WORKER' || freeTrialExpired) ? '#C4C4C4' : '#414141' : '#C4C4C4', width:'24px', marginRight:'0px', cursor:'pointer'  }}/>
                  {user ? (user['custom:role']==='WORKER' || freeTrialExpired) ? null  :
                <div className="tercerIcono" style={{position: 'absolute', marginLeft:'25px', marginTop:'35px'}}>
                    <div className="recuadro">
                            <div className="recuadroPico"></div>
                            {i18n.t("Añadir a favoritos")}
                    </div>
              </div> : null}
              </>}
               */}
                 {
                deletingChapters || deletingParts 
                ?
                <CircularProgress size={18} style={{color:'#414141', marginLeft:'10px', marginRight:'10px', marginTop:'4px'}}/>
                :
                <DeleteOutlinedIcon onClick={()=>{
                   user ? (user['custom:role']==='WORKER' || freeTrialExpired) ? null : handleDelete() : null
                  }} className="cuartoIconoShow" style={{color:  user ? (user['custom:role']==='WORKER' || freeTrialExpired) ? '#C4C4C4' : '#414141' : '#C4C4C4', width:'24px', marginRight:'3px', cursor:'pointer'  }}/>
                }

                 {user ? (user['custom:role']==='WORKER' || freeTrialExpired) ? null  :
                <div className="cuartoIcono" 
                style={{position: 'absolute', 
                marginLeft:partsChecked.length > 0 && categoriesChecked == 0 ? '25px' : '50px', 
                marginTop:'35px'}}>
                    <div className="recuadro">
                            <div className="recuadroPico"></div>
                            {i18n.t("Eliminar")}
                    </div>
              </div>
              :null }
              {
                partsChecked.length > 0 && categoriesChecked == 0 ? 
                  <ExitToAppIcon onClick={()=>{
                    user ? (user['custom:role']==='WORKER' || freeTrialExpired) ? null : setOpenChapter(true) : null
                  }} className="cuartoIconoShow" style={{color:  user ? (user['custom:role']==='WORKER' || freeTrialExpired) ? '#C4C4C4' : '#414141' : '#C4C4C4', width:'24px', marginRight:'3px', cursor:'pointer'  }}/>
                  : null
              }
                 {user ? (user['custom:role']==='WORKER' || freeTrialExpired) ? null  :

                <div className="cuartoIcono" style={{position: 'absolute', marginLeft:'78px', marginTop:'35px'}}>
                    <div className="recuadro">
                            <div className="recuadroPico"></div>
                            {i18n.t("Mover partida")}
                    </div>

              </div>
         
              : null}
              
            </div>
        
         
        </div>
        
    </div>
    <ModalSelectProject open={open} setOpen={setOpen}/>
    <ModalPasteSelectionPart open={openChapter} setOpen={setOpenChapter} project={myPricesBank}/>
    <ModalSuccessFav open={openSuccess} setOpen={setOpenSuccess}/>
    </>
    )
}
export default TopQuickMenu;