import React, { useContext } from "react"
import PropTypes from "prop-types"
import { PartStyledTable } from "common/tables"
import i18n from "i18n"
import { getCurrency } from "utils/functions"
import AuthContext from "context/auth/authContext"

const TableStructure = ({ children, capitulo, width, ml, bankSelected, tipo, disabled, setDisabled, loadingButton, setLoadingButton, doTheAction, setDoTheAction, chapterId, projectInfo, setOpen, parts}) => {
  const {user} = useContext(AuthContext)
  return (
    <PartStyledTable
      style={{
        marginLeft:  ml+"px" ,
        width:  width+"%",
        marginTop: "0px",
        marginBottom : "0px",
        border: "0px solid #e0e0e0",
      
      }}
    >
      <tr style={{ background: "rgba(217, 217, 217, 0.2)" }}>
        <th style={{ minWidth: "81px" }}></th>
        <th style={{ minWidth: "300px", width: "90%" }}></th>
        <th style={{ minWidth: "88px" }}></th>
        <th style={{ minWidth: "205", fontFamily:"Helvetica" }} colSpan="2">
          {i18n.t("Precio Objetivo")}
        </th>
      </tr>
      <tr style={{ background: "rgba(217, 217, 217, 0.2)" }}>
        <th style={{ minWidth: "81px" , fontFamily:"Helvetica"}}>U</th>
        <th
          style={{
            minWidth: "300px",
            width: "90%",
            textAlign: "left",
            paddingLeft: "33px",
            width: "auto",
            fontFamily:"Helvetica"
          }}
        >
          {i18n.t("Descripción")}
        </th>

        <th style={{ minWidth: "88px",fontFamily:"Helvetica" }}>{i18n.t("Medición")}</th>
        <th
          style={{ minWidth: "100px", textAlign: "left", paddingLeft: "33px", fontFamily:"Helvetica"}}
        >
          {  getCurrency(user['custom:currency'])}/u
        </th>
        <th
          style={{ minWidth: "105px", textAlign: "left", paddingLeft: "33px", fontFamily:"Helvetica" }}
        >
          {i18n.t("Total")}
        </th>
      </tr>
      {children}
      <tr id="lastPartida">
        <td colSpan="5">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "0.5rem",
            }}
          >
           

            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              
            
            </div>
          </div>
        </td>
      </tr>
    </PartStyledTable>
  )
}

TableStructure.propTypes = {
  children: PropTypes.node.isRequired,
  capitulo: PropTypes.object,
  width: PropTypes.number,
  ml: PropTypes.number,
  bankSelected: PropTypes.string,
  tipo: PropTypes.string,
  disabled: PropTypes.bool,
  setDisabled: PropTypes.func,
  loadingButton: PropTypes.bool,
  setLoadingButton: PropTypes.func,
  doTheAction: PropTypes.bool,
  setDoTheAction: PropTypes.func,
  chapterId: PropTypes.string,
  projectInfo: PropTypes.object,
  setOpen: PropTypes.func,
  parts: PropTypes.array
}

export default TableStructure
