import React, { useEffect, useState } from "react"
import {HelveticaBold} from "common/typographies.js" 
import i18n from "i18n";
import PropTypes from "prop-types"

const MedicionesHeader = ({fieldsToHide, unity}) => {
    const [descriptionSize, setDescriptionSize] = useState(40)
    const calculateDescriptionSize = () => {
        if(fieldsToHide.depth && fieldsToHide.width && fieldsToHide.height){
            setDescriptionSize(73)
        }else if(fieldsToHide.depth && fieldsToHide.width){
            setDescriptionSize(62)
        }else if(fieldsToHide.depth && fieldsToHide.height){
            setDescriptionSize(62)
        }else if(fieldsToHide.width && fieldsToHide.height){
            setDescriptionSize(62)
        }else if(fieldsToHide.depth){
            setDescriptionSize(51)
        }else if(fieldsToHide.width){
            setDescriptionSize(51)
        }else if(fieldsToHide.height){
            setDescriptionSize(51)
        }else{
            setDescriptionSize(40)
        }       
    }

    useEffect(() => {
        calculateDescriptionSize()
    }, [fieldsToHide])

    const [width, setWidth] = useState(0);

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth); // Update width on window resize
        };

        window.addEventListener('resize', handleResize);

        // Initial width on component mount
        setWidth(window.innerWidth);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    
    return(
        <div className="inline" style={{width:'100%'}}>
            <div style={{width:descriptionSize+'%', textAlign:'start'}}>
                <HelveticaBold style={{color:'#414141', fontSize:'12px',paddingRight:'10px' }}>{i18n.t("Descripción")}</HelveticaBold>
            </div>
            <div style={{width:'11%', textAlign:'end'}}>
                <HelveticaBold style={{color:'#414141', fontSize:'12px',paddingRight:'10px' }}>
                    {width > 1024 ? i18n.t("Cantidad") : i18n.t("Cant.")}</HelveticaBold>
            </div>
            {
                fieldsToHide.depth ? null :
            <div style={{width:'11%', textAlign:'end'}}>
                <HelveticaBold style={{color:'#414141', fontSize:'12px',paddingRight:'10px' }}>
                    {width > 1024 ? i18n.t("Longitud") : i18n.t("Lon.")}</HelveticaBold>
            </div>
            }
            {
                fieldsToHide.width ? null :
            <div style={{width:'11%', textAlign:'end'}}>
                <HelveticaBold style={{color:'#414141', fontSize:'12px',paddingRight:'10px' }}>
                    {width > 1024 ? i18n.t("Ancho") : i18n.t("An.")}</HelveticaBold>
            </div>
            }
            {
                fieldsToHide.height ? null :
            <div style={{width:'11%', textAlign:'end'}}>
                <HelveticaBold style={{color:'#414141', fontSize:'12px',paddingRight:'10px' }}>
                    {width > 1024 ? i18n.t("Altura") : i18n.t("Alt.")}</HelveticaBold>
            </div>
            }
            <div style={{width:'11%', textAlign:'end'}}>
                <HelveticaBold style={{color:'#414141', fontSize:'12px',paddingRight:'10px' }}>{i18n.t("Total")}</HelveticaBold>
            </div>
        </div>
    )
}

MedicionesHeader.propTypes = {
    fieldsToHide: PropTypes.array.isRequired,
    unity: PropTypes.string
}

export default MedicionesHeader
