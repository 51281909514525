import moment from "moment";

class Invoice{
    constructor(data){
        this.invoiceId = data.invoiceId;
        this.accountId = data.accountId;
        this.invoiceNumber = data.invoiceNumber;
        this.contact = data.contact;
        this.invoiceDate = data.invoiceDate ? moment(data.invoiceDate, "YYYY-MM-DD").format("DD/MM/YYYY"): '';
        this.invoiceDueDate = data.invoiceDueDate ? moment(data.invoiceDueDate, "YYYY-MM-DD").format("DD/MM/YYYY") : '';
        this.invoiceStatus = data.invoiceStatus;
        this.invoiceType = data.invoiceType;
        this.projectId = data.projectId;
        this.name = data.name;
        this.projectInfo = data.projectInfo;
        this.createdAt = data.createdAt;
        this.updatedAt = data.updatedAt;
        this.invoicePaymentMethod = data.invoicePaymentMethod;
        this.invoiceMessage = data.invoiceMessage;
        this.iban = data.iban;
        this.total = data.total %1==0 ?  data.total : parseFloat(data.total).toFixed(2);
        this.subtotal = data.subtotal %1==0 ?  data.subtotal : parseFloat(data.subtotal).toFixed(2);

    }
}
export default Invoice;