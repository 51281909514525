import React from "react";
import {HelveticaBold, PoppinsSemiBold} from "common/typographies.js"
import i18n from "i18n";
import { useContext, useState, useEffect } from "react";
import Capitulo from "./chapters/Capitulo";
import AuthContext from "context/auth/authContext";
import TopQuickMenu3 from "./topQuickMenu3";
import TemplatesContext from "context/templates/templatesContext";
import { useParams } from "react-router-dom";
import DropdownOptions from "./dropdowns/dropdownOptions";
import ImportTemplate from "./modals/importTemplate";
import SuccessImportTemplate from "./modals/successImportTemplate";
import { ArrowBackOutlined } from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import * as amplitude from '@amplitude/analytics-browser';


const TemplateCategories = () => {
    const {getMyBankPrices, myPricesBank, getTemplate, templateInfo, loading, addBankChapter, categoriesChecked, partsChecked, getMyBankPricesWithoutClassify, myNonSpecifiedPricesBank, cleanChapters, cleanParts, selectedCategories2} = useContext(TemplatesContext);
    const {user, getUser, freeTrialExpired, openFreeTrialExpire} = useContext(AuthContext)
    const [cont, setCont] = useState(0)
    const {templateId} = useParams();
    const [openModalImportTemplate, setOpenModalImportTemplate] = useState(false)
    const [openModalImportSuccess, setOpenModalImportSuccess] = useState(false)
    const history = useHistory();
    useEffect(() => {
            cleanChapters()
            cleanParts()
            getMyBankPrices(templateId)
            setCont(1)
    } ,[])

    useEffect(() => {
        if(freeTrialExpired){
            openFreeTrialExpire()
        }
    }, [freeTrialExpired])

    useEffect(() => {
        amplitude.track({
            event_type: 'Template Visited',
            event_properties: { templateId: templateId,  accountEmail: user?.email },
          });
    }, [])
    
    const addChapter   = () => {
        const data = {
            name: null,
            description: null,
            parentId: 'root',
            price: null,
            tags: null,
            accountId: user.sub,
            code:null,
            root:'true'
          }
          addBankChapter(data.parentId, data.projectId, myPricesBank.subcapitulos.length, data.accountId, data.root, templateId)
    }
    
    return(
        <>
        {openModalImportTemplate? <ImportTemplate open={openModalImportTemplate} setOpen={setOpenModalImportTemplate} templateInfo={templateInfo} setOpenSuccess={setOpenModalImportSuccess} /> : null}
        { openModalImportSuccess ?  <SuccessImportTemplate open={openModalImportSuccess} setOpen={setOpenModalImportSuccess} templateInfo={templateInfo} /> : null}
        { 
            loading? 
            <>
            <div style={{ width:'100%', position:'absolute', transform:'translateY(-50%, -50%)',  top:'40%', left:'45%'}}>
            <CircularProgress
                style={{ width: 150, height: 150, marginLeft:'0px', color:'#414141', marginBottom:'20px' }}
            />
            <HelveticaBold style={{color:'#414141', fontSize:'24px', marginLeft:'10px', marginTop:'0px'}}>{i18n.t("Cargando...")}</HelveticaBold>
            </div>
               
        </>
            :
            
            myPricesBank.subcapitulos.length>0?
            <>
            <div className="inline">
            <ArrowBackOutlined onClick={()=>{history.goBack()}} style={{color:'#414141', cursor:'pointer', fontSize:'18px', marginTop:'3px', marginRight:'-10px', marginLeft:'30px'}}/>
                <PoppinsSemiBold style={{fontSize:'16px', marginLeft:'30px', marginBottom:'20px'}}>{templateInfo.name? templateInfo.name : ''}</PoppinsSemiBold>
                <div style={{marginTop:'-5px', marginLeft:'20px'}}>
                    {templateInfo.accountId == 'tribboo' ? null : 
                    <DropdownOptions uploadBC3={setOpenModalImportTemplate}  />
        }
                </div>
            </div>
            {
                            user ? 
                            freeTrialExpired ||  user['custom:role'] === 'WORKER' ? null : <>
            <div style={{marginLeft:'30px'}}>
            {myPricesBank.subcapitulos.map((capitulo, i) => (
              <Capitulo key={capitulo.chapterId} i={i} capitulo={capitulo} indice={i} />
              ))}
           
            </div>
            {templateInfo.accountId=='tribboo' ? null : 
            <div className="NombreAlign">
              <button onClick={()=>{addChapter()}} className="nuevoBotonCrear3" style={{marginTop:'10px',  width:'fit-content', marginBottom:'10px', marginLeft:'30px'}}>
                + {i18n.t("Nueva Categoría")}
              </button>

          </div>}</> : null }
          </>

           
           :
            null
            
        }
           {categoriesChecked.length>0 ? <TopQuickMenu3/> : partsChecked.length>0 ? <TopQuickMenu3/> : null}
        </>
    )
}

export default TemplateCategories;