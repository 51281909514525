import MetaTags from "react-meta-tags"
import React, { useEffect, useContext } from "react"
import authContext from "../../context/auth/authContext"
import { RecordPageView } from "App"
import {
  Container
} from "reactstrap"

import { withRouter } from "react-router-dom"
import GeneralComponent from "components/ProfileRefinament/GeneralComponent"

const GeneralProfile = props => {
  RecordPageView()
  const AuthContext = useContext(authContext)
  const { user, getUser, getOrganizationNew, organizationFields} = AuthContext
  useEffect(() => {
    if(!user) {
      getUser()
    }
    if(user){
      getOrganizationNew(user['custom:organizationId'])
    }
  }, [user])
  


  

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Planhopper | General</title>
        </MetaTags>
        <Container fluid>
          {user && organizationFields ? (
            <GeneralComponent/>
          ) : null}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(GeneralProfile)
