import { CapituloMini } from "common/containers";
import React, {useState, useContext} from "react";
import PropTypes from 'prop-types'
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import BankPricesContext from "context/bankPrices/bankPricesContext";
import AuthContext from "context/auth/authContext";

const CapituloModal = ({capitulo, capituloSelected, setCapituloSelected}) => {
  const [subCapsLoaded, setSubCapsLoaded] = useState(false);
  const {getSubCaps} = useContext(BankPricesContext);
  const {user} = useContext(AuthContext);
    const [openNotSendCapitulo, setOpenNotSendCapitulo] = useState(false)
    const handleOpen = async(e) => {
        if (!e) var e = window.event;
        e.cancelBubble = true;
        if (e.stopPropagation) e.stopPropagation();
        setOpenNotSendCapitulo(prev => !prev)
        if(subCapsLoaded === false){
          await getSubCaps(capitulo.chapterId, user.sub)
          setSubCapsLoaded(true)
        }
      }
      
    return (
        <>
        <CapituloMini onClick={()=>setCapituloSelected(capitulo)} style={{background: capituloSelected.capituloInfo? capituloSelected.capituloInfo.chapterBankId==capitulo.capituloInfo.chapterBankId ? '#FFE992' : 'white': 'white'}}>
            {openNotSendCapitulo ? (
                <div>
                  <KeyboardArrowDownIcon
                    style={{ cursor: "pointer", marginLeft: "5px", marginTop:'5px', visibility: (subCapsLoaded==true && capitulo.subcapitulos.length==0) ? "hidden" : "visible"  }}
                    className="dropdownArrowB"
                    onClick={(e) => handleOpen(e)}
                  />
                </div>
              ) : (
                <KeyboardArrowRightIcon
                  style={{ cursor: "pointer", marginLeft: "5px", marginTop:'5px', visibility: (subCapsLoaded==true && capitulo.subcapitulos.length==0) ? "hidden": "visible" }}
                  className="dropdownArrowB"
                  onClick={(e) => handleOpen (e)}
                />
              )}
              <p>
                {capitulo.capituloInfo.name}
              </p>
          
        </CapituloMini>
        {openNotSendCapitulo ? 
            capitulo.subcapitulos.length > 0 ? (
                capitulo.subcapitulos.map((subcapitulo, index) => (
                    <CapituloModal key={index} capitulo={subcapitulo} capituloSelected={capituloSelected} setCapituloSelected={setCapituloSelected} />
                ))
            ) : null : null}
        </>
    )
}

CapituloModal.propTypes = {
    capitulo: PropTypes.object.isRequired,
    capituloSelected: PropTypes.object.isRequired,
    setCapituloSelected: PropTypes.func.isRequired

}

export default CapituloModal;
