import { PoppinsNormal } from "common/typographies";
import i18n from "i18n";
import React, { useEffect } from "react";
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import PropTypes from 'prop-types'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

const TopQuickBar = ({files, filesChequed, setFilesChequed, partidaState, setPartidaState}) => {

    const uncheckAll = () => {
        setFilesChequed(filesChequed.map((file, index) => false))
    }

    const handleDelete = () => {
        setPartidaState({...partidaState, files: partidaState.files.filter((file, index) => !filesChequed[index])})
        setFilesChequed(filesChequed.filter((file, index) => !filesChequed[index]))
    }

    const [chequedFilesNumber, setChequedFilesNumber] = React.useState(filesChequed.filter((value)=>value === true).length)

    useEffect(() => {
        setChequedFilesNumber(filesChequed.filter((value)=> value === true).length)
    }, [filesChequed])

    const handleDownload = () => {
        files.forEach(async (file, index) => {
            if(filesChequed[index]){
                window.open(file.url, '_blank')
            }
        })
    }

    return(
        <>
    <div style={{position:'fixed', top:'0px', left:'0px', width:'100%', height:'47px', backgroundColor:'#4461D7', zIndex:'999', paddingTop:'15px'}}>
        <div className="inline">
            <div style={{marginLeft:'10%'}} className="inline">
                <div className="tdcheckColumn">
                  <input
                    onClick={() => {uncheckAll()}}
                    className="form-check-input"
                    type="checkbox"
                    checked={true}
                    id="flexCheckDefault"
                    style={{ width: "13px", height: "13px", cursor:'pointer', zIndex:"5", position:"relative" }}
                   
                  ></input>
                </div>   
                    {chequedFilesNumber>0 ?
                    chequedFilesNumber==1 ? 
                <PoppinsNormal style={{color:'white'}}>{chequedFilesNumber}{" "}{i18n.t("archivo seleccionado")} </PoppinsNormal>
                :
                <PoppinsNormal style={{color:'white'}}>{chequedFilesNumber}{" "}{i18n.t("archivos seleccionados")} </PoppinsNormal>
              :
                null
                    }
            </div>
            <div style={{justifyContent:'center', width:'100%', textAlign:'center', position:'absolute', display:'flex', width:'100%', marginLeft:'auto', marginRight:'auto', marginTop:'-1px'}}>
            
          <DownloadForOfflineOutlinedIcon 
                onClick={()=>{handleDownload()}}
                className="tercerIconoShow" style={{color:  'white', width:'24px', marginRight:'5px', cursor:'pointer'  }}/>
                
                <div className="tercerIcono" style={{position: 'absolute', marginLeft:'-30px', marginTop:'35px'}}>
                    <div className="recuadro">
                            <div className="recuadroPico"></div>
                            {i18n.t("Descargar")}
                    </div>
              </div> 

              <DeleteOutlinedIcon onClick={()=>{handleDelete() }} className="cuartoIconoShow" style={{color:  'white', width:'24px', marginRight:'3px', cursor:'pointer'  }}/>
       
                <div className="cuartoIcono" style={{position: 'absolute', marginLeft:'30px', marginTop:'35px'}}>
                    <div className="recuadro">
                            <div className="recuadroPico"></div>
                            {i18n.t("Eliminar")}
                    </div>
              </div> 
            </div>
        </div>
    </div>
    </>
    )
}

TopQuickBar.propTypes = {
    files: PropTypes.array.isRequired,
    filesChequed: PropTypes.array.isRequired,
    setFilesChequed: PropTypes.func.isRequired,
    partidaState: PropTypes.object.isRequired,
    setPartidaState: PropTypes.func.isRequired,
}

export default TopQuickBar;