import React, {useContext, useEffect, useState} from "react";
import {CircularProgress,Modal} from "@mui/material";
import {useParams} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import deleteCap from 'assets/images/budgeting/deleteCap.png'
import i18n from "i18n";
import AuthContext from "context/auth/authContext";
import TemplatesContext from "context/templates/templatesContext";


function getModalStyle() {
    const top = 50 ;
    const left = 50;
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: '630px',
        height: '470px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        borderRadius: 6,
        maxHeight: 'calc(100vh - 100px)',
        overflowY: 'auto',
        overflowX:'hidden',
    },
}));

const ModalDeleteCap = ({ openEliminate, setOpenEliminate, infoCap}) => {
    const [ modalStyle ] = useState(getModalStyle);
    const classes = useStyles();
    const {deleteCapitulo, myPricesBank, getSelectedCategories, selectedCategories, getSelectedChapters, selectedCategories2, uncheckAll}  = useContext(TemplatesContext)
    const [listaAux, setLista] = useState([])
    const {user} = useContext(AuthContext)
    const {templateId} = useParams();
    const [loading, setLoading] = useState(false)
    
    const onClose = ()=> {
        setOpenEliminate(false)
    }
    useEffect(()=>{
        if(openEliminate){
            getSelectedCategories([], myPricesBank.subcapitulos)
            getSelectedChapters([],[], myPricesBank.subcapitulos, true)
        }
      },[openEliminate])
      const [borrado, setBorrado] = useState(false)
      useEffect(()=>{if(borrado){
        setOpenEliminate(false)
      }},[borrado])
      useEffect(()=>{
        if(selectedCategories){
         setLista(selectedCategories)
        }
      },[selectedCategories])

      useEffect(()=>{
      },[myPricesBank])
    const deleteChapterFunc = async() => {
        uncheckAll()
        //La  info  del  capitulo se pasa diferente si es no es root por lo que se hace un if
        setLoading(true)
        if(listaAux.length==0){
            await deleteCapitulo(infoCap.parentId, infoCap.chapterId)
            setBorrado(true)
            
        }else{
            for (let index = 0; index < listaAux.length; index++) {
                await deleteCapitulo(listaAux[index].parentId, listaAux[index].chapterTemplateId)
            }
            setTimeout(()=>{
                setOpenEliminate(false)
                setBorrado(false)
                setLoading(false)
            },1000)
        }  
         
        setOpenEliminate(false)
             
    }

    return(
        <>
            <Modal open={openEliminate} onClose={onClose}>
                <div style={modalStyle} className={classes.paper}>
                    <div id='centerPaco'>
                        <img src={deleteCap} style={{width:'95px', height:'144px'}}/>
                    </div>

                    <div id="DivTitleEliminar2" style={{width:'100%', textAlign:'center', display:'block', marginLeft:'0px'}}><p id="centerTitleEliminar" style={{marginTop:'40px'}}>{i18n.t("Estás a punto de eliminar esta Categoria")}</p></div>
                    
                    <div id="DivTitleEliminar2" style={{width:'100%', textAlign:'center', display:'block', marginLeft:'0px'}}>
                    <p id='subtitledelete'> {i18n.t("Eliminarás las Categorias seleccionados")} <br/> {i18n.t("No podrás recuperar este apartado una vez eliminado.")} </p>
                    </div>
                        <div id='DivTitleEliminar2' style={{marginLeft:'70px', marginBottom:'10px'}}>
                        { 
                            loading ? 
                            <div style={{width:'100%',display:'flex',justifyContent:'center',marginTop:'50px'}}>
                                <CircularProgress size={24}/> 
                            </div>
                            
                            : 
                            <div id="centerButton">
                            <button type="button" style={{width: '190px', marginRight: '15px', borderRadius:'6px'}}
                                className="CancelDel" onClick={onClose}>{i18n.t("Cancelar")}</button>
                            <button type="button" style={{width: '190px', position:'initial', marginTop:'0px', color:'white', backgroundColor:'#BF2929'}}
                                    className="AddProject" id='DeleteButton'  onClick={deleteChapterFunc}>{i18n.t("Eliminar")}</button>
                            </div>
                        }                 
                    </div>
                    
                    
                </div>
            </Modal>
        </>
    )
}

ModalDeleteCap.propTypes = {
    openEliminate : PropTypes.bool.isRequired,
    setOpenEliminate: PropTypes.func.isRequired,
    infoCap: PropTypes.object.isRequired,
}
export default ModalDeleteCap;