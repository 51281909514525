import React, { useState, useContext, useEffect } from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import { CircularProgress, Modal } from "@mui/material"
import ProjectContext from "context/projects/projectContext"
import i18n from "i18n"
import { HelveticaBold } from "common/typographies"
import OtherFormatsDropzone from "components/Dropzone/OtherFormatsDropzone"

function getModalStyle() {
  const top = 50
  const left = 50
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "550px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: 6,
    maxHeight: "calc(100vh - 100px)",
    overflowY: "auto",
    overflowX: "hidden",
  },
}))
const UploadOtherFormatsModal = ({ openModal, setOpenModal, proyecto }) => {
  const [selectedFiles, setSelectedFiles] = useState([])
  const projectContext = useContext(ProjectContext)
  const [modalStyle] = useState(getModalStyle)
  const classes = useStyles()
  const { uploadProjectFromOtherFormats } = projectContext
  const [loadingBtn, setLoadingBtn] = useState(false)

  const onUpload = async () => {
    setLoadingBtn(true)
    await uploadProjectFromOtherFormats(selectedFiles, proyecto.organizationId)
    setOpenModal(false)
    setLoadingBtn(false)
  }

  return (
    <>
      <Modal
        open={openModal}
        onClose={() => {
          null
        }}
      >
        <>
          <div style={modalStyle} className={classes.paper}>
            <div className={"py-4"}>
              <p
                className="text-center my-3"
                style={{
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "22px",
                  lineHeight: "23px",
                  color: "#414141",
                }}
              >
                {i18n.t("¡Importante!")}
              </p>
              <HelveticaBold
                className="text-center my-3"
                style={{
                  fontWeight: "700",
                  lineHeight: "10px",
                  fontSize: "14px",
                  color: "#414141",
                }}
              >
                <strong>
                  Sube aquí archivos Excel, PDF, CSV, TXT, DOC, CAD o Revit.
                </strong>
              </HelveticaBold>
              <HelveticaBold
                className="text-center my-3"
                style={{
                  fontWeight: "500",
                  lineHeight: "23px",
                  fontSize: "14px",
                  color: "#414141",
                  margin: "80px",
                }}
              >
                Una vez lo hayamos recibido, lo subiremos a tu cuenta y
                recibirás un correo cuando esté disponible.
              </HelveticaBold>
              <HelveticaBold
                className="text-center my-3"
                style={{
                  fontWeight: "500",
                  lineHeight: "23px",
                  fontSize: "14px",
                  color: "#414141",
                  margin: "100px",
                }}
              >
                Si tienes alguna duda, nos puedes contactar por WhatsApp desde
                el menú lateral izquierdo o por correo a soporte@planhopper.com
              </HelveticaBold>
              <button
                type="button"
                onClick={() => {
                  setOpenModal(false)
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" style={{ padding: "0 5rem" }}>
              <OtherFormatsDropzone
                selectedFiles={selectedFiles}
                setselectedFiles={setSelectedFiles}
              />
            </div>

            <div id="centerButton">
              {selectedFiles.length == 0 ? (
                <button
                  type="button"
                  className="AddProjectDisabled"
                  style={{ marginTop: "-10px", marginBottom: "30px" }}
                >
                  {i18n.t("Subir")}
                </button>
              ) : !loadingBtn ? (
                <button
                  data-amplify-analytics-on="click"
                  data-amplify-analytics-name="ExcelUploaded"
                  data-amplify-analytics-attrs={selectedFiles}
                  type="button"
                  className="AddContact"
                  onClick={onUpload}
                  style={{ marginTop: "-10px", marginBottom: "30px" }}
                >
                  {i18n.t("Subir")}
                </button>
              ) : (
                <CircularProgress size={24} style={{ marginBottom: "30px" }} />
              )}
            </div>
          </div>
        </>
      </Modal>
    </>
  )
}

UploadOtherFormatsModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  proyecto: PropTypes.object,
}

export default UploadOtherFormatsModal
