import React, {useContext, useEffect, useState} from "react";
import {Modal} from "@mui/material";
import {withRouter} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import { HelveticaBold } from "common/typographies"
import bancoPrecios from "assets/customIcons/banco-precios.svg"
import presupuesto from "assets/customIcons/icon_presupuesto.svg"
import higherThan from "assets/customIcons/ArrowHigherThan.svg"
import i18n from "i18n";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import AuthContext from "context/auth/authContext";
import ProjectContext from "context/projects/projectContext";
import bc3Icon from "assets/customIcons/bc3.svg"

function getModalStyle() {
    const top = 50 ;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: '912px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        borderRadius: 16,
        height:'469px',
        overflowY: 'auto',
        overflowX:'hidden',
    },
}));

const ModalFirstPage = ({open, setOpen}) => {

    // Configuración del modal para añadir contactos
    const [ modalStyle ] = useState(getModalStyle);
    const classes = useStyles();
    const history = useHistory();
    const {updateUser, user, getUser} = useContext(AuthContext)
    const [convertedBC3, setConvertedBC3] = useState(false)
    const {projects} = useContext(ProjectContext)
 
    useEffect(()=>{
        if(!user){
            getUser()
        }else{
            if(user['custom:utm_source'] === 'bc3_conversor'){
                setConvertedBC3(true)
            }
        }
    },[user])

    useEffect(()=>{
        if(projects.length > 0){
            getLastProject()
        }
    },[projects])

    const [projectId, setProjectId] = useState('')

    const getLastProject = () => {
        let auxProjects = projects.sort(function(a,b){return new Date(b.createdAt) - new Date(a.createdAt)})
        setProjectId(auxProjects[0].projectId)
    }
    
    const goToFirstPage = async(route) => {
        let userInfoParsed = {
            "custom:firstPageSelected": 'true'
        }
        await updateUser(userInfoParsed)
        if(route === '/dashboard'){
            window.location.reload()
        }else{
            history.push(route)
        }

    }

    const goToConversedBC3 = async() => {
        let userInfoParsed = {
            "custom:firstPageSelected": 'true'
        }
        await updateUser(userInfoParsed)
        history.push('/budgeting/'+projectId)
    }

    return(
                <Modal open={open} onClose={null}>
                    <div style={{...modalStyle, height:convertedBC3 && '325px'}} className={classes.paper}>
                        <div style={{display:'grid', justifyContent:'center', textAlign:'center'}}>
                            <HelveticaBold style={{fontSize:'42px', fontWeight:'700', color:'#414141', marginTop:'10px', marginBottom:'-5px'}}>
                                {i18n.t("¡Bienvenido a Planhopper!")}
                            </HelveticaBold>
                            <HelveticaBold style={{fontSize:'22px', color:'#41414199', fontWeight:'400'}}>
                                { convertedBC3 ?  i18n.t("Has convertido un archivo BC3") : i18n.t("¿Cómo te gustaría empezar?")}
                            </HelveticaBold>
                            {convertedBC3 ? <>
                                <div className="CardProjects" onClick={()=>{goToConversedBC3()}}  style={{marginTop:'30px', cursor:'pointer', width:'580px', marginLeft:'0px', height:'110px',border:'1PX SOLID #C4C4C4' }} >
            <div id="CardContent">
                <div className="ImgProyect" style={{marginLeft:'10px', marginRight:'20px', marginTop:"-3px"}}>
                    <span>
                        <img src={bc3Icon}  height="75px" width='150px' />
                    </span>
                    </div>
                    <div id="CenterContentProyectCard" style={{marginLeft: '115px', marginRight:'20px', textAlign:'left', fontSize:'14px'}}>
                        <div className="flex-grow-1 overflow-hidden" style={{marginRight:'-10px'}}>
                            <div id="AddProyecto" style={{fontSize:'18px', marginTop:'-5px'}}>
                                    {i18n.t("Ver mi archivo bc3 convertido")}
                            </div>
                            <p className="DescriptionText" style={{fontSize:'14px', marginRight:'140px'}}>Consulta tu nuevo proyecto con los datos importados desde el bc3</p>
                        </div>
                        <img src={higherThan} style={{width:'24px', height:'15px', marginTop:'22px' }}/>
                    </div>
            </div>
                    </div>
                            </> : 
                    <>
                    <div className="CardProjects" onClick={()=>{goToFirstPage('/bancodeprecios/select')}}  style={{marginTop:'30px', cursor:'pointer', width:'580px', marginLeft:'0px', height:'110px',border:'1PX SOLID #C4C4C4' }} >
            <div id="CardContent">
                <div className="ImgProyect" style={{marginLeft:'10px', marginRight:'20px', marginTop:"-3px"}}>
                    <span>
                        <img src={bancoPrecios}  height="75px" width='150px' />
                    </span>
                    </div>
                    <div id="CenterContentProyectCard" style={{marginLeft: '115px', marginRight:'20px', textAlign:'left', fontSize:'14px'}}>
                        <div className="flex-grow-1 overflow-hidden" style={{marginRight:'-10px'}}>
                            <div id="AddProyecto" style={{fontSize:'18px', marginTop:'-5px'}}>
                                    {i18n.t("Explorar bancos de precios")}
                            </div>
                            <p className="DescriptionText" style={{fontSize:'14px', marginRight:'140px'}}>Consulta las bases de precios de Cype, Bedec o Preoc.</p>
                        </div>
                        <img src={higherThan} style={{width:'24px', height:'15px', marginTop:'22px' }}/>
                    </div>
            </div>
                    </div>
                    <div className="CardProjects" onClick={()=>{goToFirstPage('/dashboard')}}   style={{marginTop:'10px',  cursor:'pointer', marginLeft:'0px', width:'580px', height:'110px',border:'1PX SOLID #C4C4C4' }} >
                        <div id="CardContent">
                            <div className="ImgProyect" style={{marginLeft:'10px', marginRight:'20px', marginTop:"-3px"}}>
                                <span>
                                    <img src={presupuesto}  height="75px" width='150px' />
                                </span>
                                </div>
                                <div id="CenterContentProyectCard" style={{marginLeft: '115px', marginRight:'20px', textAlign:'left', fontSize:'14px'}}>
                                    <div className="flex-grow-1 overflow-hidden" style={{marginRight:'-10px'}}>
                                        <div id="AddProyecto" style={{fontSize:'18px', marginTop:'-5px'}}>
                                                {i18n.t("Crear un presupuesto")}
                                        </div>
                                        <p className="DescriptionText" style={{fontSize:'14px', marginRight:'140PX'}}>Empieza a trabajar en tu presupuesto y adáptalo a tus necesidades.</p>
                                    </div>
                                    <img src={higherThan} style={{width:'24px', height:'15px', marginTop:'22px' }}/>
                                </div>
                        </div>
                    </div>
                    </>
                    }
        </div>
                    </div>
                </Modal>
    )
}

ModalFirstPage.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
  
}
export default withRouter(ModalFirstPage);