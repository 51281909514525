import React, {useContext, useRef, useState} from "react";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import {Modal} from "@mui/material";
import {makeStyles} from "@material-ui/core/styles";
import i18n from "i18n";
import { Document, Page, pdfjs } from 'react-pdf'
import {PoppinsNormal, PoppinsWhite, PoppinsSemiBold, PoppinsGrey} from 'common/typographies'
import { BlueButton } from 'common/containers';
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import ProjectContext from "context/projects/projectContext";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


function getModalStyle() {
    const top = 50 ;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: '640px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: "0px 3px 5px -1px rgb(0 0 0 / 7%), 0px 5px 8px 0px rgb(0 0 0 / 7%), 0px 1px 14px 0px rgb(0 0 0 / 7%)",
        padding: theme.spacing(2, 4, 3),
        borderRadius: 6,
        height: '700px',
        maxHeight: 'calc(100vh - 20px)',
        borderLeft: '5px solid #FFD52B',
        overflowY: 'hidden',
    },
}));

const ModalPreviewPDF = ({open, setOpen, pdf, loadingPdf, actNum}) => {
    const [ modalStyle ] = useState(getModalStyle);
    const classes = useStyles();
    const [numPages, setNumPages] = useState(null);
    const [notRender, setNotRender] = useState(true);
    const componentRef = useRef();
    const {projectInfo} = useContext(ProjectContext);

    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
    }


    return (
    <>
  <Modal open={open} onClose={()=>{setOpen(false)}}>
        <div style={modalStyle} className={classes.paper}>
            <CloseIcon id="cerrar" onClick={()=>{setOpen(false)}}style={{marginTop:'0px', paddingLeft:'6px', marginRight:'-10px', size:'7px'}}></CloseIcon>
             <div className="inline" style={{position:'relative'}}>
                <div style={{width:'80%', display:'grid', marginTop:'10px', marginRight:'0px'}}>
                    <PoppinsSemiBold style={{fontSize:'18px', fontWeight:'500'}}>{i18n.t('Acta')+' '+actNum}</PoppinsSemiBold>
                    <PoppinsGrey style={{marginTop:'-5px'}}>{i18n.t('Proyecto')+' - '+projectInfo?.name}</PoppinsGrey>
                </div>
                <BlueButton onClick={()=>window.open(pdf)} className='inline' style={{width:'114px', marginTop:'37px', position:'absolute', right:'0px'}} >
                    <DownloadForOfflineOutlinedIcon style={{color:'white', width:'16px', marginTop:'-2px', marginLeft:'7px', marginRight:'7px'}}/>
                    <PoppinsWhite>{i18n.t("Descargar")} </PoppinsWhite>
                </BlueButton>
            </div> 
            {loadingPdf ? 
            <>
                   <div style={{justifyContent:'center', width:'100%', textAlign:'center', position:'relative', display:'flex', marginLeft:'-100px', marginTop:'90px'}}>
                   <div className='loader'>
                       <div className='ball'></div>
                       <div className='ball'></div>
                       <div className='ball'></div>
                  </div>
                   </div>
                   <div style={{justifyContent:'center', width:'100%', textAlign:'center', position:'relative', display:'flex', marginLeft:'-30px'}}>
                   <PoppinsNormal>{i18n.t("Cargando PDF...")}</PoppinsNormal>
                   </div>
                   <div style={{justifyContent:'center', width:'100%', textAlign:'center', position:'relative', display:'flex', marginLeft:'-30px', marginTop:'-15px'}}>
                   <PoppinsNormal style={{fontWeight:'400'}}>{i18n.t("Esta acción puede tardar unos segundos")}</PoppinsNormal>
                   </div>
                   </>
             :   
             <> 
             <div style={{ height:'95.7%',minHeight:'85vh', maxHeight:'90vh', display:'flex', justifyContent:'center', backgroundColor:'#D9D9D9', borderBottom:'1px solid #EBEBEB', overflowY:'auto'}}>
             <Document  ref={componentRef} file={pdf} onLoadError={console.error} style={{overflowY:'auto'}} onLoadSuccess={onDocumentLoadSuccess}>
                {
               [...Array(numPages)].map((elementInArray, index) => ( 
                 <Page key={index} pageNumber={index + 1} />
                 ) 
             )}
                
             </Document>
             </div>
             
             </>
        }
         </div>
    </Modal>
                    
    </>
    )
}
 ModalPreviewPDF.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    pdf: PropTypes.string.isRequired,
    loadingPdf: PropTypes.bool.isRequired,
    actNum: PropTypes.number.isRequired,
};

export default ModalPreviewPDF;