import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { PoppinsGrey } from "common/typographies"
import BankPricesContext from "context/bankPrices/bankPricesContext"
import BankPricesReferenceContext from "context/referenceBankPrices/BankPricesReferenceContext"
import { currencyFormatDE } from "lib/transformers"
import AuthContext from "context/auth/authContext"
import { getCurrency } from "utils/functions"

const Part = ({ part, bankSelected}) => {
  const [total, setTotal] = useState(0)
  const {checkPartida, updatePartsList, checkMultipleParts} = useContext(bankSelected == 'User database' ? BankPricesContext : BankPricesReferenceContext)

  const {user} = useContext(AuthContext)
  useEffect(() => {
    if (part) {
      setTotal(
        parseFloat(parseFloat(part.quantity? part.quantity : 0) * part.userPrice? part.userPrice : part.price).toFixed(2)
      )
    }
  }, [])


  const [imChecked, setImChecked] = useState(false)

  const handleCheckBox = (e) => {
      if(e.nativeEvent.shiftKey){
        if(part.checked){
          checkPartida(part.chapterId, part.partidaId)
          updatePartsList(part)
        }else{
          checkMultipleParts(part.chapterId, part)
          
        } 
      }else{
        checkPartida(part.chapterId, part.partidaId)
        updatePartsList(part)
      }
      setImChecked(!imChecked)
  }



  return (
    <tr  style={{cursor: "pointer"}} onClick={(e) => handleCheckBox(e)}>
      <td style={{fontFamily:"Helvetica"}}>
        <div className="inline" style={{marginLeft:'-10px', marginBottom:'-3px'}}>
      <input
              type={"checkbox"}
   
              checked={bankSelected == 'User database' ? imChecked : part.checked}
              style={{
                width: "13px",
                marginRight: "10px",
                cursor: "pointer",
                marginTop: "0px",
              }}
            ></input>
        <PoppinsGrey style={{fontWeight:'300', paddingTop:'10px', fontSize:'13px'}}>{part.unity}</PoppinsGrey>
        </div>
        </td>
      <td style={{fontWeight:'300'}}>{part.name}</td>

      <td style={{fontFamily:"Helvetica"}}>{part.userPrice? currencyFormatDE(parseFloat(part.userPrice),getCurrency(user['custom:currency'])) : currencyFormatDE(parseFloat(part.price),getCurrency(user['custom:currency']))}</td>
    
    </tr>
  )
}
Part.propTypes = {
  part: PropTypes.object.isRequired,
  bankSelected: PropTypes.string.isRequired,

}
export default Part
