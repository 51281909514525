import React from "react"
import i18n from "i18n"
import { CircularProgress } from "@mui/material"
import { HelveticaBold } from "./typographies"

const Loader = () => {
  return (
    <main>
      <section
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress
          style={{
            width: 150,
            height: 150,
            marginLeft: "0px",
            color: "#414141",
            marginBottom: "20px",
          }}
        />
        <HelveticaBold
          style={{
            color: "#414141",
            fontSize: "24px",
            marginLeft: "10px",
            marginTop: "0px",
          }}
        >
          {i18n.t("Cargando...")}
        </HelveticaBold>
      </section>
    </main>
  )
}

export default Loader
