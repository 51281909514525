
import {Modal} from "@mui/material";
import {makeStyles} from "@material-ui/core/styles";
import React, { useState, useContext, useEffect } from "react";
import ProjectContext from 'context/projects/projectContext';
import PropTypes from 'prop-types'
import i18n from "i18n";
import { useProgress } from "hooks/useProgress";
import { useHistory} from 'react-router-dom';
import * as amplitude from '@amplitude/analytics-browser';


function getModalStyle() {
    const top = 50 ;
    const left = 50;
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };

}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: '500px',
        height: '425px',
        backgroundColor: 'white',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        borderRadius: 6,
        maxHeight: '500px',
        overflowY: 'auto',
        overflowX:'hidden',
        paddingLeft:'0px',
        paddingRight:'0px'
    },
}));

const WaitingModal = ({openModal, setOpenModal, setSuccessModal, setSeleccionado, project, imTemplate}) =>{
  const [ modalStyle ] = useState(getModalStyle);
  const history = useHistory();
  const classes = useStyles();
  const projectContext = useContext(ProjectContext);
  const { uploadProcess} = projectContext
  const importedProject = useProgress({projectId: project.projectId, accountId: project.createdBy})
  useEffect(()=>{
    if(importedProject){
        if(importedProject.importStatus === 'COMPLETED'){
            setTimeout(() => {
                amplitude.track('BC3 Imported');
                if(imTemplate){ history.push("/bancodeprecios/templateV2/"+res.projectId)}
                else{
                history.push('/budgeting/'+importedProject.id)}
                //setSuccessModal(true)
                //setOpenModal(false)
            }, 1000);
        }
    }
    //   if(uploadProcess){
    //       if (uploadProcess.upload_success === true) {
    //           setSuccessModal(true)
    //           setOpenModal(false)
    //       }
    //   }
      
  },[importedProject])

return(
    <>
  <Modal
      open={openModal}
      onClose={() => {null}}
  >
      <div style={modalStyle} className={classes.paper}>
      <div className="modal-body">
      <div>
          <div>
       
              <p
                  style={{
                  fontSize: '20px',
                  fontWeight: '500',
                  textAlign: 'center',
                  fontFamily: "Helvetica",
                  marginTop:'30px'
                  }}
              
                  >{i18n.t("Estamos construyendo tu")}
                  <br/>  {i18n.t("presupuesto y planning...")}
                </p>
                <p
                  style={{
                      fontStyle: 'normal',
                      fontWeight: '300',
                      fontSize: '14px',
                      lineHeight: '16px',
                      textAlign: 'center',
                      fontFamily: "Helvetica"
                  }}
              >Esto puede tomar un minuto. Por favor no te
                <br/>  vayas, ni cierres esta pestaña
                <br/> Creando {importedProject.chapters ? importedProject.chapters : 0} capítulos con 
                un total de {importedProject.parts ? importedProject.parts : 0} partidas
              </p>
              <div className='loader'>
                    <div className='ball'></div>
                    <div className='ball'></div>
                    <div className='ball'></div>
               </div>
             
          </div>
          </div>
      </div>
      </div>
  </Modal>
  </>
)
}
WaitingModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  setSuccessModal: PropTypes.func.isRequired,
  setSeleccionado: PropTypes.func.isRequired,
  project: PropTypes.object,
    imTemplate: PropTypes.bool
}
export default WaitingModal