import React, { useContext, useEffect, useRef } from "react"
import { Modal} from "@mui/material"
import { makeStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import { useState } from "react"
import { v4 as uuidv4 } from "uuid"
import UnityDropdown from "components/TablaPartida/UnityDropdown"
import i18n from "i18n"
import AuthContext from "context/auth/authContext"
import BankPricesReferenceContext from "context/referenceBankPrices/BankPricesReferenceContext"
import { getCurrency } from "utils/functions"

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "fit-content",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: 6,
    maxHeight: "calc(100vh - 100px)",
    overflowY: "auto",
    overflowX: "hidden",
  },
}))

const ModalCreateBankPart = ({ open, setOpen, capitulo, parts }) => {
  const [modalStyle] = useState(getModalStyle)
  const classes = useStyles()
  const [unity, setUnity] = useState('pa')
  const {user} = useContext(AuthContext)
  const [total, setTot] = useState(0)
  const [data, setData] = useState({
    unity: "pa",
    description: "",
    price: "",
    userPrice: "",
    name:"",
    code: uuidv4(),
    accountId: user ? user.sub? user.sub : '' : '',
    chapterBankId: capitulo.chapterId ? capitulo.chapterId : '',
    tags:[],
    userPrice: 0,
    position: parts? parts.length : 0,
  })
  const {createPartBankTree} = useContext(BankPricesReferenceContext)
  useEffect(()=> {
      if(user && capitulo){
        setData({...data, accountId: user.sub, chapterBankId: capitulo.chapterId})
      }
  },[user, capitulo])
                   
  useEffect(()=>{
    setData({
      ...data,
      unity: unity
    }
    )
  },[unity])

  useEffect(()=>{
    if(parts){
      setData({...data, position: parts.length})
    }
  },[parts])
                 
  const referenciaParti = useRef(null)
  const handleChange = e => {
    if (e.target.className == "textareaExpand5") {
      setData({
        ...data,
        description: referenciaParti.current.outerText,
      })
    } else {
      if (e.target.name == "unidades") {
        if (e.target.value < 0) {
          setData({
            ...data,
            [e.target.name]: e.target.value * -1,
          })
        } else {
          setData({
            ...data,
            [e.target.name]: e.target.value,
          })
        }
      } else if (e.target.name == "price") {
        if (e.target.value < 0) {
          setData({
            ...data,
            [e.target.name]: parseFloat(e.target.value * -1),
            precioRef: parseFloat(e.target.value * -1),
            userPrice: parseFloat(e.target.value * -1),
            total: data.unidades!= "" ?  parseFloat(e.target.value *-1 * data.unidades) : 0
          })
        } else {
          setData({
            ...data,
            [e.target.name]: parseFloat(e.target.value),
            precioRef: parseFloat(e.target.value),
            userPrice: parseFloat(e.target.value),
            total: data.unidades!= "" ?  parseFloat(e.target.value* data.unidades) : 0
            
          })
        }
      } 
      else {
        setData({
          ...data,
          [e.target.name]: e.target.value,
        })
      }
    }
  }
 const [isValid, setValid] = useState(true)
const [doIt, setDoIt] = useState(false)

useEffect(()=>{
  if(doIt){
    createPartBankTree(data)
    setOpen(false)
    setDoIt(false)
  }
},[doIt, data])

  const handleCrear = async() => {
    if (
      data.name != ""
    ) {
      setData({
        ...data,
        unidades: data.unidades=='' ? 0 : data.unidades,
        description: data.description=='' ? " " : data.description,
        price: data.price=='' ? parseFloat(0) : data.price,
        precioRef: data.precioRef=='' ? parseFloat(0): data.precioRef,
        userPrice: data.userPrice=='' ? parseFloat(0) : data.userPrice,
        total:  data.total==''? 0 : data.total
      })
      setDoIt(true)
    }else{
     setValid(false)
      }

    }
  

  useEffect(() => {
   if(data.name.length>0){
    setValid(true)
   }
  }, [data.name])
  


  
  return (
    <>
    <Modal open={open} onClose={() => null}>
      <div style={modalStyle} className={classes.paper}>
    
        <table className="tablaPartidas" style={{ marginLeft: "0px" }}>
          <tr style={{ borderBottom: "1px solid #B7B7B7 !important" }}>
            <th
              style={{
                minWidth: "78px",
                paddingLeft: "15px",
                borderLeft: "none",
                backgroundColor: "#F6F9FC",
              }}
            ></th>
            <th
              style={{
                minWidth: "690px",
                textAlign: "left",
                paddingLeft: "57px",
                borderLeft: "none",
                backgroundColor: "#F6F9FC",
              }}
            ></th>
            <th style={{ minWidth: "125px" }}>
            {i18n.t("Precio Objetivo")}
            </th>
          </tr>
          <tr id="subCategory" style={{ borderBottom: "1px solid #B7B7B7" }}>
            <td style={{ borderLeft: "none", width: "79px", color: "#414141" }}>
            {i18n.t("Unidad")}
            </td>
            <td style={{ textAlign: "left", paddingLeft: "35px" }}>
            {i18n.t("Título")}
            </td>
            <td
              style={{
                minWidth: "127px",
                color: "#414141",
                backgroundColor: "#CCD8FC",
              }}
            >
                {
                getCurrency(user['custom:currency'])
              }/u
            </td>
          </tr>

          <tr
            className="partidasInfo"
            style={{ borderBottom: "1px solid black" }}
          >
            <td style={{ borderLeft: "none", width: "79px" }}>
              <div
                className="inline"
                style={{ marginLeft: "0px", marginTop: "15px", width:'100%'}}
              >
            <UnityDropdown setUnity={setUnity} unity={unity}/>
              </div>
            </td>
            {isValid ? 
            <td>
            <input 
                  style={{
                    width:'100%',
                    paddingTop: "10px",
                    color: "#414141",
                    fontWeight: "400",
                    textAlign:'left',
                    justifyContent:'left',
                    paddingLeft:'35px'
                  }}
                  name="name"
                  id="inputUnidades"
                  type={"text"}
                  value={data.name}
                  placeholder={i18n.t("Introduce el título")}
                  onChange={handleChange}
                />
            </td>:
                 <td style={{border:'1px solid red'}}>
                 <input 
                       style={{
                         width:'100%',
                         paddingTop: "22px",
                         color: "#414141",
                         fontWeight: "400",
                         textAlign:'left',
                         justifyContent:'left',
                         paddingLeft:'35px',paddingBottom:'25px',
                         borderBottom:'1px solid red',
                         borderRadius:'0px'
                       }}
                       name="name"
                       id="inputUnidades"
                       type={"text"}
                       value={data.name}
                       placeholder={i18n.t("Introduce el título")}
                       onChange={handleChange}
                     />
                 </td>}
            <td style={{ minWidth: "127px", color: "#414141" }}>
              <div>
                <input
                  style={{ color: "#414141", fontWeight: "400" }}
                  id="inputUnidades"
                  type="number"
                  value={data.price}
                  onChange={handleChange}
                  name="price"
                  placeholder="   Precio unitario"
                />
              </div>
            </td>
           
          </tr>
          <tr>
          <td style={{color:'#414141', fontWeight:'500', fontFamily:"Helvetica", paddingLeft:'3px', paddingRight:'3px'}}>
            </td>
            <td colSpan='2' style={{color:'#414141', fontWeight:'600', fontFamily:"Helvetica", paddingLeft:'3px', paddingRight:'3px', textAlign:'left', paddingLeft:'36px', }}>
            {i18n.t("Descripción")}
            </td>
          </tr>
          <tr>
            <td></td>
          <td colSpan='2'>
              <p
                style={{
                  display: "block",
                  textAlign: "left",
                  marginBottom: "0px",
                }}
              >
                {" "}
                <span style={{maxWidth:'100% !important'}}
                  ref={referenciaParti}
                  className="textareaExpand5"
                  role="textbox"
                  autoComplete="off"
                  name="description"
                  onInput={handleChange}
                  contentEditable
                ></span>
              </p>
            </td>
          </tr>
          <tr>
            <td colSpan="3">
              <button
                style={{
                  marginLeft: "880px",
                  position: "absolute",
                  width: "102px",
                }}
                className="PartidaManual"
                onClick={() => {
                  setOpen(false)
                }}
              >
                Cancelar
              </button>
              <button
                style={{ marginLeft: "1000px", width: "102px", marginRight:'30px' }}
                className="PartidaManual"
                onClick={() => {
                  handleCrear()
                }}
              >
                Crear
              </button>
            </td>
          </tr>
        </table>
      </div>
    </Modal>
   
    </>
  )
}

ModalCreateBankPart.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  capitulo: PropTypes.object.isRequired,
  parts: PropTypes.array,
}
export default ModalCreateBankPart
