import React, { useContext, useEffect } from "react"
import PropTypes from "prop-types"
import { useHistory } from "react-router-dom"
import { Route, Redirect } from "react-router-dom"
import i18n from "i18n"
import * as amplitude from '@amplitude/analytics-browser';

import AuthContext from "context/auth/authContext"
import SubscriptionContext from "context/subscription/subscriptionContext"
import TopAdviceNavBar from "components/UserView/topAdviceNavBar"

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  navContent: navContent,
  ...rest
}) => {
    const {user, showSnackbar, form} = useContext(AuthContext)
    const {subscriptionState, isTrial, noSubscription, daysLeft, showTopAdviceBar, setShowTopAdviceBar} = useContext(SubscriptionContext)
    const history = useHistory()

    const changeLanguage = (lang) => {
      i18n.changeLanguage(lang)
    }

    useEffect(() => {
      console.log(`user = ${JSON.stringify(user)}`)
      if (isAuthProtected && user) {
        amplitude.setUserId(user.email);
        const identifyEvent = new amplitude.Identify();
        identifyEvent.set('subscription_plan', user['custom:subscription_plan']);
        amplitude.identify(identifyEvent);
        if (user['custom:onboarding'] !== 'true') {
          if (form == false) {
            return (history.push('/onboarding'))
          }
        }
        if (user['custom:idioma']) {
          changeLanguage(user['custom:idioma'])
        } else {
          i18n.changeLanguage('ES')
        }
      }
    }, [user])

  return(
  <>
  <Route
    {...rest}
    render={props => {
      if (isAuthProtected && !localStorage.getItem("userAuth")) {
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }

      return (

        <Layout navContent={navContent}>
          {user && subscriptionState && showTopAdviceBar ? <TopAdviceNavBar/> : null}
          <Component {...props} />
        </Layout>
      )
    }}
  />
  </>)
}

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
  navContent: PropTypes.any
}

export default Authmiddleware;
