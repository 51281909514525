import React, {useContext, useRef, useState, useEffect} from "react";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import {Modal} from "@mui/material";
import {makeStyles} from "@material-ui/core/styles";
import CloseBud from 'assets/images/budgeting/CloseBud.png'
import i18n from "i18n";

function getModalStyle() {
    const top = 50 ;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: '630px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: "0px 3px 5px -1px rgb(0 0 0 / 7%), 0px 5px 8px 0px rgb(0 0 0 / 7%), 0px 1px 14px 0px rgb(0 0 0 / 7%)",
        padding: theme.spacing(2, 4, 3),
        borderRadius: 6,
        maxHeight: 'calc(100vh - 100px)',
        overflowY: 'auto',
        overflowX:'hidden',
        height:'400px',
    },
}));



const ModalConfirm = ({open, setOpen}) => {
    const [ modalStyle ] = useState(getModalStyle);
    const classes = useStyles();

    return (
   <>
   <Modal open={open} onClose={()=>{setOpen(false)}}>
            <div id="totalDiv">
                <div style={modalStyle} className={classes.paper}>
                <CloseIcon id="cerrar" onClick={()=>{setOpen(false)}}style={{marginTop:'0px', paddingLeft:'6px', marginRight:'-10px'}}></CloseIcon>
                    <div style={{justifyContent:'center', display:'flex', marginTop:'45px'}}>
                    <img src={CloseBud} style={{width:'117px', height:'117px'}}/>
                    
                    </div>
                   
                    <div id="DivTitleEliminar" style={{marginTop:'15px', width:'100%' , justifyContent:'center', display:'flex'}}>
                        <p id="centradoBudget">
                        {i18n.t("Tu contraseña ha sido cambiada exitosamente")}
                         </p>
                    </div>
                   
                    <div id="centerButton" style={{marginTop:'15px'}}><button type="button"
                             className="AddContact" onClick={()=>{setOpen(false)}}>{i18n.t("Cerrar")}</button></div>
                    </div>
            </div>
        </Modal>
        
        </>
    );
}

ModalConfirm.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired
};

export default ModalConfirm;