import React, { useState } from "react"
import PropTypes from "prop-types"
import { CircularProgress, Modal, Typography } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { makeStyles } from "@material-ui/core/styles"

const getModalStyle = () => {
  const top = 50
  const left = 50
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "absolute",
    width: "522px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 6,
    maxHeight: "calc(100vh - 100px)",
    overflowY: "auto",
    overflowX: "hidden",
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "50px",
  },
  subTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "40px",
  },
  input: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "40px",
  },
  list: {
    marginTop: "40px",
  },
  closeButton: {
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(2),
    cursor: "pointer",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(2),
  },
}))

const MovePartsToSubchapterModal = ({ open, setOpen, callback }) => {
  const [modalStyle] = useState(getModalStyle)
  const [btnLoading, setBtnLoading] = useState(false)
  const [selectedChapter, setSelectedChapter] = useState(null)
  const classes = useStyles()

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false)
      }}
    >
      <div style={modalStyle} className={classes.paper}>
        <div className={classes.closeButton}>
          <CloseIcon
            onClick={() => {
              setOpen(false)
            }}
          />
        </div>
        <Typography variant="h5" gutterBottom className={classes.subTitle}>
          La(s) partida(s) se moverán a un nuevo subcapítulo.
          ¿Estás seguro de que deseas continuar?
        </Typography>

        <div className={classes.buttonContainer}>
          {btnLoading ? (
            <CircularProgress size={24} />
          ) : (
            <button
              type="submit"
              onSubmit={() => {
                setBtnLoading(true)
                callback(selectedChapter)
              }}
              onClick={() => {
                setBtnLoading(true)
                callback(selectedChapter)
                setBtnLoading(false)
              }}
              className="AcceptButton"
              data-amplify-analytics-on="click"
              data-amplify-analytics-name="ProjectCreated"
            >
              Aceptar
            </button>
          )}
        </div>
      </div>
    </Modal>
  )
}


MovePartsToSubchapterModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
}

export default MovePartsToSubchapterModal
