import React, { Fragment, useRef, useState, useEffect, useContext } from "react"
import propTypes from "prop-types"
//ICONS
import ArrowDownIcon from "assets/customIcons/ArrowDownIcon.svg"
import ArrowUpIcon from "assets/customIcons/ArrowUpIcon.svg"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import i18n from "i18n"

//MODALS
import ModalDeleteCap from "./modals/ModalDeleteCap"

//CONTEXT & HOOKS
import Budget2023Context from "context/budget2023/Budget2023Context"
import AuthContext from "context/auth/authContext"

//COMPONENTS
import TablaPartida from "./TablaPartida/index"

//FUNCTIONS
import { currencyFormatDE } from "lib/transformers"
import { useParams } from "react-router-dom"
import { getChapterParentId2 } from "utils/budgetUtils"
import NewModalPartida from "pages/ModalPartidas/NewModalPartida"
import { CircularProgress } from "@material-ui/core"

import { getCurrency } from "utils/functions"
import { isNil } from "lodash"
import ModalImportPartFromBc3Link from "pages/ModalPartidas/ModalImportPartFromBc3Link"
import MovePartsToSubchapterModal from "./modals/MovePartsToSubchapterModal"

const NewCapitulo = ({
  imTemplate,
  capitulo,
  indice,
  desplazamiento,
  preferencias,
  setPreferencias,
  numeroClases,
  numeroIndex,
  lengthSubCap,
  soyMargen,
  isChecked2,
  setIsChecked2,
  soyFinal,
  projectInfo,
  modificado,
  setClose,
  modificarMargen,
  setModificarMargen,
  ocultoFinal,
  setOcultoFinal,
  isChecked,
  setIsChecked,
  adjustWidth,
  priceWidth,
  status,
  version,
  certificaciones,
}) => {
  const {
    updateIsExpanded,
    addCapituloV2,
    updateCapitulov2,
    getSubCapitulosContent,
    moveChaptersWithArrows,
    isUpdates,
    changeSidebar,
    checkMultipleChapters,
    budgetTree,
    imChecked2,
    updateChaptersList,
    checkCapitulos,
    bc3LinkData,
    toggleModalImportPartFromBc3Link,
    openModalImportPartFromBc3Link,
    chapterIdSelectedFromBc3Link,
  } = useContext(Budget2023Context)
  const [capituloData, setCapituloData] = useState({ ...capitulo })

  const { projectId } = useParams()
  const { user, freeTrialExpired, openFreeTrialExpire } =
    useContext(AuthContext)

  useEffect(() => {
    setIsChecked2(imChecked2)
  }, [imChecked2])
  useEffect(() => {
    setCapituloData({
      ...capitulo,
    })

    isUpdates()
  }, [capitulo.capituloInfo])

  const { name } = capituloData
  const [openEliminate, setOpenEliminate] = useState(false)
  const referencia = useRef(null)
  const referenciaFila = useRef(null)
  const [render, setRender] = useState(capitulo.isExpanded ? true : false)
  const pixelesDesp = desplazamiento + "px"
  const pixelesDesp2 = desplazamiento + 30 + "px"
  const [contadorActualizaciones, setContadorActualizaciones] = useState(0)
  const [openMovePartsToSubchapter, setOpenMovePartsToSubchapter] =
    useState(false)

  const getPixels = word => {
    let sumPixels = 0
    for (let i = 0; i < word.length; i++) {
      if (word.charAt(i) == word.charAt(i).toUpperCase()) {
        sumPixels += 11.5
      } else {
        sumPixels += 9
      }
    }
    if (sumPixels > 300) {
      return sumPixels * 0.9
    } else {
      return sumPixels
    }
  }
  const refDivPrecio = useRef(null)
  const onChangeName = e => {
    setCapituloData({
      ...capituloData,
      name: e.target.value,
    })
    capitulo.name = e.target.value
    capitulo.capituloInfo.name = e.target.value
  }
  const changeCheck = e => {
    if (e.nativeEvent.shiftKey) {
      if (capitulo.checked === true) {
        checkCapitulos(capitulo.chapterId, false)
        updateChaptersList(capitulo.capituloInfo)
      } else {
        checkMultipleChapters(capitulo.chapterId)
      }
    } else {
      if (capitulo.checked === true) {
        checkCapitulos(capitulo.chapterId, false)
        updateChaptersList(capitulo.capituloInfo)
      } else {
        checkCapitulos(capitulo.chapterId, true)
        updateChaptersList(capitulo.capituloInfo)
      }
    }
  }

  const createPar1 = () => {
    setOpenModalCreatePartida(true)
  }

  const addsubCapitulo2 = async () => {
    //se llama desde el capitulo hijo por lo que para la posicion es necesario saber los subcapitulos del padre
    let parent = await getChapterParentId2(
      capituloData.parentId,
      budgetTree.subcapitulos,
      false,
      0,
      {}
    )
    addCapituloV2(
      {
        ...capituloData,
        clientPrice: 0,
        name: "",
        price: 0,
        tags: [],
        subcapitulos: [],
        parentId: capituloData.parentId,
        partidas: [],
        position: parent.subcapitulos.length,
        sended: "false",
        selfManagement: "false",
        root: "false",
      },
      indice
    )
  }
  const addsubCapitulo3 = () => {
    addCapituloV2(
      {
        ...capituloData,
        clientPrice: 0,
        name: "",
        price: 0,
        tags: [],
        subcapitulos: [],
        parentId: capituloData.chapterId,
        partidas: capituloData.partidas,
        position: capituloData.subcapitulos.length,
        sended: "false",
        selfManagement: "false",
        root: "false",
      },
      indice
    )
  }

  const referenciaPrecio = useRef(null)
  const referenciaPrecio2 = useRef(null)
  const [cargado, setCargado] = useState(
    capitulo.subcapitulos.length > 0 || capitulo.partidas.length > 0
      ? true
      : false
  )
  const [loadingCap, setLoadingCap] = useState(false)
  const handleCapitulo = async () => {
    if (!e) var e = window.event
    e.cancelBubble = true
    if (e.stopPropagation) e.stopPropagation()
    if (capitulo.isExpanded == true) {
      setRender(false)
    } else {
      setRender(true)
      if (cargado == false) {
        setLoadingCap(true)
        setLoadingCap(false)
        setCargado(true)
      }
    }
    updateIsExpanded(capitulo.capituloInfo.chapterId)
  }

  useEffect(() => {
    if (
      referenciaFila.current.id == "NewCapi" ||
      referenciaFila.current.id == "NewCapiSelect"
    ) {
      if (capitulo.isExpanded == false) {
        referencia.current.id = "hideSubCapitulos"
        if (capitulo.warning != true) {
          referenciaFila.current.id = "NewCapi"
          referenciaPrecio.current.id = "precioTotal"
        }
      } else {
        referencia.current.id = "showSubCapitulos"
        if (capitulo.warning != true) {
          referenciaFila.current.id = "NewCapiSelect"
          referenciaPrecio.current.id = "precioTotalFocus"
        }
      }
    } //
    else {
      //modifico subcapi style
      if (capitulo.isExpanded == false) {
        referencia.current.id = "hideSubCapitulos"
        if (capitulo.warning != true && capitulo.updates == null) {
          if (referenciaPrecio2.current) {
            referenciaFila.current.id = "NewSubCapi"
            referenciaPrecio2.current.id = "precioTotal"
          }
        }
      } else {
        referencia.current.id = "showSubCapitulos"
        if (capitulo.warning != true && capitulo.updates == null) {
          if (referenciaPrecio2.current != null) {
            referenciaFila.current.id = "NewSubCapiSelect"
            referenciaPrecio2.current.id = "precioTotalFocus"
          }
        }
      }
    }
  }, [
    capitulo.isExpanded,
    capitulo.warning,
    capitulo.updates,
    contadorActualizaciones,
  ])

  const handleUpdate = () => {
    updateCapitulov2(capituloData)
  }

  const [alinearPrecio, setAlineacion] = useState(false)

  useEffect(() => {
    if (modificarMargen == true) {
      if (capitulo.capituloInfo.parentId != "root") {
        let valor = pixelesDesp.slice(0, -2)
        valor = parseInt(valor) + 50
        refDivPrecio.current.style.position = "relative"
        refDivPrecio.current.style.marginTop = "0px"
        refDivPrecio.current.style.right = valor + "px"
      }
      setAlineacion(true)
    }
  }, [modificarMargen, capitulo.isExpanded])

  const [openModalCreatePartida, setOpenModalCreatePartida] = useState(false)

  const getCertPrevious = () => {
    if (certificaciones) {
      return `Cert. Previa ${currencyFormatDE(
        capitulo.totalCertPreviousChapter
          ? capitulo.totalCertPreviousChapter
          : 0,
        getCurrency(user["custom:currency"])
      )}`
    }

    return `Coste Obj. ${currencyFormatDE(
      capitulo.price,
      getCurrency(user["custom:currency"])
    )}`
  }

  const getChapterProperty = (property) => {
    return currencyFormatDE(
      capitulo[property] ? capitulo[property] : 0,
      getCurrency(user["custom:currency"])
    )
  }

  const getCertCurrent = () => {
    if (certificaciones) {
      return `Cert. Actual ${currencyFormatDE(
        capitulo.totalCertCurrentChapter ? capitulo.totalCertCurrentChapter : 0,
        getCurrency(user["custom:currency"])
      )}`
    }

    return `Comercial ${currencyFormatDE(
      preferencias.descuento
        ? capitulo.clientPrice
        : capitulo.clientPriceWithoutDiscount,
      getCurrency(user["custom:currency"])
    )}`
  }

  return (
    <Fragment>
      {capitulo ? (
        capitulo.parentId == "root" ||
        capitulo.capituloInfo.parentId == "root" ? (
          <Fragment>
            <div
              id="NewCapi"
              onClick={e => {
                handleCapitulo(e)
              }}
              className={numeroClases}
              ref={referenciaFila}
              style={{
                display: "",
                backgroundColor:
                  capitulo.isExpanded && imTemplate && "rgb(252, 213, 107)",
                visibility: "inherit",
                borderBottom: "0px",
                paddingLeft: "14px",
                marginLeft: "0px",
                border:
                  capitulo.checked &&
                  (imTemplate ? "1px solid #ffe992" : "1.5px solid #4461D7"),
              }}
            >
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  marginTop: "0px",
                  marginLeft: "0px",
                }}
              >
                <div style={{ maxWidth: "23px", marginTop: "2px" }}>
                  {loadingCap ? (
                    <CircularProgress
                      style={{
                        width: "18px",
                        height: "20px",
                        color: "#414141",
                        marginRight: "4px",
                        marginTop: "0px",
                        display: "block",
                      }}
                    />
                  ) : capitulo.isExpanded ? (
                    <KeyboardArrowDownIcon
                      style={{ cursor: "pointer" }}
                      className="dropdownArrowB"
                    />
                  ) : (
                    <KeyboardArrowRightIcon
                      style={{ cursor: "pointer" }}
                      className="dropdownArrowB"
                    />
                  )}
                </div>
                <div className={capitulo.checked ? " " : "detallesCap"}>
                  <div className="tdcheckColumn">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={capitulo.checked}
                      id="flexCheckDefault"
                      onClick={e => {
                        if (!e) var e = window.event
                        e.cancelBubble = true
                        if (e.stopPropagation) e.stopPropagation()
                      }}
                      onChange={changeCheck}
                      style={{
                        width: "13px",
                        height: "13px",
                        cursor: "pointer",
                        zIndex: "5",
                      }}
                    ></input>
                  </div>
                </div>
                <div
                  id="NumCapitulo"
                  style={{
                    minWidth: "37px",
                    marginRight: "-2px",
                    marginTop: "2px",
                  }}
                >
                  {numeroIndex}.
                </div>
                <div className="tdCapitulo">
                  <input
                    onChange={onChangeName}
                    onClick={e => {
                      e.preventDefault(), e.stopPropagation()
                    }}
                    onDoubleClick={e => {
                      e.preventDefault(), e.stopPropagation(), e.target.select()
                    }}
                    onBlur={handleUpdate}
                    className="NombreAlign"
                    name={"name"}
                    value={name}
                    style={{
                      fontWeight: "700",
                      width: getPixels(name) + "px",
                      minWidth: name.length == 0 ? "240px" : "120px",
                    }}
                    placeholder={i18n.t("Introduce el nombre del capítulo")}
                    autoComplete="off"
                    disabled={
                      projectInfo.status === "CLOSED" ||
                      version ||
                      certificaciones
                        ? true
                        : user
                        ? freeTrialExpired || user["custom:role"] === "WORKER"
                          ? true
                          : false
                        : true
                    }
                  ></input>
                </div>

                <div className="tdEtiquetas"></div>
                {capitulo.warning == true ? (
                  <div>
                    <div style={{ marginLeft: "-25px" }}>
                      <ReportProblemOutlinedIcon
                        style={{ color: "#BF2929", marginLeft: "1px" }}
                      />
                    </div>
                  </div>
                ) : null}

                <div
                  className="tdTotalCap"
                  style={{
                    paddingLeft: "7px",
                    paddingRight: "17px",
                    position: "relative",
                  }}
                >
                  <div
                    ref={refDivPrecio}
                    className="inline"
                    style={{
                      marginLeft: "5px",
                      position: "absolute",
                      right: priceWidth + "%",
                      marginTop: "-11px",
                    }}
                  >
                    <div
                      className="detallesCap"
                      style={{
                        backgroundColor: "transparent",
                        width: "40px",
                        height: "25px",
                        justifyContent: "center",
                        marginLeft: "120px",
                      }}
                    >
                      {budgetTree.subcapitulos.length ==
                      1 ? null : projectInfo.status === "CLOSED" ||
                        version ||
                        certificaciones ? null : user ? (
                        user.freeTrialExpired ||
                        user["custom:role"] === "WORKER" ? null : (
                          <div
                            className="inline"
                            style={{ marginLeft: "-20px" }}
                          >
                            <DeleteOutlineIcon
                              style={{ width: "19px", cursor: "pointer" }}
                              onClick={e => {
                                e.preventDefault()
                                e.stopPropagation()
                                freeTrialExpired
                                  ? openFreeTrialExpire()
                                  : setOpenEliminate(true)
                              }}
                            />

                            {lengthSubCap != capitulo.position + 1 && (
                              <img
                                src={ArrowDownIcon}
                                onClick={e => {
                                  e.stopPropagation()
                                  moveChaptersWithArrows(
                                    projectId,
                                    capitulo,
                                    "down"
                                  )
                                }}
                                style={{
                                  width: "15px",
                                  height: "15px",
                                  marginTop: "5px",
                                  cursor: "pointer",
                                }}
                              />
                            )}
                            {indice > 0 && (
                              <img
                                src={ArrowUpIcon}
                                onClick={e => {
                                  e.stopPropagation()
                                  moveChaptersWithArrows(
                                    projectId,
                                    capitulo,
                                    "up"
                                  )
                                }}
                                style={{
                                  width: "13px",
                                  height: "13px",
                                  marginTop: "5px",
                                  cursor: "pointer",
                                }}
                              />
                            )}
                          </div>
                        )
                      ) : null}
                    </div>
                    {preferencias.precioCoste && (
                      <div
                        style={{
                          marginRight: "10px",
                          minWidth: "200px",
                          fontFamily: "Helvetica",
                          fontWeight: "700",
                          width: "fit-content",
                          paddingLeft: "5px",
                          paddingRight: "5px",
                          backgroundColor: "#616161",
                          color: "white",
                          borderColor: "#616161",
                        }}
                        ref={referenciaPrecio}
                        id="precioTotal"
                      >
                        {getCertPrevious()}
                      </div>
                    )}
                    {
                      preferencias.costReal && (
                        <div name="costReal"
                          style={{
                            marginRight: "10px",
                            minWidth: "200px",
                            fontFamily: "Helvetica",
                            fontWeight: "700",
                            width: "fit-content",
                            paddingLeft: "5px",
                            paddingRight: "5px",
                            backgroundColor: "#414141",
                            color: "white",
                            borderColor: "#414141",
                          }}
                          id="precioTotal"
                        >
                        Coste Real {getChapterProperty('costReal')}
                        </div>
                      )
                    }
                    {(preferencias.descuento || preferencias.clientPrice) && (
                      <div
                        id="precioTotal"
                        style={{
                          fontWeight: "700",
                          minWidth: "200px",
                          backgroundColor: "white",
                          color: "#414141",
                          border: "1px solid #414141",
                          fontFamily: "Helvetica",
                          width: "fit-content",
                          paddingLeft: "5px",
                          paddingRight: "5px",
                        }}
                      >
                        {getCertCurrent()}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div
              ref={referencia}
              id="hideSubCapitulos"
              style={{
                width: "100%",
                backgroundColor:
                  capitulo.parentId == "root" ? "#CCD8FC6B" : "white",
                border: capitulo.parentId == "root" ? "" : "1px solid #F5F5F5",
                boxShadow: "0px 6px 6px rgba(0, 0, 0, 0.08)",
                paddingTop:
                  capitulo.parentId == "root" && capitulo.partidas.length > 0
                    ? "0px"
                    : "20px",
              }}
            >
              {render ? (
                capitulo.partidas != null && capitulo.partidas.length > 0 ? (
                  <TablaPartida
                    certificaciones={certificaciones}
                    capitulo={capitulo}
                    numeroIndex={numeroIndex}
                    preferencias={preferencias}
                    setPreferencias={setPreferencias}
                    desplazamiento={desplazamiento + 60}
                    esUnico={true}
                    soyFinal={soyFinal}
                    projectInfo={projectInfo}
                    contadorActualizaciones={contadorActualizaciones}
                    setContadorActualizaciones={setContadorActualizaciones}
                    alinearPrecio={alinearPrecio}
                    ocultoFinal={ocultoFinal}
                    isChecked={isChecked}
                    setIsChecked={setIsChecked}
                    version={version}
                    openModalCreatePartida={openModalCreatePartida}
                    setOpenModalCreatePartida={setOpenModalCreatePartida}
                  />
                ) : capitulo.subcapitulos.length === 0 ? (
                  projectInfo.status === "CLOSED" ||
                  version ||
                  certificaciones ? null : user ? (
                    user.freeTrialExpired ||
                    user["custom:role"] === "WORKER" ? null : (
                      <div
                        id=""
                        className={numeroClases}
                        style={{
                          paddingLeft: pixelesDesp2,
                          marginLeft: "0px",
                          height: "30px",
                        }}
                      >
                        <div
                          style={{
                            marginTop: "0px",
                            marginLeft: "0px",
                            height: "30px",
                          }}
                        >
                          <div style={{ minWidth: "30px" }}></div>
                          <div> </div>
                          <div className="tdCapitulo" style={{ zIndex: "2" }}>
                            <div
                              className="NombreAlign"
                              onClick={() => {
                                freeTrialExpired
                                  ? openFreeTrialExpire()
                                  : createPar1()
                              }}
                            >
                              <button
                                className="nuevoBotonCrear4"
                                style={{ marginTop: "10px" }}
                              >
                                + {i18n.t("Buscar o crear nueva partida")}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  ) : null
                ) : capitulo.subcapitulos.length == 0 ? (
                  projectInfo.status === "CLOSED" ||
                  version ||
                  certificaciones ? null : user ? (
                    user.freeTrialExpired ||
                    user["custom:role"] === "WORKER" ? null : (
                      <div
                        id=""
                        className={numeroClases}
                        style={{
                          paddingLeft: pixelesDesp2,
                          marginLeft: "0px",
                          height: "30px",
                        }}
                      >
                        <div
                          style={{
                            marginTop: "0px",
                            marginLeft: "0px",
                            height: "30px",
                          }}
                        >
                          <div style={{ minWidth: "30px" }}></div>
                          <div> </div>
                          <div className="tdCapitulo" style={{ zIndex: "2" }}>
                            <div
                              className="NombreAlign"
                              onClick={() => {
                                freeTrialExpired
                                  ? openFreeTrialExpire()
                                  : createPar1()
                              }}
                            >
                              <button
                                className="nuevoBotonCrear4"
                                style={{ marginTop: "10px" }}
                              >
                                + {i18n.t("Buscar o crear nueva partida")}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  ) : null
                ) : null
              ) : null}
              {render ? (
                capitulo.subcapitulos != null &&
                capitulo.subcapitulos.length > 0 ? (
                  capitulo.subcapitulos.map((elemento, index) => (
                    <>
                      {" "}
                      <NewCapitulo
                        imTemplate={imTemplate}
                        key={`from_NewCapitulo_${index}`}
                        numeroIndex={numeroIndex + "." + (index + 1)}
                        indice={index}
                        capitulo={elemento}
                        desplazamiento={desplazamiento + 60}
                        preferencias={preferencias}
                        setPreferencias={setPreferencias}
                        numeroClases={numeroClases + 1}
                        lengthSubCap={capitulo.subcapitulos.length}
                        soyMargen={true}
                        soyFinal={soyFinal}
                        projectInfo={projectInfo}
                        modificado={modificado}
                        setClose={setClose}
                        modificarMargen={modificarMargen}
                        setModificarMargen={setModificarMargen}
                        ocultoFinal={ocultoFinal}
                        isChecked={isChecked}
                        setIsChecked={setIsChecked}
                        setOcultoFinal={setOcultoFinal}
                        certificaciones={certificaciones}
                        adjustWidth={capitulo.parentId == "root" ? true : false}
                        priceWidth={priceWidth + 0.5}
                        version={version}
                        isChecked2={isChecked2}
                        setIsChecked2={setIsChecked2}
                      />
                      {
                        //<LineTo key={index} from={numeroClases} to={numeroClases+1} />
                      }
                    </>
                  ))
                ) : projectInfo.status === "CLOSED" ||
                  version ||
                  certificaciones ? null : user ? (
                  user.freeTrialExpired ||
                  user["custom:role"] === "WORKER" ? null : (
                    <div
                      id=""
                      className={numeroClases}
                      style={{
                        paddingLeft: pixelesDesp2,
                        marginLeft: "0px",
                        height: "40px",
                      }}
                    >
                      <div
                        style={{
                          marginTop: "0px",
                          marginLeft: "0px",
                          height: "40px",
                        }}
                      >
                        <div style={{ minWidth: "30px" }}></div>
                        <div> </div>
                        <div className="tdCapitulo" style={{ zIndex: "2" }}>
                          <div
                            className="NombreAlign"
                            onClick={() => {
                              freeTrialExpired
                                ? openFreeTrialExpire()
                                : capitulo.partidas &&
                                  capitulo.partidas.length > 0
                                ? setOpenMovePartsToSubchapter(true)
                                : addsubCapitulo3()
                            }}
                            data-amplify-analytics-on="click"
                            data-amplify-analytics-name="ChapterCreated"
                            data-amplify-analytics-attrs=""
                          >
                            <button
                              className="nuevoBotonCrear4"
                              style={{
                                marginTop: "5px",
                                marginBottom: "5px",
                              }}
                            >
                              {capitulo.partidas && capitulo.partidas.length > 0
                                ? "+ Mover partidas a subcapítulo"
                                : "+ Nuevo subcapítulo"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                ) : null
              ) : null}
            </div>
            <ModalDeleteCap
              openEliminate={openEliminate}
              setOpenEliminate={setOpenEliminate}
              infoCap={capitulo.capituloInfo}
              modificado={modificado}
              setClose={setClose}
              soyFinal={soyFinal}
            />
          </Fragment>
        ) : (
          <Fragment>
            <div className="inline">
              <div
                id="NewSubCapi"
                onClick={e => {
                  handleCapitulo(e)
                }}
                className={numeroClases}
                ref={referenciaFila}
                style={{
                  display: "",
                  visibility: "inherit",
                  backgroundColor:
                    capitulo.isExpanded && imTemplate && "rgb(252, 213, 107)",
                  borderBottom: "0px",
                  paddingLeft: desplazamiento,
                  borderLeft: adjustWidth == true ? "6px solid #93AEFF" : "",
                  marginLeft: "8px",
                  width: "fit-content",
                  border:
                    capitulo.checked &&
                    (imTemplate ? "1px solid #ffe992" : "1.5px solid #4461D7"),
                }}
              >
                <div
                  onClick={e => {
                    if (!e) var e = window.event
                    e.cancelBubble = true
                  }}
                  style={{
                    alignItems: "center",
                    cursor: "pointer",
                    display: "flex",
                    visibility: "inherit",
                    borderBottom: "0px",
                  }}
                >
                  <div style={{ maxWidth: "23px" }}>
                    {loadingCap ? (
                      <CircularProgress
                        style={{
                          width: "18px",
                          height: "20px",
                          color: "#414141",
                          marginLeft: "-34px",
                          marginRight: "4px",
                          marginTop: "0px",
                          display: "block",
                        }}
                      />
                    ) : capitulo.isExpanded ? (
                      <KeyboardArrowDownIcon
                        style={{ cursor: "pointer", marginLeft: "-34px" }}
                        className="dropdownArrowB"
                      />
                    ) : (
                      <KeyboardArrowRightIcon
                        style={{ cursor: "pointer", marginLeft: "-34px" }}
                        className="dropdownArrowB"
                      />
                    )}
                  </div>
                  <div className={capitulo.checked ? " " : "detallesCap"}>
                    <div className="tdcheckColumn">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexCheckDefault"
                        onChange={changeCheck}
                        onClick={e => {
                          if (!e) var e = window.event
                          e.cancelBubble = true
                          if (e.stopPropagation) e.stopPropagation()
                        }}
                        checked={capitulo.checked}
                        style={{
                          width: "13px",
                          height: "13px",
                          cursor: "pointer",
                        }}
                      ></input>
                    </div>
                  </div>
                  <div id="NumCapitulo" style={{ minWidth: "37px" }}>
                    {numeroIndex}
                  </div>
                  <div style={{ width: "450px", maxWidth: "700px" }}>
                    <input
                      onChange={onChangeName}
                      onBlur={handleUpdate}
                      onClick={e => {
                        e.preventDefault(), e.stopPropagation()
                      }}
                      onDoubleClick={e => {
                        e.preventDefault(),
                          e.stopPropagation(),
                          e.target.select()
                      }}
                      className="NombreAlign"
                      name={"name"}
                      style={{
                        fontWeight: "700",
                        width: getPixels(name) + "px",
                        minWidth: name.length == 0 ? "240px" : "120px",
                      }}
                      value={name}
                      placeholder={i18n.t("Introduce el nombre del capítulo")}
                      autoComplete="off"
                      disabled={
                        projectInfo.status === "CLOSED" ||
                        version ||
                        certificaciones
                          ? true
                          : user
                          ? freeTrialExpired || user["custom:role"] === "WORKER"
                            ? true
                            : false
                          : true
                      }
                    ></input>
                  </div>
                  <div style={{ width: "53%", minWidth: "200px" }}></div>

                  <div
                    style={{
                      width: "100%",
                      minWidth: "141px",
                    }}
                  >
                    <div
                      ref={refDivPrecio}
                      className="inline"
                      style={{
                        marginLeft: "5px",
                        position: "absolute",
                        right: priceWidth + "%",
                        marginTop: "-11px",
                      }}
                    >
                      <div
                        className="detallesCap"
                        style={{
                          backgroundColor: "transparent",
                          width: "40px",
                          height: "25px",
                          justifyContent: "center",
                          marginLeft: "251px ",
                        }}
                      >
                        {capitulo.warning == true ? (
                          <ReportProblemOutlinedIcon
                            style={{
                              color: "#BF2929",
                              marginLeft: "-142px",
                              marginRight: "120px",
                              visibility: "visible",
                            }}
                          />
                        ) : null}
                        {projectInfo.status === "CLOSED" ||
                        version ||
                        certificaciones ? null : user ? (
                          user.freeTrialExpired ||
                          user["custom:role"] === "WORKER" ? null : (
                            <div
                              className="inline"
                              style={{ marginLeft: "-20px" }}
                            >
                              <DeleteOutlineIcon
                                style={{ width: "19px", cursor: "pointer" }}
                                onClick={e => {
                                  e.preventDefault()
                                  e.stopPropagation()
                                  freeTrialExpired
                                    ? openFreeTrialExpire()
                                    : setOpenEliminate(true)
                                }}
                              />

                              {lengthSubCap != capitulo.position + 1 && (
                                <img
                                  src={ArrowDownIcon}
                                  onClick={e => {
                                    e.stopPropagation()
                                    moveChaptersWithArrows(
                                      projectId,
                                      capitulo,
                                      "down"
                                    )
                                  }}
                                  style={{
                                    width: "15px",
                                    height: "15px",
                                    marginTop: "5px",
                                    cursor: "pointer",
                                  }}
                                />
                              )}
                              {indice > 0 && (
                                <img
                                  src={ArrowUpIcon}
                                  onClick={e => {
                                    e.stopPropagation()
                                    moveChaptersWithArrows(
                                      projectId,
                                      capitulo,
                                      "up"
                                    )
                                  }}
                                  style={{
                                    width: "13px",
                                    height: "13px",
                                    marginTop: "5px",
                                    cursor: "pointer",
                                  }}
                                />
                              )}
                            </div>
                          )
                        ) : null}
                      </div>
                      {preferencias.precioCoste && (
                        <div
                          style={{
                            marginRight: "10px",
                            minWidth: "170px",
                            fontFamily: "Helvetica",
                            fontWeight: "700",
                            width: "fit-content",
                            paddingLeft: "5px",
                            paddingRight: "5px",
                            backgroundColor: "#616161",
                            color: "white",
                            borderColor: "#616161",
                          }}
                          ref={referenciaPrecio}
                          id="precioTotal"
                        >
                          {getCertPrevious()}
                        </div>
                      )}
                    {
                      preferencias.costReal && (
                        <div name="costReal"
                          style={{
                            marginRight: "10px",
                            minWidth: "200px",
                            fontFamily: "Helvetica",
                            fontWeight: "700",
                            width: "fit-content",
                            paddingLeft: "5px",
                            paddingRight: "5px",
                            backgroundColor: "#414141",
                            color: "white",
                            borderColor: "#414141",
                          }}
                          id="precioTotal"
                        >
                        Coste Real {getChapterProperty('costReal')}
                        </div>
                      )
                    }
                      {(preferencias.descuento || preferencias.clientPrice) && (
                        <div
                          id="precioTotal"
                          style={{
                            fontWeight: "700",
                            minWidth: "170px",
                            backgroundColor: "white",
                            color: "#414141",
                            border: "1px solid #414141",
                            fontFamily: "Helvetica",
                            width: "fit-content",
                            paddingLeft: "5px",
                            paddingRight: "5px",
                          }}
                        >
                          {getCertCurrent()}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              ref={referencia}
              id="hideSubCapitulos"
              style={{
                marginLeft: "8px",
                width: adjustWidth == true ? "98.8%" : "98.8%",
                backgroundColor:
                  capitulo.parentId == "root" ? "#CCD8FC6B" : "white",
                border: capitulo.parentId == "root" ? "" : "1px solid #F5F5F5",
                boxShadow: "0px 6px 6px rgba(0, 0, 0, 0.08)",
                borderLeft: adjustWidth == true ? "6px solid #93AEFF" : "",
              }}
            >
              {render ? (
                capitulo.partidas != null && capitulo.partidas.length > 0 ? (
                  <>
                    <TablaPartida
                      certificaciones={certificaciones}
                      numeroIndex={numeroIndex}
                      openModalCreatePartida={openModalCreatePartida}
                      setOpenModalCreatePartida={setOpenModalCreatePartida}
                      capitulo={capitulo}
                      preferencias={preferencias}
                      setPreferencias={setPreferencias}
                      soyFinal={soyFinal}
                      desplazamiento={desplazamiento + 60}
                      projectInfo={projectInfo}
                      contadorActualizaciones={contadorActualizaciones}
                      setContadorActualizaciones={setContadorActualizaciones}
                      alinearPrecio={alinearPrecio}
                      ocultoFinal={ocultoFinal}
                      isChecked={isChecked}
                      setIsChecked={setIsChecked}
                      version={version}
                    />
                    <div
                      id=""
                      className={numeroClases}
                      style={{
                        paddingLeft: pixelesDesp2,
                        marginLeft: "0px",
                        height: "40px",
                      }}
                    >
                      <div
                        style={{
                          marginTop: "0px",
                          marginLeft: "0px",
                          height: "40px",
                        }}
                      >
                        <div style={{ minWidth: "30px" }}></div>
                        <div> </div>
                        <div className="tdCapitulo" style={{ zIndex: "2" }}>
                          <div
                            className="NombreAlign"
                            onClick={() => {
                              freeTrialExpired
                                ? openFreeTrialExpire()
                                : setOpenMovePartsToSubchapter(true)
                            }}
                            data-amplify-analytics-on="click"
                            data-amplify-analytics-name="ChapterCreated"
                            data-amplify-analytics-attrs=""
                          >
                            <button
                              className="nuevoBotonCrear4"
                              style={{
                                marginTop: "5px",
                                marginBottom: "5px",
                              }}
                            >
                              + Mover partidas a subcapítulo
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : capitulo.subcapitulos == null ? (
                  projectInfo.status === "CLOSED" ||
                  version ||
                  certificaciones ? null : user ? (
                    user.freeTrialExpired ||
                    user["custom:role"] === "WORKER" ? null : (
                      <div
                        id=""
                        className={numeroClases}
                        style={{
                          paddingLeft: pixelesDesp2,
                          marginLeft: "0px",
                          height: "30px",
                        }}
                      >
                        <div
                          style={{
                            marginTop: "0px",
                            marginLeft: "0px",
                            height: "30px",
                          }}
                        >
                          <div style={{ minWidth: "30px" }}></div>
                          <div> </div>
                          <div className="tdCapitulo" style={{ zIndex: "2" }}>
                            <div
                              className="NombreAlign"
                              onClick={() => {
                                freeTrialExpired
                                  ? openFreeTrialExpire()
                                  : createPar1()
                              }}
                            >
                              <button
                                className="nuevoBotonCrear4"
                                style={{ marginTop: "10px" }}
                              >
                                + {i18n.t("Buscar o crear nueva partida")}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  ) : null
                ) : capitulo.subcapitulos.length == 0 ? (
                  projectInfo.status === "CLOSED" ||
                  version ||
                  certificaciones ? null : user ? (
                    user.freeTrialExpired ||
                    user["custom:role"] === "WORKER" ? null : (
                      <div
                        id=""
                        className={numeroClases}
                        style={{
                          paddingLeft: pixelesDesp2,
                          marginLeft: "0px",
                          height: "30px",
                        }}
                      >
                        <div
                          style={{
                            marginTop: "0px",
                            marginLeft: "0px",
                            height: "30px",
                          }}
                        >
                          <div style={{ minWidth: "30px" }}></div>
                          <div> </div>
                          <div className="tdCapitulo" style={{ zIndex: "2" }}>
                            <div
                              className="NombreAlign"
                              onClick={() => {
                                freeTrialExpired
                                  ? openFreeTrialExpire()
                                  : createPar1()
                              }}
                            >
                              <button
                                className="nuevoBotonCrear4"
                                style={{ marginTop: "10px" }}
                              >
                                + {i18n.t("Buscar o crear nueva partida")}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  ) : null
                ) : null
              ) : null}
              {render ? (
                capitulo.subcapitulos != null &&
                capitulo.subcapitulos.length > 0 ? (
                  capitulo.subcapitulos.map((elemento, index) => (
                    <NewCapitulo
                      certificaciones={certificaciones}
                      imTemplate={imTemplate}
                      key={`from_NewCapitulo_${index}`}
                      soyFinal={soyFinal}
                      numeroIndex={numeroIndex + "." + (index + 1)}
                      indice={index}
                      capitulo={elemento}
                      desplazamiento={desplazamiento + 30}
                      preferencias={preferencias}
                      setPreferencias={setPreferencias}
                      numeroClases={numeroClases + 1}
                      lengthSubCap={capitulo.subcapitulos.length}
                      soyMargen={false}
                      projectInfo={projectInfo}
                      modificado={modificado}
                      setClose={setClose}
                      modificarMargen={modificarMargen}
                      setModificarMargen={setModificarMargen}
                      ocultoFinal={ocultoFinal}
                      setOcultoFinal={setOcultoFinal}
                      isChecked={isChecked}
                      setIsChecked={setIsChecked}
                      adjustWidth={capitulo.parentId == "root" ? true : false}
                      priceWidth={priceWidth + 0.5}
                      version={version}
                      isChecked2={isChecked2}
                      setIsChecked2={setIsChecked2}
                    />
                  ))
                ) : capitulo.partidas == null ||
                  capitulo.partidas.length == 0 ? (
                  projectInfo.status === "CLOSED" ||
                  version ||
                  certificaciones ? null : user ? (
                    user.freeTrialExpired ||
                    user["custom:role"] === "WORKER" ? null : (
                      <div
                        id=""
                        className={numeroClases}
                        style={{
                          paddingLeft: pixelesDesp2,
                          marginLeft: "0px",
                          height: "40px",
                        }}
                      >
                        <div
                          style={{
                            marginTop: "0px",
                            marginLeft: "0px",
                            height: "40px",
                          }}
                        >
                          <div style={{ minWidth: "30px" }}></div>
                          <div> </div>
                          <div className="tdCapitulo" style={{ zIndex: "2" }}>
                            <div
                              className="NombreAlign"
                              onClick={() => {
                                freeTrialExpired
                                  ? openFreeTrialExpire()
                                  : addsubCapitulo3()
                              }}
                              data-amplify-analytics-on="click"
                              data-amplify-analytics-name="ChapterCreated"
                              data-amplify-analytics-attrs=""
                            >
                              <button
                                className="nuevoBotonCrear4"
                                style={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                }}
                              >
                                + {i18n.t("Nuevo subcapítulo")}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  ) : null
                ) : null
              ) : null}
            </div>
            <ModalDeleteCap
              openEliminate={openEliminate}
              setOpenEliminate={setOpenEliminate}
              infoCap={capitulo.capituloInfo}
              soyFinal={soyFinal}
              modificado={modificado}
              setClose={setClose}
            />

            {indice === lengthSubCap - 1 ? (
              soyMargen == false ? (
                projectInfo.status === "CLOSED" ||
                version ||
                certificaciones ? null : user ? (
                  user.freeTrialExpired ||
                  user["custom:role"] === "WORKER" ? null : (
                    <div
                      id=""
                      className={numeroClases}
                      style={{
                        paddingLeft: pixelesDesp2,
                        marginLeft: "-30px",
                        height: "40px",
                      }}
                    >
                      <div
                        style={{
                          marginTop: "0px",
                          marginLeft: "0px",
                          height: "40px",
                        }}
                      >
                        <div style={{ minWidth: "30px" }}></div>
                        <div> </div>
                        <div className="tdCapitulo" style={{ zIndex: "2" }}>
                          <div
                            className="NombreAlign"
                            onClick={() => {
                              freeTrialExpired
                                ? openFreeTrialExpire()
                                : addsubCapitulo2()
                            }}
                            data-amplify-analytics-on="click"
                            data-amplify-analytics-name="ChapterCreated"
                            data-amplify-analytics-attrs=""
                          >
                            <button
                              className="nuevoBotonCrear4"
                              style={{
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              + {i18n.t("Nuevo subcapítulo")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                ) : null
              ) : projectInfo.status === "CLOSED" ||
                version ||
                certificaciones ? null : user ? (
                user.freeTrialExpired ||
                user["custom:role"] === "WORKER" ? null : (
                  <div
                    id=""
                    className={numeroClases}
                    style={{
                      paddingLeft: pixelesDesp2,
                      marginLeft: "-30px",
                      height: "40px",
                    }}
                  >
                    <div
                      style={{
                        marginTop: "0px",
                        marginLeft: "0px",
                        height: "40px",
                      }}
                    >
                      <div style={{ minWidth: "30px" }}></div>
                      <div> </div>
                      <div
                        className="tdCapitulo"
                        style={{ zIndex: "2", marginLeft: "-30px" }}
                      >
                        <div
                          className="NombreAlign"
                          onClick={() => {
                            freeTrialExpired
                              ? openFreeTrialExpire()
                              : addsubCapitulo2()
                          }}
                          data-amplify-analytics-on="click"
                          data-amplify-analytics-name="ChapterCreated"
                          data-amplify-analytics-attrs=""
                        >
                          <button
                            className="nuevoBotonCrear4"
                            style={{ marginTop: "10px", marginBottom: "10px" }}
                          >
                            + {i18n.t("Nuevo subcapítulo")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              ) : null
            ) : null}
          </Fragment>
        )
      ) : null}
      {openModalCreatePartida ? (
        <NewModalPartida
          open={openModalCreatePartida}
          setOpen={setOpenModalCreatePartida}
          chapterId={
            isNil(chapterIdSelectedFromBc3Link)
              ? capitulo.chapterId
              : chapterIdSelectedFromBc3Link
          }
          partidaInfo={
            !isNil(bc3LinkData)
              ? {
                  ...bc3LinkData,
                  projectId: projectId,
                  accountId: capitulo.accountId,
                  chapterId: isNil(chapterIdSelectedFromBc3Link)
                    ? capitulo.chapterId
                    : chapterIdSelectedFromBc3Link,
                }
              : null
          }
        />
      ) : null}
      {openMovePartsToSubchapter && (
        <MovePartsToSubchapterModal
          open={openMovePartsToSubchapter}
          setOpen={setOpenMovePartsToSubchapter}
          callback={() => {
            addsubCapitulo3()
            setOpenMovePartsToSubchapter(false)
          }}
        />
      )}
      {openModalImportPartFromBc3Link && (
        <ModalImportPartFromBc3Link
          openModal={openModalImportPartFromBc3Link}
          setOpenModal={toggleModalImportPartFromBc3Link}
          callback={() => {
            setOpenModalCreatePartida(true)
          }}
        />
      )}
    </Fragment>
  )
}

NewCapitulo.propTypes = {
  capitulo: propTypes.object.isRequired,
  desplazamiento: propTypes.number.isRequired,
  preferencias: propTypes.object.isRequired,
  setPreferencias: propTypes.func.isRequired,
  CapitulosPresupuesto: propTypes.array,
  setCapitulosPresupuesto: propTypes.func,
  numeroClases: propTypes.number.isRequired,
  indice: propTypes.number.isRequired,
  numeroIndex: propTypes.any.isRequired,
  lengthSubCap: propTypes.number.isRequired,
  soyMargen: propTypes.bool,
  soyFinal: propTypes.bool,
  projectInfo: propTypes.object,
  modificado: propTypes.number,
  setClose: propTypes.func,
  modificarMargen: propTypes.bool,
  setModificarMargen: propTypes.func,
  ocultoFinal: propTypes.bool,
  setOcultoFinal: propTypes.func,
  isChecked: propTypes.any,
  setIsChecked: propTypes.func,
  adjustWidth: propTypes.bool,
  priceWidth: propTypes.any,
  status: propTypes.string,
  version: propTypes.object,
  isChecked2: propTypes.any,
  setIsChecked2: propTypes.func,
  imTemplate: propTypes.bool,
  certificaciones: propTypes.bool,
}
export default NewCapitulo
