import React, { useState } from "react"
import PropTypes from 'prop-types'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
//i18n
import {  useHistory } from "react-router-dom"
import i18n from "i18n"
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown"
import C1 from "assets/images/icons/CYPENUEVO.png"
import P1 from "assets/images/icons/itec.png"
import T1 from "assets/images/icons/T1.png"



const DropdownSelectDB = props => {
  const [menu, setMenu] = useState(false)
  const history = useHistory()
  return (
    <>
    <React.Fragment>
      <Dropdown
      isOpen={menu}
      toggle={() => setMenu(!menu)}
      className="d-inline-block"
      style={{height:'0px'}}
       >
      <DropdownToggle   id='settingb'
          style={{marginTop:'0px', color:'#414141' , padding:'0px', borderRadius:'0px', paddingBottom:'0px', zIndex: props.referencia!='' ? "4" : "0",}}
    >
       <button
            id="costPress"
            style={{
                backgroundColor: props.referencia!='' ? '#FFD52B': "#EAEAEA",
                border: "0px",
                marginRight: "-10px",
                zIndex: props.referencia!='' ? "4" : "0",
                paddingLeft:'10px'
            }}
            >
            {i18n.t("Bases de datos")}
            <KeyboardArrowDown/>
            </button>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-end">
      <DropdownItem tag="b"  style={{lineHeight: '20px', cursor:'pointer'}} onClick={()=>{history.push(`/bancodeprecios/favorites/referenciaC`)}}>
          {" "}
            <div className="inline">
            {i18n.t("Banco de precios")}
            <img style={{marginTop:'0px', marginLeft:'5px', marginRight:'-10px', marginBottom:'-10px', zIndex:'9'}} src={C1} width={20} height={20}/>
            </div>
        </DropdownItem>
        <DropdownItem tag="b"  style={{lineHeight: '20px', cursor:'pointer'}} onClick={()=>{history.push(`/bancodeprecios/favorites/referenciaB`)}}>
          {" "}
          <div className="inline">
         {i18n.t("Banco de precios")}
         <img style={{marginTop:'0px', marginLeft:'5px', marginRight:'-10px', marginBottom:'-10px', zIndex:'9'}} src={P1} width={20} height={20}/>
            </div>
        </DropdownItem>
        <DropdownItem tag="b"  style={{lineHeight: '20px', cursor:'pointer'}} onClick={()=>{history.push(`/bancodeprecios/favorites/referenciaT`)}}>
        <div className="inline">
         {i18n.t("Banco de precios")}
         <img style={{marginTop:'2px', marginLeft:'5px', marginRight:'-2px', marginBottom:'-10px', zIndex:'9'}} src={T1} width={40} height={15}/>
            </div>
        </DropdownItem> 

      </DropdownMenu>
    </Dropdown>
    
    </React.Fragment>

  </>
  )
}

DropdownSelectDB.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
  referencia: PropTypes.string,

}

export default DropdownSelectDB