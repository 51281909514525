import React from "react"
import PropTypes from "prop-types"
import i18n from "i18n"
import { useHistory } from "react-router-dom"
import DropdownSelectDB from "./dropdowns/selectDBdropdown"


const LayoutFav = ({tipo, referencia
}) => {
  const history = useHistory()
  return (
    <>
      <div style={{ maxHeight: "750px", height: "750px", display: "contents" }}>
        <div
          style={{
            marginLeft: "24px",
            marginRight: "30px",
            marginBottom: "30px",
            marginTop: "0px",
          }}
        >
            {tipo=='myBP'}{
                <div id="SubMenuProjects">
                <div id="botonesPrincBudget" style={{marginRight:'0px'}}>
                      <button
                        id="costPressFin"
                        style={{
                          backgroundColor: tipo=="USER_DATABASE" ? '#FFD52B': "#EAEAEA",
                          border: "0px",
                          marginRight: "-5px",
                          zIndex: tipo=="USER_DATABASE" ? "1" : "0",
                        }}
                        onClick={() => {history.push(`/bancodeprecios/favorites/myBP`)}}     
                      >
                        {i18n.t("Mi banco de precios")}
                      </button>
                      <DropdownSelectDB referencia={referencia}/>
                      <button
                            id="costPress"
                            style={{
                            backgroundColor: tipo=="TEMPLATES" ? '#FFD52B': "#EAEAEA",
                            border: "0px",
                            zIndex: tipo=="TEMPLATES" ? "1" : "0",
                            marginRight: "-5px",
                            
                            }}
                            onClick={() => {history.push(`/bancodeprecios/favorites/templates`)}}  
                      >
                        {i18n.t("Plantillas")}
                      </button>
                   
                </div>
              </div>
            }
          
        </div>
      </div>
  
    </>
  )
}
LayoutFav.propTypes = {
  tipo: PropTypes.string,
    referencia: PropTypes.string
}
export default LayoutFav
