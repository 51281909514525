import React, { useState } from "react"
import PropTypes from 'prop-types'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
//i18n
import {  useHistory } from "react-router-dom"
import { HelveticaBold } from "common/typographies"
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown"
import countries from "./countries.json"


const DropdownSelectCountry = props => {
  const [menu, setMenu] = useState(false)
  const history = useHistory()
  console.log (props.value)
  return (
    <>
    <React.Fragment>
      <Dropdown
      isOpen={menu}
      toggle={() => setMenu(!menu)}
      className="d-inline-block"
      style={{height:'0px', width: "95%", position:'absolute', zIndex:'10'}}
       >
      <DropdownToggle style={{marginTop:'0px', height:'35px', borderRadius:'6px', border:'1px solid #c4c4c4', width:'91px', paddingLeft:'10px'}}>
        <div className="inline" style={{position:'relative'}}>
        <div className="inline" style={{color:'#414141', marginTop:'-1px'}}>
            <HelveticaBold style={{fontSize:'20px', lineHeight:'20px', marginRight:'5px', marginBottom:'0px', marginTop:'-2px'}}>
            {props.value?.emoji}
            </HelveticaBold>
            <HelveticaBold style={{fontSize:'12px', lineHeight:'15px', marginRight:'5px', marginBottom:'0px', color:'#414141'}}>
            {props.value?.dial_code}
            </HelveticaBold>
            </div>
        <KeyboardArrowDown style={{color:'#414141', width:'15px', position:'absolute', right: '-10px', marginTop : '-5px'}}/>
        </div>
      </DropdownToggle>
      <DropdownMenu  className="dropdown-menu-end" style={{width: '20%', height:'200px', overflow:'auto', marginRight:'142px', marginTop:'30px'}}>
      {countries.map((country, index) => {
        return (
          <DropdownItem tag="b" key={index} style={{lineHeight: '20px', cursor:'pointer'}} onClick={()=>{props.setValue({emoji:country.emoji, dial_code:country.dial_code})}}>
          {" "}
            <div className="inline">
            <HelveticaBold style={{fontSize:'20px', lineHeight:'20px', marginRight:'5px', marginBottom:'0px'}}>
            {country.emoji}
            </HelveticaBold>
            {country.dial_code}
            </div>
        </DropdownItem>
        )
      })}
      </DropdownMenu>
    </Dropdown>
    
    </React.Fragment>

  </>
  )
}

DropdownSelectCountry.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
  value: PropTypes.object,
  setValue: PropTypes.func,
}

export default DropdownSelectCountry