import React from "react"
import PropTypes from "prop-types"
import TableStructureAccepted from "./tableStructureComparison"
import PartAccepted from "./partComparison"

const PartTableAccepted = ({ table, open, setOpen, proposalToAccept, setProposalToAccept }) => {
  return (
    <TableStructureAccepted tabla={table} setOpen={setOpen} proposalToAccept={proposalToAccept} setProposalToAccept={setProposalToAccept}> 
          {table.map((item, index) => {
          return (
          index>1 && index!=table.length-1 ? 
          <PartAccepted key={index} part={item} tabla={table} /> : null
          )
        })}
    </TableStructureAccepted>
  )
}
PartTableAccepted.propTypes = {
  table: PropTypes.array.isRequired,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  proposalToAccept: PropTypes.array,
  setProposalToAccept: PropTypes.func,
}
export default PartTableAccepted
