import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"

// ruta a los archivos de idiomas
import common_ca from "locales/ca/translation.json"
import common_de from "locales/de/translation.json"
import common_en from "locales/en/translation.json"
import common_es from "locales/es/translation.json"
import common_fr from "locales/fr/translation.json"
import common_it from "locales/it/translation.json"

// default should be Spanish
i18n.use(LanguageDetector).init({
  fallbackLng: "ES",
  lng: 'ES',
  debug: false,
  interpolation: {
    escapeValue: false, // not needed for react!!
  },
  // react i18next special options (optional)
  react: {
    wait: false,
    bindI18n: "languageChanged loaded",
    bindStore: "added removed",
    nsMode: "default",
  },
  resources: {
    CA: {
      translation: common_ca,
    },
    DE: {
      translation: common_de,
    },
    EN: {
      translation: common_en,
    },
    ES: {
      translation: common_es,
    },
    FR: {
      translation: common_fr,
    },
    IT: {
      translation: common_it,
    },
  },
})
export default i18n
