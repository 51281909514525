import React, { useContext, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { useState } from "react"
import { v4 as uuidv4 } from "uuid"
import UnityDropdown from "components/TablaPartida/UnityDropdown"
import i18n from "i18n"
import AuthContext from "context/auth/authContext"
import { YellowButton, RegisterOrLoginOrLogoutButton } from "common/buttons";
import { useParams } from "react-router-dom"
import TemplatesContext from "context/templates/templatesContext"
import { getCurrency } from "utils/functions"


const CreateManualPart = ({  setOpen, capitulo }) => {
  const {createPartBankTree} = useContext(TemplatesContext)
  const [unity, setUnity] = useState('pa')
  const {user} = useContext(AuthContext)
  const [total, setTot] = useState(0)
  const {templateId} = useParams()
  const [data, setData] = useState({
    unity: "",
    resumen: "",
    price: "",
    precioRef: "",
    unidades: "",
    userPrice: "",
    total: "",
    name:"",
    code: uuidv4(),
    costeFinal: 0,
    accountId: user ? user.sub? user.sub : '' : '',
    chapterTemplateId: capitulo.chapterId ? capitulo.chapterId : '',
    tags:[],
    userPrice: 0,
    position: capitulo.partidas? capitulo.partidas.length : 0,
    templateId: templateId,
  })

                   
  useEffect(()=>{
    setData({
      ...data,
      unity: unity
    }
    )
  },[unity])
                 
  //m m2 m3 ud pa kg
  const referenciaParti = useRef(null)
  const handleChange = e => {
    if (e.target.className == "textareaExpand5") {
      setData({
        ...data,
        resumen: referenciaParti.current.outerText,
      })
    } else {
      if (e.target.name == "unidades") {
        if (e.target.value < 0) {
          setData({
            ...data,
            [e.target.name]: e.target.value * -1,
          })
        } else {
          setData({
            ...data,
            [e.target.name]: e.target.value,
          })
        }
      } else if (e.target.name == "price") {
        if (e.target.value < 0) {
          setData({
            ...data,
            [e.target.name]: e.target.value * -1,
            precioRef: parseFloat(e.target.value * -1),
            userPrice: parseFloat(e.target.value * -1),
            total: data.unidades!= "" ?  parseFloat(e.target.value *-1 * data.unidades) : 0
          })
        } else {
          setData({
            ...data,
            [e.target.name]: e.target.value,
            precioRef: parseFloat(e.target.value),
            userPrice: parseFloat(e.target.value),
            total: data.unidades!= "" ?  parseFloat(e.target.value* data.unidades) : 0
            
          })
        }
      } 
      else {
        setData({
          ...data,
          [e.target.name]: e.target.value,
        })
      }
    }
  }
 const [isValid, setValid] = useState(true)
 const [envio, setEnvio] = useState(false)


 useEffect(() => {
   if(envio){
    createPartBankTree(data)
    setTimeout(() => {
      setEnvio(false)
      setOpen(false)
    }, 1000)
   }
 
 }, [envio])


const [saveBD, setSaveBD] = useState(false)

  const handleCrear = () => {
    if (
      data.resumen != "" &&
      data.unidades != "" &&
      data.price != "" &&
      data.name != ""
    ) {
      
      createPartBankTree(data)
      setTimeout(() => {
        setOpen(false)
      }, 1000)
    }else{
      if(data.name == ""){
        setValid(false)
      }else{
        if(data.unidades == '' && data.resumen != '' && data.price != ''){
          setData({
            ...data,
            unidades: 0,
            resumen: "descripcion",
            description: "descripcion",
            price: parseFloat(0),
            precioRef: parseFloat(0),
            userPrice: parseFloat(0),
            total:  0
          })
        }else {
          setData({
            ...data,
            unidades: data.unidades=='' ? 0 : parseInt(data.unidades),
            resumen: data.resumen=='' ? "descripcion" : data.resumen,
            description: data.resumen=='' ? "descripcion" : data.resumen,
            price: data.price=='' ? parseFloat(0) : parseFloat(data.price),
            precioRef: data.precioRef=='' ? parseFloat(0): parseFloat(data.precioRef),
            userPrice: data.userPrice=='' ? parseFloat(0) : parseFloat(data.userPrice),
            total:  data.total==''? 0 : parseFloat(data.total)
          })
        }
        setEnvio(true)
        }
       


      }

    }
  
  
  useEffect(() => {
    if (data.unidades != "" && data.price != "") {
      setTot(
        parseFloat(parseFloat(data.unidades) * parseFloat(data.price)).toFixed(
          2
        )
      )
    }
  }, [data])

  useEffect(() => {
   if(data.name.length>0){
    setValid(true)
   }
  }, [data.name])
  


  
  return (
    <div style={{overflowY:'auto'}}>
      <div style={{justifyContent: 'center', position:'relative', width:'100%', textAlign:'center', display:'inline-flex'}}>
        <table className="tablaPartidas" style={{ borderRadius:"6px" ,marginLeft: "0px", maxWidth:'962px' }}>
          <tr style={{backgroundColor: "#D9D9D933", borderLeft:"none"}}>
            <td colSpan="4" style={{ color: "#414141", borderLeft:"none" }}>
            </td>
            <td colSpan="2" style={{ width: "170px", textAlign: "left", paddingLeft: "35px" }}>
              {i18n.t("Precio objetivo")}
            </td>
          </tr>
          <tr  style={{backgroundColor: "#D9D9D933", borderLeft:"none"}}>
            <td style={{
                width: "50px",
                maxWidth: "50px",
                color: "#B7B7B7",
              }}
              >{i18n.t("U")}</td>
            <td style={ isValid ? {
                paddingLeft: "13px",
                textAlign: "start",
                width: "370px",
                maxWidth: "370px",
                color: "#414141",
              }:{
                paddingLeft: "13px",
                textAlign: "start",
                width: "370px",
                maxWidth: "370px",
                color: "#BF2929",
                borderBottom:"#BF2929"
              }}>{i18n.t("Título")}
              </td>
            <td style={{
                paddingLeft: "13px",
                textAlign: "start",
                width: "304px",
                maxWidth: "304px",
                color: "#414141",
              }}>{i18n.t("Descripción")}
              </td>
            <td>{i18n.t("Medición")}</td>
            <td
              style={{
                width: "83px",
                maxWidth: "83px",
                color: "#414141",
              }}
            >
              {  getCurrency(user['custom:currency'])}/u
            </td>
            <td
              style={{
                width: "87px",
                maxWidth: "87px",
                color: "#B7B7B7",
                
              }}
            >
              {i18n.t("Total")}
            </td>
          </tr>
          <tr style={{borderLeft:"none"}}>
            <td className={"partidasInfo"}>
              <div
                className="inline"
                style={{ marginLeft: "0px", marginTop: "15px", width:'100%'}}
              >
              <UnityDropdown setUnity={setUnity} unity={unity}>&#9660;</UnityDropdown>
              </div>
            </td>
            <td className ={isValid ? "" : "errorTitulo"}>
                <input 
                  style={{
                    width:'100%',
                    paddingTop: "10px",
                    color: "#414141",
                    fontWeight: "400",
                    textAlign:'left',
                    justifyContent:'left',
                    paddingLeft:'13px'
                  }}
                  name="name"
                  id="inputUnidades"
                  type={"text"}
                  value={data.name}
                  placeholder={i18n.t("Introduce el título")}
                  onChange={handleChange}
                />
            </td>
            <td>
                  <p
                style={{
                  display: "block",
                  textAlign: "left",
                 
                  marginBottom: "0px",
                }}
              >
                {" "}
                <span style={{maxWidth:'100% !important'}}
                  ref={referenciaParti}
                  className="textareaExpand5"
                  role="textbox"
                  autoComplete="off"
                  name="resumen"
                  onInput={handleChange}
                  contentEditable
                ></span>
              </p>
            </td>
            <td>
                <input
                    style={{
                      color: "#414141",
                      fontWeight: "400",
                      textAlign: "center",
                    }}
                    id="inputUnidades"
                    type="number"
                    value={data.unidades}
                    onChange={handleChange}
                    name="unidades"
                    placeholder="  Medición"
                  />
            </td>
            <td>
            <div>
                <input
                  style={{ color: "#414141", fontWeight: "400" }}
                  id="inputUnidades"
                  type="number"
                  value={data.price}
                  onChange={handleChange}
                  name="price"
                  placeholder="Precio unitario"
                />
              </div>
            </td>
            <td>
              <div className="inline" style={{ color: "#B7B7B7", justifyContent: "center" }}>
                {total}  {  getCurrency(user['custom:currency'])}
              </div>
            </td>
          </tr>
          <tr>
            <td style={{maxHeight:"40px", height:"40px"}} colSpan="6">
              <div style={{float:"right"}}>
                <RegisterOrLoginOrLogoutButton style={{ height:"24px", marginLeft:"18px", width:'94px'}} onClick={()=>{setOpen(false)}}>
                  {i18n.t("Cancelar")}
                  </RegisterOrLoginOrLogoutButton>
                <YellowButton style={{ height:"24px", marginLeft:"18px", marginRight:'13px' , width:'94px'}} onClick={()=>{handleCrear()}}>
                  {i18n.t("Guardar")}
                </YellowButton>
              </div>
  
            </td>
          </tr>
          
        </table>
      </div>
    </div> 
  )
}

CreateManualPart.propTypes = {
    setOpen: PropTypes.func.isRequired,
    capitulo: PropTypes.object.isRequired,
}
export default CreateManualPart
