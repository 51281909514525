import i18n from "i18n";
import {Modal} from "@mui/material";
import {makeStyles} from "@material-ui/core/styles";
import React, { useState, useRef } from "react";
import PropTypes from 'prop-types'
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { PoppinsSemiBold } from "common/typographies";
import { YellowButton } from "common/buttons";

function getModalStyle() {
    const top = 50 ;
    const left = 50;
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };

}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: 'auto',
        backgroundColor: 'white',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        borderRadius: 6,
        height: 'auto',
        maxHeight: '600px',
        overflowY: 'auto',
        overflowX:'hidden',
        paddingLeft:'0px',
        paddingRight:'0px'
    },
}));


const ModalCropImage = ({open, setOpen, selectedFiles, setselectedFiles, setImage}) => {
    const [ modalStyle ] = useState(getModalStyle);
    const classes = useStyles();
    const cropperRef = useRef(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const onCrop = () => {
      const imageElement = cropperRef?.current;
      const cropper = imageElement?.cropper;
      setCroppedImage(cropper.getCroppedCanvas().toDataURL());
    };

    return (
        <>
            <Modal
                open={open}
                onClose={() => {}}
            >
         <div style={modalStyle} className={classes.paper}>
         <div id='centerPaco'>
         <PoppinsSemiBold style={{marginTop:'-30px', marginBottom:'-40px', fontSize:'20px'}}>
                {i18n.t('Recorta tu imagen')}
            </PoppinsSemiBold>
            </div>
        <div id='centerPaco'>
            {selectedFiles.length > 0?
                    <>
      
      <Cropper
      src={selectedFiles[0].preview}
        style={{maxHeight:'450px' }}
      // Cropper.js options
        aspectRatio={16/9}
      guides={false}
      crop={onCrop}
      ref={cropperRef}
    />
    </> : null}

        </div>
        <div id='centerPaco'>
        <YellowButton style={{marginBottom:'20px'}} onClick={()=>{
            setImage(croppedImage);
            setOpen(false)
        }}>
            {i18n.t('Recortar')}
        </YellowButton>
        </div>
       
         </div>
            </Modal>
        </>
    );
}

ModalCropImage.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    selectedFiles: PropTypes.array.isRequired,
    setselectedFiles: PropTypes.func.isRequired,
    setImage: PropTypes.func.isRequired
}

export default ModalCropImage;