import React,{useState, useRef, useEffect, useContext} from 'react'
import PropTypes, { number, string } from 'prop-types'
import i18n from 'i18n';
import CurrencyInput from 'react-currency-input-field';
import styled from 'styled-components';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import { TextareaAutosize } from '@mui/base';
import { IconButton } from '@mui/material';
import DropdownSelectTax from './dropdownSelectTax';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import ModalSearchParts from 'components/Facturas/modals/modalSearchPart';
import { getCurrency } from "utils/functions"
import AuthContext from 'context/auth/authContext';

const CustomCurrencyInput= styled(CurrencyInput)`
width: 385.13px;
height: 35px;
border: 1px solid #C4C4C4;
box-sizing: border-box;
border-radius: 6px;
font-family: 'Helvetica';
font-style: normal;
font-weight: 300;
color: #414141;
margin-bottom: 9px;
padding-left:8px;

&::placeholder {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #B7B7B7;
}

&:focus {
    outline: none !important;
    border:1px solid #FFD52B !important;
}

&:hover {
    &::placeholder {
        color: #414141;
    }
    border: 1px solid #414141;
}`

const CustomTextarea = styled(TextareaAutosize)`
    padding-top: 5px;
    padding-bottom: 15px;
    display: block;
    width: 100%;
    overflow: hidden;
    font-family: "Helvetica";
    font-weight: 500;
    line-height: 19.5px;
    font-size:14px;
    min-height: 33px;
    margin-top: 10px;
    color: #414141;
    border: 0px;
    resize: none;
    justify-content:'left';
    text-align:'left',
    :focus-within {
        border: 1px solid #FFD52B !important;
    }
    :hover:not(:focus-within) {
        border: 1px solid #414141 !important;
    }
    :empty:focus::before, :empty::before {
        content: 'Escribir';
        color: lightgray;
    }
`

const CustomTextarea2 = styled(TextareaAutosize)`
    height: 41px;
    min-height: 41px;
    position: relative;
    border: 1px solid rgb(196, 196, 196);
    background: white;
    margin-bottom: -2px;
    margin-top: -7.5px;
    margin-left: 20px;
    width: 100%;
    padding-left: 10px;
    min-width: 85px;
    border-radius: 6px 0px 0px 6px;
    padding-top: 10px;
    padding-top: 5px;
    padding-bottom: 15px;
    display: block;
    overflow: hidden;
    font-family: "Helvetica";
    font-weight: 500;
    line-height: 19.5px;
    font-size:14px;
    color: #414141;
    resize: none;
    justify-content:'left';
    text-align:'left',
    :focus-within {
        border: 1px solid #FFD52B !important;
    }
    :hover:not(:focus-within) {
        border: 1px solid #414141 !important;
    }
    :empty:focus::before, :empty::before {
        content: 'Escribir';
        color: lightgray;
    }
`


const InvoiceConcept = ({
    concept,
    invoice,
    setInvoice,
    index,
    invoiceService,
    selectedParts,
    setSelectedParts,
    handleAddFromParts
}) => {

    const sizeRef = useRef(null)
    const [size, setSize] = useState({height:24})
    const [open, setOpen] = useState(false)
    const [quantityEdit, setQuantityEdit] = useState(false)
    const [priceEdit, setPriceEdit] = useState(false)
    const {user} = useContext(AuthContext)
    const handleQuantityChange = (value, _, values) => {
        if (!quantityEdit){return false}
        let newInvoice = {...invoice}
        let newConcept = {...newInvoice.concepts[index]}
        const oldTaxes = invoiceService.computeTaxes({concept: concept})
        newConcept.quantity = values
        const newTaxes = invoiceService.computeTaxes({concept: newConcept})
        newConcept.total = newTaxes.total
        newConcept.subtotal = newTaxes.subtotal
        newInvoice.iva = (newInvoice.iva - oldTaxes.iva) + newTaxes.iva
        newInvoice.retention = (newInvoice.retention - oldTaxes.retention) + newTaxes.retention
        newInvoice.recargo = (newInvoice.recargo - oldTaxes.recargo) + newTaxes.recargo
        newInvoice.total = (newInvoice.total - oldTaxes.total) + newTaxes.total
        newInvoice.subtotal = (newInvoice.subtotal - oldTaxes.subtotal) + newTaxes.subtotal
        newInvoice.concepts[index] = newConcept
        setInvoice(newInvoice)
    }
    const handlePriceChange =(value, _, values) => {
        if (!priceEdit){return false}
        let newInvoice = {...invoice}
        let newConcept = {...newInvoice.concepts[index]}
        const oldTaxes = invoiceService.computeTaxes({concept: concept})
        newConcept.price = values
        const newTaxes = invoiceService.computeTaxes({concept: newConcept})
        newConcept.total = newTaxes.total
        newConcept.subtotal = newTaxes.subtotal
        newInvoice.iva = (newInvoice.iva - oldTaxes.iva) + newTaxes.iva
        newInvoice.retention = (newInvoice.retention - oldTaxes.retention) + newTaxes.retention
        newInvoice.recargo = (newInvoice.recargo - oldTaxes.recargo) + newTaxes.recargo
        newInvoice.total = (newInvoice.total - oldTaxes.total) + newTaxes.total
        newInvoice.subtotal = (newInvoice.subtotal - oldTaxes.subtotal) + newTaxes.subtotal
        newInvoice.concepts[index] = newConcept
        setInvoice(newInvoice)
    }
    const handleClickQuantity = (e) => {
        switch (e.detail) {
            case 1:
                break;
            case 2:
                if(!quantityEdit){
                    e.target.selectionStart = e.target.value.length; // Colocar cursor al final
                    e.preventDefault();
                }    
                setQuantityEdit(true)
                break;
        }
    }
    const handleClickPrice = (e) => {
        switch (e.detail) {
            case 1:
                break;
            case 2:
                if(!quantityEdit){
                    e.target.selectionStart = e.target.value.length; // Colocar cursor al final
                    e.preventDefault();
                }    
                setPriceEdit(true)
                break;
        }
    }
    const handleOnChangeQuantity = (e) => {
        if(quantityEdit){return false}
        concept.quantity.value = e.nativeEvent.data
        setQuantityEdit(true)
    }
    const handleOnChangePrice = (e) => {
        if(priceEdit){return false}
        concept.price.value = e.nativeEvent.data
        setPriceEdit(true)
    }
    const handleOnBlurQuantity = () => {
        setQuantityEdit(false)
    }
    const handleOnBlurPrice = () => {
        setPriceEdit(false)
    }
    useEffect(()=>{
        if(sizeRef.current){
            const charWidth = 8
            const charsLength = concept.description.length * charWidth
            const rows = Math.floor(charsLength/sizeRef.current.clientWidth)+1
            console.log('chars length', charsLength, 'rows', rows, 'height', rows* 19.5)
                setSize({
                    height: rows* 19.5,
                    width: sizeRef.current.clientWidth
                })
               
        }
    }, [sizeRef.current, concept.description])
    const handleDescriptionChange = (e) => {
        const newInvoice = {...invoice}
        newInvoice.concepts[index].description = e.target.value
        setInvoice(newInvoice)
    }

    


    const handleDelete = () => {
        let newInvoice = {...invoice}
        const newConcepts = newInvoice.concepts.filter((ct, i) => ct.invoiceConceptId !== concept.invoiceConceptId)
        const {total, subtotal, iva, retention, recargo} = invoiceService.computeTaxes({concept: concept})
        newInvoice.concepts = newConcepts
        newInvoice.total = newInvoice.total - total
        newInvoice.subtotal = newInvoice.subtotal - subtotal
        newInvoice.iva = newInvoice.iva - iva
        newInvoice.retention = newInvoice.retention - retention
        newInvoice.recargo = newInvoice.recargo - recargo
        setInvoice(newInvoice)
    }

    const handleTaxChange = (tax, type, concept) => {
        const formatedTax = {
            name: tax.name,
            value: tax.value,
            type: tax.type
        }
        let newInvoice = {...invoice}
        let newConcept = {...concept}
        newConcept[type] = formatedTax
        const oldTaxes = invoiceService.computeTaxes({concept: concept})
        const {total, subtotal, iva, retention, recargo} = invoiceService.computeTaxes({concept: newConcept})
        newConcept.total = total
        newConcept.subtotal = subtotal
        newInvoice.total = (invoice.total - oldTaxes.total) + total
        newInvoice.subtotal = (invoice.subtotal - oldTaxes.subtotal) + subtotal
        newInvoice.iva = (invoice.iva - oldTaxes.iva) + iva
        newInvoice.retention = (invoice.retention - oldTaxes.retention) + retention
        newInvoice.recargo = (invoice.recargo - oldTaxes.recargo) + recargo
        newInvoice.concepts[index] = newConcept
        setInvoice(newInvoice)
    }
    const handleAddParts = () => {
        setOpen(false)
        handleAddFromParts(index)
    }

    return(
        <div 
            className="inline" 
            style={{ width:'100%', height:'100%',paddingTop:'10px', position:'relative', display:'flex',
                marginBottom: `-${size.height+10+5}px`
            }}
        >   
            {
                invoice.projectId &&
            <div style={{backgroundColor:'#F8F8F8', width:'20px', marginLeft:'10px', minWidth:'20px'}}   
            >
                <IconButton
                    sx={{
                        backgroundColor: '#F8F8F8',
                        marginBtton: '5px',
                        width: '20px',
                        height: '20px',
                        marginLeft: '10px',
                        transition: 'transform 0.3s',

                        '&:hover': {
                            color: 'black',
                            transform: 'scale(1.2)',
                        },
                    }}
                    onClick={() => setOpen(true)}
                >
                    <ManageSearchIcon/>
                </IconButton>
            </div>
            }
            <div style={{backgroundColor:'#F8F8F8', width: invoice.projectId ? '47%' : '49.5%', minWidth:'150px'}}   
            >
                {console.log('size', size, size.height, parseFloat(parseFloat(size.height)+19.5), parseFloat(size.height)+19.5)}
                <CustomTextarea2
                  
                    placeholder='Escribir descripción'
                    value={concept.description}
                    minRows={2}
                    style={{
                        minHeight: '41px',
                        height: size.height < 38 ? `${size.height+2+19.5}px` : `${size.height+19.5}px`,
                        position:"relative",
                        border:"1px solid #C4C4C4",
                        background:"white",
                        marginBottom:"-2px",
                        marginTop: '-7.5px', 
                        marginLeft:'20px', 
                        width:'100%', 
                        paddingLeft:'10px', 
                        minWidth:'85px', 
                        borderRadius:'0px',
                        borderBottomLeftRadius:  invoice.concepts.length-1 == index ? '6px' : '0px' ,
                        paddingTop:'10px',
                        borderTopLeftRadius: index>0 ? '0px' : '6px',
                        
                    }}
                    ref = {sizeRef}
                    onChange={(e)=>{
                        handleDescriptionChange(e)
                    }}
                />
            </div>
            <div style={{backgroundColor:'#F8F8F8', width:'11%', minWidth:'110px'}}>
                <CustomCurrencyInput 
                    value={concept.quantity.value}
                    onValueChange={handleQuantityChange}
                    onChange={handleOnChangeQuantity}
                    onClick={handleClickQuantity}
                    onBlur={handleOnBlurQuantity}
                    name="quantity"
                    decimalsLimit={10}
                    decimalSeparator=','
                    groupSeparator='.'
                    placeholder={i18n.t('0')}
                    style={{  
                        borderLeft: '0px',  
                    height: size.height < 38 ? `${size.height+2+19.5}px` : `${size.height+19.5}px`, minHeight: '33px'  ,marginTop:'-7.5px', marginBottom:'-2px', marginLeft:'20px', 
                    width:'100%', paddingLeft:'10px', minWidth:'85px', 
                    borderRadius:'0px',
                    caretColor: quantityEdit ? 'black' : 'transparent'
                    }} 
                />
            </div>
            <div style={{backgroundColor:'#F8F8F8', width:'11%', minWidth:'110px'}}>
                <CustomCurrencyInput
                    value={concept.price.value}
                    decimalsLimit={10}
                    decimalSeparator=","
                    groupSeparator='.'
                    name="price"
                    placeholder={i18n.t('0'+
                        getCurrency(user['custom:currency'])
                      )}
                    onValueChange={handlePriceChange}
                    onChange={handleOnChangePrice}
                    onClick={handleClickPrice}
                    onBlur={handleOnBlurPrice}

                    style={{
                        borderLeft: '0px',
                        height: size.height < 38 ? `${size.height+2+19.5}px` : `${size.height+19.5}px`, minHeight: '33px', marginTop:'-7.5px', marginBottom:'-2px', marginLeft:'20px',
                        width:'100%', paddingLeft:'10px', minWidth:'85px', borderRadius:'0px',
                        caretColor: priceEdit ? 'black' : 'transparent'}} 
                />
            </div>
            <DropdownSelectTax 
                concept={concept}
                handleTaxChange={handleTaxChange}
                size = {size}

            />
            <div style={{backgroundColor:'#F8F8F8', width:'11.5%', minWidth:'110px', marginRight:'30px'}}>
            <CustomCurrencyInput
                value={concept.total.toFixed(2)}
                placeholder={i18n.t('0'+  
                    getCurrency(user['custom:currency'])
                  )}
                name="total"
                decimalsLimit={2}
                fixedDecimalLength={2}
                contentEditable={false}
                decimalSeparator=","
                groupSeparator='.'
                style={{
                    borderLeft: '0px',
                    height: size.height < 38 ? `${size.height+2+19.5}px` : `${size.height+19.5}px`, minHeight: '33px', marginTop:'-7.5px', marginBottom:'-2px', marginLeft:'20px', 
                    width:'100%', paddingLeft:'10px', minWidth:'85px', borderRadius:'0px',
                    borderBottomRightRadius: invoice.concepts.length-1 === index ? '6px' : '0px' ,
                    borderTopRightRadius: index==0 ? '6px' : '0px'
                }} />
            </div>
            
            <DeleteOutline  style={{color: invoice?.concepts.length==1 && '#c4c4c4'}}
                sx={{
                    cursor: invoice?.concepts.length!=1 && 'pointer',
                    transition: invoice?.concepts.length!=1 && 'transform 0.3s',
                    '&:hover': {
                        transform: invoice?.concepts.length!=1 && 'scale(1.2)',

                    }
                }}
                onClick={ invoice?.concepts.length!=1 && handleDelete} />
            
            {open === true && <ModalSearchParts 
                open={open}
                setOpen={setOpen}
                projectInfo={invoice.projectInfo}
                selectedParts={selectedParts}
                setSelectedParts={setSelectedParts}
                handleAddFromParts={handleAddParts}
            />}
        </div>
       
    )
    
      
    
}
InvoiceConcept.propTypes = {
    concept: PropTypes.any,
    setInvoice: PropTypes.any,
    index: PropTypes.any,
    invoice: PropTypes.any,
    invoiceService: PropTypes.any,
    selectedParts: PropTypes.any,
    setSelectedParts: PropTypes.any,
    handleAddFromParts: PropTypes.any,
}
export default InvoiceConcept
