import React, { useEffect, useRef, useState, useContext } from "react"
import { Modal, CircularProgress } from "@mui/material"
import { makeStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleOutlineSharpIcon from '@mui/icons-material/CheckCircleOutlineSharp';
import ErrorOutlineSharpIcon from '@mui/icons-material/ErrorOutlineSharp';
import AuthContext from "context/auth/authContext";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import i18n from "i18n";
import TemplatesContext from "context/templates/templatesContext";

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "fit-content",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: 6,
    width: '630px',
    height: '470px',
    maxHeight: "calc(100vh - 50px)",
    overflowY: "auto",
    overflowX: "hidden",
  },
}))

const ModalEditTemplate = ({ open, setOpen, template }) => {
  const [modalStyle] = useState(getModalStyle)
  const classes = useStyles()
  const {user} = useContext(AuthContext)
  const refNombre = useRef(null);
  const refEmail = useRef(null);
  const [campos, guardarCampos] = useState({
    nombre: template.name? template.name : '',
    description:template.description ? template.description : '',
  });
  const {updateTemplate, loading} = useContext(TemplatesContext)
  const [tagsFinal, setTagsFinal] = useState([]);
  


const obtenerCampos = e => {
    guardarCampos({
        ...campos,
        [e.target.name] : e.target.value
    })
}

useEffect(() => {
    if(campos.nombre.length > 0){
        setValidaNombre(true)
    }},[campos.nombre])

    /*Referencias para iconos confirm and error*/
    const refNombreConfirm = useRef(null);
    const refEmailConfirm = useRef(null);
    const refNombreError = useRef(null);
    const refEmailError = useRef(null);
    const [validaNombre, setValidaNombre] = useState(false);


    const handleOnClick = (e) =>{
        if(e.target.name=="nombre"){
            refNombre.current['id']="focus"
            refNombreConfirm.current.id="confirmNotVisible"
            refNombreError.current.id="errorNotVisible"
        }
        else if(e.target.name=="description"){
            refEmail.current['id']="focus"
            refEmailConfirm.current.id="confirmNotVisible"
            refEmailError.current.id="errorNotVisible"
        }

    }
    const handleEnter = (event) => {
        if (event.key.toLowerCase() === "enter") {
          const form = event.target.form;
          const index = [...form].indexOf(event.target);
          form.elements[index + 1].focus();
          event.preventDefault();
        }
      };
  const onFocus = (e) =>  {
    setFocused(true)
  if(e.target.name=="nombre"){
      refNombre.current['id']="focus"
      refNombreConfirm.current.id="confirmNotVisible"
      refNombreError.current.id="errorNotVisible"
  }
  else if(e.target.name=="description"){
    refEmail.current['id']="focus"
    refEmailConfirm.current.id="confirmNotVisible"
    refEmailError.current.id="errorNotVisible"
}
}
function nameIsValid (name){
    var matches = name.match(/\d+/g);
    if (matches != null) {
        return false;
    }else{
        return true;
    }
}

const onBlur = (e) => {
    setFocused(false)
    if(e.target.name=="nombre"){
      if(nameIsValid(campos.nombre) && campos.nombre.length>0){
        setValidaNombre(true);
          refNombre.current.id=" ";
          refNombreConfirm.current.id="confirmVisible"
          refNombreError.current.id="errorNotVisible"
      }else{
          refNombre.current.id="errorValidation"
          setValidaNombre(false);
          refNombreConfirm.current.id="confirmNotVisible"
          refNombreError.current.id="errorVisible"
      }
  }
  else if(e.target.name=="description"){
        refEmail.current.id=" ";
        refEmailConfirm.current.id="confirmVisible"
        refEmailError.current.id="errorNotVisible"
} 
}
const [focused, setFocused] = React.useState(false)
const handleInvite = async (e) => {
  e.preventDefault()
  if(validaNombre){
    const data = {
        name: campos.nombre,
        description: campos.description,
        tags: tags,
        accountId: user.sub,
        templateId: template.templateId,
        templateImage: '',
    }
    setTimeout(async() => {
    await updateTemplate(data)
    setOpen(false)
    location.reload()
}, 100);

  }if(!validaNombre){
    refNombre.current.id="errorValidation"
    setValidaNombre(false)
    refNombreConfirm.current.id="confirmNotVisible"
    refNombreError.current.id="errorVisible"
  }
}


const [input, setInput] = useState('');
const [tags, setTags] = useState(template.tags ? template.tags : []);
const [isKeyReleased, setIsKeyReleased] = useState(false);
const [color, setColor] =useState(0)
const listaColores = ['color1', 'color2', 'color3', 'color4', 'color5', 'color6', 'color7', 'color8'];
useEffect(() => {
    if(template.name){
        setTags(template.tags ? template.tags : [])
        guardarCampos({nombre: template.name, description: template.description ? template.description : ''})

    }}, [template])

const onKeyDown = (e) => {
    const { key } = e;
    const trimmedInput = input.trim();

    if (key === 'Enter' && trimmedInput.length && !tags.includes(trimmedInput)) {
        e.preventDefault();
        setTags(prevState => [...prevState, [trimmedInput, listaColores[color]]]);
        setTagsFinal(prevState => [...prevState, trimmedInput]);
        setInput('');
        if(color!=7){
            setColor(color+1);
        }else{
            setColor(0)
        }
    }

    if (key === "Backspace" && !input.length && tags.length && isKeyReleased) {
        const tagsCopy = [...tags];
        const poppedTag = tagsCopy.pop();
        e.preventDefault();
        setTags(tagsCopy);
        setInput(poppedTag[0]);
    }

    setIsKeyReleased(false);
};
const onEtiqueta = (e) => {
    const { value } = e.target;
    setInput(value);
};
const AddTagOnClick = (e) => {
    const trimmedInput = input.trim();

    if (trimmedInput.length && !tags.includes(trimmedInput)) {
        e.preventDefault();
        setTags(prevState => [...prevState, [trimmedInput, listaColores[color]]]);
        setTagsFinal(prevState => [...prevState, trimmedInput]);
        setInput('');
        if(color!=7){
            setColor(color+1);
        }else{
            setColor(0)
        }
    }

    setIsKeyReleased(false);
};

const onKeyUp = () => {
    setIsKeyReleased(true);
}
const deleteTag = (e, index) => {
    e.preventDefault()
    setTags(prevState => prevState.filter((tag, i) => i !== index))
    setTagsFinal(prevState => prevState.filter((tag, i) => i !== index))
}


const refTags = useRef(null);


  return (
    <Modal open={open} onClose={() => null}>
      <div style={modalStyle} className={classes.paper}>
      <CloseIcon id="cerrar" style={{marginRight:'13px'}} onClick={()=>{setOpen(false)}}></CloseIcon>
      <div style={{justifyContent:'center', textAlign:'center', marginTop:'30px', width:'100%', display:'flex'}}>
            <div style={{borderRadius:'100px', height:'100px', width:'100px', backgroundColor:'#93AEFF'}}>

            </div>
      </div>
      <form id="Form">
                        <p className="Titulos" style={{marginLeft:'24px'}}>{i18n.t("Nombre")}</p>
                        <div style={{marginLeft:'25px', width:'90%'}}>
                            <input
                            onFocus={onFocus} onBlur={onBlur} onKeyDown={handleEnter}
                                ref={refNombre}
                                onClick={handleOnClick}
                                placeholder="Nombre"
                                name="nombre"
                                value={campos.nombre}
                                onChange={obtenerCampos}></input>
                            <CheckCircleOutlineSharpIcon style={{width:'16px', color: '#3BA452', top: '48%'}} ref={refNombreConfirm} id="confirmNotVisible" ></CheckCircleOutlineSharpIcon>
                            <ErrorOutlineSharpIcon  style={{width:'16px', color: '#BF2929', top: '48%'}}  ref={refNombreError} id="errorNotVisible" ></ErrorOutlineSharpIcon>
                       </div>
                       <p className="Titulos" style={{marginLeft:'24px'}}>{i18n.t("Descripción")}</p>
                        <div style={{marginLeft:'25px', width:'90%'}}>
                            <input
                            onFocus={onFocus} onBlur={onBlur} onKeyDown={handleEnter}
                                ref={refEmail}
                                onClick={handleOnClick}
                                placeholder="Descripción"
                                name="description"
                                value={campos.description}
                                onChange={obtenerCampos}></input>
                           <CheckCircleOutlineSharpIcon style={{width:'16px', color: '#3BA452', top: '64%'}} ref={refEmailConfirm} id="confirmNotVisible" ></CheckCircleOutlineSharpIcon>
                             <ErrorOutlineSharpIcon  style={{width:'16px', color: '#BF2929', top: '64%'}}  ref={refEmailError} id="errorNotVisible" ></ErrorOutlineSharpIcon>
                        </div>
                        <p className="Titulos" style={{marginLeft:'24px'}}>{i18n.t("Etiqueta")}</p>
                        <div
                            ref={refTags}
                            onClick={handleOnClick}
                            className="container2" style={{marginLeft:'25px', width:'90%', padding:'0px'}}>
                            <LocalOfferOutlinedIcon id="iconTag"></LocalOfferOutlinedIcon>
                            {tags.map((tag, index) => (
                                // eslint-disable-next-line react/jsx-key
                                <div key={index} id={tag[1]} className="tag">
                                    {tag[0]}
                                    <button onClick={(e) => deleteTag(e, index)}>x</button>
                                </div>
                            ))}
                            <input
                            onFocus={onFocus} onBlur={onBlur}
                                name="tags"
                                value={input}
                                placeholder={i18n.t("Añadir Etiqueta")}
                                onKeyDown={onKeyDown}
                                onKeyUp={onKeyUp}
                                onChange={onEtiqueta}
                            />
                            <AddCircleOutlineIcon id="addTag" onClick={AddTagOnClick}></AddCircleOutlineIcon>
                        </div>
                           <div id='centerPaco' style={{marginTop:'25px'}}>
                            {loading ? <CircularProgress size={24}/> : 
                             <button onClick={(e) => {handleInvite(e)}} className="AddContact">{i18n.t("Guardar")}</button>}
                           </div>
      </form>
      </div>
    </Modal>
  )
}

ModalEditTemplate.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  template: PropTypes.object.isRequired,
}
export default ModalEditTemplate
