import { useReducer } from "react";
import {API, Auth} from "aws-amplify";
import React from "react";
import PropTypes from "prop-types";
import axios from "axios"
import FavoritesBPContext from "./favoritesBPContext";
import FavoritesBPReducer from "./favoritesBPReducer";

import  {
    LOADING_BANKPRICES,
    GET_BANK_PRICES,
    LOADING_SUBCAPS,
} from '../types';

import {getChapterParentId,getChapterParentId2,changesPartidasById, getChapterById, getPartIndex, getChapterIndex} from 'utils/budgetUtils';

class Capitulo{
    constructor(data){
        this.capituloInfo = {...data.chapterInfo, chapterId:data.chapterInfo.chapterBankReferenceId? data.chapterInfo.chapterBankReferenceId : data.chapterInfo.chapterBankId? data.chapterInfo.chapterBankId : data.chapterInfo.chapterTemplateId? data.chapterInfo.chapterTemplateId : data.chapterInfo.chapterId};
        this.parentId = data.chapterInfo.parentId? data.chapterInfo.parentId : 'root';
        this.partidas = data.parts? data.parts.map(partida => new Partida(partida)) : [];   
        this.chapterId = data.chapterInfo.chapterBankReferenceId? data.chapterInfo.chapterBankReferenceId : data.chapterInfo.chapterBankId? data.chapterInfo.chapterBankId : data.chapterInfo.chapterTemplateId? data.chapterInfo.chapterTemplateId : data.chapterInfo.chapterId;
        this.subcapitulos = data.subchapters? data.subchapters.map(subcapitulo => new Capitulo(subcapitulo)) : [];
        this.isExpanded = false
        this.total = data.chapterInfo.price? data.chapterInfo.price : 0
        this.checked = false
        this.status= 'OPEN'
        this.empty= false
        this.warning= false
        this.costeFinal = data.chapterInfo.costeFinal ? data.chapterInfo.costeFinal : 0
        this.updates = null,
        this.finalClientPrice = data.chapterInfo.finalClientPrice ? data.chapterInfo.finalClientPrice : 0
        this.sended = data.chapterInfo.sended ? data.chapterInfo.sended : false
        this.selfManagement = data.chapterInfo.selfManagement ? data.chapterInfo.selfManagement : false
        this.table = data.table ? data.table : false
    }
    update(data){
        this.capituloInfo = data
    }
    addCapitulo(capitulo){
        this.subcapitulos.push(capitulo)
    }
    updateTotal = () => {
        if(this.partidas.length>0){
            this.total = this.partidas.reduce((total, partida) => total + (partida.userPrice*partida.quantity), 0)
        }
        else{
            this.total = 0
            for (let capitulo of this.subcapitulos){
                capitulo.updateTotal()
                this.total = this.total + capitulo.total
            }
        }
    }
    updateTotal2 = () => {
        if(this.partidas.length>0){
            this.costeFinal = this.partidas.reduce((total, partida) => total + ((partida.costeFinal-((partida.discount /100)*partida.costeFinal))*partida.quantity), 0)
        }
        else{
            this.costeFinal = 0
            for (let capitulo of this.subcapitulos){
                capitulo.updateTotal2()
                this.costeFinal = this.costeFinal + capitulo.costeFinal
            }
        }
    }
    checkEmpty = () => {
        if(this.partidas.length == 0 && this.subcapitulos.length == 0){
            this.empty = true
            this.warning = true
            return true
        }
        else if(this.partidas.length > 0){
            for(let partida of this.partidas){
                if(partida.empty){
                    this.warning = true
                    return true
                }
            }
            this.warning = false
            return false
        }
        else if(this.subcapitulos.length > 0){
            let hasWarnings = false
            for(let capitulo of this.subcapitulos){
                if(capitulo.checkEmpty()===true){
                    hasWarnings = true
                }
            }
            this.warning = hasWarnings
            return hasWarnings
        }
        return false
    }

    checkUpdates = () => {
        if(this.partidas.length > 0){
            for(let partida of this.partidas){
                if(partida.updates){
                    this.updates = partida.updates
                    return true
                }
            }
        }
        else if(this.subcapitulos.length > 0){
            for(let capitulo of this.subcapitulos){
                capitulo.checkUpdates()
                if(capitulo.updates!=null){
                    this.updates = capitulo.updates
                }
            }
        }

    }


    *preOrder(){
        yield this
        for(let capitulo of this.subcapitulos){
            yield* capitulo.preOrder()
        }
    }

    remove(capituloId){
        for(let capitulo of this.preOrder()){
            const filtered = capitulo.subcapitulos.filter(element => element.chapterId !== capituloId)
            if(capitulo.subcapitulos.length !== filtered.length){
                capitulo.subcapitulos = filtered
                break
            }
        }
    }

    removePartida(capituloId, partidaId){
        for(let capitulo of this.preOrder()){
            if(capitulo.chapterId === capituloId){
                const filtered = capitulo.partidas.filter(element => element.partidaId !== partidaId)
                if(capitulo.partidas.length !== filtered.length){
                    capitulo.partidas = filtered
                    break
                }
            }

        }
    }
    addPartida(capituloId, partida, indexPartida){
        for(let capitulo of this.preOrder()){
            if(capitulo.chapterId === capituloId){
                capitulo.partidas.splice(indexPartida+1, 0, partida)
                break
            }
        }
    }
    addCapitulo(parentId, capituloNue, indexCapitulo){
        for(let capitulo of this.preOrder()){
            if(parentId=='root'){
                capituloNue.chapterInfo.parentId='root'
                let aux = [...capitulo.subcapitulos]
                let aux2 = {chapterInfo:capituloNue.chapterInfo, subcapitulos:[], parts:capituloNue.parts}

                let capi = new Capitulo(aux2)
                aux.splice(indexCapitulo, 0, capi)
                capitulo.subcapitulos = aux
                break
            }
            if(capitulo.chapterId === parentId){
                let aux = [...capitulo.subcapitulos]
                let aux2 = {chapterInfo:capituloNue.chapterInfo, subcapitulos:[], parts:capituloNue.parts}
                let capi = new Capitulo(aux2)
                aux.splice(indexCapitulo, 0, capi)
                capitulo.subcapitulos = aux
                break
            }
        }
    }
    updateIsExpanded(capituloId){
        for(let capitulo of this.preOrder()){
            if(capitulo.chapterId === capituloId){
                capitulo.isExpanded = !capitulo.isExpanded
                break
            }
        }
    }
    getInfoSelected(){
        let selectedInfo = []
        for(let capitulo of this.preOrder()){
            if(capitulo.checked){
                selectedInfo.push(capitulo.capituloInfo)
            }else{
                for(let partida of capitulo.partidas){
                    if(partida.checked){
                        selectedInfo.push(partida.partidaInfo)
                    }
                }
            }
        }
        return selectedInfo
    }
    checkPartida(capituloId, partidaId){
        for(let capitulo of this.preOrder()){
            if(capitulo.chapterId === capituloId){
                for(let partida of capitulo.partidas){
                    if(partida.partidaId === partidaId){
                        partida.checked = !partida.checked
                        break
                    }
                }
            }
        }
    }
    checkCapitulo(capituloId, value){
        for(let capitulo of this.preOrder()){
            if(capitulo.chapterId === capituloId){
                capitulo.checked = value
                if(capitulo.subcapitulos.length > 0){
                    for(let subcapitulo of capitulo.subcapitulos){
                        subcapitulo.checkCapitulo(subcapitulo.chapterId, value)
                    }
                }
                if(capitulo.partidas.length > 0){
                    for(let partida of capitulo.partidas){
                        partida.checked = value
                    }
                }
                break
            }
        }
    }


    updatePartida(newData, capituloId, final){
        for(let capitulo of this.preOrder()){
            if(capitulo.chapterId === capituloId){
                for(let partida of capitulo.partidas){
                    if(partida.partidaId === newData.partidaId){
                        partida.updatePartida(newData)
                        break
                    }
                }
                break
            }
        }
       if(final){
           this.updateTotal2()
        }else{
            this.updateTotal()
       }

    }
    updateCapitulo(newData, capituloId){
        for(let capitulo of this.preOrder()){
            if(capitulo.chapterId === capituloId){
                capitulo.update(newData)
                break
            }
        }
    }

    isEmpty(){
        for(let capitulo of this.preOrder()){
            if(capitulo.subcapitulos.length==0){
                if(capitulo.partidas.length==0){
                    //capitulo.update(capitulo)
                }
            }
        }
    }
    getChaptersToGroup(){
        let chaptersToGroup = []
        for(let capitulo of this.subcapitulos){
            if(capitulo.checked === true){
                chaptersToGroup.push(capitulo.capituloInfo)
            }
            else{
                chaptersToGroup = chaptersToGroup.concat(capitulo.getChaptersToGroup())
            }
        }
        return chaptersToGroup
    }
    

    postGroupParts(newChapter, newParts, oldParts){
        try {
            for(let part of oldParts){
                this.removePartida(part.chapterId,part.partidaId)
            }
            let chapterParentId = newChapter.parentId
            const chapter = new Capitulo({'capituloInfo': newChapter, 'partidas': newParts.map(part => ({
                'partidaId': part
            }))})
            if (chapterParentId === 'root'){
                this.subcapitulos.push(chapter)
            }else{
                for(let capitulo of this.preOrder()){
                    if(capitulo.chapterId === chapterParentId){
                        capitulo.subcapitulos.push(chapter)
                        break
                    }
                }
            }
        } catch (error) {
            console.error(error)
        }
        
    }

    postGroupChapters(chapterCreated, newChapters, oldChapters){
        try {
            for(let chapter of oldChapters){
                this.remove(chapter.chapterId)
            }
            let chapterParentId = chapterCreated.parentId
            let auxChapters = oldChapters
            for (let index = 0; index < auxChapters.length; index++) {
                auxChapters[index].parentId=chapterCreated.chapterId
            }
            const chapter = new Capitulo({'capituloInfo': chapterCreated, 'subcapitulos': auxChapters})
            if (chapterParentId === 'root'){
                this.subcapitulos.push(chapter)
            }else{
                for(let capitulo of this.preOrder()){
                    if(capitulo.chapterId === chapterParentId){
                        capitulo.subcapitulos.push(chapter)
                        break
                    }
                }
            }
        } catch (error) {
            console.error(error)
        }
    }

}
class Partida{
    constructor(data){
        this.partidaId = data.partInfo.partBankReferenceId? data.partInfo.partBankReferenceId : data.partInfo.partBankId ? data.partInfo.partBankId : data.partInfo.partTemplateId ? data.partInfo.partTemplateId : data.partInfo.partId
        this.accountId = data.partInfo.accountId? data.partInfo.accountId : ''
        this.userPrice = parseFloat(data.partInfo.userPrice? data.partInfo.userPrice : data.partInfo.price).toFixed(2)
        this.price = parseFloat(data.partInfo.userPrice? data.partInfo.userPrice : data.partInfo.price).toFixed(2)
        this.quantity = data.partInfo.quantity
        this.updatedAt = data.partInfo.updatedAt
        this.createdAt = data.partInfo.createdAt
        this.code = data.partInfo.code
        this.description = data.partInfo.description
        this.chapterId = data.partInfo.chapterBankReferenceId? data.partInfo.chapterBankReferenceId : data.partInfo.chapterBankId ? data.partInfo.chapterBankId : data.partInfo.chapterTemplateId ? data.partInfo.chapterTemplateId : data.partInfo.chapterId
        this.name = data.partInfo.name
        this.elementInfo = data.partInfo.partBankReferenceInfo ? data.partInfo.partBankReferenceInfo : null
        this.parentId = data.partInfo.chapterBankReferenceId? data.partInfo.chapterBankReferenceId : data.partInfo.chapterBankId ? data.partInfo.chapterBankId : data.partInfo.chapterTemplateId ? data.partInfo.chapterTemplateId : data.partInfo.chapterId
        this.checked = false,
        this.unity = data.partInfo.unity? data.partInfo.unity : ''
        this.status= 'OPEN'
        this.costeFinal = data.partInfo.costeFinal ? data.partInfo.costeFinal : 0
        this.empty = this.code === '' || this.description === '' || this.code === undefined
        this.industrialProfit = data.partInfo.industrialProfit ? data.partInfo.industrialProfit : null
        this.costeGeneral = data.partInfo.costeGeneral ? data.partInfo.costeGeneral: null
        this.costeIndirecto = data.partInfo.costeIndirecto ? data.partInfo.costeIndirecto : null;
        this.updates = data.partInfo.updates ? data.partInfo.updates : null;
        this.creadaEnFinal = data.partInfo.creadaEnFinal ?  data.partInfo.creadaEnFinal : false
        this.images = data.partInfo.images ? data.partInfo.images : null,
        this.discount = data.partInfo.discount ? data.partInfo.discount : 0
        this.finalClientPrice = data.partInfo.finalClientPrice? data.partInfo.finalClientPrice : 0
        this.position = data.partInfo.position ? data.partInfo.position : 0
        this.table = data.partInfo.table ? data.partInfo.table : null
        }

    updatePartida (data){
        
        this.partidaId = data.partInfo.partBankReferenceId? data.partInfo.partBankReferenceId : data.partInfo.partBankId ? data.partInfo.partBankId : data.partInfo.partTemplateId ? data.partInfo.partTemplateId : data.partInfo.partId
        this.accountId = data.partInfo.accountId
        this.userPrice = parseFloat(data.partInfo.userPrice? data.partInfo.userPrice : data.partInfo.price).toFixed(2)
        this.price = parseFloat(data.partInfo.userPrice? data.partInfo.userPrice : data.partInfo.price).toFixed(2)
        this.quantity = data.partInfo.quantity
        this.updatedAt = data.partInfo.updatedAt
        this.createdAt = data.partInfo.createdAt
        this.code = data.partInfo.code
        this.description = data.partInfo.description
        this.chapterId = data.partInfo.chapterBankReferenceId? data.partInfo.chapterBankReferenceId : data.partInfo.chapterBankId ? data.partInfo.chapterBankId : data.partInfo.chapterTemplateId ? data.partInfo.chapterTemplateId : data.partInfo.chapterId
        this.name = data.partInfo.name
        this.elementInfo = data.partInfo.partBankReferenceInfo ? data.partInfo.partBankReferenceInfo : null
        this.parentId = data.partInfo.chapterBankReferenceId? data.partInfo.chapterBankReferenceId : data.partInfo.chapterBankId ? data.partInfo.chapterBankId : data.partInfo.chapterTemplateId ? data.partInfo.chapterTemplateId : data.partInfo.chapterId
        this.unity = data.partInfo.unity? data.partInfo.unity : ''
        this.status= data.partInfo.status? data.partInfo.status : 'OPEN'
        this.costeFinal = data.partInfo.costeFinal
        this.empty = this.code === '' || this.description === '' || this.code === undefined
        this.industrialProfit = data.partInfo.industrialProfit ? data.partInfo.industrialProfit : null
        this.costeGeneral = data.partInfo.costeGeneral ? data.partInfo.costeGeneral: null
        this.costeIndirecto = data.partInfo.costeIndirecto ? data.partInfo.costeIndirecto : null
        this.updates = data.partInfo.updates ? data.partInfo.updates : null;
        this.creadaEnFinal = data.partInfo.creadaEnFinal ?  data.partInfo.creadaEnFinal : false
        this.images = data.partInfo.images ? data.partInfo.images : null
        this.discount = data.partInfo.discount ? data.partInfo.discount : 0
        this.finalClientPrice = data.partInfo.finalClientPrice? data.partInfo.finalClientPrice : 0
        this.position = data.partInfo.position ? data.partInfo.position : 0
        this.table = data.partInfo.table ? data.partInfo.table : null
    }
}

const FavoritesBPState = (props) => {
    const ENDPOINT = 'Planhopper-Bank-Prices'
    const initialState = {
        loading: false,
        myPricesBank: new Capitulo({
            chapterInfo: {
                chapterId: "root"
            },
            partidas: null,
            subcapitulos: null
        }),
        loadingCaps: false,
        selectedCategories:[],
        categoriesChecked: [],
        partsChecked: [],
        selectedParts: [],
        errorAgrupar: false,
        selectedPartGroup: [],
        selectedCategories2: [],
        myNonSpecifiedPricesBank: new Capitulo({
            chapterInfo: {
                chapterId: "root"
            },
            partidas: null,
            subcapitulos: null
        }),
    }
    const [state, dispatch] = useReducer(FavoritesBPReducer, initialState);

    const getMyBankPrices =  async (accountId, table, referencia) => {
        try {
            dispatch({
                type: LOADING_BANKPRICES,
                payload: {
                    myPricesBank: new Capitulo({
                        chapterInfo: {
                            chapterId: "root"
                        },
                        partidas: null,
                        subcapitulos: null
                    })
                }
            })
            const headers = {
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                'Access-Control-Allow-Origin': '*',
            }
            let res;
            if(referencia!==''){
                res = await API.get(ENDPOINT, `/favourites/`+accountId+'?table='+table+'&parentId=root'+'&type='+referencia, {headers: headers});
            }else{
                res = await API.get(ENDPOINT, `/favourites/`+accountId+'?table='+table+'&parentId=root', {headers: headers});
            }
           
          
            let subcapitulosAux = []
                for (let capitulo of res.favouriteTree){
                    let aux = new Capitulo(capitulo)
                    subcapitulosAux.push(aux)
                }
            dispatch({
                type: GET_BANK_PRICES,
                payload: subcapitulosAux
            })
        }
        catch (err) {
            console.log(err);
        }
    }

    const getSubCaps = async (chapterId, accountId, table, referencia) => {
        try {
            dispatch({
                type: LOADING_SUBCAPS
            })
            const headers = {
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                'Access-Control-Allow-Origin': '*',
            }
            let res;
            if(referencia!==''){
                res = await API.get(ENDPOINT, `/favourites/`+accountId+'?table='+table+'&parentId='+chapterId+'&type='+referencia, {headers: headers});
            }else{
                res = await API.get(ENDPOINT, `/favourites/`+accountId+'?table='+table+'&parentId='+chapterId, {headers: headers});
            }
            if(res.favouriteTree){
                for (let i=0; i<res.favouriteTree.length; i++){
                        let aux = res.favouriteTree[i]
                        let parentId= aux.chapterInfo.parentId
                        dispatch({
                            type: 'ADD_CAPITULO',
                            payload: {
                                parentId:parentId,
                                res:aux,
                                indexCapitulo:i
                            }
                        })
                    }
                }else{
                    for (let i=0; i<res.myParts.length; i++){
                    let aux = {...res.myParts[i],
                        elementInfo: res.myParts[i].partBankReferenceInfo ? res.myParts[i].partBankReferenceInfo : null,
                        chapterId: res.myParts[i].chapterBankId
                    }
                    let partidaNueva = new Partida(aux)
                    dispatch({
                        type: 'ADD_PARTIDA',
                        payload: {
                            chapterId: res.myParts[i].chapterBankId,
                            partidaNueva: partidaNueva,
                            index: res.myParts[i].position
                        }
                    })
                }
        }
    }

        catch (err) {
            console.log(err);
        }
    }

    const createInitialStructure = async (accountId) => {
        try {
           
            const headers = {
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                'Access-Control-Allow-Origin': '*',
            }
            const res = await API.post(ENDPOINT, `/createInitialStructure`, {headers: headers, body: {accountId: accountId}});
            
        }
        catch (err) {
            console.log(err);
        }
    }


    const addBankChapter = async (parentId, projectId, indexCapitulo, accountId, root) =>{
        try{
            let payload = {
              headers: {
                'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                'Access-Control-Allow-Origin': '*'
              },
              body:{
                name: '',
                description: '',
                parentId: parentId,
                projectId: projectId,
                price : 0,
                tags: [],
                accountId: accountId,
                code:null,
                root:root,
                position: indexCapitulo
              }
            }
            const res = await API.post(ENDPOINT, `/createChapterBank`, payload)
            let aux = {...res, chapterInfo:res}
            dispatch({
                type: 'ADD_CAPITULO',
                payload: {
                    parentId:parentId,
                    res:aux,
                    indexCapitulo: indexCapitulo
                }
            })
        }
        catch(error){
          console.log(error);
        }
    }

    const updateChapterBank = async ( data) => {
        try{
          
            let headers ={
                'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                'Access-Control-Allow-Origin': '*'
              }
              let body ={
                name : data.name? data.name : "",
                description: data.description? data.description: null,
                price: data.price? data.price : 0,
                tags: data.tags? data.tags: [],
                accountId: data.accountId,
                chapterBankReferenceId: data.chapterId,
              }
                const res = await API.put(ENDPOINT, '/editChaptersBankTree', {headers: headers, body:body})
                let aux = {...res, chapterInfo:res}
                dispatch({
                    type: 'UPDATE_CAPITULO',
                    payload: {
                        newData: aux,
                        capituloId: data.chapterId
                    }
                  })
        }
        catch(e){
            console.log(e)
        }

        
    }
    const deleteCapitulo = async (capituloId, accountId) => {
        try{
       
            const payload = {
              headers: {
                'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                'Access-Control-Allow-Origin': '*'
              },
              body:{
                chapterBankReferenceId: capituloId=='SOYUNCAPITULOCONPARTIDASSINCLASIFICAR'? 'root' : capituloId,
               accountId: accountId
              }
            }
            
            const res = await API.post(ENDPOINT, `/deleteChaptersBankTree`, payload)
            dispatch({
                type: 'DEL_CAPITULO',
                payload: capituloId
            })
          } catch (error){
            console.log(error)
          }

    }

    function getSelectedCategories(selectedBudget, capitulos){
        for(let i=0; i<capitulos.length ; i++){
          if(capitulos[i].checked){
            selectedBudget.push({...capitulos[i].capituloInfo})
          }if(capitulos[i].subcapitulos.length>0){
              getSelectedCategories(selectedBudget, capitulos[i].subcapitulos)
          }
        }
        dispatch({
            type: 'SELECCIONAR_AGRUPAR',
                payload: {
                    selectedBudget: selectedBudget
                }
        })
      }

      const checkCapitulos = (capituloId, value) => {
        dispatch({
            type: 'CHECK_CAPITULOS',
            payload: {
                capituloId,
                value
            }
        })
    }
    const updateChaptersList = (chapter) => {
        let find = false;
        let i = 0;
        while(!find && i<state.categoriesChecked.length){
            if(state.categoriesChecked[i].chapterId==chapter.chapterId){
                state.categoriesChecked.splice(i, 1)
                find=true
            }else{
                i++;
            }
        }
        if(find==false){
            state.categoriesChecked.push(chapter)
        }
        dispatch({
            type: 'UPDATE_CHAPTERS_LIST',
            payload: {
                list: state.categoriesChecked
            }
        })
    }
    const checkMultipleChapters = async (chapterId) => {
        let parent = await getChapterParentId(chapterId, state.myPricesBank.subcapitulos)
        let chapter = {}
        if(parent!='root'){
            chapter = await getChapterById(parent, state.myPricesBank.subcapitulos)
        }else{
            chapter=state.myPricesBank
        }

        if(chapter.subcapitulos.length>0){
            let indiceChapter = await getChapterIndex(chapter, chapterId)
            let i = 0
            let trobat1 =false
            let trobat2 = false
            while ((!trobat1 || !trobat2) && i<chapter.subcapitulos.length) {
                if(chapter.subcapitulos[i].chapterId!=chapterId){
                    if(chapter.subcapitulos[i].checked==true){
                        if(i<indiceChapter){
                            for(let j=i+1;j<indiceChapter+1;j++){  //indice +1 porque quiero que marque la que hemos seleccionado tb
                                dispatch({
                                    type: 'CHECK_CAPITULOS',
                                    payload: {
                                        capituloId: chapter.subcapitulos[j].chapterId,
                                        value: true
                                    }
                                })
                                updateChaptersList(chapter.subcapitulos[j].capituloInfo)

                            }
                            trobat1=true
                            i=indiceChapter
                        }else{
                            let valorIni = indiceChapter
                            for(let j=indiceChapter;j<i;j++){
                                if(j==valorIni){
                                    if(chapter.subcapitulos[valorIni].checked==false){
                                        dispatch({
                                            type: 'CHECK_CAPITULOS',
                                            payload: {
                                                capituloId: chapter.subcapitulos[j].chapterId,
                                                value: true
                                            }
                                        })
                                        updateChaptersList(chapter.subcapitulos[j].capituloInfo)

                                    }
                                }else{
                                    dispatch({
                                        type: 'CHECK_CAPITULOS',
                                        payload: {
                                            capituloId: chapter.subcapitulos[j].chapterId,
                                            value: true
                                        }
                                    })
                                    updateChaptersList(chapter.subcapitulos[j].capituloInfo)

                            }
                            }
                            trobat2=true
                            i++;
                        }
                    }else{
                        i++
                    }
                }else{
                    i++
                }
            }
        }
    }

    const createPartBankTree = async (data) =>{
 
        try{
            let payload = {
              headers: {
                'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                'Access-Control-Allow-Origin': '*'
              },
              body : {...data}
            }
            const partida = await API.post(ENDPOINT, '/createPartBankTree', payload)
            let aux = {...partida,
                elementInfo: null,
                chapterId: partida.chapterBankReferenceId
            }
            let partidaNueva = new Partida(aux)
            dispatch({
                type: 'ADD_PARTIDA',
                payload: {
                    chapterId: data.chapterBankReferenceId,
                    partidaNueva: partidaNueva,
                    index: data.position
                }
            })
        }

          catch(error){
            console.log(error)
          }
    }

    const deleteBankPart = async (capituloId, partidaId, accountId) => {
     
        try{
          let payload = {
            headers: {
              'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
              'Access-Control-Allow-Origin': '*'
            },
            body:{
              partBankId: partidaId,
              chapterId: capituloId=='SOYUNCAPITULOCONPARTIDASSINCLASIFICAR'? 'root' : capituloId,
              accountId: accountId
            }
          }
        
            const res = await API.del(ENDPOINT,'/deletePartBankTree', payload)
            dispatch({
                type: 'DEL_PARTIDA',
                payload: {
                    capituloId:capituloId=='root'? 'SOYUNCAPITULOCONPARTIDASSINCLASIFICAR' : capituloId,
                    partidaId
                }
            })

        }
        catch(error){
          console.log(error)
        }
        
     

}
const updatePartsList = (partida) => {
    let find = false;
    let i = 0;
    while(!find && i<state.partsChecked.length){
        if(state.partsChecked[i].partidaId==partida.partidaId){
            state.partsChecked.splice(i, 1)
            find=true
        }else{
            i++;
        }
    }
    if(find==false){
        state.partsChecked.push(partida)
    }

    dispatch({
        type: 'UPDATE_PARTS_LIST',
        payload: {
            list: state.partsChecked
        }
    })
}

const checkPartida = (capituloId, partidaId) => {
    dispatch({
        type: 'CHECK_PARTIDA',
        payload: {
            capituloId: capituloId=='root'? 'SOYUNCAPITULOCONPARTIDASSINCLASIFICAR' : capituloId,
            partidaId
        }
    })
}

const checkMultipleParts = async (chapterId, part) => {
    let chapterId2 = chapterId
    if(chapterId=='root'){
        chapterId2 = 'SOYUNCAPITULOCONPARTIDASSINCLASIFICAR'
    }
    let chapter = await getChapterById(chapterId2, state.myPricesBank.subcapitulos)
    if(chapter.partidas.length>0){
        //busqueda para el indice de la partida
        let indicePartida = await getPartIndex(chapter, part.partidaId)
        //busqueda para ver que partidas se han checkeado antes de (part)
        let i = 0
        let trobat1 =false
        let trobat2 = false
        while ((!trobat1 || !trobat2) && i<chapter.partidas.length) {
            if(chapter.partidas[i].partidaId!=part.partidaId){
                if(chapter.partidas[i].checked==true){
                    if(i<indicePartida){
                        for(let j=i+1;j<indicePartida+1;j++){
                            dispatch({
                                type: 'CHECK_PARTIDA',
                                payload: {
                                    capituloId: chapterId=='root'? 'SOYUNCAPITULOCONPARTIDASSINCLASIFICAR' : chapterId,
                                    partidaId: chapter.partidas[j].partidaId
                                }
                            })
                            state.partsChecked.push(chapter.partidas[j])
                            dispatch({
                                type: 'UPDATE_PARTS_LIST',
                                payload: {
                                    list: state.partsChecked
                                }
                            })
                        }
                        trobat1=true
                        i=indicePartida
                    }else{
                        let valorIni = indicePartida
                        for(let j=indicePartida;j<i;j++){
                            if(j==valorIni){
                                if(chapter.partidas[valorIni].checked==false){
                                    dispatch({
                                        type: 'CHECK_PARTIDA',
                                        payload: {
                                            capituloId: chapterId=='root'? 'SOYUNCAPITULOCONPARTIDASSINCLASIFICAR' : chapterId,
                                            partidaId: chapter.partidas[j].partidaId
                                        }
                                    })
                                    state.partsChecked.push(chapter.partidas[j])
                                    dispatch({
                                        type: 'UPDATE_PARTS_LIST',
                                        payload: {
                                            list: state.partsChecked
                                        }
                                    })
                                }
                            }else{
                            dispatch({
                                type: 'CHECK_PARTIDA',
                                payload: {
                                    capituloId: chapterId=='root'? 'SOYUNCAPITULOCONPARTIDASSINCLASIFICAR' : chapterId,
                                    partidaId: chapter.partidas[j].partidaId
                                }
                            })
                            state.partsChecked.push(chapter.partidas[j])
                            dispatch({
                                type: 'UPDATE_PARTS_LIST',
                                payload: {
                                    list: state.partsChecked
                                }
                            })
                        }
                        }
                        trobat2=true
                        i++;
                    }
                }else{
                    i++
                }
            }else{
                //esquivo la partida ya marcada y paso a la siguiente
                i++
            }
        }
    }
}
function selectPartsGroup(selectedParts, capitulos){
    for(let i=0; i<capitulos.length ; i++){
        if(capitulos[i].checked){
            dispatch({
                type: 'ERROR_CAPITULO',
                    payload: {
                        error: true
                    }
            })
            return false;
        }else{
            if(capitulos[i].partidas.length>0){
              let copia = []
              for(let j=0; j<capitulos[i].partidas.length; j++){
                if(capitulos[i].partidas[j].checked==true){
                  copia.push(capitulos[i].partidas[j])
                }
              }
              if(copia.length>0){
                if(!copia.isEmpty){
                    selectedParts.push(copia)
                }
              }

          }
          if(capitulos[i].subcapitulos.length>0){
              selectPartsGroup(selectedParts, capitulos[i].subcapitulos)
          }
        }
    }
      dispatch({
          type: 'SET_GROUP',
              payload: {
                  selectedParts: selectedParts,
                  error: false
              }
      })
}

const updatePartPrice = async (data) => {
    try{
      
        let headers ={
            'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
            'Access-Control-Allow-Origin': '*'
            }

            const res = await API.put(ENDPOINT, '/editPartBankTree', {headers: headers, body: {...data, partBankId: data.partidaId}})
            const partidaUpdated = {
                partidaId: data.partidaId,
                elementInfo: res.elementInfo
            }
            dispatch({
                type: 'UPDATE_PARTIDA',
                payload: {
                    newData: partidaUpdated,
                    capituloId: data.capituloId,
                    final: false
                }
            })
        

    }
    catch(e){
        console.log(e)
    }
}
const cleanParts = async() => {
    dispatch({
        type: 'UPDATE_PARTS_LIST',
        payload: {
            list: []
        }
    })
}

const cleanChapters = async () => {
    dispatch({
        type: 'UPDATE_CHAPTERS_LIST',
        payload: {
            list: []
        }
    })
}
function getSelectedChapters(selectedBudget, auxList, totalCaps, start){
    for(let i=0; i<totalCaps.length ; i++){
            if(totalCaps[i].checked){
                if(totalCaps[i].subcapitulos.length>0){
                    let auxChapter = {...totalCaps[i]}
                    auxChapter.subcapitulos = []
                    auxList.push(auxChapter)
                    getSelectedChapters(selectedBudget, auxList[auxList.length-1].subcapitulos, totalCaps[i].subcapitulos, start)
                    
                }else if(totalCaps[i].partidas.length>0){
                    let auxChapter = {...totalCaps[i]}
                    auxChapter.partidas = []
                    let copia = []
                    for(let j=0; j<totalCaps[i].partidas.length; j++){
                        if(totalCaps[i].partidas[j].checked==true){
                        copia.push(totalCaps[i].partidas[j])
                        }
                    }
                    auxChapter.partidas = copia
                    auxList.push(auxChapter)
                }else{
                    auxList.push(totalCaps[i])
                }
            }else{
                if(totalCaps[i].subcapitulos.length>0){
                getSelectedChapters(selectedBudget, auxList, totalCaps[i].subcapitulos, start)
                }
            }
}
if(auxList.length==0){
    selectedBudget=state.myPricesBank.subcapitulos
}
  dispatch({
      type: 'SET_SELECTED',
          payload: {
              selectedBudget: auxList
          }
  })
}


const importChaptersFromDatabase = async(chapters, projectId, accountId, chapterId, tipo) => {
    try{
        let headers ={
            'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
            'Access-Control-Allow-Origin': '*'
            }
            let body = {
                chapters: chapters,
                projectId: projectId,
                accountId: accountId,
                chapterId: chapterId
            }
            if(tipo=='USER_DATABASE'){
                const res = await API.post('service-budget', '/importChaptersFromDatabase', {headers: headers, body: body})
            }else if(tipo=='REFERENCE_DATABASE'){
                const res = await API.post('Planhopper-Bank-Prices', '/importChaptersFromBankReference', {headers: headers, body: body})
            }else if(tipo=='TEMPLATES'){
                const res = await API.post( 'service-templates', '/importChaptersFromTemplate', {headers: headers, body: body})
            }
    }
    catch(e){
        console.log(e)
    }
}

const importChapters2MBP = async(chapters, accountId, chapterId, tipo) => {
    try{
        let headers ={
            'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
            'Access-Control-Allow-Origin': '*'
            }
            let body = {
                chapters: chapters,
                accountId: accountId,
                chapterId: chapterId,
                comesFrom:tipo
            }
            const res = await API.post('Planhopper-Bank-Prices', '/moveToBankFromBankReference', {headers: headers, body: body})
    }
    catch(e){
        console.log(e)
    }
}

const importParts2MBP = async(parts, accountId, chapterId, tipo) => {
    try{
        let headers ={
            'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
            'Access-Control-Allow-Origin': '*'
            }
            let body = {
                parts: parts,
                accountId: accountId,
                chapterId: chapterId,
                comesFrom:tipo
            }
            const res = await API.post('Planhopper-Bank-Prices', '/importPartsToBank', {headers: headers, body: body})
    }
    catch(e){
        console.log(e)
    }
}

const uploadFile = async ({ file, presignedPostData }) => {
    try {
      const formData = new FormData()
      Object.keys(presignedPostData.fields).forEach(key => {
        formData.append(key, presignedPostData.fields[key])
      })
      formData.append("file", file)
      const res = await axios.post(presignedPostData.url, formData)
      return res
    } catch (error) {
      console.log(error)
    }
  }

const importExcel = async(data) => {

    try{
        let payload = {
            headers: {
              "Access-Control-Allow-Origin": "*",
              Authorization: `Bearer ${(await Auth.currentSession())
                .getIdToken()
                .getJwtToken()}`,
            },
            body: {
              filename: data.file.name,
              extension: data.file.name.split(".").slice(-1)[0],
              size: data.file.size,
              contentType: data.file.type,
              customDB: false,
                importedProject: false,
                tags: [],
                description: "",
                orgId: "",
                        },
          }
          const res1 = await API.post("dev-PLANHOPPER-API", "/api/file/upload", payload)
          const res2 = await uploadFile({ file: data.file, presignedPostData: res1.uploadInfo })

        let headers ={
            'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
            'Access-Control-Allow-Origin': '*'
            }
            let body = {
                key: res1.uploadInfo.fields.key,
                accountId: data.accountId
            }
            const res = await API.post(ENDPOINT, '/createPartBankFromExcel', {headers: headers, body: body})
    }
    catch(e){
        console.log(e)
    }
}

const createPartsOnBudget = async(accountId, parts, projectId, chapterId, capituloEntero) => {
        try{
            let auxChapterId
            if(chapterId=='root'){
                const path ='/chapter'
                let payload = {
                  headers: {
                    'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                    'Access-Control-Allow-Origin': '*'
                  },
                  body:{
                    name: 'PARTIDAS SIN CLASIFICAR',
                    description: '',
                    parentId: chapterId,
                    projectId: projectId,
                    price : 0,
                    tags: [],
                    status: 'OPEN', position: 0,
                    accountId: accountId
                  }
                }
                const res = await API.post('service-budget', path, payload)
                auxChapterId = res.chapterId
            }else{
                auxChapterId=chapterId
            }
            for(let i=0; i<parts.length; i++){
            let payload = {
                headers: {
                  'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                  'Access-Control-Allow-Origin': '*'
                },
                body:{
                  chapterId: auxChapterId,
                  code: parts[i].code,
                  name: parts[i].name,
                  description: parts[i].description,
                  quantity: 0,
                  userPrice: parseFloat(parts[i].userPrice ? parts[i].userPrice : parts[i].price),
                  price: parseFloat(parts[i].userPrice ? parts[i].userPrice : parts[i].price),
                  costeGeneral: 0,
                  costeIndirecto: 0,
                  industrialProfit: 0,
                  projectId: projectId,
                  status: 'OPEN',
                  position: chapterId=='root' ? i : capituloEntero.partidas.length+i ,
                  accountId: accountId,
                  refPrice: parseFloat(data.parts[i].price)
                }
              }
                const res = await API.post('service-budget', '/part', payload)
            }
        }
        catch(e){
            console.log(e)
        }
}

const getMyBankPricesWithoutClassify =  async (accountId) => {
    try {
        const headers = {
            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
            'Access-Control-Allow-Origin': '*',
        }
        const res = await API.get(ENDPOINT, `/getNonSpecifiedParts?accountId=`+accountId+`&parentId=root`, {headers: headers});
        let aux = {
            name: 'PARTIDAS SIN CLASIFICAR',
            description: '',
            parentId: 'root',
            price : 0,
            tags: [],
            accountId: accountId,
            code:null,
            root: true,
            position: state.myPricesBank.subcapitulos.length,
            parts: res.myParts,
            chapterBankReferenceId: 'SOYUNCAPITULOCONPARTIDASSINCLASIFICAR',
            chapterInfo: {
                name: 'PARTIDAS SIN CLASIFICAR',
                description: '',
                parentId: 'root',
                price : 0,
                tags: [],
                accountId: accountId,
                code:null,
                root:true,
                position: state.myPricesBank.subcapitulos.length,
                chapterBankReferenceId: 'SOYUNCAPITULOCONPARTIDASSINCLASIFICAR',
            }
        }
        if(res.myParts.length>0){
        
        dispatch({
            type: 'ADD_CAPITULO',
            payload: {
                parentId:aux.parentId,
                res:aux,
                indexCapitulo:aux.position
            }
        })}
  
    }
    catch (err) {
        console.log(err);
    }
}
function getSelectedChaptersToFav(selectedBudget, auxList, totalCaps, start, accountId){
    dispatch({
        type: LOADING_SUBCAPS
    })
    for(let i=0; i<totalCaps.length ; i++){
            if(totalCaps[i].checked){
                if(totalCaps[i].subcapitulos.length>0){
                    let auxChapter = {...totalCaps[i]}
                    auxChapter.subcapitulos = []
                    auxList.push(auxChapter)
                    getSelectedChaptersToFav(selectedBudget, auxList[auxList.length-1].subcapitulos, totalCaps[i].subcapitulos, start, accountId)
                    
                }else if(totalCaps[i].partidas.length>0){
                    let auxChapter = {...totalCaps[i]}
                    auxChapter.partidas = []
                    let copia = []
                    for(let j=0; j<totalCaps[i].partidas.length; j++){
                        if(totalCaps[i].partidas[j].checked==true){
                        copia.push(totalCaps[i].partidas[j])
                        }
                    }
                    auxChapter.partidas = copia
                    auxList.push(auxChapter)
                }else{
                    auxList.push(totalCaps[i])
                }
            }else{
                if(totalCaps[i].subcapitulos.length>0){
                getSelectedChaptersToFav(selectedBudget, auxList, totalCaps[i].subcapitulos, start, accountId)
                }
            }
}
if(auxList.length==0){
    selectedBudget=state.myPricesBank.subcapitulos
}
    addChaptersToFavorites(auxList, 'REFERENCE_DATABASE', accountId)
}

const addChaptersToFavorites = async(chapters, table, accountId) => {
    //REFERENCE_DATABASE, USER_DATABASE, TEMPLATES
    try{
        let payload = {
            headers: {
              'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
              'Access-Control-Allow-Origin': '*'
            },
            body:{
                chapters: chapters,
                table: table,   
                accountId: accountId
            }
          }
          for(let i=0; i<chapters.length; i++){
            const res = await API.del(ENDPOINT, '/favourites/'+accountId+'?favouriteId='+chapters[i].chapterId, payload)
            dispatch({
                type: 'DEL_CAPITULO',
                payload: chapters[i].chapterId
            })
          }
            dispatch({
                type: 'LOADING_SUBCAPS_SUCCESS',
            })
            cleanChapters()
    }
    catch(e){
        console.log(e)
    }
}


    return (
        <FavoritesBPContext.Provider
            value={{
                getMyBankPrices,
                myPricesBank: state.myPricesBank,
                loading: state.loading,
                loadingCaps: state.loadingCaps,
                getSubCaps,
                createInitialStructure,
                addBankChapter,
                updateChapterBank,
                getSelectedCategories,
                selectedCategories: state.selectedCategories,
                deleteCapitulo,
                checkCapitulos,
                updateChaptersList,
                checkMultipleChapters,
                categoriesChecked: state.categoriesChecked,
                createPartBankTree,
                deleteBankPart,
                checkPartida,
                checkMultipleParts,
                partsChecked: state.partsChecked,
                updatePartsList: updatePartsList,
                selectPartsGroup: selectPartsGroup,
                selectedParts: state.selectedParts,
                error: state.error,
                errorAgrupar: state.errorAgrupar,
                selectPartsGroup: selectPartsGroup,
                selectedPartGroup: state.selectedPartGroup,
                updatePartPrice, getSelectedChapters, selectedCategories2: state.selectedCategories2,
                importChaptersFromDatabase,
                importExcel,
                createPartsOnBudget,
                cleanParts,
                cleanChapters,
                getMyBankPricesWithoutClassify,
                myNonSpecifiedPricesBank: state.myNonSpecifiedPricesBank,
                getSelectedChaptersToFav,
                importChapters2MBP,importParts2MBP

            }}
        >
            {props.children}
        
        </FavoritesBPContext.Provider>
    )

}
FavoritesBPState.propTypes = {
    children: PropTypes.element.isRequired,
}

export default FavoritesBPState;