import React, {useContext, useEffect, useState} from "react"
import {HelveticaBold, HelveticaLight} from "common/typographies.js" 
import { ReactComponent as Obreroia }  from "assets/customIcons/obreroia.svg";
import { ReactComponent as CalculadoraIcon }  from "assets/customIcons/calculadora.svg";
import {ReactComponent as ArrowMeasures} from 'assets/customIcons/measuresArrow.svg'
import i18n from "i18n";
import MedicionesHeader from "./detallesMediciones/medicionesHeader";
import Medicion from "./detallesMediciones/medicion";
import PropTypes from "prop-types"
import ProjectContext from "context/projects/projectContext";
import { PriceInput } from "common/inputs";
import UnityDropdown from "./UnityDropdown";
import { useOpenAI } from "hooks/useOpenAI";
import OptionsPartDropdown from "./OptionsPartDropdown";
import ModalRegenerate from "./ModalRegenerate";
import Budget2023Context from "context/budget2023/Budget2023Context";
import { useSnackbar } from "notistack";
import ModalDestacarPalabras from "./ModalDestacarPalabras";
import { getCurrency, checkIfMeasuresAreEmpty } from "utils/functions"
import { calcPartida } from "utils/partUtils";
import AuthContext from "context/auth/authContext";
import { isNil } from "lodash";
import ExtrasAndContradictionsContext from "context/extrasAndContradictions/ExtrasAndContradictionsContext";

//import { NumericFormat } from 'react-number-format';

const LeftGridContent = ({ bancoDePrecios, setOpenModalGeneral,partidaState, setOpenSnackIA, handleClick, setErrorMessage, details, setDetails, setPartidaState, imInUpdate, partidaInfo, referenciaObject}) => {
    const {user} = useContext(AuthContext)
    const [enableEdit, setEnableEdit] = useState(false)
    const [toggleBenefits, setToggleBenefits] = useState(false)
    const [mediciones, setMediciones] = useState([])
    const [unity, setUnity] = useState('pa')
    const {projectInfo} = useContext(ProjectContext)
    const [possibleRegenerate, setPossibleRegenerate] = useState(false)
    const {
      copyBudgetData,
      copiedData,
      bc3LinkData,
      toggleModalImportPartFromBc3Link,
      setIsImportingPartFromEditModal,
    } = useContext(Budget2023Context)
  const { newModalPart } = useContext(ExtrasAndContradictionsContext)
    const { enqueueSnackbar } = useSnackbar()
    const [openModalDestacarPalabras, setOpenModalDestacarPalabras] = useState(false)

    useEffect(()=>{        
        if(imInUpdate || !isNil(bc3LinkData)){
            setEnableEdit(true) //DISABLE IA FOR NOW (IA DESACTIVADA POR AHORA)
            if(partidaInfo.measures.length>0){
                setToggleBenefits(true)
            }
            setMediciones(partidaInfo.measures)
            setTotalDeTotales(partidaInfo.quantity)
            setUnity(partidaInfo.unity)
  
            setPartidaState({...partidaState, unity:partidaInfo.unity, quantity:partidaInfo.quantity})
        }
        //DISABLE IA FOR NOW (IA DESACTIVADA POR AHORA)
        else{
            setEnableEdit(true)
        }
    },[])
    const [fieldsToHide, setFieldsToHide] = useState({
        depth:false,
        width:false,
        height:false
    })

    const importFromBc3Link = () => {
      toggleModalImportPartFromBc3Link(true)
      setIsImportingPartFromEditModal(true)
    }

    const addEmptyMedicion = () => {
        let newMedicion = {
            quantity:0,
            description:'',
            depth:0,
            width:0,
            height:0,
            total:0
        }

        setMediciones((prevArray) => [...prevArray, newMedicion]);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;

        setPartidaState({
            ...partidaState,
            [name]: value
        });        
    }

    const handleBlur = (e) => {
      const { name, value } = e.target

      switch (name) {
	      case 'costView': 
          partidaState = calcPartida(partidaState, 'price', parseFloat(value));
          break;
        case 'costTotalView':
          if (isNil(partidaState.quantity) || partidaState.quantity == 0) {
            partidaState = calcPartida(partidaState, 'quantity', 1)
          }
          const unitCost = parseFloat(value) / parseFloat(partidaState.quantity)
          partidaState = calcPartida(partidaState, 'price', unitCost)
          break;
        case 'quantityView': 
          partidaState = calcPartida(partidaState, 'quantity', parseFloat(value))
          break;
      }

      // If name ends with 'View' and value is empty, remove the key from the state
      // So view gets reset to the calculated value
      if (name.endsWith('View')) {
        setPartidaState(_.omit(partidaState, name))
	  } else {
        setPartidaState({
            ...partidaState,
            [name]: parseFloat(value).toFixed(2)
        })
      }
    }

    //console.log('mediciones =', mediciones)
    let emptyMeasures = checkIfMeasuresAreEmpty(mediciones)
    //console.log('emptyMeasures =', emptyMeasures)

//    const removeEmptyMeasures = () => {
//	    let newMeasures = []
//    	for(let i=0; i<mediciones.length; i++){
//            console.log('mediciones[i] =', mediciones[i])
//    	    if(!emptyMeasure(mediciones[i])){
//           		newMeasures.push(mediciones[i])
//    	    } else {
//                console.log('empty')
//            }
//    	}
//    	setMediciones(newMeasures)
//    }
//    
//    useEffect(()=>{
//        if(emptyMeasures){
//            setMediciones([])
//        } else {
//            removeEmptyMeasures()
//        }
//    },[emptyMeasures])

    useEffect(()=>{
        if(referenciaObject.referenciaName.current){
            if(imInUpdate || !isNil(bc3LinkData)){
            referenciaObject.referenciaName.current.innerText=partidaState.name
            referenciaObject.referenciaDescripcion.current.value=partidaState.description
            }
        }
      
    },[referenciaObject.referenciaName])

    const [totalDeTotales, setTotalDeTotales] = useState(0)

    useEffect(()=>{
        if(mediciones.length==1){
            if(mediciones[0].description==0 && mediciones[0].quantity==0 && mediciones[0].depth==0 &&  mediciones[0].height==0 && mediciones[0].total==0){
                setMediciones([])
            }
        }
    },[toggleBenefits])

    useEffect(()=>{
        let priceDetailsSum = details.reduce((acc, m) => { acc += parseFloat(m.total); return acc }, 0)
        let priceMaterial = details.reduce((acc, m) => { m.type == "material" ? acc += parseFloat(m.total) : acc; return acc }, 0)
        let priceManoDeObra = details.reduce((acc, m) => { m.type == "manodeobra" ? acc += parseFloat(m.total) : acc; return acc }, 0)
        let priceOther = details.reduce((acc, m) => { (m.type != "material" && m.type != "manodeobra") ? acc += parseFloat(m.total) : acc; return acc }, 0)

        setPartidaState({...partidaState,
		price:priceDetailsSum,
		priceMaterial:priceMaterial,
		priceManoDeObra:priceManoDeObra,
		priceOther:priceOther
	})
    },[details])

    useEffect(()=>{

      // Why does this use auxmediciones and a for instead of mediciones.forEach?
      let measures_aux = []
      mediciones.forEach((m, i) => {
        let measure = {  // Copy the measure to avoid modifying the original
	  description: m.description,
          quantity: m.quantity ? parseFloat(m.quantity) : 0,
          depth: m.depth ? parseFloat(m.depth) : 0,
          width: m.width ? parseFloat(m.width) : 0,
          height: m.height ? parseFloat(m.height) : 0,
          total: m.total ? parseFloat(m.total) : 0
        }
        measures_aux.push(measure)
      })

      let total = measures_aux.reduce((acc, m) => { acc += m.total; return acc }, 0)
      setTotalDeTotales(total)

      if (imInUpdate || !isNil(bc3LinkData)) {
        if (total==0) {
          setPartidaState({...partidaState,
      			measures:measures_aux,
      			unity:unity})
        } else {
          setPartidaState({...partidaState,
      			measures:measures_aux,
      			unity: unity,
      			unidades: total,
      			quantity: total})
        }
      } else {
        setPartidaState({...partidaState,
			    measures:measures_aux,
			    unity:unity,
			    unidades:total,
			    quantity:total})
      }

    },[mediciones, unity])  // End of useEffect

    const openai = useOpenAI()
    const [model, setModel] = useState('CURIE');
    const validate = () => {
        const words = partidaState.name.split(' ');
        if (words.length < 3) {
            referenciaObject.referenciaName.current.style.border = '1px solid red'
            setErrorMessage('Para generar una partida es necesario introducir al menos 3 palabras');
            handleClick();
            return false;
        }
        return true;
    }

    const handleGenerateIAPart = () => {
        if (validate()) {
            referenciaObject.referenciaName.current.style.border = '1px solid #C4C4C4'
            try {
                setOpenSnackIA(true);
                setPossibleRegenerate(true)
                openai.handleSubmit({text: partidaState.name, model: model});
            } catch (error) {
                console.log(error);
            }
        
        }else{
            referenciaObject.referenciaName.current.style.border = '1px solid red'
        }
      
    }

    useEffect(()=>{
        if(openai.currentPart){
            setEnableEdit(true)
            let price = openai.currentPart.price
            let quantity = 1
            let unity = openai.currentPart.unity
            let description = openai.currentPart.description
            referenciaObject.referenciaDescripcion.current.innerText=description
            let detailsAux = []
            if(openai.currentPart.decomposition){
                openai.currentPart.decomposition.forEach(details => {
                    let auxDetail = {
          
                        type: details.type == '0' ? 'coste' :
                        details.type == '1' ? 'manodeobra' :
                        details.type == '2' ? 'maquinaria' :
                        details.type == '4' ? 'residuoAdicional' :
                        details.type == '5' ? 'residuoClasificacion' :
                        details.type == '3' ? 'material' :
                        'material',
                        description: details.name,
                        price: details.price.toString(),
                        unity: details.unity,
                        quantity: details.quantity.toString(),
                        total: parseFloat(details.total).toFixed(2)
                    }
                    detailsAux.push(auxDetail)
                });
            }
            setUnity(unity)
            setPartidaState({...partidaState, price:price, quantity:quantity, unity:unity, description:description})
            setDetails(detailsAux)
        }
        if(openai.generating==false){
            setOpenSnackIA(false)
            if(openai.currentPart){
            setOpenRegenerate(true)
            }
        }
    },[openai.currentPart])

    const [openRegenerate, setOpenRegenerate] = useState(false)
    return(
        <>
        <div style={{height:'100%', position:'relative'}}>
            <div style={{
                width: "100%", height: "61px", backgroundColor: '#D9D9D9',
                borderTopLeftRadius: '10px', display: 'flex', alignItems: 'center', gap: '2px', padding: '15px',
                justifyContent:"space-between"
            }}>
                <a style={{fontFamily:'Helvetica', fontWeight:'700', fontSize:'14px', color:'#414141'}}>
                {`${projectInfo?. name} ${partidaInfo?.ref} ${isNil(partidaInfo?.code)? '' : partidaInfo?.code}`}
                </a>
                {
                    possibleRegenerate ?
                    <button onClick={()=>{handleGenerateIAPart()}} style={{ marginLeft:'auto', marginRight:'30px', width:'102px', height:'38px', backgroundColor:'#FFD52B'}} className="AddContact">
                        {i18n.t("Regenerar")}
                    </button>
                    :
                    null

                }
                <div style={{
                    display:"flex",
                    justifyContent:"flex-end",
                    gap: 1
                }}>                
	    	        {
                        <button
                        className="nuevoBotonCrear4"
                        style={{ marginTop: "10px", marginBottom: "10px", marginRight:"30px" }}
                        onClick={()=>{importFromBc3Link()}}
                        >{i18n.t("importBC3Link.link")}</button>
                }
                    <div style={{position:'absolute', right:'10px'}}>
                        {
                        bancoDePrecios=='BANCO DE PRECIOS REFERENCIA' ? null :
                        
                        <OptionsPartDropdown bancoDePrecios={bancoDePrecios} setOpenModalGeneral={setOpenModalGeneral} imInUpdate={imInUpdate} partidaInfo={partidaInfo} partidaState={partidaState} setPartidaState={setPartidaState} />}
                    </div>
                </div>
            </div>
            <div style={{overflowY:'auto',maxHeight:'calc(100vh - 190px)' ,height: 'calc(100% - 151px)',padding:'15px'}}>
                <HelveticaBold style={{color:'#414141', fontSize:'14px', marginLeft:'10px', marginTop:'0px', marginBottom:'10px'}}>{i18n.t("Descripción corta")}</HelveticaBold>
                <textarea
                        ref={referenciaObject.referenciaName}
                        suppressContentEditableWarning={true}
                        contentEditable="true"
                        id="descripcionInput"
                        disabled={bancoDePrecios=='BANCO DE PRECIOS REFERENCIA' ? true : false}
                        name="name"
                        onChange={(e)=>{handleChange(e)}}
                        placeholder="Escribe tu mensaje"
                        style={{width:'100%',minHeight:'60px', height:'13%', position:'relative',paddingTop:'10px', paddingLeft:'10px', resize:'none'}}
                />
                <div className="inline">
                    <HelveticaBold style={{color:'#414141', fontSize:'14px', marginLeft:'10px', marginTop:'0px', marginBottom:'10px'}}>{i18n.t("Descripción larga")}</HelveticaBold>
                  {!bancoDePrecios && <button onClick={()=>{setOpenModalDestacarPalabras(true)}} className="nuevoBotonCrear4" style={{marginLeft:'15px', border:'1px solid #414141', width:'fit-content', marginTop:'0px', height:'22px'}}>
                    {
                        partidaState.comment.length>0 ?
                        i18n.t('Modificar palabras destacadas')
                        :
                        i18n.t('Añadir palabras destacadas')

                    }
                    </button>}
                </div>
                <div style={{ height: toggleBenefits && enableEdit ? '20%' : '60%', position:'relative'}}>
                    <textarea
                            ref={referenciaObject.referenciaDescripcion}
                            suppressContentEditableWarning={true}
                            disabled={!enableEdit ? true : bancoDePrecios=='BANCO DE PRECIOS REFERENCIA' ? true : false} 
                            onChange={(e)=>{handleChange(e)}}
                            id="descripcionInput"
                            onBlur={(e)=>{handleChange(e)}}
                            name="description"
                            placeholder="Escribe tu mensaje"
                            style={{width:'100%' ,height: '100%', position:'relative',paddingTop:'10px', paddingLeft:'10px', resize:'none'}}
                    />
                    {   
                        !enableEdit ? 
                        <div style={{display:'flex', position: "absolute", width:'100%', zIndex:'9', marginTop:'-207px',
                                  textAlign:'center'  ,justifyContent:'center', flexDirection:'column', alignItems:'center'}}>
                            <Obreroia/>
                            <p style={{marginBottom:'0px'}}>{i18n.t("Generar descripción ")}</p>
                            <p>{i18n.t("detallada automáticamente")}</p>
                            <div style={{display:'flex', gap:'10px'}}>
                                <button onClick={()=>{setEnableEdit(true)}}
                                    style={{marginLeft:'auto', marginRight:'0px', width:'102px', height:'38px', backgroundColor:'white', border:'1px solid #FFD52B'}} className="AddContact">
                                    {i18n.t("Manual")}
                                </button>
                                <button onClick={()=>{handleGenerateIAPart()}} style={{marginLeft:'auto', marginRight:'0px', width:'102px', height:'38px', backgroundColor:'#FFD52B'}} className="AddContact">
                                    {i18n.t("Generar")}
                                </button>
                            </div>
                        </div>
                        :
                        null
                    }
                   
                </div>
                
                {
                    toggleBenefits && enableEdit ? 
                    <div>
                        <div className="inline">
                            <HelveticaBold style={{color:'#414141', fontSize:'14px', marginLeft:'10px', marginTop:'10px', marginBottom:'14px'}}>{i18n.t("Detalle de Mediciones")}</HelveticaBold>
                            <button onClick={()=>{copyBudgetData({measures:mediciones}); 
                            enqueueSnackbar('Mediciones copiadas', { variant: 'success',
                            autoHideDuration: 2000, })}} className="nuevoBotonCrear4" style={{marginLeft:'15px', border:'1px solid #414141', width:'fit-content', marginTop:'10px', height:'22px'}}>
                                    {i18n.t('Copiar mediciones')}
                            </button>        
                            {
                                copiedData &&      
                                 <button onClick={()=>{setMediciones(copiedData.measures);  copyBudgetData(null)
                                }} className="nuevoBotonCrear4" style={{marginLeft:'15px', border:'1px solid #414141', width:'fit-content', marginTop:'10px', height:'22px'}}>
                                        {i18n.t('Pegar mediciones')}
                                </button>        
                            }
                        </div> 
                        <div style={{width:'100%', border:'1px solid #C4C4C4', borderRadius:'6px', padding:'10px'}}>
                            <MedicionesHeader fieldsToHide={{}} unity={unity}/>
                            <div style={{width:'100%'}}>
                                {
                                    mediciones.length > 0 ? 
                                        mediciones.map((medicion, index) => (
                                            <Medicion fieldsToHide={{}} key={index} medicion={medicion} mediciones={mediciones} index={index} setMediciones={setMediciones}/>
                                        ))
                                    : null
                                    /*addEmptyMedicion()*/
                                }  
                            </div>  
                            <button onClick={()=>{addEmptyMedicion()}} className="nuevoBotonCrear4" style={{marginTop:'10px', width:'fit-content'}}>
                                {i18n.t('+  Nueva medición')}
                            </button>                      
                          
                        </div>
                    </div>
                    : null
                }
            </div> 

            <div style={{position:'absolute', width:'100%', height:'90px', display:'flex', borderBottomLeftRadius:'10px', borderTop:'1px solid #C4C4C4', background:'white'}}>

            {  !bancoDePrecios &&
              <div style={{borderRight:'1px solid #C4C4C4', justifyContent:'center', display:'flex', flexDirection:'column',
                  width: '30%', textAlign:'center', alignItems:'center'}}>
                <div style={{display:'flex', gap:'5px'}}>
                  <HelveticaBold style={{color:'#414141', fontSize:'14px', marginTop:'1px', marginBottom:'14px'}}>{i18n.t("Mediciones")}</HelveticaBold>
                  { toggleBenefits
                      ? <ArrowMeasures style={{cursor:'pointer'}} onClick={()=>{setToggleBenefits(!toggleBenefits); setEnableEdit(true)}}/>
                      : <CalculadoraIcon style={{cursor:'pointer'}} onClick={()=>{setToggleBenefits(!toggleBenefits); setEnableEdit(true)}}/>}
                </div>
                { (mediciones.length > 0 && !checkIfMeasuresAreEmpty(mediciones))
                    ? /* <NumericFormat allowedDecimalSeparators={[',']} decimalSeparator="," value={totalDeTotales} displayType={'text'} /> */
                      <p style={{cursor:'default'}}>{totalDeTotales.toString().replace('.', ',')}</p>
                    : 
                      <div className="inline" style={{width:'100%', justifyContent:'center', position:'relative', textAlign:'center'}}>
                      <PriceInput name="quantityView" type="number" 
                        style={{width:'90%', padding: '0',position:'relative', fontSize:'14px', marginTop:'-10px', marginBottom:'5px', textAlign:'center'}}
                        value={partidaState.hasOwnProperty('quantityView')
                          ? partidaState.quantityView
                          : partidaState.quantity}
			onFocus={e => e.target.select()}
              		onKeyDown={(e)=>{if(e.key === 'Enter') e.target.blur()}}
                        onChange={(e)=>{handleChange(e)}} 
                        onBlur={(e)=>{handleBlur(e)}} />
                      </div>
                }                   
                </div>
            }

            <div style={{borderRight:'1px solid #C4C4C4', justifyContent:'center', display:'flex', flexDirection:'column', 
                width:  bancoDePrecios ?  '50%' :  '20%', textAlign:'center'}}>
              <HelveticaBold style={{color:'#414141', fontSize:'14px', marginTop:'2px', marginBottom:'13px'}}>{i18n.t("Unidad")}</HelveticaBold>
              <div style={{width:'100%', display:'flex', justifyContent:'center'}}>
              { bancoDePrecios=='BANCO DE PRECIOS REFERENCIA'
                  ? <p style={{color:'#414141', textTransform:'none', marginLeft:'15px', fontSize:'14px', fontWeight:'400'}}>{unity}</p>
                  : <UnityDropdown unity={unity} setUnity={setUnity}/>}
              </div>
            </div>

              <div style={{borderRight:'1px solid #C4C4C4', justifyContent:'center', display:'flex', flexDirection:'column',
                            backgroundColor: details.length>0 ? '#F2F2F2' : 'white',
                            width: bancoDePrecios ? '50%' : '25%', textAlign:'center'}}>
                <HelveticaBold style={{color:'#414141', position:'relative', fontSize:'14px', marginTop:'-1px', marginBottom:'15px'}}>
                  {i18n.t("Coste Unitario")}</HelveticaBold>
                <div className="inline" style={{width:'100%', justifyContent:'center', position:'relative', textAlign:'center'}}>
                  <PriceInput name="costView" type="number"
                        style={{width:'70%',position:'relative', fontSize:'14px', marginTop:'-11px', textAlign:'center',
                          backgroundColor: details.length>0 ? '#F2F2F2' : 'white' }}
                        disabled={(bancoDePrecios=='BANCO DE PRECIOS REFERENCIA' || details.length > 0) ? true : false}
                        value={partidaState.hasOwnProperty('costView')
				                        ? partidaState.costView
                                : parseFloat(partidaState.price).toFixed(2)}
			                  onFocus={e => e.target.select()}
              		      onKeyDown={(e)=>{if(e.key === 'Enter') e.target.blur()}}
			                  onChange={(e)=>{handleChange(e)}} 
	    		              onBlur={(e)=>{handleBlur(e)}} />
                  <HelveticaBold style={{color:'#414141', fontSize:'14px', marginTop:'-1.5px', marginBottom:'15px', fontWeight:'400', marginLeft:'5px'}}>
                    {getCurrency(user['custom:currency'])}</HelveticaBold>
                </div>
              </div>

   	          <div style={{ borderRight: "1px solid #C4C4C4", justifyContent: "center", display: "flex", flexDirection: "column",
                  width: bancoDePrecios ? "100%" : "25%", backgroundColor: details.length > 0 ? "#F2F2F2" : "white", textAlign: "center" }}>
                <HelveticaBold style={{color: "#414141", fontSize: "14px", marginTop: "0px", marginBottom: "10px"}}>{i18n.t("Coste Total")}</HelveticaBold>
                <div className="inline" style={{width:'100%', justifyContent:'center', position:'relative', textAlign:'center'}}>
                { bancoDePrecios 
                  ? (<p>{parseFloat(partidaState.price).toFixed(2)}{" "}
                    {getCurrency(user["custom:currency"])}</p>)
                  : (

                    <PriceInput name="costTotalView" type="number" 
                        style={{width:'70%',position:'relative', fontSize:'14px', marginTop:'-11px', textAlign:'center',
                          backgroundColor: details.length>0 ? '#F2F2F2' : 'white'}}
                        disabled={details.length > 0 ? true : false}
                        value={partidaState.hasOwnProperty('costTotalView')
				                        ? partidaState.costTotalView
                                : parseFloat(partidaState.quantity * partidaState.price).toFixed(2)}
			                  onFocus={e => e.target.select()}
              		      onKeyDown={(e)=>{if(e.key === 'Enter') e.target.blur()}}
                        onChange={(e)=>{handleChange(e)}}
                        onBlur={(e)=>{handleBlur(e)}}
                    />

                  )}
                <HelveticaBold style={{color:'#414141', fontSize:'14px', marginTop:'-1.5px', marginBottom:'15px', fontWeight:'400', marginLeft:'5px'}}>
                    {getCurrency(user['custom:currency'])}</HelveticaBold>
                  </div>
              </div>

           </div>       
        </div>
        {
            openRegenerate && <ModalRegenerate open={openRegenerate} setOpen={setOpenRegenerate} handleGenerateIAPart={handleGenerateIAPart} openai={openai}/>
        }
        
         <ModalDestacarPalabras open={openModalDestacarPalabras} setOpen={setOpenModalDestacarPalabras} partidaState={partidaState} setPartidaState={setPartidaState}/>
        
        </>
            
    )
}

LeftGridContent.propTypes = {
    partidaState: PropTypes.object,
    setPartidaState: PropTypes.func,
    imInUpdate: PropTypes.bool,
    partidaInfo: PropTypes.object,
    referenciaObject: PropTypes.object,
    details: PropTypes.array,
    setDetails: PropTypes.func,
    setErrorMessage: PropTypes.func,
    handleClick: PropTypes.func,
    setOpenSnackIA: PropTypes.func,
    setOpenModalGeneral: PropTypes.func,
    bancoDePrecios: PropTypes.string
}

export default LeftGridContent
