import React, { useContext, useEffect, useState } from "react"
import { ReactComponent as Details } from "assets/customIcons/details.svg"
import { ReactComponent as Files } from "assets/customIcons/files.svg"
import { ReactComponent as Layers } from "assets/customIcons/layers.svg"
import { ReactComponent as Certificates } from "assets/customIcons/certificates.svg"
import { HelveticaBold } from "common/typographies.js"
import PropTypes from "prop-types"
import i18n from "i18n"
import { v4 as uuidv4 } from "uuid"
import PartDetails from "./detallesPartida/partDetails"
import Budget2023Context from "context/budget2023/Budget2023Context"
import { CircularProgress } from "@mui/material"
import validateFields from "./validateFields"
import FileIndex from "./fileHandler/fileIndex"
import BankPricesContext from "context/bankPrices/bankPricesContext"
import ProjectContext from "context/projects/projectContext"
import PriceSearcher from "./priceSearch"
import { getCurrency, partidaGetClientPrice } from "utils/functions"
import AuthContext from "context/auth/authContext"
import { isNil } from "lodash";
import { PriceInput } from "common/inputs";
import { API, Auth } from "aws-amplify"
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min"
import ExtrasAndContradictionsContext from "context/extrasAndContradictions/ExtrasAndContradictionsContext"
import { getChapterById } from "utils/budgetUtils"
import DecompositionTypeEnum from "common/DecompositionTypeEnum"
import { calcCostTotal, calcPriceTotal, marginCalc, calcPartida } from "utils/partUtils";
import { PartInput } from "components/Part/PartInput";
import Tooltip from '@mui/material/Tooltip';

const decompositionTypeMap = {
  0: 'coste',
  1: 'manodeobra',
  2: 'maquinaria',
  3: 'material',
  4: 'residuoAdicional',
  5: 'residuoClasificacion'  
};

const RightGridContent = ({
  bancoDePrecios,
  partidaState,
  details,
  setDetails,
  handleClick,
  setErrorMessage,
  setPartidaState,
  imInUpdate,
  setOpen,
  referenciaObject,
  srcPage,
}) => {
const history = useHistory()

  const contents =
    bancoDePrecios == "BANCO DE PRECIOS REFERENCIA"
      ? ["Details"]
      : ["Details", "Files", "Layers", "Certificates", "PartSearcher"]
  const [content, setContent] = useState("Details")
  const {
    createPartidaV2,
    updatePartidav2,
    bc3LinkData,
    setBc3LinkData,
    setIsImportingPartFromEditModal,
    setChapterIdSelectedFromBc3Link,
    isContradictingPart,
    setIsContradictingPart,
    isBudgetClosed,
  } = useContext(Budget2023Context)
  const [loading, setLoading] = useState(false)
  const { createPartBankTree, updatePartPrice, getPartsLenghtByChapterId } =
    useContext(BankPricesContext)
  const { projectInfo } = useContext(ProjectContext)
  const { user } = useContext(AuthContext)
  const { projectId } = useParams()
  const {
    newModalPart,
    tree
  } = useContext(ExtrasAndContradictionsContext)

  const SERVICE_CORE_BASE = "service-core-base"
  
  const transformTypeToNumber = (type) => {
    return Object.keys(decompositionTypeMap).find(key => decompositionTypeMap[key] === type) || 3;
};

  const handleContradictingPart = async () => {
    try {
      setIsContradictingPart(false)
      const { partidaId, ...partidaStateForBody } = partidaState
      partidaStateForBody.decomposition = details.map(item => {
        return {
          ...item,
          price: parseFloat(item.price),
          quantity: parseFloat(item.quantity),
          type: item.type ? DecompositionTypeEnum[item.type] : DecompositionTypeEnum.material,
        }
      })
      let payload = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
          "Access-Control-Allow-Origin": "*",
        },
        body: partidaStateForBody,
      }
      const resp = await API.post(
        SERVICE_CORE_BASE,
        `/extras/contradict/${partidaId}`,
        payload
      )
      setOpen(false)
      history.push(`/extras/${projectId}`)
    } catch (error) {
      console.log(error)
      setOpen(false)
    }
  }

  const handleCreatePartida = async () => {
    setLoading(true)
    if (validateFields(partidaState, referenciaObject, setErrorMessage)) {
      if (!bancoDePrecios) {
        const extraChapter = newModalPart?.openModal ? await getChapterById(partidaState.chapterId, tree.chapters) : null
        await createPartidaV2(
          partidaState.accountId,
          partidaState.chapterId,
          partidaState.projectId,
          "last",
          undefined,
          { ...partidaState, decomposition: details },
          extraChapter
        )
      } else {
        const position = await getPartsLenghtByChapterId(partidaState.chapterId)
        await createPartBankTree({
          ...partidaState,
          decomposition: details,
          chapterBankId: partidaState.chapterId,
          userPrice: parseFloat(partidaState.price),
          quantity: parseFloat(partidaState.quantity),
          price: parseFloat(partidaState.price),
          code: uuidv4(),
          position: position,
        })
      }
      setLoading(false)
      setOpen(false)
    } else {
      handleClick()
      setLoading(false)
    }
    setIsImportingPartFromEditModal(false)
    setBc3LinkData(null)
    setChapterIdSelectedFromBc3Link(null)
    setDetails([])
  }

  const handleUpdatePartida = async () => {
    setLoading(true)
    if (!bancoDePrecios) {
      await updatePartidav2(
        {
          ...partidaState,
          decomposition: details,
          costeIndirecto: 0,
          industrialProfit: 0,
        },
        partidaState.chapterId,
        partidaState.accountId,
        false
      )
    } else {
      await updatePartPrice(
        {
          ...partidaState,
          decomposition: details,
          costeIndirecto: 0,
          industrialProfit: 0,
          userPrice: parseFloat(partidaState.price),
          quantity: parseFloat(partidaState.quantity),
          price: parseFloat(partidaState.price),
          code: uuidv4(),
          position: 0,
        },
        partidaState.chapterId,
        partidaState.accountId,
        false
      )
    }

    setLoading(false)
    setOpen(false)
    setIsImportingPartFromEditModal(false)
    setBc3LinkData(null)
    setChapterIdSelectedFromBc3Link(null)
    setDetails([])
  }

  const currencySymbol = getCurrency(user["custom:currency"])

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(`handleChange: ${name} => ${value}`)
    switch(name) {
      case "clientPrice":
        // Calculate margin based on client price
        let clientPrice = parseFloat(value)
        let cost = partidaState.price * partidaState.quantity
        let margin = 100 * (1 - cost / clientPrice)
        console.log(`margin => ${margin}`)
        setPartidaState({
          ...partidaState,
          margin: cost ? margin : 0,
          clientPrice: clientPrice
        });
        break;
    }
  }

  const handleViewChange = (e) => {
    const { name, value } = e.target
    console.log(`handleViewChange: ${name} => ${value}`)

    // TODO: Use partidaViewState instead of partidaState ?
    setPartidaState({
      ...partidaState,
      [name]: value
    })
  }

  const handleViewBlur = (e) => {
    var { name, value } = e.target
    console.log(`handleViewBlur ${name} => ${value}`)

    switch (name) {
      case 'marginPercView':
            if (value < 0) { value = 0 }
            if (value >= 100) { value = 99.99 }

            partidaState = calcPartida(partidaState, 'margin', parseFloat(value))
            break;
      case 'priceView': 
            partidaState = calcPartida(partidaState, 'clientPrice', parseFloat(value))
            break;
      case 'marginPriceView':
            // Pf = Pc / (1 - m/100)
            // Pf = Pc + Mp
            var priceTotal = parseFloat(value) + calcCostTotal(partidaState)
            var clientPrice = priceTotal / parseFloat(partidaState.quantity ? partidaState.quantity : 1)
            partidaState = calcPartida(partidaState, 'clientPrice', clientPrice)
            break;
      case 'priceTotalView': 
            var price = parseFloat(value) / parseFloat(partidaState.quantity ? partidaState.quantity : 1)
            partidaState = calcPartida(partidaState, 'clientPrice', price)
            break;
    }

    // If name ends with 'View' and value is empty, remove the key from the state
    // So view gets reset to the calculated value
    if (name.endsWith('View')) {
        console.log(`Removing ${name} from state`)
        setPartidaState(_.omit(partidaState, name))
        return
    } else {
        setPartidaState({
            ...partidaState,
            [name]: value
            })
    }

    // Format to 2 decimal places
//    setPartidaState({
//      ...partidaState,
//      [name]: parseFloat(value).toFixed(2)
//    })
  }

  const [creatingDetailInViewMode, setCreatingDetailInViewMode] =
    useState(false)

  useEffect(() => {
    if (imInUpdate || !isNil(bc3LinkData)) {
      if (partidaState.decomposition) {
        let aux = partidaState.decomposition
        for (let i = 0; i < partidaState.decomposition.length; i++) {
          aux[i].total = parseFloat(partidaState.decomposition[i].price) * parseFloat(partidaState.decomposition[i].quantity)
          aux[i].price = partidaState.decomposition[i].price
          aux[i].quantity = partidaState.decomposition[i].quantity
          aux[i].unity = partidaState.decomposition[i].unity.toString()
          aux[i].description = partidaState.decomposition[i].description.toString()

	        let typeEnumStr;
	        switch(partidaState.decomposition[i].type) {
            case 0: typeEnumStr = "coste"; break;
            case 1: typeEnumStr = "manodeobra"; break;
            case 2: typeEnumStr = "maquinaria"; break;
            case 3: typeEnumStr = "material"; break;
            case 4: typeEnumStr = "residuoAdicional"; break;
            case 5: typeEnumStr = "residuoClasificacion"; break;
          }
          console.log("typeEnumStr", typeEnumStr)
          aux[i].type = typeEnumStr
        }
        setDetails(aux)
        setCreatingDetailInViewMode(false)
      }
    } else {
      setCreatingDetailInViewMode(false)
    }
  }, [partidaState.decomposition])

  const getSvg = cont => {
    if (cont == "Details") {
      return (
        <>
          <Details fill-opacity={cont == content ? 1 : 0.5} />
          <a style={{ opacity: cont == content ? 1 : 0.5 }}>Detalle partida</a>
        </>
      )
    } else if (cont == "Files") {
      return (
        <>
          <Files fill-opacity={cont == content ? 1 : 0.5} />
          <a style={{ opacity: cont == content ? 1 : 0.5 }}>
            Archivos adjuntos
          </a>
        </>
      )
    } else if (cont == "Layers") {
      return (
        <>
          <Layers fill-opacity={cont == content ? 1 : 0.5} />
          <a style={{ opacity: cont == content ? 1 : 0.5 }}>Pliegos</a>
        </>
      )
    } else if (cont == "Certificates") {
      return (
        <>
          <Certificates fill-opacity={cont == content ? 1 : 0.5} />
          <a style={{ opacity: cont == content ? 1 : 0.5 }}>Certificados</a>
        </>
      )
    }
    if (cont == "PartSearcher") {
      return (
        <>
          <Details fill-opacity={cont == content ? 1 : 0.5} />
          <a style={{ opacity: cont == content ? 1 : 0.5 }}>Buscar precio</a>
        </>
      )
    }
  }

  return (
    <div style={{ height: "100%" }}>
      <div
        style={{
          width: "100%",
          height: "61px",
          backgroundColor: "#D9D9D9",
          borderTopRightRadius: "10px",
          display: "flex",
          alignItems: "center",
          paddingLeft: "16px",
          paddingRight: "20px",
        }}
      >
        <ul
          style={{
            display: "flex",
            listStyle: "none",
            marginBottom: "0",
            gap: "18px",
            padding: "0",
            height: "100%",
          }}
        >
          {contents.map(
            (c, index) =>
              c !== "Layers" &&
              c !== "Certificates" &&
              (imInUpdate || c !== "PartSearcher") && (
                <li
                  key={index}
                  style={{
                    display: "flex",
                    gap: "6px",
                    cursor: "pointer",
                    height: "92%",
                    borderBottom: c == content ? "2px solid #414141" : "none",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    setContent(c)
                  }}
                >
                  {getSvg(c)}
                </li>
              )
          )}
        </ul>
        {loading ? (
          <CircularProgress
            size={24}
            style={{ marginLeft: "auto", marginRight: "0px" }}
          />
        ) : bancoDePrecios == "BANCO DE PRECIOS REFERENCIA" ? null : (
          <Tooltip title={
              (isBudgetClosed && !isContradictingPart 
                  && srcPage != "extras" && srcPage != "priceBanks")
                ? "No es posible editar una partida cuando el proyecto está cerrado"
                : ""}>
            <button
              disabled={(isBudgetClosed && !isContradictingPart 
                  && srcPage != "extras" && srcPage != "priceBanks")}
              onClick={() => {
                isContradictingPart ? handleContradictingPart() : (imInUpdate ? handleUpdatePartida() : handleCreatePartida())
              }}
              style={{
                marginLeft: "auto",
                marginRight: "0px",
                width: "102px",
                height: "38px",
                backgroundColor: (isBudgetClosed && !isContradictingPart 
                    && srcPage != "extras" && srcPage != "priceBanks") ? "#FFFFFF" : "#FFD52B",
                }}
              className="AddContact"
            >
              {i18n.t("Guardar")}
            </button>
          </Tooltip>
        )}
      </div>
      <div style={{ height: "calc(100% - 151px)" }}>
        {content == "Details" ? (
          <PartDetails
            bancoDePrecios={bancoDePrecios}
            partidaState={partidaState}
            creatingDetailInViewMode={creatingDetailInViewMode}
            setCreatingDetailInViewMode={setCreatingDetailInViewMode}
            details={details}
            setDetails={setDetails}
          />
        ) : content == "Files" ? (
          <FileIndex
            imInUpdate={imInUpdate}
            partidaState={partidaState}
            setPartidaState={setPartidaState}
          />
        ) : content == "PartSearcher" && imInUpdate ? (
          <PriceSearcher
            partidaState={partidaState}
            setPartidaState={setPartidaState}
          />
        ) : null}
      </div>

	    <div style={{position:'relative', width:'100%', height:'90px', display:'flex', borderBottomRightRadius:'10px', borderTop:'1px solid #C4C4C4', background:'white'}}>

      {/* Margen % */}
      {  !bancoDePrecios &&
        <div style={{borderRight:'1px solid #C4C4C4', justifyContent:'center', display:'flex', flexDirection:'column', width:'25%', textAlign:'center'}}>
          <HelveticaBold style={{color:'#414141', position:'relative', fontSize:'14px', marginTop:'-1px', marginBottom:'15px'}}>{i18n.t("Margen")}</HelveticaBold>
          <div className="inline" style={{width:'100%', justifyContent:'center', position:'relative', textAlign:'center'}}>
            <PriceInput type="number" name="marginPercView" style={{width:'70%',position:'relative', fontSize:'14px', marginTop:'-11px', textAlign:'center'}}
              value={partidaState.hasOwnProperty('marginPercView')
                ? partidaState.marginPercView
                : marginCalc(partidaState).toFixed(2)}
	            onFocus={e => e.target.select()}
              onKeyDown={(e)=>{if(e.key === 'Enter') e.target.blur()}}
              onChange={(e)=>{handleViewChange(e)}}
              onBlur={(e)=>{handleViewBlur(e)}} />
            <HelveticaBold style={{color:'#414141', fontSize:'14px', marginTop:'-1px', marginBottom:'15px', fontWeight:'400', marginLeft:'5px'}}>%</HelveticaBold>
          </div>
        </div>
      }

      { /* Precio Unitario */ }
	    <div style={{borderRight:'1px solid #C4C4C4', justifyContent:'center', display:'flex', flexDirection:'column', width:'25%', textAlign:'center'}}>
        <HelveticaBold style={{color: "#414141", fontSize: "14px", marginTop: "0px", marginBottom: "10px"}}>{i18n.t("Precio Unitario")}</HelveticaBold>
          <div className="inline" style={{width:'100%', justifyContent:'center', position:'relative', textAlign:'center'}}>
	        <PriceInput name="priceView" type="number" style={{width:'50%',position:'relative', fontSize:'14px', marginTop:'-11px', textAlign:'center'}}
            value={partidaState.hasOwnProperty('priceView')
                    ? partidaState.priceView
                    : parseFloat(partidaState.clientPrice).toFixed(2)}
	          onFocus={e => e.target.select()}
            onKeyDown={(e)=>{if(e.key === 'Enter') e.target.blur()}}
            onChange={(e)=>{handleViewChange(e)}}
            onBlur={(e)=>{handleViewBlur(e)}} />
          <HelveticaBold style={{color:'#414141', fontSize:'14px', marginTop:'-1px', marginBottom:'15px', fontWeight:'400', marginLeft:'5px'}}>{currencySymbol}</HelveticaBold>
	      </div>
      </div>

      { /* Margen € */ }
      {  !bancoDePrecios &&
        <div style={{borderRight:'1px solid #C4C4C4', justifyContent:'center', display:'flex', flexDirection:'column', width:'25%', textAlign:'center'}}>
          <HelveticaBold style={{color:'#414141', position:'relative', fontSize:'14px', marginTop:'-1px', marginBottom:'15px'}}>{i18n.t("Margen")}</HelveticaBold>
          <div className="inline" style={{width:'100%', justifyContent:'center', position:'relative', textAlign:'center'}}>
            <PriceInput type="number" name="marginPriceView" style={{width:'70%',position:'relative', fontSize:'14px', marginTop:'-11px', textAlign:'center'}}
              value={partidaState.hasOwnProperty('marginPriceView')
                ? partidaState.marginPriceView
                : parseFloat(partidaGetClientPrice(partidaState) - partidaState.price * partidaState.quantity).toFixed(2)
              }
	    	      onFocus={e => e.target.select()}
              onKeyDown={(e)=>{if(e.key === 'Enter') e.target.blur()}}
              onChange={(e)=>{handleViewChange(e)}}
              onBlur={(e)=>{handleViewBlur(e)}} />
            <HelveticaBold style={{color:'#414141', fontSize:'14px', marginTop:'-1px', marginBottom:'15px', fontWeight:'400', marginLeft:'5px'}}>{currencySymbol}</HelveticaBold>
          </div>
        </div>
      }

      { /* Precio Total */ }
      { !bancoDePrecios &&
        <PartInput title={i18n.t("Precio Total")} name="priceTotalView" disabled={false}
          onBlur={(name, value) => { handleViewBlur({target: {name: name, value: value}}) }}
	  value={() => { return partidaState.hasOwnProperty('priceTotalView')
                    ? partidaState.priceTotalView
                    : calcPriceTotal(partidaState).toFixed(2)}}
	  symbol={"€"} />

	
		/*    <div style={{borderRight:'1px solid #C4C4C4', justifyContent:'center', display:'flex', flexDirection:'column', width:'25%', textAlign:'center'}}>
        <HelveticaBold style={{color: "#414141", fontSize: "14px", marginTop: "0px", marginBottom: "10px"}}>{i18n.t("Precio Total")}</HelveticaBold>
          <div className="inline" style={{width:'100%', justifyContent:'center', position:'relative', textAlign:'center'}}>
	        <PriceInput name="priceTotalView" type="number" style={{width:'50%',position:'relative', fontSize:'14px', marginTop:'-11px', textAlign:'center'}}
            value={partidaState.hasOwnProperty('priceTotalView')
                    ? partidaState.priceTotalView
                    : calcPriceTotal(partidaState).toFixed(2)}
	    	    onFocus={e => e.target.select()}
            onKeyDown={(e)=>{if(e.key === 'Enter') e.target.blur()}}
            onChange={(e)=>{handleViewChange(e)}}
            onBlur={(e)=>{handleViewBlur(e)}} />
          <HelveticaBold style={{color:'#414141', fontSize:'14px', marginTop:'-1px', marginBottom:'15px', fontWeight:'400', marginLeft:'5px'}}>{currencySymbol}</HelveticaBold>
	      </div>
      </div>*/ 
	
      }
      </div>
    </div>
  )
}

RightGridContent.propTypes = {
  partidaState: PropTypes.object,
  setPartidaState: PropTypes.func,
  imInUpdate: PropTypes.bool,
  setOpen: PropTypes.func,
  referenciaObject: PropTypes.object,
  handleClick: PropTypes.func,
  setErrorMessage: PropTypes.func,
  details: PropTypes.array,
  setDetails: PropTypes.func,
  bancoDePrecios: PropTypes.string,
  srcPage: PropTypes.string,
}

export default RightGridContent
