import React, {useState } from "react"
import NewModalPartida from "./NewModalPartida"
const ModalPartidas = () => {

  const [openModalPartida, setOpenModalPartida] = useState(true)

  return (
    <NewModalPartida open={openModalPartida} setOpen={setOpenModalPartida} chapterId={'asjfbsidufviwuvbui'}/>
  )
}

export default ModalPartidas
